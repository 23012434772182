import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { IDocsStatusData, IDocsStatusResponse } from "./SellerDashboardComponents/Interfaces";
import { emptyProfileImg } from "./assets";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  email: string;
  full_name: string;
  profile_picture: string | null;
  activePanel: string;
  authToken: string;
  seller_id: string;
  showMenu: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerDashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiCheckDocumentsCallId: string = "";
  apiDocumentsCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      email: "",
      full_name: "",
      activePanel: "myprofile",
      authToken: "",
      seller_id: "",
      showMenu: true,
      profile_picture: emptyProfileImg
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    this.handleNav(message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.apiCheckDocumentsCallId) {
        this.handleUserDocsResponse(responseJson);
      }
      if (apiRequestCallId === this.apiDocumentsCallId) {
        this.handleDocsResponse(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  handleUserDocsResponse = (responseJson: unknown) => {
    const successResponse = responseJson as IDocsStatusResponse;
    if ("data" in successResponse) {
      const documents = successResponse.data.reduce((newArr: unknown[], value: IDocsStatusData) => [...newArr, value.attributes.approved], []);
      if (documents.includes(true) && !documents.includes(null) && !documents.includes(false)) {
        this.props.navigation.navigate("SellerHomeDashboard", {path:"products"})
      }
    }
  };

  handleDocsResponse = (resp: unknown) => {
    const sucsResponse = resp as IDocsStatusResponse;
    if ("data" in sucsResponse) {
      if(sucsResponse.data.length === 0) {
        this.props.navigation.navigate("SellerLanding")
      } else if (sucsResponse.data.length < 5){
        this.props.navigation.navigate("BulkUploading")
      }
    }
  };

  handleChangePanel = (activePanel: string) => {
    this.setState({ activePanel });
    this.props.navigation.navigate("SellerDashboard", {path: activePanel})
  };

  handleGetSellerInfo = async () => {
    const email = await getStorageData("seller_email");
    const full_name = await getStorageData("seller_full_name");
    const authToken = await getStorageData("authToken");
    const seller_id = await getStorageData("seller_id");
    const profile_picture = await getStorageData("profile_picture");
    if (!authToken || !seller_id) {
      return;
    }
    this.setState({ email, full_name, authToken, seller_id, profile_picture }, () => this.checkSellerDocUser());
  };

  handleShowMenu = () => {
    if (this.state.showMenu) {
      const msg: Message = new Message(getName(MessageEnum.NavigationSellerNavigationBarMessage));
      msg.addData(getName(MessageEnum.HideSellerNavigationBar), true);
      this.send(msg);
    }
    this.setState({ showMenu: !this.state.showMenu });
  };

  handleNavigateToSellerHomeDashboard = () => {
    this.checkDocsUser();
  };

  checkDocsUser = () => {
    const headers = { "Content-Type": configJSON.contentType, token: this.state.authToken };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCheckDocumentsCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiGetSellerDocumentsStatusEndpoint + this.state.seller_id + "/seller_documents");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  checkSellerDocUser = () => {
    const headersDoc = { "Content-Type": configJSON.contentType, token: this.state.authToken };
    const requestMessageDoc = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiDocumentsCallId = requestMessageDoc.messageId;
    requestMessageDoc.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessageDoc.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiGetSellerDocumentsStatusEndpoint + this.state.seller_id + "/seller_documents");
    requestMessageDoc.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headersDoc));
    runEngine.sendMessage(requestMessageDoc.id, requestMessageDoc);
  };

  async componentDidMount(): Promise<void> {
    const token = await getStorageData('authToken');
    if(token === 'null' || !token) {
      this.props.navigation.navigate("FeaturesHeader");
    }
    this.handleGetSellerInfo();
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): Promise<void> {
    const path = window.location && window.location.pathname;
    const tabName = path.split("/")
    if (tabName[tabName.length - 1] !== prevState.activePanel) {
      this.setState({
        activePanel: tabName[tabName.length - 1],
      });
    }
  }

  handleNav = (msgs: Message) => {
    if (getName(MessageEnum.NavigationPayLoadMessage) === msgs.id) {
      const profileUpdates = msgs.getData(getName(MessageEnum.UpdatedSellerProfileInfo)) || false;
      if (profileUpdates) {
        this.setState({ email: profileUpdates.email, full_name: profileUpdates.full_name });
      }
      const showMenus = msgs.getData(getName(MessageEnum.ShowSellerDashboardMenu)) || false;
      if (showMenus) {
        this.setState({ showMenu: !this.state.showMenu });
      }
      const sellerProfileImages= msgs.getData(getName(MessageEnum.sellerProfileImage)) || false;
      if (sellerProfileImages) {
        this.setState({ profile_picture: sellerProfileImages });
      }
    }
  }
  // Customizable Area End
}
