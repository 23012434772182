import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { setStorageData } from "framework/src/Utilities";

// Customizable Area Start
import { createRef } from "react";
import { InputProps } from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showEmailLoginModal: boolean;
  showEmailVerifModal: boolean;
  showPassword: boolean;
  password: string;
  email: string;
  errorMessages: string[];
  loggingIn: boolean;
  full_phone_number: string;
  token: string;
  inputValues: string[];
  minutes: number;
  seconds: number;
  seller_id: string | number;
  country_code: string;
  phone_number: string;
  document_status: string;
  first_time_login: boolean;
  isResendOtpMSG:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class MobileAccountLoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  inputRefs: React.RefObject<InputProps & { focus: Function }>[];
  apiSignInUserCallId: string = "";
  apiSendOtpCallId: string = "";
  apiConfirmEmailOtpCallId: string = "";
  apiGetAllSellerInfoCallId: string = "";
  timer: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.SellerLoginEmailMessage)];

    this.state = {
      showEmailLoginModal: false,
      showEmailVerifModal: false,
      showPassword: false,
      password: "",
      email: "",
      errorMessages: [],
      inputValues: ["", "", "", "", ""],
      seconds: 30,
      minutes: 1,
      loggingIn: false,
      full_phone_number: "",
      token: "",
      seller_id: "",
      country_code: "",
      isResendOtpMSG:false,
      phone_number: "",
      document_status: "",
      first_time_login: false,
    };

    this.inputRefs = [createRef(), createRef(), createRef(), createRef(), createRef()];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.apiSignInUserCallId) {
        this.handleSignInResponse(responseJson);
      }
      if (apiRequestCallId === this.apiSendOtpCallId) {
        this.handleSendtOtpResponse(responseJson);
      }
      if (apiRequestCallId === this.apiConfirmEmailOtpCallId) {
        this.handleEmailOtpConfirmation(responseJson);
      }
      if (apiRequestCallId === this.apiGetAllSellerInfoCallId) {
        this.handleGetAllSellerInfoResponse(responseJson);
      }
    }
    if (getName(MessageEnum.SellerLoginEmailMessage)) {
      const msg = message.getData(getName(MessageEnum.SellerLoginEmailMessageData));
      this.handleBlockOnLoad(msg);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleGetAllSellerInfoResponse = (responseJson: unknown) => {
    interface SellerInfoData {
      data: {
        attributes: {
          country_code: string;
          phone_number: string;
          full_name: string;
        };
      };
    }
    const successData = responseJson as SellerInfoData;
    if ("data" in successData) {
      setStorageData("seller_full_name", successData.data.attributes.full_name);
      this.setState({ country_code: successData.data.attributes.country_code, phone_number: successData.data.attributes.phone_number });
      return;
    }
    this.setState({ errorMessages: ["Something went wrong, please try again later!"] });
  };

  handleEmailOtpConfirmation = async (responseJson: unknown) => {
    interface SuccessResponse {
      data: {
        attributes: {
          id: number;
        };
      };
      meta: {
        message: string;
      };
    }
    interface ErrorResponse {
      errors: {
        pin: string;
      }[];
    }
    const successRespnseData = responseJson as SuccessResponse;
    const errorResponseData = responseJson as ErrorResponse;
    if ("meta" in successRespnseData) {
      if (successRespnseData.meta.message === "Phone Number Confirmed Successfully" || successRespnseData.meta.message === "Phone Number Already Activated") {
        if (this.state.first_time_login) {
          const msg: Message = new Message(getName(MessageEnum.NavigationSellerLandingMessage));
          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          return this.send(msg);
        }
        if (this.state.document_status === "Your Document verification is in progress" || this.state.document_status === "No documents uploaded") {
          const msg: Message = new Message(getName(MessageEnum.NavigationSellerLandingMessage));
          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          return this.send(msg);
        }
        if (this.state.document_status === "Your document has been verified") {
          this.props.navigation.navigate("SellerHomeDashboard", { path: "products" });
        }
      }
    }
    if ("errors" in errorResponseData) {
      return this.setState({ errorMessages: [errorResponseData.errors[0].pin] });
    }
  };

  handleBlockOnLoad = (msg: unknown) => {
    interface BlockMessage {
      openEmailLoginModal: boolean;
    }
    const message = msg as BlockMessage;
    if (msg !== undefined) {
      if ("openEmailLoginModal" in message) {
        this.setState({
          showEmailLoginModal: true,
          showEmailVerifModal: false,
          password: "",
          email: "",
          errorMessages: [],
        });
      }
    }
  };

  handleSendtOtpResponse = (responseJson: unknown) => {
    interface SuccessResponse {
      data: {
        attributes: {
          pin: number;
        };
      };
      meta: {
        token: string;
      };
    }
    const successResponseData = responseJson as SuccessResponse;
    if ("data" in successResponseData) {
      this.setState({
        showEmailLoginModal: false,
        showEmailVerifModal: true,
        loggingIn: false,
        token: successResponseData.meta.token,
      });
      this.inputRefs[0].current?.focus()
      this.startTimer();
      return;
    }
    this.setState({ errorMessages: ["Something went wrong! Please try again later."], loggingIn: false });
  };

  handleSignInResponse = (responseJson: unknown) => {
    interface SuccessResponse {
      meta: {
        token: string;
        refresh_token: string;
        id: number;
        document_status: string;
        full_phone_number: string;
        email: string;
        full_name: string;
        first_time_login: boolean;
        profile_picture: any;
      };
    }
    interface FailedResponse {
      errors: [
        {
          failed_login: string;
        },
      ];
    }
    const successResponseData = responseJson as SuccessResponse;
    const failedResponse = responseJson as FailedResponse;
    if ("meta" in successResponseData) {
      setStorageData("authToken", successResponseData.meta.token);
      setStorageData("seller_id", successResponseData.meta.id);
      setStorageData("seller_email", successResponseData.meta.email);
      setStorageData("full_phone_number", successResponseData.meta.full_phone_number);
      setStorageData("profile_picture", successResponseData.meta?.profile_picture);
      setStorageData("isDocumentVerified", successResponseData.meta?.document_status);
      this.setState({
        full_phone_number: successResponseData.meta.full_phone_number,
        token: successResponseData.meta.token,
        seller_id: successResponseData.meta.id,
        first_time_login: successResponseData.meta.first_time_login,
        document_status: successResponseData.meta.document_status,
      });
      this.sendLoginOtp();
      this.getAllSellerInfo();
      return;
    }
    if ("errors" in failedResponse) {
      this.setState({ errorMessages: [failedResponse.errors[0].failed_login], loggingIn: false });
      return;
    }
    this.setState({ errorMessages: ["Something went wrong! Please try again later."], loggingIn: false });
  };

  handleCloseModal = () => {
    this.setState({
      showEmailLoginModal: false,
      showEmailVerifModal: false,
    });
  };

  handlePasswordOnChange = (password: string) => {
    this.setState({
      password: password,
    });
  };

  handleEmailOnChange = (email: string) => {
    this.setState({
      email: email,
    });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  handleKeyDown = async (e: any) => {
    if (e.key === "Enter") {
      this.handleOnSignIn();
    }
  };
  handleOnSignIn = () => {
    this.setState({ errorMessages: [], loggingIn: true });
    const { email, password } = this.state;
    if (email && password) {
      this.signInUser();
      return;
    }
    this.setState({ errorMessages: ["Please provide all of the necessary information!"], loggingIn: false });
  };

  handleGoToSignUpPage = () => {
    this.setState({
      showEmailLoginModal: false,
      showEmailVerifModal: false,
    });
    const msg: Message = new Message(getName(MessageEnum.NavigationSellerAccountRegistrationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  handleInputOnChange = (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const targetValue = event.target.value;
    if (!isNaN(Number(targetValue))) {
      const inputValues = this.state.inputValues;
      inputValues[index] = targetValue;
      this.setState({ inputValues: inputValues });
      if (index < this.inputRefs.length - 1 && this.state.inputValues[index]) {
        const inputRef = this.inputRefs[index + 1];
        if (inputRef && inputRef.current) {
          inputRef.current.focus();
        }
      }
    }
  };

  handleResendOtp = () => {
    this.setState({
      inputValues:["","","","",""]
  })
  this.inputRefs = [createRef(), createRef(), createRef(), createRef(), createRef()];


    if (this.state.seconds === 0 && this.state.minutes === 0) {
      setTimeout(() => {
        this.setState((state, props) => ({
         isResendOtpMSG:true
        }), () => {
          setTimeout(() => {
            this.setState(() => ({
              isResendOtpMSG:false
            }))
          }, 1500);
        })
      }, 100);
      this.setState({ seconds: 30, minutes: 1 });
      this.sendLoginOtp();
      this.startTimer();
      return;
    }
    this.setState({
      errorMessages: ["Please wait for a while to resend otp again!"],
    });
  };
  handleInputOnPress = (index: number, event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      this.handleSubmitOtp();
    }
    if (event.key === "Backspace") {
      const newInputValues:string[] 
      = [...this.state.inputValues];
      if (
         index > 0) {
        const prevInputRef =
         this.inputRefs[index - 1];
        if (prevInputRef 
          &&
           prevInputRef.current) {
          prevInputRef.current.focus();
        }
      }
      newInputValues[index] = "";
      this.setState({ inputValues: newInputValues });
    }
    
  };

  handleSubmitOtp = () => {
    if((this.state.minutes <= 0 && this.state.seconds <= 0)) {
      this.setState({ errorMessages: ['OTP has been expired. Click on Resend now to continue...'] });
      return;
    }
    this.setState({ errorMessages: [] });
    if ([...this.state.inputValues].includes("") === false) {
      this.confirmEmailOtp();
    }
    if ([...this.state.inputValues].includes("")) {
      this.setState({
        errorMessages: ["Please provide all of the needed information!"],
      });
    }
  };

  handleOpenForgotPWModal = () => {
    const msg: Message = new Message(getName(MessageEnum.SendMessage));
    msg.addData(getName(MessageEnum.OpenForgoPasswordModal), true);
    this.send(msg);
    this.setState({ showEmailLoginModal: false });
  };

  startTimer = () => {
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      const { seconds, minutes } = this.state;
      if (seconds === 0 && minutes === 0) {
        clearInterval(this.timer);
      } else {
        if (seconds > 0) {
          this.setState(prevState => ({ seconds: prevState.seconds - 1 }));
        } else {
          this.setState(prevState => ({
            seconds: 59,
            minutes: prevState.minutes - 1,
          }));
        }
      }
    }, 1000);
  };

  sendLoginOtp = () => {
    const headers = { "Content-Type": configJSON.contentType, token: this.state.token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiSendOtpCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiSendOtpEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            full_phone_number: this.state.full_phone_number,
          },
        },
      }),
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  confirmEmailOtp = () => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiConfirmEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiVerifyOtpEndpont);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        pin: this.state.inputValues.join(""),
      }),
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllSellerInfo = () => {
    const headers = { "Content-Type": configJSON.contentType, token: this.state.token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetAllSellerInfoCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiGetUsersInfoEndpoint + this.state.seller_id);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  signInUser = () => {
    const path = window.location.pathname.split("/")[1];
    console.log("Path", path)
    const headers = { "Content-Type": configJSON.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiSignInUserCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiSignInUserEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          type: "email_account",
          user_type: ((path === "" ) || (path === "seller")) ? "seller" : "buyer",
          attributes: {
            email: this.state.email,
            password: this.state.password,
          },
        },
      }),
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
