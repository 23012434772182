import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("../src/config.js")
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes : {
    mainContainer: string,
    headerWrapper : string,
    headerText: string,
    container: string,
    orderTextWrapper: string,
    ordersText: string,
    text: string,
    rightSide: string,
    stockBtn : string
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  authToken: string;
  createReq: boolean
  isPrintLabel: boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class FBBStockMgmtStorageController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      authToken: "",
      createReq: false,
      isPrintLabel: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  handleCreateStockReq = () => {
    this.props.navigation.navigate('SellerHomeDashboard', {navigationBarTitleText: "create/stockreq"})
    this.setState({ createReq :true })
    this.props.navigation.navigate(configJSON.sellerHomeText, {path: configJSON.createStockReqPath})
  }

  handlePrintLabel = (value: boolean) => {
    this.setState({isPrintLabel : true})
  }

  handleGetToken = async () => {
    const authToken = await getStorageData("authToken");
    this.setState({ authToken });
  };

  async componentDidMount(): Promise<void> {
    this.handleGetToken();
  }
  // Customizable Area End
}
