import React from "react";

// Customizable Area Start
import {
  Box,
  Card,
  CardMedia,
  Container,
  Typography, withStyles
} from "@material-ui/core";
import ProductListController, { Props, calculateDiscountPercentage } from "./ProductListController.web";
import { rapidLogo, starIcon } from "../assets";
// Customizable Area End


class ProductList extends ProductListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { classes, item } = this.props;
    const productDetails =item?.attributes?.product_content?.product_attributes;
    return (
      <>
        <Box data-test-id="item" onClick={() => this.handleRedirectToProduct(item.id)} style={webStyle.link}>
        <Container className={classes.cardContainer}>
          <Card className={classes.mainCard}>
            <Typography style={webStyle.newText}>New</Typography>
            <CardMedia
              className={classes.cardImage}
              component="img"
              image={item.attributes.product_image}
              alt={item.attributes.product_title || ""}
              data-test-id="cardMediaRef"
            />
          </Card>
          <Typography className={classes.mobDetails}>
            {item.attributes?.product_title || 'N/A'}
          </Typography>
          <Typography className={classes.discountedPrice}>
  AED <span className={classes.offer}>{productDetails && productDetails.retail_price ? productDetails.retail_price : 0}</span>
</Typography>
{calculateDiscountPercentage((productDetails && productDetails.mrp) as number, (productDetails && productDetails.retail_price) as number) > 0 && <Typography className={classes.ogPrice}>
            <span className={classes.mrp}>
              {productDetails?.mrp}{" "}
            </span>{" "}
            <b className={classes.discount} data-test-id="discountRef"> &nbsp; {calculateDiscountPercentage(productDetails?.mrp as number,productDetails?.retail_price as number)}% OFF</b>
          </Typography>}
          <Box className={classes.last}>
                      <img data-test-id="rapidButtonRef" className={classes.rapidImg} src={rapidLogo} alt="express_img" />
                      <Box style={webStyle.flex}>
                        <Typography className={classes.rateBox}>
                        {Number(
                              item?.attributes
                                ?.product_rating?.average_rating || 0
                            )}{" "}
                          <span className={classes.starImg}>
                            <img src={starIcon} alt="start" />
                          </span>
                        </Typography>
                        <Typography data-test-id="ratingRef" className={classes.ratingText}>
                        {Number(
                              item?.attributes
                                ?.product_rating?.total_reviews || 0
                            )}
                        </Typography>
                      </Box>
                    </Box>
          
        </Container>
        </Box>
      </>
    )
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = {
  cardContainer: {
    width: "100%",
  },
  mainCard: {
    width: "100%",
    height: "280px",
    marginLeft: "10px",
    marginTop: "10px",
    borderRadius: "8px",
    padding: "5px",
    border: "1px solid #eee"
  },
  cardImage: {
    alignItems: "center",
    justifyContent: "center",
    height: "220px",
    marginTop: "10%",
    objectFit:'fill' as const ,
  },
  mobDetails: {
    fontSize: "16px",
    marginTop: "10px",
    marginLeft: "10px",
    color: "#33333a",
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    fontFamily:"Lato, sans-serif",
    fontWeight: 400,
    wordBreak: "break-all" as const
  },
  discountedPrice: {
    fontSize: "14px",
    marginTop: "10px",
    marginLeft: "10px",
    color: "#ff6008",
    fontFamily:"Lato, sans-serif",
    fontWeight: 400
  },
  ogPrice: {
    fontSize: "10px",
    marginLeft: "10px",
  },
  rapidImg: {
    width:'67px',
    height: '24px',
    marginLeft:'10px'
  },
  rateBox: {
    padding: "2px 6px",
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    backgroundColor: "#565656",
    color: "#ffffff",
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
    fontWeight: 700 as const,
  },
  starImg: {
    marginLeft: "5px",
  },
  last: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: '-24px'
  },
  ratingText: {
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
    marginLeft: "5px",
    display: "flex",
    alignItems: "center",
    color: "#ff6008",
    fontWeight: 400 as const,
  },
  offer: {
    color: "#ff6008",
    fontWeight: 700 as const,
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
  },
  mrp: { 
    color: "grey", 
    textDecoration: "line-through", 
    fontSize:'14px', 
    fontWeight: 400, 
    fontFamily: `"Lato",sans-serif`,
  },
  discount: { 
    color: "#33333a", 
    fontSize:'14px', 
    fontWeight: 700, 
    fontFamily: `"Lato",sans-serif`,
  }
};

const webStyle = {
  link: {
    cursor: 'pointer'
  },
  flex: {
    display: 'flex'
  },
  newText: {
    marginTop: "6px",
    width: "20%",
    textAlign: "center" as const,
    marginLeft: "78%",
    background: "#fb620f",
    color: "white",
    borderRadius: "3px",
    fontSize: "9px",
    padding: "1.5px",
  }
}

export { ProductList };

export default withStyles(styles)(ProductList);
// Customizable Area End