// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { IMapProps } from "google-maps-react";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

export interface Props extends IMapProps{
  navigation: any;
  id: string;
  // Customizable Area Start
  lat?: number
  lng?: number
  address: string
  disabled?: boolean
  placeholder: string
  classes: Record<string, string>,
  google: any,
  handlePlacesAutocompleteChange: (address: string) => void
  handlePlacesAutocompleteSelect: (address: string) => void
  detectPlace: (setFieldVal: (key: string, value: string) => void) => void
  setFieldValue: (key: string, value: string) => void
  errorFlag: boolean,
  inputFieldError: {[key:string] : string}
  // Customizable Area End
}
// Customizable Area End

interface S {
  // Customizable Area Start
  locationAddress: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End

export default class GooglePlaceAutoCompleteController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      locationAddress: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    const { lat, lng } = this.props
    const latLng = new this.props.google.maps.LatLng(lat as number, lng as number);
    const geocoder = new google.maps.Geocoder()
    geocoder
    .geocode({ location : latLng})
      .then((response: {results: {formatted_address: string}[]}) => {
        if (response.results[0]) {
          this.setState({ locationAddress: response.results[0].formatted_address })
        }
      })
      .catch((error: string) => {
        toast.error(error)
      })
    // Customizable Area End
  }
  // Customizable Area End

  // Customizable Area Start
  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevProps.address !== this.props.address) {
      this.setState({ locationAddress: this.props.address })
    }
  }

  handleChange = (address: string) => {
    this.props.handlePlacesAutocompleteChange(address)
  }

  handleSelect = (address: string) => {
    this.props.handlePlacesAutocompleteSelect(address)
  }
  // Customizable Area End
}
