import React from "react";

// Customizable Area Start
import {
    Box, Typography, withStyles,
    Grid,
    Container,
    Divider,
    Select,
    Theme,
    styled
} from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ProductCatalogueController, { IBrandList, Props } from "./ProductCatalogueController.web";
import CategoriesSideBar from "./CategoriesSideBar.web";
import ProductList from "./ProductList.web";
import HeaderWeb from "../../../landingpage/src/Header.web";
import FooterWeb from "../../../landingpage/src/Footer.web";
import AppsIcon from '@material-ui/icons/Apps';
import { FormControlTwo, MenuItemTwo } from "../../../dashboard/src/CustomizedElementStyle";
import { withRouter } from 'react-router-dom';
import { logoImg } from "../assets";
import { ClickableBox } from "../../../mobile-account-login/src/CustomElementStyling";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
const ShopByBrandSlider = styled(Slider)({
    "& .slick-slide > div > div": {
      borderRadius: 10
    },
    "& .slick-slide > div": {
      display: "flex",
      margin: "0 8px",
      gap: 10
    },
    "& .slick-prev::before": {
        fontSize: 35,
        opacity: 1,
        color: "rgb(255, 255, 255)"
    },
    "& .slick-next::before": {
        fontSize: 35,
        opacity: 1,
        color: "rgb(255, 255, 255)"
    },
    "& .slick-slide": {
      height: "unset",
      padding: "0 20px"
    },
    "& .slick-track": {
        display: "flex"
    },
    "& .slick-prev": {
      top: "42%",
      color: "#000",
      backgroundColor: "#fff",
      padding: "4px",
      fontSize: "18px",
      borderRadius: '50%'
    },
    "& .slick-next": {
      top: "42%",
      color: "#000",
      backgroundColor: "#fff",
      padding: "4px",
      fontSize: "18px",
      borderRadius: '50%',
      "& >path": {
      }
    }
  });
// Customizable Area End


class ProductCatalogue extends ProductCatalogueController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        const { classes } = this.props; 
        const settings = {
            centerMode: false,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: this.state.brandList.length > 6 ? 6 : this.state.brandList.length,
            slidesToScroll: 1,
            boxShadow: "0px 1px 3px #EBEBEB",
            prevArrow: <ArrowBackIos style={{
                color: 'black',
                fontSize: '18px'
            }} />,
            nextArrow: <ArrowForwardIos style={{
                color: 'black',
                fontSize: '18px'
            }} />,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 6,
                  slidesToScroll: 1,
                  infinite: true,
                 
                }
              },
              {
                breakpoint: 960,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  infinite: true,
                  
                }
              },
              {
                breakpoint: 786,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: true,
                  
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ],
          };
        return (
            <Box className={classes.mainWrraper}>
                <HeaderWeb navigation={this.props.navigation} id={this.props.id} />
                <Container className={classes.catalogContainer}>
                <Box className={classes.root} >
                    <Box style={{ zIndex: 99}}>
                    <nav className={classes.drawer} aria-label="mailbox folders">
                        <CategoriesSideBar data-test-id="categorySideBar" colorFilter={this.state.color_filter} onSetColorFilter={this.handleSetColorFilter} navigation={undefined} id={"categories-sidebar"} subCategoryList={this.state.subCategoryList} brandList={this.state.brandList} setFilterValues={this.setFilterValues} subCatagoryItemsIds={this.state.subCatagoryItemsIds} brandItemsIds={this.state.brandItemsIds} productList={this.state.productList} max_price={this.state.max_price} min_price={this.state.min_price} customFieldsData={this.state.customFieldsData} customFieldValues={this.state.customFieldValues} handleRatingVlaue={this.handleRatingVlaue} out_of_stocks={this.state.out_of_stocks} onSetOutOfStocks={this.handleOutOfStocks}/>
                    </nav>
                    </Box>
                    <Box className={classes.content}>
                        <Box>
                            <Container style={{ width: "100%", marginBottom: "10px"}}>
                                <Box className={classes.flex}>
                                <Typography data-test-id="redirect-to-home" onClick={this.handleHome} className={classes.category}>
                                    Home
                                </Typography>
                                <Typography className={classes.categoryOne}>{" / "} {this.state.productList.category}</Typography>
                                </Box>
                                <Typography
                                    className={classes.shopByText}>
                                    Shop by Brand
                                </Typography>
                                <Grid container spacing={2} style={{display:"block"}} data-test-id="slider-container" className={classes.shopByBrandContainer}>
                                    <ShopByBrandSlider {...settings}>
                                    {this.state.brandList && this.state.brandList.map((brand:IBrandList,index:any)=>{
                                        return <Grid data-test-id="brand-item-id" item key={`brand-list-${index}`} xs={12} sm={12} lg={12} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start" }} >
                                           <ClickableBox test-dataId="brand-option" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }} onClick={() => this.handleShopByBrandFilter(brand.id)}>
                                            <Box className={classes.brandBtn}>
                                            <img data-test-id="oppoRef" className={classes.brandImage} src={brand.attributes.brand_image ? brand.attributes.brand_image : logoImg} />
                                            </Box>
                                            <Typography className={classes.brandName}>{brand.attributes.brand_name}</Typography>
                                        </ClickableBox>
                                    </Grid>
                                    })}
                                    </ShopByBrandSlider>
                                </Grid>
                            
                            </Container>
                        </Box>
                            <Box className={classes.categoryFilterContainer} >
                                <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: "row", marginLeft: 'auto' }} >
                                    <Grid item xs={6} >
                                        <FormControlTwo style={{ width: '100%' }}>
                                            <Box className={classes.displayFilter}>{"Display"}</Box>
                                            <Select 
                                                style={{background:"#eee"}}
                                                data-test-id="selectPerPaageref"
                                                labelId="demo-simple-select-outlined-label"
                                                defaultValue="50"
                                                id="demo-simple-select-outlined"
                                                value={this.state.itemPerPage}
                                                onChange={this.handleItemPerPage}
                                                inputProps={{
                                                    classes: {
                                                        select: classes.selectDisplay,
                                                      },
                                                }}
                                                MenuProps={{
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "left"
                                                    },
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    },
                                                    PaperProps: {
                                                        style: {
                                                            border: "1px solid #b1b3b5",
                                                            padding: 0,
                                                            boxShadow: "none",
                                                            borderRadius: 5,
                                                            background: "white",
                                                            overflowY: "auto",
                                                            marginTop: "5px",
                                                            maxHeight: "400px",
                                                        }
                                                    },
                                                    getContentAnchorEl: null,
                                                }}
                                            >
                                                <MenuItemTwo value={50}>50 per Page</MenuItemTwo>
                                                <MenuItemTwo value={40}>40 per page</MenuItemTwo>
                                                <MenuItemTwo value={30}>30 per page</MenuItemTwo>
                                            </Select>
                                        </FormControlTwo>
                                    </Grid>
                                    <Grid item xs={6}>

                                        <FormControlTwo style={{ width: '100%' }}>
                                            <Box className={classes.displayFilter}>{"Sort By"}</Box>
                                            <Select 
                                                style={{background:"#eee"}}
                                                data-test-id="selectRecommonded"
                                                labelId="demo-simple-select-outlined-label"
                                                defaultValue="recommended"
                                                value={this.state.sortByProduct}
                                                onChange={this.handleSortByProductOption}
                                                inputProps={{
                                                    classes: {
                                                        select: classes.selectDisplay,
                                                      },
                                                }}
                                                id="demo-simple-select-outlined"
                                                placeholder="Recommonded"
                                                MenuProps={{
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "left"
                                                    },
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    },
                                                    PaperProps: {
                                                        style: {
                                                            border: "1px solid #b1b3b5",
                                                            padding: 0,
                                                            boxShadow: "none",
                                                            borderRadius: 5,
                                                            background: "white",
                                                            overflowY: "auto",
                                                            marginTop: "5px",
                                                            maxHeight: "400px",
                                                        }
                                                    },
                                                    getContentAnchorEl: null,
                                                }}
                                            >
                                                <MenuItemTwo value="recommended">
                                                    Recommended
                                                </MenuItemTwo>
                                                <MenuItemTwo value="whats_new">
                                                    What's New
                                                </MenuItemTwo>
                                                <MenuItemTwo value="popularity">Popularity</MenuItemTwo>
                                                <MenuItemTwo value="customer_rating">Customer Rating</MenuItemTwo>
                                                <MenuItemTwo value="high_to_low">High to Low</MenuItemTwo>
                                                <MenuItemTwo value="low_to_high">Low to High</MenuItemTwo>
                                            </Select>
                                        </FormControlTwo>
                                    </Grid>
                                </Grid>
                                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: "center", flexDirection: "column", margin: "0 10px" }}>
                                    <Box className={classes.displayFilter}>{"Grid"}</Box>
                                    <AppsIcon style={{ color: "#eb6b2e", fontSize: '40px' }} />
                                </Box>
                            </Box>
                        <Divider />
                        <Grid container spacing={2} xs={12}>
                            {this.state.productList.data && this.state.productList.data.map((product: any) => (
                                <Grid item xs={12} sm={6} md={3} key={product.id}>
                                    <ProductList navigation={this.props.navigation} id="product-list-categories" item={product}/>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
                </Container>
                {/* Footer */}
                <FooterWeb navigation={this.props.navigation} id={this.props.id} categories={[]} token={""} />
            </Box >
        )
    }
    // Customizable Area End
}

// Customizable Area Start
const styles = (theme: Theme) => ({
    root: {
        fontFamily: `"Lato", sans-serif`,
        display: 'flex',
        flexDirecton: 'row' as const,
        flexWrap: 'nowrap' as const,
        backgroundColor: "#fefaf9",
        padding:'20px',
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            flexDirection: "column" as const
        }
    },
    categoryFilterContainer: {
        display: 'flex', 
        width: '450px', 
        justifyContent: 'space-between', 
        flexDirection: "row" as const, 
        marginLeft: 'auto',
        marginBottom:'15px',
        [theme.breakpoints.down('xs')]:{
            width: "100%"
        }
      },
    mainWrraper: {
        background: "#fff",
        width: "100%",
        overflowX: "hidden" as const,
    },
    brandContainer: {
        margin: "20px 0px",
    },
    brandBtn: {
        height: "90px",
        width: "90px",
        borderRadius: "50%",
        border: "none",
        background: "white",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        padding: "10px",
    },
    drawer: {
        padding:"20px 0 0 20px",
        [theme.breakpoints.up('sm')]: {
            width: 240,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: 240,
    },
    content: {
        width: 'auto' as const,
        overflowX: 'auto' as const,
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(1)
        }
    },
    contentWrapper: {
        overflowY: "auto" as const,
        overflowX: "hidden" as const
    },
    headerText: {
        padding: "20px 0px 15px 0px",
        fontSize: "18px",
        fontWeight: 400 as const,
        fontFamily: `"Lato", sans-serif` as const
    },
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    orderTextWrapper: {
        width: "100%",
        display: "flex",
        marginBottom: 10,
        flexDirection: "row" as const,
        flexWrap: "wrap" as const,
        justifyContent: "space-between",
        alignItems: "center" as const,
    },
    lAndIContainer: {
        display: "flex",
        flexDirection: "column" as const,
    },
    searchWrapper: {
        padding: "20px 0px 15px 0px"
    },
    searchOrderText: {
        fontSize: '12px',
        fontWeight: 400 as const,
        color: '#33333A',
    },
    container: {
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column" as const,
        padding: "30px",
        position: "relative" as const,
        background: "white"
    },
    searchBoxWrapper: {
        position: 'relative' as const,
        width: '65%',
    },
    options: {
        width: "250px !important",
        borderRadius: "5px",
        background: "white",
    },
    label: {
        fontSize: "12px",
        fontFamily: '"Lato",san-serif' as const,
    },
    select: {
        paddingLeft: "8px",
        width: "150px",
        height: "40px",
        borderRadius: "5px",
        marginTop: "10px",
        background: "grey",
        paddingRight: "8px",
        fontSize: "14px",
        border: "1px solid grey"
    },
    ordersText: {
        fontSize: "27px",
        fontWeight: 700 as const,
        fontFamily: `"Lato", sans-serif` as const
    },
    brandImage: {
        width:'100%',
        objectFit:'fill' as const,
        borderRadius:'50%'
    },
    category: { 
        fontFamily: `"Lato", sans-serif`, 
        fontSize: "14px", 
        color: "#585858", 
        cursor:'pointer' 
    },
    shopByText: { 
        fontFamily: `"Lato-Heavy", sans-serif`, 
        marginTop: "20px", 
        fontSize: "32px", 
        color: "#33333a", 
        fontWeight: 400 
    },
    brandName: { 
        fontFamily: `"Lato", sans-serif`,
        marginTop: "10px", 
        color: "#33333a", 
        textAlign: "center" as const, 
        fontSize: "16px", 
        fontWeight: 400 
    },
    categoryOne: { 
        fontFamily: `"Lato", sans-serif`, 
        fontSize: "14px", 
        color: "#585858", 
    },
    shopByBrandContainer: {
        padding: "16px 32px",
        '& .slick-current': {
            paddingLeft: 40,
            [theme.breakpoints.down('xs')]:{
                padding: 10
            },
        },
        '& .slick-list': {
            height: 180,
            [theme.breakpoints.down('xs')]:{
                height: 180,
            }
        },
    },
    flex:{
        display: 'flex'
    },
    catalogContainer: { 
        maxWidth: "1700px", 
        padding: "0", 
        margin: "0 auto" 
      },
      selectDisplay: {
        fontSize: "16px !important",
        fontFamily: `"Avenir-Medium", sans-serif !important` as const,
        color: "#657A8E !important",
        fontWeight: 400,
      },
      displayFilter: { 
        color: "#666666", 
        fontFamily: `"Lato", sans-serif`, 
        fontWeight: 400, 
        fontSize: 12, 
        marginBottom: 10 
    }
});

export { ProductCatalogue }

export default withRouter(withStyles(styles)(ProductCatalogue) as any) as any;
// Customizable Area End