// Customizable Area Start
import React from "react";
import ShippingDetailsController, {
  Props,
  configJSON,
} from "./ShippingDetailsController.web";
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  InputAdornment,
  Modal,
  Paper,
  Typography,
  styled,
} from "@material-ui/core";
import { CustomizeShippingInput } from "../../../dashboard/src/CustomizedElementStyle";
import { Form, Formik } from "formik";
import { ToastContainer } from "react-toastify";
// Customizable Area End

class ShippingDetails extends ShippingDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open, onClose } = this.props;
    let initialValues = {
      courier_name: this.state.courier_name,
      tracking_number: this.state.tracking_number || "",
    };
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      <ToastContainer/>
      <ShipModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
      >
        <ClickAwayListener onClickAway={onClose}>
          <Paper className="paper">
            <Formik
              data-test-id="formik"
              enableReinitialize
              initialValues={initialValues}
              validationSchema={this.shippingSchema}
              validateOnMount={true}
              validateOnChange={true}
              onSubmit={(values) => this.AddUpdateOrderDetails(values)}
            >
              {(formikProps) => {
                const { values, handleChange, touched, errors } = formikProps;
                const renderInputField = (
                  fieldName: string,
                  placeholder: string,
                  label: string,
                  value: string
                ) => (
                  <Grid item xs={12} sm={6} md={6} lg={6} style={{ marginTop: "10px" }}>
                    <CustomizeShippingInput
                      fullWidth
                      data-test-id={fieldName}
                      placeholder={placeholder}
                      label={label}
                      id="outlined-start-adornment"
                      value={value}
                      onChange={handleChange(fieldName)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                    />
                    {(touched as {[key: string]: boolean})[fieldName] &&
                    (errors as {[key: string]: string})[fieldName] ? (
                      <Typography
                        style={{
                          color: "red",
                          textAlign: "left",
                          fontSize: "12px",
                          fontFamily: `"Lato", sans-serif`,
                          marginTop: "4px",
                        }}
                      >
                        {(errors as {[key: string]: string})[fieldName]}
                      </Typography>
                    ) : null}
                  </Grid>
                );
                return (
                  <Form translate={undefined}>
                    <Typography className="title">Shipping Details</Typography>
                    <Grid container spacing={2}>
                      {renderInputField(
                        "courier_name",
                        configJSON.courierNamePlaceHolder,
                        configJSON.courierName,
                        values.courier_name
                      )}
                      {renderInputField(
                        "tracking_number",
                        configJSON.trackingNumberPlaceHolder,
                        configJSON.trackingNumber,
                        values.tracking_number
                      )}
                    </Grid>
                    <Box className="buttonContainer">
                      <Button
                        className="cancel"
                        data-test-id="cancel"
                        onClick={() => this.props.onClose()}
                      >
                        Cancel
                      </Button>
                      <Button type="submit" className="orangeButton">
                        Submit
                      </Button>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Paper>
        </ClickAwayListener>
      </ShipModal>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ShipModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  borderRadius: "10px",
  "& .paper": {
    padding: "15px 20px",
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    flexDirection: "column",
    gap: "15px",
    "& form": {
      display: "flex",
      gap: "18px",
      marginBottom: "5px",
      borderRadius: "10px",
      flexDirection: "column",
      "& .title": {
        fontFamily: `"Lato", sans-serif`,
        fontSize: "18px",
        fontWeight: 500,
        color: "black",
      },
    },
    "& .buttonContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      flexDirection: "row",
      gap: "10px",

      "& button": {
        padding: "5px 21px",
        textTransform: "capitalize",
        color: "#657A8E",
        fontFamily: `"Lato", sans-serif`,
        fontSize: "14px",
        fontWeight: 500,
        backgroundColor: "#fff",
        borderRadius: "5px",
        display: "flex",
      },
      "& .cancel": {
        border: "1px solid rgba(101, 122, 142, 0.320241)",
      },
      "& .orangeButton": {
        color: "white",
        backgroundColor: "rgb(253, 97, 12)",
      },
    },
  },
});

export { ShippingDetails };
export default ShippingDetails;
// Customizable Area End
