import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

// Customizable Area Start
import { ICheckUserInfoSuccessResponse, IDeleteUserSuccessResponse, IErrorMessage, IUpdateShowPassword, IUpdateValue } from "./Interfaces";
import { getStorageData, removeStorageData, setStorageData } from "../../../../framework/src/Utilities";
import Cookies from "js-cookie";

import Cropper, { Area } from "react-easy-crop";
import React, { ChangeEvent, createRef } from "react";
import { getOrientation } from "get-orientation/browser";
import { InputProps } from "@material-ui/core";
import { toast } from "react-toastify";

type CanvasContext = CanvasRenderingContext2D | null

export interface IFormValues {
  firstName: string,
  storeName: string,
  email: string,
  currentPassword: string,
  newPasswordForm: string
}
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    controls: string;
    sliderContainer: string;
    cropContainer: string;
    sliderLabel: string;
    slider: string;
    cropButton: string;
    appBar: string;
    flex: string;
    imgContainer: string;
    img: string;
    modal: string;
    modalContent: string;
    modalHeader: string;
    root: string;
    editButton: string;
    avatar: string;
    labelHeader: string;
    textField: string;
  };
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  authToken: string;
  seller_id: string;
  email: string;
  full_name: string;
  company_or_store_name: string;
  showCurrentPassword: boolean;
  showNewPassword: boolean;
  currentPassword: string;
  newPassword: string;
  errorMessages: string[];
  showVerifyDeleteModal: boolean;
  successMsg: string;
  croppedAreaPixels: string | Area | null;
  imageSrc: unknown;
  crop: {x: number, y: number};
  rotation: number;
  zoom: number;
  croppedImage: unknown | null;
  isEditModalOpen: boolean;
  isPreviewModalOpen: boolean;
  profilePicture: string | null;
  loading: boolean;
  previousValues: {
    firstName: string,
    storeName: string
  }
  // Customizable Area End
}
interface SS {
  id: any;
}

const ORIENTATION_TO_ANGLE: {[key:string]:number} = {
  "3": 180,
  "6": 90,
  "8": -90
};

export default class SellerMyProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetSellerInfoCallId: string = "";
  apiUpdateSellerProfileCallId: string = "";
  apiDeleteUserCallId: string = "";
  cropperRef: React.RefObject<Cropper> = React.createRef();
  inputRef: React.RefObject<InputProps & { click: Function }>;
  formRef: React.RefObject<HTMLFormElement>;
  apiUploadImageId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      authToken: "",
      seller_id: "",
      email: "",
      full_name: "",
      company_or_store_name: "",
      showNewPassword: false,
      showCurrentPassword: false,
      newPassword: "",
      currentPassword: "",
      errorMessages: [],
      successMsg: "",
      showVerifyDeleteModal: false,
      croppedAreaPixels: null,
      imageSrc: null,
      crop: { x: 0, y: 0 },
      rotation: 0,
      zoom: 1,
      croppedImage: null,
      isEditModalOpen: false,
      isPreviewModalOpen: false,
      profilePicture: null,
      loading: false,
      previousValues: {
        firstName:'',
        storeName: ''
      }
    };
    this.inputRef = createRef();
    this.formRef = createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.apiGetSellerInfoCallId) {
        this.handleGetSellerInfoResponse(responseJson);
      }
      if (apiRequestCallId === this.apiUpdateSellerProfileCallId) {
        this.handleSellerProfileUpdateResponse(responseJson);
      }
      if (apiRequestCallId === this.apiDeleteUserCallId) {
        this.handleDeleteUserResponse(responseJson);
      }
      if (apiRequestCallId === this.apiUploadImageId) {
        this.handleProfileUploadResponse(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  // Function to handle opening the Edit modal
  createImage = (url: string ) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", error => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  getRadianAngle(degreeValue: number) {
    return (degreeValue * Math.PI) / 180;
  }

  rotateSize(width: number, height: number, rotation: number) {
    const rotRad = this.getRadianAngle(rotation);
    return {
      width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
      height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
    };
  }

  async getCroppedImg(imageSrc: string, pixelCrop: Area, ctx: CanvasContext, canvas: {width: number, height: number}, rotation = 0, flip = { horizontal: false, vertical: false }): Promise<Blob | null>  {

    const image  = await this.createImage(imageSrc);
    if (!ctx) {
      return null;
    } else {
      const rotRad = this.getRadianAngle(rotation);

      // calculate bounding box of the rotated image
      const { width: bBoxWidth, height: bBoxHeight } = this.rotateSize((image as HTMLImageElement).width , (image as HTMLImageElement).height, rotation);

      // set canvas size to match the bounding box
      canvas.width = bBoxWidth;
      canvas.height = bBoxHeight;

      // translate canvas context to a central location to allow rotating and flipping around the center
      ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
      ctx.rotate(rotRad);
      ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
      ctx.translate(-(image as HTMLImageElement).width / 2, -(image as HTMLImageElement).height / 2);

      // draw rotated image
      ctx.drawImage(image as CanvasImageSource, 0, 0);

      const croppedCanvas = document.createElement("canvas");

      const croppedCtx = croppedCanvas.getContext("2d");

      if (!croppedCtx) {
        return null;
      }

      // Set the size of the cropped canvas
      croppedCanvas.width = pixelCrop.width;
      croppedCanvas.height = pixelCrop.height;

      // Draw the cropped image onto the new canvas
      croppedCtx.drawImage(canvas as CanvasImageSource, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, pixelCrop.width, pixelCrop.height);

      // As Base64 string
      // return croppedCanvas.toDataURL('image/jpeg');

      // As a blob
      return new Promise((resolve, reject) => {
        croppedCanvas.toBlob((file) => {
          resolve(file);
        }, "image/jpeg");
      });
    }
  }

  async getRotatedImage(imageSrc: string, rotation = 0) {
    const image:unknown  = this.createImage(imageSrc);
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const orientationChanged = rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;
    if (orientationChanged) {
      canvas.width = (image as HTMLImageElement).height;
      canvas.height = (image as HTMLImageElement).width;
    } else {
      canvas.width = (image as HTMLImageElement).width;
      canvas.height = (image as HTMLImageElement).height;
    }

    (ctx as CanvasRenderingContext2D).translate(canvas.width / 2, canvas.height / 2);
    (ctx as CanvasRenderingContext2D).rotate((rotation * Math.PI) / 180);
    (ctx as CanvasRenderingContext2D).drawImage(image as CanvasImageSource, -(image as HTMLImageElement).width / 2, -(image as HTMLImageElement).height / 2);

    return new Promise(resolve => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file as Blob | MediaSource));
      }, "image/png");
    });
  }
  handleEditClick = () => {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.click();
    }
  };

  // Function to handle closing the Edit modal
  handleEditModalClose = () => {
    this.setState({ isEditModalOpen: false, zoom: 1, crop: { x: 0, y: 0 }, rotation: 0 });
  };

  // Function to handle opening the Preview modal
  handlePreviewClick = async () => {
    await this.showCroppedImage();
    this.setState({ isPreviewModalOpen: true });
  };

  // Function to handle closing the Preview modal
  handlePreviewModalClose = () => {
    this.setState({ isPreviewModalOpen: false });
  };
  setZoom = (zoom: number) => {
    this.setState({ zoom });
  };
  setCroppedImage = (croppedImage: unknown) => {
    this.setState({ croppedImage });
  };
  setRotation = (rotation: number) => {
    this.setState({ rotation });
  };
  setCrop = (crop: { x: number; y: number; }) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea: unknown, croppedAreaPixels: Area) => {
    this.setState({ croppedAreaPixels });
  };

  getFileToBlob = (file: File) => {
    if (file) {
      // Convert the selected file to a Blob
      const blob = new Blob([file], { type: file.type });
      const url = URL.createObjectURL(blob);
      return url;
    }
  };

  showCroppedImage = async () => {
    try {
      const canvas: HTMLCanvasElement  = document.createElement("canvas");
      const ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");
      const croppedImage = await this.getCroppedImg(this.state.imageSrc as string, this.state.croppedAreaPixels as Area, ctx, canvas, this.state.rotation, { horizontal: false, vertical: false });
      this.setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  setProfilePicture = (image: string) => {
    const msg: Message = new Message(getName(MessageEnum.NavigationSellerNavigationBarMessage));
    msg.addData(getName(MessageEnum.sellerProfileImage), image);
    this.send(msg);

    this.handleEditModalClose();
    this.handlePreviewModalClose();
    this.setState({ profilePicture: image });
    setStorageData("profile_picture", image);
  };

  onSaveProfilePicture = async () => {
    this.setState({ loading: true });
    await this.showCroppedImage();
    this.onUploadImage(this.state.croppedImage);
  };

  onUploadImage = (image: unknown) => {
    const formData = new FormData();
    formData.append("profile_picture", image as Blob);

    const headers = { token: this.state.authToken };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiUploadImageId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiEndpointUploadProfileImage);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = async (event: ProgressEvent<FileReader>) => {
        let imageDataUrl:unknown = event.target?.result as string;
        try {
          // apply rotation if needed
          const orientation = await getOrientation(file);
          const rotation = ORIENTATION_TO_ANGLE[orientation];
          if (rotation) {
            imageDataUrl = await this.getRotatedImage(imageDataUrl as string, rotation);
          }
        } catch (e) {
          console.warn("failed to detect the orientation");
        }
        this.setState({ isEditModalOpen: true });
        // Use imageDataUrl as needed (e.g., set it in the state)
        this.setState({ imageSrc: imageDataUrl });
      };

      reader.readAsDataURL(file);
    }
  };

  handleProfileUploadResponse = (responseJson: unknown) => {
    this.setState({ loading: false });
    const successData = responseJson as ICheckUserInfoSuccessResponse;
    const errorData = responseJson as IErrorMessage;
    if ("data" in successData) {
      const image = successData.data.attributes.profile_picture;
      this.setProfilePicture(image);
      toast.success("Profile picture updated successfully!", {
        position: "top-right",
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
    if ("error" in errorData) {
      toast.error(errorData.error ? errorData.error : "Failed to update your profile picture. Please try again later!", {
        position: "top-right",
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  };
  handleDeleteUserResponse = async (responseJson: unknown) => {
    const successData = responseJson as IDeleteUserSuccessResponse;
    if ("message" in successData) {
      if (successData.message === "Account deleted successfully") {
        this.setState({ showVerifyDeleteModal: false });
        toast.success("Account deleted successfully!", {
          position: "top-right",
          autoClose: 3000, // milliseconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setStorageData("authToken", "null");
        setStorageData("seller_id", "null");
        setStorageData("seller_full_name", "null");
        setStorageData("seller_email", "null");
        setStorageData("full_phone_number", "null");
        setStorageData("profile_picture", "null");
        Cookies.remove("global_search");
        Cookies.remove("sellerToken");
        Cookies.remove("ba");
        Cookies.remove("accountId");
        Cookies.remove("setAddress");
        Cookies.remove("currentOrder");
        await removeStorageData("seller_documents_verified")
        await removeStorageData("isAccountActivated")
        await removeStorageData("isAccountCreated")
        await removeStorageData("isDocumentVerified")
        setTimeout(() => {
          const msg: Message = new Message(getName(MessageEnum.NavigationHomeScreenMessage));
          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(msg);
          this.props.navigation.navigate('FeaturesHeader');
        }, 1000)
        return;
      }
    }
    this.setState({ errorMessages: ["Failed to delete your account. Please try again later!"], showVerifyDeleteModal: false });
  };

  handleSellerProfileUpdateResponse = (responseJson: unknown) => {
    const successData = responseJson as ICheckUserInfoSuccessResponse;
    const errorData = responseJson as IErrorMessage;
    if ("data" in successData) {
      this.setState({showCurrentPassword: false, showNewPassword: false})
    }
    if ("message" in successData) {
      toast.success(successData.message, {
        position: "top-right",
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      return setTimeout(() => {
        return this.getSellerProfileInfo();
      }, 2000);
    }
    if ("error" in errorData) {
      toast.error(errorData.error ? errorData.error : "Failed to update your profile picture. Please try again later!", {
        position: "top-right",
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  };

  handleGetSellerInfoResponse = (responseJson: unknown) => {
    const successData = responseJson as ICheckUserInfoSuccessResponse;
    if ("data" in successData) {
      const { attributes } = successData.data;
      this.setState({
        email: attributes.email,
        full_name: attributes.full_name,
        company_or_store_name: attributes.company_or_store_name,
        errorMessages: [],
        previousValues: {
          firstName: attributes.full_name,
          storeName: attributes.company_or_store_name
        }
      });
      this.setProfilePicture(attributes.profile_picture);
      setStorageData("seller_email", attributes.email);
      setStorageData("seller_full_name", attributes.full_name);
      setStorageData("profile_picture", attributes.profile_picture);
      const msg: Message = new Message(getName(MessageEnum.NavigationSellerDashboardMessage));
      msg.addData(getName(MessageEnum.UpdatedSellerProfileInfo), { email: attributes.email, full_name: attributes.full_name });
      return this.send(msg);
    }
    this.setState({ errorMessages: ["Something went wrong. Please try again later!"] });
  };

  handleGetTokenAndSellerId = async () => {
    const authToken = await getStorageData("authToken");
    const seller_id = await getStorageData("seller_id");
    if (!authToken || !seller_id) {
      return;
    }
    this.setState({ authToken, seller_id });
    this.getSellerProfileInfo();
  };

  handleClickShowPassword = (inputType: string) => {
    const updateShowPassword = {
      currentPassword: () => this.setState({ showCurrentPassword: !this.state.showCurrentPassword }),
      newPassword: () => this.setState({ showNewPassword: !this.state.showNewPassword })
    };
    updateShowPassword[inputType as keyof IUpdateShowPassword]();
  };

  handleUpdateUserProfile = (values: IFormValues) => {
    this.setState({ errorMessages: [], successMsg: "" });
    this.formRef.current?.scrollIntoView({ behavior: "smooth" });
    this.updateSellerProfile(values);
  };

  handleOpenVerifyDeleteModal = () => this.setState({ showVerifyDeleteModal: !this.state.showVerifyDeleteModal });

  handleDeleteAccount = () => {
    this.deleteSellerAccount();
  };

  updateSellerProfile = (values: IFormValues) => {
    const formData = new FormData()
    const { previousValues } = this.state;
    let changesMade = false;

  const appendIfChanged = (key:string, newValue:string, prevValue:string) => {
    if (newValue !== prevValue) {
      formData.append(key, newValue);
      changesMade = true;
    }
  };

  appendIfChanged("data[attributes][full_name]", values.firstName, previousValues.firstName);
  appendIfChanged("data[attributes][company_or_store_name]", values.storeName, previousValues.storeName);
  if(values.currentPassword) {
    formData.append("data[attributes][current_password]", values.currentPassword)
    changesMade = true;
  }
  if(values.newPasswordForm) {
    formData.append("data[attributes][new_password]", values.newPasswordForm)
    changesMade = true;
  }

  if (!changesMade) {
    toast.error("No changes made to update.", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
    return;
  }

    const headers = { token: this.state.authToken };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiUpdateSellerProfileCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiGetSellerDocumentsStatusEndpoint + this.state.seller_id);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteSellerAccount = () => {
    const headers = { "Content-Type": configJSON.contentType, token: this.state.authToken };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiDeleteUserCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiGetSellerDocumentsStatusEndpoint + this.state.seller_id);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSellerProfileInfo = () => {
    const headers = { "Content-Type": configJSON.contentType, token: this.state.authToken };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetSellerInfoCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiGetSellerInfoEndpoint + this.state.seller_id);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount(): Promise<void> {
    this.handleGetTokenAndSellerId();
  }
  // Customizable Area End
}
