import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import { IStaticError, IStaticPageData } from "./Interface";
import { InputProps } from "@material-ui/core/Input";
import { createRef } from "react";
import { toast } from "react-toastify";
interface FormValues {
  title: string;
  email: string;
  description: string;
  image: File | null;
  radioGroup: string;
}
export const initialValues: FormValues = {
  title: "",
  email: "",
  description: "",
  image: null,
  radioGroup: "feedback",
};
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeCheck: string;
  details: string;
  firstName: string;
  lastName: string;
  eMail: string;
  isFetching: boolean;
  errMsg: string[];
  successMessage: string[];
  errormsg: string[];
  upload_file: any;
  FormKey: number;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class HomeContactUsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  inputRef: React.RefObject<InputProps & { click: Function }>;
  formRef: React.RefObject<HTMLFormElement>;
  apiSubmitContactUs: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.ReceiveMessage), getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      activeCheck: "feedback",
      details: "",
      firstName: "",
      lastName: "",
      eMail: "",
      isFetching: false,
      errMsg: [],
      successMessage: [],
      errormsg: [],
      upload_file: "",
      FormKey: 1,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.inputRef = createRef();
    this.formRef = createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.apiSubmitContactUs) {
        this.handleSubmitContactResponse(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  handleSubmitContactResponse = (responseJson: IStaticPageData | IStaticError) => {
    this.setState({ isFetching: false });
    this.formRef.current?.scrollIntoView({ behavior: "smooth" });
    if ("data" in responseJson) {
      this.setState({ FormKey: this.state.FormKey + 1 });
      toast.success("We have received you request! , Thank you!! for contacting us.", {
        position: "top-right",
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if ("errors" in responseJson) {
      const key = Object.keys(responseJson.errors[0])[0];
      toast.error(`${JSON.stringify(responseJson.errors?.[0]?.[key])}`, {
        position: "top-right",
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // return this.setState({ errMsg: [responseJson.errors[0][key]] });
    }
    if ("id" in responseJson) {
      return this.setState({ errMsg: [], successMessage: [`${this.state.activeCheck} has been submitted successfully!`] });
    }
    // return this.setState({ errMsg: ["Something went wrong. Please try again later!"], successMessage: [] });
  };

  handleOnCheckChange = (box: string) => {
    this.setState({ activeCheck: box });
  };

  handleInputChange = (inputVal: string, value: string) => {
    this.setState({ [inputVal]: value } as unknown as Pick<S, keyof S>);
  };

  handleSubmit = (values: any) => {
    this.setState({ errMsg: [], successMessage: [] });

    this.setState({ isFetching: true });

    const formData = new FormData();
    formData.append("title", values?.title);
    formData.append("description", values?.description);
    formData.append("email", values?.email);
    formData.append("contact_type", values?.radioGroup);
    if (values.image) {
      formData.append("image", values?.image);
    }

    const headers = {};
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiSubmitContactUs = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiContactUsEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleRemoveFile = () => {
    this.setState({ upload_file: null });
  };

  handleFileOpenInput = () => {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.click();
    }
  };

  getColorForFeedback() {
    if (this.state.activeCheck === "feedback") {
      return "#FF6008";
    } else {
      return "#33333A";
    }
  }

  getColorForQuery() {
    if (this.state.activeCheck === "query") {
      return "#FF6008";
    } else {
      return "#33333A";
    }
  }

  getColorForComplaint() {
    if (this.state.activeCheck === "complaint") {
      return "#FF6008";
    } else {
      return "#33333A";
    }
  }
  // Customizable Area End
}
