import React from "react";

// Customizable Area Start
import { theme } from "../../dashboard/src/CustomizedElementStyle";
import { ThemeProvider } from "@material-ui/core/styles";
import { Box, Container, Grid, Button, Divider, Hidden } from "@material-ui/core";

// Customizable Area End
//Customizable Area Start
import FeaturesLandingpageController, {Props} from "../../email-account-registration/src/FeaturesLandingpageController.web";
import DOMPurify from "dompurify";
import { objectsImg } from "./EmailAccountRegistrationController";
import SellerLandingNavbar from "./SellerLandingNavbar.web";
import SellerFooter from "./SellerFooter.web";
//Customizable Area End 

class Features extends FeaturesLandingpageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
       <SellerLandingNavbar navigation={this.props.navigation} id={this.props.navigation} />
        <ThemeProvider theme={theme}>
          <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Hidden smDown>
            <Box sx={webStyle.imgBoxOne}>
              <img src={objectsImg} width={"90%"} />
            </Box>
            </Hidden>
            <Container style={{ position: "relative", textAlign: "start", maxWidth: "96vw" }}>
            {this.state.featuresContent && 
            <>
              <Grid container style={{ marginTop: 160 }}>
              <Grid item lg={12} sm={12} xs={12}>
              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.featuresContent.content)}} data-test-id="featuresContent">
              </div>
              </Grid>
              </Grid>
            <Button data-test-id="partner" style={{...webStyle.becomePartner}} 
              onClick={this.handleBecomeAPartner}>
              {"Become a partner/seller"}
            </Button>
            </>
            } 
            </Container>
          </Box>
          <Divider />
          <SellerFooter navigation={this.props.navigation} id={this.props.id} />
        </ThemeProvider>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default Features;
const webStyle = {
  becomePartner: {
    margin:'10px 0 50px',
    padding: "10px 22px",
    borderRadius: "6px",
    background: "rgba(253, 97, 12, 1)",
    fontSize: 16,
    fontWeight: 600,
    color: "white",
    fontFamily: `"Lato",  sans-serif`,
    height: "max-content",
    width: "max-content", 
    textTransform: "none" as const,
    cursor: "pointer" 
  },
  imgBoxOne: { 
    position: "absolute" as const, 
    top: 176, 
    left: 25, 
    zIndex: 0 
  }
};
// Customizable Area End
