// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import { Message } from "framework/src/Message";
export const configJSON = require("./config");

export interface IContentRes {
  title: string,
  content: string,
  id: string
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  footerTitle: string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
    footerData: IContentRes,
    title: string
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellWithByEzzyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  staticPageApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      footerData: {
        id: '',
        title: '',
        content: ''
      },
      title:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const footerTitle = await getStorageData("footerTitle")
    this.setState({title: footerTitle})
    this.getSellWithByEzzyContent()
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) ;
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId === this.staticPageApiCallId) {
        this.handleContentResponse(responseJson);
      }
    }
  }

  handleContentResponse = async(responseJson:IContentRes[]) => {
    if(responseJson.length) {
        const content = responseJson.filter((item:IContentRes) => {
          console.log("Title", this.state.title)
            if(item.title === this.state.title) {
                return item
            }
        })
        if(content.length) this.setState({footerData : content[0]})
    }
  }

  getSellWithByEzzyContent = async() => {
    const headers = { token: await getStorageData("authToken"), "Content-Type": configJSON.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.staticPageApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.staticApiEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End

  // Customizable Area Start
  handleBackHomeBtn = () => {
    removeStorageData('footerTitle')
    this.props.navigation.navigate("FeaturesHeader")
  }
  // Customizable Area End
}
