// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON2 = require("../../catalogue/src/config.js");
export const configJSON = require("./config.js");
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  activeMenu:string;
  menu : (value: string) => void
  handleWarehouseMenu: () => void
  isPartnerCatalogActive: boolean
  isByEzzyActive: boolean
  isPartnerActive: boolean
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerSidebarController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  handleChangeMenu = (activeMenu: string) => {
    this.props.menu(activeMenu)
  };

  // Customizable Area End

}
