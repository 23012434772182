import React from "react";

// Customizable Area Start
import { OrangeButton } from "../../dashboard/src/CustomizedElementStyle";
import { Box } from "@material-ui/core";
// Customizable Area End

import CommonNavController, { Props, configJSON } from "./CommonNavController";
import { byezzylogo } from "./assets";

class CommonNav extends CommonNavController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <Box style={webStyle.top}>
          <img src={byezzylogo} style={webStyle.logoImg} />
          <OrangeButton data-test-id="back_home" onClick={this.handleBackToHome}>
            {configJSON.backToHomeText}
          </OrangeButton>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  logoImg: {
    width: "102px",
    height: "85px",
  },
  top: {
    width: "100%",
    height: 100,
    display: "flex",
    flexWrap: "wrap" as const,
    alignItems: "center",
    justifyContent: "space-between",
    maxHeight: 100,
  },
};

export default CommonNav;
// Customizable Area End
