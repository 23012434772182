import React from "react";

// Customizable Area Start
import { theme } from "../../dashboard/src/CustomizedElementStyle";
import { ThemeProvider } from "@material-ui/core/styles";
import { Box, Container, Divider, Grid, Hidden } from "@material-ui/core";
import PricingController, { Props } from "./PricingController.web";
import DOMPurify from "dompurify";
import { objectsImg } from "./EmailAccountRegistrationController";
import SellerLandingNavbar from "./SellerLandingNavbar.web";
import SellerFooter from "./SellerFooter.web";
// Customizable Area End

class Pricing extends PricingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  background = () => {
    return(
      <Hidden smDown>
        <Box sx={webStyle.backBox}>
          <img src={objectsImg} width={"90%"} />
        </Box>
      </Hidden>
    )
  }
  // Customizable Area End

  // Customizable Area Start
  render() {
    // Customizable Area Start
    return (
      <>
        <SellerLandingNavbar navigation={this.props.navigation} id={this.props.id} />
        <ThemeProvider theme={theme}>
          <Box style={{  display: "flex", width: "100%",justifyContent: "center" }}>
            {this.background()}
            <Container style={{ position: "relative", textAlign: "start", maxWidth: "96vw" }}>
                {this.state.pricingContent &&
                <Grid container style={{ marginTop: 160}} spacing={3}>
                <Grid style={{marginBottom:'60px'}} item lg={12} sm={12} xs={12} >
                  <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.pricingContent.content)}} data-test-id="pricingContent">
                  </div>
                  </Grid>
              </Grid>
                }
            </Container>
          </Box>
          <Divider />
          <SellerFooter navigation={this.props.navigation} id={this.props.id} />
        </ThemeProvider>
      </>
    );
    // Customizable Area End
  }
  // Customizable Area End
}

// Customizable Area Start
export default Pricing;
const webStyle = {
  backBox: { 
    position: "absolute" as const, 
    top: 176, 
    left: 25, 
    zIndex: 0 
  }
};
// Customizable Area End
