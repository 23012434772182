// Customizable Area Start
import { withStyles } from "@material-ui/styles";
import React from "react";
import FBBDamagesAndReturnsController, {
    Props,
    configJSON,
} from "./FBBDamagesAndReturnsController.web";
// Customizable Area End
import {
    Box,
    Button,
    Grid,
    InputAdornment,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography,
} from "@material-ui/core";
import { mobileImg } from "./assets";
import { CustomizeInputSearchBox, SearchIconSVG } from "../../dashboard/src/CustomizedElementStyle";

export function StockTabPanelDamage(props: any) {
    const { value, index, children, ...other } = props;

    return (
        <div
            hidden={value !== index}
            role="tabpanel"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }} id="Dreturn">
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function tabPropsDamage(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

interface DamagedTableHeadProps {
    numSelected?: number;
    onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    orderBy?: string;
    rowCount?: number;
    classes: any;
}

interface ReturnsTableHeadProps {
    numSelected?: number;
    onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    orderBy?: string;
    rowCount?: number;
    classes: any;
}

export function DamagedTableHead(props: DamagedTableHeadProps) {
    const { onSelectAllClick, numSelected, rowCount, classes } = props;

    return (
        <TableHead className={classes.tableHeader}>
            <TableRow className={classes.tableHeader}>
                <TableCell className={classes.tableHeaderText}>
                    <div className={classes.flex} >
                        <input
                        data-test-id="damageCheckbox"
                            className={classes.checkBox}
                            type="checkbox"
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                        <span>{configJSON.requestDateLabel}</span>
                    </div>
                </TableCell>
                <TableCell className={classes.tableHeaderText}>{configJSON.requestDetailsLabel}</TableCell>
                <TableCell className={classes.tableHeaderText}> {configJSON.imageLabel}</TableCell>
                <TableCell className={classes.tableHeaderText}>{configJSON.productNameLabel}</TableCell>
                <TableCell style={webStyle.center} className={classes.tableHeaderText}>{configJSON.noOfUnitsLabel}</TableCell>
                <TableCell className={classes.tableHeaderText}>{configJSON.reasonOfDamageLabel}</TableCell>
                <TableCell className={classes.action}>{configJSON.actionLabel}</TableCell>
            </TableRow>
        </TableHead>
    );
}

 export function ReturnsTableHead(props: ReturnsTableHeadProps) {
    const { onSelectAllClick, numSelected, rowCount, classes } = props;

    return (
        <TableHead style={{ background: 'rgba(123,123,123,0.07)' }}>
            <TableRow>
                <TableCell className={classes.tableHeaderText}>
                    <div className={classes.flex}>
                        <input
                        data-test-id="returnsCheckbox"
                            className={classes.checkBox}
                            type="checkbox"
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                        <span>{configJSON.stockIntakeDateLabel}</span>
                    </div>
                </TableCell>
                <TableCell className={classes.tableHeaderText}>{configJSON.requestDetailsLabel}</TableCell>
                <TableCell className={classes.tableHeaderText}> {configJSON.imageLabel}</TableCell>
                <TableCell className={classes.tableHeaderText}>{configJSON.productNameLabel}</TableCell>
                <TableCell className={classes.centerOne}>{configJSON.stockQtyLabel}</TableCell>
                <TableCell className={classes.centerTwo}>{configJSON.stockConditionLabel}</TableCell>
                <TableCell className={classes.action}>{configJSON.actionLabel}</TableCell>
            </TableRow>
        </TableHead>
    );
}
// Customizable Area End

class FBBDamagesAndReturns extends FBBDamagesAndReturnsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { classes } = this.props;
        return (
          <Box className={classes.overview}>
            <Box className={classes.byezzyWrap}>
              <Box className={classes.byezzy}>{configJSON.fulFilledByEzzyText}</Box>
            </Box>
            <Grid container style={webStyle.headerContainerDamage}>
              <Grid item xs={6}>
                <Typography
                  className={classes.text}
                >
                  {configJSON.damageAndReturnLabel}
                </Typography>
              </Grid>
            </Grid>
            <Tabs
              data-test-id="tabs"
              value={this.state.tabValue}
              onChange={this.handleTabChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: webStyle.tabProp,
              }}
            >
              <Tab
                label={configJSON.damagesLabel}
                className={classes.tabsLabel}
                style={{
                  textTransform: "capitalize",
                  backgroundColor: this.setDamageTabBackgrnd(0)
                }}
                {...tabPropsDamage(0)}
              />
              <Tab
                label={configJSON.returnsLabel}
                className={classes.tabsLabel}
                style={{
                  textTransform: "capitalize",
                  backgroundColor:this.setDamageTabBackgrnd(1)
                }}
                {...tabPropsDamage(1)}
              />
            </Tabs>
            <StockTabPanelDamage
              value={this.state.tabValue}
              index={0}
              style={webStyle.backgroundColor}
            >
              <Grid container className={classes.storageHeaderDamage}>
                <Grid item xs={6}>
                  <Typography
                    data-test-id="request"
                    className={classes.text}
                  >
                    {configJSON.damagesRequestText}
                  </Typography>
                </Grid>
                <Grid container>
                <Box className={classes.searchContainer}>
              <Typography className={classes.searchDamagesText}>
                {configJSON.searchRequestText}
              </Typography>
              </Box>
              <Grid container spacing={2}>
              <Grid item
              xs={12}
              lg={7}
              md={7}
              sm={12} className={classes.searchDamages}>
                <CustomizeInputSearchBox
                data-test-id="searchRequestOne"
                  id="outlined-start-adornment"
                  className={classes.searchInput}
                  value={this.state.searchRequestId}
                  placeholder={configJSON.damagesRequestPlaceholder}
                  onChange={this.handleSearchRequestId}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={webStyle.rightMargin}
                      ></InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={webStyle.rightPadding}
                      >
                        <SearchIconSVG />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid
                  item
                  xs={12}
                  lg={5}
                  md={5}
                  sm={12}
                  style={webStyle.optionsDWrapperDamage}
                >
                  <Box sx={webStyle.optionsD} style={webStyle.rightMargin}>
                  {configJSON.disposeAllText}
                  </Box>
                  <Box sx={webStyle.optionsD}>{configJSON.returnAllText}</Box>
              </Grid>
            </Grid>
                </Grid>
              </Grid>

              <Box style={webStyle.topPadding} id="boX2">
                <TableContainer
                  style={webStyle.tableContainer}
                  data-test-id="tasP"
                >
                  <Table aria-label="simple table" className={classes.table} stickyHeader>
                    <DamagedTableHead
                      data-test-id="tableHeader"
                      classes={classes}
                      numSelected={this.state.selectedReq.length}
                      onSelectAllClick={this.handleSelectAllDamageRequest}
                      rowCount={
                        this.state.damageList && this.state.damageList.length
                      }
                    />
                    <TableBody style={webStyle.layoutDamage}>
                      {this.state.damageList.length > 0 &&
                        this.state.damageList.map((item: any) => {
                          const isItemSelected = this.isSelectedReq(item.id);
                          return (
                            <TableRow className={classes.tableRowContainer} key={item.id}>
                              <TableCell
                                component="th"
                                scope="row"
                                style={webStyle.tableCellContentDamage}
                                data-test-id="one"
                              >
                                <div>
                                  <input
                                    style={webStyle.rightMargin}
                                    type="checkbox"
                                    id={`checkBox1-${item.id}`}
                                    onChange={() =>
                                      this.handleClickDamageRow(item.id)
                                    }
                                    checked={isItemSelected}
                                    data-test-id="twoone"
                                  />
                                </div>
                                <div style={webStyle.dateWrapperD}>
                                  <Box style={webStyle.day}  data-test-id="2daysago">{item.attributes.days}</Box>
                                  <Box style={{...webStyle.buyer, width:'90px'}}  data-test-id="oneBox">
                                    {item.attributes.date}
                                  </Box>
                                </div>
                              </TableCell>
                              <TableCell style={webStyle.tableRowHeaderText}  data-test-id="headteText">
                                <Box className={classes.orderId}  data-test-id="wrapperId">
                                  <Typography style={webStyle.buyer}  data-test-id="buyerId">
                                    {configJSON.orderId}
                                  </Typography>
                                  <Typography style={webStyle.buyerName}  data-test-id="buyerNme">
                                    {item.attributes.order_id}
                                  </Typography>
                                </Box>
                                <Box style={webStyle.detailsWrapperd}  data-test-id="oneFullFil">
                                  <Typography style={webStyle.buyer}  data-test-id="one:j">
                                    {configJSON.fullFillmentLabel}
                                  </Typography>
                                  <Typography style={webStyle.buyerName}  data-test-id="Byezzy">
                                    {configJSON.byEzzyText}
                                  </Typography>
                                </Box>
                              </TableCell>

                              <TableCell style={webStyle.tableRowHeaderText} data-test-id="oneThree">
                                <img
                                  src={mobileImg}
                                  style={webStyle.prodImage}
                                  alt="img"
                                  data-test-id="Img"
                                />
                              </TableCell>

                              <TableCell style={webStyle.productWrapper} >
                                <Box className={classes.prodName} data-test-id="oneUram">
                                  {item.attributes.product_name}
                                </Box>  
                                <Box>
                                  <Typography style={webStyle.buyer}  data-test-id="oSTrage">
                                    ({item.attributes.brand})
                                  </Typography>
                                </Box>
                                <Box style={webStyle.detailsWrapperd}  data-test-id="mahi">
                                  <Typography style={webStyle.buyer}>
                                    {configJSON.pskuLabel}
                                  </Typography>
                                  <Typography style={webStyle.buyerName}>
                                    {item.attributes.psku}
                                  </Typography>
                                </Box>
                                <Box style={webStyle.detailsWrapperd}>
                                  <Typography style={webStyle.buyer}>
                                    {configJSON.unitPriceInAED}
                                  </Typography>
                                  <Typography style={webStyle.buyerName}>
                                    {item.attributes.price}
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell style={webStyle.unit}>
                                <Box style={webStyle.customerOption}>
                                  {item.attributes.unit}
                                </Box>
                              </TableCell>
                              <TableCell style={webStyle.description}>
                                  <Typography style={webStyle.buyerName}>
                                    {item.attributes.damage_description}
                                  </Typography>
                              </TableCell>
                              <TableCell className={classes.btnWrap}>
                              <Button className={classes.returnOption}>
                                {configJSON.disposeText}
                              </Button>
                              <Button className={classes.returnOption}>
                                {configJSON.returnText}
                              </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </StockTabPanelDamage>
            <StockTabPanelDamage
              value={this.state.tabValue}
              index={1}
              style={webStyle.backgroundColor}
            >
              <Grid container className={classes.storageHeaderDamage}>
                <Grid item xs={6}>
                  <Typography data-test-id="returnText"
                    className={classes.text}
                  >
                    {configJSON.returnRequestText}
                  </Typography>
                </Grid>
                <Grid container>
                <Box className={classes.searchContainer}>
              <Typography className={classes.searchDamagesText}>
                {configJSON.searchRequestText}
              </Typography>
              </Box>
              <Grid container>
              <Grid item
              xs={12}
              lg={7}
              md={7}
              sm={12} className={classes.searchDamages}>
                <CustomizeInputSearchBox
                data-test-id="searchRequestTwo"
                  id="outlined-start-adornment"
                  className={classes.searchInput}
                  value={this.state.searchReturnReqId}
                  placeholder={configJSON.damagesRequestPlaceholder}
                  onChange={this.handleSearchReturnReqId}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={webStyle.rightMargin}
                      ></InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={webStyle.rightPadding}
                      >
                        <SearchIconSVG />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid
                  item
                  xs={12}
                  lg={5}
                  md={5}
                  sm={12}
                  style={webStyle.optionsDWrapperDamage}
                >
                  <Box sx={webStyle.optionsD}>{configJSON.returnAllText}</Box>
                </Grid>
            </Grid>
                </Grid>
              </Grid>

              <Box style={webStyle.topPadding}>
                <TableContainer style={webStyle.tableContainer}>
                  <Table aria-label="simple table" className={classes.table}>
                    <ReturnsTableHead
                    data-test-id="tableHeader2"
                      classes={classes}
                      numSelected={this.state.selectedReturnReq.length}
                      onSelectAllClick={this.handleSelectAllReturnReq}
                      rowCount={
                        this.state.requestsList && this.state.requestsList.length
                      }
                    />
                    <TableBody style={webStyle.layoutDamage}>
                      {this.state.requestsList.length > 0 &&
                        this.state.requestsList.map((item: any) => {
                          const isItemSelected = this.isSelectedReturnReq(item.id);
                          return (
                            <TableRow className={classes.tableRowContainer} key={item.id}>
                              <TableCell
                                component="th"
                                scope="row"
                                style={webStyle.tableCellContentReturn}
                              >
                                <div>
                                  <input
                                    style={webStyle.rightMargin}
                                    type="checkbox"
                                    id={`checkBox2-${item.id}`}
                                    onChange={() =>
                                      this.handleClickReturnRow(item.id)
                                    }
                                    checked={isItemSelected}
                                    data-test-id="onere"
                                  />
                                </div>
                                <div style={webStyle.dateWrapperD}>
                                  <Box style={webStyle.day}>{item.attributes.days}</Box>
                                  <Box style={{...webStyle.buyer, width:'90px'}} data-test-id="Call">
                                    {item.attributes.date}
                                  </Box>
                                </div>
                              </TableCell>
                              <TableCell style={webStyle.tableRowHeaderText} data-test-id="Idandme">
                              <Box className={classes.orderId}  data-test-id="wrapperId">
                                  <Typography style={webStyle.buyer}  data-test-id="buyerId">
                                    {configJSON.stockIntakeId}
                                  </Typography>
                                  <Typography style={webStyle.buyerName}  data-test-id="buyerNme">
                                    {item.attributes.request_id}
                                  </Typography>
                                </Box>
                                <Box style={webStyle.detailsWrapperd}>
                                  <Typography style={webStyle.buyer} data-test-id="optionByzzy">
                                    {configJSON.fullFillmentLabel}
                                  </Typography>
                                  <Typography style={webStyle.buyerName} data-test-id="optionTwoName">
                                    {configJSON.byEzzyText}
                                  </Typography>
                                </Box>
                              </TableCell>

                              <TableCell style={webStyle.tableRowHeaderText}>
                                <img
                                  src={mobileImg}
                                  style={webStyle.prodImage}
                                  alt="img"
                                />
                              </TableCell>

                              <TableCell style={webStyle.productWrapper}>
                                <Box className={classes.prodName}>
                                  {item.attributes.product_name}
                                  </Box>
                                  <Box>
                                  <Typography style={webStyle.buyer} data-test-id="AEDU">
                                    ({item.attributes.brand})
                                  </Typography>
                                </Box>
                                <Box style={webStyle.detailsWrapperd}>
                                  <Typography style={webStyle.buyer} data-test-id="AEDET">
                                    {configJSON.pskuLabel}
                                  </Typography>
                                  <Typography style={webStyle.buyerName} data-test-id="AEDR">
                                    {item.attributes.psku}
                                  </Typography>
                                </Box>
                                <Box style={webStyle.detailsWrapperd}>
                                  <Typography style={webStyle.buyer} data-test-id="AED">
                                    {configJSON.unitPriceInAED}
                                  </Typography>
                                  <Typography style={webStyle.buyerName}>
                                    {item.attributes.price}
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Box style={webStyle.customerOption}>
                                  {item.attributes.unit}
                                </Box>
                              </TableCell>
                              <TableCell align="center">
                                <Box style={webStyle.stockCondition}>
                                  {item.attributes.stock_condition}
                                </Box>
                              </TableCell>
                              <TableCell>
                              <Button className={classes.returnOption}>
                                {configJSON.returnText}
                              </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </StockTabPanelDamage>
          </Box>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
const styles = {
    tabsLabel: {
        textTransform: "capitalize" as const,
        fontSize: "14px",
        fontWeight: 700 as const,
        color: "#727272",
    },
    storageHeaderDamage: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom:'20px'
    },
    byezzyWrap : {
      display: "flex", 
      justifyContent: "space-between", 
      alignItems: "center"
    },
    byezzy : {
      fontFamily: `"Lato", sans-serif` as const,
      padding: "20px 0px 15px 0px", 
      fontSize: "18px", 
      fontWeight: 400 as const
    },
    text: {
      fontSize: "22px",
      fontWeight: 700,
      fontFamily: `"Lato", sans-serif`,
    },
    overview: { 
      overflowY: "scroll" as const
    },
    topMargin: {
      marginTop: '20px'
    },
    searchContainer: {
      padding: "24px 0px 6px 0px",
      "@media screen and (max-width:460px)": {
        width: "100%",
      },
    },
    searchDamagesText: {
      color: "#33333A",
      fontSize: "12px",
      fontWeight: 400 as const,
      fontFamily: `"Lato", sans-serif` as const,
      marginBottom:'4px'
    },
    searchDamages: {
      position: "relative" as const,
    },
    searchInput: {
      "& .MuiInputBase-input::placeholder": {
        fontFamily: `"Avenir-Medium", sans-serif !important` as const,
        color: '#333333 !important',
        fontSize:'14px !important',
        opacity: 'initial'
      }
    },
    flex: {
      display:'flex'
    },
    tableHeaderText: {
      fontFamily: `"Lato", sans-serif` as const,
      fontSize: '14px',
      textAlign: 'left' as const,
      fontWeight: 700 as const,
      color: '#333',
      padding:'16px 10px',
      '&.MuiTableCell-stickyHeader': {
      }
  },
  checkBox: {
    marginRight: '20px'
  },
  tableRowContainer: {
    verticalAlign:'top' as const,
    borderBottom:"1px solid rgba(224,224,224,1)"
  },
  prodName: {
    fontSize:'14px',
    fontFamily: `"Lato", sans-serif` as const,
    fontWeight: 400
  },
  orderId: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'flex-start',
  },
  table: {
    borderCollapse: "collapse" as const
  },
  returnOption : {
    padding: "5px 20px", 
    color: "#657A8E", 
    fontFamily: `"Lato", sans-serif` as const, 
    fontSize: 14, 
    fontWeight: 500 as const, 
    border: '0px solid rgba(101, 122, 142, 0.320241)',
    backgroundColor: 'rgb(242,243,244)', 
    borderRadius: 5,
    display:'flex', 
    alignItems:'center',
    justifyContent:'center', 
    borderBottom:'0',
    cursor: 'pointer',
    textTransform: 'capitalize' as const,
    width:'74px'
  },
  centerOne: {
    width:'100px',
    textAlign: "center" as const,
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: '14px',
    fontWeight: 700 as const,
    color: '#333',
    padding:'16px 10px',
    '&.MuiTableCell-stickyHeader': {
    }
  },
  centerTwo: {
    width:'180px',
    textAlign: "center" as const,
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: '14px',
    fontWeight: 700 as const,
    color: '#333',
    padding:'16px 10px',
    '&.MuiTableCell-stickyHeader': {
    }
  },
  action: {
    '&.MuiTableCell-stickyHeader': {
    },
    textAlign: "center" as const,
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: '14px',
    fontWeight: 700 as const,
    color: '#333',
    padding:'16px 10px',
  },
  btnWrap: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: "center",
    justifyContent: "flex-start",
    borderBottom: "none",
    gap: "12px"
  }
};

const webStyle = {
  center: {
    textAlign:"center" as const
  },
    rightMargin: { 
      marginRight: "20px" 
    },
    rightPadding: { 
      paddingRight: "20px" 
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
    },
    textWrapper: {
        width: "100%",
        marginBottom: 10,
    },

    optionsDWrapperDamage: {
        display: "flex",
        flexDirection: "row" as const,
        justifyContent: 'flex-end' as const
    },
    optionsD: {
        padding: "7px 23px",
        color: "#657A8E",
        fontFamily: `"Lato", sans-serif` as const,
        fontSize: 16,
        fontWeight: 500 as const,
        border: "1px solid rgba(101, 122, 142, 0.320241)",
        borderRadius: 5,
        backgroundColor: "rgb(242,243,244)",
    },
    dateWrapperD: {
        display: 'block',
        alignItems: 'flex-start'
    },
    tableContainer: {
        border: '0.5px solid #ccc',
        borderRadius: '8px'
    },
    tableCellContentDamage: {
        display: 'flex',
        alignItems: 'flex-start',
        borderBottom: '0',
        padding: "16px 10px",
        width:'130px'
    },
    day: {
        fontWeight: 700 as const,
        color: '#000',
        fontSize: '14px',
        marginBottom: '8px'
    },
    buyer: {
        fontSize: '14px',
        color: '#697A8D',
        fontWeight: 400 as const
    },
    buyerName: {
        fontSize: '14px',
        color: '#000',
        fontWeight: 400 as const
    },
    detailsWrapperd: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'flex-start',
        marginTop: '10px'
    },
    tableRowHeaderText: {
        color: 'rgb(101, 122, 142)',
        fontFamily: `"Lato", sans-serif` as const,
        fontSize: '12px',
        textAlign: 'left' as const,
        padding:'16px 10px'
    },
    customerOption: {
        fontWeight: 700 as const,
        color: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: '0',
        fontSize: '14px'
    },
    viewOption: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: "5px 23px",
        color: "#657A8E",
        fontFamily: `"Lato", sans-serif`,
        fontSize: 14,
        fontWeight: 500,
        border: '0px solid rgba(101, 122, 142, 0.320241)',
        backgroundColor: 'rgb(242,243,244)',
        borderRadius: 5,
        display: 'flex',
        borderBottom: '0',
    },
    prodImage: {
        height: '80px',
        width: '80px',
        objectFit: 'fill' as const,
        borderRadius: '8px'
    },
    pendingOrderStatus: {
        padding: "4px 16px",
        color: "#657A8E",
        fontFamily: `"Lato", sans-serif` as const,
        fontSize: 14,
        fontWeight: 500 as const,
        border: '0px solid rgba(101, 122, 142, 0.320241)',
        backgroundColor: 'rgb(242,243,244)',
        borderRadius: 5,
        borderBottom: '0',
        textAlign: 'center' as const
    },
    completedOrderStatus: {
        padding: "4px 23px",
        color: "#60c32b",
        fontFamily: `"Lato", sans-serif` as const,
        fontSize: 14,
        fontWeight: 500 as const,
        border: '0px solid #ec9e31',
        backgroundColor: '#d4f4c3',
        borderRadius: 5,
        display: 'flex',
        alignItems: 'flex-start',
        borderBottom: '0',
    },
    btnWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: "5px 23px",
        color: "#657A8E",
        fontFamily: `"Lato", sans-serif`,
        fontSize: 14,
        fontWeight: 500,
        border: '0px solid rgba(101, 122, 142, 0.320241)',
        backgroundColor: 'rgb(242,243,244)',
        borderRadius: 5,
        display: 'flex',
        borderBottom: '0',
    },
    tabs: {
        paddingLeft: "30px"
    },
    ordersWrapper: {
        backgroundColor: 'rgb(242,243,244)',
        padding: '0 0 15px 0'
    },
    pendingOrdersWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: 'white'
    },
    ordersInfo: {
        padding: "0px 0px 0px 10px",
        fontSize: "18px",
        fontWeight: 400,
        fontFamily: `"Lato", sans-serif` as const
    },
    tabsLabel: {
        textTransform: 'capitalize' as const,
        fontSize: '14px',
        fontWeight: 700 as const,
        color: '#727272'
    },
    layoutDamage: {
        backgroundColor: 'white'
    },

    tableHead: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    cancelledOrderStatus: {
        padding: "4px 23px",
        color: "red",
        fontFamily: `"Lato", sans-serif` as const,
        fontSize: 14,
        fontWeight: 500 as const,
        border: '0px solid #ec9e31',
        backgroundColor: '#FCE4E4',
        borderRadius: 5,
        display: 'flex',
        alignItems: 'flex-start',
        borderBottom: '0',
    },

    headerContainerDamage: {
        borderRadius: "8px",
        display: "flex",
        flexDirection: "row" as const,
        padding: "30px 30px 50px",
        background: "white",
    },
    productCardWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    productDetails: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flexStart',
        gap: '10px',
        width: '100%',
        padding: '1rem',
    },
    cardProdImage: {
        height: '90px',
        width: '90px',
        borderRadius: '8px',
    },
    orderTextWrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "left" as const,
        marginBottom: 10
    },
    ordersText: {
        fontSize: "24px",
        fontWeight: 700 as const,
        fontFamily: `"Lato", sans-serif` as const
    },
    text: {
        fontSize: "14px",
        fontWeight: 500 as const,
        fontFamily: `"Lato", sans-serif` as const,
        color: '#747474'
    },
    stockBtn: {
        marginTop: '10px',
        fontSize: '16px',
        fontFamily: `"Lato", sans-serif` as const,
        fontWeight: 500,
        textTransform: 'capitalize' as const,
        padding: '16px 24px',
        backgroundColor: '#0fbc9e',
        borderRadius: '8px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: 'white',
            color: '#0fbc9e',
            border: '0.5px solid #0fbc9e'
        }
    },
    tabProp: {
      backgroundColor: "rgb(255,96,8)",
      top: 0
    },
    backgroundColor: {
      backgroundColor: '#fff'
    },
    topPadding: {
      paddingTop:'20px'
    },
    productWrapper: {
      color: 'rgb(101, 122, 142)',
      fontFamily: `"Lato", sans-serif` as const,
      fontSize: '12px',
      textAlign: 'left' as const,
      padding:'16px 10px',
      width:'150px'
    },
    tableCellContentReturn: {
      display: 'flex',
      alignItems: 'flex-start',
      borderBottom: '0',
      padding: "16px 10px",
      width:'160px'
    },
    stockCondition: {
      fontWeight: 700 as const,
      color: '#000',
      display: 'flex',
      alignItems: 'center',
      justifyContent: "center",
      borderBottom: '0',
      fontSize: '14px'
    },
    description: {
      width:'160px',
      padding:'16px 10px'
    },
    unit: {
      padding:'16px 10px 16px 20px',
      marginLeft : '10px'
    }
};

export { FBBDamagesAndReturns };
export default withStyles(styles)(FBBDamagesAndReturns);
// Customizable Area End
