import React from "react";

// Customizable Area Start
import { Box, Typography, Modal, IconButton, InputLabel, Input, InputAdornment, Container } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme, CustomTextField, CustomFormControl, CustomSubmitButton, OtpInput, VisibilityOffSvg, ClickableBox } from "./CustomElementStyling";
import ClearIcon from "@material-ui/icons/Clear";
import Visibility from "@material-ui/icons/Visibility";
import { errorImage } from "./assets";
import { CustomSnackbar } from "../../../components/src/CustomCheckBox.web";

// Customizable Area End

import LandingPageController, { Props } from "./SellerLoginPageController";

export default class SellerLandingPageWeb extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  CloseModal = (testIdRef: string) => {
    return (
      <Box sx={webStyle.closeButton}>
        <IconButton onClick={this.handleCloseModal} data-test-id={testIdRef}>
          <ClearIcon style={{ color: "#3E3E3E" }} />
        </IconButton>
      </Box>
    );
  };
  ShowError = () => {
    return (
      <>
        {this.state.errorMessages.map((item, index) => {
          return (
            <Box sx={webStyle.errorMsgContainer} key={index}>
              <Box sx={{ marginRight: "5px" }}>
                <img src={errorImage} />
              </Box>
              <Box sx={{ fontSize: 14, color: "#D74D4D", fontFamily: `"Lato", sans-serif`, fontWeight: 700 }}>{item}</Box>
            </Box>
          );
        })}
      </>
    );
  };
  EmailLoginModal = () => {
    return (
      <Modal open={this.state.showEmailLoginModal} hideBackdrop={true}>
        <Box sx={webStyle.mainContainer}>
          <Box sx={webStyle.blurredBackground}></Box>
          <Box sx={webStyle.modalContainer}>
            {this.CloseModal("closeEmailLoginButton")}
            <Typography variant="h1">Sign in to your account</Typography>
            <Box sx={webStyle.subHeaderBox}>
              <Typography style={{ marginRight: "5px",color:"#626161",fontWeight:"bold",fontFamily: `"Lato", sans-serif`}} variant="h2">
                Don't have an account?
              </Typography>
              <ClickableBox data-test-id="goToSignUpPageRef" onClick={this.handleGoToSignUpPage}>
                <Typography variant="h3" style={{fontWeight:"bold",fontFamily: `"Lato", sans-serif`}}>Sign Up</Typography>
              </ClickableBox>
            </Box>
            <CustomTextField data-test-id="emailInputRef" onKeyDown={this.handleKeyDown} label="Email" fullWidth style={{ marginTop: "40px" }} value={this.state.email} onChange={(event) => this.handleEmailOnChange(event.target.value)} />
            <CustomFormControl>
              <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
              <Input
                id="standard-adornment-password"
                data-test-id="passwordInputRef"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.password}
                onChange={(event) => {
                  this.handlePasswordOnChange(event.target.value);
                }}
                onKeyDown={this.handleKeyDown}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton data-test-id="showPasswordRef" aria-label="toggle password visibility" onClick={this.handleClickShowPassword}>
                      {this.state.showPassword ? <Visibility className="rotateIcon" /> : <VisibilityOffSvg />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </CustomFormControl>
            <Box sx={webStyle.forgotPwBox}>
              <ClickableBox data-test-id="forgotPasswordRef" onClick={() => this.handleOpenForgotPWModal()}>
                <Typography variant="h4">Forgot Password ?</Typography>
              </ClickableBox>
            </Box>
            <Box sx={{ height: "65px" }}>{this.ShowError()}</Box>
            <CustomSubmitButton data-test-id="signInButtonRef" onClick={this.handleOnSignIn} disabled={this.state.loggingIn}>
              Sign In
            </CustomSubmitButton>
          </Box>
        </Box>
      </Modal>
    );
  };
  EmailVerifModal = () => {
    return (
      <Modal open={this.state.showEmailVerifModal} hideBackdrop={true}>
        <Box sx={webStyle.mainContainer}>
        <CustomSnackbar message={'OTP has been resent successfully.'} open={this.state.isResendOtpMSG}/>

          <Box sx={webStyle.blurredBackground}></Box>
          <Box sx={webStyle.modalContainer}>
            {this.CloseModal("closeEmailVerifButton")}
            <Typography variant="h1">Code Verification</Typography>
            <Box sx={webStyle.subHeaderBox}>
              <Typography style={{ marginRight: "5px",fontFamily: `"Lato", sans-serif`}} variant="h2">
                Please enter 5 digit code sent to your phone number {`+${this.state.country_code}-${this.state.phone_number.slice(0, 4)} ${this.state.phone_number.slice(4)}`}
              </Typography>
            </Box>
            <Box sx={webStyle.inputContainer}>
              <Box>
                <OtpInput data-test-id="inputOtp1" placeholder="-" value={this.state.inputValues[0]} ref={this.inputRefs[0]} inputProps={{ maxLength: 1 }} onChange={(event) => this.handleInputOnChange(0, event)} onKeyDown={(event) => this.handleInputOnPress(0, event)} />
              </Box>
              <Box>
                <OtpInput data-test-id="inputOtp2" placeholder="-" value={this.state.inputValues[1]} ref={this.inputRefs[1]} inputProps={{ maxLength: 1 }} onChange={(event) => this.handleInputOnChange(1, event)} onKeyDown={(event) => this.handleInputOnPress(1, event)} />
              </Box>
              <Box>
                <OtpInput data-test-id="inputOtp3" placeholder="-" value={this.state.inputValues[2]} ref={this.inputRefs[2]} inputProps={{ maxLength: 1 }} onChange={(event) => this.handleInputOnChange(2, event)} onKeyDown={(event) => this.handleInputOnPress(2, event)} />
              </Box>
              <Box>
                <OtpInput data-test-id="inputOtp4" placeholder="-" value={this.state.inputValues[3]} ref={this.inputRefs[3]} inputProps={{ maxLength: 1 }} onChange={(event) => this.handleInputOnChange(3, event)} onKeyDown={(event) => this.handleInputOnPress(3, event)} />
              </Box>
              <Box>
                <OtpInput id="okay" data-test-id="inputOtp5" placeholder="-" value={this.state.inputValues[4]} ref={this.inputRefs[4]} inputProps={{ maxLength: 1 }} onChange={(event) => this.handleInputOnChange(4, event)} onKeyDown={(event) => this.handleInputOnPress(4, event)} />
              </Box>
            </Box>
            <Box sx={webStyle.codeVerifyTimer}>
              <Typography variant="h5">
                {this.state.minutes}:{this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds} min
              </Typography>
              <Box sx={webStyle.didNotGetOtpBox}>
                <Typography style={{ marginRight: "5px" }} variant="h6">
                  Did not get OTP ?
                </Typography>
                <ClickableBox data-test-id="resendOtpRef" onClick={() => this.handleResendOtp()}>
                  <Typography variant="h3">Resend now</Typography>
                </ClickableBox>
              </Box>
            </Box>
            <Box sx={{ height: "120px" }}>{this.ShowError()}</Box>
            <CustomSubmitButton data-test-id="submitOtpRef" onClick={this.handleSubmitOtp}>
              Submit
            </CustomSubmitButton>
          </Box>
        </Box>
      </Modal>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.mainWrapper}>
          <Container maxWidth="xl">
            {this.EmailLoginModal()}
            {this.EmailVerifModal()}
          </Container>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    width: "100%",
    position: "fixed",
  },
    mainContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "none",
      position: "relative",
    },
  modalContainer: {
    display: "flex",
    width: "495px",
    padding: "50px",
    background: "white",
    borderRadius: "8px",
    flexDirection: "column",
    margin: "15px",
    position: "relative",
  },
  subHeaderBox: {
    marginTop: "22px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  forgotPwBox: {
    marginTop: "35px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  closeButton: {
    position: "absolute",
    top: "28px",
    right: "28px",
    boxShadow: "0px 3px 6px rgba(86, 86, 86, 0.1)",
    borderRadius: "100%",
  },
  codeVerifyTimer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "calc(100% - 92px)",
  },
  didNotGetOtpBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  inputContainer: {
    display: "flex",
    marginTop: "60px",
    marginBottom: "20px",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    width: "calc(100% - 90px)",
  },
  errorMsgContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "no-wrap",
    alignItems: "center",
    marginTop: "15px",
  },
  blurredBackground: {
    position: "absolute",
    zIndex: -1,
    backgroundColor: "rgba(0,0,0,0.1)",
    width: "100%",
    height: "100%",
    backdropFilter: "blur(8px)",
  },
};
// Customizable Area End
