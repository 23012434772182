import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { Message } from "framework/src/Message";

// Customizable Area Start
import { InputProps } from "@material-ui/core/Input";
import { createRef } from "react";
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  successMsg: string;
  authToken: string;

  branding_tradmark_certificate: File | "";
  errorMsg: string;
  value: string;
  nxtBtnClicked: boolean;
  cattegoryId: any;
  subCatId: any;
  miniCatId: any;
  microCatId: any;
  brandId: string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerBulkProductUploadController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiCreateBrandCallId: string = "";
  inputBrandRef: React.RefObject<InputProps & { click: Function }>;
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      value: "",
      nxtBtnClicked: false,
      successMsg: "",
      authToken: "",
      branding_tradmark_certificate: "",
      errorMsg: "",
      cattegoryId: "",
      subCatId: "",
      miniCatId: "",
      microCatId: "",
      brandId: ""
    };
    this.inputBrandRef = createRef();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.apiCreateBrandCallId) {
        await removeStorageData("category_id");
        await removeStorageData("sub_category_id");
        await removeStorageData("mini_category_id");
        await removeStorageData("micro_category_id");
        this.handleCreateBrandResponse(responseJson);
      }
    }
  }

  // Customizable Area End

  // Customizable Area Start
  handleCreateBrandResponse = (responseJson: any) => {
    this.setState({ errorMsg: "", successMsg: "", branding_tradmark_certificate: "" });
    this.handleDownloadExcel(responseJson);
    return this.setState({ successMsg: "Your brand was successfully created!" });
  };
  
  handleDownloadExcel = async (data: any) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    worksheet.addRow(data.headers);

    worksheet.columns = data.headers.map((header:any) => ({
      header,
      key: header,
      width: header.length + 10 
    }));

    const valueData = Array.isArray(data.values) ? data.values : [data.values]; 
    
    valueData.forEach((rowData: any, rowIndex: any) => {
      const rowValues = data.headers.map((header: any) => {
        if(header === "Product Color"){
          return [
           "Purple", 
           "Light Coral", 
           "Lime Green", 
           "Yellow",
           "Grey", 
           "Ocean Green", 
           "Pink",
           "Sky blue",
          ]
        } else {
          return rowData[header]
        }
      });
      worksheet.addRow(rowValues);
      
      rowValues.forEach((cellValue: any, colIndex: any) => {
        if (Array.isArray(cellValue)) {
          for(let i=1; i<=200; i++) {
            worksheet.getCell(rowIndex + i, colIndex + 1).value = "";
            const cell = worksheet.getCell(rowIndex + i, colIndex + 1)
            cell.dataValidation = {
              type: 'list',
              allowBlank: true,
              formulae: [`"${cellValue.join(',')}"`],
              showErrorMessage: true,
              error: "Please select from dropdown",
              errorStyle: "stop",
              errorTitle: "Invalid Input"
            };
            cell.protection = { locked: true }
          }
        }
      });
    });

    worksheet.getRow(1).values = data.headers;

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), 'data.xlsx');
  };

  handleGoToCategorizeProducts = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationSellerDashboardMessage));
    msg.addData(getName(MessageEnum.ChangeMenuPanel), "addBulkProductUpload");
    this.send(msg);
  };

  handleRadio(value: string) {
    this.setState({ value }, () => this.apiCallForTemplateDownLoad());
  }
  apiCallForTemplateDownLoad = async () => {
    if (this.state.value) {
      const headers = {
        "Content-Type": "application/vnd.ms-excel",
        token: this.state.authToken
      };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.apiCreateBrandCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.myDealsApiEndPoint.createDeals(this.state.cattegoryId, this.state.subCatId, this.state.miniCatId, this.state.microCatId, this.state.brandId));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  handleNext = () => {
    const comp = this.props.id === configJSON.bulkUploadFileId ? configJSON.bulkUploadFilePath : "";
    this.props.navigation.navigate(configJSON.sellerHomeText, {path: comp})
  }

  handleCancel = () => {
    this.props.navigation.navigate(configJSON.sellerHomeText, {path: configJSON.bulkSelectCategoryPath})
  };

  handleGetToken = async () => {
    const authToken = await getStorageData("authToken");
    const categoryId = await getStorageData("category_id");
    const subCatId = await getStorageData("sub_category_id");
    const miniCatId = await getStorageData("mini_category_id");
    const microCatId = await getStorageData("micro_category_id");
    const brandId = await getStorageData("brand_id");
    this.setState({ cattegoryId: categoryId, subCatId: subCatId, miniCatId: miniCatId, microCatId: microCatId, brandId: brandId });
    this.setState({ authToken });
  };

  async componentDidMount(): Promise<void> {
    this.handleGetToken();
  }

  // Customizable Area End
}
