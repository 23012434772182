import React from "react";
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import { ClassNameMap } from "@material-ui/styles";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { styled, Box, Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

export const styles: Styles<Theme, {}, string> = () => ({
  mainBox: {
    height: "245px",
  },
  carouselContainer: {
    position: "relative",
    "& img": {
      height: "140px",
      width: "90%",
      margin: "0px auto",
      paddingTop: "25px",
      objectFit: "contain",
    },
  },
  categoryBox: {
    height: "245px",
    transform: "scale(0.9)",
    transition: "transform 0.5s",
    cursor: "pointer",
    borderRadius: "10px",
    backgroundColor: "#fff",
  },
  categoryName: {
    textAlign: "center",
    textTransform: "capitalize" as const,
    padding: "10px 20px",
  },
});

interface CarouselState {
  currentSlide: number;
}

interface CarouselProps {
  data: {
    id: string;
    attributes: {
      productImg: string;
      name: string;
    };
  }[];
  handleCategoryClick: any;
  classes: ClassNameMap<
    | "carouselContainer"
    | "activeSlide"
    | "mainBox"
    | "categoryName"
    | "categoryBox"
  >;
}

const StyledArrow = styled("div")({
  display: "flex",
  width: "32px",
  height: "32px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  cursor: "pointer",
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 10,
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "#fff",
  },
});

const StyledLeftArrow = styled(StyledArrow)({
  left: "-36px",
});

const StyledRightArrow = styled(StyledArrow)({
  right: "-36px",
});

const SampleNextArrow: React.FC<{
  style?: React.CSSProperties;
  onClick?: () => void;
}> = (props) => {
  const { style, onClick } = props;
  return (
    <Box style={{ ...style, display: "block" }} onClick={onClick}>
      <StyledRightArrow>
        <ChevronRightIcon />
      </StyledRightArrow>
    </Box>
  );
};

const SamplePrevArrow: React.FC<{
  style?: React.CSSProperties;
  onClick?: () => void;
}> = (props) => {
  const { style, onClick } = props;
  return (
    <Box style={{ ...style, display: "block" }} onClick={onClick}>
      <StyledLeftArrow>
        <ChevronLeftIcon />
      </StyledLeftArrow>
    </Box>
  );
};

export class CustomSlider extends React.Component<
  CarouselProps,
  CarouselState
> {
  slider: Slider | null = null;

  constructor(props: CarouselProps) {
    super(props);
    this.state = {
      currentSlide: 0,
    };
  }

  settings = {
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (current: any) => this.setState({ currentSlide: current }),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  adjustedSettings: Settings = {
    ...this.settings,
    infinite: this.props.data.length > 7,
    slidesToShow: Math.min(7, this.props.data.length),
  };

  nextSlide = () => {
    if (this.slider) {
      this.slider.slickNext();
    }
  };

  prevSlide = () => {
    if (this.slider) {
      this.slider.slickPrev();
    }
  };

  render() {
    const { classes, data, handleCategoryClick } = this.props;
    return (
      <Box className={classes.mainBox}>
        <Slider ref={(c) => (this.slider = c)} {...this.adjustedSettings}>
          {data.map((item, index: number) => (
            <Box
              onClick={(e) => {
                e.stopPropagation();
              }}
              key={index}
              className={`${classes.carouselContainer}`}
            >
              <Box className={classes.categoryBox}>
                <img
                  onClick={() => handleCategoryClick(item.id)}
                  src={item.attributes.productImg}
                  alt={`Image ${index + 1}`}
                />
                <Typography className={classes.categoryName}>
                  {item.attributes.name}
                </Typography>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    );
  }
}

export default withStyles(styles)(CustomSlider);
