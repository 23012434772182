import React from "react";

// Customizable Area Start
import { Box, InputAdornment, Typography, withStyles } from "@material-ui/core";
import SellerFullfilledByEzzyController, { Props, configJSON } from "./SellerFullfilledByEzzyController.web";
import { CustomizeInputSearchBox, SearchIconSVG } from "../../dashboard/src/CustomizedElementStyle";
import FulFilledByEzzyOrderManagement  from "./FulFilledByEzzyOrderManagement.web";
// Customizable Area End

class SellerFullfilledByEzzy extends SellerFullfilledByEzzyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
  const {classes} = this.props;
    return (
        <>
        <Box className={classes.contentWrapper}>
        <Box className={classes.headerContainer}>
          <Box className={classes.headerText}>{configJSON.fulFilledByEzzyText}</Box>
        </Box> 
        <Box className={classes.container}>
            <Box className={classes.orderTextWrapper}>
                <Box className={classes.ordersText}>{configJSON.manageOrdersText}</Box>
            </Box>
            <Box className={classes.searchWrapper}>
                <Typography className={classes.searchOrderText}>{configJSON.searchOrderIdText}</Typography>
                <Box className={classes.searchBoxWrapper}>
                    <CustomizeInputSearchBox
                        id="outlined-start-adornment"
                        value={this.state.searchValue}
                        onChange={this.handleOnSearch}
                        fullWidth
                        InputProps={{
                        startAdornment: <InputAdornment position="start" style={{ marginRight: 20 }}></InputAdornment>,
                        endAdornment: (
                            <InputAdornment position="end" style={{ paddingRight: 20 }}>
                            <SearchIconSVG />
                            </InputAdornment>
                        ),
                        }}
                    />
                </Box>
            </Box>
        </Box>
        <FulFilledByEzzyOrderManagement navigation={undefined} id={""} />
        </Box>
        </>
    )
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = {
    contentWrapper : {
        overflowY: "auto" as const,
        overflowX: "hidden" as const
    },
    headerContainer : {
        display: "flex", 
        justifyContent: "space-between", 
        alignItems: "center"
    },
    headerText : {
        padding: "20px 0px 15px 0px", 
        fontSize: "18px", 
        fontWeight: 400 as const, 
        fontFamily: `"Lato", sans-serif` as const
    },
    container : {
        borderRadius: "8px", 
        display: "flex", 
        flexDirection: "column" as const, 
        padding: "30px",  
        position: "relative" as const, 
        background: "white" 
    },
    orderTextWrapper : {
        width: "100%", 
        display: "flex", 
        flexDirection: "row" as const, 
        flexWrap: "wrap" as const, 
        justifyContent: "space-between", 
        alignItems: "center" as const, 
        marginBottom: 10
    },
    ordersText : {
        fontSize: "27px", 
        fontWeight: 700  as const, 
        fontFamily: `"Lato", sans-serif` as const
    },
    searchWrapper : {
        padding:"20px 0px 15px 0px"
    },
    searchOrderText : {
        color: '#33333A', 
        fontSize:'12px',
        fontWeight: 400 as const
    },
    searchBoxWrapper : {
        width:'65%', 
        position:'relative' as const
    }
}

export {SellerFullfilledByEzzy}

export default withStyles(styles)(SellerFullfilledByEzzy);
// Customizable Area End