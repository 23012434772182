import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

// Customizable Area Start
import { getStorageData } from "../../../../framework/src/Utilities";
import { IErrorData, IErrorMap, ISendSupportData, ISupportDocsData } from "./Interfaces";
import { GetCookies } from "../../../../framework/src/WebUtilities";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  language: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  documents: ISupportDocsData[];
  firstName: string;
  lastName: string;
  email: any;
  details: string;
  showDocsModal: boolean;
  docs_title: string;
  docs_content: string[];
  successMsg: string;
  errMsg: string;
  isFetching: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class ContatusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetDocsCallId: string = "";
  apiSendContactSuppCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.ReceiveMessage), getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      documents: [],
      firstName: "",
      lastName: "",
      email: "",
      details: "",
      showDocsModal: false,
      docs_title: "",
      docs_content: [],
      errMsg: "",
      successMsg: "",
      isFetching: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.setState({ isFetching: false });
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId === this.apiGetDocsCallId) {
        this.handleGetDocsResponses(responseJson);
      }
      if (apiRequestCallId === this.apiSendContactSuppCallId) {
        this.handleSendContactSuppResponse(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  clearSuccessMessage = () => {
    this.setState({ successMsg: "" });
  };
  handleSendContactSuppResponse = (responseJson: unknown) => {
    const successData = responseJson as ISendSupportData;
    const errData = responseJson as IErrorMap;
    if ("id" in successData) {
      this.setState({ successMsg: "Successfully sent an email to ByEzzy." });
      return setTimeout(this.clearSuccessMessage, 3000);
    }
    if ("errors" in errData) {
      const key = Object.keys(errData.errors[0])[0];
      return this.setState({ errMsg: errData.errors[0][key] });
    }
    return this.setState({ errMsg: "Something went wrong. Please try again later!" });
  };

  handleGetDocsResponses = (responseJson: unknown) => {
    const successData = responseJson as ISupportDocsData[];
    const errorData = responseJson as IErrorData;
    if ("errors" in errorData) {
      return;
    }
    if (successData.length > 0) {
      return this.setState({ documents: successData });
    }
  };

  handleOnChange = (input: string, value: string) => {
    this.setState(({ [input]: value } as unknown) as Pick<S, keyof S>);
  };

  handleCloseButton = () => {
    this.setState({ showDocsModal: !this.state.showDocsModal });
  };

  handleOpenModal = (docs_title: string, docs_content: string) => {
    this.setState({ showDocsModal: !this.state.showDocsModal, docs_title, docs_content: docs_content.split(/\r?\n+/).filter(item => item !== "") });
  };

  handleOnSubmit = () => {
    this.setState({ successMsg: "", errMsg: "" });
    const { firstName, lastName, email, details } = this.state;
    const rule = /^[A-Za-z]+$/;
    if (!firstName || !lastName || !email || !details) {
      return this.setState({ errMsg: "Please fill all the required fields." });
    }
    if (!rule.test(firstName) || !rule.test(lastName)) {
      return this.setState({ errMsg: "Please enter valid name" });
    }
    this.sendContactSupport();
  };

  getDocuments = async () => {
    const headers = { token: await getStorageData("authToken"), "Content-Type": configJSON.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetDocsCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiSupportDocsEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendContactSupport = async () => {
    this.setState({ isFetching: true });
    const headers = { token: await getStorageData("authToken"), "Content-Type": configJSON.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiSendContactSuppCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiSupportContactUsEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            details: this.state.details,
            email: this.state.email
          }
        }
      })
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ firstName: "" });
    this.setState({ lastName: "" });
    this.setState({ details: "" });
  };

  async componentDidMount(): Promise<void> {
    const user_email = localStorage.getItem("seller_email");
    this.setState({ email: user_email });
    this.getDocuments();
  }
  // Customizable Area End
}
