// Customizable Area Start
import React from "react";
import { Box, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography, withStyles } from "@material-ui/core";
import FulFilledByEzzyOrderManagementController, { Props, configJSON } from "./FulFilledByEzzyOrderManagementController.web";
import {mobileImg} from "./assets";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  interface EnhancedTableProps {
    numSelected?: number;
  
    onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    orderBy?: string;
    rowCount?: any;
    classes:any;
  }
  
  function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, numSelected, rowCount, classes } = props;
  
    return (
      <TableHead>
        <TableRow style={{ backgroundColor: 'rgba(123,123,123,0.07)' }}>
          <TableCell className={classes.tableHeaderText}>
            <div className={classes.tableHead} >
              <input
              className={classes.checkBox}
              type="checkbox" 
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              /> 
              <span className={classes.tableHeaderText}>{configJSON.orderDateLabel}</span>
            </div>
          </TableCell>
          <TableCell className={classes.tableHeaderText}>{configJSON.orderDetailsLabel}</TableCell>
          <TableCell className={classes.tableHeaderText}> {configJSON.imageLabel}</TableCell>
          <TableCell className={classes.tableHeaderText}>{configJSON.productNameLabel}</TableCell>
          <TableCell className={classes.tableHeaderText}>{configJSON.customerOptionLabel}</TableCell>
          <TableCell className={classes.tableHeaderText}>{configJSON.orderStatusLabel}</TableCell>
          <TableCell className={classes.tableHeaderText}>{configJSON.actionLabel}</TableCell>
        </TableRow>
      </TableHead>
    );
  }  
// Customizable Area End

// Customizable Area Start
class FulFilledByEzzyOrderManagement extends FulFilledByEzzyOrderManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    render() {
      const {classes} = this.props;
    return (
        <>
        <Box className={classes.ordersWrapper}>
        <Tabs
          className={classes.tabs}
            value={this.state.value}
            onChange={this.handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
            style: {
                backgroundColor: 'rgb(255,96,8)',
                top: 0
            }
            }}
        >
        <Tab
          label={configJSON.pendingOrdersLabel}
          className={classes.tabsLabel}
          style={{ backgroundColor: this.state.value === 0 ? 'white' : 'rgb(242,243,244)' }}
          {...a11yProps(0)}
        />
        <Tab
          label={configJSON.fulfilledOrdersLabel}
          className={classes.tabsLabel}
          style={{backgroundColor: this.state.value === 1 ? 'white' : 'rgb(242,243,244)'}}
          {...a11yProps(1)}
        />

        <Tab
          label={configJSON.cancelledOrdersLabel}
          className={classes.tabsLabel}
          style={{backgroundColor: this.state.value === 2 ? 'white' : 'rgb(242,243,244)'}}
          {...a11yProps(2)}
        />
        </Tabs>
        {[...Array(3)].map((tab: any, index: any) => {
        return (
        <div className={classes.layout}>
        <TabPanel value={this.state.value} index={index} >

          <Box className={classes.pendingOrdersWrapper}>
            <Box className={classes.ordersInfo}>2 Orders</Box>
            <Box className={classes.optionsWrapper}>
              <Box className={classes.options} sx={{marginRight: 20 }}>{configJSON.filtersLabel}</Box>
              <Box className={classes.options} >{configJSON.sortingLabel}</Box>
            </Box>
          </Box>
        
        <Box style={{paddingTop:'20px'}}>
        <TableContainer className={classes.tableContainer}>
          <Table aria-label="simple table" stickyHeader >
          <EnhancedTableHead
          data-test-id="tableHeader"
            classes={classes}
            numSelected={this.state.selectedOrders.length}
            onSelectAllClick={this.handleSelectAllClick}
            rowCount={this.state.ordersList && this.state.ordersList.length}
          />
          <TableBody className={classes.layout}>
            {this.state.ordersList.length > 0 && this.state.ordersList.map((item:any) => {
              const isItemSelected = this.isSelectedOrders(item.id);
              return(
              <TableRow key={item.id} >
                <TableCell component="th" scope="row"
                  className={classes.tableCellContent}>
                  <div
                  >
                    <input
                    data-test-id="checkBox"
                      style={{marginRight:'20px'}} 
                      type="checkbox" 
                      id={item.id}
                      onChange={() =>
                        this.handleClickOrdersRow(item.id)
                      }
                      checked={isItemSelected}
                    /> 
                  </div>
                  <div className={classes.dateWrapper}>
                      <Box className={classes.day}>2 days ago</Box> 
                      <Box className={classes.buyer}>{item.attributes.date}</Box>
                  </div>
                </TableCell>
                 <TableCell className={classes.tableRowHeaderText}>
                <Box className={classes.buyer}>FD65456DFDF456F</Box>
                  <Box className={classes.detailsWrapper}>
                    <Typography className={classes.buyer}>Buyer:</Typography>
                    <Typography className={classes.buyerName}>Moh. Yashin</Typography>
                  </Box>
                  <Box className={classes.detailsWrapper}>
                    <Typography className={classes.buyer}>Sales:</Typography>
                    <Typography className={classes.buyerName}>ByEzzy</Typography> 
                  </Box>
                  <Box className={classes.detailsWrapper}>
                    <Typography className={classes.buyer}>Fullfillment:</Typography>
                    <Typography className={classes.buyerName}>ByEzzy</Typography>  
                  </Box>
                </TableCell>
                
                <TableCell>
                  <img
                    src={mobileImg}
                    className={classes.prodImage}
                    alt="img"
                  />
                </TableCell>
                
                <TableCell>
                  <Box style={{marginTop:'-10px'}} className={classes.buyer}>
                    OPPO A31 
                    <Typography className={classes.buyer}> 
                    (Mystery Black, 6GB RAM,
                    128GB Storage)
                    </Typography> 
                  </Box>
                  <Box className={classes.detailsWrapper}>
                    <Typography className={classes.buyer}>PSKU:</Typography>
                    <Typography className={classes.buyerName}>PS1200KP</Typography>
                  </Box>
                  <Box className={classes.detailsWrapper}>
                    <Typography className={classes.buyer}>Unit Price (AED):</Typography>
                    <Typography className={classes.buyerName}>4198</Typography>
                  </Box>
                </TableCell>
                <TableCell><Box className={` ${classes.customerOption}`}>Standard</Box></TableCell>
                <TableCell>
                  <Box className={this.getStatusClass(this.state.value)} >
                    {this.getStatus(this.state.value)}
                  </Box></TableCell>
                <TableCell>
                  <Box className={classes.viewOption}>
                    {this.getActionResults(this.state.value)}
                  </Box>
                </TableCell>
              </TableRow>

            )})}
          </TableBody>
          </Table>
        </TableContainer>
        </Box>
        </TabPanel>
        </div>
        )})}
      </Box>
        </>
    )   
  } 
  // Customizable Area End
}

const styles = {
  tabs : {
    paddingLeft: "30px"
  },
  ordersWrapper : {
    backgroundColor: 'rgb(242,243,244)',
    padding:'0 0 15px 0'
  },
  pendingOrdersWrapper : {
    display: "flex", 
    justifyContent: "space-between", 
    alignItems: "center",
    backgroundColor: 'white'
  },
  ordersInfo: {
    padding: "0px 0px 0px 10px", 
    fontSize: "18px", 
    fontWeight: 400, 
    fontFamily: `"Lato", sans-serif` as const
  },
  optionsWrapper: {
    display: "flex", 
    flexDirection: "row" as const
  },
  tabsLabel: {
    textTransform:'capitalize' as const,
    fontSize: '14px',
    fontWeight: 700 as const,
    color: '#727272'
  },
  layout: {
    backgroundColor:'white'
  },
  tableContainer: {
    border: '0.5px solid #ccc',
    borderRadius:'8px'
  },
  tableHead: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  tableCellContent: {
    display:'flex',
    alignItems:'flex-start', 
    borderBottom:'0'
  },
  dateWrapper : {
    display:'block',
    alignItems:'flex-start', 
    borderBottom:'0'
  },
  detailsTableHead: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  checkBox: {
    marginRight:'20px' 
  },
  tableHeaderText : {
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: '14px',
    textAlign: 'left' as const,
    fontWeight: 700 as const,
    color: '#333',
    '&.MuiTableCell-stickyHeader' :{
    }
  },
  tableRowHeaderText : {
    color : 'rgb(101, 122, 142)',
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: '12px',
    textAlign: 'left' as const,
  },
  options: {
    padding: "7px 23px", 
    color: "#657A8E", 
    fontFamily: `"Lato", sans-serif` as const, 
    fontSize: 16, 
    fontWeight: 500 as const, 
    border: "1px solid rgba(101, 122, 142, 0.320241)", 
    borderRadius: 5,
    backgroundColor: 'rgb(242,243,244)'
  },
  viewOption: {
    padding: "5px 23px", 
    color: "#657A8E", 
    fontFamily: `"Lato", sans-serif` as const, 
    fontSize: 14, 
    fontWeight: 500 as const, 
    border: '0px solid rgba(101, 122, 142, 0.320241)',
    backgroundColor: 'rgb(242,243,244)', 
    borderRadius: 5,
    display:'flex', 
    alignItems:'flex-start', 
    borderBottom:'0',
    marginTop:'-85px'
  },
  pendingOrderStatus: {
    padding: "4px 16px", 
    color: "#ec9e31", 
    fontFamily: `"Lato", sans-serif` as const, 
    fontSize: 14, 
    fontWeight: 500 as const, 
    border: '0px solid #ec9e31',
    backgroundColor: '#ffefd3', 
    borderRadius: 5,
    borderBottom:'0',
    marginTop:'-85px',
    textAlign: 'center' as const
  },
  completedOrderStatus: {
    padding: "4px 23px", 
    color: "#60c32b", 
    fontFamily: `"Lato", sans-serif` as const, 
    fontSize: 14, 
    fontWeight: 500 as const, 
    border: '0px solid #ec9e31',
    backgroundColor: '#d4f4c3', 
    borderRadius: 5,
    display:'flex', 
    alignItems:'flex-start', 
    borderBottom:'0',
    marginTop:'-85px'
  },
  cancelledOrderStatus: {
    padding: "4px 23px", 
    color: "red", 
    fontFamily: `"Lato", sans-serif` as const, 
    fontSize: 14, 
    fontWeight: 500 as const, 
    border: '0px solid #ec9e31',
    backgroundColor: '#FCE4E4', 
    borderRadius: 5,
    display:'flex', 
    alignItems:'flex-start', 
    borderBottom:'0',
    marginTop:'-85px'
  },
  customerOption : {
    fontWeight: 700 as const,
    color: '#000',
    display:'flex', 
    alignItems:'flex-start', 
    borderBottom:'0',
    marginTop:'-85px',
    fontSize:'14px'
  }, 
  day: {
    fontWeight: 700 as const,
    color: '#000',
    fontSize:'14px'
  },
  prodImage: {
    marginTop:'-85px',
    height: '80px',
    width: '80px',
    objectFit: 'fill' as const,
    borderRadius: '8px'
  },
  buyer: {
    fontSize:'14px',
    color: '#697A8D',
    fontWeight: 400 as const
  },
  buyerName: {
    fontSize:'14px',
    color: '#000',
    fontWeight: 400 as const
  },
  detailsWrapper: {
    display: 'flex', 
    flexDirection:'column' as const,
    alignItems:'flex-start', 
    marginTop:'10px'
  }
}

export {FulFilledByEzzyOrderManagement}

export default withStyles(styles)(FulFilledByEzzyOrderManagement)
// Customizable Area End