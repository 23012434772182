// Customizable Area Start
import React from "react";
import StoreTabsController, { Props, TabComponent, configJSON } from "./StoreTabsController.web";
import { Box, Dialog, Theme, withStyles } from "@material-ui/core";
import { BoxNoScroll, CustomScrollbar } from "../CustomizedElementStyle";
import { ClickableBox } from "../../../mobile-account-login/src/CustomElementStyling";
import { template } from "../assets";
import StoreHeaderTab from "./StoreHeaderTab.web";
import StoreBannerTab from "./StoreBannerTab.web";
import StoreSectionOneTab from "./StoreSectionOneTab.web";
import StoreSectionTwoTab from "./StoreSectionTwoTab.web";
import StoreMenuTab from "./StoreMenuTab.web";
import StoreSectionThreeTab from "./StoreSectionThreeTab.web";
import StoreCategories from "./StoreCategories.web";
import StoreProductListing from "./StoreProductListingTab.web";
import TemplatePreview from "./TemplatePreview.web";
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End

class StoreTabs extends StoreTabsController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  renderTabComponent = (activeTab: string) => {
    const tabComponent: TabComponent = {
      header: <StoreHeaderTab navigation={this.props.navigation} id={"store-header"} />,
      menu: <StoreMenuTab navigation={this.props.navigation} id={"store-menu"}/>,
      banner: <StoreBannerTab navigation={this.props.navigation} id={"store-banner"} />,
      sectionOne : <StoreSectionOneTab navigation={this.props.navigation} id={"store-section-one"} />,
      sectionTwo : <StoreSectionTwoTab navigation={this.props.navigation} id={"store-section-two"} />,
      productListing: <StoreProductListing navigation={this.props.navigation} id={"store-productlist"} />,
      sectionThree: <StoreSectionThreeTab navigation={this.props.navigation} id={"store-section-three"} />,
      categories: <StoreCategories navigation={this.props.navigation} id={"store-categories"} />
    };
    return tabComponent[activeTab];
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box className={classes.headerText}>{configJSON.storeManagementTab}</Box>
        <BoxNoScroll className={classes.noScroll}>
          <Box {...({ ref: this.headerRef })} className={classes.box}>
            <Box className={classes.topContainer}>
              <Box data-test-id="component" className={classes.storeText}>{configJSON.createStore}</Box>
              <ClickableBox id="modalOpen" className={classes.previewBox} onClick={this.handleTemplatePreview}>
                <img src={template} className={classes.img} />
                {configJSON.templatePreviewText}
              </ClickableBox>
            </Box>
            <Box className={classes.line}></Box>
            <Box className={classes.horizontal}></Box>
            <CustomScrollbar className={classes.scroll}>
              <ClickableBox data-test-id="header_tab" onClick={() => this.handleTabChange("header")} className={this.state.activeTab === "header" ? classes.active : classes.inactive}>
                {configJSON.headerTab}
              </ClickableBox>
              <ClickableBox data-test-id="menu" onClick={() => this.handleTabChange("menu")} className={this.state.activeTab === "menu" ? classes.active : classes.inactive}>
                {configJSON.menutabText}
              </ClickableBox>
              <ClickableBox data-test-id="banner" onClick={() => this.handleTabChange("banner")} className={this.state.activeTab === "banner" ? classes.active : classes.inactive}>
                {configJSON.bannerText}
              </ClickableBox>
              <ClickableBox data-test-id="sectionOne" onClick={() => this.handleTabChange("sectionOne")} className={this.state.activeTab === "sectionOne" ? classes.active : classes.inactive}>
                {configJSON.sectionOneText}
              </ClickableBox>
              <ClickableBox data-test-id="sectionTwo" onClick={() => this.handleTabChange("sectionTwo")} className={this.state.activeTab === "sectionTwo" ? classes.active : classes.inactive}>
                {configJSON.sectionTwoText}
              </ClickableBox>
              <ClickableBox data-test-id="productListing" onClick={() => this.handleTabChange("productListing")} className={this.state.activeTab === "productListing" ? classes.active : classes.inactive}>
                {configJSON.productListingText}
              </ClickableBox>
              <ClickableBox data-test-id="sectionThree" onClick={() => this.handleTabChange("sectionThree")} className={this.state.activeTab === "sectionThree" ? classes.active : classes.inactive}>
                {configJSON.sectionThreeText}
              </ClickableBox>
            </CustomScrollbar>
            <Box className={classes.page}>
              {this.renderTabComponent(this.state.activeTab)}
            </Box>
          </Box>
        </BoxNoScroll>
        <Dialog data-test-id="modal" className={classes.dialog} maxWidth="lg" open={this.state.isPreviewOpen} onClose={this.handleClose} >
        <Box className={classes.closeBox}>
          <CloseIcon data-test-id="closeIcon" onClick={this.handleClose} className={classes.close}/>
        </Box>
          <TemplatePreview navigation={this.props.navigation} id={this.props.id}/>
        </Dialog>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const styles = (theme: Theme) => ({
  active: {
    height: 56,
    maxWidth: 200,
    minWidth: 142,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#727272",
    fontSize: 14,
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`,
    background: "white",
    borderTop: "4px solid #FF6008",
  },
  inactive: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#727272",
    height: 56,
    maxWidth: 200,
    minWidth: 142,
    fontSize: 14,
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`,
    borderTop: "4px solid #FFF",
    background: "linear-gradient(180deg, #F8F8F8 0%, #EEE 100%)",
  },
  headerText: {
    padding: "20px 0px 15px 0px",
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `"Lato", sans-serif`,
  },
  noScroll: {
    paddingBottom: 20,
    overflow: "auto" as const,
  },
  box: {
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as const,
    padding: "30px",
    overflowY: "auto" as const,
    overflowX: "hidden" as const,
    position: "relative" as const,
    background: "white",
  },
  topContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row" as const,
    flexWrap: "wrap" as const,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  storeText: {
    fontSize: "27px",
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`,
  },
  previewBox: {
    color: "#657A8E",
    fontSize: 16,
    fontFamily: `"Lato", sans-serif`,
    background: "#F4F4F4",
    padding: "14px 23px",
    display: "flex",
    alignItems: "center",
    borderRadius: 5,
    border: "1px solid rgba(0,0,0,0.2)",
    '@media (max-width: 715px)': {
      marginTop:'50px'
    }
  },
  img: {
    marginRight: 7,
  },
  line: {
    width: "calc(100% + 60px)",
    height: 1,
    background: "#EEEEEE",
    position: "absolute" as const,
    left: 0,
    top: 100,
  },
  scroll: {
    display: "flex",
    flexDirection: "row" as const,
    flexWrap: "nowrap" as const,
    width: "100%",
    position: "absolute" as const,
    left: 0,
    top: 100,
    background: "linear-gradient(180deg, #F8F8F8 0%, #EEE 100%)",
    overflowX: "auto" as const,
    '@media (max-width: 715px)': {
      marginTop:'100px',
    }
  },
  page: {
    '@media (max-width: 715px)': {
      marginTop:'50px',
    }
  },
  horizontal: { 
    width: "100%", 
    height: 90 
  },
  dialog: {
    maxWidth: '100% !important' ,
    '.MuiDialog-paperWidthSm' : {
      maxWidth: '100%'
    }
  },
  close: {
    cursor:'pointer', 
    border: '1px solid #666',
    borderRadius: '50%',
    padding: '4px',
    fontSize: '24px'
  },
  closeBox: { 
    margin:'20px 16px 0',
    fontFamily: `"Lato", sans-serif`, 
    textAlign: "right" as const
  }
});

export { StoreTabs };
export default withStyles(styles)(StoreTabs);

// Customizable Area End
