import { withStyles, TextField } from "@material-ui/core";
import React from "react";

export const HomeSearchBox = withStyles(() => ({
  root: {
    "& .MuiInputBase-input": {
      fontSize: 14,
      fontFamily: '"Lato", sans-serif',
      fontWeight: 500,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#f44336",
    },
    "& .MuiInputBase-root.Mui-error": {
      border: "1px solid #f44336",
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none", // Remove the underline
    },
    "& .MuiInputLabel-root": {
      top: -20,
      left: 0,
      color: "#33333A",
      fontWeight: 600,
      fontSize: 14,
      fontFamily: `"Lato", sans-serif`,
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none", // Remove the underline on hover
    },
    "& .MuiInput-formControl": {
      margin: 0,
      padding: "5px 0px",
      borderRadius: 5,
      backgroundColor: "white",
    },
  },
}))(TextField);
