import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { webStyle } from "./ReturnItemDetails.web";
import { IShippingDetails } from "./FulfilledByPartnerReturnsController.web";

// Customizable Area Start
export const configJSON = require("../src/config.js")

export interface IItemDetails {
  id: number | string,
  order_number: string,
  ordered_at: string,
  total_items: number,
  total_fees: number,
  total_tax: number,
  time_passed_since_order_placed: string,
  final_price: number,
  accepted: boolean,
  shipping_address: {
    data: {
      attributes: {
        address_1: string,
        address_2: string,
        city: string,
        phone_number: number,
        state: string,
        zip_code: number
      }
    } | null
  },
  order_status: {
    data: {
      id: string,
      attributes: {
        name: string
      }
    }
  },
  seller: {
    data: {
      id: string,
      attributes: {
        full_name: string
      }
    }
  },
  customer: {
    data: {
      id: string,
      attributes: {
        full_name: string
      }
    }
  },
  order_items: {
    data: [
      {
        id: string,
        attributes: {
          quantity: number,
          price: number,
          order_status: {
            data: {
                id: string,
                attributes: {
                  name: string
                }
              }
          },
          return_reason_details?: {
            id: number,
            title:string,
            details:string,
            shopping_cart_order_item_id:number,
            reason_type:string
          }[],
          item: {
            catalogue: {
              data: {
                id: string,
                attributes: {
                  product_image: string,
                  product_title: string,
                  brand: {
                    brand_name: string
                  },
                  product_content: {
                    product_attributes: {
                      unique_psku: string
                    }
                  }
                }
              }
            }
          }
        }
      }
    ]
  }
}

export interface OrderItem {
  id: string,
  attributes: {
    quantity: number,
    price: number,
    item: {
      catalogue: {
        data: {
          id: string,
          attributes: {
            product_image: string,
            product_title: string,
            brand: {
              brand_name: string
            }
            product_content: {
              product_attributes: {
                unique_psku: string
              }
            }
          }
        }
      }
    }
  }
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    detail: string;
    detailValue: string;
    productDetails: string;
    productName: string;
    color: string;
    brandName: string;
    quantity: string;
    leftMargin: string;
    priceValue: string;
    priceBox: string;
    productInfo: string;
    summaryText: string;
    orderSummary: string;
    quantitySec: string;
    totalQty: string;
    dividerOne: string;
    bottomRight: string;
    bottomMargin: string;
    productBox: string;
    productContainer: string;
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  authToken: string;
  itemDetails: IItemDetails,
  orderId: string,
  shippingDetails: {
    id: number,
    title:string,
    details:string,
    shopping_cart_order_item_id:number,
    reason_type:string
  },
  shippingModal: boolean,
  shippingInput: string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class ReturnItemDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  getItemDetailsApiCallId: string = "";
  updateShippingDetailsApiCallId: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      authToken: "",
      itemDetails: {
        id: "",
        order_number: "",
        ordered_at: "",
        total_items: 0,
        total_fees: 0,
        total_tax: 0,
        time_passed_since_order_placed: "",
        final_price: 0,
        accepted: false,
        shipping_address: {
          data: {
            attributes: {
              address_1: "",
              address_2: "",
              city: "",
              phone_number: 0,
              state: "",
              zip_code: 0
            }
          }
        },
        order_status: {
          data: {
            id: "",
            attributes: {
              name: ""
            }
          }
        },
        seller: {
          data: {
            id: "",
            attributes: {
              full_name: ""
            }
          }
        },
        customer: {
          data: {
            id: "",
            attributes: {
              full_name: ""
            }
          }
        },
        order_items: {
          data: [
            {
              id: "",
              attributes: {
                quantity:1,
                price: 0,
                order_status: {
                    data: {
                        id: '',
                        attributes: {
                          name: ''
                        }
                      }
                  },
                  return_reason_details: [],
                item: {
                  catalogue: {
                    data: {
                      id: "",
                      attributes: {
                        product_image: "",
                        product_title: "",
                        brand: {
                          brand_name: ''
                        },
                        product_content: {
                          product_attributes: {
                            unique_psku: ""
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          ]
        }
      },
      orderId: '',
      shippingDetails: {
        id: 0,
        title:'',
        details:'',
        shopping_cart_order_item_id:0,
        reason_type:'',
      },
      shippingModal: false,
      shippingInput: ''
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.getItemDetailsApiCallId) {
        this.handleOrderResponse(responseJson)
      }
      if (apiRequestCallId === this.updateShippingDetailsApiCallId) {
        this.handleUpdateShippingDetails(responseJson)
      }
    }
  }

  handleUpdateShippingDetails = (responseJson: IShippingDetails) => {
    if(responseJson) {
        this.setState({shippingModal: false})
        this.getItemDetails(this.state.itemDetails.id as string)
    }
  }

  handleOrderResponse = (responseJson:IItemDetails[]) => {
    if(responseJson) {
      this.setState({ itemDetails: responseJson[0]})
      const returnDetails = responseJson[0].order_items.data[0].attributes?.return_reason_details
      if(returnDetails) {
        const filterData = returnDetails.filter((reasonData: {reason_type:string}) => {
            if(reasonData.reason_type === "pickup_and_delivery") {
                return reasonData
            }
          })
          this.setState({shippingDetails: filterData[0], shippingInput: filterData[0]?.details})
      }
    }
  }

  async componentDidMount(): Promise<void> {
    const orderId = await getStorageData('sellerItemId')
    this.setState({orderId: orderId})
    if(orderId) {
      this.getItemDetails(orderId)
    }
  }

  getItemDetails = async (orderId:string) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: await getStorageData("authToken"),
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getItemDetailsApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/orders/${orderId}/show_seller_order`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(message.id, message);
  }

  getItemOrderDate = (value: string) => {
    const parsedDate = new Date(value);
    const day = parsedDate.getDate();
    const month = parsedDate.getMonth() + 1; 
    const year = parsedDate.getFullYear();
    return `${day}/${month}/${year}`;
  }

  getItemOrderTime = (value: string) => {
    const parsedDate = new Date(value);
    const hour = parsedDate.getHours().toString().padStart(2, '0');
    const minute = parsedDate.getMinutes().toString().padStart(2, '0');
    
    const ampm = hour >= '12' ? 'PM' : 'AM';
    const setHour = (parseInt(hour) % 12) || 12;
    const formattedHour = setHour.toString().length === 1 ? `0${setHour}`: setHour

    return `${formattedHour}:${minute} ${ampm}`;
  }

  handleEditShippingDetails = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, itemId:string, shippingDetailsId: number ) => {
    this.setState({shippingModal: true, shippingInput: this.state.shippingDetails.details})
  }

  handleShippingInputText = (value: string) => {
    this.setState({shippingInput: value})
  }

  handleSubmitDetails = () => {
    if(this.state.shippingInput) {
        this.updateShippingDetails()
    }
  }

  handleCloseModal = () => {
    this.setState({shippingModal: false})
  }

  updateShippingDetails = async () => {
    const httpBody = {
        "return_reason_detail": {
            "shopping_cart_order_item_id": this.state.itemDetails.order_items.data[0].id,
            "title": "shipping_details",
            "details": this.state.shippingInput,
            "reason_type": "pickup_and_delivery"  
        }
    }
    const headers = {
        "Content-Type": configJSON.productApiContentType,
        token: await getStorageData("authToken"),
      };
      const message = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateShippingDetailsApiCallId = message.messageId;
      message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_shopping_cart/return_reason_details' + `/${this.state.shippingDetails.id}`
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePatch
      );
      runEngine.sendMessage(message.id, message);
  }

  getCustomBtnStyle = () => {
    return this.state.shippingInput ? webStyle.active : webStyle.inactive
  }
  // Customizable Area End
}
