import { Box, Theme, Typography, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { OrangeButton } from "../../blocks/dashboard/src/CustomizedElementStyle";
import { expressImg, starIcon } from "../../blocks/catalogue/src/assets";

interface Props {
    classes: any,
    prodName: string,
    productImg: string,
    price: string,
    offerPrice: string,
    discount: string,
    rating: string,
    number: string,
    isDiscount: boolean
}

class ProductCard extends Component<Props> {
    constructor(props:any) {
        super(props);
    }
    render() {
        const {classes, prodName, productImg, price, offerPrice, discount, rating, number, isDiscount } = this.props;
        return (
            <>
              <Box className={classes.trendingProductsSlider}>
                  <img
                    src={productImg}
                    alt={"image"}
                    className={classes.dualSliderImg}
                  />
                  {isDiscount ? 
                  <OrangeButton className={classes.offBtn}>{discount} Off</OrangeButton>
                  :
                  <OrangeButton className={classes.newBtn}>NEW</OrangeButton>
                  }
                </Box>
                <Box className={classes.cardDesc}>
                  <Typography className={classes.cardProdName}>
                    {prodName}
                  </Typography>
                  <Typography className={classes.cardProdPrice}>
                    EAD <span className={classes.cardPrice}>{offerPrice}</span>
                  </Typography>
                  <Box className={classes.priceBox}>
                    <Typography className={classes.priceValue}>{price}</Typography>
                    <Typography className={classes.discountText}>
                      {discount} OFF
                    </Typography>
                  </Box>
                  <Box className={classes.imgBox}>
                    <img src={expressImg} alt="express_img" />
                    <Box style={{ display: "flex" }}>
                      <Typography className={classes.ratingBox}>
                        {rating}{" "}
                        <span className={classes.star}>
                          <img src={starIcon} alt="start" />
                        </span>
                      </Typography>
                      <Typography className={classes.orangeText}>{number}</Typography>
                    </Box>
                  </Box>
                </Box>
            </>
        )
    }
}

const styles = (theme: Theme) => ({
    trendingProductsSlider: {
        position: "relative" as const,
        height: "350px",
        padding: "55px 20px",
        borderRadius: "10px",
        backgroundColor: "#fff",
        [theme.breakpoints.down("md")]: {
          height: '300px',
        },
        [theme.breakpoints.down("sm")]: {
          height: '280px'
        },
        [theme.breakpoints.down("xs")]: {
         height: '250px'
        }
      },
      offBtn: {
        position: "absolute" as const,
        top: "0px",
        padding: "8px 16px",
        color: "#ffffff",
        fontFamily: `"Lato", sans-serif`,
        fontSize: "14px",
        fontWeight: 700 as const,
        right: "0px",
        borderTopRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderTopLeftRadius: '0',
        borderBottomRightRadius: '0'
      },
      newBtn: {
        position: "absolute" as const,
        top: "15px",
        padding: "4px",
        color: "#ffffff",
        fontFamily: `"Lato", sans-serif`,
        fontSize: "10px",
        fontWeight: 700 as const,
        right: "20px",
      },
      cardDesc: {
        margin: "16px 0",
        textAlign: "left" as const,
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "left",
      },
      cardProdName: {
        color: "#33333a",
        fontFamily: `"Lato",sans-serif`,
        fontSize: "16px",
        fontWeight: 400 as const,
        height: '48px',
        [theme.breakpoints.down("md")]: {
          fontSize: "14px",
          marginBottom: '20px',
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "14px"
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "14px"
        }
      },
      cardProdPrice: {
        margin: "16px 0px 10px",
        color: "#ff6008",
        fontFamily: `"Lato",sans-serif`,
        fontSize: "14px",
        fontWeight: 400 as const,
        [theme.breakpoints.down("md")]: {
          marginTop:'0px'
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "12px"
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "12px"
        }
      },
      cardPrice: {
        fontSize: "22px",
        fontWeight: 500 as const,
        [theme.breakpoints.down("xs")]: {
          fontSize: "18px"
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "18px"
        }
      },
      priceValue: {
        color: "#33333a",
        fontFamily: `"Lato",sans-serif`,
        fontSize: "14px",
        fontWeight: 400 as const,
        textDecoration: "line-through" as const,
        [theme.breakpoints.down("sm")]: {
          fontSize: "12px"
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "12px"
        } 
      },
      discountText: {
        fontWeight: 700 as const,
        textDecoration: "none" as const,
        marginLeft: "10px",
      },
      ratingBox: {
        padding: "2px 6px",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
        backgroundColor: "#565656",
        color: "#ffffff",
        fontFamily: `"Lato",sans-serif`,
        fontSize: "14px",
        fontWeight: 700 as const,
      },
      star: {
        marginLeft: "5px",
      },
      imgBox: {
        marginTop: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
      orangeText: {
        marginLeft: "5px",
        display: "flex",
        alignItems: "center",
        color: "#ff6008",
        fontFamily: `"Lato",sans-serif`,
        fontSize: "14px",
        fontWeight: 400 as const,
      },
      dualSliderImg: {
        height: "100%",
        width: "100%",
      },
      priceBox: { 
        display: "flex", 
        alignItems: "center" 
    }
})

export default withStyles(styles)(ProductCard)