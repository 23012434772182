import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import MobilePhoneLogInAdapter from "../../blocks/adapters/src/MobilePhoneLogInAdapter";
import MobilePhoneToAdditionalDetailsAdapter from "../../blocks/adapters/src/MobilePhoneToAdditionalDetailsAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import OtpInputConfirmationAdapter from "../../blocks/adapters/src/OtpInputConfirmationAdapter";
import SellerLandingAdapter from "../../blocks/adapters/src/SellerLandingAdapter";
import BulkUploadingAdapter from "../../blocks/adapters/src/BulkUploadingAdapter";
import SellerFinalUploadRegistrationAdapter from '../../blocks/adapters/src/SellerFinaUploadRegistrationAdapter';
import HomePageAdapter from '../../blocks/adapters/src/HomePageAdapter';
import SellerAccountRegistrationAdapter from '../../blocks/adapters/src/SellerAccountRegistrationAdapter';
import SellerDashboardAdapter from "../../blocks/adapters/src/SellerlDashboardAdapter";
import SellerLoginEmailAdapter from '../../blocks/adapters/src/SellerLoginEmailAdapter';
import SellerNavigationBarAdapter from '../../blocks/adapters/src/SellerNavigationBarAdapter';
import SellerHomeDashboardAdapter from '../../blocks/adapters/src/SellerHomeDashboardAdapter';
import SendMessageAdapter from '../../blocks/adapters/src/SendMessageAdapter';
import BuyerSIgnUpAdapter from "../../blocks/adapters/src/BuyerSIgnUpAdapter";
import StaticPageAdapter from "../../blocks/adapters/src/StaticPageAdapter";
import HomeContactUsAdapter from "../../blocks/adapters/src/HomeContactUsAdapter";

//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const mobilePhoneLogInAdapter = new MobilePhoneLogInAdapter();
const mobilePhoneToAdditionalDetailsAdapter = new MobilePhoneToAdditionalDetailsAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const otpInputConfirmationAdapter = new OtpInputConfirmationAdapter();
const sellerLandingAdapter = new SellerLandingAdapter();
const bulkUploadingAdapter = new BulkUploadingAdapter();
const sellerFinalUploadRegistrationAdapter = new SellerFinalUploadRegistrationAdapter();
const homePageAdapter = new HomePageAdapter();
const sellerAccountRegistrationAdapter = new SellerAccountRegistrationAdapter();
const sellerDashboardAdapter = new SellerDashboardAdapter();
const sellerLoginEmailAdapter = new SellerLoginEmailAdapter();
const sellerNavigationBarAdapter = new SellerNavigationBarAdapter();
const sellerHomeDashboardAdapter = new SellerHomeDashboardAdapter();
const sendMessageAdapter = new SendMessageAdapter();
const buyerSIgnUpAdapter = new BuyerSIgnUpAdapter();
const staticPageAdapter = new StaticPageAdapter();
const homeContactUsAdapter = new HomeContactUsAdapter();
//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance: HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>
                Welcome to ByEzzy1!
              </Text>
            </View>

            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={'InfoPage'}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={'Alert'}
              onPress={() => this.showAlert("Example", "This happened")}
            />
            <CustomTextItem content={'StripePayments'} onPress={() => navigation.navigate("StripePayments")} />
            <CustomTextItem content={'core'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'utilities'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'BulkUploading'} onPress={() => navigation.navigate("BulkUploading")} />
            <CustomTextItem content={'Search'} onPress={() => navigation.navigate("Search")} />
            <CustomTextItem content={'productquickview'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'InvoiceBilling'} onPress={() => navigation.navigate("InvoiceBilling")} />
            <CustomTextItem content={'itemavailability'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'ReviewApprovalAdmin'} onPress={() => navigation.navigate("ReviewApprovalAdmin")} />
            <CustomTextItem content={'OrderManagement'} onPress={() => navigation.navigate("OrderManagement")} />
            <CustomTextItem content={'Catalogue'} onPress={() => navigation.navigate("Catalogue")} />
            <CustomTextItem content={'dashboard'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'UserProfileBasicBlock'} onPress={() => navigation.navigate("UserProfileBasicBlock")} />
            <CustomTextItem content={'CountryCodeSelector'} onPress={() => navigation.navigate("CountryCodeSelector")} />
            <CustomTextItem content={'EducationalUserProfile'} onPress={() => navigation.navigate("EducationalUserProfile")} />
            <CustomTextItem content={'SocialMediaAccountLoginScreen'} onPress={() => navigation.navigate("SocialMediaAccountLoginScreen")} />
            <CustomTextItem content={'social-media-account'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'SocialMediaAccountRegistrationScreen'} onPress={() => navigation.navigate("SocialMediaAccountRegistrationScreen")} />
            <CustomTextItem content={'Sorting'} onPress={() => navigation.navigate("Sorting")} />
            <CustomTextItem content={'Filteritems'} onPress={() => navigation.navigate("Filteritems")} />
            <CustomTextItem content={'ForgotPassword'} onPress={() => navigation.navigate("ForgotPassword")} />
            <CustomTextItem content={'OTPInputAuth'} onPress={() => navigation.navigate("OTPInputAuth")} />
            <CustomTextItem content={'salesreporting'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'TermsConditions'} onPress={() => navigation.navigate("TermsConditions")} />
            <CustomTextItem content={'Categoriessubcategories'} onPress={() => navigation.navigate("Categoriessubcategories")} />
            <CustomTextItem content={'PhoneNumberInput'} onPress={() => navigation.navigate("PhoneNumberInput")} />
            <CustomTextItem content={'EmailAccountRegistration'} onPress={() => navigation.navigate("EmailAccountRegistration")} />
            <CustomTextItem content={'customform'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'LandingPage'} onPress={() => navigation.navigate("LandingPage")} />
            <CustomTextItem content={'Reviews'} onPress={() => navigation.navigate("Reviews")} />
            <CustomTextItem content={'Analytics'} onPress={() => navigation.navigate("Analytics")} />
            <CustomTextItem content={'promocodes'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'MobileAccountLoginBlock'} onPress={() => navigation.navigate("MobileAccountLoginBlock")} />
            <CustomTextItem content={'ShoppingCartOrders'} onPress={() => navigation.navigate("ShoppingCartOrders")} />
            <CustomTextItem content={'Favourites'} onPress={() => navigation.navigate("Favourites")} />
            <CustomTextItem content={'Contactus'} onPress={() => navigation.navigate("Contactus")} />
            <CustomTextItem content={'CarouselDisplay'} onPress={() => navigation.navigate("CarouselDisplay")} />
            <CustomTextItem content={'LanguageSupport'} onPress={() => navigation.navigate("LanguageSupport")} />
            <CustomTextItem content={'searchengineoptimisationseo'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'Geofence'} onPress={() => navigation.navigate("Geofence")} />
            <CustomTextItem content={'EmailAccountLoginBlock'} onPress={() => navigation.navigate("EmailAccountLoginBlock")} />
            <CustomTextItem content={'Adminconsole2'} onPress={() => navigation.navigate("Adminconsole2")} />
            <CustomTextItem content={'Rolesandpermissions'} onPress={() => navigation.navigate("Rolesandpermissions")} />
            <CustomTextItem content={'Customadvertisements2'} onPress={() => navigation.navigate("Customadvertisements2")} />
            <CustomTextItem content={'Inventorymanagementintegration2'} onPress={() => navigation.navigate("Inventorymanagementintegration2")} />
            <CustomTextItem content={'Emailnotifications2'} onPress={() => navigation.navigate("Emailnotifications2")} />
            <CustomTextItem content={'Servicespecificsettingsadmin2'} onPress={() => navigation.navigate("Servicespecificsettingsadmin2")} />
            <CustomTextItem content={'Cfchatapiintegration2'} onPress={() => navigation.navigate("Cfchatapiintegration2")} />
            <CustomTextItem content={'Cflogisticsapiintegration2'} onPress={() => navigation.navigate("Cflogisticsapiintegration2")} />
            <CustomTextItem content={'SellerLanding'} onPress={() => navigation.navigate("SellerLanding")} />
            <CustomTextItem content={'SellerAccountRegistration'} onPress={() => navigation.navigate("SellerAccountRegistration")} /> 
            <CustomTextItem content={'HomePage'} onPress={() => navigation.navigate("HomePage")} />
            <CustomTextItem content={'SellerDashboard'} onPress={() => navigation.navigate("SellerDashboard")} />
            <CustomTextItem content={'SellerLoginEmail'} onPress={() => navigation.navigate("SellerLoginEmail")} />
            <CustomTextItem content={'SellerNavigationBar'} onPress={() => navigation.navigate("SellerNavigationBar")} />
            <CustomTextItem content={'SellerHomeDashboard'} onPress={() => navigation.navigate("SellerHomeDashboard")} />
            <CustomTextItem content={'SellerForgotPassword'} onPress={() => navigation.navigate("SellerForgotPassword")} />
            <CustomTextItem content={'BuyerSignup'} onPress={() => navigation.navigate("BuyerSignup")} />
            <CustomTextItem content={'StaticPage'} onPress={() => navigation.navigate("StaticPage")} />
            <CustomTextItem content={'HomeContactUs'} onPress={() => navigation.navigate("HomeContactUs")} />
          </View> 
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;