import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../../framework/src/Utilities";
import { ICategoryData, IMicroCategoriesData, IMiniCategoriesDataValue } from "./Interfaces";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    errorMsg: string;
    header: string;
    radio: string;
    card: string;
    textOne: string;
    textTwo: string;
    section: string
  };
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  expandBar: boolean;
  openProfileMenu: boolean;
  auth_token: string;
  data: ICategoryData[];
  category: string;
  sub_category: string;
  micro_category: unknown;
  micro_category_data: [];
  mini_category: string;
  category_id: string | number;
  sub_category_id: string | number;
  micro_data: IMicroCategoriesData[];
  allMiniMicroData: IMiniCategoriesDataValue[];
  micro_category_id: string | number;
  mini_category_id: string | number;
  mini_data: IMiniCategoriesDataValue[]; //IMiniCategsData[];
  searchCategoryValue: string;
  openSearchCategoryResult: boolean;
  searchCategoryResults: any[];
  categoryId: string;
  categoryError: string;
  productId: string;
  microCategoryId: string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerCategorizeController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetCategoriesCallId: string = "";
  apiGetMiniMicroCategoriesCallId: string = "";
  apiGetSearchCategoriesCallId: string = "";
  getProductDetailsApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      expandBar: false,
      openProfileMenu: false,
      auth_token: "",
      data: [],
      category: "",
      sub_category: "",
      micro_category_data: [],
      micro_category: "",
      mini_category: "",
      category_id: "",
      sub_category_id: "",
      allMiniMicroData: [],
      micro_data: [],
      mini_data: [],
      micro_category_id: "",
      mini_category_id: "",
      searchCategoryValue: "",
      openSearchCategoryResult: false,
      searchCategoryResults: [],
      categoryId: "",
      categoryError: "",
      productId: "",
      microCategoryId: ""
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const id = message.getData(getName(MessageEnum.ProductData));
      if(id) {
        this.setState({ productId: JSON.parse(id) });
      }
      if (this.state.productId) {
        this.getProductCategory();
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.apiGetSearchCategoriesCallId) {
        this.handleCategoryResult(responseJson);
      }
      if (apiRequestCallId === this.getProductDetailsApiCallId) {
        this.handleProductCategory(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area

  handleProductCategory = (responseJson: any) => {
    if (responseJson.data) {
      const categoryValue = responseJson.data.attributes?.micro_category?.name;
      const { brand, category, sub_category, mini_category, micro_category } = responseJson.data?.attributes;
      setStorageData("brand_id", brand.id);
      setStorageData("catalog_id", this.state.productId);
      setStorageData("category_id", category.id);
      setStorageData("sub_category_id", sub_category.id);
      setStorageData("mini_category_id", mini_category.id);
      setStorageData("micro_category_id", micro_category.id);
      this.setState({ searchCategoryValue: categoryValue, openSearchCategoryResult: true, categoryId: this.state.productId });
      this.getSearchResults();
    }
  };

  handleClickAwaySearch = () => {
    this.setState({ openSearchCategoryResult: true });
  };

  handleChangeCategory = (item: any) => {
    const { category_id, mini_category_id, sub_category_id } = item.attributes;
    this.setState({ categoryId: item.id, productId: "", categoryError: "" });
    setStorageData("category_id", category_id);
    setStorageData("sub_category_id", sub_category_id);
    setStorageData("mini_category_id", mini_category_id);
    setStorageData("micro_category_id", item.id);
  };

  handleCategoryResult = (responseJson: any) => {
    if (responseJson.data) {
      if (this.state.productId) {
        this.setState({ searchCategoryResults: responseJson.data, microCategoryId: responseJson.data[0].id });
      } else this.setState({ searchCategoryResults: responseJson.data, microCategoryId: "", categoryId: "" });
    } else this.setState({ searchCategoryResults: [], microCategoryId: "", categoryId: "" });
  };

  SearchDebounce = (call: any, delay: any) => {
    let timer: any;
    return function(...args: any) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        call(...args);
      }, delay);
    };
  };

  handleOnSubmit = () => {
    const { categoryId } = this.state;
    if (categoryId) {
      this.setState({ searchCategoryValue: "" });
      this.handleGoToSearchCategory();
    } else this.setState({ categoryError: "Please select category" });
  };

  handleGoToSearchCategory = () => {
    const path = this.props.id === configJSON.bulkUploadCategoryId ? configJSON.bulkUploadSearchBrandPath : configJSON.searchBrandMenu;
    this.props.navigation.navigate(configJSON.sellerHomeText, {path: path})
    const msg: Message = new Message(getName(MessageEnum.NavigationSellerDashboardMessage));
    msg.addData(getName(MessageEnum.ChangeMenuPanel), path);
    msg.addData(getName(MessageEnum.ProductData), JSON.stringify(this.state.productId));
    this.send(msg);
  };

  getSearchResults = async () => {
    const headers = { "Content-Type": configJSON.contentType, token: await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetSearchCategoriesCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_categories/categories/search_micro_categories?search_query=${this.state.searchCategoryValue}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  SearchDebounceUpdate = this.SearchDebounce(this.getSearchResults, 1000);

  getProductCategory = async () => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: await getStorageData("authToken")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProductDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/${this.state.productId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.props.id !== prevProps.id) {
      this.setState({ searchCategoryValue: "", searchCategoryResults: [], openSearchCategoryResult: false, categoryId: "", categoryError: "" });
      setStorageData("category_id", null);
      setStorageData("sub_category_id", null);
      setStorageData("mini_category_id", null);
      setStorageData("micro_category_id", null);
    }
  }

  setSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value) {
      this.setState({ searchCategoryValue: event.target.value, openSearchCategoryResult: true, categoryError: "", productId: "" });
      this.SearchDebounceUpdate(event.target.value);
    } else this.setState({ searchCategoryValue: "", openSearchCategoryResult: false, microCategoryId: "", categoryId: "", searchCategoryResults: [] });
  };
  // Customizable Area End
}
