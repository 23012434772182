// Customizable Area Start
import { withStyles } from "@material-ui/styles";
import React from "react";
import CreateAddressController, {
  Props,
  configJSON,
} from "./CreateAddressController.web";
import HeaderWeb from "../../../landingpage/src/Header.web";
import {
  Box,
  Container,
  Divider,
  Grid,
  InputAdornment,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  address,
  align,
  background,
  cartBox,
  cartBoxDes,
  cartText,
  detail,
  finalPriceHeader,
  horizontal,
  link,
  path,
  priceContainer,
  span,
  wrapper,
  zoom,
} from "./commomstyles";
import {
  CustomizeShippingInput,
  FormControlTwo,
  MenuItemTwo,
  OrangeButton,
} from "../../../dashboard/src/CustomizedElementStyle";
import { paymentMethods } from "../assets";
import { Link, withRouter } from "react-router-dom";
import { Form, Formik } from "formik";
import { ScrollView } from "react-native";
import Footer from "../../../landingpage/src/Footer.web";
// Customizable Area End

class CreateShippingAddress extends CreateAddressController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderErrorMsg = () => {
    return this.state.setErrorMsg ? <Typography className={this.props.classes.paymentError}>Please select shipping address.</Typography> : ''
  }

  renderAddressErrors = (fieldName: string, touched: any, errors: any) => {
    return touched[fieldName] && errors[fieldName] ? (
      <Typography className={this.props.classes.error}>
        {errors[fieldName]}
      </Typography>
    ) : null;
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { total_fees, total_tax, final_price } = this.state.orderPriceDetails
    const addressDetails = this.state.addressDetails?.attributes;
    let initialValues = {
      firstName: addressDetails?.first_name || "",
      lastName: addressDetails?.last_name || "",
      addressOne: addressDetails?.address_1 || "",
      addressTwo: addressDetails?.address_2 || "",
      phoneNumber: this.setNumber(addressDetails?.phone_number),
      selectedState: addressDetails?.state || "",
      city: addressDetails?.city || "",
      zipCode: addressDetails?.zip_code || "",
    }
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      <ScrollView>
        <HeaderWeb navigation={this.props.navigation} id={this.props.id} />
        <Container className={classes.container}>
        <Box className={classes.background}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.navigation}>
            <Link to={"/"} rel="noopener" className={classes.linkText}>
              <Typography className={classes.path}>
                {configJSON.home}
                </Typography>
                </Link>
                <Link to={"/shopping-cart"} rel="noopener" className={classes.linkText}>
                <Typography className={classes.path}> / {configJSON.cartText}{" "} </Typography>
                </Link>
                <span className={classes.span}>/ {configJSON.address}</span>
            </Grid>
          </Grid>
          <Box className={classes.cartBox}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={9} lg={9} className={classes.position}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={classes.cartText}>
                      {configJSON.shippingAddressLabel}
                    </Typography>
                  </Grid>
                </Grid>

                <Divider className={classes.divider} orientation="vertical" />
                <Grid container className={classes.descContainer}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={classes.cartBoxDes}>
                      {""}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={9} lg={9}>
                <Formik
                  data-test-id="formik"
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={this.Schema}
                  validateOnMount={true}
                  validateOnChange={true}
                  onSubmit={this.onSubmit}
                >
                  {(formikProps) => {
                    const { values, handleChange, touched, errors, setFieldValue } =
                      formikProps;
                      const renderInputField = (fieldName:string, placeholder:string, label: string, value:string) => (
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          <CustomizeShippingInput
                            data-test-id={fieldName}
                            placeholder={placeholder}
                            label={label}
                            id="outlined-start-adornment"
                            value={value}
                            onChange={fieldName === "phoneNumber" ? this.handlePhoneNumberChange.bind(this,setFieldValue) : handleChange(fieldName)}
                            className={classes.phoneNumber}
                            InputProps={{
                              startAdornment: <InputAdornment position="start"></InputAdornment>,
                            }}
                          />
                          {(touched as any)[fieldName] && (errors as any)[fieldName] ? (
                            <Typography className={classes.errorOne}>{(errors as any)[fieldName]}</Typography>
                          ) : null}
                        </Grid>
                      );
                    return (
                      <Form translate={undefined}>
                        <Grid
                          container
                          spacing={2}
                          className={classes.marginOne}
                        >
                          {renderInputField(
                            "firstName",
                            configJSON.firstNamePlaceholder,
                            configJSON.firstName,
                            values.firstName
                          )}
                          {renderInputField(
                            "lastName",
                            configJSON.lastNamePlaceholder,
                            configJSON.lastName,
                            values.lastName
                          )}
                        </Grid>
                        <Grid container className={classes.marginOne}>
                          <Grid item xs={12} sm={8} md={8} lg={8}>
                            <CustomizeShippingInput
                              data-test-id="addressOne"
                              placeholder={configJSON.addressPlaceholder}
                              label={configJSON.addressOne}
                              id="outlined-start-adornment"
                              value={values.addressOne}
                              onChange={handleChange("addressOne")}
                              className={classes.addressWidth}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start"></InputAdornment>
                                ),
                              }}
                            />
                            {this.renderAddressErrors('addressOne', touched, errors)}
                          </Grid>
                        </Grid>
                        <Grid container className={classes.topMargin}>
                          <Grid item xs={12} sm={8} md={8} lg={8}>
                            <CustomizeShippingInput
                              data-test-id="addressTwo"
                              placeholder={configJSON.addressPlaceholder}
                              label={configJSON.addressTwo}
                              id="outlined-start-adornment"
                              value={values.addressTwo}
                              onChange={handleChange("addressTwo")}
                              className={classes.addressWidth}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start"></InputAdornment>
                                ),
                              }}
                            />
                            {this.renderAddressErrors('addressTwo', touched, errors)}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          className={classes.marginTwo}
                        >
                          {renderInputField(
                            "phoneNumber",
                            configJSON.phoneNumberPlaceholder,
                            configJSON.phoneNumberLabel,
                            values.phoneNumber
                          )}
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            <FormControlTwo fullWidth={true}>
                            <Box style={{
                                top: '-22px',
                                position:'absolute',
                                left: 0,
                                color:' #666666',
                                fontSize: '11px',
                                fontFamily: "Lato, sans-serif",
                                fontWeight: 400
                            }}>State</Box>
                          <Select
                              onChange={(event:React.ChangeEvent<{value: unknown}>) => this.handleStateChange(event,setFieldValue)}
                              value={values.selectedState}
                              data-test-id="selectedState"
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              displayEmpty
                              disableUnderline
                              defaultValue={""}
                              style={{
                                border: '1px solid #b1b3b5',
                                fontSize: '16px !important',
                                fontFamily: "AvenirNext-Regular, sans-serif",
                                fontWeight: 400,
                                color: 'rgba(102,102,102)'
                              }}
                              className={classes.selectedState}
                              inputProps={{
                                'aria-label': 'Without label', 
                                style: {
                                    borderBottom: 'none', background: 'white', 
                                    height: "53px",
                                    marginTop: "9px",
                                    fontSize: '16px !important',
                                    fontFamily: "AvenirNext-Regular, sans-serif",
                                    fontWeight: 400,
                                    color: 'rgba(102,102,102,0.5)'
                                }
                              }}
                          >
                              <MenuItemTwo value={""} disabled><span style={{ color: 'rgba(102,102,102,0.5)',
                                fontSize: '12px',
                                fontFamily: "AvenirNext-Regular, sans-serif",
                                fontWeight: 400}} >
                                {'Select State'}</span>
                              </MenuItemTwo>
                              <MenuItemTwo value="Abu Dhabi">Abu Dhabi</MenuItemTwo>
                              <MenuItemTwo value="Dubai">Dubai</MenuItemTwo>
                              <MenuItemTwo value="Sharjah">Sharjah</MenuItemTwo>
                              <MenuItemTwo value="Ajman">Ajman</MenuItemTwo>
                              <MenuItemTwo value="Umm Al-Quwain">Umm Al-Quwain</MenuItemTwo>
                              <MenuItemTwo value="Fujairah">Fujairah</MenuItemTwo>
                              <MenuItemTwo value="Ras Al Khaimah">Ras Al Khaimah</MenuItemTwo>
                          </Select>
                          </FormControlTwo>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          style={{marginTop:'10px'}}
                          className={classes.marginTwo}
                        >
                          {renderInputField(
                            "city",
                            configJSON.cityPlaceholder,
                            configJSON.cityLabel,
                            values.city
                          )}
                          {renderInputField(
                            "zipCode",
                            configJSON.zipCodePlaceholder,
                            configJSON.zipCodeLabel,
                            values.zipCode
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={8}
                          lg={8}
                          className={classes.flexTwo}
                        >
                          <OrangeButton
                            type="submit"
                            disabled={this.state.isClicked}
                            className={classes.continue}
                          >
                            {configJSON.addAddressContinueBtn}
                          </OrangeButton>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.orderContainer}
              >
                <Grid container className={classes.section}>
                  <Typography className={classes.orderSummary}>
                    {configJSON.orderSummaryLabel}
                  </Typography>
                  <Typography
                    className={classes.address}
                    style={{ fontSize: "14px" }}
                  >
                    {""}
                  </Typography>
                </Grid>
                <Grid container className={classes.priceContainer}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={`${classes.text} ${classes.bottomContainer}`}
                  >
                    <Grid container>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Typography className={classes.detail}>
                      {configJSON.totalPrice}
                    </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} className={classes.left}>
                      <Typography className={classes.detailData}>AED {total_fees}</Typography>
                      </Grid>
                    </Grid>
                    </Grid>
                    <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={`${classes.text}`}
                  >
                    <Grid container>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography className={classes.detail}>
                      {configJSON.tax}
                    </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} className={classes.left}>
                    <Typography className={classes.detailData}>AED {total_tax}</Typography>
                    </Grid>
                    </Grid>
                    </Grid>
                    <Divider className={classes.horizontal} />
                    <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}>
                      <Grid container>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Typography
                      className={`${classes.detail} ${classes.totalStyle}`}
                    >
                      {configJSON.total}
                    </Typography>
                    </Grid>
                    <Grid item xs={9} sm={9} md={9} lg={9} className={classes.left}>
                    <Typography
                      className={`${classes.finalData} ${classes.totalStyle}`}
                    >
                      AED {final_price}
                    </Typography>
                    </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <OrangeButton onClick={this.handlePaymentNowBtn} className={classes.paymentBtn}>
                  {configJSON.paymentNowBtn}
                </OrangeButton>
                <img
                  src={paymentMethods}
                  alt="payment_icon"
                  className={classes.paymentImage}
                />
                {this.renderErrorMsg()}
              </Grid>
            </Grid>
          </Box>
        </Box>
        </Container>
        <Footer navigation={this.props.navigation} id={this.props.id} categories={[]} token={""} />
        </ScrollView>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = (theme:Theme) => ({
  align: {
    textAlign: "end" as const,
  },
  marginOne: {
    marginTop: "20px",
  },
  marginTwo: {
    marginTop: "30px",
  },
  marginThree: {
    marginTop: "50px",
  },
  flexOne: {
    display: "flex",
    alignItems: "center",
  },
  flexTwo: {
    display: "flex",
    justifyContent: "flex-end",
  },
  orderContainer: {
    padding: "8px 40px 8px 70px !important",
  },
  priceSection: {
    paddingTop: "50px",
    display: "flex",
    alignItems: "flex-end !important",
  },
  section: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  path: path,
  background: background,
  span: span,
  cartText: cartText,
  cartBox: cartBox,
  cartBoxDes: cartBoxDes,
  divider: {
    height: "60vh",
    position: "absolute" as const,
    left: "102%",
  },
  bottomText: {
    marginLeft: "10px",
    color: "#585858",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "18px",
    fontWeight: 400 as const,
  },
  billingAddress: {
    color: "#57aece",
    fontFamily: `"Lato-Semibold", sans-serif`,
    fontSize: "18px",
    fontWeight: 400 as const,
  },
  continue: {
    margin: "50px 0",
    color: "#ffffff",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "12px",
    fontWeight: 400 as const,
    lineHeight: "20px",
    textAlign: "center" as const,
    textTransform: "uppercase" as const,
    letterSpacing: "2px",
    padding:"15px 32px"
  },
  orderSummary: {
    color: "#33333a",
    fontSize: "24px",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 600 as const,
  },
  error: {
    color: "red",
    textAlign: "left" as const,
    fontSize: "12px",
    marginTop: "5px",
  },
  paymentError: {
    color: "red",
    textAlign: "center" as const,
    fontSize: "12px",
    marginTop: "5px",
  },
  detail: detail,
  paymentBtn: {
    padding : '10px 16px',
    marginTop:'30px',
    fontSize:'12px',
    fontFamily: `"Montserrat", sans-serif`,
    fontWeight: 700 as const,
    width:'100%',
    textTransform: 'uppercase' as const,
    letterSpacing: '2px'
  },
  priceContainer: priceContainer,
  horizontal: horizontal,
  bottomContainer: {
      paddingTop:'50px',
      display:'flex'
  },
  text: {
    marginTop:'10px',
    display:'flex',
    alignItems:'flex-start',
    flexDirection:'column' as const,
    justifyContent: 'space-between'
  },
  address: address,
  detailOne: finalPriceHeader,
  detailData: {
    textAlign:'right' as const,
    color: "#545454",
    fontSize: "13px",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 400 as const,
  },
  left: align,
  container: zoom,
  navigation: wrapper,
  linkText: link,
  fieldMargin: {
    marginTop: '0',
  },
  errorOne: {
    color: "red",
    textAlign: "left" as const,
    fontSize: "12px",
    marginTop: "-18px",
    marginBottom:'20px'
  },
  paymentImage: {
    marginTop: "5px",
    width:'100%' 
  },
  finalData : finalPriceHeader,
  position: {
    position:'relative' as const
  },
  descContainer: { 
    justifyContent: "space-between" 
  },
  phoneNumber: { 
    width: "100%", 
    marginBottom: '20px' 
  },
  addressWidth: { 
    width: "99%" 
  },
  topMargin: {
    marginTop:'40px'
  },
  topMarginTwo: {
    marginTop:'10px'
  },
  totalStyle : {
    margin: "10px 0px"
  },
  selectedState: {
    '& .MuiInputBase-input': {
      fontSize: '16px !important',
      fontFamily: "AvenirNext-Regular, sans-serif !important",
      fontWeight: 400,
      color: 'rgba(102,102,102)'
    }
  }
});

export { CreateShippingAddress };
export default withRouter(
  withStyles(styles)(CreateShippingAddress) as any
) as any;
// Customizable Area End
