import React from "react";

// Customizable Area Start
import { Box, Button, Grid, Input, Modal, Typography, IconButton } from "@material-ui/core";
import { pdf } from "../assets";
import Close from "@material-ui/icons/Close";
// Customizable Area End

import SellerMyDocumentsController, { Props } from "./SellerMyDocumentsController";
import { ClickableBox, CustomTextField } from "../../../mobile-account-login/src/CustomElementStyling";
import { BoxNoScroll, CustomBackButton, CustomSubmitReuploadedButton } from "../CustomizedElementStyle";

export default class SellerMyDocuments extends SellerMyDocumentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ReUploadFileModal = () => {
    return (
      <Modal open={this.state.showReUploadFileModal}>
        <Box sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center " }} style={{ background: "rgba(0,0,0,0.1)" }}>
          <Box sx={{ width: "500px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", borderRadius: "8px", padding: "30px" }} style={{ background: "white" }}>
            <Box sx={{ marginBottom: "10px", textAlign: "start", fontFamily: `"Lato", sans-serif`, fontWeight: 600 }}>Rejection Reason:</Box>
            <Box sx={{ marginBottom: "10px", textAlign: "start", fontFamily: `"Lato", sans-serif` }}>{this.state.rejectionReason}</Box>
            {this.state.documentType === "Don't have VAT Certificate" && (
              <>
                <CustomTextField data-test-id="vatReasonRef" label="Vat Reason" fullWidth style={{ marginTop: "-10px", marginBottom: "20px" }} value={this.state.vatReason} onChange={event => this.handleInputOnChange("vatReason", event.target.value)} />
                {this.state.vatReasonErrorMsg ? (
                  <Typography data-test-id="accountErr" style={webStyle.errorText}>
                    {this.state.vatReasonErrorMsg}
                  </Typography>
                ) : (
                  ""
                )}
              </>
            )}
            {this.state.documentType === "Don't have IBAN Certificate" && (
              <>
                <CustomTextField data-test-id="accountNoRef" label="Account no*" fullWidth style={{ marginTop: "-10px", marginBottom: "20px" }} value={this.state.accountNo} onChange={event => this.handleInputOnChange("accountNo", event.target.value)} />
                {this.state.accountErr ? (
                  <Typography data-test-id="accountErr" style={webStyle.errorText}>
                    {this.state.accountErr}
                  </Typography>
                ) : (
                  ""
                )}
                <CustomTextField data-test-id="ibanRef" label="IBAN*" fullWidth style={{ marginTop: "-10px", marginBottom: "20px" }} value={this.state.iban} onChange={event => this.handleInputOnChange("iban", event.target.value)} />
                {this.state.ibanErr ? (
                  <Typography data-test-id="ibanErr" style={webStyle.errorText}>
                    {this.state.ibanErr}
                  </Typography>
                ) : (
                  ""
                )}
                <CustomTextField data-test-id="bankAddressRef" label="Bank Address*" fullWidth style={{ marginTop: "-10px", marginBottom: "20px" }} value={this.state.bankAddress} onChange={event => this.handleInputOnChange("bankAddress", event.target.value)} />
                {this.state.bankAdd ? (
                  <Typography data-test-id="bankAdd" style={webStyle.errorText}>
                    {this.state.bankAdd}
                  </Typography>
                ) : (
                  ""
                )}
                <CustomTextField data-test-id="nameRef" label="Name*" fullWidth style={{ marginTop: "-10px", marginBottom: "20px" }} value={this.state.name} onChange={event => this.handleInputOnChange("name", event.target.value)} />
                {this.state.nameErr ? (
                  <Typography data-test-id="bankNameErr" style={webStyle.errorText}>
                    {this.state.nameErr}
                  </Typography>
                ) : (
                  ""
                )}
                <CustomTextField data-test-id="bankNameRef" label="Bank Name*" fullWidth style={{ marginTop: "-10px", marginBottom: "20px" }} value={this.state.bankName} onChange={event => this.handleInputOnChange("bankName", event.target.value)} />
                {this.state.bankNameErr ? (
                  <Typography data-test-id="bankNameErr" style={webStyle.errorText}>
                    {this.state.bankNameErr}
                  </Typography>
                ) : (
                  ""
                )}
                <CustomTextField data-test-id="swiftCodeRef" label="Swift Code*" fullWidth style={{ marginTop: "-10px", marginBottom: "20px" }} value={this.state.swiftCode} onChange={event => this.handleInputOnChange("swiftCode", event.target.value)} />
                {this.state.swiftCodeErr ? (
                  <Typography data-test-id="swiftCodeErr" style={webStyle.errorText}>
                    {this.state.swiftCodeErr}
                  </Typography>
                ) : (
                  ""
                )}
              </>
            )}
            {this.state.documentType !== "Don't have VAT Certificate" && this.state.documentType !== "Don't have IBAN Certificate" && (
              <>
                <Input
                  data-test-id="inputFileRef"
                  type="file"
                  style={{ display: "none" }}
                  onChange={event => this.handleReUploadFile(event)}
                  value=""
                  inputProps={{
                    "aria-label": "upload documents",
                    ref: this.inputRef,
                    accept: ".pdf"
                  }}
                />
                <label htmlFor="upload-button">
                  <Button
                    data-test-id="uploadButtonRef"
                    variant="outlined"
                    fullWidth
                    style={{
                      width: "100%",
                      padding: "20px 0px",
                      margin: "20px 0px",
                      border: "none",
                      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23797979FF' stroke-width='3' stroke-dasharray='6%2c 6' stroke-dashoffset='19' stroke-linecap='square'/%3e%3c/svg%3e")`,
                      borderRadius: "5px",
                      textTransform: "none",
                      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
                      color: "#646464",
                      backgroundColor: "#f3f3f3"
                    }}
                    onClick={this.handleOpenFileDialog}
                  >
                    Upload Document
                  </Button>
                </label>
                {this.state.reuploadedFiles?.map((item, index) => (
                  <Box sx={{ marginBottom: "10px", textAlign: "start", fontFamily: `"Lato", sans-serif` }}>
                    <Grid item xl={12} lg={12} sm={12} xs={12} key={`${item.name}-${index}`}>
                      <Box>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
                          <Box>
                            <Typography variant="h6">{item.name}</Typography>
                          </Box>
                          <Box>
                            <IconButton data-test-id="removeFileButtonRef" style={{ margin: 0, padding: 0, color: "black" }} onClick={() => this.handleRemoveFile(index)}>
                              <Close />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Box>
                ))}
              </>
            )}
            {this.state.errorMessages.map((item, index) => {
              return (
                <Box sx={{ marginBottom: "10px", textAlign: "start", fontFamily: `"Lato", sans-serif`, color: "rgb(215, 77, 77)" }} key={`${index}${index}`}>
                  {item}
                </Box>
              );
            })}
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <CustomBackButton data-test-id="backRef" onClick={() => this.handleShowReUploadFileModal()}>
                Back
              </CustomBackButton>
              <CustomSubmitReuploadedButton data-test-id="submitRef" onClick={this.handleSubmitReUploadedFile} disabled={this.state.disableButtonOnUpload}>
                Submit
              </CustomSubmitReuploadedButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        {this.ReUploadFileModal()}
        <Box sx={{ padding: "20px 0px 15px 0px", fontSize: "18px", fontWeight: 900, fontFamily: `"Lato", sans-serif` }}>My Documents</Box>
        <BoxNoScroll sx={{ paddingBottom: 20, overflow: "auto" }}>
          <Box sx={{ minHeight: 800, borderRadius: "8px", display: "flex", flexDirection: "column", padding: "30px" }} style={{ background: "white" }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", marginBottom: "56px" }}>
              <Box sx={{ fontSize: "27px", fontWeight: 1000, fontFamily: `"Lato", sans-serif` }}>My Documents</Box>
            </Box>

            <Grid container direction="column" spacing={3}>
              {this.state.documents.map((item, index) => {
                return (
                  <Grid item key={index}>
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }}>
                      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Box sx={{ padding: "17px", borderRadius: "5px", border: "1px solid #E1E1E1", marginRight: "30px" }} style={{ background: "#F6F6F6" }}>
                          <img src={pdf} />
                        </Box>
                        <Box>
                          <Box sx={{ fontSize: 14, fontWeight: 700, color: "#585858", fontFamily: `"Lato", sans-serif`, marginBottom: "10px" }}>{item.attributes.document_name}</Box>
                          <Box sx={{ fontSize: 14, fontWeight: 400, color: "#999999", fontFamily: `"Lato", sans-serif` }}>{this.handleConvertDate(item.attributes.created_at)}</Box>
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        {item.attributes.approved && (
                          <Box
                            sx={{
                              padding: "10px",
                              color: "white",
                              borderRadius: "5px",
                              fontWeight: 600,
                              fontFamily: `"Lato", sans-serif`,
                              width: 70,
                              textAlign: "center"
                            }}
                            style={{ background: "rgb(80, 175, 28)" }}
                          >
                            Approved
                          </Box>
                        )}
                        {!item.attributes.approved && item.attributes.approved !== null && (
                          <>
                            <ClickableBox
                              data-test-id="retryRef"
                              sx={{
                                padding: "10px",
                                color: "white",
                                borderRadius: "5px",
                                fontWeight: 600,
                                fontFamily: `"Lato", sans-serif`,
                                width: 70,
                                textAlign: "center",
                                marginRight: 10
                              }}
                              onClick={() => {
                                this.handleShowReUploadFileModal();
                                this.setState({ rejectionReason: item.attributes.reason_for_rejection, file_id: item.id, documentType: item.attributes.document_name, reuploadedFiles: [] });
                              }}
                              style={{ background: "rgb(255, 96, 8)" }}
                            >
                              Retry
                            </ClickableBox>
                            <Box
                              sx={{
                                padding: "10px",
                                color: "white",
                                borderRadius: "5px",
                                fontWeight: 600,
                                fontFamily: `"Lato", sans-serif`,
                                width: 70,
                                textAlign: "center"
                              }}
                              style={{ background: "#d2140f" }}
                            >
                              Rejected
                            </Box>
                          </>
                        )}
                        {item.attributes.approved === null && (
                          <Box
                            sx={{
                              padding: "10px",
                              color: "white",
                              borderRadius: "5px",
                              fontWeight: 600,
                              fontFamily: `"Lato", sans-serif`,
                              width: 70,
                              textAlign: "center"
                            }}
                            style={{ background: "rgb(62, 62, 62)" }}
                          >
                            Under Review
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </BoxNoScroll>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  errorText: {
    color: "red",
    fontSize: "12px",
    marginTop: "5px",
    padding: "0 0 10 0"
  }
};
// Customizable Area End
