// Customizable Area Start
import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { toast } from 'react-toastify';

export class MapComponent extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      mapCenter: {
        lat: "",
        lng: ""
      }
    };
  }

  componentDidMount() {
    this.setState({
      mapCenter: {
        lat: this.props.lat,
        lng: this.props.lng
      }
    })
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.mapCenter !== this.props.mapCenter) {
      this.setState({
        mapCenter: {
          lat: this.props.mapCenter.lat,
          lng: this.props.mapCenter.lng
        }
      })
    }
  }

  onMarkerDragEnd = ( props: google.maps.MapMouseEvent,marker: {position: google.maps.LatLng},event: google.maps.MapMouseEvent) => {
    const latitude = marker.position.lat();
    const longitude = marker.position.lng();

    const geocoder = new (this.props.google as typeof google).maps.Geocoder();
    const latLng = new (this.props.google as typeof google).maps.LatLng(latitude, longitude);

    geocoder.geocode({ location: latLng }, (results , status) => {
      if (status === 'OK' && results && results[0]) {
        this.props.handlePlacesAutocompleteChange(results[0].formatted_address)
        this.props.handlePlacesAutocompleteSelect(results[0].formatted_address)
      } else {
        toast.error("Something went wrong");
      }
  });
}

  render() {

    return (
      <div id='googleMaps'>

        <div style={{
          position: "relative",
          height: "300px"
        }}>

          <Map
            google={this.props.google}
            initialCenter={{
              lat: this.state.mapCenter.lat,
              lng: this.state.mapCenter.lng
            }}
            center={{
              lat: this.state.mapCenter.lat,
              lng: this.state.mapCenter.lng
            }}
            zoom={10}

          >
            <Marker
              position={{
                lat: this.state.mapCenter.lat,
                lng: this.state.mapCenter.lng
              }}
              draggable={true}
            //@ts-ignore
            onDragend={this.onMarkerDragEnd}
               />
          </Map>
        </div>

      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyDTM9RzL0P4rBXptLIOgfVAQLX92vUnE4c')

})(MapComponent)

// Customizable Area End
