import React from "react";
import { Box, Button, Grid, InputAdornment, Theme, Typography, withStyles } from "@material-ui/core";
import StoreSectionThreeTabController, { Props, configJSON } from "./StoreSectionThreeTabController.web";
import { ClickableBox } from "../../../mobile-account-login/src/CustomElementStyling";
import { file } from "../assets";
import { CustomizeBrandInput, NextButton } from "../CustomizedElementStyle";
import { errorImage } from "../../../bulkuploading/src/assets";
// Customizable Area End

class StoreSectionThreeTab extends StoreSectionThreeTabController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  showCommonErr = () => {
    return (
      <>
        {this.state.commonErr ? (
          <Box className={this.props.classes.commonErrBox}>
            <Box className={this.props.classes.errorMargin}>
              <img src={errorImage} />
            </Box>
            <Box>
              <Typography data-test-id="commonErrTwo" className={this.props.classes.errorTextOne}>
                {this.state.commonErr}
              </Typography>
            </Box>
          </Box>
        ) : (
          ""
        )}
      </>
    );
  };

  renderSection3Grid2 = () => {
    const { classes } = this.props;
    return (
      <>
        <Grid container className={classes.marginStyle} spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddingStyle}>
            <Typography className={classes.headingTwo}>{configJSON.sectionThreeGridTwoText}</Typography>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topSpace}>
          <CustomizeBrandInput
            data-test-id="nameTwo"
            label={configJSON.gridNameLabel}
            id="outlined-start-adornment"
            value={this.state.nameTwo}
            onChange={this.handleGridNameChange.bind(this, "nameTwo")}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>
            }}
            inputProps={{
              style: webStyle.type,
              maxLength: 15
            }}
          />
          {this.state.setNameError ? (
            <Typography data-test-id="nameTwoErr" className={classes.errorText}>
              {this.state.setNameError}
            </Typography>
          ) : (
            ""
          )}
        </Grid>
        <Box className={classes.imgLabel}>{configJSON.gridImageLabel}</Box>
        <input data-test-id="gridImageTwo" type="file" accept=".jpeg, .jpg, .png" ref={this.secThreeGridTwoImgRef} className={classes.imgInput} value="" onChange={this.handleUploadGridImage.bind(this, "imageTwo")} />
        {this.state.previewImgTwo ? (
          <Box className={classes.preview}>
            <img src={this.state.previewImgTwo} alt="Image Preview" className={classes.previewImg} />
            <ClickableBox data-test-id="remove_image_two" onClick={this.handleRemoveGridImage.bind(this, "imageTwo")} className={classes.removeBanBtn}>
              {configJSON.removeText}
            </ClickableBox>
          </Box>
        ) : (
          <label htmlFor="upload-button">
            <Button variant="outlined" fullWidth data-test-id="open_image_two" onClick={this.handleOpenGridImage.bind(this, "imageTwo")} className={classes.uploadImgBtn}>
              <Box>
                <img src={file} />
                <Box className={classes.buttnBox}>{configJSON.dropImageText}</Box>
                <Box className={classes.btnText}>{configJSON.fileSizeInfoText}</Box>
                <Box className={classes.btnText}>{configJSON.recommendedSize} 552&times;408</Box>
              </Box>
            </Button>
          </label>
        )}
        {this.state.errorTwo ? <Typography className={classes.errorText}>{this.state.errorTwo}</Typography> : ""}
        <Grid container className={classes.topSpace} spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topSpace}>
            <CustomizeBrandInput
              data-test-id="gridlinkTwo"
              label={configJSON.gridLink}
              id="outlined-start-adornment"
              value={this.state.linkTwo}
              onChange={this.handleGridLink.bind(this, "linkTwo")}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>
              }}
            />
            {this.state.linkErrTwo ? <Typography className={classes.errorText}>{this.state.linkErrTwo}</Typography> : ""}
            {this.showCommonErr()}
          </Grid>
          <Box className={classes.flexEndBox}>
            <Grid container spacing={3} className={classes.justifyContentEnd}>
              <Grid item>
                <NextButton data-test-id="nextTwo" onClick={this.handleSec3Grid2Next}>
                  {configJSON.nextBtn}
                </NextButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </>
    );
  };

  renderSection3Grid3 = () => {
    const { classes } = this.props;
    return (
      <>
        <Grid container className={classes.marginStyle} spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddingStyle}>
            <Typography className={classes.headingTwo}>{configJSON.sectionThreeGridThreeText}</Typography>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topSpace}>
          <CustomizeBrandInput
            data-test-id="nameThree"
            label={configJSON.gridNameLabel}
            id="outlined-start-adornment"
            value={this.state.nameThree}
            onChange={this.handleGridNameChange.bind(this, "nameThree")}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>
            }}
            inputProps={{
              style: webStyle.type,
              maxLength: 15
            }}
          />
          {this.state.setNameError ? (
            <Typography data-test-id="nameThreeErr" className={classes.errorText}>
              {this.state.setNameError}
            </Typography>
          ) : (
            ""
          )}
        </Grid>
        <Box className={classes.imgLabel}>{configJSON.gridImageLabel}</Box>
        <input data-test-id="gridImageThree" type="file" accept=".jpeg, .jpg, .png" ref={this.secThreeGridThreeImgRef} className={classes.imgInput} value="" onChange={this.handleUploadGridImage.bind(this, "imageThree")} />
        {this.state.previewImgThree ? (
          <Box className={classes.preview}>
            <img src={this.state.previewImgThree} alt="Image Preview" className={classes.previewImg} />
            <ClickableBox data-test-id="remove_image_three" onClick={this.handleRemoveGridImage.bind(this, "imageThree")} className={classes.removeBanBtn}>
              {configJSON.removeText}
            </ClickableBox>
          </Box>
        ) : (
          <label htmlFor="upload-button">
            <Button variant="outlined" fullWidth data-test-id="open_image_three" onClick={this.handleOpenGridImage.bind(this, "imageThree")} className={classes.uploadImgBtn}>
              <Box>
                <img src={file} />
                <Box className={classes.buttnBox}>{configJSON.dropImageText}</Box>
                <Box className={classes.btnText}>{configJSON.fileSizeInfoText}</Box>
                <Box className={classes.btnText}>{configJSON.recommendedSize} 552&times;408</Box>
              </Box>
            </Button>
          </label>
        )}
        {this.state.errorThree ? <Typography className={classes.errorText}>{this.state.errorThree}</Typography> : ""}
        <Grid container className={classes.topSpace} spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topSpace}>
            <CustomizeBrandInput
              data-test-id="gridlinkThree"
              label={configJSON.gridLink}
              id="outlined-start-adornment"
              value={this.state.linkThree}
              onChange={this.handleGridLink.bind(this, "linkThree")}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>
              }}
            />
            {this.state.linkErrThree ? <Typography className={classes.errorText}>{this.state.linkErrThree}</Typography> : ""}
            {this.showCommonErr()}
          </Grid>
          <Box className={classes.flexEndBox}>
            <Grid container spacing={3} className={classes.justifyContentEnd}>
              <Grid item>
                <NextButton data-test-id="nextThree" onClick={this.handleSec3Grid3Next}>
                  {configJSON.nextBtn}
                </NextButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </>
    );
  };

  renderSection3Grid4 = () => {
    const { classes } = this.props;
    return (
      <>
        <Grid container className={classes.marginStyle} spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddingStyle}>
            <Typography className={classes.headingTwo}>{configJSON.sectionThreeGridFourText}</Typography>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topSpace}>
          <CustomizeBrandInput
            data-test-id="nameFour"
            label={configJSON.gridNameLabel}
            id="outlined-start-adornment"
            value={this.state.nameFour}
            onChange={this.handleGridNameChange.bind(this, "nameFour")}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>
            }}
            inputProps={{
              style: webStyle.type,
              maxLength: 15
            }}
          />
          {this.state.setNameError ? (
            <Typography data-test-id="nameFourErr" className={classes.errorText}>
              {this.state.setNameError}
            </Typography>
          ) : (
            ""
          )}
        </Grid>
        <Box className={classes.imgLabel}>{configJSON.gridImageLabel}</Box>
        <input data-test-id="gridImageFour" type="file" accept=".jpeg, .jpg, .png" ref={this.secThreeGridFourImgRef} className={classes.imgInput} value="" onChange={this.handleUploadGridImage.bind(this, "imageFour")} />
        {this.state.previewImgFour ? (
          <Box className={classes.preview}>
            <img src={this.state.previewImgFour} alt="Image Preview" className={classes.previewImg} />
            <ClickableBox data-test-id="remove_image_four" onClick={this.handleRemoveGridImage.bind(this, "imageFour")} className={classes.removeBanBtn}>
              {configJSON.removeText}
            </ClickableBox>
          </Box>
        ) : (
          <label htmlFor="upload-button">
            <Button variant="outlined" fullWidth data-test-id="open_image_four" onClick={this.handleOpenGridImage.bind(this, "imageFour")} className={classes.uploadImgBtn}>
              <Box>
                <img src={file} />
                <Box className={classes.buttnBox}>{configJSON.dropImageText}</Box>
                <Box className={classes.btnText}>{configJSON.fileSizeInfoText}</Box>
                <Box className={classes.btnText}>{configJSON.recommendedSize} 552&times;408</Box>
              </Box>
            </Button>
          </label>
        )}
        {this.state.errorFour ? <Typography className={classes.errorText}>{this.state.errorFour}</Typography> : ""}
        <Grid container className={classes.topSpace} spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topSpace}>
            <CustomizeBrandInput
              data-test-id="gridlinkFour"
              label={configJSON.gridLink}
              id="outlined-start-adornment"
              value={this.state.linkFour}
              onChange={this.handleGridLink.bind(this, "linkFour")}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>
              }}
            />
            {this.state.linkErrFour ? <Typography className={classes.errorText}>{this.state.linkErrFour}</Typography> : ""}
            {this.showCommonErr()}
          </Grid>
          <Box className={classes.flexEndBox}>
            <Grid container spacing={3} className={classes.justifyContentEnd}>
              <Grid item>
                <NextButton data-test-id="nextFour" onClick={this.handleSec3Grid4Next}>
                  {configJSON.nextBtn}
                </NextButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.showSection3Grid1 && (
          <>
            <Grid container className={classes.topSpace} spacing={4}>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topSpace}>
                <Typography className={classes.heading}>{configJSON.sectionThreeText}</Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topSpace}>
                <CustomizeBrandInput
                  data-test-id="sectionThreeType"
                  label={configJSON.sectionTypeLabel}
                  id="outlined-start-adornment"
                  value={this.state.sectionThreeType}
                  disabled
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>
                  }}
                  inputProps={{
                    style: webStyle.type
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.marginStyle} spacing={4}>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddingStyle}>
                <Typography className={classes.headingTwo}>{configJSON.sectionThreeGridOneText}</Typography>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topSpace}>
              <CustomizeBrandInput
                data-test-id="nameOne"
                label={configJSON.gridNameLabel}
                id="outlined-start-adornment"
                value={this.state.nameOne}
                onChange={this.handleGridNameChange.bind(this, "nameOne")}
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start"></InputAdornment>
                }}
                inputProps={{
                  style: webStyle.type,
                  maxLength: 15
                }}
              />
              {this.state.setNameError ? (
                <Typography data-test-id="nameOneErr" className={classes.errorText}>
                  {this.state.setNameError}
                </Typography>
              ) : (
                ""
              )}
            </Grid>
            <Box className={classes.imgLabel}>{configJSON.gridImageLabel}</Box>
            <input data-test-id="gridImgOne" type="file" className={classes.imgInput} value="" accept=".jpeg, .jpg, .png" ref={this.secThreeGridOneImgRef} onChange={this.handleUploadGridImage.bind(this, "imageOne")} />
            {this.state.previewImgOne ? (
              <Box className={classes.preview}>
                <img src={this.state.previewImgOne} alt="Image Preview" className={classes.previewImg} />
                <ClickableBox data-test-id="remove_image_one" onClick={this.handleRemoveGridImage.bind(this, "imageOne")} className={classes.removeBanBtn}>
                  {configJSON.removeText}
                </ClickableBox>
              </Box>
            ) : (
              <label htmlFor="upload-button">
                <Button variant="outlined" fullWidth data-test-id="open_image_one" onClick={this.handleOpenGridImage.bind(this, "imageOne")} className={classes.uploadImgBtn}>
                  <Box>
                    <img src={file} />
                    <Box className={classes.buttnBox}>{configJSON.dropImageText}</Box>
                    <Box className={classes.btnText}>{configJSON.fileSizeInfoText}</Box>
                    <Box className={classes.btnText}>{configJSON.recommendedSize} 552&times;408</Box>
                  </Box>
                </Button>
              </label>
            )}
            {this.state.errorOne ? <Typography className={classes.errorText}>{this.state.errorOne}</Typography> : ""}
            <Grid container className={classes.topSpace} spacing={4}>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topSpace}>
                <CustomizeBrandInput
                  data-test-id="gridlinkOne"
                  label={configJSON.gridLink}
                  id="outlined-start-adornment"
                  value={this.state.linkOne}
                  onChange={this.handleGridLink.bind(this, "linkOne")}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>
                  }}
                />
                {this.state.linkErrOne ? (
                  <Typography data-test-id="linkErrOne" className={classes.errorText}>
                    {this.state.linkErrOne}
                  </Typography>
                ) : (
                  ""
                )}
                {this.state.commonErr ? (
                  <Box className={classes.commonErrBox}>
                    <Box className={classes.errorMargin}>
                      <img src={errorImage} />
                    </Box>
                    <Box>
                      <Typography data-test-id="commonErrOne" className={classes.errorTextOne}>
                        {this.state.commonErr}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
              </Grid>
              <Box className={classes.flexEndBox}>
                <Grid container spacing={3} className={classes.justifyContentEnd}>
                  <Grid item>
                    <NextButton data-test-id="nextOne" onClick={this.handleSec3Grid1Next}>
                      {configJSON.nextBtn}
                    </NextButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </>
        )}
        {this.state.showSection3Grid2 && this.renderSection3Grid2()}
        {this.state.showSection3Grid3 && this.renderSection3Grid3()}
        {this.state.showSection3Grid4 && this.renderSection3Grid4()}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  type: {
    fontSize: "14px",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 500,
    color: "#000"
  }
};

const styles = (theme: Theme) => ({
  topSpace: {
    marginTop: "20px"
  },
  justifyContentEnd: {
    justifyContent: "flex-end",
    padding: "0 16px"
  },
  imgLabel: {
    color: "#9a9a9a",
    fontWeight: 600,
    marginTop: "30px",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "12px"
  },
  imgInput: {
    display: "none"
  },
  preview: {
    position: "relative" as const,
    width: "fit-content",
    marginTop: "30px"
  },
  previewImg: {
    maxHeight: "300px",
    maxWidth: "100%",
    alignSelf: "flex-start"
  },
  removeBanBtn: {
    background: "#fcaca7",
    borderRadius: "5px",
    width: "80px",
    padding: "10px 20px",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    marginTop: "10px"
  },
  uploadImgBtn: {
    padding: "20px 0px",
    textTransform: "none" as const,
    width: "100%",
    height: "210px",
    margin: "10px 0px",
    border: "none",
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23FBBC98FF' stroke-width='3' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    borderRadius: "5px",
    fontFamily: `"Lato", sans-serif`
  },
  buttnBox: {
    fontWeight: 700,
    fontSize: "27px",
    fontFamily: `"Lato", sans-serif`
  },
  btnText: {
    fontFamily: `"Lato", sans-serif`,
    color: "#9a9a9a",
    fontSize: "16px"
  },
  flexEndBox: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: "60px"
  },
  errorText: {
    marginTop: "5px",
    color: "red",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "12px"
  },
  heading: {
    marginTop: "-30px",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "24px",
    fontWeight: 600
  },
  headingTwo: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: "20px",
    fontWeight: 600
  },
  marginStyle: {
    margin: "16px 0"
  },
  paddingStyle: {
    padding: "16px 0 !important"
  },
  errorTextOne: {
    color: "red",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "12px"
  },
  commonErrBox: {
    display: "flex",
    paddingTop: "16px",
    flexDirection: "row" as const,
    flexWrap: "nowrap" as const,
    alignItems: "center"
  },
  errorMargin: {
    marginRight: "10px"
  }
});

export { StoreSectionThreeTab };
export default withStyles(styles)(StoreSectionThreeTab);

// Customizable Area End
