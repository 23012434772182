// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { GetCookies } from "framework/src/WebUtilities";
export const configJSON = require("../../../blocks/catalogue/src/config");

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  priceDetails: {
    total_fees:string, 
    total_tax:string, 
    final_price:string
  }
  classes: {
    orderContainer: string,
    paymentIcons: string,
    preview: string;
    description: string;
    payBtn: string;
    priceSec: string;
    horizontalLine: string;
    textOne: string;
    priceHeaderOne: string;
    price: string,
    bottomSec: string;
    finalPrice: string
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  paymentUrl: string,
  isPayBtn: boolean,
  loader: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PaymentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createPaymentSessionApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseData),
    ];
    this.state = {
      paymentUrl: "",
      isPayBtn: false,
      loader: false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.handlePaymentBtnResponse(responseJson, apiRequestCallId);
      }
    }
  }

  handlePaymentBtnResponse = (responseJson: any, apiRequestCallId: string) => {
    if (apiRequestCallId === this.createPaymentSessionApiCallId) {
      if(responseJson) {
        this.setState({paymentUrl : responseJson.order.url, loader: false})
        window.location.href = responseJson.order.url
      }
    } 
  };

  handlePaymentButton = () => {
    this.createPaymentSession()
  };

  createPaymentSession = () => {
    const orderId = GetCookies("currentOrder");
    const finalPrice = this.props.priceDetails.final_price
    this.setState({loader: true})
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: GetCookies("ba"),
    };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createPaymentSessionApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/telr_payments?order_id=${orderId}&final_price=${finalPrice}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(message.id, message);
  }
  // Customizable Area End
}
