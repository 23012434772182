import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import { createRef } from "react";
import { IEmailLinkMessage, IErrorArray, IErrorString, IOTPErrorMap, ISendOTPData, ISignUpBuyerData, IVerifyEmailLinkMessage, IVerifyOtpData } from "./Interfaces";
import { InputProps } from "@material-ui/core";
import { SetCookies } from "../../../framework/src/WebUtilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  showSignUpModal: boolean;
  showPassword: boolean;
  first_name: string;
  last_name: string;
  email: string;
  full_phone_number: string;
  password: string;
  errMsg: string[];
  otp_token: string;
  isFetching: boolean;
  showOTPModal: boolean;
  inputValues: number[] | string[];
  mins: number;
  country_code: string;
  phone_number: string;
  secs: number;
  showOTPSuccessModal: boolean;
  showEmailLink: boolean;
  emailToken: string;
  showEmailSuccessModal: boolean;
  resendMsg: string;
  invalidToken: string;
  login_otp: string;
  phoneErr: string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class BuyerSignupController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiSignUpBuyerCallId: string = "";
  apiSendOTPCallId: string = "";
  inputRefs: React.RefObject<InputProps & { focus: Function }>[];
  timer: any;
  apiVerifyOtpCallId: string = "";
  apiEmailLinkCallId: string = "";
  apiVerifyEmailLinkCallId: string = "";
  apiResendEmailLinkCallId: string = "";
  formikRef: React.RefObject<any>;
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.ReceiveMessage)];

    this.state = {
      showSignUpModal: false,
      showOTPSuccessModal: false,
      showPassword: false,
      first_name: "",
      last_name: "",
      email: "",
      full_phone_number: "",
      password: "",
      errMsg: [],
      otp_token: "",
      isFetching: false,
      showOTPModal: false,
      secs: 30,
      mins: 1,
      inputValues: ["", "", "", "", ""],
      country_code: "",
      phone_number: "",
      emailToken: "",
      resendMsg: "",
      showEmailLink: false,
      showEmailSuccessModal: false,
      invalidToken: "",
      login_otp: "",
      phoneErr: ""
    };

    this.inputRefs = [createRef(), createRef(), createRef(), createRef(), createRef()];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.formikRef = createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.ReceiveMessage)) {
      const msg = message.getData(getName(MessageEnum.OpenBuyerSignupModal)) || false;
      if (msg) {
        clearInterval(this.timer);
        this.setState({ showSignUpModal: true, mins: 1, secs: 30, inputValues: ["", "", "", "", ""], errMsg: [], resendMsg: "", first_name: "", last_name: "", email: "", password: "", full_phone_number: "" });
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.setState({ isFetching: false });
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const errorJson = message.getData(getName(MessageEnum.RestAPIResponceEndPointMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.apiSignUpBuyerCallId) {
        this.handleSignUpBuyerResponse(responseJson,errorJson);
      }
      if (apiRequestCallId === this.apiSendOTPCallId) {
        this.handleSendOTPResponse(responseJson);
      }
      if (apiRequestCallId === this.apiVerifyOtpCallId) {
        this.handleVerifyOtp(responseJson);
      }
      if (apiRequestCallId === this.apiEmailLinkCallId) {
        this.handleEmailLinkResponse(responseJson);
      }
      if (apiRequestCallId === this.apiVerifyEmailLinkCallId) {
        this.handleVerifyEmailLinkResponse(responseJson);
      }
      if (apiRequestCallId === this.apiResendEmailLinkCallId) {
        this.handleResendEmailLinkResponse(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  handleResendEmailLinkResponse = (responseJson: unknown) => {
    const successData = responseJson as IEmailLinkMessage;
    if ("message" in successData) {
      if (successData.message === "Activation email has been resent") {
        return this.setState({ resendMsg: "Email resent! Please check your inbox!" });
      }
    }
    return this.setState({ errMsg: ["Something went wrong. Please try again later!"] });
  };

  handleVerifyEmailLinkResponse = (responseJson: unknown) => {
    const successData = responseJson as IVerifyEmailLinkMessage;
    const errorData = responseJson as IErrorArray;
    if ("meta" in successData) {
      if (successData.meta.message === "Your account has been successfully activated. Please continue registration") {
        //ba = buyer auth token
        SetCookies("ba", this.state.emailToken, 7);
        this.setState({ showEmailSuccessModal: true });
        return setTimeout(() => {
          this.handleGoToHomePage();
        }, 1500);
      }
    }
    if ("errors" in errorData) {
      const invalidToken = responseJson as IErrorString;
      if (typeof invalidToken.errors === "string") {
        return this.setState({ invalidToken: "Expired or invalid link. Please try again." });
      }
      return this.setState({ errMsg: errorData.errors });
    }
    this.setState({
      errMsg: ["Something went wrong! Please try again later!"]
    });
  };

  handleEmailLinkResponse = (responseJson: unknown) => {
    const successData = responseJson as IEmailLinkMessage;
    if ("message" in successData) {
      if (successData.message === "Activation email has been resent") {
        return this.setState({ showEmailLink: true, showOTPSuccessModal: false });
      }
    }
    return this.setState({ errMsg: ["Something went wrong. Please try again later!"] });
  };

  handleVerifyOtp = (responseJson: unknown) => {
    const successData = responseJson as IVerifyOtpData;
    const errorData = responseJson as IOTPErrorMap;
    if ("data" in successData) {
      this.setState({ showOTPSuccessModal: true, showOTPModal: false });
      return setTimeout(() => this.sendEmailLink(), 2000);
    }
    if ("errors" in errorData) {
      const key = Object.keys(errorData.errors[0])[0];
      return this.setState({ errMsg: [errorData.errors[0][key]] });
    }
    return this.setState({ errMsg: ["Something went wrong. Please try again later!"] });
  };

  handleSendOTPResponse = (responseJson: unknown) => {
    const successData = responseJson as ISendOTPData;
    const errorData = responseJson as IOTPErrorMap;
    if ("meta" in successData) {
      this.startTimer();
      this.setState({ showOTPModal: true, showSignUpModal: false, otp_token: successData.meta.token });
      this.inputRefs[0].current?.focus();
      return;
    }
    if ("errors" in errorData) {
      const key = Object.keys(errorData.errors[0])[0];
      return this.setState({ errMsg: [errorData.errors[0][key]] });
    }
    return this.setState({ errMsg: ["Something went wrong. Please try again later!"] });
  };

  handleContactNumberBuyer = (value:string) => {
    const numValueBuyer = value.replace(/\D/g, "");
    const truncatedValue = numValueBuyer.slice(0, 12);
    let formattedValue = truncatedValue.slice(0, 3) + (truncatedValue.length > 3 ? "-" + truncatedValue.slice(3, 5) : "") + (truncatedValue.length > 5 ? "-" + truncatedValue.slice(5) : "");
    return formattedValue;
}

  handleContactNumberChangeBuyer = (e:React.ChangeEvent<HTMLInputElement>,setFieldValue:any) => {
    const number = e.target.value;
    const formattedNumberBuyer = this.handleContactNumberBuyer(number);
    const inputElementBuyer = e.target
    const cursorPosition:any = e.target.selectionStart;
    if (number === "") {
        setFieldValue('full_phone_number', "");
    } else {
        setFieldValue('full_phone_number', formattedNumberBuyer.length <= 14 ? formattedNumberBuyer : formattedNumberBuyer.slice(0, 14));
        setTimeout(() => {
          const addedChars = formattedNumberBuyer.length - number.length;
          const newCursorPosition = cursorPosition + addedChars;
      
          if (inputElementBuyer.setSelectionRange) {
              inputElementBuyer.setSelectionRange(newCursorPosition, newCursorPosition);
          }
      }, 0);
    }
}

  handleSignUpBuyerResponse = (responseJson: unknown,errorJson:any) => {
    const successData = responseJson as ISignUpBuyerData;
    const errorData = responseJson as IOTPErrorMap;
    if ("data" in successData) {
        this.formikRef.current?.resetForm();
      this.setState({ errMsg: [], login_otp: successData.meta.token, otp_token: successData.meta.token, phone_number: successData.data.attributes.phone_number, country_code: successData.data.attributes.country_code, emailToken: successData.meta.token });
      return this.sendOTP();
    }
    if ("errors" in errorData) {
      const key = Object.keys(errorData.errors[0])[0];
      if (key === "0") {
        const err = responseJson as IErrorArray;
        return this.setState({ errMsg: err.errors });
      }
      return this.setState({ errMsg: [errorData.errors[0][key]] });
    }
    return this.setState({ errMsg: ["Something went wrong. Please try again later!"] });
  };

  handleCloseModal = () => {
    clearInterval(this.timer);
    this.setState({ showSignUpModal: false, showOTPModal: false, showEmailLink: false, showEmailSuccessModal: false, showOTPSuccessModal: false });
  };

  handleGoToSignInPage = () => {
    this.setState({ showSignUpModal: false });
    const msg: Message = new Message(getName(MessageEnum.SendMessage));
    msg.addData(getName(MessageEnum.OpenBuyerEmailLoginModal), true);
    this.send(msg);
  };

  handleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleResendOtp = () => {
    this.setState({
      inputValues:["","","","",""]
  })
  this.inputRefs = [createRef(), createRef(), createRef(), createRef(), createRef()];

    if (this.state.secs === 0 && this.state.mins === 0) {
      this.setState({ secs: 30, mins: 1 });
      this.sendOTP();
      this.startTimer();
      return;
    }
    this.setState({
      errMsg: ["Please wait for a while to resend otp again!"]
    });
  };

  handleSubmitOtp = () => {
    this.setState({ errMsg: [] });
    if ([...this.state.inputValues].includes("") === false) {
      this.verifyOtp();
    }
    if ([...this.state.inputValues].includes("")) {
      this.setState({
        errMsg: ["Please provide all of the needed information!"]
      });
    }
  };

  handleInputOnChange = (indexRef: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const targetVal = event.target.value;
    if (!isNaN(Number(targetVal))) {
      const inputValues = this.state.inputValues;
      inputValues[indexRef] = targetVal;
      this.setState({ inputValues });
      if (indexRef < this.inputRefs.length - 1 && this.state.inputValues[indexRef]) {
        const inputRef = this.inputRefs[indexRef + 1];
        if (inputRef.current && inputRef) {
          inputRef.current.focus();
        }
      }
    }
  };

  handleInputOnPress = (index: number, event: any) => {
    if (event.key === "Backspace") {
      const newInputValues = this.state.inputValues;
      newInputValues[index] = "";
      this.setState({ inputValues: newInputValues });
    }
    if (event.key === "Enter") {
      this.handleSubmitOtp();
    }
  };

  startTimer = () => {
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      const { mins, secs } = this.state;
      if (mins === 0 && secs === 0) {
        clearInterval(this.timer);
      } else {
        if (secs > 0) {
          this.setState(prevState => ({
            secs: prevState.secs - 1
          }));
        } else {
          this.setState(prevState => ({
            mins: prevState.mins - 1,
            secs: 59
          }));
        }
      }
    }, 1000);
  };

  handleGoToHomePage = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationHomePageMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  verifyOtp = () => {
    this.setState({ isFetching: true });
    const headers = { "Content-Type": configJSON.contentType, token: this.state.otp_token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiVerifyOtpCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiSignUpEndpoint + "/sms_otp_confirmations");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        pin: this.state.inputValues.join("")
      })
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  signUpBuyer = (values:any) => {
    this.setState({ isFetching: true 
      ,first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      full_phone_number: values.full_phone_number
    });
    const headers = { "Content-Type": configJSON.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiSignUpBuyerCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiSignUpEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          user_type: "buyer",
          attributes: {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            password: values.password,
            full_phone_number: values.full_phone_number
          }
        }
      })
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendOTP = () => {
    this.setState({ isFetching: true });
    const headers = { "Content-Type": configJSON.contentType, token: this.state.otp_token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiSendOTPCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiSignUpEndpoint + "/send_otps");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            full_phone_number: this.state.full_phone_number
          }
        }
      })
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendEmailLink = () => {
    this.setState({ isFetching: true });
    const headers = { "Content-Type": configJSON.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiEmailLinkCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiEmailLinkEndpoint + this.state.emailToken);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  verifyEmailLink = (emailToken: string) => {
    this.setState({ isFetching: true });
    const headers = { "Content-Type": configJSON.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiVerifyEmailLinkCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiVerifyEmailLinkEndpoint + emailToken);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  resendEmailLink = () => {
    this.setState({ isFetching: true });
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const headers = { "Content-Type": configJSON.contentType };
    this.apiResendEmailLinkCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiEmailLinkEndpoint + this.state.emailToken);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount(): Promise<void> {
    try {
      const pageUrl = window.location.href;
      const emailToken = pageUrl.split("?")[1].split("=")[1];
      if (emailToken) {
        this.setState({ emailToken });
        this.verifyEmailLink(emailToken);
      }
    } catch (error) {}
  }
  // Customizable Area End
}
