// Customizable Area Start
import React from "react";
import ReturnItemDetailsController, {
  OrderItem,
  Props, configJSON,
} from "./ReturnItemDetailsController.web";
import {styles} from "./SellerPartnerOrderDetails.web";
import { Grid, Typography, withStyles, Box, Divider, IconButton, Button, InputAdornment, TextField, Modal } from "@material-ui/core";
import { closeIcon, editImage, shippingIcon } from "./assets";
import { returnStyles } from "./FulfilledByPartnerReturns.web";
// Customizable Area End

export class ReturnItemDetails extends ReturnItemDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {itemDetails} = this.state;
    const { classes } = this.props;
    const { address_1 , address_2, city, state, phone_number } = this.state.itemDetails.shipping_address.data && this.state.itemDetails.shipping_address.data.attributes || {}
    const orderItems = this.state.itemDetails.order_items.data
    const orderStatus = this.state.itemDetails.order_items.data[0].attributes.order_status.data.attributes.name
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box style={styles.wrapper}>
        <Grid item xs={12} sm={6} md={6} lg={6} style={styles.orderDetailsHeader}>
              <Typography style={styles.orderDetailsText}>
              {configJSON.orderDetailsLabel}
              </Typography>
          </Grid>
            <Box data-test-id="component" style={styles.componentWrapper}>
                <Grid container style={styles.main}>
                    <Grid item lg={4} md={12} sm={12} xs={12} style={styles.border}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container style={styles.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={styles.orderBox}>
                            <Typography style={styles.left}>{configJSON.orderIdText} </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} style={styles.orderBox1}>
                            <Typography style={styles.right}>{itemDetails.order_number}</Typography>
                        </Grid>   
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container style={styles.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={styles.orderBox}>
                            <Typography style={styles.left}>{configJSON.buyerNameLabel} </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} style={styles.orderBox1}>
                            <Typography style={styles.right}>{itemDetails.customer.data.attributes.full_name}</Typography>
                        </Grid>   
                    </Grid>
                </Grid> 
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container style={styles.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={styles.orderBox}>
                            <Typography style={styles.left}>{configJSON.sellerNameLabel} </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} style={styles.orderBox1}>
                            <Typography style={styles.right}>{itemDetails.seller.data.attributes.full_name}</Typography>
                        </Grid>   
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container style={styles.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={styles.orderBox}>
                            <Typography style={styles.left}>{configJSON.fullFillmentLabel} </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} style={styles.orderBox1}>
                            <Typography style={styles.right}>{configJSON.partnerText}</Typography>
                        </Grid>   
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container style={styles.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={styles.orderBox}>
                            <Typography style={styles.left}>{configJSON.dateLabel} </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} style={styles.orderBox1}>
                            <Typography style={styles.right}>{this.getItemOrderDate(itemDetails.ordered_at)}</Typography>
                        </Grid>   
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container style={styles.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={styles.orderBox}>
                            <Typography style={styles.left}>{configJSON.timeLabel} </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} style={styles.orderBox1}>
                            <Typography style={styles.right}>{`${this.getItemOrderTime(itemDetails.ordered_at)} GST`}</Typography>
                        </Grid>   
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container style={styles.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={styles.orderBox}>
                            <Typography style={styles.left}>{configJSON.shippingAddressText} </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} style={styles.orderBox1}>
                            <Typography style={styles.right}>{address_1 || ""} {address_2 || ""}, {city || ""}</Typography>
                            <Typography style={styles.right}>{phone_number || ""}</Typography>
                            <Typography style={styles.right}>{state || ""}</Typography>
                        </Grid>   
                    </Grid>
                </Grid>
                {this.state.shippingDetails &&
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container style={styles.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={styles.orderBox}>
                            <Typography style={styles.left}>{"Shipping details:"} </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} style={webStyle.shippingDetailsBox}>
                            <Typography style={styles.right}>{this.state.shippingInput ? this.state.shippingInput :this.state.shippingDetails.details}</Typography>
                            <IconButton
                              style={webStyle.editIconBtn}
                        aria-label="more"
                        data-test-id="editIcon"
                        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.handleEditShippingDetails(event, itemDetails.id as string, this.state.shippingDetails.id)}
                      > <img src={editImage} alt="edit_icon" style={{height:'12px', width:'11px'}} /></IconButton>
                        </Grid>   
                    </Grid>
                </Grid>
                }
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container style={styles.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={styles.orderBox}>
                            <Typography style={styles.left}>{configJSON.orderStatusLabel}: </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} style={styles.padding}>
                          <Box style={webStyle.orderStatus}>{orderStatus}
                        </Box>
                        </Grid>   
                    </Grid>
                </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={8} >

                <Grid container spacing={1} className={classes.productContainer}>
                  <Box className={classes.productBox}>
                  {orderItems.map((order:OrderItem) => {
                    const {product_image, product_title, brand} = order.attributes.item.catalogue.data.attributes
                   return (
                    <>
                    <Box style={styles.itemWrapper} >
                        <Grid item xs={12} sm={2} md={2} lg={2} style={styles.imageBox}>
                          <Box style={styles.prodImageContainer}>
                            <img src={product_image} alt={"product_img"} style={styles.prodImage}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={4} className={classes.productDetails}>
                            <Typography data-test-id="title" className={classes.productName}>{product_title}</Typography>
                            <Typography className={classes.color}>{""}</Typography>
                            <Typography className={classes.brandName}>{configJSON.brandText}: {brand.brand_name}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} className={`${classes.quantity}`}>
                            <Typography className={classes.detail}>{configJSON.quantityLabel}</Typography>
                            <Typography className={classes.detailValue}>{order.attributes.quantity}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} className={`${classes.quantity} ${classes.leftMargin}`}>
                            <Typography className={classes.detail}>{configJSON.customerOptionLabel}</Typography>
                            <Typography className={this.props.classes.detailValue}>{configJSON.standardText}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} className={`${classes.quantity} ${classes.leftMargin}`}>
                            <Typography className={classes.detail}>{configJSON.totalPriceLabel}</Typography>
                            <Typography className={classes.detailValue}>{configJSON.AedText} {order.attributes.price}</Typography>
                        </Grid>

                    </Box>
                    </>
                  ) 
                })} 

              </Box>
            </Grid>
            <Grid container spacing={1} className={`${classes.productInfo} ${classes.bottomRight}`}>
                        <Grid item xs={12} sm={12} md={2} lg={6} className={classes.summaryText}>
                            <Typography className={classes.orderSummary}>{configJSON.orderSummaryLabel}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} className={`${classes.quantity} ${classes.totalQty}`}>
                            <Typography className={classes.detail}>{configJSON.totalQuantityLabel}</Typography>
                            <Typography>{itemDetails.total_items}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} className={classes.quantitySec}>
                        <Grid item xs={12} sm={6} md={6} lg={6} className={`${classes.quantity} ${classes.productInfo} ${classes.leftMargin}`}>
                            <Typography className={classes.detail} >{configJSON.totalPriceLabel}:</Typography>
                            <Typography className={classes.detail}>{configJSON.tax}</Typography>
                            <Divider className={classes.dividerOne}/>
                            <Typography className={classes.detail}>{configJSON.total}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} className={`${classes.priceBox} ${classes.leftMargin}`}>
                            <Typography className={classes.priceValue}>{configJSON.AedText} {itemDetails.total_fees}</Typography>
                            <Typography className={`${classes.priceValue} ${classes.bottomMargin}`}>{configJSON.AedText} {itemDetails.total_tax}</Typography>
                            {/* <Divider className={classes.dividerTwo}/> */}
                            <Typography>{itemDetails.final_price}</Typography>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>
                
                </Grid>
            </Box>
                
        </Box>
        <Modal
        open={this.state.shippingModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box style={returnStyles.mainModalWrapper}>
          <Box style={returnStyles.blurredBckgnd}></Box>
          
          <Box
            style={{...returnStyles.modalContainerMain, ...returnStyles.modalBoxOne}}
          >
           
            <Box
              style={returnStyles.modalBoxTwo}
            >
              <Box style={{display:'flex', justifyContent:'flex-end'}}
              >
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  style={{padding: '20px 30px'}}
                >
                  <img src={closeIcon} data-test-id="closeIcon" onClick={this.handleCloseModal} />
                </IconButton>
              </Box> 
              <Box
                style={{...returnStyles.imageWrapperMain, ...returnStyles.boxOne}}
              >
                <Box style={returnStyles.imageContainer}>
                  <img style={returnStyles.shippingImg} src={shippingIcon} alt="shipping image" />
                </Box>
                <Box
                  style={returnStyles.shippingText}
                >
                  {configJSON.enterShippingDetailsText}
                </Box>
                <TextField
                data-test-id="shippingRef"
                style={webStyle.shippingInput}
                multiline
                placeholder="Enter details"
                minRows={4}
                maxRows={4}
                value={this.state.shippingInput}
                onChange={(event:React.ChangeEvent<HTMLInputElement>) => this.handleShippingInputText(event.target.value)}
                id="outlined-start-adornment"
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start"></InputAdornment>
                }}
                InputLabelProps={{
                  style: webStyle.shippingField
                }}
              />
              <Button 
              data-test-id="submitBtn"
              style={this.getCustomBtnStyle()}
              onClick={this.handleSubmitDetails}
              >Submit</Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle = {
    summaryContainer: {
        display:'flex',
        alignItems:'flex-start',
        height:'50vh',
        padding:'35px 20px'
    },
    totalSec: {
        display:'flex',
        alignItems:'center',
        flexDirection:'column' as const,
        justifyContent: 'space-between',
        height:'60px'
    },
    detailContainer: {
        display:'flex',
        alignItems:'center',
        flexDirection:'column' as const,
        justifyContent: 'space-between',
        height:'90px'
    },
    orderStatus: {
        padding: "0px",
        color: "#121212",
        fontFamily: `"Lato", sans-serif` as const,
        fontSize: 14,
        fontWeight: 500 as const,
        backgroundColor: '#fff',
        borderRadius: 5,
        display: "flex",
        alignItems: "flex-start",
        borderBottom: "0",
        textTransform: 'uppercase' as const,
        width: 'auto'
    },
    editIconBtn: {
        padding: '0',
        display: 'flex', 
        alignItems:'center', 
        justifyContent:'center', 
        marginLeft:'8px'
    },
    shippingDetailsBox: {
        padding:'0 40px 0 55px',
        display:'flex',
        alignItems: 'center',
        flexDirection: 'row' as const,
        justifyContent: 'space-between'
    },
      shippingInput: {
        margin: "24px",
        width: '92%',
        "& .MuiInput-formControl": {
          border: "1px solid #DDE3E8",
          margin: 0,
          padding: "10px 0px",
          borderRadius: "5px"
        },
        '&.MuiInputBase-input:focus':{
          border: "1px solid #DDE3E8",
        }
      },
      shippingText: {
        color: '#141414',
        fontFamily: `"Poppins", sans-serif` as const,
        fontSize: '22px',
        fontWeight: 500,
        textAlign: 'center' as const
      },
      shippingField: { 
        fontSize: "16px", 
        fontWeight: 400, 
        fontFamily: "Poppins, sans-serif", 
        color: '#999' 
      },
      active: {
        color: '#fff',
        backgroundColor: '#ff6008',
        fontFamily : `"Montserrat", sans-serif` as const,
        fontSize: '20px',
        fontWeight: 700,
        textAlign: 'center' as const,
        padding: '8px 46px',
        textTransform: 'capitalize' as const,
        '&:hover': {
          color: '#fff',
          backgroundColor: '#ff6008'
        }
    },
    inactive: {
        color: '#ffffff',
        backgroundColor: '#999',
        fontFamily : `"Montserrat", sans-serif` as const,
        fontSize: '20px',
        fontWeight: 700,
        textAlign: 'center' as const,
        padding: '8px 46px',
        textTransform: 'capitalize' as const,
        cursor: 'none',
        '&:hover' : {
        color: '#ffffff',
        backgroundColor: '#999',
        cursor: 'none'
        }
    }
}

const style = {
  detail: {
    color: 'rgb(101, 122, 142)',
    fontSize:'14px',
    fontFamily: `"Lato", sans-serif`,
    marginBottom: '8px',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all' as const
  },
  detailValue: {
    fontSize:'15px',
    fontFamily: `"Lato", sans-serif`,
    fontWeight:500,
    color:'#000',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all' as const
  },
  productDetails: {
    display: 'flex',
    flexDirection:'column' as const,
    alignItems:'flex-start',
    justifyContent: 'space-between',
    marginLeft: '-20px',
    padding:'0 14px',
    gap:'3px',
    '@media (max-width: 860px)': {
      marginLeft: '0px',
    }
  },
  productName: {
    color: '#232529',
    fontFamily: `"Lato", sans-serif`,
    fontSize: '15px',
    fontWeight: 400,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all' as const
  },
  color: {
    color: '#969696',
    fontFamily: `"Lato", sans-serif`,
    fontSize: '13px',
    fontWeight: 400
  },
  brandName: {
    color: '#33333a',
    fontFamily: `"Lato", sans-serif`,
    fontSize: '13px',
    fontWeight: 400,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all' as const
  },
  quantity: {
    display:'flex',
    alignItems:'center',
    flexDirection:'column' as const,
    justifyContent: 'space-between',
    '@media (max-width:460px)' : {
      marginLeft:'10px',
      alignItems: 'revert'
    }
  },
  leftMargin: {
    marginLeft:'20px'
  },
  bottomMargin: {
    marginBottom:'20px !important'
  },
  priceValue: {
    marginBottom:'8px',
    fontSize: "14px",
    fontFamily: `"Lato", sans-serif`,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all' as const
  },
  priceBox: {
    display:'flex',
    alignItems:'end',
    flexDirection:'column' as const,
    justifyContent: 'space-between'
  },
  productInfo: {
    display:'flex',
    alignItems:'flex-start'
  },
  summaryText: {
    display:'flex',
    alignItems:'center', 
    justifyContent:'center', 
    height:'90px',
    '@media (max-width:860px)' : {
      justifyContent:'flex-start', 
    }
  },
  orderSummary: {
    fontSize:'28px',
    color: 'rgb(101, 122, 142)'
  },
  totalQty: {
    display:'flex',
    height:'60px',
    '@media (max-width:460px)': {
      alignItems: 'revert' as const,
      flexDirection:'row' as const
    }
  },
  quantitySec: { 
    display:'flex', 
    justifyContent:'space-between'
  },
  dividerOne: {
    width:'230%',
    border: '0.5px solid rgba(224,224,224,1)',
    marginTop: '10px',
    marginBottom: '10px'
  },
  bottomRight: {
    height:'50vh',
    padding:'35px 20px',
    '@media (max-width: 860px)': {
      height:'0'
    }
  },
  productBox: {
    height:'70vh', 
    overflowY: 'auto' as const, 
    width:'100%',
    marginLeft:'-10px',
    '@media (max-width:1260px)' : {
      height:'auto'
    }
  },
  productContainer: {
    display:'flex',
    alignItems:'flex-start',
    height:'60vh', 
    padding:'10px 20px',
    marginLeft:'10px',
    borderBottom:'1px solid rgba(224,224,224,1)',
    '@media (max-width:1260px)' : {
      height:'auto'
    }
},
}
export default withStyles(style)(ReturnItemDetails);
// Customizable Area End
