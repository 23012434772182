Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.contentType = "application/json";
exports.getMethod = 'GET';
exports.apiGetSellerDocumentsStatus = '/account_block/accounts/';
exports.welcomeHeader = 'Welcome';
exports.checkBuyerSession = "account_block/accounts/logged_user?token=";
exports.getHeaderSingleImgApiEndPoint = "/bx_block_dashboard/banners/header_single_images";
exports.getLeftBannerImgApiEndPoint = "/bx_block_dashboard/banners/middle_slideshow?group_name=middle_group_1";
exports.getRightBannerImgApiEndPoint = "/bx_block_dashboard/banners/middle_slideshow?group_name=middle_group_2";
exports.getMiddleBannerImgApiEndPoint = "/bx_block_dashboard/banners/middle_single_images";
exports.getFooterBannerImgApiEndPoint = "/bx_block_dashboard/banners/footer_single_images";
exports.trendingProductsLabel = "Trending Products";
exports.categoriesLabel = "Categories";
exports.quickLinkLabel = "Quick link";
exports.aboutUsLabel = "About us";
exports.testimonialLabel = "Testimonials";
exports.contactLabel = "Contact";
exports.privacyPolicyLabel = "Privacy Policy";
exports.supportLabel = "Support";
exports.termsConditionsLabel = "Terms & Conditions";
exports.shippingReturnLabel = "Shipping & Returns ";
exports.wantToSellWithUsText = "Want to sell with us?";
exports.signUpLabel = "Sign up";
exports.findOnSocialPlatformText = "Find on social platform";
exports.guaranteeText = "100% ORIGINAL guarantee";
exports.forAllProductsText = "for all products";
exports.returnWithIn30DaysText = "Return within 30days";
exports.receivingOrderText = "of receiving your order";
exports.contactUsText = "Contact Us";
exports.copyRightText = "Copyright@2023 All rights reserved | Made with ByEZZY";
exports.getSocialMediaApiEndPoint = "/bx_block_support/social_platforms";
exports.getCatagoryListApiEndPoint = "/bx_block_categories/categories";
exports.allCategoriesText = "All Categories";
exports.ITHardwareText = "IT hardware";
exports.electronicsText = "Electronics";
exports.babyProductsText = "Baby products";
exports.sportsText = "Sports";
exports.mobileAccessoriesText = "Mobile & Accessories";
exports.multiMediaText = "Tv,Audio & Cameras";
exports.getProductOneApiEndPoint = "/bx_block_dashboard/trending_products?slider=1";
exports.getProductTwoApiEndPoint = "/bx_block_dashboard/trending_products?slider=2";
exports.getWeeklyDealsApiEndPoint = "/bx_block_dashboard/weekly_homiee_deals/latest_weekly_deal";
exports.getFavBooksApiEndPoint = "/bx_block_dashboard/author_favorite_books/latest_author_favorite";
exports.postMethod = "POST";
exports.shopByCategory = "Shop by Categories"
exports.allCategoryText = "All Categories";
exports.topBrandApiEndPoint = "/bx_block_dashboard/top_brands";
exports.topPopularCategoryApiENdPoint = "/bx_block_dashboard/most_popular_categories"
exports.completeRegistrationProcessText = "Please complete the registration process";
exports.startSellingText = "to start selling with us.";
exports.startRegistration = "Start Registration";
exports.welcomeBackText = "Welcome back!";
exports.documentVerificationText = "Hold on, Your document verification is in process."
exports.goToHomepageTxt = "Go To Home Page";
exports.congratulationsText= "Congratulations !";
exports.yourDocumentVerifiedText = "Your document has been verified. You can";
exports.sellingWithByezzyText = "start selling with ByEzzy.";
exports.continueText = "Continue";
exports.headerSlideshowApiEndpoint = "bx_block_dashboard/banners/header_slideshow";
// Customizable Area End