import React from "react";

import "typeface-lato"
import {
  Container,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { CustomizedButton } from './CustomElementStyling';

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
      fontSize: '3.5rem',
      fontWeight: 700,
      textAlign: 'center',
      color: '#3e3e3e'
    },
    h5: {
      fontWeight: 400,
      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
      fontSize: '1.2rem',
      textAlign: 'center',
    },
    body1: {
      fontWeight: 600,
      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    },
    body2: {
      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1460
    }
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderBottomColor: 'rgba(0, 0, 0, 0.1)',
        },
        '&:after': {
          borderBottomColor: 'rgba(253, 97, 12, 1)',
        },
        '&:hover:not($disabled):before': {
          borderBottomColor: 'rgba(253, 97, 12, 1)',
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: 'rgba(253, 97, 12, 1)',
        },
        fontSize: 14,
        fontWeight: 700
      },
    },
  },
});

import BulkUploadingController, {
  Props,
  logoImg,
  objectsImg,
  pdfImg,
} from "./BulkUploadingController";

export default class SellerFinaUploadRegistrationWeb extends BulkUploadingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.mainWrapper}>
          <Container maxWidth={"xl"} style={{ position: "relative" }}>
            <Box sx={{ position: 'absolute', left: 30, top: 40 }}>
              <img src={logoImg} style={webStyle.appLogo}/>
            </Box>
            <Box sx={{ position: 'absolute', left: '-11%', top: 40 }}>
              <img src={objectsImg} width={"140%"} />
            </Box>
            <Container style={{ position: 'relative' }} maxWidth={"lg"}>
              <Grid container spacing={8} direction='column'>
                <Grid item>
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '140px', marginBottom: '25px' }}>
                    <Typography variant="h1">Almost there..</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'center', marginBottom: '5px', flexWrap: 'wrap' }}>
                    <Typography variant="h5">You are one step closer to</Typography>
                    <Typography variant="h5" style={{ marginLeft: '5px', color: 'rgba(253, 97, 12, 1)' }}>start selling with ByEzzy.</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'center' }}>
                    <Typography variant="h5">We have received your documents for veriication. You will be notified once the process is completed.</Typography>
                  </Box>
                </Grid>
                
                <Grid item>
                  <Grid container spacing={3} direction="column">
                    {this.state.sellerDocuments.map((item, index) => {
                      return (
                        <Grid item key={index}>
                          <Box sx={{ width: '100%', justifyContent: 'space-between', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <Box sx={{ width: '70px', height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '30px', border: '1px solid #d4d4d4', borderRadius: '7px' }}>
                                <img src={pdfImg} width={'50px'} />
                              </Box>
                              <Box>
                                <Box sx={{ marginBottom: '3px' }}>
                                  <Typography variant="body1">{item.attributes.document_name}</Typography>
                                </Box>
                                <Box>
                                  <Typography variant="body2" style={{ color: '#878787' }}>{this.handleConvertDateToReadable(item.attributes.created_at)}</Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box>
                              <Box sx={{ padding: '10px 15px', borderRadius: '7px' }} style={{ background: '#50af1c', }}>
                                <Typography variant="body1" style={{ color: 'white' }}>Uploaded</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
                <Grid item>
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <CustomizedButton onClick={this.handleGoToSellerLandingPage}>Go To My Profile</CustomizedButton>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Container >
        </Box >
      </ThemeProvider >
    )
  }
}

const webStyle = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    width: "100%",
    overflowX: "hidden",
  },
  appLogo: {
    width:'102px',
    height:'85px'
  }
}