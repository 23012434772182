import React from "react";

// Customizable Area Start
import { Box } from "@material-ui/core";
import { BoxNoScroll, CustomScrollbar } from "../CustomizedElementStyle";
import ProductProfile from "../ProductDetails/ProductProfile";
import Barcode from "../ProductDetails/Barcode.web";
import Offers from "../ProductDetails/Offers.web";
import ProductDeals from "../../../catalogue/src/ProductDeals.web";
import ProductContent from "../ProductDetails/ProductContent.web";
import Variant from "../ProductDetails/Variant.web";
// Customizable Area End

import SellerAddNewProductController, { Props, configJSON } from "./SellerAddNewProductController";
import Promotions from "../Promotions.web";

export default class SellerAddNewProduct extends SellerAddNewProductController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <Box sx={webStyle.topHeader}>{configJSON.productCatalogueText}</Box>
        <BoxNoScroll style={webStyle.boxScroll}>
          <Box {...({ ref: this.sectionRef } as any)} style={webStyle.box}>
            <Box sx={webStyle.wrapper}>
              <Box sx={webStyle.tabHeader}>{configJSON.productDetailsText}</Box>
            </Box>
            <CustomScrollbar style={webStyle.scrollbar}>
              <Box data-test-id="productProfile" sx={this.state.activePanel === configJSON.productProfileMenu ? webStyle.productDetailsColumnSelected : webStyle.productDetailsColumn} onClick={() => this.handleTabChange(0)}>
              {configJSON.productProfileTab}
              </Box>
              <Box data-test-id="content" sx={this.state.activePanel === configJSON.productContentMenu ? webStyle.productDetailsColumnSelected : webStyle.productDetailsColumn} onClick={() => this.handleTabChange(1)}>
              {configJSON.contentTab}
              </Box>
             { !this.state.isVariant &&<Box data-test-id="variant" sx={this.state.activePanel === configJSON.variantMenu ? webStyle.productDetailsColumnSelected : webStyle.productDetailsColumn} onClick={() => this.handleTabChange(2)}>
              {configJSON.variantTab}
              </Box>}
              <Box data-test-id="barcode" sx={this.state.activePanel === configJSON.barcodeMenu ? webStyle.productDetailsColumnSelected : webStyle.productDetailsColumn} onClick={() => this.handleTabChange(3)}>
              {configJSON.barcodeTab}
              </Box>
              <Box data-test-id="offer" sx={this.state.activePanel === configJSON.offerMenu ? webStyle.productDetailsColumnSelected : webStyle.productDetailsColumn} onClick={() => this.handleTabChange(4)}>
              {configJSON.offersTab}
              </Box>
              <Box data-test-id="deals" sx={this.state.activePanel === configJSON.dealsMenu ? webStyle.productDetailsColumnSelected : webStyle.productDetailsColumn} onClick={() => this.handleTabChange(5)}>
                {configJSON.dealsTab}
              </Box>
            </CustomScrollbar>
            {this.state.activePanel === configJSON.productProfileMenu && <ProductProfile navigation={this.props.navigation} id={"product-profile"} variant={this.state.isVariant}/>}
            {this.state.activePanel === configJSON.productContentMenu && <ProductContent navigation={this.props.navigation} id={"product-content"} variant={this.state.isVariant}/>}
            { !this.state.isVariant && this.state.activePanel === configJSON.variantMenu && <Variant navigation={this.props.navigation} id={"variant"} />}
            {this.state.activePanel === configJSON.barcodeMenu && <Barcode navigation={this.props.navigation} id={"barcode"} variant={this.state.isVariant}/>}
            {this.state.activePanel === configJSON.offerMenu && <Offers navigation={this.props.navigation} id={"offer"} variant={this.state.isVariant}/>}
            {this.state.activePanel === configJSON.dealsMenu && <ProductDeals navigation={this.props.navigation} id={"deals"} />}
          </Box>
        </BoxNoScroll>
        <Box sx={webStyles.footer_container}>
          <Box sx={webStyles.footer_item}>{configJSON.upgradeText}</Box>
          <Box sx={webStyles.footer_item}>{configJSON.moreThemesText}</Box>
          <Box sx={webStyles.footer_item}>{configJSON.supportText}</Box>
          <Box sx={webStyles.footer_item}>{configJSON.contactText}</Box>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  footer_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    color: "#B1B2B4",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px"
  },
  footer_item: {
    padding: "30px 0px 20px 20px",
    fontWeight: 600
  }
};
export const webStyle = {
  productDetailsColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#727272",
    height: 56,
    flex: 1,
    fontSize: 12,
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`,
    borderTop: "4px solid #FFF",
    background: "linear-gradient(180deg, #F8F8F8 0%, #EEE 100%)",
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    padding: 5
  },
  productDetailsColumnSelected: {
    height: 56,
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFF",
    fontSize: 12,
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`,
    background: "#434343",
    borderTop: "4px solid #FF6008",
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    padding: 5
  },
  scrollbar: { 
    display: "flex", 
    flexDirection: "row" as const, 
    flexWrap: "nowrap" as const, 
    width: "100%", 
    left: 0, 
    top: 100, 
    background: "linear-gradient(180deg, #F8F8F8 0%, #EEE 100%)", 
    overflowX: "auto" as const 
  },
  tabHeader: { 
    fontSize: "27px", 
    fontWeight: 700, 
    fontFamily: `"Lato", sans-serif` 
  },
  wrapper: { 
    width: "100%", 
    display: "flex", 
    flexDirection: "row", 
    flexWrap: "wrap", 
    justifyContent: "space-between", 
    alignItems: "center", 
    padding: "30px", 
    boxSizing: "border-box" 
  },
  box: {
    borderRadius: "8px", 
    flexDirection: "column", 
    overflowY: "auto", 
    overflowX: "hidden", 
    position: "relative", 
    display: "flex", 
    background: "white" 
  },
  topHeader: { 
    fontSize: "18px", 
    padding: "20px 0px 15px 0px", 
    fontFamily: `"Lato", sans-serif`, 
    fontWeight: 400, 
    flexWrap: 'wrap'
  }, 
  boxScroll: { 
    overflow: "auto" 
  }
};
// Customizable Area End
