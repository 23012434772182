import { orange } from "@material-ui/core/colors"

export const horizontal= {
    margin:'10px 0px',
    width:'100%', 
    border: '0px solid #e4e4e4'
}

export const path = {
    color: "#585858",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    fontWeight: 400 as const,
}

export const background = {
    backgroundColor: "#e5e5e5",
    padding: "20px 20px",
}

export const span = {
    color: "#FF6008",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    fontWeight: 700 as const,
    lineHeight: '16.8px'
}

export const cartText = {
    fontFamily: `"Lato", sans-serif`,
    fontSize: "32px",
    fontWeight: 700 as const,
    color: "#030303",
}

export const cartBox = {
    backgroundColor: "#fff",
    padding: "20px",
    margin: "20px 20px",
    borderRadius: "6px",
}

export const cartBoxDes = {
    paddingTop: "10px",
    color: "#585858",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    fontWeight: 400 as const,
    position: "relative" as const,
}

export const divider = {
    height: "170vh",
    position: "absolute" as const,
    left: "75%",
}

export const link = {
    textDecoration:'none',
    display:'flex', 
    alignItems:'center',
}

export const wrapper = {
    display:'flex', 
    alignItems:'center',
    gap:'3px',
    marginLeft:'20px'
}

export const zoom = { maxWidth: "1700px", padding: "0", margin: "0 auto" }

export const address = {
    color: "#585858",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "16px",
    fontWeight: 400,
}

export const text = {
    display:'flex',
    alignItems:'flex-start',
    flexDirection:'column' as const,
    justifyContent: 'space-between'
}

export const bottomContainer = {
    paddingTop:'50px',
    display:'flex', 
}

export const priceContainer = { 
    display:'flex', 
    alignItems:'flex-start',
    justifyContent:'space-between'
}

export const nextBtn =  {
    padding : '10px 16px',
    marginTop:'20px',
    fontSize:'12px',
    fontFamily: `"Montserrat", sans-serif`,
    fontWeight: 700 as const,
    width:'100%',
    textTransform: 'uppercase' as const,
    letterSpacing: '2px'
}

export const detail = {
    marginBottom:'10px',
    color: '#545454',
    fontSize:'13px',
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 400 as const
}

export const finalPriceHeader = {
    marginBottom:'10px',
    color: '#1C1C1C',
    fontSize:'13px',
    fontWeight: 600 as const,
    fontFamily: `"Lato", sans-serif`, 
  }

export const tableHeaderText = {
    color: "#969696",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "11px",
    fontWeight: 400 as const,
    textTransform: "uppercase" as const,
}

export const productDetailsWrapper = {
    margin: "10px 10px 20px"
}

export const flex = {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
}

export const productName = {
    color: "#232529",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "15px",
    fontWeight: 600 as const,
}

export const color = {
    color: "#969696",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "13px",
    fontWeight: 400 as const,
}

export const brandName = {
    color: "#33333a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "13px",
    fontWeight: 400 as const,
}

export const quantity = {
    width: "80px",
    "& .MuiOutlinedInput-input": {
        padding:'12px 12px'
    }
}
  
export const quantityField = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
}

export const priceWrapper = {
    display:'flex',
    flexDirection:'column' as const
}

export const priceTextOne = {
    color: '#1c1c1c',
    fontFamily: `"Lato", sans-serif`,
    fontSize: '15px',
    fontWeight: 600,
}

export const imgWrapper = {
    padding: "6px",
    border: '1px solid #979797',
    maxWidth: "64px",
    height: "67px",
    borderRadius: "5px",
    minWidth: '64px'
  }

  export const categoryWrapper = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }

  export const checkbox = {
    color: orange[600],
    '&$checked': {
        color: orange[600],
    },
    '&.Mui-checked': {
        color: orange[600],
    },
}

export const checkboxFormControl = {
    fontSize: "14px",
    color: "#000",
    '&MuiFormControlLabel-label': {
        color: "#000",

    },
}

export const align = {
        display:'flex',
        justifyContent: 'flex-end'
}

export const pendingOrderStatus = {
    padding: "4px 23px",
    color: "#ec9e31",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 14,
    fontWeight: 500 as const,
    border: "0px solid #ec9e31",
    backgroundColor: "#ffefd3",
    borderRadius: 5,
    borderBottom: "0",
    textAlign: "center" as const,
    width:'100px',
    justifyContent:'center',
    textTransform: 'uppercase' as const,
    maxWidth: '120px'
}

export const completedOrderStatus = {
    padding: "4px 12px",
    color: "#60c32b",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 14,
    fontWeight: 500 as const,
    border: "0px solid #ec9e31",
    backgroundColor: '#d4f4c3',
    borderRadius: 5,
    display: "flex",
    alignItems: "flex-start",
    borderBottom: "0",
    textAlign:'center' as const,
    justifyContent:'center',
    textTransform: 'uppercase' as const,
    maxWidth: '120px'
}

export const cancelledOrderStatus = {
    padding: "4px 23px",
    color: "rgb(255, 85, 111)",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 14,
    fontWeight: 500 as const,
    border: "0px solid #ec9e31",
    backgroundColor: '#FCE4E4',
    borderRadius: 5,
    display: "flex",
    alignItems: "flex-start",
    borderBottom: "0",
    justifyContent:'center',
    textTransform: 'uppercase' as const,
    maxWidth: '120px'
  }

export const refundOption = {
    padding: "5px 23px", 
    color: "#657A8E", 
    fontFamily: `"Lato", sans-serif` as const, 
    fontSize: 14, 
    fontWeight: 500 as const, 
    border: '0px solid rgba(101, 122, 142, 0.320241)',
    backgroundColor: 'rgb(242,243,244)', 
    borderRadius: 5,
    display:'flex', 
    alignItems:'flex-start', 
    borderBottom:'0',
    cursor: 'pointer'
  }  
