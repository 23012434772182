import React, { Component } from 'react';
import { Box, Button, Container, Grid, Theme, Typography, withStyles } from "@material-ui/core";
import {} from "../../blocks/landingpage/src/assets";
import { ClickableBox } from "../../blocks/otp-input-confirmation/src/CustomElementStyling";
import { configJSON,  bitmapsImg, emailImg, fbImg, linkdinImg, originalsImg, tiktokImg, whatsappImg  } from '../../blocks/landingpage/src/LandingPageController';

interface Props {
    categoryList: any[],
    classes: {
        text: string;
        footer: string;
        footerContainer: string;
        categoriesContainer: string;
        signUpBtnWrapper: string;
        signUpBox: string;
        socialIcons: string;
        originalImg: string;
        btnWrapper: string;
        contactUsWrapper: string;
        contactUsBox: string;
        copyRightText: string;
        test2: string;
        fontTheme1: string;
        body1: string;
    }
}

class TemplateFooter extends Component<Props> {
    constructor(props:any) {
        super(props);
    }
    chunkArray = (arr: any, size: number) => {
        return Array.from({ length: Math.ceil(arr?.length / size) }, (v, index) => arr?.slice(index * size, index * size + size));
    };
    render() {
        const {classes} = this.props;
        return(
            <>
            <Box className={classes.footer} data-test-id="footer">
          <Container maxWidth={"lg"}>
            <Grid container spacing={1} className={classes.footerContainer}>
              <Grid item xs={6} sm={4}  style={{ marginBottom: "20px" }}>
                <Box className={classes.categoriesContainer}>{configJSON.categoriesLabel}</Box>

                <Grid container spacing={1}>
                  {this.chunkArray(this.props.categoryList, 9).map((column, columnIndex) => (
                    <Grid item lg={4} sm={4} xs={12} key={columnIndex}>
                      <Box className={classes.test2}>
                        {column.map((item: any, index: number) => (
                            <Typography key={index} className={classes.text}>
                              {item.attributes.name}
                            </Typography>
                        ))}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={6} sm={4}  style={{ marginBottom: "20px" }}>
                <Box className={classes.categoriesContainer}>{configJSON.quickLinkLabel}</Box>
                <Box className={classes.test2}>
                  <ClickableBox data-test-id="click-1">
                    <Typography className={classes.text}>{configJSON.aboutUsLabel}</Typography>
                  </ClickableBox>
                  <ClickableBox data-test-id="click-2">
                    <Typography className={classes.text}>{configJSON.testimonialLabel}</Typography>
                  </ClickableBox>
                  <ClickableBox data-test-id="click-3">
                    <Typography className={classes.text}>{configJSON.contactLabel}</Typography>
                  </ClickableBox>
                  <ClickableBox data-test-id="click-4">
                    <Typography className={classes.text}>{configJSON.privacyPolicyLabel}</Typography>
                  </ClickableBox>
                  <ClickableBox data-test-id="click-5">
                    <Typography className={classes.text}>{configJSON.supportLabel}</Typography>
                  </ClickableBox>
                  <ClickableBox data-test-id="click-6">
                    <Typography className={classes.text}>{configJSON.termsConditionsLabel}</Typography>
                  </ClickableBox>
                  <ClickableBox data-test-id="click-7">
                    <Typography className={classes.text}>{configJSON.shippingReturnLabel}</Typography>
                  </ClickableBox>
                  <Box sx={{ marginTop: "30px" }}>
                    <Typography className={classes.body1}>{configJSON.wantToSellWithUsText}</Typography>
                    <Button className={classes.signUpBtnWrapper} data-test-id="goToSignUpButton">
                      <Box className={classes.signUpBox}>{configJSON.signUpLabel}</Box>
                    </Button>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4}  style={{ marginBottom: "20px" }}>
                <Box className={classes.categoriesContainer}>{configJSON.findOnSocialPlatformText}</Box>
                <Grid container spacing={2}>
                  <Grid item>
                      <Box className={classes.socialIcons} style={{ backgroundColor: '#39579C' }}>
                        <img src={fbImg} width={"34px"} style={{ width: '24px', margin: 'auto' }} />
                      </Box>
                  </Grid>
                  <Grid item>
                      <Box className={classes.socialIcons} style={{ backgroundColor: '#2581F6' }}>
                        <img src={linkdinImg} width={"34px"} style={{ width: '14px', margin: 'auto' }} />
                      </Box>
                  </Grid>
                  <Grid item>
                      <Box className={classes.socialIcons} style={{ backgroundColor: '#D40C0C' }}>
                        <img src={emailImg} width={"34px"} style={{ width: '14px', margin: 'auto' }} />
                      </Box>
                  </Grid>
                  <Grid item>
                      <Box className={classes.socialIcons} style={{ backgroundColor: '#509B2E' }} >
                        <img src={whatsappImg} width={"34px"} style={{ width: '14px', margin: 'auto' }} />
                      </Box>
                  </Grid>
                  <Grid item>
                      <Box className={classes.socialIcons} style={{ backgroundColor: '#F76330' }} >
                        <img src={tiktokImg} width={"34px"} style={{ width: '14px', margin: 'auto' }} />
                      </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={1} style={{ marginTop: "30px", alignItems: "center" }}>
                  <Grid item>
                    <Box className={classes.originalImg}>
                      <img src={originalsImg} width={"34px"} style={{ width: '44px', margin: 'auto', marginTop: '-5px' }} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.fontTheme1}>{configJSON.guaranteeText}</Typography>
                    <Typography className={classes.fontTheme1}>{configJSON.forAllProductsText}</Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={1} style={{ marginTop: "15px", alignItems: "center" }}>
                  <Grid item>
                    <Box className={classes.originalImg}>
                      <img src={bitmapsImg} width={"34px"} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.fontTheme1}>{configJSON.returnWithIn30DaysText}</Typography>
                    <Typography className={classes.fontTheme1}>{configJSON.receivingOrderText}</Typography>
                  </Grid>
                </Grid>

                <Box className={classes.btnWrapper} />
                <Button data-test-id="goToHome" className={classes.contactUsWrapper}>
                  <Box className={classes.contactUsBox}>{configJSON.contactUsText}</Box>
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} style={{ marginBottom: "10px", marginTop: "20px" }}>
                <Box className={classes.copyRightText}>{configJSON.copyRightText}</Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
            </>
        )
    }
}

const styles = (theme:Theme)=>({
    fontTheme1: {
      fontSize: 14,
      color: "white",
      fontFamily: "Arial, san-serif",
      paddingBottom: "5px"
    },
    text: {
      color: "#ffffff",
      fontFamily: `"Lato",sans-serif` as const,
      fontSize: "14px",
      fontWeight: 400,
      textTransform: "capitalize" as const,
      cursor: 'none'
    },
    footer: {
      margin: '20px -30px -30px -30px',
      background: "rgba(23,34,54,255)",
    },
    footerContainer: {
      padding: "30px 60px 0",
      justifyContent: "center",
      [theme.breakpoints.down("xs")]: {
        padding: "15px 0",
    },
    },
    categoriesContainer: {
      fontSize: 18,
      fontWeight: "bold" as const,
      marginBottom: "30px",
      color: "white"
    },
    signUpBtnWrapper: {
      marginTop: "11px",
      background: "rgba(253, 97, 12, 1)",
      textTransform: "none" as const,
      fontSize: 16,
      fontWeight: 600 as const,
      fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif` as const,
      borderRadius: "5px",
      width: "fit-content",
      cursor: 'none'
    },
    signUpBox: {
      padding: "5px 35px",
      fontWeight: "bold" as const,
      fontSize: 14,
      color: "white",
      fontFamily: "Arial, san-serif" as const
    },
    socialIcons: {
      width: "30px",
      height: "30px",
      borderRadius: "50px",
      display:'flex',
      justifyContent: 'center'
    },
    originalImg: {
      width: "40px",
      height: "40px",
      borderRadius: "50px"
    },
    btnWrapper: {
      width: "185px",
      height: "2px",
      marginTop: "30px",
      background: "white"
    },
    contactUsWrapper: {
      marginTop: "20px",
      background: "rgba(253, 97, 12, 1)",
      textTransform: "none" as const,
      fontSize: 16,
      fontWeight: 600 as const,
      fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif` as const,
      borderRadius: "5px",
      width: "fit-content",
      cursor: 'none'
    },
    contactUsBox: {
      padding: "5px 45px",
      fontWeight: "bold" as const,
      fontSize: 14,
      color: "white",
      fontFamily: "Arial, san-serif" as const
    },
    copyRightText: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      fontSize: 14,
      color: "#eeedee"
    },
    test2: {
      width: "100%"
    },
    body1: {
      fontSize: 16,
      color: "white",
      fontFamily: "Lato, Arial, san-serif" as const
    }
  });

export default withStyles(styles)(TemplateFooter) 