// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { createRef } from "react";
import { getStorageData } from "../../../../framework/src/Utilities";
export const configJSON = require("../../src/config.js");

type RefsMap = {
    [key: string]: React.RefObject<HTMLInputElement> | null;
};

export interface ISection {
  attributes: {
    section_name: string
  }
}

export interface IResponseJson {
  data: {
    attributes: {
      section_name: string;
      store_section_grids: {
        data: {
          attributes: {
            id: string;
            grid_name: string;
            grid_image: string;
            grid_url: string;
          };
        };
      }[];
    };
    id: string;
  }[];
}

export interface IGridData {
  data: {
    id: string,
    attributes: {
      id: string,
      store_id: string
    }
  }
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    topSpace: string,
    imgLabel: string,
    imgInput: string,
    preview: string,
    previewImg: string,
    removeBanBtn: string,
    uploadImgBtn: string,
    buttnBox: string,
    btnText: string,
    flexEndBox: string,
    justifyContentEnd: string,
    errorText: string,
    heading: string,
    headingTwo: string,
    marginStyle: string,
    paddingStyle: string,
    errorTextOne: string,
    commonErrBox: string,
    errorMargin: string
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  sectionThreeType: string,
  nameOne: string,
  nameTwo: string,
  previewImgOne: string | null,
  previewImgTwo: string | null,
  linkOne: string,
  linkTwo: string,
  linkThree: string,
  linkFour: string,
  errorOne: string,
  errorTwo: string,
  errorThree: string,
  errorFour: string,
  imageOne: string,
  imageTwo: string,
  imageThree: string,
  imageFour: string,
  linkErrOne: string,
  linkErrTwo: string,
  linkErrThree: string,
  linkErrFour: string,
  nameThree: string,
  nameFour: string,
  previewImgThree: string | null,
  previewImgFour: string | null,
  showSection3Grid1: boolean,
  showSection3Grid2: boolean,
  showSection3Grid3: boolean,
  showSection3Grid4: boolean,
  commonErr: string,
  setNameError: string,
  sectionThreeId: string,
  gridFirstId: string,
  gridSecondId: string,
  gridThirdId: string,
  gridFourthId: string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class StoreSectionTwoTabController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  secThreeGridOneImgRef: React.RefObject<HTMLInputElement>;
  secThreeGridTwoImgRef: React.RefObject<HTMLInputElement>;
  secThreeGridThreeImgRef: React.RefObject<HTMLInputElement>;
  secThreeGridFourImgRef: React.RefObject<HTMLInputElement>;
  postGridOneApiCallId: string = "";
  postGridTwoApiCallId: string = "";
  postGridThreeApiCallId: string = "";
  postGridFourApiCallId: string = "";
  getSectionInfoApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.ReceiveMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
        sectionThreeType: "4 grids layout",
        nameOne: "",
        previewImgOne: "",
        linkOne: "",
        errorOne: "",
        imageOne: "",
        nameTwo: "",
        previewImgTwo: "",
        linkTwo: "",
        errorTwo: "",
        imageTwo: "",
        linkErrOne: "",
        linkErrTwo: "",
        linkErrThree: "",
        linkErrFour: "",
        nameThree: "",
        previewImgThree: "",
        linkThree: "",
        errorThree: "",
        imageThree: "",
        nameFour: "",
        previewImgFour: "",
        linkFour: "",
        errorFour: "",
        imageFour: "",
        showSection3Grid1: true,
        showSection3Grid2: false,
        showSection3Grid3: false,
        showSection3Grid4: false,
        commonErr: "",
        setNameError: "",
        sectionThreeId: "",
        gridFirstId: "",
        gridSecondId: "",
        gridThirdId: "",
        gridFourthId: ""
    };

    this.secThreeGridOneImgRef = createRef();
    this.secThreeGridTwoImgRef = createRef();
    this.secThreeGridThreeImgRef = createRef();
    this.secThreeGridFourImgRef = createRef();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area End

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.postGridOneApiCallId) {
        this.gridOneResponse(responseJson);
      }
      if (apiRequestCallId === this.postGridTwoApiCallId) {
        this.gridTwoResponse(responseJson);
      }
      if (apiRequestCallId === this.postGridThreeApiCallId) {
        this.gridThreeResponse(responseJson);
      }
      if (apiRequestCallId === this.postGridFourApiCallId) {
        this.gridFourResponse(responseJson);
      }
      if (apiRequestCallId === this.getSectionInfoApiCallId) {
        this.handleSectionInfo(responseJson);
      }
    }
  }

  handleSectionInfo = (responseJson: IResponseJson) => {
    if (responseJson.data) {
      const data = responseJson.data.filter((item: ISection) => {
        return item.attributes.section_name === "section_3";
      });
      if (data[0]) {
        const { id, grid_name, grid_image, grid_url } = data[0].attributes.store_section_grids[0].data.attributes;
        this.setState({
          sectionThreeId: data[0].id,
          nameOne: grid_name,
          previewImgOne: grid_image,
          linkOne: grid_url,
          gridFirstId: id,
        });
        if (data[0].attributes?.store_section_grids[1]?.data.attributes) {
          const { id, grid_name, grid_image, grid_url } = data[0].attributes.store_section_grids[1].data.attributes;
          this.setState({
            sectionThreeId: data[0].id,
            nameTwo: grid_name,
            previewImgTwo: grid_image,
            linkTwo: grid_url,
            gridSecondId: id,
          });
        }
        if (data[0].attributes?.store_section_grids[2]?.data.attributes) {
          const { id, grid_name, grid_image, grid_url } = data[0].attributes.store_section_grids[2].data.attributes;
          this.setState({
            sectionThreeId: data[0].id,
            nameThree: grid_name,
            previewImgThree: grid_image,
            linkThree: grid_url,
            gridThirdId: id,
          });
        }
        if (data[0].attributes?.store_section_grids[3]?.data.attributes) {
          const { id, grid_name, grid_image, grid_url } = data[0].attributes.store_section_grids[3].data.attributes;
          this.setState({
            sectionThreeId: data[0].id,
            nameFour: grid_name,
            previewImgFour: grid_image,
            linkFour: grid_url,
            gridFourthId: id,
          });
        }
      }
    }
  };

  gridOneResponse = (responseJson: IGridData) => {
    if (responseJson.data) {
      this.setState({showSection3Grid2: true, showSection3Grid1: false,showSection3Grid3: false, showSection3Grid4: false, commonErr: "", sectionThreeId: responseJson.data.id})
      this.getSectionInfo(responseJson.data.attributes.store_id);
    }
  };

  gridTwoResponse = async (responseJson: IGridData) => {
    if (responseJson.data) {
      const storeId = await getStorageData("store_id");
      this.setState({showSection3Grid2: false, showSection3Grid1: false, showSection3Grid3: true, showSection3Grid4: false, commonErr: ""})
      this.getSectionInfo(storeId);
    }
  };

  gridThreeResponse = async (responseJson: IGridData) => {
    if (responseJson.data) {
      const storeId = await getStorageData("store_id");
      this.setState({showSection3Grid2: false, showSection3Grid1: false, showSection3Grid3: false, showSection3Grid4: true, commonErr: ""})
      this.getSectionInfo(storeId)
    }
  };

  gridFourResponse = (responseJson:IGridData) => {
    if(responseJson.data) {
      if(this.state.gridFourthId){
      this.props.navigation.navigate('SellerHomeDashboard', {path: 'store',edit:true})
      }
   else{
      this.props.navigation.navigate('SellerHomeDashboard', {path: 'store',edit:false})
      }
    }
  }

  handleGridNameChange = (input: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const gridName = event.target.value 
    if(input === "nameOne") {
        this.setState({ nameOne : event.target.value})
        this.checkGridName(gridName)
    }
    if(input === "nameTwo") {
        this.setState({ nameTwo : event.target.value})
        this.checkGridName(gridName)
    }
    if(input === "nameThree") {
        this.setState({ nameThree : event.target.value})
        this.checkGridName(gridName)
    }
    if(input === "nameFour") {
        this.setState({ nameFour : event.target.value})
        this.checkGridName(gridName)
    }
  }

  checkGridName = (value: string) => {
    
    if (!(value.length>=3&&value.length<=15)) {
      this.setState({ setNameError: configJSON.nameValidateText });
    } else this.setState({ setNameError: "" });
  };

  handleUploadGridImage = (input:string, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const targetElement = event.target as HTMLInputElement;
    const file: File = (targetElement.files as FileList)[0];
    if (!file) return;
    const fileSize = file.size / (1024 * 1024);
    if (fileSize > 5) {
        this.getErrorStateName(input)
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      if(input === "imageOne") {
        return this.setState({
            previewImgOne: reader.result as string,
            errorOne: ""
          });
      } 
      if(input === "imageTwo") {
        return this.setState({
            previewImgTwo: reader.result as string,
            errorTwo: ""
          });
      }
      if(input === "imageThree") {
        return this.setState({
            previewImgThree: reader.result as string,
            errorThree: ""
          });
      }
      if(input === "imageFour") {
        return this.setState({
            previewImgFour: reader.result as string,
            errorFour: ""
          });
      }  
    };
    reader.readAsDataURL(file);

    this.setState(({ [input]: file } as unknown) as Pick<S, keyof S>);
  };

  getErrorStateName = (input:string) => {
    if(input === "imageOne") {
        return this.setState({ errorOne: configJSON.imageSizeErrorText });
    }
    if(input === "imageTwo") {
        return this.setState({ errorTwo: configJSON.imageSizeErrorText });
    }
    if(input === "imageThree") {
        return this.setState({ errorThree: configJSON.imageSizeErrorText });
    }
    if(input === "imageFour") {
        return this.setState({ errorFour: configJSON.imageSizeErrorText });
    }
  }

  handleOpenGridImage = (input: string) => {
    const refsMap:RefsMap = {
        "imageOne": this.secThreeGridOneImgRef,
        "imageTwo": this.secThreeGridTwoImgRef,
        "imageThree": this.secThreeGridThreeImgRef,
        "imageFour": this.secThreeGridFourImgRef
    };

    const targetRef = refsMap[input];
    if (targetRef && targetRef.current) {
        targetRef.current.click();
    }
  }

  handleRemoveGridImage = (input: string) => {
    if(input === "imageOne") {
        this.setState({previewImgOne: null, imageOne: "" })
    }
    if(input === "imageTwo") {
        this.setState({previewImgTwo: null, imageTwo: "" })
    }
    if(input === "imageThree") {
        this.setState({previewImgThree: null, imageThree: "" })
    }
    else this.setState({previewImgFour: null, imageFour: "" })
  }

handleGridLink = (input: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const gridLink = event.target.value;
    this.setState({ [input]: gridLink } as unknown as Pick<S, keyof S>);
    const urlPattern = /^(https:\/\/|www.)/;

    if (gridLink === '' || !urlPattern.test(gridLink)) {
        switch (input) {
            case "linkOne":
                this.setState({ linkErrOne: configJSON.validUrlErrorText  });
                break;
            case "linkTwo":
                this.setState({ linkErrTwo: configJSON.validUrlErrorText  });
                break;
            case "linkThree":
                this.setState({ linkErrThree: configJSON.validUrlErrorText  });
                break;
            case "linkFour":
                this.setState({ linkErrFour: configJSON.validUrlErrorText  });
                break;
        }
    } else {
        switch (input) {
            case "linkOne":
                this.setState({ linkErrOne: "" });
                break;
            case "linkTwo":
                this.setState({ linkErrTwo: "" });
                break;
            case "linkThree":
                this.setState({ linkErrThree: "" });
                break;
            case "linkFour":
                this.setState({ linkErrFour: "" });
                break;
        }
    }
}


  handleSec3Grid1Next = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const {nameOne, previewImgOne, linkOne} = this.state;
    if(!nameOne || !previewImgOne || !linkOne) {
        this.setState({commonErr : configJSON.fillAllFieldsText})
    }
    else {
      this.createSectionThreeGridOne()
    }
  }

  handleSec3Grid2Next = () => {
    const {nameTwo, previewImgTwo, linkTwo} = this.state;
    if(!nameTwo || !previewImgTwo || !linkTwo) {
        this.setState({commonErr : configJSON.fillAllFieldsText})
    }
    else {
      this.createSectionThreeGridTwo()
    }
   }

   handleSec3Grid3Next = () => {
        const {nameThree, previewImgThree, linkThree} = this.state;
        if(!nameThree || !previewImgThree || !linkThree) {
            this.setState({commonErr : configJSON.fillAllFieldsText})
        }
        else {
          this.createSectionThreeGridThree()
        }
    }

    handleSec3Grid4Next = () => {
        const {nameFour, previewImgFour, linkFour} = this.state;
        if(!nameFour || !previewImgFour || !linkFour) {
            this.setState({commonErr : configJSON.fillAllFieldsText})
        }
        else {
            this.createSectionThreeGridFour()
        }
    }

  createSectionThreeGridOne = async () => {
    const storeId = await getStorageData("store_id");
    const endpoint = this.state.sectionThreeId ? `/store_dashboard_sections/${this.state.sectionThreeId}` : "/store_dashboard_sections";
    const apiMethod = this.state.sectionThreeId ? configJSON.patchMethod : configJSON.postMethod;
    const headers = { token: await getStorageData("authToken") };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postGridOneApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + storeId + endpoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), apiMethod);
    const formData = new FormData();
    formData.append("section_name", "section_3");
    formData.append("section_type", "4_grids_layout");
    if (this.state.gridFirstId) {
      formData.append("store_section_grids_attributes[0][id]", this.state.gridFirstId);
    }
    formData.append("store_section_grids_attributes[0][grid_no]", "grid_1");
    formData.append("store_section_grids_attributes[0][grid_name]", this.state.nameOne);
    if (this.state.imageOne) {
      formData.append("store_section_grids_attributes[0][grid_image]", this.state.imageOne);
    }
    formData.append("store_section_grids_attributes[0][grid_url]", this.state.linkOne);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };  

  createSectionThreeGridTwo = async () => {
    const storeId = await getStorageData("store_id");
    const endpoint = this.state.gridSecondId ? `/store_dashboard_sections/${this.state.sectionThreeId}/store_section_grids/${this.state.gridSecondId}` : `/store_dashboard_sections/${this.state.sectionThreeId}/store_section_grids`;
    const apiMethod = this.state.gridSecondId ? configJSON.patchMethod : configJSON.postMethod;
    const headers = { token: await getStorageData("authToken") };
    const gridTwoMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postGridTwoApiCallId = gridTwoMessage.messageId;
    gridTwoMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + storeId + endpoint);
    gridTwoMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    gridTwoMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), apiMethod);
    const formData = new FormData();
    formData.append("section_name", "section_3");
    formData.append("section_type", "4_grids_layout");
    formData.append("grid_no", "grid_2");
    formData.append("grid_name", this.state.nameTwo);
    if (this.state.imageTwo) {
      formData.append("grid_image", this.state.imageTwo);
    }
    formData.append("grid_url", this.state.linkTwo);
    gridTwoMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    runEngine.sendMessage(gridTwoMessage.id, gridTwoMessage);
  };

  createSectionThreeGridThree = async () => {
    const storeId = await getStorageData("store_id");
    const endpoint = this.state.gridThirdId ? `/store_dashboard_sections/${this.state.sectionThreeId}/store_section_grids/${this.state.gridThirdId}` : `/store_dashboard_sections/${this.state.sectionThreeId}/store_section_grids`;
    const apiMethod = this.state.gridThirdId ? configJSON.patchMethod : configJSON.postMethod;
    const headers = { token: await getStorageData("authToken") };
    const gridThreeMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postGridThreeApiCallId = gridThreeMessage.messageId;
    gridThreeMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + storeId + endpoint);
    gridThreeMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    gridThreeMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), apiMethod);
    const formData = new FormData();
    formData.append("section_name", "section_3");
    formData.append("section_type", "4_grids_layout");
    formData.append("grid_no", "grid_3");
    formData.append("grid_name", this.state.nameThree);
    if (this.state.imageThree) {
      formData.append("grid_image", this.state.imageThree);
    }
    formData.append("grid_url", this.state.linkThree);
    gridThreeMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    runEngine.sendMessage(gridThreeMessage.id, gridThreeMessage);
  };

  createSectionThreeGridFour = async () => {
    const storeId = await getStorageData("store_id");
    const endpoint = this.state.gridFourthId ? `/store_dashboard_sections/${this.state.sectionThreeId}/store_section_grids/${this.state.gridFourthId}` : `/store_dashboard_sections/${this.state.sectionThreeId}/store_section_grids`;
    const apiMethod = this.state.gridFourthId ? configJSON.patchMethod : configJSON.postMethod;
    const headers = { token: await getStorageData("authToken") };
    const gridThreeMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postGridFourApiCallId = gridThreeMessage.messageId;
    gridThreeMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + storeId + endpoint);
    gridThreeMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    gridThreeMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), apiMethod);
    const formData = new FormData();
    formData.append("section_name", "section_3");
    formData.append("section_type", "4_grids_layout");
    formData.append("grid_no", "grid_4");
    formData.append("grid_name", this.state.nameFour);
    if (this.state.imageFour) {
      formData.append("grid_image", this.state.imageFour);
    }
    formData.append("grid_url", this.state.linkFour);
    gridThreeMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    runEngine.sendMessage(gridThreeMessage.id, gridThreeMessage);
  };

  async componentDidMount(): Promise<void> {
    const storeId = await getStorageData("store_id");
    if (storeId) {
      this.getSectionInfo(storeId);
    }
  }

  getSectionInfo = async (id: string) => {
    const headers = { token: await getStorageData("authToken") };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSectionInfoApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + id + "/store_dashboard_sections");
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(message.id, message);
  };
  //Customizable Area End
}