import React from "react";

// Customizable Area Start
import { Box,FormControl ,IconButton, Input, InputAdornment, InputLabel, Modal, ThemeProvider,TextField,createTheme  } from "@material-ui/core";
import { errorImage } from "../../bulkuploading/src/assets";
import ClearIcon from "@material-ui/icons/Clear";
import Visibility from "@material-ui/icons/Visibility";
import { ClickableBox, VisibilityOffSvg, CustomSubmitButton } from "../../mobile-account-login/src/CustomElementStyling";
// Customizable Area End

import BuyerEmailLoginController, { Props } from "./BuyerEmailLoginController";

export const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
      fontFamily: `"Lato",sans-serif`,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1460,
    },
  },
  overrides: {
    MuiInput: {
      root: {
        fontFamily: `"Lato", sans-serif`,
        paddingBottom:'11px'
      },
      underline: {
         "&:before": {
          borderBottom: "0.01rem solid #D8D8D8",
        },
        "&:after": {
          borderBottom: "0.01rem solid #D8D8D8",
        },
        "&:hover:not($disabled):before": {
          borderBottom: "0.01rem solid #D8D8D8",
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: `"Lato", sans-serif`,
        "&$focused": {
          color:"grey"
        },
        fontSize: 14,
        fontWeight: 600,
      },
    },
    MuiContainer: {
      root: {
        fontFamily: `"Lato", sans-serif`,
      },
    },
    MuiButton: {
      root: {
        fontFamily: `"Lato", sans-serif`,
      },
    },
  },
});

export default class BuyerEmailLogin extends BuyerEmailLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  CloseElement = (testIdRef: string) => {
    return (
      <Box sx={webStyle.buttonClose}>
        <IconButton onClick={this.handleCloseModal} data-test-id={testIdRef}>
          <ClearIcon style={{ color: "#3E3E3E" }} />
        </IconButton>
      </Box>
    );
  };
  DisplayError = () => {
    return (
      <>
        {this.state.errorMessages.map((item, index) => {
          return (
            <Box key={index} sx={webStyle.errorMsgContainer}>
              <Box sx={{ marginRight: "5.1px" }}>
                <img src={errorImage} />
              </Box>
              <Box sx={{ color: "#D74D4D", fontSize: 14, fontFamily: `"Lato", sans-serif`, fontWeight: 600 }}>{item}</Box>
            </Box>
          );
        })}
      </>
    );
  };
  EmailLoginModal = () => {
    return (
      <Modal hideBackdrop={true} open={this.state.showEmailLoginModal}>
        <Box sx={webStyle.main}>
          <Box sx={webStyle.blurBg}></Box>
          <Box sx={webStyle.modalContainer}>
            {this.CloseElement("close")}
            <Box style={fontStyle.h1}>Sign in to your account</Box>
            <Box sx={webStyle.subHeaderBox}>
              <Box style={{ ...fontStyle.h2, marginRight: "5px",fontWeight:"bold" }}>Don't have an account?</Box>
              <ClickableBox onClick={this.handleGoToSignUpPage} data-test-id="goToSignUpPage">
                <Box style={{ ...fontStyle.h3 , margin:"0px"}}>Sign Up</Box>
              </ClickableBox>
            </Box>
            <TextField data-test-id="email" label="Email" onKeyDown={this.handleKeyDown} fullWidth style={{ marginTop: "40px"}} value={this.state.email} onChange={(event) => this.handleInputChange("email", event.target.value)} /> 
            <FormControl>
              <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
              <Input
                id="standard-adornment-password"
                data-test-id="password"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.password}
                onChange={(event) => this.handleInputChange("password", event.target.value)}
                onKeyDown={this.handleKeyDown}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" data-test-id="showPassword" onClick={this.handleShowPassword}>
                      {this.state.showPassword ? <Visibility className="rotateIcon" /> : <VisibilityOffSvg />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Box sx={{ paddingTop: "20px" }}>{this.DisplayError()}</Box>
            <Box sx={webStyle.forgotPwBox}>
              <ClickableBox data-test-id="forget_password" onClick={() => this.handleOpenFP()}>
                <Box style={fontStyle.h4}>Forgot Password ?</Box>
              </ClickableBox>
            </Box>
            <CustomSubmitButton disabled={this.state.isFetching} data-test-id="signin" onClick={this.handleSignIn}>
              Sign In
            </CustomSubmitButton>
          </Box>
        </Box>
      </Modal>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <ThemeProvider theme={theme}>{this.EmailLoginModal()}</ThemeProvider>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  buttonClose: {
    position: "absolute",
    top: "28px",
    right: "28px",
    boxShadow: "0px 3px 6px rgba(86, 86, 86, 0.1)",
    borderRadius: "100%",
  },
  errorMsgContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "no-wrap",
    alignItems: "center",
    marginTop: "5px",
  },
  main: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "none",
    position: "relative",
  },
  blurBg: {
    position: "absolute",
    zIndex: -1,
    backgroundColor: "rgba(0,0,0,0.1)",
    width: "100%",
    height: "100%",
    backdropFilter: "blur(8px)",
  },
  modalContainer: {
    display: "flex",
    width: "495px",
    padding: "50px",
    background: "white",
    borderRadius: "8px",
    flexDirection: "column",
    margin: "15px",
    position: "relative",
  },
  subHeaderBox: {
    marginTop: "22px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  forgotPwBox: {
    marginTop: "20px",
    marginBottom: "85px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
};

const fontStyle = {
  h4: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#061b6f",
    fontFamily: `"Lato", sans-serif`,
  },
  h1: {
    fontSize: "20px",
    fontWeight: 700,
    color: "#3E3E3E",
    fontFamily: `"Lato", sans-serif`,
  },
  h5: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#061B6F",
    fontFamily: `"Lato", sans-serif`,
  },
  h2: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#3E3E3E",
    fontFamily: `"Lato", sans-serif`,
  },
  h3: {
    fontSize: "13px",
    fontWeight: 700,
    color: "#FF6008",
    fontFamily: `"Lato", sans-serif`,
  },
  h6: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#565656",
    fontFamily: `"Lato", sans-serif`,
  },
};
// Customizable Area End
