import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../../framework/src/Utilities";
import { ICreateStoreData, ICreateStreError, ISearchBrandData, ISearchBrandResult } from "../SellerHomeDashboardComponents/Interfaces";
import { InputProps } from "@material-ui/core/Input";
import { createRef } from "react";
import { SetCookies } from "../../../../framework/src/WebUtilities";
import StorageProvider from "../../../../framework/src/StorageProvider";
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object | string;
}
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  store_name: string;
  store_year: string | number;
  brand_trade_certificate: any;
  store_url: string;
  website_social_url: string;
  brand_id: string | number;
  brandResult: ISearchBrandData[];
  errorMessage: string;
  isFetching: boolean;
  successMessage: string;
  createVisible: boolean;
  isEditable: boolean;
  storeId: string;
  isError: "";
  isFileUpload: boolean;
  dealsList: any;
  dealsDdValues: string;
  selectedStoreValueName: string;
  websiteErr: string
  socialErr: string,
  brandErr: string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class CreateStorePageController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetBrandListCallId: string = "";
  apiCreateStoreCallId: string = "";
  apiGetStoreCallID: string = "";
  inputCertificateFile: React.RefObject<InputProps & { click: Function }>;
  postCreateStoreApiACallId: string = "";
  editStoreApiCallId: string = "";
  getStoreListCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.ReceiveMessage), getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      isEditable: false,
      isFileUpload: false,
      isError: "",
      store_name: "",
      store_year: "",
      brand_trade_certificate: "",
      store_url: "",
      website_social_url: "",
      brand_id: "",
      brandResult: [],
      isFetching: false,
      socialErr:'',
      errorMessage: "",
      successMessage: "",
      createVisible: false,
      storeId: "",
      dealsList: [],
      dealsDdValues: "",
      selectedStoreValueName: "",
      websiteErr: "",
      brandErr: ""
    };

    this.inputCertificateFile = createRef();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received 1111", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.setState({ isFetching: false });
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.apiGetBrandListCallId) {
        this.handleBrandListResponse(responseJson);
      }
      if (apiRequestCallId === this.apiCreateStoreCallId) {
        this.handleCreateStoreResponse(responseJson);
      }
      if (apiRequestCallId === this.editStoreApiCallId) {
        this.handleEditeStoreResponse(responseJson);
      }
      if (this.getStoreListCallId === apiRequestCallId) {
        this.handleGetStoreDdRponseJson(responseJson);
      }
      if(this.apiGetStoreCallID === apiRequestCallId) {
        this.handleStoreDetailsResponse(responseJson)
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start

  handleStoreDetailsResponse = (responseJson:any) => {
    if(responseJson.data) {
      const { id, store_year, brand, store_url, website_social_url, brand_trade_certificate } = responseJson.data.attributes
      this.setState({
        storeId: id,
        store_year: store_year,
        isEditable: true,
        dealsDdValues: brand.id.toString(),
        store_url: store_url,
        website_social_url: website_social_url,
        brand_trade_certificate: brand_trade_certificate
      })
    }
  }


  storeApiCall = (data: APIPayloadType) => {
    let { contentType, method, endPoint, body } = data;
    const authToken = window.localStorage.getItem("authToken");
    const header = {
      "Content-Type": contentType,
      authToken
    };

    let storeRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    storeRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    storeRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    storeRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && storeRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(storeRequestMessage.id, storeRequestMessage);
    return storeRequestMessage.messageId;
  };

  getStoreDetails = async() => {
    const storeId = await getStorageData("store_id")
    this.apiGetStoreCallID = this.storeApiCall({
      contentType: configJSON.dashboarContentType,
      method: configJSON.getMethod,
      endPoint: configJSON.getStoreEndPoint + `/${storeId}`
    });
  };
  handleGetStoreDdRponseJson = (responseJson: any) => {
    if (responseJson) {
      const filterData=responseJson.data.filter((item:any)=>{
        return item.attributes.approve
      })
      this.setState({ dealsList: filterData});
      if(responseJson.data.length === 0) {
        this.setState({dealsList: [], brandErr: "No brands available. Please create a new one to proceed"})
      }
    } 
  };
  handleCreateStoreResponse = async(responseJson: ICreateStoreData | ICreateStreError) => {
    if ("data" in responseJson) {
      await setStorageData("store_id", responseJson.data.id)
      await setStorageData("brandId", responseJson.data.attributes.brand.id)
      await setStorageData("store_name", responseJson.data?.attributes?.store_name)
      return this.handleGoToCreateTabs();
    }
    if ("errors" in responseJson) {
      let errorMessage = responseJson.errors[0]?.toLowerCase().includes("store name has already been taken") ? responseJson.errors[0] : "Please fill in all required fields";

      if (responseJson.errors.some((error: string) => error.toLowerCase().includes("account already has a store associated"))) {
        errorMessage = "Account already has a store associated";
      }

      return this.setState({ errorMessage });
    }
  };
  handleEditeStoreResponse = async (responseJson: unknown) => {
    const responseData: any = responseJson;
    if (responseData.errors) {
      this.setState({ isError: responseData.errors });
    } else {
      await setStorageData("store_id", responseData.data.id)
      await setStorageData("brandId", responseData.data.attributes.brand.id)
    this.props.navigation.navigate(configJSON.sellerHomeText, {path: configJSON.createTabsPath})
      await setStorageData("store_name", responseData.data?.attributes?.store_name)
      StorageProvider.set("storeYear", this.state.store_year);
      this.setState({
        isEditable: false
      });
    }
  };

  handleBrandListResponse = (responseJson: unknown) => {
    const successData = responseJson as ISearchBrandResult;
    if ("data" in successData) {
      return this.setState({ brandResult: [...successData.data] });
    }
  };

  handleOnInputChange = (input: string, value: string) => {
    const urlRegex = configJSON.imageUrlRegex
    if(input === "website_social_url") {
      if(!urlRegex.test(value)) {
        this.setState({websiteErr : configJSON.validUrlErrorText})
      }
      else {
        this.setState(({ websiteErr: "",[input]: value } as unknown) as Pick<S, keyof S>);
      }
    }
   else if(input === "store_url") {
      if(!urlRegex.test(value)) {
        this.setState(({ [input]: value,socialErr: configJSON.validUrlErrorText } as unknown) as Pick<S, keyof S>);
      }
      else {
        this.setState(({ [input]: value ,socialErr:''} as unknown) as Pick<S, keyof S>);
      }
    }
    this.setState(({ [input]: value } as unknown) as Pick<S, keyof S>);
  };

  handleOpenUploadFile = () => {
    if (this.inputCertificateFile && this.inputCertificateFile.current) {
      this.inputCertificateFile.current.click();
    }
  };

  handleGoToCreateTabs = async() => {
    this.props.navigation.navigate(configJSON.sellerHomeText, {path: configJSON.createTabsPath})
    StorageProvider.set("storeYear", this.state.store_year);
  };

  handleRemoveFile = () => {
    this.setState({ brand_trade_certificate: "" });
  };

  handlBackWelcome = () => {
    this.props.navigation.navigate(configJSON.sellerHomeText, {path: "store"})
  };

  handleOnSubmitAndCreatePage = (event: React.FormEvent) => {
    event.preventDefault();
      this.setState({ errorMessage: "", successMessage: "" });
      if(!this.state.websiteErr && !this.state.socialErr) {
        this.state.isEditable ? this.onEditStore() : this.createStore()
      }
  };

  handleUploadCertFile = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    if (!file) return;
    if (file && file.type !== "application/pdf") {
      return this.setState({ errorMessage: "Please upload a PDF document." });
    }

    this.setState({ brand_trade_certificate: file, isFileUpload: true });
  };

  handleGenerateWebUrl = () => {
    const baseUrl = window.location.origin;
    const brandUrl = this.state.selectedStoreValueName
      ? this.state.selectedStoreValueName
          .toLowerCase()
          .split(" ")
          .join("-")
      : "";
    this.setState({ store_url: baseUrl + "/store/" + brandUrl });
  };

  getBrandList = async () => {
    const headers = { "Content-Type": configJSON.contentType, token: await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetBrandListCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateBrandEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createStore = async () => {
    this.setState({ isFetching: true });
    const headers = { token: await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCreateStoreCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.postStoreEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    const formData = new FormData();
    this.state.brand_trade_certificate && formData.append("brand_trade_certificate", this.state.brand_trade_certificate);
    formData.append("store_name", this.state.selectedStoreValueName);
    formData.append("brand_id", this.state.dealsDdValues);
    formData.append("store_year", String(this.state.store_year));
    formData.append("store_url", this.state.store_url);
    formData.append("website_social_url", this.state.website_social_url);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  onEditStore = async () => {
    const headers = {
      token: await getStorageData("authToken")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.editStoreApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.myDealsApiEndPoint.editStoreData(this.state.storeId));
    const formData = new FormData();
    if (this.state.isFileUpload) {
      formData.append("brand_trade_certificate", this.state.brand_trade_certificate);
    }
    if(this.state.store_year) {
      formData.append("store_year", String(this.state.store_year));
    }
    formData.append("store_name", this.state.selectedStoreValueName);
    formData.append("store_url", this.state.store_url);
    formData.append("website_social_url", this.state.website_social_url);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount(): Promise<void> {
    let storeData = await getStorageData("store_data");
    let getStoreData = JSON.parse(storeData);
    if (storeData) {
      this.setState({
        storeId: getStoreData.attributes.id,
        store_year: getStoreData.attributes.store_year,
        isEditable: true,
        dealsDdValues: getStoreData.attributes.brand.id.toString(),
        store_url: getStoreData.attributes.store_url,
        website_social_url: getStoreData.attributes.website_social_url,
        brand_trade_certificate: getStoreData.attributes.brand_trade_certificate
      });
      this.handleChangeMenuItem(getStoreData.attributes.brand.id.toString(), getStoreData.attributes.brand.brand_name);
    } else {
      this.handleChangeMenuItem("");
    }
    this.getBrandList();
    this.getStoreDetails();
  }

  handleStoreYear = (event: { target: { value: string } }) => {
    let reg = new RegExp(configJSON.regEx);
    let correct = reg.test(event.target.value);
    if (correct) {
      this.setState({ store_year: event.target.value });
    }
  };

  handleChangeMenuItem = async (id: any, storeValue?: any) => {
    let arr = this.state.dealsList.filter((item: any, ind: any) => {
      return item.id == id;
    })
    const brandName = arr[0]?.attributes?.brand_name ?? "";
    this.setState({ dealsDdValues: id, selectedStoreValueName: storeValue ? storeValue : brandName, store_year: arr[0]?.attributes.brand_year });
    const headers = {
      token: await getStorageData("authToken")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStoreListCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.storeCreateList.storeDDList);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
