// Customizable Area Start
import React from "react";
import { LinearProgress, Box, Grid, Typography, withStyles, Divider, Button, FormGroup, FormControlLabel, Checkbox, Menu, Hidden } from "@material-ui/core";
import HappinessRatingController, { Props, configJSON } from "./HappinessRatingController.web";
import { Rating } from "@material-ui/lab";
import { BoxNoScroll } from "../../dashboard/src/CustomizedElementStyle";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
class HappinessRating extends HappinessRatingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getHeading = (ratingType:string) => {
    return(
      <Hidden mdUp>
        <Grid className={`${this.props.classes.header} ${this.props.classes.headerStyle}`} item xs={12} md={12} lg={12} sm={12}>
        <Typography variant="h6">{ratingType}</Typography>
        </Grid>
      </Hidden>
    )
  }

  filter = () => {
    return <FormGroup data-test-id="FormGroupRef">
    <Typography className={this.props.classes.filterText}>{'Filter by Rating'}</Typography>
      {[...Array(5).keys()].map(i => 5 - i).map((item: any, index: number) => {
        return <Box className={this.props.classes.filterlistWrapper} key={`${index}-form-group`}>
          <FormControlLabel
           data-test-id="FormControlLabelRef"
            control={<Checkbox checked={this.state.selectedFilters.includes(item)} data-test-id="checkboxFilterRef"
              onChange={() => this.handleCheckboxChange(item)}
              value={item.toString()} className={this.props.classes.checkBox} />}
            label={<Rating
              name="customized-color"
              precision={0.5}
              value={item}
              readOnly
            />}
            className={this.props.classes.checkBoxFormControl}
          />
        </Box>

      })}
    </FormGroup>
  }
  RatingProgressBar = (item: any, ratingCounts: any) => {
    const { classes } = this.props;

    return (
      <Box className={classes.root}>
        <Box className={classes.averageRating}>
          <Box className={classes.averageRatingTitle}>
            <Typography variant="h4" style={{ fontWeight: 500 ,fontFamily: `"Lato", sans-serif`,}}>{item?.average_rating}</Typography>
            <Rating value={Number(item?.average_rating)} precision={0.1} readOnly data-test-id="ratingProgressRef" />
          </Box>

          <Typography variant="h6" style={{ fontWeight: 600, fontStyle: 'italic', color: "#b6b6b6", fontSize: "11px", lineHeight: "0.2",fontFamily: `"Lato", sans-serif`, }}>{this.ratePerformance(Number(item?.average_rating))}</Typography>
        </Box>
        <Divider />
        <Box style={{ padding: "15px 10px 60px 10px" }}>
          {ratingCounts && Object.values(ratingCounts).reverse().map((count: any, index) => (
            <Box key={index} mb={1} className={classes.progreeBarContainers}>
              <Typography variant="subtitle1"  style={{ fontFamily: `"Lato", sans-serif`,}}>
                {5 - index}
              </Typography>
              <Typography variant="subtitle1" className={classes.starIcon}>
                &#9733;
              </Typography>
              <LinearProgress
                data-test-id="LinearProgressRef"
                variant="determinate"
                value={Number(count)}
                className={classes.progressBar}
                classes={{ bar: classes.progressBarOrange }}

              />
              <Typography variant="subtitle1" style={{ marginLeft: "5px" ,fontFamily: `"Lato", sans-serif`,}}>
                ({Math.floor(Number(count))})
              </Typography>
            </Box>
          ))}
        </Box>

      </Box>
    );

  };

  reviewListFunction = (arr: any, header: string, averageRating: any, ratingCounts: any) => {
    const { classes } = this.props;
    return (
      <Box>
        {averageRating?.average_rating ? <Box>{this.RatingProgressBar(averageRating, ratingCounts)}</Box> : <Box style={{
          fontFamily: `"Lato", sans-serif`, fontSize: 16, marginTop: 25,
          color: "rgb(254, 96, 11)", height: "267px",
          display: "flex",
          alignItems: "center",
          // padding: " 0 32px",
          textAlign: "center"
        }}>No Average {header} found</Box>}
        <Typography className={classes.userRatingHeading} variant="body1">{header}</Typography>

        <Box className={classes.reviewListWrraper}>
          {arr && Array.isArray(arr) && arr.length > 0 ? arr.map((item: any) => {
            return <Box className={classes.reviewListContainer}>
              <Typography className={classes.nameDate} variant="body1">{item?.reviewer_name ? `${item?.reviewer_name}-` : ""}{`${moment(item?.updated_at).format("MMM DD YYYY")}`}</Typography>
              <Typography className={classes.ratingTitle} variant="h6">{`${item.title}`}</Typography>
              <Rating className={classes.ratingStar}
                data-test-id="ratingRef"
                name="customized-color"
                precision={0.5}
                value={item?.rating}
                readOnly
              />
              <Typography className={classes.ratingDescription} variant="body1">{`${item?.description}`}</Typography>
            </Box>
          }) : <Box style={{
            fontFamily: `"Lato", sans-serif`, fontSize: 16, marginTop: 25,
            color: "rgb(254, 96, 11)"
          }}>No {header} found</Box>}
        </Box>
      </Box>
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Box sx={{ fontFamily: `"Lato", sans-serif`, padding: "20px 0px 15px 0px", fontSize: "18px", fontWeight: 400 }}>{configJSON.ratingType.title}</Box>
        <Box sx={{ justifyContent: "space-between", display: "flex", alignItems: "center", fontFamily: `"Lato", sans-serif`, padding: "23px", fontSize: "20px", fontWeight: 600, borderRadius: "8px 8px 0 0" }} style={{ backgroundColor: "#fff" }}>{configJSON.ratingType.title}  <Button
          data-test-id="filterRef"
          style={{
            textTransform: "none",
            fontFamily: `"Lato", sans-serif`,
            fontWeight: 700,
            fontSize: 16,
            background: "#f7f7f7",
            color: "#9ba1aa",
            display: "flex",
            alignItems: "center",
            borderRadius: "8px",
            border: "1px solid #9ba1aa",
            padding: "12px 45px"
          }}
          onClick={this.handleFilterButtonClick}
        >
          {" "}
          Filter
        </Button>
          <Menu
            id="filter-menu"
            data-test-id="filterMenu"
            anchorEl={this.state.filterRatingAnchorEl}
            keepMounted
            open={Boolean(this.state.filterRatingAnchorEl)}
            onClose={this.handleCloseFilterRating}
            PaperProps={{
              style: {
                fontFamily: `"Lato", sans-serif`,
                padding: "12px 0 12px 16px",
                borderRadius: "5px",
                marginTop: "155px",
                boxSizing: "border-box" as const,
                maxHeight: "300px",
                width: "auto"
              }
            }}
          >
            {this.filter()}
          </Menu>
        </Box>
        <BoxNoScroll style={{ paddingBottom: 20.5, overflow: "auto" }}>
          <Box style={{ borderRadius: "8px", display: "flex", flexDirection: "column", overflowY: "auto", overflowX: "hidden", position: "relative", }}>
            <Box className={classes.happinessContainer}>
              <Hidden smDown>
              <Grid container style={{ padding: "10px" }}>
                <Grid className={classes.header} item xs={12} md={4} lg={4} sm={12}>
                  <Typography variant="h6">{configJSON.ratingType.seller}</Typography>
                </Grid>
                <Grid className={classes.header} item xs={12} md={4} lg={4} sm={12}> <Typography variant="h6">{configJSON.ratingType.delivery}</Typography></Grid>
                <Grid className={classes.header} item xs={12} md={4} lg={4} sm={12}> <Typography variant="h6">{configJSON.ratingType.catalog}</Typography></Grid>
              </Grid>
              </Hidden>
              <Box className={classes.happinessWrapper}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} lg={4} sm={12}>
                    {this.getHeading(configJSON.ratingType.seller)}
                    {this.reviewListFunction(this.state.sellerRatingList, "Seller Review", this.state.sellerAverageRating, this.state.sellerAverageRating?.seller_review_percentages)}
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} sm={12}>
                  {this.getHeading(configJSON.ratingType.delivery)}
                    {this.reviewListFunction(this.state.deliveryRatingList, "Delivery Review", this.state.deliveryAverageRating, this.state.deliveryAverageRating?.seller_review_percentages)}
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} sm={12}>
                    {this.getHeading(configJSON.ratingType.catalog)}
                    {this.reviewListFunction(this.state.catalogRatingList, "Catalog Review", this.state.catalogAverageRating, this.state.catalogAverageRating?.seller_review_percentages)}
                  </Grid>
                </Grid>

              </Box>
            </Box>
          </Box>
        </BoxNoScroll>
      </>
    )
  }
  // Customizable Area End
}

const styles = {
  root: {
    width: '100%',
    marginTop: 16,
  },
  progressBar: {
    height: 13,
    borderRadius: 10,
    backgroundColor: '#dadada',
    width: '300px',
    flex: "0 0 80%",
    fontFamily: `"Lato", sans-serif`,
  },
  progreeBarContainers: {
    display: "flex",
    flexDirection: "row" as const,
    columnGap: "2px",
    alignItems: "center",
    margin: 0
  },
  progressBarOrange: {
    height: '100%', // Set the height to 100% to fill the entire background
    borderRadius: 10,
    backgroundColor: '#fe600b',
  },
  averageRating: {
    marginBottom: 16,
  },
  averageRatingTitle: {
    display: "flex",
    flexDirection: "row" as const,
    alignItems: 'center',
    fontFamily: `"Lato", sans-serif`,

  },
  header: {
    display: "flex",
    color: "#797979",
    padding: "0 10px",
    fontFamily: `"Lato", sans-serif`,
    "&>h6": {
      fontWeight: "600",
      fontSize: "15px",
      fontFamily: `"Lato", sans-serif`,
    }
  },
  reviewListWrraper: {
    height: "400px",
    overflow: "auto",
    paddingRight: "15px",
    fontFamily: `"Lato", sans-serif`,
  },

  reviewListContainer: {
    display: "flex",
    flexDirection: "column" as const,
    margin: "25px 0"

  },
  userRatingHeading: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: "24px",
    fontWeight: 600,
    margin: "10px 0",
  },
  happinessContainer: {
    backgroundColor: 'rgb(242,243,244)',
    // padding: '20px'
  },
  happinessWrapper: {
    backgroundColor: '#fff',
    padding: '20px 25px'
  },
  nameDate: {
    fontFamily: `"Lato", sans-serif`,
    color: "#f9782e",
    fontSize: "10px"
  },
  ratingTitle: {
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 600
  },
  ratingDescription: {
    fontFamily: `"Lato", sans-serif`,
    color: "#797979",
  },
  ratingStar: {
    margin: "0 0 10px 0"
  },
  starIcon: {
    color: 'gold', // Set the color of the star icon
    fontSize: "20px",
    lineHeight: "0",
    fontFamily: `"Lato", sans-serif`,
  },
  filterlistWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: `"Lato", sans-serif`,
  },
  checkBoxFormControl: {
    fontSize: "14px",
    color: "#000",
    fontFamily: `"Lato", sans-serif`,
    '&MuiFormControlLabel-label': {
      color: "#000",
    },
  },
  checkBox: {
    color: '#fe600b',
    fontFamily: `"Lato", sans-serif`,
    '&$checked': {
      color: '#fe600b',
    },
    '&.Mui-checked': {
      color: '#fe600b',
    },
  },
  filterText: {
    color: "#33333a",
    fontSize: 12,
    fontWeight: 400,
    fontFamily: `"Lato-Heavy", sans-serif` as const,
    textTransform: "capitalize" as const,
    margin: "auto",
  },
  headerStyle: {
    backgroundColor: 'rgb(242,243,244)',
    padding:'12px'
  }
}

export { HappinessRating };

export default withStyles(styles)(HappinessRating)
// Customizable Area End