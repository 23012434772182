// Customizable Area Start
import React from "react";
import { Box, Container, Divider, Grid, Modal, Theme, Typography, withStyles } from "@material-ui/core";
import OrderOverviewController, {
  Props,
  configJSON,
} from "./OrderOverviewController.web";
import HeaderWeb from "../../../landingpage/src/Header.web";
import {
  background,
  brandName,
  cartBox,
  cartBoxDes,
  cartText,
  color,
  flex,
  imgWrapper,
  link,
  path,
  priceTextOne,
  priceWrapper,
  productDetailsWrapper,
  productName,
  quantity,
  quantityField,
  span,
  tableHeaderText,
  text,
  wrapper,
  zoom,
} from "./commomstyles";
import { CustomScrollbar } from "../../../dashboard/src/CustomizedElementStyle";
import { Link } from "react-router-dom";
import { ScrollView } from "react-native";
import Footer  from "../../../landingpage/src/Footer.web";
import Payment from "../../../stripepayments/src/Payment.web";
import { CustomBox } from "../../../otp-input-confirmation/src/CustomElementStyling";
import CloseIcon from '@material-ui/icons/Close';
import { errorImg } from "../../../bulkuploading/src/BulkUploadingController";
// Customizable Aea End

class OrderOverView extends OrderOverviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const addressData = this.state.setAddress && this.state.setAddress.attributes
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      <ScrollView>
        <HeaderWeb navigation={this.props.navigation} id={this.props.id} />
        <Container className={classes.mainWrapper}>
        <Box className={classes.main}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.linkSec}>
            <Link to={"/"} rel="noopener" className={classes.home}>
              <Typography className={classes.route}>
                {configJSON.home} 
                </Typography>
                </Link>
                <Typography className={classes.product}>/ {configJSON.myCartText}</Typography>
            
            </Grid>
          </Grid>
          <Box className={classes.cartWrapper}>
            <Grid container className={classes.place}>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={classes.headerText}>
                      {configJSON.myCartText}
                    </Typography>
                  </Grid>
                  <Divider
                    orientation="vertical"
                    className={classes.verticalLine}
                  />
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={classes.headerDesc}>
                      {""}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={0}
                  className={classes.productContainer}
                >
                  <Grid item xs={10} sm={10} md={10} lg={10}>
                    <Typography className={classes.headerLabel}>
                      {configJSON.productLabel}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Typography className={classes.headerLabel}>
                      {configJSON.priceLabel}
                    </Typography>
                  </Grid>
                </Grid>
                <CustomScrollbar className={classes.cartScroll}>
                {this.state.orderData.map((item: any) => {
                  const { product_image, product_title, brand } =
                  item.attributes.item.catalogue.data.attributes;
                  return (
                    <Grid
                      container
                      spacing={0}
                      className={classes.detailsWrapper}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={10}
                        lg={10}
                        className={classes.leftSide}
                      >
                        <Grid item xs={12} sm={12} md={1} lg={1} className={classes.imgSec}>
                          <img className={classes.itemImage} src={product_image} alt="prod_img" />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={11}
                          lg={11}
                          className={classes.productGrid}
                        >
                          <Typography className={classes.prodName}>
                            {product_title}
                          </Typography>
                          <Typography className={classes.prodColor}>
                            {""}
                          </Typography>
                          <Typography className={classes.brand}>
                            Brand: {brand.brand_name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        lg={2}
                        data-test-id="component"
                        className={classes.quantity}
                      >
                        <Box className={classes.priceContainer}>
                          <Typography className={classes.priceText}>
                            AED {item.attributes.price}
                          </Typography>
                          <Typography className={classes.prodColor}>
                          Off AED 0
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  );
                })}
                </CustomScrollbar>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.orderWrap}
              >
                <Payment navigation={this.props.navigation} id={this.props.id} priceDetails={this.state.priceInfo} />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.cartWrapper}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.headerText}>
                  {configJSON.address}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.headerDesc}>
                  {""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} className={classes.addressSec}>
                <Typography className={classes.addressDesc}>
                {addressData.address_1} {addressData.address_2}, {addressData.city}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.addressDesc}>
                  {addressData.phone_number}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.addressDesc}>
                  {addressData.state}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        </Container>
        {this.state.isPaymentFailed && (
            <Modal open={true}>
              <Box className={classes.modalContainer}>
                <Box className={classes.innerModalBox}>
                <Box className={classes.closeErrBox}>
                  <CloseIcon data-test-id="closePayFailIcon" onClick={this.handleClosePopup} className={classes.closeIcon}/>
                </Box>
                <CustomBox className={classes.payErrorBox}>
                    <img className={classes.payErrorImage} src={errorImg} alt="error image" />
                </CustomBox>
                <Box className={classes.paymentFailErr}>{this.state.paymentFailureError}</Box>
                </Box></Box>
            </Modal>
          )}
        <Footer navigation={this.props.navigation} id={this.props.id} categories={[]} token={""} />
        </ScrollView>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = (theme:Theme) => ({
  main: background,
  route: path,
  product: span,
  headerText: {...cartText,
    [theme.breakpoints.down("md")]: {
      fontSize: "28px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px"
    }
  },
  cartWrapper: {...cartBox,
    [theme.breakpoints.down("xs")]: {
      margin:'10px 20px'
    }
  },
  headerDesc: cartBoxDes,
  imgSec: imgWrapper,
  productContainer: {
    margin: "30px 10px 0px",
    [theme.breakpoints.down("xs")]: {
      display:'none'
    }
  },
  headerLabel: tableHeaderText,
  detailsWrapper: productDetailsWrapper,
  leftSide: flex,
  prodName: {...productName,
    [theme.breakpoints.down("md")]: {
      fontSize: "14px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px"
    }
  },
  prodColor: {...color,
    [theme.breakpoints.down("md")]: {
      fontSize: "12px"
    }
  },
  brand: {...brandName,
    [theme.breakpoints.down("md")]: {
      fontSize: "12px"
    }
  },
  quantitySec: quantity,
  quantity: {...quantityField,
    [theme.breakpoints.down("xs")]: {
      marginTop:'10px'
    }
  },
  priceContainer: priceWrapper,
  priceText: {...priceTextOne,
    [theme.breakpoints.down("md")]: {
      fontSize: "14px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px"
    }
  },
  verticalLine: {
    left: "76%",
    height: "70%",
    position: "absolute" as const,
    top: "10%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  addressDesc: {
    wordBreak : "break-all" as const,
    color: "#585858",
    fontFamily: `"Lato-Semibold", sans-serif`,
    fontSize: "16px",
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  mainWrapper: zoom,
  linkSec: wrapper,
  home: link,
  cartScroll: {
    width: "100%", 
    height: "250px", 
    overflowY: "auto" as const,
    overflowX: "hidden" as const,
    marginTop:'10px',
    [theme.breakpoints.down("xs")]: {
      overflowY: "hidden" as const,
      height:'auto'
    }
  },
  productGrid: {
    marginLeft: "25px",
    maxWidth: '65%'
  },
  orderWrap: {
    margin: "50px 0px 30px 0px",
    padding: "8px 32px 8px 70px",
    [theme.breakpoints.down("xs")]: {
      padding:'0',
      margin:'50px 20px'
    }
  },
  itemImage: {
    width:'100%', 
    height:'100%'
  },
  addressSec: {
    paddingTop:'16px'
  },
  place: {
    position:'relative' as const
  },
  closeErrBox: { 
    fontFamily: `"Lato", sans-serif`, 
    textAlign: "right" as const,
    margin:'-20px -20px 20px 0',
  },
  closeIcon: {
    cursor:'pointer', 
    padding: '4px',
    fontSize: '24px'
  },
  paymentFailErr: { 
    marginBottom: "21px", 
    textAlign: "center" as const, 
    fontFamily: `"Lato", sans-serif`,
    fontSize:'16px',
    fontWeight:600
  },
  modalContainer: { 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center",
    width: "100%", 
    height: "100vh"
  },
  innerModalBox: {
    display: "flex", 
    flexDirection: "column" as const, 
    justifyContent: "center", 
    width: "300px", 
    height: "auto",  
    borderRadius: "8px", 
    padding: "30px",
    background: "white" 
  },
  payErrorImage: {
    height: '30px', 
    width: '30px'
  },
  payErrorBox: {
    textAlign:'center' as const,
    marginBottom: '24px', 
  }
});

export { OrderOverView };
export default withStyles(styles)(OrderOverView);
// Customizable Area End
