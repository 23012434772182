import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("../src/config.js")
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes : {
    storageWrapper: string,
    tabsLabel: string,
    storageHeader: string,
    bckGround: string,
    spaceWrapper: string,
    totalStorageText: string,
    desc: string,
    storageBox: string,
    box: string;
    infoText: string;
  }
  handlePrintLabel: (value: boolean) => void
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  authToken: string;
  tabValue: number;
  searchRequestId: string;
  selectedReq: any[];
  requestsList: any[];
  selectedRequests: any[]
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class FBBStockMgmtTabsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      authToken: "",
      tabValue: 0,
      searchRequestId: '',
      selectedReq: [],
      requestsList: [],
      selectedRequests: []
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  requestData = [
    {
      id:'1',
      attributes: {
        name: 'Product One',
        status: 'draft',
        details: 'Draft',
        date: '23/05/23 01:26PM GST'
      }
    },
    {
      id:'2',
      attributes: {
        name: 'Product One',
        status: 'requested',
        details: 'FD65456DFDF456F',
        date: '23/05/23 01:26PM GST'
      }
    }
  ]

  handleSelectAllRequests = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = this.state.requestsList.map((n: {id:number}) => n.id);
      this.setState({ selectedRequests: newSelected });

    } else {
      this.setState({ selectedRequests: [] });
    }
  };

  handleClickOrdersRow = (num: number) => {
    const selectedIndex = this.state.selectedRequests.indexOf(num);

    let newSelected: number[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selectedRequests, num);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selectedRequests.slice(1));
    } else if (selectedIndex === this.state.selectedRequests.length - 1) {
      newSelected = newSelected.concat(
        this.state.selectedRequests.slice(0, -1)
      );
    }
    this.setState({
      selectedRequests: newSelected,
    });
  };

  isSelectedRequests = (num: number) => {
    if(this.state.selectedRequests.indexOf(num) !== -1) return true;
    else return false
  }

  handleTabChange = (event:React.ChangeEvent<{}>, value:number) => {
    this.setState({tabValue: value})
  }

  handleSearchRequest = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({searchRequestId : event.target?.value})
  }

  handlePrintBtn = () => {
    this.props.handlePrintLabel(true)
  }

  handleGetToken = async () => {
    const authToken = await getStorageData("authToken");
    this.setState({ authToken });
  };

  async componentDidMount(): Promise<void> {
    this.handleGetToken();
    this.setState({requestsList : this.requestData})
  }

  setBackground = (tabValue: number) => {
    return this.state.tabValue === tabValue ? "white" : "rgb(242,243,244)"
  }
  // Customizable Area End
}
