import React from "react";
import { Checkbox, CheckboxProps, createTheme, withStyles } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
export const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1460
    }
  },
  overrides: {
    MuiInput: {
      root: {
        fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`
      },
      underline: {
        "&:before": {
          borderBottomColor: "rgba(0, 0, 0, 0.1)"
        },
        "&:after": {
          borderBottomColor: "rgba(253, 97, 12, 1)"
        },
        "&:hover:not($disabled):before": {
          borderBottomColor: "rgba(253, 97, 12, 1)"
        }
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily: "Lato",
        "&$focused": {
          color: "rgba(253, 97, 12, 1)"
        },
        fontSize: 14,
        fontWeight: 600
      }
    },
    MuiContainer: {
      root: {
        fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`
      }
    },
    MuiButton: {
      root: {
        fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`
      }
    }
  }
});

const styles = {
  root: {
    fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    "&$checked": {
      color: "rgba(253, 97, 12, 1)"
    }
  },
  checked: {}
};

interface CustomCheckboxProps extends CheckboxProps {}

export const GreenCheckbox = withStyles(styles)((props: CustomCheckboxProps) => <Checkbox color="default" {...props} />);
const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiPaginationItem-root": {
      color: "#9B9B9B",
      fontSize: 18
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      backgroundColor: "#f78e24",
      color: "white",
      borderColor: "#f78e24"
    }
  }
}));
const CustomPagination = () => {
  const classes = useStyles();

  return <Pagination count={5} variant="outlined" classes={{ root: classes.root }} />;
};
export default CustomPagination;
