import React from "react";

// Customizable Area Start
import { Box, Button, Grid, InputAdornment, Select, Tooltip, TextField, FormControl, Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { CustomExpandMoreIcon, CustomMenuItem, FormControlTwo, OrangeButton } from "../CustomizedElementStyle";
import { withStyles } from "@material-ui/styles";
import { ToastContainer } from "react-toastify";
import { Country } from "country-state-city";
// Customizable Area End

import ProductContentController, { ICustomField, Props, configJSON } from "./ProductContentController";
import { deleteIcon } from "../../../catalogue/src/assets";

export default class ProductProfile extends ProductContentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  imageURLInput = (index: number, label: string) => {
    return (
      <Grid key={index} container style={webStyle.imageField}>
        <Box style={webStyle.featureBox}>
          <CustomizeInputSearchBox
            label={label}
            autoComplete="off"
            data-test-id="imageRef"
            defaultValue=""
            id="outlined-start-adornment"
            onChange={this.handleImageUrlChangeInput.bind(this, index)}
            value={this.state.image_urls_attributes[index]?.url}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start" style={webStyle.rightMargin}></InputAdornment>
            }}
          />
          <img data-test-id="delImage" style={{ ...webStyle.delIcon, display: index === 0 ? "none" : "flex" }} src={deleteIcon} alt="deleteIcon" onClick={this.handleDeleteImage.bind(this, index, this.state.image_urls_attributes[index]?.id)} />
        </Box>
        {this.state.imageErrorObj[index] ? <Typography style={webStyle.errorMsg}>{this.state.imageErrorObj[index]}</Typography> : ""}
      </Grid>
    );
  };

  featureBulletInput = (index: number, label: string) => (
    <Grid key={index} container style={webStyle.fieldContainer}>
      <Box style={webStyle.featureBox}>
        <CustomizeInputSearchBox
          value={this.state.feature_bullets_attributes[index]?.value}
          label={label}
          id="outlined-start-adornment"
          autoComplete="off"
          key={index}
          data-test-id="featureBulletRef"
          onChange={this.handleFeatureBulletChange.bind(this, index, label)}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start" style={webStyle.rightMargin}></InputAdornment>
          }}
        />
        <img data-test-id="delFeature" style={{ ...webStyle.delIcon, display: index === 0 ? "none" : "flex" }} src={deleteIcon} alt="deleteIcon" onClick={this.handleDeleteFeatureBullet.bind(this, index, label, this.state.feature_bullets_attributes[index]?.id, this.state.feature_bullets_attributes[index]?.value)} />
      </Box>
      {this.state.featureErrorObj[index] ? <Typography style={webStyle.errorMsg}>{this.state.featureErrorObj[index]}</Typography> : ""}
    </Grid>
  );
  getLabel(index: number, name: string) {
    let label = `${name} ${index + 1} ${index === 0 ? "*" : ""}`;
    return label;
  }

  shippingDetailsFirst = () => {
    const { shipping_length, shipping_height } = this.state.shipping_detail_attributes;
    return (
      <>
        <Box style={webStyle.fieldContainer}>
          <Grid container style={webStyle.imageField}>
            <CustomizeInputSearchBox
              onChange={this.handleShippingLengthChangeInput}
              value={shipping_length}
              data-test-id="shippingLengthRef"
              label={configJSON.shippingLengthLabel}
              autoComplete="off"
              id="outlined-start-adornment"
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start" style={webStyle.rightMargin}></InputAdornment>
              }}
            />
            {this.state.lengthErr ? (
              <Typography data-test-id="lengthErr" style={webStyle.errorMsg}>
                {this.state.lengthErr}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid container style={webStyle.lengthUnit}>
            <CustomFormControl fullWidth={true}>
              <Box style={webStyle.lengthUnitBox}>{configJSON.shippingLengthUnitLabel}</Box>
              <Select
                IconComponent={CustomExpandMoreIcon}
                defaultValue={configJSON.selectUnitText}
                style={{...webStyle.selectFieldColor}}
                data-test-id="shippingLengthUnitRef"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={this.state.shipping_detail_attributes.shipping_length_unit}
                onChange={this.handleShippingLengthUnitChangeInput}
                renderValue={this.handleRenderValue}
                MenuProps={{
                  anchorOrigin: {
                    vertical: configJSON.bottomText,
                    horizontal: configJSON.leftText
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: webStyle.selectBox
                  },
                  transformOrigin: {
                    horizontal: configJSON.leftText,
                    vertical: configJSON.topText
                  }
                }}
              >   
                  <CustomMenuItem value={configJSON.selectUnitText} key="0" disabled>
                    {configJSON.selectUnitText}
                  </CustomMenuItem>
                  <CustomMenuItem key="1" value={configJSON.centimeterText}>
                    {configJSON.centimeterText}
                  </CustomMenuItem>
                  <CustomMenuItem key="2" value={configJSON.meterText}>
                    {configJSON.meterText}
                  </CustomMenuItem>
                  <CustomMenuItem key="3" value={configJSON.inchText}>
                    {configJSON.inchText}
                  </CustomMenuItem>
                  <CustomMenuItem key="4" 
                    value={configJSON.feetText}>
                    {" "}
                    {configJSON.feetText}
                  </CustomMenuItem>
              </Select>
            </CustomFormControl>
            {this.state.lengthUnitErr ? (
              <Typography data-test-id="lengthUnitErr" style={webStyle.errorMsg}>
                {this.state.lengthUnitErr}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid container style={webStyle.imageField}>
            <CustomizeInputSearchBox
              data-test-id="shippingHeightRef"
              label={configJSON.shippingHeightLabel}
              id="outlined-start-adornment"
              autoComplete="off"
              fullWidth
              onChange={this.handleShippingHeightChangeInput}
              value={shipping_height}
              InputProps={{
                startAdornment: <InputAdornment position="start" style={webStyle.rightMargin}></InputAdornment>
              }}
            />
            {this.state.heightErr ? (
              <Typography data-test-id="heightErr" style={webStyle.errorMsg}>
                {this.state.heightErr}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid container style={webStyle.lengthUnit}>
            <CustomFormControl fullWidth={true}>
              <Box sx={webStyle.lengthUnitBox}>{configJSON.shippingHeightUnitLabel}</Box>
              <Select
                data-test-id="shippingHeightUnitRef"
                id="demo-simple-select-outlined"
                style={webStyle.selectFieldColor}
                value={this.state.shipping_detail_attributes.shipping_height_unit}
                defaultValue=""
                labelId="demo-simple-select-outlined-label"
                onChange={this.handleShippingHeightUnitChangeInput}
                IconComponent={CustomExpandMoreIcon}
                renderValue={(selectes: unknown) => {
                  return (selectes as string)?.length === 0 ? configJSON.selectUnitText : selectes;
                }}
                MenuProps={{
                  transformOrigin: {
                    vertical: configJSON.topText,
                    horizontal: configJSON.leftText
                  },
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: configJSON.bottomText,
                    horizontal: configJSON.leftText
                  },
                  PaperProps: {
                    style: webStyle.selectBox
                  }
                }}
              > 
                  <CustomMenuItem value={configJSON.selectUnitText} disabled>
                    {configJSON.selectUnitText}
                  </CustomMenuItem>
                  <CustomMenuItem value={configJSON.centimeterText}>{configJSON.centimeterText}</CustomMenuItem>
                  <CustomMenuItem value={configJSON.meterText}>{configJSON.meterText}</CustomMenuItem>
                  <CustomMenuItem value={configJSON.inchText}>{configJSON.inchText}</CustomMenuItem>
                  <CustomMenuItem value={configJSON.feetText}>{configJSON.feetText}</CustomMenuItem>
              </Select>
            </CustomFormControl>
            {this.state.heightUnitErr ? (
              <Typography data-test-id="heightUnitErr" style={webStyle.errorMsg}>
                {this.state.heightUnitErr}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
        </Box>
      </>
    );
  };

  shippingDetailsSecond = () => {
    const { shipping_width, shipping_weight } = this.state.shipping_detail_attributes;
    return (
      <>
        <Box style={webStyle.fieldContainer}>
          <Grid container style={webStyle.imageField}>
            <CustomizeInputSearchBox
              data-test-id="shippingWidthRef"
              label={configJSON.shippingWidthLabel}
              id="outlined-start-adornment"
              autoComplete="off"
              fullWidth
              value={shipping_width}
              onChange={this.handleShippingWidthDepthChangeInput}
              InputProps={{
                startAdornment: <InputAdornment position="start" style={webStyle.rightMargin}></InputAdornment>
              }}
            />
            {this.state.widthErr ? (
              <Typography data-test-id="widthErr" style={webStyle.errorMsg}>
                {this.state.widthErr}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid container style={webStyle.lengthUnit}>
            <CustomFormControl fullWidth={true}>
              <Box sx={webStyle.lengthUnitBox}>{configJSON.shippingWidthUnitLabel}</Box>
              <Select
                data-test-id="shippingWidthUnitRef"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                style={webStyle.selectFieldColor}
                value={this.state.shipping_detail_attributes.shipping_width_unit}
                defaultValue=""
                IconComponent={CustomExpandMoreIcon}
                onChange={this.handleShippingWidthDepthUnitChangeInput}
                renderValue={(select1: unknown) => {
                  return (select1 as string)?.length === 0 ? configJSON.selectUnitText : select1;
                }}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: configJSON.bottomText,
                    horizontal: configJSON.leftText
                  },
                  transformOrigin: {
                    vertical: configJSON.topText,
                    horizontal: configJSON.leftText
                  },
                  PaperProps: {
                    style: webStyle.selectBox
                  }
                }}
              >   
                  <CustomMenuItem value={configJSON.selectUnitText} key="0" disabled>
                    {configJSON.selectUnitText}
                  </CustomMenuItem>
                  <CustomMenuItem value={configJSON.centimeterText} key="1">
                    {configJSON.centimeterText}
                  </CustomMenuItem>
                  <CustomMenuItem value={configJSON.meterText} key="2">
                    {configJSON.meterText}
                  </CustomMenuItem>
                  <CustomMenuItem value={configJSON.inchText} key="3">
                    {configJSON.inchText}
                  </CustomMenuItem>
                  <CustomMenuItem value={configJSON.feetText} key="4">
                    {configJSON.feetText}
                  </CustomMenuItem>
              </Select>
            </CustomFormControl>
            {this.state.widthUnitErr ? (
              <Typography data-test-id="widthUnitErr" style={webStyle.errorMsg}>
                {this.state.widthUnitErr}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid container style={webStyle.imageField}>
            <CustomizeInputSearchBox
              data-test-id="shippingWeightRef"
              label={configJSON.shippingWeightLabel}
              id="outlined-start-adornment"
              autoComplete="off"
              fullWidth
              value={shipping_weight}
              onChange={this.handleShippingWeightChangeInput}
              InputProps={{
                startAdornment: <InputAdornment position="start" style={webStyle.rightMargin}></InputAdornment>
              }}
            />
            {this.state.weightErr ? (
              <Typography data-test-id="weightErr" style={webStyle.errorMsg}>
                {this.state.weightErr}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid container style={webStyle.lengthUnit}>
            <CustomFormControl fullWidth={true}>
              <Box sx={webStyle.lengthUnitBox}>{configJSON.shippingWeightUnitLabel}</Box>
              <Select
                style={webStyle.selectFieldColor}
                data-test-id="shippingWeightUnitRef"
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                value={this.state.shipping_detail_attributes.shipping_weight_unit}
                IconComponent={CustomExpandMoreIcon}
                onChange={this.handleShippingWeightUnitChangeInput}
                defaultValue=""
                renderValue={(select2: unknown) => {
                  return (select2 as string)?.length === 0 ? configJSON.selectUnitText : select2;
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: configJSON.bottomText,
                    horizontal: configJSON.leftText
                  },
                  getContentAnchorEl: null,
                  transformOrigin: {
                    horizontal: configJSON.leftText,
                    vertical: configJSON.topText
                  },
                  PaperProps: {
                    style: webStyle.selectBox
                  }
                }}
              > 
                  <CustomMenuItem value={configJSON.selectUnitText} key="0" disabled>
                    {configJSON.selectUnitText}
                  </CustomMenuItem>
                  <CustomMenuItem value={configJSON.kilogramText} key="1">
                    {configJSON.kilogramText}
                  </CustomMenuItem>
                  <CustomMenuItem value={configJSON.gramText} key="2">
                    {configJSON.gramText}
                  </CustomMenuItem>
                  <CustomMenuItem value={configJSON.ounceText} key="3">
                    {configJSON.ounceText}
                  </CustomMenuItem>
                  <CustomMenuItem value={configJSON.poundText} key="4">
                    {configJSON.poundText}
                  </CustomMenuItem>
              </Select>
            </CustomFormControl>
            {this.state.weightUnitErr ? (
              <Typography data-test-id="weightUnitErr" style={webStyle.errorMsg}>
                {this.state.weightUnitErr}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
        </Box>
      </>
    );
  };

  sizeAndCapacity = () => {
    const { size, prod_model_name, prod_model_number, number_of_pieces, capacity } = this.state.size_and_capacity_attributes;
    return (
      <>
        <Box style={webStyle.fieldContainer}>
          <Grid container style={webStyle.imageField}>
            <CustomizeInputSearchBox
              data-test-id="sizeRef"
              label="Size"
              id="outlined-start-adornment"
              autoComplete="off"
              fullWidth
              value={size}
              onChange={event => this.handleSizeChangeInput(event.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start" style={webStyle.rightMargin}></InputAdornment>
              }}
            />
            {this.state.sizeErr ? (
              <Typography data-test-id="sizeErr" style={webStyle.errorMsg}>
                {this.state.sizeErr}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid container style={webStyle.lengthUnit}>
            <CustomFormControl fullWidth={true} data-test-id="CustomFormControlRef">
              <Box sx={webStyle.lengthUnitBox}>{configJSON.sizeUnitText}</Box>
              <Select
                defaultValue={configJSON.selectUnitText}
                labelId="demo-simple-select-outlined-label"
                onChange={event => this.handleSizeUnitChangeInput(event.target.value as string)}
                id="demo-simple-select-outlined"
                style={webStyle.selectFieldColor}
                value={this.state.size_and_capacity_attributes.size_unit}
                data-test-id="sizeUnitRef"
                IconComponent={CustomExpandMoreIcon}
                renderValue={(selected: unknown) => {
                  return (selected as string)?.length === 0 ? configJSON.selectUnitText : selected;
                }}
                MenuProps={{
                  anchorOrigin: {
                    horizontal: configJSON.leftText,
                    vertical: configJSON.bottomText
                  },
                  getContentAnchorEl: null,
                  transformOrigin: {
                    horizontal: configJSON.leftText,
                    vertical: configJSON.topText
                  },
                  PaperProps: {
                    style: webStyle.selectBox
                  }
                }}
              >   
                  <CustomMenuItem value={configJSON.selectUnitText} key="0" disabled>
                    {configJSON.selectUnitText}
                  </CustomMenuItem>
                  <CustomMenuItem value="Grams" key="11">
                    {configJSON.gramsText}
                  </CustomMenuItem>
                  <CustomMenuItem value="KiloGrams" key="22">
                    {configJSON.kilogramsText}
                  </CustomMenuItem>
                  <CustomMenuItem value={configJSON.centimeterText} key="33">
                    {configJSON.centimeterText}
                  </CustomMenuItem>
                  <CustomMenuItem value={configJSON.millimeterText} key="44">
                    {configJSON.millimeterText}
                  </CustomMenuItem>
              </Select>
            </CustomFormControl>
            {this.state.sizeUnitErr ? (
              <Typography data-test-id="sizeUnitErr" style={webStyle.errorMsg}>
                {this.state.sizeUnitErr}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid container style={webStyle.imageField}>
            <CustomizeInputSearchBox
              label="Capacity"
              id="outlined-start-adornment"
              data-test-id="capacityRef"
              autoComplete="off"
              value={capacity}
              onChange={event => {
                this.handleCapacityChangeInput(event.target.value);
              }}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start" style={webStyle.rightMargin}></InputAdornment>
              }}
            />
            {this.state.capacityErr ? (
              <Typography data-test-id="capacityErr" style={webStyle.errorMsg}>
                {this.state.capacityErr}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid container style={webStyle.lengthUnit}>
            <CustomFormControl fullWidth={true}>
              <Box sx={{ fontSize: 12, color: "#9a9a9a", fontWeight: 600, fontFamily: `"Lato", sans-serif`, paddingBottom: "8px" }}>Capacity Unit</Box>
              <Select
                value={this.state.size_and_capacity_attributes.capacity_unit}
                labelId="demo-simple-select-outlined-label"
                style={webStyle.selectFieldColor}
                data-test-id="capacityUnitRef"
                renderValue={(select: unknown) => {
                  return (select as string)?.length === 0 ? configJSON.selectUnitText : select;
                }}
                defaultValue={configJSON.selectUnitText}
                onChange={event => this.handleCapacityUnitChangeInput(event.target.value as string)}
                IconComponent={CustomExpandMoreIcon}
                id="demo-simple-select-outlined"
                MenuProps={{
                  transformOrigin: {
                    vertical: configJSON.topText,
                    horizontal: configJSON.leftText
                  },
                  anchorOrigin: {
                    vertical: configJSON.bottomText,
                    horizontal: configJSON.leftText
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: webStyle.selectBox
                  }
                }}
              >   
                  <CustomMenuItem value={configJSON.selectUnitText} key="0" disabled>
                    {configJSON.selectUnitText}
                  </CustomMenuItem>
                  <CustomMenuItem value={configJSON.litreText} key="2">
                    {configJSON.litreText}
                  </CustomMenuItem>
                  <CustomMenuItem value={configJSON.mililetreText} key="1">
                    {configJSON.mililetreText}
                  </CustomMenuItem>
              </Select>
            </CustomFormControl>
            {this.state.capacityUnitErr ? (
              <Typography data-test-id="capacityUnitErr" style={webStyle.errorMsg}>
                {this.state.capacityUnitErr}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
        </Box>
        <Box style={webStyle.fieldContainer}>
          <Grid container style={webStyle.imageField}>
            <CustomizeInputSearchBox
              style={{ color: "#575759" }}
              label={
                <>
                  HS Code{" "}
                  <Tooltip title="Tooltip Text" arrow placement="top-end">
                    <InfoOutlinedIcon color="action" style={{ paddingLeft: "5px", fontSize: "1.5rem", position: "relative", top: "5", color: "#656C71" }} />
                  </Tooltip>
                </>
              }
              data-test-id="hsCodeRef"
              value={this.state.size_and_capacity_attributes.hs_code}
              onChange={event => {
                this.handleHsCodeChangeInput(event.target.value);
              }}
              id="outlined-start-adornment"
              autoComplete="off"
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start" style={{ color: "#575759", marginRight: 20 }}></InputAdornment>
              }}
            />
            {this.state.hsCodeErr ? (
              <Typography data-test-id="hsCodeErr" style={webStyle.errorMsg}>
                {this.state.hsCodeErr}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid container style={webStyle.imageField}>
            <CustomizeInputSearchBox
              data-test-id="modelNameRef"
              onChange={event => {
                this.handleModelNameChangeInput(event.target.value);
              }}
              value={prod_model_name}
              label="Model Name"
              id="outlined-start-adornment"
              autoComplete="off"
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start" style={{ marginRight: 20, color: "#575759" }}></InputAdornment>
              }}
            />
            {this.state.modelNameErr ? (
              <Typography data-test-id="modelNameErr" style={webStyle.errorMsg}>
                {this.state.modelNameErr}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid container style={webStyle.imageField}>
            <CustomizeInputSearchBox
              label="Model Number"
              data-test-id="modelNumberRef"
              id="outlined-start-adornment"
              autoComplete="off"
              fullWidth
              value={prod_model_number}
              onChange={event => {
                this.handleModelNumberChangeInput(event.target.value);
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start" style={webStyle.rightMargin}></InputAdornment>
              }}
            />
            {this.state.modelNumErr ? (
              <Typography data-test-id="modelNumErr" style={webStyle.errorMsg}>
                {this.state.modelNumErr}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid container style={webStyle.imageField}>
            <CustomizeInputSearchBox
              label="Number of Pieces"
              id="outlined-start-adornment"
              autoComplete="off"
              data-test-id="piecesRef"
              fullWidth
              onChange={event => {
                this.handleNumberOfPiecesChangeInput(event.target.value);
              }}
              value={number_of_pieces}
              InputProps={{
                startAdornment: <InputAdornment position="start" style={webStyle.rightMargin}></InputAdornment>
              }}
            />
            {this.state.piecesErr ? (
              <Typography data-test-id="piecesErr" style={webStyle.errorMsg}>
                {this.state.piecesErr}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
        </Box>
      </>
    );
  };

  getCustomField = () => {
    return (
      <>
        <Grid container spacing={2}>
          {this.state.customFieldList.map((item: ICustomField, index) => {
            return (
              <Grid item lg={6} sm={12} xs={12} key={index} container style={{ marginTop: "30px" }}>
                <Box sx={webStyle.customField}>
                  <Box style={{ marginBottom: "5px" }}>
                    {item?.attributes?.custom_field_detail.data.attributes.field_name}
                    {item?.attributes?.custom_field_detail?.data?.attributes?.mandatory ? " *" : ""}
                  </Box>
                  <FormControlTwo fullWidth={true}>
                  <Select
                IconComponent={CustomExpandMoreIcon}
                style={webStyle.selectFieldColor}
                data-test-id="customChange"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={String(this.state.catalogue_content_attributes?.[index]?.value)}
                onChange={event => this.handleCustomFieldChange(index, item, event.target.value as string)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: configJSON.bottomText,
                    horizontal: configJSON.leftText
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: webStyle.selectBox
                  },
                  transformOrigin: {
                    horizontal: configJSON.leftText,
                    vertical: configJSON.topText
                  }
                }}
              >
                {item.attributes.custom_field_detail.data.attributes.custom_fields_options.map((value:string, index: number) => {
                  return(
                    <CustomMenuItem key={index} value={value}>
                      {value}
                    </CustomMenuItem>
                  )
                })}
                
              </Select>
                  </FormControlTwo>
                  {this.state.catalogue_content_error_list[index] ? <Typography style={webStyle.errorMsg}>{this.state.catalogue_content_error_list[index].error}</Typography> : ""}
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    const countries = Country.getAllCountries();
    return (
      <>
        <ToastContainer />
        <Box sx={{ marginTop: 50, padding: "30px", display: "flex", flexDirection: "column" }}>
          <Box style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
            <Grid container style={{ color: "#878789", marginRight: "20px" }}>
              <CustomizeInputSearchBox1
                label={
                  <>
                    {configJSON.GtinLabel}{" "}
                    <Tooltip title="Tooltip Text" arrow placement="top-end">
                      <InfoOutlinedIcon color="action" style={webStyle.toolTip} />
                    </Tooltip>
                  </>
                }
                value={this.state.gtin}
                data-test-id="GtinRef"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleGtinChangeInput(event.target.value)}
                id="outlined-start-adornment"
                fullWidth
                autoComplete="off"
                InputLabelProps={{
                  shrink: true,
                  style: {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "200px",
                    display: "inline-block"
                  },
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" style={webStyle.rightMargin}></InputAdornment>
                }}
              />
              {this.state.gtinErr ? (
                <Typography data-test-id="gtinErr" style={webStyle.errorMsg}>
                  {this.state.gtinErr}
                </Typography>
              ) : (
                ""
              )}
            </Grid>
            <Grid container style={{ marginRight: "20px" }}>
              <CustomizeInputSearchBox1
                label={
                  <>
                    {configJSON.uniquePskuText} *{" "}
                    <Tooltip title={configJSON.uniquePskuToolTip} arrow placement="top-end">
                      <InfoOutlinedIcon style={webStyle.pskuStyle} color="action" />
                    </Tooltip>
                  </>
                }
                value={this.state.unique_psku}
                id="outlined-start-adornment"
                fullWidth
                autoComplete="off"
                data-test-id="pskuRef"
                disabled
                InputLabelProps={{
                  shrink: true,
                  style: {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "200px",
                    display: "inline-block"
                  },
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" style={webStyle.rightMargin}></InputAdornment>
                }}
              />
            </Grid>
          </Box>

          <Box style={webStyle.fieldContainer}>
          {this.state.isVariantProduct &&<Grid container style={webStyle.imageField}>
              <CustomizeInputSearchBox
                id="outlined-start-adornment"
                value={this.state.unique_sku}
                autoComplete="off"
                fullWidth
                disabled
                data-test-id="uniqueskuText"
                label={configJSON.uniqueskuText}
                InputProps={{
                  startAdornment: <InputAdornment position="start" style={webStyle.rightMargin}></InputAdornment>
                }}
              />
            </Grid>}
            <Grid container style={webStyle.imageField}>
              <CustomizeInputSearchBox
                id="outlined-start-adornment"
                value={this.state.brand_name}
                autoComplete="off"
                fullWidth
                data-test-id="brandRef"
                label={configJSON.brandLabel}
                InputProps={{
                  startAdornment: <InputAdornment position="start" style={webStyle.rightMargin}></InputAdornment>
                }}
              />
            </Grid>
            <Grid container style={webStyle.imageField}>
              <CustomizeInputSearchBox
                label={configJSON.productTitleLabel}
                id="outlined-start-adornment"
                data-test-id="titleRef"
                autoComplete="off"
                value={this.state.product_title}
                onChange={event => this.handleProductTitleChangeInput(event.target.value)}
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start" style={webStyle.rightMargin}></InputAdornment>
                }}
              />
              {this.state.productTitleErr ? (
                <Typography data-testid="titleErr" style={webStyle.errorMsg}>
                  {this.state.productTitleErr}
                </Typography>
              ) : (
                ""
              )}
            </Grid>
            <Grid container style={webStyle.lengthUnit}>
            <CustomFormControl fullWidth={true} data-test-id="CustomFormControlRef">
              <Box sx={webStyle.lengthUnitBox}>{configJSON.productColorText}</Box>
              <Select
                defaultValue={configJSON.selectProductColorText}
                labelId="demo-simple-select-outlined-label-color"
                onChange={event => this.handleProductColorChangeInput(event.target.value as string)}
                id="demo-simple-select-outlined-color"
                style={{...webStyle.selectFieldColor, color:'#000 !important'}}
                value={this.state.product_color}
                data-test-id="productColorRef"
                IconComponent={CustomExpandMoreIcon}
                renderValue={(selected: unknown) => {
                  return (selected as string)?.length === 0 ? configJSON.selectProductColorText : selected;
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: configJSON.bottomText,
                    horizontal: configJSON.leftText,
                  },
                  PaperProps: {
                    style: webStyle.selectBox
                  },
                  transformOrigin: {
                    vertical: configJSON.topText,
                    horizontal: configJSON.leftText,
                  },
                  getContentAnchorEl: null,
                }}
              >
                {configJSON.ProductColorOptions.map((color: {[colorKey:string]: string}, coIndex: number)=>{
                    return <CustomMenuItem value={color.title} key={`${5*coIndex}-color`}>
                    <span style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "5px",
                      backgroundColor: color.value,
                      marginRight: "10px"
                    }}></span>
                    {color.title}
                  </CustomMenuItem>
                })}
              </Select>
            </CustomFormControl>
            {this.state.productColorErr ? (
              <Typography data-test-id="productColorErr" style={webStyle.errorMsg}>
                {this.state.productColorErr}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          </Box>
          <Box style={webStyle.fieldContainer}>
            <Grid container style={webStyle.imageField}>
              <CustomizeInputSearchBox
                data-test-id="mrpRef"
                label={configJSON.mrpLabel}
                value={this.state.mrp}
                id="outlined-start-adornment"
                autoComplete="off"
                fullWidth
                onChange={event => this.handlemrpChangeInput(event.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start" style={webStyle.rightMargin}></InputAdornment>
                }}
              />
              {this.state.mrpErr ? (
                <Typography data-test-id="mrpErr" style={webStyle.errorMsg}>
                  {this.state.mrpErr}
                </Typography>
              ) : (
                ""
              )}
            </Grid>
            <Grid container style={webStyle.imageField}>
              <CustomizeInputSearchBox
                label={configJSON.retailPriceLabel}
                id="outlined-start-adornment"
                autoComplete="off"
                value={this.state.retail_price}
                onChange={event => this.handleRetailPriceChangeInput(event.target.value)}
                fullWidth
                data-test-id="retailRef"
                InputProps={{
                  startAdornment: <InputAdornment position="start" style={webStyle.rightMargin}></InputAdornment>
                }}
              />
              {this.state.retailPriceErr ? (
                <Typography data-test-id="retailErr" style={webStyle.errorMsg}>
                  {this.state.retailPriceErr}
                </Typography>
              ) : (
                ""
              )}
            </Grid>
          </Box>
          <Box style={webStyle.desc}>
            <CustomizeInputSearchBox
              label={configJSON.longDescLabel}
              data-test-id="longRef"
              id="outlined-start-adornment"
              value={this.state.long_description}
              autoComplete="off"
              onChange={event => this.handleLongDescriptionChangeInput(event.target.value)}
              fullWidth
              inputProps={{ maxLength: 2000 }}
              InputProps={{
                multiline: true,
                rows: 3,
                startAdornment: <InputAdornment position="start" style={webStyle.rightMargin}></InputAdornment>
              }}
            />
            {this.state.desErr ? (
              <Typography data-test-id="longDesc" style={webStyle.errorMsg}>
                {this.state.desErr}
              </Typography>
            ) : (
              ""
            )}
          </Box>
          <Box style={webStyle.fieldContainer}>
            <Grid container style={webStyle.imageField}>
              <CustomizeInputSearchBox
                onChange={event => this.handlewhatsInThePackageChangeInput(event.target.value)}
                data-test-id="packageRef"
                label={configJSON.whatsInPackageLabel}
                value={this.state.whats_in_the_package}
                id="outlined-start-adornment"
                fullWidth
                autoComplete="off"
                InputProps={{
                  startAdornment: <InputAdornment position="start" style={webStyle.rightMargin}></InputAdornment>
                }}
              />
              {this.state.packageErr ? (
                <Typography data-test-id="packageErr" style={webStyle.errorMsg}>
                  {this.state.packageErr}
                </Typography>
              ) : (
                ""
              )}
            </Grid>
            <Grid container style={{ ...webStyle.imageField, marginTop: "6px" }}>
              <CustomFormControl fullWidth={true}>
                <Box style={webStyle.lengthUnitBox}>{configJSON.countryOfOriginLabel}</Box>
                <Select
                  IconComponent={CustomExpandMoreIcon}
                  defaultValue={configJSON.selectUnitText}
                  style={{ color: "rgba(0, 0, 0, 0.87)" }}
                  data-test-id="customFieldRef"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.country_of_origin}
                  onChange={(event: React.ChangeEvent<{ name?: string; value: unknown}>) => this.handleCountryOriginChangeInput(event.target.value as string)}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: configJSON.bottomText,
                      horizontal: configJSON.leftText
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: webStyle.selectBox
                    },
                    transformOrigin: {
                      horizontal: configJSON.leftText,
                      vertical: configJSON.topText
                    }
                  }}
                >
                  {countries.map((item: {name: string}, index: number) => {
                    return (
                      <CustomMenuItem key={index} value={item.name}>
                        {item.name}
                      </CustomMenuItem>
                    );
                  })}
                </Select>
                {this.state.countryErr ? (
                  <Typography data-test-id="countryErr" style={webStyle.errorMsg}>
                    {this.state.countryErr}
                  </Typography>
                ) : (
                  ""
                )}
              </CustomFormControl>
            </Grid>
          </Box>
          <Box style={webStyle.feature}>
            {this.state.elementsArray.map((item, index) => {
              let label = this.getLabel(index, "Feature Bullet ");
              return (
                <Box key={index} style={webStyle.featureWrap}>
                  {this.featureBulletInput(index, label)}
                </Box>
              );
            })}
            <Button style={webStyle.moreButton} onClick={() => this.handleAddFeature()} data-test-id="btnAddFeatureRef">
              <p style={webStyle.addMoreStyle}>{configJSON.addMoreFeatureBullets}</p>
            </Button>
          </Box>
          <hr style={webStyle.horizontalLine} />
          {this.sizeAndCapacity()}
          <hr style={webStyle.horizontalLine} />
          <Box style={webStyle.feature}>
            {this.state.imageArray.map((item, index) => {
              let label = this.getLabel(index, "`Image URL ");
              return (
                <Box data-test-id="imageList" key={index} style={{ display: "flex", flexWrap: "nowrap", color: "black", flexDirection: "row", marginTop: "30px", width: "100%" }}>
                  {this.imageURLInput(index, label)}
                </Box>
              );
            })}
            <Button style={webStyle.moreButton} data-test-id="addImageRef" onClick={() => this.handleAddImage()}>
              <p style={webStyle.addMoreStyle}>{configJSON.addMoreImageUrls}</p>
            </Button>
          </Box>
          <hr style={webStyle.horizontalLine} />
          {this.shippingDetailsFirst()}
          {this.shippingDetailsSecond()}

          <hr style={webStyle.horizontalLine} />
          {this.getCustomField()}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", marginTop: "150px" }}>
          <OrangeButton
            data-test-id="nextRef"
            onClick={this.handleNext}
            style={{
              fontFamily: `"Lato",sans-serif`,
              position: "absolute",
              right: 30,
              bottom: 30
            }}
          >
            Next
          </OrangeButton>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  item: {
    padding: "20px"
  },
  errorMsg: {
    color: "#D74D4D",
    fontFamily: '"Lato", sans-serif',
    fontSize: 12,
    fontWeight: 400 as const,
    textTransform: "none" as const
  },
  moreButton: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "58px"
  },
  delIcon: {
    width: "24px",
    height: "24px",
    marginLeft: "20px",
    cursor: "pointer"
  },
  featureBox: {
    display: "flex",
    width: "100%",
    alignItems: "center"
  },
  imageField: {
    marginRight: "20px",
    marginTop: "30px"
  },
  rightMargin: {
    marginRight: 20
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "row" as const,
    flexWrap: "wrap" as const,
    marginTop: "30px"
  },
  lengthUnit: {
    marginRight: "20px",
    marginTop: "9px"
  },
  lengthUnitBox: {
    paddingBottom: "8px",
    color: "#9a9a9a",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 600,
    fontSize: 12
  },
  selectBox: {
    background: "#F6F6F6",
    border: "1px solid rgba(151, 151, 151, 0.147084)",
    marginTop: "12px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0468298)",
    overflowY: "auto" as const, // Add a scroll if the items overflow
    maxHeight: "400px" // Set the maximum height limit for the menu
  },
  selectFieldColor: {
    color: "#000"
  },
  customField: {
    fontWeight: 600,
    color: "#9a9a9a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: 12,
    paddingBottom: "8px",
    width: "98%",
    textTransform: "capitalize" as const
  },
  pskuStyle: {
    position: "relative" as const,
    top: "3",
    fontSize: "1.5rem",
    color: "#656C71",
  },
  toolTip: {
    fontSize: "1.5rem",
    paddingLeft: "5px",
    color: "#656C71",
    position: "relative" as const,
    top: "3"
  },
  desc: {
    marginTop: "50px",
    marginRight: "20px"
  },
  feature: {
    display: "flex",
    flexWrap: "wrap" as const,
    marginTop: "30px"
  },
  featureWrap: {
    marginTop: "30px",
    width: "100%",
    display: "flex",
    flexDirection: "row" as const,
    flexWrap: "nowrap" as const
  },
  addMoreStyle: {
    color: "#FF6008",
    fontFamily: `"Lato",sans-serif`,
    textTransform: "none" as const
  },
  horizontalLine: {
    border: "1px solid #e8eef4",
    marginTop: "30px"
  }
};
const CustomizeInputSearchBox1 = withStyles(() => ({
  root: {
    "& .MuiInputBase-input": {
      fontFamily: '"Lato", sans-serif',
      fontSize: 14,
      fontWeight: 500
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#f44336"
    },
    "& .MuiInputBase-root.Mui-error": {
      border: "1px solid #f44336"
    },
    "& .MuiInputBase-root": {
      border: "1px solid #DDE3E8"
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline
    },
    "& .MuiInputLabel-root": {
      top: -30,
      left: 0,
      fontFamily: `"Lato", sans-serif`,
      color: "#908F92",
      fontSize: 14,
      fontWeight: 600
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline on hover
    },
    "& .MuiInput-formControl": {
      margin: 0,
      borderRadius: "5px",
      padding: "7px 0px"
    }
  }
}))(TextField);
const CustomizeInputSearchBox = withStyles(() => ({
  root: {
    "& .MuiInputBase-input": {
      fontFamily: '"Lato", sans-serif',
      fontWeight: 500,
      fontSize: 14
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#f44336"
    },
    "& .MuiInputBase-root.Mui-error": {
      border: "1px solid #f44336"
    },
    "& .MuiInputBase-root": {
      border: "1px solid #DDE3E8"
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline
    },
    "& .MuiInputLabel-root": {
      top: -25,
      left: 0,
      fontFamily: `"Lato", sans-serif`,
      fontWeight: 600,
      fontSize: 14,
      color: "#908F92"
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline on hover
    },
    "& .MuiInput-formControl": {
      padding: "7px 0px",
      margin: 0,
      borderRadius: "5px"
    }
  }
}))(TextField);
const CustomFormControl = withStyles(() => ({
  root: {
    "& .MuiInput-formControl": {
      margin: 0,
      border: "1px solid #D8D8D8",
      borderRadius: "5px",
      padding: "5px 0px 5px 0px"
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline on hover
    },
    "& .MuiSelect-select:focus": {
      background: "none"
    },
    "& .MuiSelect-select.MuiSelect-select": {
      fontWeight: 500,
      fontSize: 14,
      paddingLeft: 15,
      fontFamily: `"Lato", sans-serif`
    }
  }
}))(FormControl);
// Customizable Area End
