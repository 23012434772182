// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import SellerStoreCategoriesController, {
  ICategoryList,
  IDiscountList,
  Props,
  configJSON,
} from "./SellerStoreCategoriesController.web";
import { Rating } from "@material-ui/lab";
import {
  categoryWrapper,
  checkbox,
  checkboxFormControl,
} from "./ShoppingCart/commomstyles";
import FooterWeb from "../../landingpage/src/Footer.web";
import ProductCardWeb from "../../../components/src/ProductCard.web";
import CustomSlider  from "../../../components/src/CustomSlider.web";
import  CustomSliderTwo  from "../../../components/src/CustomSliderTwo.web";

interface ILatestArrivals {
    id: string,
    attributes: {
        productName: string, 
        img: string, 
        price: string, 
        offprice: string, 
        discount: string, 
        rating: string, 
        number: string
    }
}

// Customizable Area End

class SellerStoreCategories extends SellerStoreCategoriesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box className={classes.main} data-test-id="wrapper">
          <Box className={classes.sideBar}>
            <Typography className={classes.sidebarTitle}>
              {configJSON.categoryText}
            </Typography>
            <FormControl
              component="fieldset"
              className={classes.categoryMargin}
            >
              <FormGroup>
                {this.state.categoryList &&
                  this.state.categoryList.map((item: ICategoryList, index: number) => {
                    return (
                      <Box
                        className={classes.wrapper}
                        key={`filter-comp${index}`}
                      >
                        <FormControlLabel
                          data-test-id="category"
                          control={
                            <Checkbox
                              value={item.id}
                              data-test-id="selectCategory"
                              checked={this.state.categoryIds.includes(item.id)}
                              onChange={() => this.handleCheckBox(item.id)}
                              className={classes.checkboxOne}
                              name={item.attributes.category}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              className={classes.discountOne}
                            >
                              {item.attributes.category}
                            </Typography>
                          }
                          className={classes.checkboxFormControlOne}
                        />
                      </Box>
                    );
                  })}
              </FormGroup>
            </FormControl>
            <Divider />
            <Typography className={classes.sidebarTitle}>
              {configJSON.brandText}
            </Typography>
            <Divider />
            <Typography className={classes.sidebarTitle}>
              {configJSON.priceLabel}
            </Typography>
            <Divider />
            <Typography className={classes.sidebarTitle}>
              {configJSON.discountText}
            </Typography>
            <FormControl
              component="fieldset"
              className={classes.categoryMargin}
            >
              <FormGroup>
                {this.state.discountList &&
                  this.state.discountList.map((item: IDiscountList, index: number) => {
                    return (
                      <Box
                        className={classes.wrapper}
                        key={`filter-comp${index}`}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={item.id}
                              data-test-id="selectDiscount"
                              checked={this.state.discountIds.includes(item.id)}
                              onChange={() => this.handleDiscountCheckBox(item.id)}
                              className={classes.checkboxOne}
                              name={item.attributes.discount}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              className={classes.discount}
                            >
                              {item.attributes.discount}
                            </Typography>
                          }
                          className={classes.checkboxFormControlOne}
                        />
                      </Box>
                    );
                  })}
              </FormGroup>
            </FormControl>
            <Divider />
            <Typography className={classes.sidebarTitle}>
              {configJSON.ratingText}
            </Typography>
            <FormControl component="fieldset">
              <FormGroup>
                {[...Array(5).keys()]
                  .map((i) => i + 1)
                  .map((item: any, index: number) => {
                    return (
                      <Box
                        className={classes.wrapper}
                        key={`${index}-form-group`}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              className={classes.checkboxOne}
                              defaultChecked={item.checked}
                              name={item.title}
                            />
                          }
                          label={
                            <Rating
                              name="customized-color"
                              precision={0.5}
                              value={5 - index}
                              readOnly
                            />
                          }
                          className={classes.checkboxFormControlOne}
                        />
                      </Box>
                    );
                  })}
              </FormGroup>
            </FormControl>
            <Typography className={classes.sidebarTitle}>
              {configJSON.colorText}
            </Typography>
            <Grid container className={classes.colorsContainer}>
              {this.colors.map((item: string, index: number) => {
                return (
                  <Grid
                    item
                    xs={2}
                    className={classes.colorWrapper}
                    key={`${index}-color-group`}
                  >
                    <Box
                      style={{
                        background: item,
                        height: "21px",
                        width: "21px",
                        borderRadius: "2px",
                      }}
                    ></Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Box className={classes.rightSide}>
            <Box className={classes.headingContainer}>
              <Typography className={classes.heading}>
                Latest Arrivals by ETrade Online
              </Typography>
              <Button className={classes.viewBtn}>{configJSON.viewAllBtn}</Button>
            </Box>
            <Grid container spacing={2} className={classes.paddingTop}>
              {this.latestArrivals.map((item:ILatestArrivals) => {
                const { productName, img, price, offprice, discount, rating, number } = item.attributes
                return(
              <Grid item xs={12} sm={6} md={6} lg={3} className={classes.cardPadding}>
                <ProductCardWeb 
                    prodName={productName}
                    productImg={img}
                    price={price}
                    offerPrice={offprice}
                    discount={discount}
                    rating={rating}
                    number={number}
                    isDiscount={false}
                />
              </Grid>
                )
              })}
            </Grid>
            <Box className={classes.headingContainer}>
              <Typography className={classes.heading}>
                Popular Products by ETrade Online
              </Typography>
              <Button className={classes.viewBtn}>{configJSON.viewAllBtn}</Button>
            </Box>
            <Grid container spacing={2} className={classes.paddingTop}>
              {this.popularProducts.map((item:ILatestArrivals) => {
                const { number, productName, discount,img, price, offprice, rating} = item.attributes
                return(
              <Grid item sm={6} xs={12} md={6} lg={3} className={classes.cardPadding}>
                <ProductCardWeb 
                    productImg={img}
                    prodName={productName}
                    price={price}
                    offerPrice={offprice}
                    discount={discount}
                    rating={rating}
                    number={number}
                    isDiscount={true}
                />
              </Grid>
                )
              })}
            </Grid>
            <Box className={classes.headingContainer}>
              <Typography className={classes.heading}>
                Top Discounted Products by ETrade Online
              </Typography>
              <Button className={classes.viewBtn}>{configJSON.viewAllBtn}</Button>
            </Box>
            <Grid container spacing={2} className={classes.discountedProducts}>
              {this.discountedProducts.map((item:ILatestArrivals) => {
                const { offprice, discount, rating, productName, img, price, number } = item.attributes
                return(
              <Grid item lg={3} xs={12} sm={6} md={6} className={classes.cardPadding}>
                <ProductCardWeb 
                    rating={rating}
                    number={number}
                    isDiscount={true}
                    prodName={productName}
                    productImg={img}
                    price={price}
                    offerPrice={offprice}
                    discount={discount}
                />
              </Grid>
                )
              })}
            </Grid>
          </Box>
        </Box>
        <Box className={classes.shopContainer}>
          <Typography className={classes.headingOne}>
            Shop by Categories from ETrade Online
          </Typography>
        <Box className={classes.sliderContainer}>
          <CustomSlider data={this.categories} handleCategoryClick={this.handleCategoryClick}  />
        </Box>
      </Box>
        <Box className={classes.boxStyle}>
        <Box className={classes.headingContainer}>
              <Typography className={classes.headingOne}>
                All Products from ETrade Online
              </Typography>
            </Box>
            <Grid container className={classes.discountedProducts}>
              {this.allProducts.map((item:ILatestArrivals) => {
                const { productName, img, price, offprice, discount, rating, number } = item.attributes
                return(
              <Box className={`${classes.cardPaddingOne} ${classes.products}`} >
                <ProductCardWeb 
                    prodName={productName}
                    productImg={img}
                    price={price}
                    offerPrice={offprice}
                    discount={discount}
                    rating={rating}
                    number={number}
                    isDiscount={true}
                />
              </Box>
                )
              })}
            </Grid>
          </Box>
          <Box className={classes.bottomSlider}>
              <Box className={classes.bookMargin}>
                <span className={classes.offText}>Upto 80% off</span>
                <span className={classes.bookDes}> | 20 Famous Authors' Favorite Books</span>
              </Box>
          <Box className={classes.sliderWrapper}>
          <CustomSliderTwo data={this.bookData} handleClick={this.handleBookClick}  />
              </Box>
              </Box>
        <FooterWeb navigation={this.props.navigation} id={this.props.id} categories={[]} token={this.state.token}/>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
  products: {
    width: '18%', 
    marginBottom: '16px' ,
    [theme.breakpoints.down("md")]: {
      width: '22%'
    },
    [theme.breakpoints.down("sm")]: {
      width: '44%'
    },
    [theme.breakpoints.down("xs")]: {
      width: '98%'
    }
  },
  main: {
    display: 'flex',
    [theme.breakpoints.down("xs")]: {
      display: 'block'
    }
  },
  sliderWrapper: { 
    height: "auto", 
    margin: "0px 30px", 
    display: "flex", 
    justifyContent: "center", 
    flexDirection: "column" as const
  },
  offText: {
    fontFamily: `"Lato-Heavy",sans-serif`, 
    color: "black", 
    fontSize: "32px", 
    fontWeight: 400 
  },
  bookDes: {
    fontFamily: `"Lato-Heavy",sans-serif`, 
    color: "#FF6008", 
    fontSize: "32px", 
    fontWeight: 400 
  },
  bottomSlider: {
    height: "auto", 
    borderRadius: "10px", 
    background: "linear-gradient( to right,#F8ECDA,#FCE4CA)", 
    marginBlock: "30px", 
    padding: "30px" ,
    margin: '10px 30px 0'
  },
  bookImage: {
    width: "150px",
    height: "auto",
    borderRadius: "8px"
  },
  bottomText: {
    textAlign: "center" as const,
    width: "150px",
    display: "block",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    padding: "10px 0px",
    color: "#33333a",
    fontFamily: `"Lato-Medium", sans-serif`,
    fontSize: "16px",
    fontWeight: 400
  },
  categoryImages: {
    width: '100%',
    height:'100%'
  },
  categoryBox:{ 
    padding: '20px',
    [theme.breakpoints.down("sm")]: {
      padding: '0'
    }
  },
  categoryName: {
    background: "#fff",
    textAlign: "center" as const,
    textTransform: "capitalize" as const,
    width: "180px",
    display: "block",
    paddingBottom: "20px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    color: "#33333a",
  },
  contentWrapper: {
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
    backgroundColor: "#fff",
    width: "140px",
    height: "160px",
    padding: "20px 20px"
  },
  sliderContainer: {
    height: "250px", margin: "20px 40px" 
  },
  shopText: {
    fontSize: "26px", fontWeight: 500 
  },
  categoryContainer: {
      marginBottom: "30px",
      display:'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
  },
  shopContainer: {
    margin: "30px",
    height: "auto"
  },
  boxStyle: {
    margin:'30px', 
    width: 'calc(100% - 60px)'
  },
  sideBar: {
    margin: "20px 0 20px 20px",
    displax: "flex",
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "16px",
    width: "263px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: '100%',
      margin: '20px'
    },
  },
  sidebarHeading: {
    color: "#33333a",
    fontFamily: `"Helvetica", sans-serif`,
    fontSize: "20px",
    fontWeight: 400,
    paddingBottom: "10px",
  },
  sidebarTitle: {
    color: "#33333a",
    fontFamily: `"Helvetica", sans-serif`,
    fontSize: "20px",
    fontWeight: 400,
    padding: "16px 0",
  },
  discount: {
    color: "#5a5a5a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "15px",
  },
  discountOne: {
    color: "#33333a" ,
    fontFamily: `"Lato", sans-serif`,
    fontSize: "15px",
  },
  wrapper: categoryWrapper,
  checkboxOne: checkbox,
  checkboxFormControlOne: checkboxFormControl,
  heading: {
    color: "#33333a",
    fontFamily: `"Lato-Heavy", sans-serif`,
    fontSize: "32px",
    fontWeight: 400 as const,
    marginLeft:'10px',
    [theme.breakpoints.down("md")]: {
      fontSize: "28px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px"
    }
  },
  rightSide: {
    padding: "40px 50px 8px 50px",
    width: '100%',
    position: 'relative' as const,
    [theme.breakpoints.down("xs")]: {
      padding: '20px',
      maxWidth: '100%',
      width:'auto'
    }
  },
  viewBtn: {
    border: "none",
    color: "#5a5a5a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "16px",
    fontWeight: 400 as const,
    textTransform: "capitalize" as const,
    textAlign: "end" as const,
    '&:hover': {
      backgroundColor: 'rgb(234,233,233)',
      boxShadow: 'none'
    }
  },
  headingContainer: {
    display:'flex',
    alignItems:'flex-start',
    justifyContent: 'space-between',
  },
  trendingProd: {
    color: "#33333a",
    fontFamily: `"Lato-Heavy", sans-serif`,
    fontSize: "32px",
    fontWeight: 400 as const,
    margin: "20px 0 30px 20px",
  },
  paddingTop: {
    paddingTop:'20px',
    paddingBottom: '50px',
  },
  cardPadding: {
    padding:'8px 14px 8px 14px !important'
  },
  discountedProducts: {
    paddingTop:'20px',
  },
  cardPaddingOne: {
    padding:'8px 14px 8px 14px !important'
  },
  headingOne: {
    color: "#33333a",
    fontFamily: `"Lato-Heavy", sans-serif`,
    fontSize: "32px",
    fontWeight: 400 as const,
    marginLeft: '14px',
    [theme.breakpoints.down("md")]: {
      fontSize: "28px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px"
    }
  },
  categoryMargin : { 
    margin: "-10px 0 16px" 
  },
  colorsContainer : {
    maxWidth: "190px"
  },
  colorWrapper : {
    margin: "2px 4px 2px 2px", padding: "8px 0" ,
    ...categoryWrapper
  },
  bookMargin: { 
    marginBottom: "25px" 
  }
});

export { SellerStoreCategories };
export default withStyles(styles)(SellerStoreCategories);
// Customizable Area End
