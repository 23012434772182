import { Box, Typography, Button, Theme, withStyles, Grid, } from "@material-ui/core";
import React from "react";
import UserOrderHistory2Controller, { OrderData, Props, configJSON } from "./UserOrderHistory2Controller";
import {styledProductReviews as AddProductReviews} from "../../reviews/src/AddProductReviews.web";
import moment from "moment";
import { Loader2 } from "../../../components/src/Loader.web";
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
 class UserOrderHistory2 extends UserOrderHistory2Controller {
    isValid: any;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderPagination = (paginationCurrentPage: number) => {
        return(
        this.state.historyList.length === 0  ?
        <Box style={{height:'300px', textAlign:'center',display:'flex'}}>
          <Typography variant="h6" style={webStyle.desc}>No Order History found</Typography>
        </Box>
        :

            <Box mt="16px" display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Pagination
                    variant="outlined"
                    shape="rounded"
                    onChange={this.handleOrderHistoryPageChange}
                    count={this.state.orderHistoryPagination.total_pages}
                    page={this.state.orderHistoryPagination.current_page}
                    classes={{ ul: this.props.classes.paginationUl }}
                    renderItem={(item) => {
                        if (item.type === 'previous') {
                            return <Button data-test-id="previous" className={this.props.classes.paginationButton} disabled={this.state.orderHistoryPagination.current_page === 1} onClick={() => this.handlePaginationButton(paginationCurrentPage - 1)}>Previous</Button>
                        }
                        if (item.type === 'next') {
                            return <Button data-test-id="next" className={this.props.classes.paginationButton} disabled={this.state.orderHistoryPagination.current_page === this.state.orderHistoryPagination.total_pages} onClick={() => this.handlePaginationButton(paginationCurrentPage + 1)}>Next</Button>
                        }
                        return <PaginationItem {...item} />
                    }}
                />
            </Box>
          
        ) 
    }

    renderReturnButton = (returnOrderStatus:string, orderStatus:string, itemId:string) => {
        return(
            !returnOrderStatus && orderStatus === "Delivered" &&
                <Button
                    data-test-id="returnItem"
                    style={{
                        textTransform: "none",
                        fontFamily: "Lato, sans-serif",
                        fontWeight: 500,
                        fontSize: 14,
                        background: "white",
                        color: "black",
                        alignItems: "center",
                        borderRadius: "8px",
                        padding: "8px 50px",
                        border: "1px solid #E9E9E9",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                    onClick={() => this.handleReturnItem(itemId)}>
                    Return Item
                </Button>
        )
    }

    setReturnOrderStatus = (status: string) => {
        const returnStatus = configJSON.returnStatusList
        const isReturnExists = returnStatus.includes(status)
        return(
           isReturnExists ? 
            <Typography data-test-id="returnStatus" style={{fontFamily: "Lato, sans-serif", color: "#FF6008", fontWeight: 600 }}>RETURN STATUS <span style={{ color: 'black', fontWeight: 400 }}>{status === "Return" ? "Processing" : status}</span></Typography>
            : ''
        )
    }
   
    personalDetails() {
        const paginationCurrentPage = this.state.orderHistoryPagination.current_page || 1
        return(
            <Box>
                <Box>
              <Typography variant="h4" style={webStyle.headerTxt}>Order History</Typography>

            </Box>
            {this.state.loading ?
            (<Box sx={webStyle.loaderWrapper}>
                <Loader2 loading={this.state.loading}/>
            </Box>): <>
            
            <Grid container style={{maxHeight:1086, overflowY:'scroll', marginTop:'20px'}}>
                <Grid item xs={12} sm={12} md={12} lg={11} >
            {  
          this.state.historyList && this.state.historyList?.map((item:OrderData, index:number) => {
            const orderStatus =item.order_details.order_status?.data?.attributes?.name 
            const returnOrderStatus =item.order_details.order_item_details.order_status?.data?.attributes?.name ;
            const productId = item.order_details.order_item_details?.catalogue?.data.id;
            const productDetails = item.order_details.order_item_details?.catalogue?.data.attributes;
            const {classes} = this.props
            return (
                  <Box key={index} className={classes.historyBox}>
                      <Box style={{ display: "flex", justifyContent: "space-between", gap: 15 }} className={this.props.classes.historyContainer}>
                          <Box style={{ display: "flex", gap: 20,fontFamily: "Lato, sans-serif"}} className="innerSection">
                              <Box data-testId="order_product_img" className={classes.imageBox}  onClick={() => this.handleQuickProductView(productId)}>
                                  <img src={productDetails.product_image} alt="img" style={{width:'inherit', height: "100%", borderRadius: "5px", margin: "auto",fontFamily: "Lato, sans-serif" }}></img>
                              </Box>
                              <Box>
                                  <Typography variant="h5" data-testId="order_product_name" className={this.props.classes.lineEcllipse}  onClick={() => this.handleQuickProductView(productId)}>{productDetails.product_title}</Typography>
                                  {orderStatus==='Delivered' && <Typography style={{fontFamily: "Lato, sans-serif", color: "#757575", fontWeight: 600, paddingTop: "10px" }}>Delivered to: <span style={{ color: "black", fontWeight: 400, wordBreak:'break-all' }}>{item.order_details?.shipping_address?.data?.attributes?.address_1 || ""}</span></Typography>}
                              </Box>
                          </Box>
                          <Box style={{minWidth:'20%'}}>
                              <Typography style={{fontFamily: "Lato, sans-serif", color: "#FF6008", paddingBottom: "20px", fontWeight: 600 }}>ORDER # <span style={{ color: 'black', fontWeight: 400 }}>{item.order_details?.order_number}</span></Typography>
                              <Typography style={{fontFamily: "Lato, sans-serif", color: "#FF6008", paddingBottom: "20px", fontWeight: 600 }}>ORDER PLACED <span style={{ color: 'black', fontWeight: 400 }}>{moment(item.order_details?.order_placed_at).format("D MMMM YYYY")}</span></Typography>
                              <Typography style={{fontFamily: "Lato, sans-serif", color: "#FF6008", paddingBottom: "20px", fontWeight: 600 }}>TOTAL <span style={{ color: 'black', fontWeight: 400 }}>AED {item.order_details?.order_item_details?.price}</span></Typography>
                              {!returnOrderStatus && orderStatus ==='Delivered' && item.order_details?.delivered_at && <Typography style={{fontFamily: "Lato, sans-serif", color: "#FF6008", fontWeight: 800 }}>DELIVERED <span style={{ color: 'black', fontWeight: 400 }}>{moment(item.order_details.delivered_at).format("D MMMM YYYY")}</span></Typography>}
                              {!returnOrderStatus && orderStatus && orderStatus!=='Delivered' && <Typography style={{fontFamily: "Lato, sans-serif", color: "#FF6008", fontWeight: 600 }}>ORDER STATUS <span style={{ color: 'black', fontWeight: 400 }}>{orderStatus}</span></Typography>}
                              {this.setReturnOrderStatus(returnOrderStatus)}
                          </Box>
                      </Box>
                      <Box style={{ fontFamily: "Lato, sans-serif", marginTop: "5%",  display: "flex", justifyContent: "space-between",gap:15 }} className={this.props.classes.historyContainer}>
                      <Box style={{ display: "flex", gap: 20,fontFamily: "Lato, sans-serif"}} className="innerSection">

                          <Button
                              data-test-id="saveRef"
                              style={{
                                  textTransform: "none",
                                  fontFamily: "Lato, sans-serif",
                                  fontWeight: 500,
                                  fontSize: 14,
                                  background: "white",
                                  color: "black",
                                  alignItems: "center",
                                  borderRadius: "8px",
                                  padding: "8px 50px",
                                  border: "1px solid #E9E9E9",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                              }}>
                              Track Order
                          </Button>
                          {this.renderReturnButton(returnOrderStatus, orderStatus,item.order_details.order_item_details.id as string)}
                          {orderStatus==='Delivered' && 
                         <>
                          <Button
                              id="reviews-page"
                              data-test-id="writeRef"
                              style={{
                                  textTransform: "none",
                                  fontFamily: "Lato, sans-serif",
                                  fontWeight: 500,
                                  fontSize: 14,
                                  background: "white",
                                  color: "black",
                                  alignItems: "center",
                                  borderRadius: "8px",
                                  padding: "8px 50px",
                                  border: "1px solid #E9E9E9",                            
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                          }}
                          onClick={() => this.handleWriteAReview(item.order_details?.order_item_details,item.order_details?.order_number, moment(item.order_details?.order_placed_at).format("D MMMM YYYY"),productDetails.product_title, productDetails.product_image, item.order_details?.shipping_address?.data?.attributes?.address_1)}>
                              Write a Review
                          </Button>
                          <Button
                              data-test-id="invoiceRef"
                              style={{
                                  textTransform: "none",
                                  fontFamily: "Lato, sans-serif",
                                  fontWeight: 500,
                                  fontSize: 14,
                                  background: "white",
                                  color: "black",
                                  alignItems: "center",
                                  borderRadius: "8px",
                                  padding: "8px 65px",
                                  border: "1px solid #E9E9E9",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                              }}
                              onClick={() => this.handleDownloadOrderInvoice(item.order_details.id,item.order_details.order_item_details.id as number)}
                              >
                              Invoice
                          </Button> 
                          </>}
                          </Box>
                          <Box style={{minWidth:'20%'}}></Box>
                      </Box>
                  </Box>
  
              )})}
              </Grid>
            </Grid>
            {this.renderPagination(paginationCurrentPage)} 
         </>}
         </Box>  
 )}

    // Customizable Area End

    // Customizable Area Start
    render() {
        return (     
                this.isReview() && this.state.showAddReviews ?
                <AddProductReviews data-test-id="review" navigation={this.props.navigation} id={"fgdfg"} showReview={this.handleShowReview}></AddProductReviews>
                : 
                    <>
                    {this.personalDetails()}</>  
           );
        // Customizable Area End
    }
}

// Customizable Area Start
// Customizable Area End

const webStyle = {

    horizontalBar: {
        width: "150px",
        height: "2px",
        marginBlock: "10px",
        fontFamily: "Lato, sans-serif"
    },
    mainWrapper: {

        background: '#eae9e9',
        padding: "20px",
        fontFamily: "Lato, sans-serif"
    },
    papperClasses: {
        background: '#fff',
        padding: "20px 15px",
        borderRadius: "15px",
        fontFamily: "Lato, sans-serif"
    },
    subHeaders: {
        color: "rgb(36, 35, 35)",
        fontWeight: 600,
        fontSize: "20px",
        fontFamily: "Lato, sans-serif"
    },
    desc: {
        color: "rgb(96, 96, 96)",
        marginBlock: "15px",
        fontWeight: 400,
        fontFamily: "Lato, sans-serif",
        margin:'auto'
    },
       headerTxt: {
        color: "rgb(36, 35, 35)",
        fontWeight: 600,
        fontSize: "2rem",
        fontFamily: "Lato, sans-serif"
    },
    loaderWrapper: {
        position: 'relative',
        width: '50px',
        left: '40%',
        top: '80%',
        marginTop: '60px'
    }
}

const styles = (theme: Theme) =>({
    paginationUl: {
        [theme.breakpoints.down('xs')] :{
            gap: '12px'
        },
        '& .MuiPaginationItem-root': {
          borderRadius: '4px',
          fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, Courier New,monospace',
          border: '1px solid #000',
          fontSize: 18,
          color: '#000',
          '&.Mui-selected': {
            backgroundColor: '#ff5722',
            color: '#fff',
            borderColor: '#ff5722',
          }
        },
        '& .Mui-disabled': {
            border: '1px solid #d9d9d9'
        }
      },
      paginationButton: {
        minWidth: '120px',
        height: '33px',
        margin: '0 14px',
        border: '1px solid gray',
        textTransform: 'none' as const,
        padding: 14,
        fontSize: 16
      },
    historyContainer: {
        flexWrap: 'nowrap',
        [theme.breakpoints.down("md")]: {
          flexWrap: 'wrap',
        },
        [theme.breakpoints.down("xs")]: {
          flexWrap: 'wrap',
        },
        [theme.breakpoints.down("sm")]: {
            flexWrap: 'wrap',
      },
      '& .innerSection':{
        maxWidth: '70%' ,
        [theme.breakpoints.down("md")]: {
            maxWidth: '65%',
            flexDirection: "column" as const
          },
        [theme.breakpoints.down("xs")]: {
            width : '100%',
            flexDirection: "column" as const
          }
      }
    } as any
    ,
    lineEcllipse:{
        maxHeight: 'calc(3 * 1.3em)',
        cursor: "pointer" as const,
        overflow: 'hidden' as const,
        textOverflow: 'ellipsis' as const,
        whiteSpace: 'normal' as const,
        fontFamily: "Lato, sans-serif", 
        fontWeight: 700,
        display: '-webkit-box' as const,
        WebkitLineClamp: 3 as const, 
        wordBreak: "break-all" as const,
        WebkitBoxOrient: 'vertical' as const,
    },
    historyBox: { 
        border: "2px solid rgb(234, 233, 233)", 
        borderRadius: "5px", 
        height: "unset", 
        marginBlock: "10px", 
        padding: "30px", 
        marginTop: "40px",
        fontFamily: "Lato, sans-serif" 
    },
    imageBox: { 
        display: "flex",
        cursor: "pointer", 
        background: "#E7E7E7", 
        width: "140px",
        borderRadius: "5px", 
        height: "140px", 
        padding: "10px",
        fontFamily: "Lato, sans-serif"
    }
});

export { UserOrderHistory2 };
export default withStyles(styles)(UserOrderHistory2) as any;