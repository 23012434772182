// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { getStorageData } from "../../../../framework/src/Utilities";
import { IProductData } from "./StoreMenuTabController.web";
export const configJSON = require("../../src/config.js");

interface IProductDetails {
  data: {
    id: string,
    attributes: {
      id: string,
      product_quantity: number | null,
      catalogues: {
        data: {
          id: string
        }
      }[]
    }
  }
}

interface IMenuResponse {
  data: {
    id: string
  }[]
}

export interface IProductResult {
  data: IProductData[]
}

interface IProductItem {
    attributes: 
        {
            category: {
                name:string
            },
            sub_category: {
                name: string
            }, 
            mini_category: {
                name: string
            }, 
            micro_category: {
                name: string
            }
        }
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    marginOne: string,
    productText: string,
    box: string,
    resultCard: string,
    radioBtn: string
    headingOne: string,
    headingTwo: string,
    selectBorder: string,
    searchWrap: string,
    errStyle: string,
    resultText: string,
    para: string,
    boxTwo: string
  };
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  quantityValue: string | number | null;
  productValue: string;
  openProductResult: boolean;
  productResults: IProductData[],
  productId: string,
  productList: IProductData[];
  selectProductId: string[];
  quantityErr: string,
  errorMessage: string,
  homeId: string,
  storeId: string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class StoreProductListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProductResApiCallId: string = "";
  getHomeDetailsApiCallId: string = "";
  addHomeProductsAPICallId: string = "";
  getHomeProductsApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.ReceiveMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
        quantityValue: null,
        productValue: "",
        openProductResult: false,
        productResults: [],
        productId: '',
        productList: [],
        selectProductId: [],
        quantityErr: '',
        errorMessage: '',
        homeId: '',
        storeId: ''
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area End

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.getProductResApiCallId) {
        this.handleProductResults(responseJson);
      }
      if(apiRequestCallId === this.getHomeDetailsApiCallId) {
        this.handleMenuRes(responseJson)
      }
      if(apiRequestCallId === this.addHomeProductsAPICallId) {
        this.handleAddProductsResponse(responseJson)
      }
      if(apiRequestCallId === this.getHomeProductsApiCallId) {
        this.setProductsResponse(responseJson)
      }
    }
  }

  setProductsResponse = (responseJson:IProductDetails) => {
    if(responseJson.data) {
      const quantity = responseJson.data.attributes.product_quantity
      const ids = responseJson.data.attributes.catalogues.map((item:{data: {id:string}}) => item.data.id)
      this.setState({selectProductId: ids, quantityValue: quantity, openProductResult: true})
      if(quantity === ids.length) {
        this.setState({errorMessage: ''})
      }
      else this.setState({errorMessage: configJSON.productListingErrMsg})
      this.SearchProdDebounceUpdate('')
    }
  }  

  handleAddProductsResponse = (responseJson:IProductDetails) => {
    if(responseJson.data) {
      this.getHomeMenuDetails(this.state.storeId)
    }
  }

  handleMenuRes = (responseJson: IMenuResponse) => {
    if(responseJson.data[0]) {
      this.setState({homeId : responseJson.data[0].id})
      this.getHomeProducts(responseJson.data[0].id)
    }
  }

  handleProductResults = (responseJson: IProductResult) => {
    if (responseJson.data) {
        this.setState({ productResults: responseJson.data});
     
    } else this.setState({ productResults: []});
  };

  handleClickAway = () => {
    this.setState({ openProductResult: true });
  };

  handleQuantity = (event: React.ChangeEvent<{ name?: string ; value: unknown | number }>) => {
    const quantity = event.target.value as string
    this.setState({ quantityValue: event.target.value as number });
    const quantityRegex = /^(?:[6-9]|1\d|2[0-5])$/;
    if(!quantityRegex.test(quantity)) {
      this.setState({quantityErr: configJSON.quantityErrorMsg})
    }
    else {
      if(this.state.quantityValue !== this.state.selectProductId.length) {
        this.setState({errorMessage: configJSON.productListingErrMsg})
      }
      this.setState({quantityErr: ""})
    }
  };

  setProductValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value) {
      this.setState({ productValue: event.target.value, openProductResult: true});
      this.SearchProdDebounceUpdate(event.target.value);
    } else this.setState({ productValue: "", openProductResult: false, productResults: [] });
  };


  getProductResults = async () => {
    const brandId = await getStorageData("brandId")
    const header = { "Content-Type": configJSON.contentType, token: await getStorageData("authToken") };
    const prodMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProductResApiCallId = prodMessage.messageId;
    prodMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.searchProductEndpoint + `${this.state.productValue}` + `&brand_id=${brandId}` + `&per_page=1000`);
    prodMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    prodMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(prodMessage.id, prodMessage);
  };

  SearchProdDebounce = (call: (...args: string[]) => void, delay: number | string | unknown) => {
    let timer: string | number ;
    return function(...args: string[]) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        call(...args);
      }, delay);
    };
  };

  SearchProdDebounceUpdate = this.SearchProdDebounce(this.getProductResults, 1000);

  getRelation = (item: IProductItem) => {
    return `${item.attributes.category.name} >> ${item.attributes.sub_category?.name} >> ${item.attributes.mini_category?.name} >> ${item.attributes.micro_category?.name}`
  }

  handleSelectProduct = (event: React.ChangeEvent<HTMLInputElement>, data: IProductData) => {
    if(this.state.quantityValue) {
    if (event.target.checked) {
      if((this.state.selectProductId.length+1) <= Number(this.state.quantityValue)) {
        this.setState({
          productList: [...this.state.productList, data],
          selectProductId: [...this.state.selectProductId, data.id],
          errorMessage: configJSON.productListingErrMsg
        }, () => 
        this.addProducts(this.state.storeId,this.state.homeId));
      }
      else this.setState({errorMessage: ''})
      
    } else {
      let copySelectProductData = [...this.state.productList];
      let findIndexData = copySelectProductData.findIndex((product:{id:string}) => product.id === data.id);
      copySelectProductData.splice(findIndexData, 1);
      let copySelectProductBrandId = [...this.state.selectProductId];
      let findIndexBrand = copySelectProductBrandId.findIndex(id => id === data.id);
      copySelectProductBrandId.splice(findIndexBrand, 1);
        this.setState({
          productList: [...copySelectProductData],
          selectProductId: [...copySelectProductBrandId],
          errorMessage: configJSON.productListingErrMsg
        }, () => 
        this.addProducts(this.state.storeId,this.state.homeId));
    }
  }
  else this.setState({errorMessage: configJSON.selectQuantityText})
  };

  async componentDidMount(): Promise<void> {
    const storeId = await getStorageData("store_id")
    this.setState({storeId: storeId})
    this.getHomeMenuDetails(storeId)
  }

  getHomeMenuDetails = async(id:string) => {
    const headers = { token: await getStorageData("authToken") };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getHomeDetailsApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + id + "/store_menus");
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(message.id, message);
  }

  
  addProducts = async(storeId:string, homeId:string) => {
    if(this.state.quantityValue !== this.state.selectProductId.length) {
      this.setState({errorMessage: configJSON.productListingErrMsg})
    }
    else this.setState({errorMessage: ''})
    const formData = new FormData()
    formData.append("product_quantity", this.state.quantityValue as string)
    if(this.state.selectProductId.length > 0) {
      this.state.selectProductId.forEach((value: string) => formData.append("catalogue_ids[]",value))
    }
    else formData.append("catalogue_ids[]","[]")
    const headers = { token: await getStorageData("authToken") };
    
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addHomeProductsAPICallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + storeId + `/store_menus/${homeId}`);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    message.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
    runEngine.sendMessage(message.id, message);
  }

  getHomeProducts = async (id: string) => {
    const headers = { token: await getStorageData("authToken") };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getHomeProductsApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + this.state.storeId + "/store_menus/" + id);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(message.id, message);
  }

  //Customizable Area End
}
