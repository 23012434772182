import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IProductData } from "../../dashboard/src/StoreManagement/StoreMenuTabController.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { IProductResult } from "../../dashboard/src/StoreManagement/StoreProductListController.web";
// Customizable Area Start
export const configJSON = require("../src/config.js")

interface IProductList {
  attributes: 
      {
          category: {
              name:string
          },
          sub_category: {
              name: string
          }, 
          mini_category: {
              name: string
          }, 
          micro_category: {
              name: string
          }
      }
}

export interface IWarehouseList {
  id: string,
  attributes: {
    id: string,
    warehouse_name: string
  }
}

export interface IWarehouse {
  data: IWarehouseList[]
}

export interface ICatalogList {
  data: {
    id: string,
    attributes: {
      id: string , 
      product_image: string,
      product_title: string,
      status: boolean,
      stocks: number | null,
      product_content: {
        product_attributes: {
          product_title: string,
          unique_psku: string
        }
      }
    }
  }
}

export interface IWarehouseProdData {
  id: string,
  attributes: {
    stocks: number,
    catalogue: ICatalogList
  }
}

interface WarehouseProductRes {
  data: IWarehouseProdData[],
  message: {
    success: boolean,
    error: string
  },
  total_count: number
}

interface RemoveProdRes {
  message : string
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    label: string,
    main : string,
    stockTextWrapper: string,
    productSearch : string,
    searchContainer: string,
    searchProductText: string,
    searchField: string,
    box : string,
    header: string,
    table: string,
    wrapper: string,
    borderBottom: string,
    cellData: string,
    image: string,
    bodyText: string,
    update: string,
    boxWrap: string,
    resultWrapper: string,
    radioStyle: string
    title: string,
    category: string,
    searchLine: string,
    results: string,
    desc: string,
    resultBox: string,
    textWrap: string,
    warehouse: string,
    marginOne: string,
    searchInput: string,
    bodyTextOne: string,
    pagination: string
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  searchProduct: string,
  quantity: number[] | any,
  stockData: IWarehouseProdData[],
  openResults: boolean,
  searchProdResults: IProductData[],
  selectProducts: string[],
  productInfo: IProductData[],
  selectedWarehouse: string,
  warehouseList: IWarehouseList[],
  prodError: string,
  pageNumber: number,
  totalCountValue: number,
  qtyError: string[],
  showChangeSuccessModal: boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class StockLogsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProductListApiCallId: string= "";
  getWarehouseListApiCallId: string = "";
  addProductApiCallId: string = "";
  removeProductApiCallId: string = "";
  listWarehouseProductsApiCallId: string = "";
  updateQuantityApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.ReceiveMessage), getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      searchProduct: '',
      quantity: [],
      stockData: [],
      openResults: false,
      searchProdResults: [],
      selectProducts: [],
      productInfo: [],
      selectedWarehouse:'',
      warehouseList: [],
      prodError: '',
      pageNumber: 1,
      totalCountValue :8,
      qtyError: [],
      showChangeSuccessModal: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.getProductListApiCallId) {
        this.handleResults(responseJson);
      }
      if (apiRequestCallId === this.getWarehouseListApiCallId) {
        this.handleWarehouseRes(responseJson);
      }
      if (apiRequestCallId === this.listWarehouseProductsApiCallId) {
        this.handleWarehouseProductData(responseJson)
      }
      if (apiRequestCallId === this.updateQuantityApiCallId) {
        this.handleUpdateQuantityRes(responseJson)
      }
      if (apiRequestCallId === this.removeProductApiCallId) {
        this.handleRemoveProductRes(responseJson)
      }
      if (apiRequestCallId === this.addProductApiCallId) {
        this.handleAddProductsRes(responseJson)
      }
    }
  }

  handleAddProductsRes = (responseJson: WarehouseProductRes) => {
    if(responseJson.data) {
      this.setState({prodError: ''})
      this.listWarehouseProducts()
    }
    if(responseJson.message.success === false) {
      if(responseJson.message.error) {
        const errorArray = responseJson.message.error.split(":")
        this.setState({prodError : errorArray[1], selectProducts: []})
      }
    }
  }

  handleRemoveProductRes = (responseJson: RemoveProdRes) => {
    if(responseJson.message ===	configJSON.warehouseProductDeletedText) {
      this.setState({openResults: true})
      this.listWarehouseProducts()
    }
  }

  handleUpdateQuantityRes = (responseJson:WarehouseProductRes) => {
    if(responseJson.data) {
      this.listWarehouseProducts()
    }
  }

  handleWarehouseProductData = (responseJson: WarehouseProductRes) => {
    if(responseJson.data) {
      const stocks: number[] = responseJson.data.map((item:IWarehouseProdData) => {
        return item.attributes.stocks
      })
      const selectedProducts = responseJson.data.map((item: IWarehouseProdData) => {
        return item.attributes.catalogue.data.id
      })
        this.setState({
          stockData: responseJson.data, 
          quantity: stocks, 
          selectProducts: selectedProducts, 
          openResults: true, 
          totalCountValue :  responseJson.total_count,
        })
    }
  }

  handleWarehouseRes = (responseJson: IWarehouse) => {
    if(responseJson.data) {
      this.setState({warehouseList: responseJson.data})
    }
  }

  handleResults = (responseJson: IProductResult) => {
    if (responseJson.data) {
        this.setState({ searchProdResults: responseJson.data});
     
    } else this.setState({ searchProdResults: []});
  };

  handleSearchProduct = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    if(value) {
      this.setState({ searchProduct : event.target.value, openResults:true})
      this.SearchProd(event.target.value)
    }
    else this.setState({searchProduct: '', openResults: false, searchProdResults: []})
  }

  handleStockQty = (value: string, index: number) => {
    const { quantity, qtyError } = this.state;
    
    const quantityValue:any = value === '' ? '' : Number(value);
  
    const updatedQuantities = [...quantity];
    updatedQuantities[index] = quantityValue;
  
    const updatedQtyErr = [...qtyError];
    if (value === '' || isNaN(quantityValue) || quantityValue < 0) {
      updatedQtyErr[index] = configJSON.quantityErrText;
    } else {
      updatedQtyErr[index] = '';
    }
  
    this.setState({
      quantity: updatedQuantities,
      qtyError: updatedQtyErr
    });
  }

  getRelation = (item: IProductList) => {
    return `${item.attributes.category.name} >> ${item.attributes.sub_category?.name} >> ${item.attributes.mini_category?.name} >> ${item.attributes.micro_category?.name}`
  }

  handleAddProduct = (event: React.ChangeEvent<HTMLInputElement>, data: IProductData) => {
    if (this.state.selectedWarehouse) {
      if (event.target.checked) {
        this.setState({
          productInfo: [...this.state.productInfo, data],
          selectProducts: [...this.state.selectProducts, data.id],
          prodError: ''
        }, () => this.addProductsToWarehouse(this.state.selectedWarehouse));
      } else {
        this.setState(prevState => ({
          productInfo: prevState.productInfo.filter(product => product.id !== data.id),
          selectProducts: prevState.selectProducts.filter(prodId => prodId !== data.id),
          prodError: ''
        }), () => this.addProductsToWarehouse(this.state.selectedWarehouse));
      }
    } else {
      this.setState({ prodError: configJSON.selectWarehouseErrMsg });
    }
  };
  

  SearchProduct = (call: (...args: string[]) => void, delay: number | string | unknown) => {
    let timer: string | number ;
    return function(...args: string[]) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        call(...args);
      }, delay);
    };
  };


  async componentDidMount(): Promise<void> {
    this.getWarehouseList()
  }

  getProductList = async () => {
    const header = { 
      "Content-Type": configJSON.contentType, 
      token: await getStorageData("authToken") 
    };
    const prodMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProductListApiCallId = prodMessage.messageId;
    prodMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.searchProdByTitleEndpoint + `${this.state.searchProduct}` + `&per_page=1000`);
    prodMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    prodMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(prodMessage.id, prodMessage);
  };

  SearchProd = this.SearchProduct(this.getProductList, 1000);

  handleSelectWarehouse = (event: React.ChangeEvent<{value: unknown | string}>) => {
    const value = event.target.value
    if(value) {
      this.setState({ selectedWarehouse: value as string, prodError: ''})
      this.listWarehouseProducts()
    }
    else this.setState({selectedWarehouse: '', searchProdResults: []})
  }

  getWarehouseList = async () => {
    const headers = {
      token: await getStorageData('authToken')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getWarehouseListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sellerWarehouseApiEndPoint.list);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addProductsToWarehouse = async (warehouseId: string) => {
    const headers = {
      token: await getStorageData('authToken'),
      "Content-Type": "application/json"
    };
    const body = {
      "warehouse": {
        "id": warehouseId,
          "catalogue_ids": this.state.selectProducts
      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addProductApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/warehouses/update_seller_warehouse` );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePUT);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  removeProduct = async (catalogId: string) => {
    const headers = {
      token: await getStorageData('authToken')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.removeProductApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addPrductToWarehouseEndpoint + this.state.selectedWarehouse + "/warehouse_catalogues/" + catalogId );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeDelete);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  listWarehouseProducts = async() => {
    const headers = {
      token: await getStorageData('authToken')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.listWarehouseProductsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addPrductToWarehouseEndpoint + this.state.selectedWarehouse + `/warehouse_catalogues?per_page=10&page=${this.state.pageNumber}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleKeyDown = (event:React.KeyboardEvent<HTMLDivElement>) => {
    if (
      !(
        event.key === 'Backspace' ||
        event.key === 'ArrowLeft' ||
        event.key === 'ArrowRight' ||
        (event.key >= '0' && event.key <= '9')
      )
    ) {
      event.preventDefault();
    }
  }

  handleUpdateBtn = (catalogId: string, quantity: number) => {
    this.updateStockQuantity(catalogId,quantity)
  }

  updateStockQuantity = async(catalogId:string, quantity: number) => {
    const headers = {
      token: await getStorageData('authToken'),
      "Content-Type": "application/json"
    };
    const body = {
      "warehouse_catalogue": {
          "stocks": quantity
      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateQuantityApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addPrductToWarehouseEndpoint + this.state.selectedWarehouse + `/warehouse_catalogues/${catalogId}` );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePatch);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ showChangeSuccessModal: true});
  }

  handleCloseFWPModal = () => {
    this.setState({showChangeSuccessModal: false });
  };

  getCount = () => {
    const count: number = this.state.totalCountValue / 10;
    const roundedCount = Number.isInteger(count) ? count : Math.ceil(count); 
    return roundedCount
  }

  getQuantityLimit = (value: number) => {
    if(typeof value !== 'number' || isNaN(value)) {
      return true
    }
    else return false
  }

  handleClickAway = () => {
    this.setState({openResults: false, searchProduct: ''})
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if(prevState.selectedWarehouse !== this.state.selectedWarehouse) {
      this.setState({ searchProduct: '', searchProdResults: [], selectProducts: []})
    }
  }
  // Customizable Area End
}
