export const logoImage = require("../assets/logo.png");
export const trashImage = require("../assets/trash.png");
export const bellImage = require("../assets/bell.png");
export const emptyProfileImg = require("../assets/emptyProfile.png");
export const add = require("../assets/plus.png");
export const pdf = require("../assets/pdf_1.png");
export const file = require("../assets/file.png");
export const warning = require("../assets/warning.png");
export const cross = require("../assets/cross.png");
export const trash_1 = require("../assets/trash_1.png");
export const check = require("../assets/check.png");
export const template = require("../assets/template.png");
export const dummyMobile = require("../assets/dummyMobile.png");
export const editIcon = require("../assets/editIcon.png");
export const searchIcon = require("../assets/searchIcon.png");
export const editImage = require("../assets/edit-image.png");
export const checkmark = require("../assets/checkmark.jpg");
export const appleLogo = require("../assets/appleLogo.jpg");
export const iPhoneSeries = require("../assets/iPhoneSeries.jpg");
export const ipad = require("../assets/ipad.jpeg");
export const airpods = require("../assets/airpods.png");
export const iphone14 = require("../assets/iphone14.jpg");
export const imac = require("../assets/imac.jpg");
export const ipod = require("../assets/ipod.jpeg");
export const userImg = require("../assets/userImg.png");
export const byezzyLogo = require("../assets/orangelogo.jpg");
export const arrowSquareUp = require("../assets/arrow-square-up.png");
export const arrowSquareDown = require("../assets/arrow-square-down.png");
export const stockEditIcon = require("../assets/stock_edit.png");
