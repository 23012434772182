import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../../framework/src/Utilities";

// Customizable Area Start
export const configJSON = require("../../src/config.js");

export interface ICategory {
    id: string,
    type: string,
    attributes: {
      id: number,
      name: string,
      parent_id: number,
      relation: string,
      mini_categories: 
        {
          id: number,
          name: string,
          micro_categories: {
              id: number,
              name: string
            }[]
        }[]
      
    }
}

export interface IBrandList {
  id: string,
  type: string,
  attributes: {
    id: number,
    brand_name: string,
    brand_image: string
  }
}
interface DocumentsData {
  id: string;
  type: string;
  attributes: {
    document_type: string;
    document_name: string;
    vat_reason: string;
    account_no: string;
    iban: string;
    bank_address: string;
    name: string
    bank_name: string;
    swift_code: string;
    approved: boolean | null;
    rejected: boolean | null;
    reason_for_rejection: string;
    account_id: number;
    document_files: string[];
  };
}
export interface SuccessResponse {
  data: DocumentsData[];
  meta: {
    message: string;
  };
  min_range:string;
  max_range:string;
  message: string;
}
export interface ProductListArgs {
  category_id?: string | number,
  subCatagoryIds?: string[];
  brandIds?: string[];
  miniCategoryIds?: string[] | number[];
  microCategoryIds?: string[] | number[];
  per_page?: number | string;
  min_price?: number |null;
  max_price?: number | null;
  customFieldValues?: string[];
  filter_by_rating?: number[];
  color_filter?: string[];
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  match: any;
  classes: {
    root: string,
    mainWrraper: string,
    brandContainer: string
    brandBtn: string
    drawer: string,
    drawerPaper: string,
    content: string,
    contentWrapper: string
    headerContainer: string,
    headerText: string,
    container: string,
    orderTextWrapper: string,
    ordersText: string,
    searchWrapper: string,
    searchOrderText: string,
    searchBoxWrapper: string,
    lAndIContainer: string,
    options: string,
    label: string,
    select: string,
    brandImage: string,
    category: string,
    shopByText: string,
    brandName: string,
    categoryOne: string,
    flex: string,
    catalogContainer: string,
    selectDisplay: string,
    displayFilter: string,
    categoryFilterContainer?: string;
    shopByBrandContainer?: string;
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  out_of_stocks: boolean;
  authToken: string;
  searchValue: string;
  productList: any
  category_id: any
  subCategoryList: ICategory[],
  brandList: IBrandList[],
  itemPerPage: any
  subCatagoryItemsIds: string[];
  brandItemsIds: string[];
  min_price: number | null;
  max_price: number | null;
  customFieldsData: {
    [key: string]: string[]; 
  }
  customFieldValues: string[],
  miniCategoryIds: string[],
  microCategoryIds: string[],
  sortByProduct: string;
  rating: number[],
  color_filter: string[]
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class ProductCatalogueController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getApiProductListCallId: string = "";
  getApiSubCategoryCallId: string = "";
  getApiBrandCallId: string = "";
  getSocialMediaInfoApiCallId: string = "";
  getCustomFieldsApiCallId: string = "";
  getSearchKeywordApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.NavigationPayLoadMessage), getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      authToken: "",
      searchValue: '',
      category_id: '',
      subCatagoryItemsIds: [],
      brandItemsIds: [],
      subCategoryList: [],
      brandList: [],
      itemPerPage: 50,
      min_price: null,
      max_price: null,
      productList: {},
      customFieldsData: {},
      customFieldValues: [],
      miniCategoryIds: [],
      microCategoryIds: [],
      sortByProduct: "recommended",
      rating: [],
      color_filter: [],
      out_of_stocks: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      this.handleSuccessResponse(responseJson, apiRequestCallId);

    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Props, prevState: S) {
    // Check if prop or state has changed
    if (prevProps.match.params?.id !== this.props.match.params?.id || prevProps.match.params?.type !== this.props.match.params?.type) {
      // Call your function here
      if(this.state.customFieldsData !== prevState.customFieldsData){
        this.setState({customFieldValues: []})
      }
      this.setState({min_price: null, max_price:null, customFieldValues: [], customFieldsData: {}, brandItemsIds: [], subCatagoryItemsIds: [] })
      this.onMountApicall();
    }
    else return
  }

  async componentDidMount() {
    const authToken = await getStorageData("authToken");
    const sortByProduct = await getStorageData("sortFilter");
    const itemPerPage = await getStorageData("itemPerPage") 
    if(itemPerPage) {
      this.setState({itemPerPage})
    }
    if(sortByProduct) {
      this.setState({sortByProduct})
    }
    this.setState({ authToken, out_of_stocks: true })
    this.onMountApicall();
  }

  async componentWillUnmount() {
   await removeStorageData('sortFilter');
   await removeStorageData('itemPerPage');
   await removeStorageData('brandIds');
   await removeStorageData('subCatagoryIds');
  }

  onMountApicall() {
    const { match } = this.props;
    const category_id = match.params?.id;

    const decodedUrl = decodeURIComponent(window.location.href);

    const subCatagoryIdsMatch = decodedUrl.match(/subCatagoryIds=([\d,]+)/);
    
    const subCatagoryIds = subCatagoryIdsMatch ? subCatagoryIdsMatch[1].split(',') : [];

    this.setState({ category_id, subCatagoryItemsIds: subCatagoryIds })

    this.handleProductlistContent({ category_id });
    this.subCategoryApiCall(category_id);
    this.brandApiCall(category_id);
    this.getCustomFields(category_id)
  }

  getCustomFields = (category_id:string) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.authToken,
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCustomFieldsApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/fetch_custom_field_filters?category_ids[]=${category_id}&sub_category_ids[]=${this.state.subCatagoryItemsIds}&mini_category_ids[]=${this.state.miniCategoryIds}&micro_category_ids[]=${this.state.microCategoryIds}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(message.id, message);
  }

  handleSuccessResponse = (responseJson: any, apiRequestCallId: any) => {

    switch (apiRequestCallId) {
      case this.getApiSubCategoryCallId:
        this.handleSubCategoryApiCallResponse(responseJson);
        break;

      case this.getApiBrandCallId:
        this.handleBrandApiCallResponse(responseJson);
        break;

      case this.getApiProductListCallId:
        this.handleProductlistContentResponse(responseJson);
        break;

      case this.getCustomFieldsApiCallId: 
        this.handleCustomFieldsResponse(responseJson)  
        break;

      default: break;
    }
  }

  handleCustomFieldsResponse = (responseJson: any) => {
    this.setState({customFieldsData: responseJson.custom_field_values })
  }

  handleProductlistContentResponse = (resposeJson: unknown) => {
    const successResponse = resposeJson as SuccessResponse;
    if ("data" in successResponse) {
      this.setState({ productList: successResponse })
      this.getCustomFields(this.state.category_id)
    }
  };

  handleSubCategoryApiCallResponse = (resposeJson: any) => {
    const successResponse = resposeJson.data as ICategory[];
    this.setState({ subCategoryList: successResponse })
  };

  handleBrandApiCallResponse = (resposeJson: any) => {
    const successResponse = resposeJson ;
    this.setState({ brandList: successResponse.data as IBrandList[]})
  };

  handleSetColorFilter = (color: string) => {
    this.setState((prevState) => {
      const color_filter = prevState.color_filter.includes(color)
        ? prevState.color_filter.filter((clr) => clr !== color)
        : [...prevState.color_filter, color];
  
      return { color_filter };
    }, () => this.handleProductlistContent({}));
  }

  handleOutOfStocks = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        out_of_stocks: event.target?.checked || false
      }, () => this.handleProductlistContent({}))
  }

  handleProductlistContent = async ({ category_id = this.state.category_id, subCatagoryIds = this.state.subCatagoryItemsIds, brandIds = this.state.brandItemsIds, miniCategoryIds = this.state.miniCategoryIds, microCategoryIds = this.state.microCategoryIds, per_page = this.state.itemPerPage, min_price , max_price, customFieldValues, filter_by_rating = this.state.rating }: ProductListArgs) => {
    const localsubCatagoryIds = await getStorageData("subCatagoryIds", true);
    if(localsubCatagoryIds && localsubCatagoryIds.length > 0) {
      subCatagoryIds = localsubCatagoryIds
      this.setState({subCatagoryItemsIds: localsubCatagoryIds})
    }
    const localbrandIds = await getStorageData("brandIds", true);
    if(localbrandIds && localbrandIds.length > 0) {
      brandIds = localbrandIds
      this.setState({ brandItemsIds : localbrandIds})
    }
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.authToken
    };
    const isSearch = window.location.pathname.includes('search');
    let body = {};
    let searchParams =  window.location.pathname.split('/');
    if(isSearch) {
      body = {
        search_keyword: decodeURIComponent(searchParams[searchParams.length-1]),
        per_page,
        category_id,
        brand_ids: brandIds,
        min_price,
        max_price,
        page_no: 1,
        sort_by: this.state.sortByProduct,
        color_filter: this.state.color_filter.length > 0 ?  this.state.color_filter : null,
        filter_by_rating,
        out_of_stocks: this.state.out_of_stocks
      }
    }else{
      body =
      {
        category_id, //category id mandatory
        sub_category_ids: subCatagoryIds,     // we can use this to filter if needed
        brand_ids: brandIds, // pass only if you are filtering by brand , also if multiple brand you can pass in array
        mini_category_ids: miniCategoryIds,  // and also if we want pass multiple ids pass like sub_category_ids: [1,2]
        micro_category_ids: microCategoryIds,
        min_price,
        max_price,
        per_page,
        page_no: 1,
        customFieldValues: customFieldValues,
        sort_by: this.state.sortByProduct,
        filter_by_rating,
        color_filter: this.state.color_filter.length > 0 ?  this.state.color_filter : null,
        out_of_stocks: this.state.out_of_stocks,
      };
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getApiProductListCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),isSearch ? configJSON.getSearchProductAPIEndPoint : configJSON.postProductListApiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  subCategoryApiCall = async (categoryid: any) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.authToken
    };
    const isSearch = window.location.pathname.includes('search');
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getApiSubCategoryCallId = requestMessage.messageId;
    const searchQuery = await getStorageData('searchQuery');
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),isSearch ? `${configJSON.getCategoryListSeachKeywordAPIEndPoint}?search_keyword=${searchQuery || this.props.match.params.type}` : configJSON.getSubCategoryEndPoint(categoryid));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  brandApiCall = async (catagoryId: any) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.authToken
    };
    const isSearch = window.location.pathname.includes('search');
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getApiBrandCallId = requestMessage.messageId;
    const searchQuery = await getStorageData('searchQuery');
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),isSearch ? `${configJSON.getBrandListSearchKeywordEndPoint}?search_keyword=${searchQuery || this.props.match.params.type}` : configJSON.getBrandListEndPoint(catagoryId));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleItemPerPage: any = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState({
      itemPerPage: value,
    },() => {
      setStorageData("itemPerPage", value)
      this.handleUpdateUrl("itemPerPage", value)
      this.handleProductlistContent({ per_page: value ,min_price :this.state.min_price, max_price :this.state.max_price, miniCategoryIds: this.state.miniCategoryIds, microCategoryIds: this.state.microCategoryIds})
    });
  };

  handleShopByBrandFilter = (brandId: string) => {
    setStorageData("brandIds", JSON.stringify([brandId]))
    this.handleUpdateUrl("brandIds", [brandId])
    this.setFilterValues({ brandIds: [brandId] })
  }

  setFilterValues = ({ subCatagoryIds = this.state.subCatagoryItemsIds, brandIds = this.state.brandItemsIds,min_price = this.state.min_price, max_price = this.state.max_price,  customFieldValues= this.state.customFieldValues }) => {
    const result: any = {};
    subCatagoryIds.forEach((subCategoryId:any) => {
    const subCategory = this.state.subCategoryList.find((item:any) => item.attributes.id === subCategoryId);

    if (subCategory) {
      result[subCategoryId] = {
        miniCategoriesIds: subCategory.attributes.mini_categories.map((miniCat: any) => miniCat.id),
        microCategoriesIds: subCategory.attributes.mini_categories.reduce((acc:any, miniCat:any) => {
          return acc.concat(miniCat.micro_categories.map((microCat:any) => microCat.id));
        }, []),
      }
    } else {
      result[subCategoryId] = {
        miniCategoriesIds: [],
        microCategoriesIds: [],
      };
    }
  });
  const miniCategoryIds: string[] = (subCatagoryIds as string[])
  .map((id: string): string[] => result[id]?.miniCategoriesIds || [])
  .reduce((acc: string[], ids: string[]) => [...acc, ...ids], []);

const microCategoryIds: string[] = (subCatagoryIds as string[])
  .map((id: string): string[] => result[id]?.microCategoriesIds || [])
  .reduce((acc: string[], ids: string[]) => [...acc, ...ids], []);
    this.setState({
      subCatagoryItemsIds: subCatagoryIds,
      brandItemsIds: brandIds,
      min_price,
      max_price,
      customFieldValues: customFieldValues,
      microCategoryIds: microCategoryIds,
      miniCategoryIds: miniCategoryIds
    });
    this.handleProductlistContent({ subCatagoryIds, brandIds ,min_price, max_price, customFieldValues, miniCategoryIds, microCategoryIds})
  }

  handleUpdateUrl = (type: string, value: string | string[]) => {
    const { origin, pathname } = window.location;
    const { id } = this.props.match.params;
    const pathParts = pathname.split('/');
    const baseUrl = `${origin}/buyer/categories/${id}/${pathParts[4]}`;

    let newUrl = `${pathname}/${type}=${value}`;

    if (pathname.includes(type)) {
        newUrl = `${baseUrl}/${type}=${value}`;
    }

    window.history.pushState(null, '', newUrl);
};
  
  handleHome = () => {
    this.props.navigation.navigate('Home')
  }

  handleSortByProductOption = (event: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
    const value = event.target.value as string;
    this.setState({sortByProduct: value},() => {
      setStorageData("sortFilter", value);
      this.handleUpdateUrl("sort", value)
      this.handleProductlistContent({ per_page: this.state.itemPerPage ,min_price :this.state.min_price, max_price :this.state.max_price, miniCategoryIds: this.state.miniCategoryIds, microCategoryIds: this.state.microCategoryIds})
    })
  }

  handleRatingVlaue = (value : any) => {
    const baseValue = 5;
    const rating: number[] = value.reduce((acc: number[], item: boolean, index: number) => {
      if (item) {
        acc.push(baseValue - index);
      }
      return acc;
    }, []);
    this.setState({ rating });
    this.handleProductlistContent({ filter_by_rating: rating });
}
  // Customizable Area End
}
