// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import {
  getStorageData
} from "../../../../framework/src/Utilities";
import { Message } from "../../../../framework/src/Message";
export const configJSON = require("../../src/config");
import * as Yup from "yup";
import { toast } from "react-toastify";

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open: boolean;
  order_id: string;
  onClose: () => void;
  onSubmit?: () => void;
  orderDetails?: {
    id: string | number,
    tracking_number: string,
    courier_name: string
  }
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  courier_name: string;
  tracking_number: string;
  token: string;
  ship_id: string | number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ShippingAddressController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addOrderDetailsApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      courier_name: "",
      tracking_number: "",
      token: "",
      ship_id: "",
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleApiSuccessResponse(responseJson, apiRequestCallId);
    }
  }

  handleApiSuccessResponse = async(responseJson: unknown, apiRequestCallId: string) => {
    if (apiRequestCallId === this.addOrderDetailsApiCallId) {
      if(!!responseJson){       
        toast.success(`Shipping details ${!!this.props.orderDetails ? "updated" : "added"} successfully`, {
          progress: undefined,
          position: "top-right",
          pauseOnHover: true,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          autoClose: 3000,
        });

        this.props.onSubmit && this.props.onSubmit();

        this.props.onClose();
        
        this.setState({ship_id: ""})
      } else {
        toast.error("Something went wrong", {
          progress: undefined,
          position: "top-right",
          pauseOnHover: true,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
        });
      }
    }
  };

  shippingSchema = Yup.object().shape({
    courier_name: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9 !@#\$%\^\&*\)\(+=._-]+$/, "Only alphanumeric characters are allowed")
    .required('This field is required'),
    tracking_number: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9 ]+$/, "Only alphanumeric characters are allowed")
    .required('This field is required')
  })

  async componentDidMount() {
    this.handleGetDetails()
  }

  handleGetDetails = async () => {
    const auth = await getStorageData("authToken");
    if(this.props.orderDetails){
      const {tracking_number, courier_name, id} = this.props.orderDetails
      this.setState({
        tracking_number, courier_name, ship_id: id
      })
    }
    if (!auth) {
      return;
    }
    this.setState({ token: auth,  });
  };


  AddUpdateOrderDetails = async (values: { tracking_number: string, courier_name: string}) => {
    const headerO = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const methodOfApi = this.state.ship_id ? configJSON.apiMethodTypePatch : configJSON.apiMethodTypePost
    const endPoint = this.state.ship_id ? `shipped_order_details/${this.state.ship_id}` : "shipped_order_details"
    const { tracking_number, courier_name} = values;
    const Sbody = {
      shipped_order_detail: {
      tracking_number, courier_name
      }
    }
    const orderMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addOrderDetailsApiCallId = orderMessage.messageId;
    orderMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_shopping_cart/orders/${this.props.order_id}/${endPoint}`
    );
    orderMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerO)
    );
    orderMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodOfApi
    );
    orderMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Sbody)
    );
    runEngine.sendMessage(orderMessage.id, orderMessage);
  };

  // Customizable Area End
}
