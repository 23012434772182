// Customizable Area Start
import React from "react";
import WarehouseHomeController, { Props, configJSON } from "./WarehouseHomeController.web";
import { Box, withStyles } from "@material-ui/core";
import {
  BoxNoScroll,
  FitContentOrangeButton,
} from "../../dashboard/src/CustomizedElementStyle";
import { cross } from "../../dashboard/src/assets";
// Customizable Area End

class WarehouseHome extends WarehouseHomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box data-test-id="warehouse"
          className={classes.heading}
        >
          {configJSON.fulfilledByPartnerLabel}
        </Box>
        <BoxNoScroll className={classes.noScroll}>
          <Box
            className={classes.mainContainer}
          >
            <Box
              className={classes.headerWrapper}
            >
              <Box
                className={classes.warehouseText}
              >
                {configJSON.createWarehouseLabel}
              </Box>
            </Box>
            <Box
              className={classes.boxWrapper}
            >
              <img src={cross} alt="cross_icon" />
              <Box
                className={classes.text}
              >
                {configJSON.welcomeText}
              </Box>
              <Box
                className={classes.desc}
              >
                {configJSON.createWarehouseDes}
              </Box>
              <FitContentOrangeButton
                className={classes.createButton}
                style={{
                  fontSize: '17px',
                  padding: "15px"
                }}
                onClick={this.handleGoToCreateWarehousePage}
              >
                {configJSON.createNewWarehouseBtn}
              </FitContentOrangeButton>
            </Box>
          </Box>
        </BoxNoScroll>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  heading: {
    fontFamily: `"Lato", sans-serif` as const,
    padding: "20px 0px 15px 0px",
    fontSize: "18px",
    fontWeight: 400,
  },
  noScroll: {
    paddingBottom: 20.5,
    overflow: "auto"
  },
  mainContainer: {
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as const,
    overflowY: "auto" as const,
    overflowX: "hidden" as const,
    position: "relative" as const,
    background: "white",
    height: "135vh",
  },
  headerWrapper: {
    width: "100%",
    flexDirection: "row" as const,
    display: "flex",
    flexWrap: "wrap" as const,
    justifyContent: "space-between" as const,
    alignItems: "center",
    marginBottom: 10,
    padding:'22px 30px',
    borderBottom:'1px solid #EEEEEE',
  },
  warehouseText: {
    fontSize: "27.1px",
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif` as const,
  },
  section: {
    width: "calc(100% + 60px)",
    height: 1,
    background: "#EEEEEE",
    position: "absolute" as const,
    left: 0,
    top: 100,
  },
  dimension: {
    width: "100%",
    height: 90
  },
  boxWrapper: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center" as const,
    flexDirection: "column" as const,
    position:'absolute' as const,
    marginTop:'135px',
  },
  text: {
    color: "#33333A",
    fontSize: 20,
    fontFamily: `"Lato", sans-serif` as const,
    fontWeight: 400,
    margin: "9px 0px",
  },
  desc: {
    color: "#33333A",
    fontSize: 20,
    fontFamily: `"Lato", sans-serif` as const,
    fontWeight: 700,
    marginBottom: 15,
  },
  createButton: { 
    fontSize: 20 
  }
}

export {WarehouseHome }

export default withStyles(styles)(WarehouseHome);
// Customizable Area End
