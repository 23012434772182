import React, { forwardRef } from 'react';
import { Box, Button, FormControl, InputBase, TextField, createTheme, withStyles } from '@material-ui/core';
import { CustomInputBaseProps } from './Interfaces';

export const theme = createTheme({
  typography: {
    h1: {
      fontSize: "20px",
      fontWeight: 700,
      color: "#3E3E3E",
      fontFamily: `"Lato", sans-serif`,
    },
    h2: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#3E3E3E",
      fontFamily: `"Lato", sans-serif`,
    },
    h3: {
      fontSize: "13px",
      fontWeight: 700,
      color: "#FF6008",
      fontFamily: `"Lato", sans-serif`,
    },
    h4: {
      fontSize: "14px",
      fontWeight: 600,
      color: "#061B6F",
      fontFamily: `"Lato", sans-serif`,
    },
    h5: {
      fontSize: "12px",
      fontWeight: 600,
      color: "#061B6F",
      fontFamily: `"Lato", sans-serif`,
    },
    h6: {
      fontSize: "12px",
      fontWeight: 500,
      color: "#565656",
      fontFamily: `"Lato", sans-serif`,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1460,
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        fontFamily: `"Lato", sans-serif`,
      },
    },
  },
});

export const CustomTextField = withStyles(() => ({
  root: {
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "1px solid #D8D8D8",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#626161",
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "1px solid #D8D8D8",
    },
    "& .MuiInputLabel-root": {
      color: "#626161",
      fontSize: 14,
      fontWeight: "500",
      fontFamily: `"Lato", sans-serif`,
    },
    "& .MuiInputBase-input, & .MuiInput- input": {
      color: "#232529",
      fontSize: 16,
      fontWeight: "500",
      fontFamily: `"Lato", sans-serif`,
      height: "25px",
    },
  },
}))(TextField);

export const CustomFormControl = withStyles((theme) => ({
  root: {
    fontSize: "16px",
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "1px solid #D8D8D8", // Remove the underline
    },
    "& .MuiInputLabel-root.Mui-focused": {
       color: "#626161", // Change the label font color to black when focused
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "1px solid #D8D8D8", // Remove the underline on hover
    },
    "& .MuiInputLabel-root": {
      color: "#626161",
      fontSize: 14,
      fontWeight: "500",
      fontFamily: `"Lato", sans-serif`,
    },
    "& .MuiInputBase-input, & .MuiInput- input": {
      color: "#232529",
      fontSize: 16,
      fontWeight: "500",
      fontFamily: `"Lato", sans-serif`,
      height: "25px",
    },
  },
}))(FormControl);

export const CustomSubmitButton = withStyles(() => ({
  root: {
    textTransform: "none",
    backgroundColor: "#FF6008",
    "&:hover": {
      backgroundColor: "#FF6008",
    },
    color: "white",
    padding: "15px 0px",
    borderRadius: "8px",
    fontSize: "20px",
    fontWeight: "bold",
    fontFamily: "'Montserrat Variable', sans-serif",
  },
}))(Button);

export const OtpInput = withStyles(() => ({
  root: {
    fontSize: 24,
    fontFamily: `"Lato", sans-serif`,
    textAlign: "center",
    width: "60px",
    height: "60px",
    // border: "1px solid black",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    borderRadius: "8px",
    "& input": {
      textAlign: "center",
    },
    "& input::placeholder": {
      opacity: 1,
      fontWeight: 600,
    },
  },
}))(forwardRef((props: CustomInputBaseProps, ref) => <InputBase {...props} inputRef={ref} />));

// Customizable Area Start
export const VisibilityOffSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.20965 12.9117L6.82383 11.2975C7.19142 11.4286 7.58737 11.5 8 11.5C9.933 11.5 11.5 9.933 11.5 8C11.5 7.58737 11.4286 7.19142 11.2975 6.82383L13.3589 4.76242C15.0613 6.27902 16 8 16 8C16 8 13 13.5 8 13.5C6.98461 13.5 6.0517 13.2732 5.20965 12.9117Z" fill="#757575" />
      <path d="M10.7903 3.08834C9.94828 2.72682 9.01538 2.5 8 2.5C3 2.5 0 8 0 8C0 8 0.93871 9.72097 2.6411 11.2376L4.70252 9.17614C4.5714 8.80856 4.5 8.41262 4.5 8C4.5 6.067 6.067 4.5 8 4.5C8.41262 4.5 8.80856 4.5714 9.17614 4.70252L10.7903 3.08834Z" fill="#757575" />
      <path d="M10.4752 7.64615C10.4915 7.76175 10.5 7.87988 10.5 8C10.5 9.38071 9.38071 10.5 8 10.5C7.87988 10.5 7.76175 10.4915 7.64615 10.4752L10.4752 7.64615Z" fill="#757575" />
      <path d="M5.52484 8.35382L8.35382 5.52484C8.23824 5.50847 8.12011 5.5 8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 8.12011 5.50847 8.23823 5.52484 8.35382Z" fill="#757575" />
      <path d="M2.35355 14.3536L14.3536 2.35355L13.6464 1.64645L1.64645 13.6464L2.35355 14.3536Z" fill="#757575" />
    </svg>
  );
};

export const ClickableBox = withStyles(() => ({
  root: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}))(Box);
