// Customizable Area Start
import React from "react";
import { Box, Grid, InputAdornment, Typography, withStyles } from "@material-ui/core";
import {
  BlackButton,
  BoxNoScroll,
  CustomFormControl,
  CustomizeBrandInput,
  OrangeButton,
} from "../../dashboard/src/CustomizedElementStyle";
import CreateWarehouseController, {
  Props,
  configJSON,
} from "./CreateWarehouseController.web";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import { Loader2 } from "../../../components/src/Loader.web";
// Customizable Area End

class CreateWarehouse extends CreateWarehouseController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  returnErrorMessage = (touched: boolean | undefined, errors: string | undefined) => {
    if (touched && errors) {
      return <Typography className={this.props.classes.errorStyle}>{errors}</Typography>;
    } else {
      return null;
    }
  };

  validationSchema = Yup.object().shape({
    name: Yup.string().required(configJSON.warehouseNameRequiredText).max(50, configJSON.maxWarehouseNameText),
    processingDays: Yup.string()
      .matches(/^\d+$/, configJSON.validProcessingDaysText)
      .min(0, configJSON.validProcessingDaysText)
      .max(2, configJSON.daysLimitText)
      .required(configJSON.processingDaysRequiredText),
    addressOne: Yup.string().required(configJSON.addressOneRequiredText).max(100, configJSON.addressMaxLimitText),
    addressTwo: Yup.string().required(configJSON.addressTwoRequiredText).max(100, configJSON.addressMaxLimitText),
    contactPerson: Yup.string().required(configJSON.contactPersonReqText).max(30, configJSON.contactNameMaxLimitText),
    contactNumber: Yup.string().trim()
      .matches(/^971-\d{2}-\d{7}$/, configJSON.contactNumberFormatText)
      .required(configJSON.enterContactNumberText),
  })

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <ToastContainer />
        <Box className={classes.tabText}>
          {configJSON.fulfilledByPartnerLabel}
        </Box>
        <BoxNoScroll className={classes.boxscroll}>
          {this.state.loading ? <Loader2 loading={this.state.loading} /> : <></>}
          <Formik
            initialValues={{
              name: this.state.name,
              processingDays: this.state.processingDays,
              addressOne: this.state.addressOne,
              addressTwo: this.state.addressTwo,
              contactPerson: this.state.contactPerson,
              contactNumber: this.state.contactNumber,
            }}
            enableReinitialize={true}
            validationSchema={this.validationSchema}
            validateOnMount={true}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={this.submitForm}
            data-test-id="formik"
          >
            {({ handleChange,
              errors,
              setFieldTouched,
              touched,
              setFieldValue,
              handleBlur, values
            }) => (
              <Form translate={undefined} ref={this.formRef}
              >
                <Box className={classes.wrapper}>
                  <Box className={classes.headerWrapper}>
                    <Box data-test-id="wareHouseLabel" className={classes.headerText}>
                      {configJSON.wareHouseLabel}
                    </Box>
                  </Box>
                  <Box className={classes.fieldWrapper}></Box>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <CustomFormControl className={classes.fieldContainer}>
                        <CustomizeBrandInput
                          data-test-id="name"
                          label={configJSON.warehouseName}
                          id="outlined-start-adornment"
                          inputProps={{
                            maxLength: 50
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start"></InputAdornment>
                            ),
                          }}
                          name="name"
                          value={values.name}
                          onChange={(event) => {
                            handleChange(event);
                            setFieldTouched("name", true, false)
                          }}
                          onBlur={handleBlur}
                        />
                        {this.returnErrorMessage(touched.name, errors.name)}
                      </CustomFormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <CustomFormControl className={classes.fieldContainer}>
                        <CustomizeBrandInput
                          data-test-id="days"
                          label={configJSON.processingDays}
                          id="outlined-start-adornment"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start"></InputAdornment>
                            ),
                          }}
                          inputProps={{
                            maxLength: 2
                          }}
                          name="processingDays"
                          value={values.processingDays}
                          onChange={(event) => {
                            handleChange(event);
                            setFieldTouched("processingDays", true, false)
                          }}
                          onBlur={handleBlur}
                        />
                        {this.returnErrorMessage(touched.processingDays, errors.processingDays)}
                      </CustomFormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className={classes.topMargin}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <CustomFormControl className={classes.fieldContainer}>
                        <CustomizeBrandInput
                          data-test-id="addressOne"
                          label={configJSON.addressOne}
                          id="outlined-start-adornment"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start"></InputAdornment>
                            ),
                          }}
                          inputProps={{
                            maxLength: 100
                          }}
                          name="addressOne"
                          value={values.addressOne}
                          onChange={(event) => {
                            handleChange(event);
                            setFieldTouched("addressOne", true, false)
                          }}
                          onBlur={handleBlur}
                        />
                        {this.returnErrorMessage(touched.addressOne, errors.addressOne)}
                      </CustomFormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <CustomFormControl className={classes.fieldContainer}>
                        <CustomizeBrandInput
                          data-test-id="addressTwo"
                          label={configJSON.addressTwo}
                          id="outlined-start-adornment"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start"></InputAdornment>
                            ),
                          }}
                          inputProps={{
                            maxLength: 100
                          }}
                          name="addressTwo"
                          value={values.addressTwo}
                          onChange={(event) => {
                            handleChange(event);
                            setFieldTouched("addressTwo", true, false)
                          }}
                          onBlur={handleBlur}
                        />
                        {this.returnErrorMessage(touched.addressTwo, errors.addressTwo)}
                      </CustomFormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className={classes.topMargin}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <CustomFormControl className={classes.fieldContainer}>
                        <CustomizeBrandInput
                          data-test-id="contactPerson"
                          label={configJSON.contactPerson}
                          id="outlined-start-adornment"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start"></InputAdornment>
                            ),
                          }}
                          inputProps={{
                            maxLength: 30
                          }}
                          name="contactPerson"
                          value={values.contactPerson}
                          onChange={(event) => {
                            handleChange(event);
                            setFieldTouched("contactPerson", true, false)
                          }}
                          onBlur={handleBlur}
                        />
                        {this.returnErrorMessage(touched.contactPerson, errors.contactPerson)}
                      </CustomFormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <CustomFormControl className={classes.fieldContainer}>
                        <CustomizeBrandInput
                          data-test-id="contactNumber"
                          label={configJSON.contactNumber}
                          id="outlined-start-adornment"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start"></InputAdornment>
                            ),
                          }}
                          name="contactNumber"
                          value={values.contactNumber}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.handleContactNumberChange(event,setFieldValue);
                            setFieldTouched("contactNumber", true, false)
                          }}
                          onBlur={handleBlur}
                        />
                        {this.returnErrorMessage(touched.contactNumber, errors.contactNumber)}
                      </CustomFormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className={classes.buttonContainer}>
                    <BlackButton data-test-id="backBtn" onClick={this.handleBackBtn} className={classes.backButton}>Back</BlackButton>
                   {this.state.warehouseId ? <OrangeButton data-test-id="updateSubmitBtn" type="submit" className={classes.submitButton}>Update</OrangeButton>
                    :<OrangeButton data-test-id="submitBtn" type="submit" className={classes.submitButton}>Submit</OrangeButton>}
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </BoxNoScroll>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  tabText: {
    fontFamily: `"Lato", sans-serif`,
    padding: "20px 0px 15px 0px",
    fontSize: "18px",
    fontWeight: 400,
  },
  wrapper: {
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as const,
    padding: "30px",
    overflowY: "auto" as const,
    overflowX: "hidden" as const,
    position: "relative" as const,
    background: "white",
  },
  headerWrapper: {
    width: "100%",
    flexDirection: "row" as const,
    display: "flex",
    flexWrap: "wrap" as const,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  headerText: {
    fontSize: "27.1px",
    fontWeight: 700 as const,
    fontFamily: `"Lato", sans-serif` as const,
  },
  fieldWrapper: {
    width: "100%",
    height: 50,
  },
  fieldContainer: {
    width: "100%",
    paddingTop: "10px",
  },
  topMargin: {
    marginTop: "30px",
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginTop: '100px'
  },
  backButton: {
    borderRadius: '8px',
    backgroundColor: '#444444',
    padding: '8px 23px',
    color: '#ffffff',
    fontFamily: "Lato, sans-serif",
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'capitalize' as const,
    marginRight: '20px',
  },
  submitButton: {
    borderRadius: '8px',
    padding: '8px 23px',
    fontFamily: "Lato, sans-serif",
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'capitalize' as const
  },
  errorStyle: {
    fontFamily: "Lato, sans-serif",
    color: "red",
    textAlign: "left" as const,
    fontSize: "12px",
    marginTop: "5px"
  },
  boxscroll: { 
    paddingBottom: 20.5, 
    overflow: "auto" as const, 
    position: 'relative' as const
  }
};

export { CreateWarehouse }
export default withStyles(styles)(CreateWarehouse);
// Customizable Area End
