// Customizable Area Start
import React from "react";
import { Box, Button, Grid, InputAdornment, Theme, Typography, withStyles } from "@material-ui/core";
import StoreSectionTwoTabController, { Props, configJSON } from "./StoreSectionTwoTabController.web";
import { ClickableBox } from "../../../mobile-account-login/src/CustomElementStyling";
import { file } from "../assets";
import { CustomizeBrandInput, NextButton } from "../CustomizedElementStyle";
import { errorImage } from "../../../bulkuploading/src/assets";
// Customizable Area End

class StoreSectionTwoTab extends StoreSectionTwoTabController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  showGridNameInputErr = () => {
    return this.state.nameError ? (
      <Typography data-test-id="nameInputErr" className={this.props.classes.errorMessage}>
        {this.state.nameError}
      </Typography>
    ) : (
      ""
    );
  };

  showErr = () => {
    return (
      <>
        {this.state.showNextErrMsg ? (
          <Box className={this.props.classes.inlineErrorBox}>
            <Box className={this.props.classes.marginEnd}>
              <img src={errorImage} />
            </Box>
            <Box>
              <Typography data-test-id="errorText" className={this.props.classes.errorText}>
                {this.state.showNextErrMsg}
              </Typography>
            </Box>
          </Box>
        ) : (
          ""
        )}
      </>
    );
  };

  renderSection2Grid2 = () => {
    const { classes } = this.props;
    return (
      <>
        <Grid container className={classes.firstMargin} spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.firstPadding}>
            <Typography className={classes.secondaryHeader}>{configJSON.sectionTwoGridTwoText}</Typography>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.spaceTop}>
          <CustomizeBrandInput
            data-test-id="gridTwoNameInput"
            label={configJSON.gridNameLabel}
            id="outlined-start-adornment"
            value={this.state.gridTwoNameInput}
            onChange={this.handleGridNameChange.bind(this, "gridTwoNameInput")}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>
            }}
            inputProps={{
              style: webStyle.sectionType,
              maxLength: 15
            }}
          />
          {this.showGridNameInputErr()}
        </Grid>
        <Box className={classes.gridLabel}>{configJSON.gridImageLabel}</Box>
        <input data-test-id="sec2_grid_two_image" type="file" accept=".jpeg, .jpg, .png" ref={this.secTwoGridTwoImgRef} className={classes.hideGridImageInput} value="" onChange={this.handleUploadSection2GridImage.bind(this, "gridTwoImageInput")} />
        {this.state.gridTwoImgPreview ? (
          <Box className={classes.dynamicWidthContainer}>
            <img src={this.state.gridTwoImgPreview} alt="Image Preview" className={classes.resizableImage} />
            <ClickableBox data-test-id="remove_gridtwo_image" onClick={this.handleRemoveGridImage.bind(this, "gridTwoImageInput")} className={classes.deleteBannerBtn}>
              {configJSON.removeText}
            </ClickableBox>
          </Box>
        ) : (
          <label htmlFor="upload-button">
            <Button variant="outlined" fullWidth data-test-id="open_grid2_image" onClick={this.handleOpenGridImage.bind(this, "gridTwoImageInput")} className={classes.flexibleUploadBtn}>
              <Box>
                <img src={file} />
                <Box className={classes.boxButton}>{configJSON.dropImageText}</Box>
                <Box className={classes.buttonInformation}>{configJSON.fileSizeInfoText}</Box>
                <Box className={classes.buttonInformation}>{configJSON.recommendedSize} 552&times;408</Box>
              </Box>
            </Button>
          </label>
        )}
        {this.state.gridTwoErr ? <Typography className={classes.errorMessage}>{this.state.gridTwoErr}</Typography> : ""}
        <Grid container className={classes.spaceTop} spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.spaceTop}>
            <CustomizeBrandInput
              data-test-id="grid_two_link"
              label={configJSON.gridLink}
              id="outlined-start-adornment"
              value={this.state.gridTwoLinkInput}
              onChange={this.handleGridLink.bind(this, "gridTwoLinkInput")}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>
              }}
            />
            {this.state.gridTwoLinkErr ? <Typography className={classes.errorMessage}>{this.state.gridTwoLinkErr}</Typography> : ""}
            {this.showErr()}
          </Grid>
          <Box className={classes.endFlexContainer}>
            <Grid container spacing={3} className={classes.endJustifyBtn}>
              <Grid item>
                <NextButton data-test-id="next2Ref" onClick={this.handleSec2Grid2Next}>
                  {configJSON.nextBtn}
                </NextButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.showSection2Grid1 && (
          <>
            <Grid container className={classes.spaceTop} spacing={4}>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.spaceTop}>
                <Typography className={classes.bigHeader}>{configJSON.sectionTwoText}</Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.spaceTop}>
                <CustomizeBrandInput
                  data-test-id="sectionTwoType"
                  label={configJSON.sectionTypeLabel}
                  id="outlined-start-adornment"
                  value={this.state.sectionTwoType}
                  disabled
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>
                  }}
                  inputProps={{
                    style: webStyle.sectionType
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.firstMargin} spacing={4}>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.firstPadding}>
                <Typography className={classes.secondaryHeader}>{configJSON.sectionTwoGridOneText}</Typography>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.spaceTop}>
              <CustomizeBrandInput
                data-test-id="gridOneNameInput"
                label={configJSON.gridNameLabel}
                id="outlined-start-adornment"
                value={this.state.gridOneNameInput}
                onChange={this.handleGridNameChange.bind(this, "gridOneNameInput")}
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start"></InputAdornment>
                }}
                inputProps={{
                  style: webStyle.sectionType,
                  maxLength: 15
                }}
              />
              {this.showGridNameInputErr()}
            </Grid>
            <Box className={classes.gridLabel}>{configJSON.gridImageLabel}</Box>
            <input data-test-id="sec2_grid_one_image" type="file" className={classes.hideGridImageInput} value="" accept=".jpeg, .jpg, .png" ref={this.secTwoGridOneImgRef} onChange={this.handleUploadSection2GridImage.bind(this, "gridOneImageInput")} />
            {this.state.gridOneImgPreview ? (
              <Box className={classes.dynamicWidthContainer}>
                <img src={this.state.gridOneImgPreview} alt="Image Preview" className={classes.resizableImage} />
                <ClickableBox data-test-id="remove_grid1One_image" onClick={this.handleRemoveGridImage.bind(this, "gridOneImageInput")} className={classes.deleteBannerBtn}>
                  {configJSON.removeText}
                </ClickableBox>
              </Box>
            ) : (
              <label htmlFor="upload-button">
                <Button variant="outlined" fullWidth data-test-id="open_sec2_grid1_image" onClick={this.handleOpenGridImage.bind(this, "gridOneImageInput")} className={classes.flexibleUploadBtn}>
                  <Box>
                    <img src={file} />
                    <Box className={classes.boxButton}>{configJSON.dropImageText}</Box>
                    <Box className={classes.buttonInformation}>{configJSON.fileSizeInfoText}</Box>
                    <Box className={classes.buttonInformation}>{configJSON.recommendedSize} 552&times;408</Box>
                  </Box>
                </Button>
              </label>
            )}
            {this.state.gridOneErr ? <Typography className={classes.errorMessage}>{this.state.gridOneErr}</Typography> : ""}
            <Grid container className={classes.spaceTop} spacing={4}>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.spaceTop}>
                <CustomizeBrandInput
                  data-test-id="grid_one_link"
                  label={configJSON.gridLink}
                  id="outlined-start-adornment"
                  value={this.state.gridOneLinkInput}
                  onChange={this.handleGridLink.bind(this, "gridOneLinkInput")}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>
                  }}
                />
                {this.state.gridOneLinkErr ? <Typography className={classes.errorMessage}>{this.state.gridOneLinkErr}</Typography> : ""}
                {this.state.showNextErrMsg ? (
                  <Box className={classes.inlineErrorBox}>
                    <Box className={classes.marginEnd}>
                      <img src={errorImage} />
                    </Box>
                    <Box>
                      <Typography data-test-id="errorTextOne" className={classes.errorText}>
                        {this.state.showNextErrMsg}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
              </Grid>
              <Box className={classes.endFlexContainer}>
                <Grid container spacing={3} className={classes.endJustifyBtn}>
                  <Grid item>
                    <NextButton data-test-id="next1Ref" onClick={this.handleSec2Grid1Next}>
                      {configJSON.nextBtn}
                    </NextButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </>
        )}
        {this.state.showSection2Grid2 && this.renderSection2Grid2()}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  sectionType: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    fontWeight: 500,
    color: "#000"
  }
};

const styles = (theme: Theme) => ({
  spaceTop: {
    marginTop: "20px"
  },
  endJustifyBtn: {
    padding: "0 16px",
    justifyContent: "flex-end"
  },
  gridLabel: {
    marginTop: "30px",
    color: "#9a9a9a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "12px",
    fontWeight: 600
  },
  hideGridImageInput: {
    display: "none"
  },
  dynamicWidthContainer: {
    width: "fit-content",
    position: "relative" as const,
    marginTop: "30px"
  },
  resizableImage: {
    maxWidth: "100%",
    maxHeight: "300px",
    alignSelf: "flex-start"
  },
  deleteBannerBtn: {
    borderRadius: "5px",
    width: "80px",
    background: "#fcaca7",
    padding: "10px 20px",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    marginTop: "10px"
  },
  flexibleUploadBtn: {
    width: "100%",
    height: "210px",
    padding: "20px 0px",
    margin: "10px 0px",
    border: "none",
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23FBBC98FF' stroke-width='3' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    borderRadius: "5px",
    textTransform: "none" as const,
    fontFamily: `"Lato", sans-serif`
  },
  boxButton: {
    fontSize: "27px",
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`
  },
  buttonInformation: {
    color: "#9a9a9a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "16px"
  },
  endFlexContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: "60px"
  },
  errorMessage: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: "12px",
    marginTop: "5px",
    color: "red"
  },
  bigHeader: {
    marginTop: "-30px",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "24px",
    fontWeight: 600
  },
  secondaryHeader: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: "20px",
    fontWeight: 600
  },
  firstMargin: {
    margin: "16px 0"
  },
  firstPadding: {
    padding: "16px 0 !important"
  },
  errorText: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: "12px",
    color: "red"
  },
  inlineErrorBox: {
    flexDirection: "row" as const,
    flexWrap: "nowrap" as const,
    display: "flex",
    paddingTop: "16px",
    alignItems: "center"
  },
  marginEnd: {
    marginRight: "10px"
  }
});

export { StoreSectionTwoTab };
export default withStyles(styles)(StoreSectionTwoTab);

// Customizable Area End
