// Customizable Area Start
import { withStyles } from "@material-ui/styles";
import React from "react";
import FBBFeesAndDisputesController, {
  Props, configJSON,
} from "./FBBFeesAndDisputesController.web";
import DatePicker from "react-datepicker";
import "../../catalogue/src/calendar.css";
import {calendar} from "./assets";
// Customizable Area End
import {
  Box,
  Grid,
  InputAdornment,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
} from "@material-ui/core";
import {
  CustomExpandMoreIcon,
  CustomizeInputSearchBox,
  FormControlTwo,
  MenuItemTwo,
  OrangeButton,
  SearchIconSVG,
} from "../../dashboard/src/CustomizedElementStyle";

export function StockTabPanel(props: any) {
  const { value, index, children, ...other } = props;

  return (
    <div
      hidden={value !== index}
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Customizable Area End

class FBBFeesAndDisputes extends FBBFeesAndDisputesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const { classes } = this.props;
    return (
      <Box style={webStyle.wrapper}>
        <Box className={classes.textWrapper}>
        <Box className={classes.byezzyText}>{configJSON.fulFilledByEzzyText}</Box>
        </Box>
        <Grid container style={webStyle.headerContainerFees}>
          <Grid item xs={6}>
            <Typography
              className={classes.totalStorageText}
            >
              {configJSON.fbbFeesAndDisputesText}
            </Typography>
          </Grid>
        </Grid>
        <Tabs
          data-test-id="FBBtabs"
          value={this.state.tabValue}
          onChange={this.handleTabsChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: webStyle.tabStyle,
          }}
        >
          <Tab
            label={configJSON.fbbFeesLabel}
            className={classes.tabsLabel}
            style={{
              textTransform: "capitalize",
              backgroundColor: this.setTabBackground(0)
            }}
            {...tabProps(0)}
          />
          <Tab
            label={configJSON.disputesLabel}
            className={classes.tabsLabel}
            style={{
              textTransform: "capitalize",
              backgroundColor: this.setTabBackground(1)
            }}
            {...tabProps(1)}
          />
        </Tabs>
        <StockTabPanel
        data-test-id="stock"
          value={this.state.tabValue}
          index={0}
          style={webStyle.color}
        >
          <Grid container data-test-id="FeesN">
            <Grid item xs={12} style={webStyle.textWrapper}>
              <Box sx={webStyle.requestText}>{configJSON.fbbFeesLabel}</Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          <Box
            display={"flex"}
            width={"100%"}
            alignItems={"center"}
            data-test-id="FeesNBox"
          >
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Box style={webStyle.box}>
              <Box style={webStyle.fromDate}>
              <Typography
                data-test-id="spanTag"
                style={webStyle.dateBox}
              >
                <Box sx={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                  {configJSON.fromDateLabel}
                </Box>
              </Typography>
              <div className="DateContainers">
                <span className="dateInput">
                  {" "}
                  <DatePicker
                    id="endDate"
                    placeholderText={configJSON.dateFormat}
                    popperPlacement="auto"
                    popperModifiers={[
                      {
                        name: "offset",
                        options: {
                          offset: [0, 10],
                        },
                      },
                      {
                        name: "flip",
                        enabled: false,
                      },
                      {
                        name: "preventOverflow",
                        options: {
                          padding: 16,
                        },
                      },
                    ]}
                    onChange={this.endDate}
                    popperClassName="example-datepicker-class"
                    selected={this.state.endDate}
                  />
                </span>
                <span className="icon">
                  <img src={calendar} alt={"calendar"} />
                </span>
              </div>
              </Box>
              <Box style={webStyle.hyphen}>
                <Typography>&nbsp;</Typography>
                {"-"}
              </Box>
              <Box style={webStyle.fromDate}>
                <Typography
                  data-test-id="spanTag"
                  style={webStyle.dateBox}
                >
                  {configJSON.toDateLabel}
                </Typography>
                <div className="DateContainers">
                  <span className="dateInput">
                    {" "}
                    <DatePicker
                      id="dateStart"
                      placeholderText={configJSON.dateFormat}
                      popperPlacement="auto"
                      popperModifiers={[
                        {
                          name: "offset",
                          options: {
                            offset: [0, 10],
                          },
                        },
                        {
                          name: "flip",
                          enabled: false,
                        },
                        {
                          name: "preventOverflow",
                          options: {
                            padding: 16,
                          },
                        },
                      ]}
                      onChange={this.startDate}
                      popperClassName="example-datepicker-class"
                      selected={this.state.startDate}
                    />
                  </span>
                  <span className="icon">
                    <img src={calendar} alt={"calendar"} />
                  </span>
                </div>
              </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
              <Box style={webStyle.box}>
              <Box className={classes.textStyle}>
              <Typography>&nbsp;</Typography>
              {configJSON.orText}
              </Box>
            <Box style={webStyle.rangeWidth}>
              <Typography
                data-test-id="spanTag"
                style={webStyle.dateBox}
              >
                {configJSON.customRangeText}
              </Typography>
              <FormControlTwo fullWidth={true}>
                <Select
                  data-test-id="selectStoreDropdown"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  placeholder={configJSON.selectSku}
                  value={this.state.customDateRange}
                  style={webStyle.customPadding}
                  IconComponent={CustomExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: webStyle.range,
                    },
                  }}
                >
                  <MenuItemTwo value={this.state.customDateRange}>
                    <Box style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '90px'}}>
                      {configJSON.lastOneMonth}
                    </Box>
                  </MenuItemTwo>
                </Select>
              </FormControlTwo>
            </Box>
            </Box>
            </Grid>
          </Box>
          </Grid>
          </Grid>
          <Box sx={webStyle.searchContainer}>
            <Typography
              style={webStyle.dateBox}
            >
              {configJSON.searchFees}
            </Typography>
            <Grid container style={styles.FbbSearch} spacing={2}>
              <Grid
                item
                xs={12}
                lg={8}
                md={8}
                sm={12}
                style={webStyle.searchBoxWrapper}
              >
                <CustomizeInputSearchBox
                  data-test-id="EnterBBIC"
                  id="outlined-start-adornment"
                  value={this.state.searchRequestId}
                  onChange={this.handleSearchRequestFBB}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={webStyle.rightMargin}
                      ></InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={webStyle.rightPadding}
                      >
                        <SearchIconSVG />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Box pl={"24px"}>
                <OrangeButton style={{ padding: "8px 28px" }}>
                  {configJSON.searchText}
                </OrangeButton>
              </Box>
            </Grid>
          </Box>
          <Box>
            <Box>
              <h2>{configJSON.showResultsText}'BE1234567898'</h2>
            </Box>
            <Box>
              <CustomizeTextField
                data-test-id="emailRef"
                style={{ marginTop: "10px", width: "66.4%" }}
                value={"AED 4198.00"}
                id="outlined-start-adornment"
                inputProps={{
                  readOnly: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  )
                }}
              />
            </Box>
            <Box mt={"6px"}>
              <Typography
                style={webStyle.dateBox}
              >
                {" "}
                {configJSON.includesStorageCostText}
              </Typography>
            </Box>
          </Box>
        </StockTabPanel>
        <StockTabPanel
        data-test-id="stock1"
          value={this.state.tabValue}
          index={1}
          style={webStyle.color}
        >
          <Grid container>
            <Grid item xs={12} style={webStyle.textWrapper}>
              <Box data-test-id="component" sx={webStyle.requestText}>{configJSON.raiseDisputeText}</Box>
            </Grid>
          </Grid>
          <Box sx={webStyle.searchContainer}>
            <Typography style={webStyle.fieldLabel}>
              {configJSON.bbicNumber}
            </Typography>
            <Grid container style={styles.storageHeader}>
              <Grid
                item
                xs={12}
                lg={9}
                md={8}
                sm={12}
                style={webStyle.searchBoxWrapper}
              >
                <CustomizeInputSearchBox
                  data-test-id="bibcNumber"
                  id="outlined-start-adornment"
                  value={this.state.bibcNumber}
                  onChange={this.handleBibcNumber}
                  placeholder={configJSON.bbicPlaceholderText}
                  className={classes.inputBox}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={webStyle.rightMargin}
                      ></InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={webStyle.searchContainer}>
            <Typography style={webStyle.fieldLabel}>{configJSON.sellerSKULabel}</Typography>
            <Grid container style={styles.storageHeader}>
              <Grid
                item
                xs={12}
                lg={9}
                md={8}
                sm={12}
                style={webStyle.searchBoxWrapper}
              >
                <CustomizeInputSearchBox
                data-test-id="skuId"
                  id="outlined-start-adornment"
                  className={classes.inputBox}
                  value={this.state.sellerSKUId}
                  onChange={this.handleSellerSKu}
                  placeholder={configJSON.sellerSkuPlaceholder}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={webStyle.rightMargin}
                      ></InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={webStyle.searchContainer}>
            <Typography style={webStyle.fieldLabel}>
              {configJSON.typeOfReqLabel}
            </Typography>
            <Grid container style={styles.storageHeader}>
              <Grid
                item
                xs={12}
                lg={9}
                md={8}
                sm={12}
                style={webStyle.searchBoxWrapper}
              >
                <FormControlTwo fullWidth={true}>
                  <Select
                  data-test-id="typeOfReq"
                    name="selectedRole"
                    labelId="demo-simple-select-outlined-label"
                    id="role-select-dropdown-csv-popup"
                    className={classes.outline}
                    inputProps={{
                      'aria-label': 'Without label',
                      classes: {
                        select: classes.selectStyle 
                      }
                    }}
                    value={this.state.typeOfRequest}
                    onChange={this.handleChangeMenuItem}
                    IconComponent={CustomExpandMoreIcon}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        style: webStyle.selectReq,
                      },
                    }}
                    displayEmpty
                  >
                    <MenuItemTwo value="" disabled className={classes.menu}>{configJSON.selectReqType}</MenuItemTwo>
                    <MenuItemTwo value={10} className={classes.menu}>{configJSON.sellerFeeDispute}</MenuItemTwo>
                    <MenuItemTwo value={20} className={classes.menu}>{configJSON.storageFeeDispute}</MenuItemTwo>
                    <MenuItemTwo value={40} className={classes.menu}>{configJSON.fulfillmentFeeDispute}</MenuItemTwo>
                    <MenuItemTwo value={30} className={classes.menu}>{configJSON.otherText}</MenuItemTwo>
                  </Select>
                </FormControlTwo>
              </Grid>
            </Grid>
          </Box>
          <Box sx={webStyle.searchContainer}>
            <Typography style={webStyle.fieldLabel}>
              {configJSON.descriptionLabel}
            </Typography>
            <Grid container style={styles.storageHeader}>
              <Grid
                item
                xs={12}
                lg={9}
                md={8}
                sm={12}
                style={webStyle.searchBoxWrapper}
              >
                <CustomizeInputSearchBox
                data-test-id="description"
                  id="outlined-start-adornment"
                  value={this.state.description}
                  onChange={this.handleDescription}
                  placeholder={configJSON.descriptionPlaceholder}
                  fullWidth
                  rows={3}
                  multiline
                  className={classes.inputBox}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={webStyle.rightMargin}
                      ></InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            style={webStyle.button}
          >
            <OrangeButton>{configJSON.ReturnAndWarrenty.submitButton}</OrangeButton>
          </Box>
        </StockTabPanel>
      </Box>
    );
  }

  // Customizable Area End
}

// Customizable Area Start
const styles = {
  storageWrapper: {
    backgroundColor: "rgb(242,243,244)",
    padding: "0 0 15px 0",
  },
  tabsLabel: {
    textTransform: "capitalize" as const,
    fontSize: "14px",
    fontWeight: 700 as const,
    color: "#727272",
  },
  bckGround: {
    backgroundColor: "white",
  },
  storageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  FbbSearch:{
    display: "flex",
    alignItems: "center",
  },
  spaceWrapper: {
    border: "0.5px solid rgba(101, 122, 142, 0.320241)",
    marginTop: "60px",
    padding: "20px",
    width: "40%",
    height: "400px",
    borderRadius: "8px",
  },
  totalStorageText: {
    fontSize: "22px",
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`,
  },
  inputBox: {
    opacity: 'initial',
    fontFamily: `"Lato, sans-serif !important` as const,
    color: '#333333 !important',
    fontSize:'14px !important',
    "& .MuiInputBase-input::placeholder": {
      fontFamily: `"Lato", sans-serif !important` as const,
      color: '#333333 !important',
      fontSize:'14px !important',
      opacity: 'initial'
    }
  },
  selectStyle: {
    fontFamily: `"Lato", sans-serif !important` as const,
    color: '#333333 !important',
    fontSize:'14px !important',
    opacity: 'initial',
  },
  menu: {
    fontFamily: `"Lato", sans-serif !important` as const,
    color: '#33333a !important',
    fontSize:'14px !important',
  },
  textWrapper : {
    display: "flex", 
    justifyContent: "space-between", 
    alignItems: "center"
},
byezzyText : {
    padding: "20px 0px 15px 0px", 
    fontSize: "18px", 
    fontWeight: 400 as const, 
    fontFamily: `"Lato", sans-serif` as const
},
textStyle: { 
  margin: '12px',
  textAlign:'center' as const,
  width: "12%"
},
  outline: {
      borderColor: "#dde3e8 !important",
    },
};

const webStyle = {
  container: {
    display: "flex",
    justifyContent: "space-between",
  },

  textWrapper: {
    width: "100%",
    marginBottom: 10,
  },

  requestText: {
    fontSize: "24px",
    fontWeight: 700 as const,
    fontFamily: `"Lato", sans-serif` as const,
  },
  searchContainer: {
    padding: "20px 0px 15px 0px",
  },
  searchOrderText: {
    color: "#33333A",
    fontSize: "12px",
    fontWeight: 400 as const,
    fontFamily: `"Lato-Semibold", sans-serif` as const,
    marginBottom:'4px'
  },
  searchBoxWrapper: {
    width: "65%",
    position: "relative" as const,
  },
  options: {
    padding: "7px 23px",
    color: "#657A8E",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 16,
    fontWeight: 500 as const,
    border: "1px solid rgba(101, 122, 142, 0.320241)",
    borderRadius: 5,
    backgroundColor: "rgb(242,243,244)",
  },

  dateWrapper: {
    display: 'block',
    alignItems: 'flex-start'
  },
  detailsTableHead: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  checkBox: {
    marginRight: '20px'
  },
  tableContainer: {
    border: '0.5px solid #ccc',
    borderRadius: '8px'
  },
  tableCellContent: {
    display: 'flex',
    alignItems: 'flex-start',
    borderBottom: '0'
  },
  tabs: {
    paddingLeft: "30px"
  },

  tabsLabel: {
    textTransform: 'capitalize' as const,
    fontSize: '14px',
    fontWeight: 700 as const,
    color: '#727272'
  },
  headerContainerFees: {
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row" as const,
    padding: "30px",
    background: "white"
  },

  text: {
    fontSize: "14px",
    fontWeight: 500 as const,
    fontFamily: `"Lato", sans-serif` as const,
    color: '#747474'
  },
  customPadding: {
    padding: '6px 0 6px'
  },
  wrapper: { overflowY: "scroll"  as const},
  tabStyle: {
    backgroundColor: "rgb(255,96,8)",
    top: 0,
  },
  color: { 
    backgroundColor: "white" 
  },
  rightMargin: {
    marginRight: '20px'
  },
  selectReq: {
    background: "white",
    border: "1px solid #b1b3b5",
    boxShadow: "none",
    borderRadius: 5,
    marginTop: "5px",
    padding: 0,
    maxHeight: "400px",
    overflowY: "auto" as const,
  },
  button: {
    marginTop: "60px",
    display: "flex",
    justifyContent: "flex-end",
  },
  dateBox: {
    color: "#9a9a9a",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 400,
    fontSize: 12,
    marginBottom: "5px"
  },
  range: {
    background: "white",
    border: "1px solid #b1b3b5",
    boxShadow: "none",
    borderRadius: 5,
    marginTop: "5px",
    padding: 0,
    maxHeight: "400px",
    overflowY: "auto" as const,
  },
  rightPadding: { paddingRight: 20 },
  box: {
    display:'flex', 
    width:'100%'
  },
  rangeWidth: {
    width: "88%"
  },
  fromDate: {
    width:'45%'
  },
  hyphen: {
    textAlign:'center' as const, 
    marginTop:'12px', 
    width:'10%'
  },
  fieldLabel: {
    color: "#9a9a9a",
    fontSize: "12px",
    fontWeight: 600 as const,
    fontFamily: `"Lato-Semibold", sans-serif` as const,
    marginBottom:'4px'
  },
};
const CustomizeTextField = styled(TextField )({
 
  "& .MuiInputBase-input": {
    color: "#333333",
    fontSize:'14px',
    paddingLeft:'10px'
  },
  "& .MuiInput-formControl": {
    border: "1px solid #DDE3E8",
    margin: 0,
    padding: "10px 0px",
    borderRadius: "5px",
    backgroundColor:'#f4f4f4'
  },
  "& .MuiInputLabel-root": {
    top: -20,
    left: 0,
    color: "black",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 600,
    fontSize: 14
  },
  "& .MuiInput-underline:before, & .MuiInput-underline:after": {
    borderBottom: "none"
  },
  "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
    borderBottom: "none" 
  },
  "& .MuiInputBase-input::placeholder": {
    fontSize: 14 
  }
});
export { FBBFeesAndDisputes };
export default withStyles(styles)(FBBFeesAndDisputes);
// Customizable Area End