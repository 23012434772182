import { Button, LinearProgress, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import "typeface-lato";

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 5,
    borderRadius: 1.5,
  },
  colorPrimary: {
    backgroundColor: "#c6c6c6",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#ff600a',
  },
}))(LinearProgress);

export const UploadLinearProgress = withStyles((theme) => ({
  root: {
    height: 5,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#e0e0e0",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#58c9fe',
  },
}))(LinearProgress);

export const CustomTextField = withStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      padding: "10px 0px",
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
      borderBottom: '1px solid #D8D8D8', // Remove the underline
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'black', // Change the label font color to black when focused
    },
    '&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after': {
      borderBottom: '1px solid #D8D8D8', // Remove the underline on hover
    },
    '& .MuiInputLabel-root': {
      color: '#575656', // Change the label font color to black
      fontSize: 14,
    },
    '& .MuiInputBase-input, & .MuiInput- input': {
      fontSize: 16,
      color: "#232529",
    },
  },
}))(TextField)

export const CustomIBANTextField = withStyles(() => ({
  root: {
    fontSize: "16px",
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
      borderBottom: '1px solid #D8D8D8', // Remove the underline
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'black', // Change the label font color to black when focused
    },
    '&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after': {
      borderBottom: '1px solid #D8D8D8', // Remove the underline on hover
    },
    '& .MuiInputLabel-root': {
      color: '#6f6e6e', // Change the label font color to black
      fontSize: 14,
      fontWeight: 600,
    },
    '& .MuiInputBase-input, & .MuiInput- input': {
      fontSize: 16,
      color: "black"
    }
  },
}))(TextField)

export const CustomizedButton = withStyles(() => ({
  root: {
    background: "rgba(253, 97, 12, 1)",
    fontFamily: `"Helvetica", "Arial", sans-serif`,
    textTransform: "none",
    fontSize: 18,
    fontWeight: 700,
    width: "fit-content",
    padding: '15px 150px',
    borderRadius: '8px',
    color: "white",
    '&:hover': {
      backgroundColor: 'rgba(253, 97, 12, 1)',
    },
  },
}))(Button)