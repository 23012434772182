// Customizable Area Start
import React from "react";
import SellerSidebarController, { Props, configJSON, configJSON2 } from "./SellerSidebarController.web";
import { Box, Grid } from "@material-ui/core";
import { CustomScrollbar } from "./CustomizedElementStyle";
import { ClickableBox } from "../../otp-input-confirmation/src/CustomElementStyling";
// Customizable Area End

class SellerSidebar extends SellerSidebarController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  fulfilledByEzzyTabs = () => {
    return (
      <>
        <Box sx={{ ...webStyle.listFontStyle, ...(this.props.isByEzzyActive && webStyle.activePanel) }}>{configJSON.fulfilledByByEzzyText}</Box>
        <ClickableBox data-test-id="orderStatus" onClick={() => this.handleChangeMenu(configJSON.byezzyOrdersPath)}  sx={{ ...webStyle.listFontStyle, ...webStyle.listButton, ...(this.props.activeMenu === configJSON.byezzyOrdersPath && webStyle.activePanel) }}>
          {configJSON2.orderStatusLabel}
        </ClickableBox>
        <ClickableBox data-test-id="stockMgmt" onClick={() => this.handleChangeMenu(configJSON.storagePath)} sx={{ ...webStyle.listFontStyle, ...webStyle.listButton, ...((this.props.activeMenu === configJSON.storagePath || this.props.activeMenu === configJSON.createStockReqPath) && webStyle.activePanel) }}>
          FBB Stock Mgmt & St...
        </ClickableBox>
        <ClickableBox data-test-id="fbbFees" onClick={() => this.handleChangeMenu("fees-disputes")} sx={{ ...webStyle.listFontStyle, ...webStyle.listButton, ...(this.props.activeMenu === "fees-disputes" && webStyle.activePanel) }}>
          FBB Fees and Disputes
        </ClickableBox>
        <ClickableBox data-test-id="fbbDamages" onClick={() => this.handleChangeMenu("damages-returns")} sx={{ ...webStyle.listFontStyle, ...webStyle.listButton, ...(this.props.activeMenu === "damages-returns" && webStyle.activePanel) }}>
          Damages and Returns
        </ClickableBox>
      </>
    );
  };

  otherTabs = () => {
    return (
      <>
        <Box sx={{ ...webStyle.listHeader, ...webStyle.headerColor }}>
          {configJSON.otherTab}
          <Box style={webStyle.box}></Box>
        </Box>
        <ClickableBox data-test-id="contacUs" onClick={() => this.handleChangeMenu(configJSON.contactUsTab)} sx={{ ...webStyle.listFontStyle, ...(this.props.activeMenu === configJSON.contactUsTab && webStyle.activePanel) }}>
          {configJSON2.supportLabel}
        </ClickableBox>
        <ClickableBox data-test-id="termsCondition" onClick={() => this.handleChangeMenu(configJSON.termConditionsPath)} sx={{ ...webStyle.listFontStyle, ...(this.props.activeMenu === configJSON.termConditionsPath && webStyle.activePanel) }}>
          {configJSON.termsConditionsTab}
        </ClickableBox>
        <ClickableBox data-test-id="privacyPolicy" onClick={() => this.handleChangeMenu(configJSON.privacyPolicyPath)} sx={{ ...webStyle.listFontStyle, ...(this.props.activeMenu === configJSON.privacyPolicyPath && webStyle.activePanel) }}>
          {configJSON.privacyPolicyTab}
        </ClickableBox>
      </>
    );
  };

  fulfilledByPartnerTabs = () => {
    return (
      <>
        <Box data-test-id="fulFilledByPartner" sx={{ ...webStyle.listFontStyle, ...(this.props.isPartnerActive && webStyle.activePanel) }}>
          {configJSON2.fulfilledByPartnerLabel}
        </Box>
        <ClickableBox data-test-id="returns" onClick={() => this.handleChangeMenu(configJSON.returnsPath)} sx={{ ...webStyle.listFontStyle, ...webStyle.listButton, ...((this.props.activeMenu === configJSON.returnsPath || this.props.activeMenu === configJSON.returnDetailsPath) && webStyle.activePanel) }}>
          {configJSON2.returnsLabel}
        </ClickableBox>
        <ClickableBox data-test-id="orderManagement" onClick={() => this.handleChangeMenu(configJSON.partnerOrdersPath)} sx={{ ...webStyle.listFontStyle, ...webStyle.listButton, ...((this.props.activeMenu === configJSON.partnerOrdersPath || this.props.activeMenu === configJSON.partnerOrderDetailsPath) && webStyle.activePanel) }}>
          {configJSON2.orderManagementTab}
        </ClickableBox>
        <ClickableBox data-test-id="warehouse" onClick={this.props.handleWarehouseMenu} sx={{ ...webStyle.listFontStyle, ...webStyle.listButton, ...((this.props.activeMenu === configJSON.warehousePath || this.props.activeMenu === configJSON.warehouseListPath || this.props.activeMenu === configJSON.createWarehousePath) && webStyle.activePanel) }}>
          {configJSON2.wareHouseLabel}
        </ClickableBox>
        <ClickableBox data-test-id="stockLogs" onClick={() => this.handleChangeMenu(configJSON2.stocklogsPath)} sx={{ ...webStyle.listFontStyle, ...webStyle.listButton, ...(this.props.activeMenu === configJSON2.stocklogsPath && webStyle.activePanel) }}>
          {configJSON2.stockLogsLabel}
        </ClickableBox>
        <ClickableBox data-test-id="deliveryReq" onClick={() => this.handleChangeMenu(configJSON2.requestListPath)} sx={{ ...webStyle.listFontStyle, ...webStyle.listButton, ...((this.props.activeMenu === configJSON2.deliveryReqPath || this.props.activeMenu === configJSON.requestListPath || this.props.activeMenu === configJSON.createDeliveryReqPath) && webStyle.activePanel) }}>
          {configJSON2.deliveryReqLabel}
        </ClickableBox>
      </>
    );
  };

  getBrandPath = () => {
    return this.props.activeMenu === configJSON.brandManagementMenu || this.props.activeMenu === configJSON.brandCatalogMenu || this.props.activeMenu === configJSON.storePath || this.props.activeMenu === configJSON.createStorePath || this.props.activeMenu === configJSON.brandCatalogPath || this.props.activeMenu === configJSON.createTabsPath;
  };

  addSingleHomeActive = () => {
    const activeMenus = [configJSON.addSingleHomeMenu, configJSON.singleProductSelCategoryPath, configJSON.searchBrandMenu, configJSON.searchProductPath, configJSON.addProductPath];

    return activeMenus.includes(this.props.activeMenu);
  };

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Grid item style={webStyle.sidebarContainer}>
          <CustomScrollbar style={webStyle.scrollBar}>
            <Box sx={{ ...webStyle.listHeader, ...webStyle.headerColor }}>
              {configJSON.servicesText}
              <Box style={webStyle.box}></Box>
              <Box style={webStyle.boxTwo}></Box>
            </Box>
            <Box sx={{ ...webStyle.listFontStyle, ...(this.props.isPartnerCatalogActive && webStyle.activePanel) }}>{configJSON.productCatalogueText}</Box>
            <ClickableBox
              data-test-id="addSingleRef"
              onClick={() => this.handleChangeMenu("products")}
              sx={{
                ...webStyle.listFontStyle,
                ...webStyle.listButton,
                ...(this.props.navigation.getParam("path") !== configJSON.discountOffersPath && this.addSingleHomeActive() && webStyle.activePanel)
              }}
            >
              {configJSON.addSingleProductText}
            </ClickableBox>
            <ClickableBox
              data-test-id="bulkProductUploadRef"
              onClick={() => this.handleChangeMenu(configJSON.bulkSelectCategoryPath)}
              sx={{ ...webStyle.listFontStyle, ...webStyle.listButton, ...((this.props.activeMenu === configJSON.bulkSelectCategoryPath || this.props.activeMenu === configJSON.bulkUploadSearchBrandPath || this.props.activeMenu === configJSON.bulkUploadDownloadTempId || this.props.activeMenu === configJSON.bulkUploadFilePath) && webStyle.activePanel) }}
            >
              {configJSON.bulkProductUploadTab}
            </ClickableBox>

            {this.fulfilledByEzzyTabs()}
            {this.fulfilledByPartnerTabs()}
            <ClickableBox data-test-id="promocodeCouponsRef" onClick={() => this.handleChangeMenu(configJSON.discountOffersPath)} sx={{ ...webStyle.listFontStyle, ...(this.props.navigation.getParam("path") === configJSON.discountOffersPath && webStyle.activePanel) }}>
              {configJSON.promotionAdsTab}
            </ClickableBox>
            <ClickableBox data-test-id="brandManagement" onClick={() => this.handleChangeMenu(configJSON.brandManagementMenu)} sx={{ ...webStyle.listFontStyle, ...(this.getBrandPath() && webStyle.activePanel) }}>
              {configJSON.brandManagementText}
            </ClickableBox>
            <ClickableBox data-test-id="brandMngRef" onClick={() => this.handleChangeMenu(configJSON.brandCatalogPath)} sx={{ ...webStyle.listFontStyle, ...webStyle.listButton, ...(this.props.activeMenu === configJSON.brandCatalogPath && webStyle.activePanel) }}>
              {configJSON2.brandCatalogText}
            </ClickableBox>
            <ClickableBox data-test-id="storeManagement" onClick={() => this.handleChangeMenu(configJSON.storePath)} sx={{ ...webStyle.listFontStyle, ...webStyle.listButton, ...((this.props.activeMenu === configJSON.storePath || this.props.activeMenu === configJSON.createStorePath || this.props.activeMenu === configJSON.createTabsPath) && webStyle.activePanel) }}>
              {configJSON.storeManagementTab}
            </ClickableBox>
            <ClickableBox data-test-id="happinessRef" onClick={() => this.handleChangeMenu(configJSON.happinessRatingTab)} sx={{ ...webStyle.listFontStyle, ...(this.props.activeMenu === configJSON.happinessRatingTab && webStyle.activePanel) }}>
              {configJSON2.customerHappinessIndicatorTab}
            </ClickableBox>
            <ClickableBox data-test-id="analytics" onClick={() => this.handleChangeMenu(configJSON.analyticsPath)} sx={{ ...webStyle.listFontStyle, ...(this.props.activeMenu === configJSON.analyticsPath && webStyle.activePanel) }} >
              {configJSON.analyticsReportsTab}
            </ClickableBox>
            {this.otherTabs()}
          </CustomScrollbar>
        </Grid>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  sidebarContainer: {
    width: "100%",
    padding: "0px 30px 0px 30px",
    margin: "-20px 0 -40px"
  },
  scrollBar: {
    maxHeight: "calc(100vh - 83px)",
    overflowX: "hidden" as const,
    margin: "-10px 0 -20px",
    overflowY: "auto" as const
  },
  headerColor: {
    color: "#BCBCBC",
    position: "relative" as const
  },
  box: {
    position: "absolute" as const,
    width: "4px",
    height: "35px",
    top: 8,
    right: -30,
    borderRadius: "5px 0px 0px 5px",
    background: "#FF6008"
  },
  boxTwo: {
    width: "18px",
    height: "1px",
    position: "absolute" as const,
    left: -30,
    background: "rgb(221 219 219)",
    top: 24
  },
  mainWrapper: {
    display: "flex",
    background: "#fff",
    width: "100%",
    height: "100vh",
    overflow: "hidden"
  },
  listButton: {
    paddingLeft: "40px"
  },
  listHeader: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#666666",
    fontFamily: `"Lato", sans-serif`,
    width: "100%",
    padding: "17px 0px 17px 0px"
  },
  activePanel: {
    color: "#000000",
    fontWeight: 700
  },
  listFontStyle: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#666666",
    fontFamily: `"Lato", sans-serif`,
    width: "100%",
    padding: "17px 0px 17px 15px"
  }
};

export default SellerSidebar;
// Customizable Area End
