import React from "react";

// Customizable Area Start
import { theme } from "../../dashboard/src/CustomizedElementStyle";
import { ThemeProvider } from "@material-ui/core/styles";
import { Box, Container } from "@material-ui/core";
import CommonNav from "./CommonNav.web";
// Customizable Area End

import StaticPageController, { Props } from "./StaticPageController";
import DOMPurify from "dompurify";

class StaticPage extends StaticPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <ThemeProvider theme={theme}>
          {this.state.content.length > 0 && (
            <Box style={{ width: "100%", display: "flex", justifyContent: "center", overflowX: "hidden", overflowY: "auto" }}>
              <Container maxWidth={"lg"}>
                <CommonNav navigation={this.props.navigation} id={"home-contact-us-nav"} />
                <Box sx={{ display: "flex", width: "100%", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", marginBottom: 25, marginTop: 35 }}>
                  <Box sx={{ fontSize: "27px", fontWeight: 600, fontFamily: `"Lato", sans-serif` }}>{this.state.selectedPage}</Box>
                </Box>
                {this.state.content.map((item, index) => {
                  return (
                    <>
                      <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item)}} data-test-id="content">
                      </div>
                      <Box style={{ width: "100%", height: 20 }}></Box>
                    </>
                  );
                })}
              </Container>
            </Box>
          )}
        </ThemeProvider>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default StaticPage;
// Customizable Area End
