import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

// Customizable Area Start
import { getStorageData } from "../../../../framework/src/Utilities";
import { ISuggestionFeedbackResponse } from "./Interfaces";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeCheckbox: string;
  first_name: string;
  last_name: string;
  email: string;
  detail: string;
  errorMessages: string[];
  authToken: string;
  sellerId: string;
  successMsg: string[];
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerSuggestionFeedbackController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiSubmitSuggestionFeedbackCallId: string = "";
  apiGetInfoSellerCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      activeCheckbox: "Suggestion",
      first_name: "",
      last_name: "",
      email: "",
      detail: "",
      errorMessages: [],
      authToken: "",
      sellerId: "",
      successMsg: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.apiSubmitSuggestionFeedbackCallId) {
        this.handleSubmitSuggestionFeedbackResponse(responseJson);
      }
      if (apiRequestCallId === this.apiGetInfoSellerCallId) {
        this.handleSellerInfoResponse(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  handleSellerInfoResponse = (responseJson: unknown) => {
    const successData = responseJson as ISuggestionFeedbackResponse;
    if ("data" in successData) {
      return this.setState({ first_name: successData.data.attributes.first_name, last_name: successData.data.attributes.last_name, email: successData.data.attributes.email });
    }
    return this.setState({ errorMessages: ["Something went wrong. Please try again later!"] });
  };

  handleSubmitSuggestionFeedbackResponse = (responseJson: unknown) => {
    const successData = responseJson as ISuggestionFeedbackResponse;
    if ("data" in successData) {
      this.setState({
        successMsg: [`${this.state.activeCheckbox} has been submitted successfully!`],
        errorMessages: [],
        detail: "",
      });
      setTimeout(() => {
        this.setState({ successMsg: [] });
      }, 2000);
    } else {
      this.setState({
        errorMessages: ["Something went wrong. Please try again later!"],
        successMsg: [],
      });
    }
  };

  handleOnCheckBoxChange = (checkbox: string) => {
    this.setState({ activeCheckbox: checkbox });
  };
  handleInputOnChange = (input: string, value: string) => {
    let errorMessages: string[] = [];
     if (input === "detail") {
      if (value.length > 7598) {
        value = value.substring(0, 7598);
      }
    }
    if (errorMessages.length === 0 && value.length <= 7598) {
      this.setState({ [input]: value, errorMessages: [] } as any);
    } else {
      this.setState({ errorMessages });
    }
  };
  
  handleOnSubmit = () => {
    this.setState({ successMsg: [] });
    const { detail, email, first_name, last_name } = this.state;
    let errorMessages = [];

    if (!detail || !email || !first_name || !last_name) {
        errorMessages.push("Please complete the form!");
    }
    const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>0-9]/;
    if (specialCharsRegex.test(first_name) || specialCharsRegex.test(last_name)) {
      errorMessages.push("Special characters and numbers are not allowed in first name and last name");
    } 
    if (errorMessages.length > 0) {
        this.setState({ errorMessages });
    } else {
        this.setState({ errorMessages: ["Submitting..."] }, () => {
            this.submitSuggestionFeedback();
        });
    }
};

  submitSuggestionFeedback = () => {
    const headers = { token: this.state.authToken, "Content-Type": configJSON.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiSubmitSuggestionFeedbackCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/suggestion_feedbacks");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            detail_type: this.state.activeCheckbox,
            detail: this.state.detail,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
          },
        },
      }),
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSellerInfoProfle = () => {
    const headers = { token: this.state.authToken, "Content-Type": configJSON.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetInfoSellerCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiGetSellerInfoEndpoint + this.state.sellerId);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetIdAndToken = async () => {
    const authToken = await getStorageData("authToken");
    const sellerId = await getStorageData("seller_id");
    if (!authToken || !sellerId) {
      return;
    }
    this.setState({ authToken, sellerId });
    this.getSellerInfoProfle();
  };

  async componentDidMount(): Promise<void> {
    this.handleGetIdAndToken();
  }
  // Customizable Area End
}
