// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { Message } from "framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { IPrivacyNotice } from "../../termsconditions/src/PrivacyNoticeController.web";
export const configJSON = require("./config");

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  whyUsContent: IPrivacyNotice
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class WhyusController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiStaticDataCallId: string = ""; 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      whyUsContent: {
        title: '',
        id: '',
        content: ''
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) ;
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if(apiRequestCallId === this.apiStaticDataCallId) {
        this.handleWhyusStaticResponse(responseJson)
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  handleWhyusStaticResponse = (responseJson:IPrivacyNotice[]) => {
    if(responseJson.length) {
      this.getWhyusContent(responseJson) 
    }
  }

  getWhyusContent = (responseJson: IPrivacyNotice[]) => {
    const whyUsContent = responseJson.filter((item:IPrivacyNotice) => {
      if(item.title === "Why us?") {
          return item
      }
    })
    console.log("Why us", whyUsContent)
    if(whyUsContent.length) {
      this.setState({whyUsContent : whyUsContent[0]})
    }
  }

  async componentDidMount(): Promise<void> {
    this.getStaticWhyusData()
  }

  getStaticWhyusData = async() => {
    const headers = { token: await getStorageData("authToken"), "Content-Type": configJSON.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiStaticDataCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.staticApiEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
