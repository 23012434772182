import React from "react";

// Customizable Area Start
import { Box, Grid, InputAdornment, Typography } from "@material-ui/core";
import { AddNewButton, CustomizeBrandInput, OrangeButton } from "../CustomizedElementStyle";
import { check } from "../assets";
import { errorImage } from "../../../bulkuploading/src/assets";
// Customizable Area End

import BarcodeController, { Props } from "./BarcodeController";

export default class Barcode extends BarcodeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <Box style={webStyle.box}>
          <Grid container spacing={5}>
            <Grid item lg={6} sm={12} xs={12}>
              <CustomizeBrandInput
                data-test-id="barcode"
                label="Enter bar code"
                id="outlined-start-adornment"
                value={this.state.bar_code}
                onChange={event => this.handleBarcodeOnChange(event.target.value)}
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start" style={webStyle.margin}></InputAdornment>
                }}
              />
              {this.state.barcodeErr ? 
              <Typography style={webStyle.errorStyle}>{this.state.barcodeErr}</Typography>: ''}
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
              <AddNewButton data-test-id="addNew" onClick={this.handleAddNewBarcode}>
                Add new
              </AddNewButton>
            </Grid>
          </Grid>
          <Grid container spacing={5} direction="column">
            <Grid item lg={6} sm={12} xs={12}>
              {this.state.successCreatingBarcode && (
                <Box style={webStyle.successBox}>
                  <img src={check} style={webStyle.checkImg}></img>
                  <Box style={webStyle.boxOne}>{this.state.bar_code}</Box>
                </Box>
              )}
            </Grid>
            {this.state.errorMsg.map((message, index) => {
              return (
                <Grid item lg={6} sm={12} xs={12} key={index}>
                  <Box style={webStyle.errorBox} key={index}>
                    <Box style={webStyle.marginTwo}>
                      <img src={errorImage} />
                    </Box>
                    <Box>
                      <Typography variant="body2" style={webStyle.errorStyle}>
                        {message}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <OrangeButton
            data-test-id="submit"
            onClick={this.handleOnSubmit}
            style={{
              right: 30,
              bottom: 30
            }}
          >
            Submit
          </OrangeButton>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  errorStyle: {
    color: "#D74D4D", 
    fontSize:'12px', 
    fontFamily:"Lato, sans-serif"
  },
  box: { 
    marginTop: 50, 
    padding: "30px" 
  },
  margin: { 
    marginRight: 5 
  },
  successBox: { 
    height: 45, 
    background: "#d6f9c5", 
    borderRadius: 8, 
    display: "flex", 
    alignItems: "center", 
    padding: "0px 20px" 
  },
  checkImg: { 
    mixBlendMode: "darken" as const
  },
  boxOne: { 
    color: "#42B400", 
    fontFamily: `"Lato", sans-serif`, 
    fontSize: 14, 
    fontWeight: 500 
  },
  errorBox: { 
    flexWrap: "nowrap" as const, 
    display: "flex", 
    paddingTop: "10px", 
    flexDirection: "row" as const, 
    alignItems: "center" 
  },
  marginTwo: { 
    marginRight: "10px" 
  }
}
// Customizable Area End
