Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.formContentType = 'multipart/form-data';
exports.apiUploadDocumentEndpoint = 'account_block/accounts/';
exports.docVerification = 'account_block/accounts/document_verification_email';
exports.postMethod = 'POST';
exports.getMethod = 'GET';
exports.patchMethod = 'PATCH';
// Customizable Area End