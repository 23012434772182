import { Box, Typography, Button, Radio, withStyles, Modal, Theme, Grid } from "@material-ui/core";
import React from "react";
import { Divider } from "react-native-elements";
import UserAddressListController, { Props, configJSON } from "./UserAddressListController";
import { deleteIcon, editIcon } from "./assets";
import {styledAddress as UserAddressForm} from "./UserAddress.web";
import {StyledAddAddress as AddAddress} from "./AddAddress.web"
import { CustomBackButton, CustomDeleteButton } from "../../dashboard/src/CustomizedElementStyle";
import { address } from "../../catalogue/src/ShoppingCart/commomstyles";
import  {Loader2} from "../../../components/src/Loader.web";


export default class UserAddress extends UserAddressListController {
    [x: string]: any;
    isValid: any;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    DeleteVerifyModal1 = () => {
        return (
            <Modal open={this.state.showVerifyDeleteModal1}>
                <Box sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center " }} style={{ background: "rgba(0,0,0,0.1)" }}>
                    <Box sx={{ width: "300px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", borderRadius: "8px", padding: "50px" }} style={{ background: "white" }}>
                        <Box sx={{ marginBottom: "20px", textAlign: "center", fontFamily: `"Lato", sans-serif` }}>Are you sure you want to delete the address?</Box>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <CustomBackButton data-test-id="backRef" onClick={() => this.handleCloseDeleteModal()}>
                                Back
                            </CustomBackButton>
                            <CustomDeleteButton data-testid="deleteConfirmRef" onClick={()=>this.handleDelete()}>
                                Delete
                            </CustomDeleteButton>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        );
    };

    personalDetails() {
        const { userAddresList } = this.state
        
        return (
            <>
                {userAddresList.length>0 ?
                <Box>
                    {this.DeleteVerifyModal1()}
                    <Grid container style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "25px" }}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Typography variant="h4" className={this.props.classes.headerText}>My Addresses</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} className={this.props.classes.saveBtnBox}>
                       
                            <Button
                                className={this.props.classes.saveBtn}
                                data-testid="saveButton"
                                onClick={() => this.handleAdNewAddress()}

                            >
                                Add new address
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider style={{ ...webStyle.horizontalBar }} />
                    <Box style={{ marginTop: "30px", paddingRight: "10%", paddingBottom: "25%" }}>
                  
                        {(userAddresList.length > 0 ? userAddresList : []).map((item: any, index: number) => {
                            return (
                                <>
                                    <Grid container>
                            <Grid item xs={12} sm={6} md={7} lg={7}  className={this.props.classes.cellData}>
                              <Box>
                              <Radio
                                name="address"
                                data-test-id="address"
                                className={this.props.classes.radioBtn}
                                checked={item.attributes.is_default}
                                onChange={() =>
                                  this.handleChangeAdd(item.id)
                                }
                              />
                              </Box>
                            <Box className={this.props.classes.wrapAddress}>
                              <Typography className={this.props.classes.userName}>
                                {item.attributes.first_name} {item.attributes.last_name}
                              </Typography>
                              <Typography className={this.props.classes.addressData}>
                                {item.attributes.address_1} {item.attributes.address_2}, {item.attributes.city} ,{item.attributes.phone_number} {`(${item.attributes.state})`}
                              </Typography>
                            </Box>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} lg={2}></Grid>
                            <Grid item xs={12} sm={4} md={3} lg={3} className={`${this.props.classes.buttonContainer} ${this.props.classes.btnWrap}`}>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={this.props.classes.buttonContainer}>
                                <Box data-test-id="deleteIcon" onClick={() => this.handleOpenVerifyDeleteModal(item.id, item.attributes.is_default)} className={this.props.classes.buttonContainer}>
                            <img src={deleteIcon} alt="deleteIcon" className={this.props.classes.deleteIcon} />
                                <Typography className={this.props.classes.deleteText}>{configJSON.deleteBtnLabel}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={this.props.classes.editContainer}>
                                <Box className={this.props.classes.buttonContainer} onClick={() => this.editAddress(item)}>
                            <img src={editIcon} alt="editIcon" className={this.props.classes.deleteIcon} />
                                <Typography className={this.props.classes.editText} >{configJSON.editBtnLabel}</Typography>
                                
                                </Box>
                            </Grid>
                            </Grid>
                          </Grid>

                          {index !== userAddresList.length - 1 && <Divider style={webStyle.horizontalAddBar} />}
                                </>)
                        })}
                    </Box>
                </Box>
                :
                <AddAddress navigation={this.props.navigation} id={this.props.id} userId={this.state.userId} match={this.props.match}/>
                }
            </>)
    }
    // Customizable Area End

    // Customizable Area Start
    render() {

        return (
            <>
            {this.state.loading ?
            <Box className={this.props.classes.loader}>
            <Loader2 loading={this.state.loading}/>
            </Box>
            :
            <>
            {this.state.showAddressForm ?
            <UserAddressForm data-test-id="addressForm" navigation={this.props.navigation} id={this.props.id} userId={this.state.userId} singleAddressInfo={this.state.singleAddressInfo} toShow={this.toShowChildComponent} match={this.props.match} setAddress={this.handleAddress}/> : 
            
            this.personalDetails()
            }
            </>
            }

            
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const styles = (theme:Theme) => ({
    headerText : {
        color: "#232529",
        fontWeight: 700,
        fontSize: "32px",
        fontFamily: "Lato, sans-serif",
        [theme.breakpoints.down("md")]: {
            fontSize: "28px"
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "24px"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "22px"
        }
    },
    saveBtn: {
        background: "rgb(253, 97, 12) none repeat scroll 0% 0%",
        fontWeight: 800,
        color: "white",
        borderRadius: "8px",
        textTransform: "none" as const,
        fontFamily: `"Lato", sans-serif`,
        padding:"12px 35px",
        fontSize:'16px',
        "&:hover": {
            backgroundColor: '#ff7f50'
          },
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "12px"
        }
    },
    radioBtn: {
        color: "#FF6008",
        marginRight:'-20px',
        paddingLeft : '0',
        "&.Mui-checked": {
          color: "#FF6008",
        },
      },
      cellData: {
        display: "flex",
        justifyContent: "flex-start",
      },
      wrapAddress: {
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "flex-start",
        justifyContent: "center",
        marginLeft: '22px'
      },
      userName: {
        color: "#33333a",
        wordBreak: 'break-all' as const,
        fontFamily: `"Lato-Semibold", sans-serif`,
        fontSize: "18px",
        fontWeight: 400,
        [theme.breakpoints.down("md")]: {
          fontSize: "16px"
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "14px"
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "12px"
        }
      },
      addressData: {...address,
        wordBreak: 'break-all' as const,
        maxWidth: '500px',
      [theme.breakpoints.down("md")]: {
          fontSize: "14px"
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "12px"
        }
      },
      buttonContainer : {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        cursor: 'pointer',
        [theme.breakpoints.down("xs")]: {
            justifyContent: 'flex-start'
        }
      },
      editContainer:{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        cursor: 'pointer',
        marginRight:'10px',
        [theme.breakpoints.down("xs")]: {
            justifyContent: 'flex-start'
        }
      },
      btnWrap: {
        [theme.breakpoints.down("xs")]: {
            margin:'12px 0 0 35px'
        }
      },
      deleteIcon : {
        paddingRight: '10px',
        [theme.breakpoints.down("xs")]: {
            width: '18px',
            height:'20px'
        }
      },
      deleteText: {
        color: '#e30303',
        fontFamily: `"Lato", sans-serif`,
        fontSize: "14px",
        fontWeight: 400,
        cursor: 'pointer',
        [theme.breakpoints.down("xs")]: {
            fontSize: '12px'
        }
      },
      editText: {
        color: '#147bd1',
        fontFamily: `"Lato", sans-serif`,
        fontSize: "14px",
        fontWeight: 400,
        [theme.breakpoints.down("xs")]: {
            fontSize: '12px'
        }
      },
      text: {
        display:'flex',
        alignItems:'flex-start',
        flexDirection:'column' as const,
        justifyContent: 'space-between'
      },
      saveBtnBox: {
        display: "flex",
        justifyContent: "end",
        [theme.breakpoints.down("xs")]: {
            marginTop:'20px',
            justifyContent: "flex-start",
        }
    },
    loader: {
      left: '50%',
      top: '25%',
      marginTop: '60px',
      position: 'relative' as const,
      width: '50px',
      '@media (max-width:800px)': {
        top: '5%',
        left: '40%'
      }
  }
})

const styledAddressList = withStyles(styles)(UserAddress)
export {styledAddressList}
// Customizable Area End

const webStyle = {


    horizontalBar: {
        // width: "150px",
        height: "1px",
        marginBlock: "10px"
    },

    horizontalAddBar: {
        height: "1px",
        marginBlock: "20px"
    },


    papperClasses: {
        background: '#fff',
        padding: "20px 15px",
        borderRadius: "15px",
    },

    headerTxt: {
        color: "rgb(36, 35, 35)",
        fontWeight: 600,
        fontSize: "2rem",
        fontFamily: "Lato, sans-serif"
    },





}

