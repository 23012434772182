import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("../src/config.js")

interface DropDownValues {
  id: number;
  label: string;
  value: any;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes : {
    storageWrapper: string,
    tabsLabel: string,
    storageHeader: string,
    bckGround: string,
    spaceWrapper: string,
    totalStorageText: string,
    inputBox: string,
    selectStyle: string,
    menu: string,
    textWrapper: string,
    byezzyText: string,
    textStyle: string,
    outline: string
  }
  handlePrintLabel: (value: boolean) => void
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  authToken: string;
  tabValue: number;
  searchRequestId: string;
  customDateRange:string,
  endDate: Date | null,
  startDate: Date | null,
  typeOfRequest:string,
  bibcNumber:string,
  sellerSKUId:string,
  description:string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class FBBFeesAndDisputesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      authToken: "",
      tabValue: 0,
      searchRequestId: "BE1234567898",
      customDateRange: "Last 1 month",
      endDate: null,
      startDate: null,
      typeOfRequest: "",
      bibcNumber:'',
      sellerSKUId:'',
      description:"",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  handleTabsChange = (event: any, value: number) => {
    this.setState({ tabValue: value });
  };

  handleSearchRequestFBB = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchRequestId: event.target.value });
  };

  handleBibcNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({bibcNumber : event.target.value})
  }

  handleSellerSKu = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({sellerSKUId : event.target.value})
  }

  handleDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({description : event.target.value})
  }

  startDate = (date:  Date | null) => {
    this.setState({
      startDate: date,
    });
  };
  endDate = (date:  Date | null) => {
    this.setState({
      endDate: date,
    });
  };

  handleGetTokens = async () => {
    const authToken = await getStorageData("authToken");
    this.setState({ authToken });
  };
  handleChangeMenuItem = (e: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({
      typeOfRequest: e.target.value as string,
    });
  };
  
  async componentDidMount(): Promise<void> {
    this.handleGetTokens();
  }

  setTabBackground = (tabNumber: number) => {
    return this.state.tabValue === tabNumber ? "white" : "rgb(242,243,244)"
  }

  // Customizable Area End
}
