// Customizable Area Start
import React from "react";
import DeliveryRequestListController, {
  IRequestData,
  Props,
  configJSON,
} from "./DeliveryRequestListController.web";
import { Box, Button, Grid, Typography, withStyles } from "@material-ui/core";
import {
  BoxNoScroll,
  OrangeButton,
} from "../../dashboard/src/CustomizedElementStyle";
import { questionImg } from "./assets";
import { Loader2 } from "../../../components/src/Loader.web";
import DeliveryRequestHome from "./DeliveryRequestHome.web";
// Customizable Area End

class DeliveryRequestList extends DeliveryRequestListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.loading && 
          <Loader2 loading={this.state.loading} />
        }
        {!this.state.loading && this.state.reqList.length === 0 ? (
          <DeliveryRequestHome navigation={this.props.navigation} id={this.props.id} /> )  
          : (this.state.reqList.length > 0 &&
          <>
        <Box className={classes.partnerLabel}>
          {configJSON.fulfilledByPartnerLabel}
        </Box>
        <BoxNoScroll className={classes.backGrnd}>
          <Box data-test-id="deliveryRequestList" className={classes.box}>
            <Grid container spacing={2} style={webStyle.padding}>
              <Grid item xs={12} lg={12} md={12} sm={12}>
                <Typography className={classes.mainText}>
                  {configJSON.deliveryReqLabel}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12} md={12} sm={12}>
                <Typography className={classes.paraText}>
                  {configJSON.createNewRequestBtn}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12} md={12} sm={12}>
                <Button onClick={this.handleCreateNewReqBtn} className={classes.createReqBtn}>
                  {configJSON.createNewRequestBtn}
                </Button>
              </Grid>
            </Grid>
            <Box className={classes.divider}></Box>
          </Box>

          <Grid container spacing={2} className={classes.padding}>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              <Typography className={classes.paraText}>
                {configJSON.myDeliveryReqText}
              </Typography>
            </Grid>
          </Grid>
          <Box style={webStyle.listContainer}>
          {this.state.reqList.map((item: IRequestData, index: number) => {
            return (
              <Grid container spacing={2} className={classes.row}>
                <Grid item xs={12} lg={1} md={1} sm={2}>
                  <img src={questionImg} alt="img" />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  md={5}
                  sm={5}
                  className={classes.infoContainer}
                >
                  <Typography className={classes.requestText}>
                    Request {index+1}
                  </Typography>
                  <Typography className={classes.dateTime}>
                    {this.getDateTime(item.attributes.created_at)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  md={4}
                  sm={4}
                  className={classes.infoContainer}
                >
                  <OrangeButton
                    className={this.getClass(item.attributes.status)}
                  >
                    {item.attributes.status}
                  </OrangeButton>
                </Grid>
              </Grid>
            );
          })}
          </Box>
        </BoxNoScroll>
        </>
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  partnerLabel: {
    fontFamily: `"Lato", sans-serif` as const,
    padding: "20px 0px 15px 0px",
    fontSize: "18px",
    fontWeight: 400 as const,
  },
  backGrnd: {
    overflowX: "hidden" as const,
    overflowY: "auto" as const,
    background: "white",
    borderRadius: "8px",
  },
  createReqBtn: {
    marginTop: "10px",
    fontSize: "20px",
    fontFamily: `"Montserrat", sans-serif` as const,
    fontWeight: 700,
    textTransform: "none" as const,
    padding: "16px 24px",
    backgroundColor: "#0fbc9e",
    borderRadius: "8px",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#0fbc8e",
    },
  },
  mainText: {
    fontSize: "27.1px",
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif` as const,
  },
  paraText: {
    color: "#333333",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    fontWeight: 700 as const,
  },
  box: {
    position: "relative" as const,
  },
  divider: {
    width: "calc(100% + 60px)",
    height: 1,
    background: "#EEEEEE",
    position: "absolute" as const,
    left: 0,
    bottom: "-8px",
  },
  row: {
    padding: "10px 30px",
  },
  padding: {
    padding: "40px 30px 20px",
  },
  requestText: {
    color: "#666666",
    fontFamily: `"Lato",sans-serif` as const,
    fontSize: "14px",
    fontWeight: 700,
  },
  yellowBtn: {
    padding: "6px 23px",
    borderRadius: "4px",
    backgroundColor: "#fb8b00",
    fontFamily: `"Montserrat",sans-serif`,
    fontSize: "18px",
    fontWeight: 600 as const,
    textTransform: 'capitalize' as const,
    "&:hover": {
      backgroundColor: "white",
      color: "#fb8b00",
      border: "0.5px solid #fb8b00",
    },
  },
  greenBtn: {
    padding: "6px 23px",
    borderRadius: "4px",
    backgroundColor: "#10bc9e",
    fontFamily: `"Montserrat",sans-serif`,
    fontSize: "18px",
    fontWeight: 600 as const,
    textTransform: 'capitalize' as const,
    "&:hover": {
      backgroundColor: "white",
      color: "#10bc9e",
      border: "0.5px solid #10bc9e",
    },
  },
  redBtn: {
    padding: "6px 23px",
    borderRadius: "4px",
    backgroundColor: "#e84f4f",
    fontFamily: `"Montserrat",sans-serif`,
    fontSize: "18px",
    fontWeight: 600 as const,
    textTransform: 'capitalize' as const,
    "&:hover": {
      backgroundColor: "white",
      color: "#e84f4f",
      border: "0.5px solid #e84f4f"
    },
  },
  dateTime: {
    color: "#999999",
    fontFamily: `"Helvetica",sans-serif`,
    fontSize: "14px",
    fontWeight: 400,
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "flex-start",
    justifyContent: "space-evenly",
  },
};

const webStyle = {
  padding: { 
    padding: "30px" 
  },
  listContainer: {
    marginBottom: '100px'
  }
} 

export { DeliveryRequestList };

export default withStyles(styles)(DeliveryRequestList);
// Customizable Area End
