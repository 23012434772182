// Customizable Area Start
import React from "react";
import { Box, Button, Grid, InputAdornment, Theme, Typography, withStyles } from "@material-ui/core";
import StoreSectionOneTabController, { Props, configJSON } from "./StoreSectionOneTabController.web";
import { ClickableBox } from "../../../mobile-account-login/src/CustomElementStyling";
import { file } from "../assets";
import { CustomizeBrandInput, NextButton } from "../CustomizedElementStyle";
import { errorImage } from "../../../bulkuploading/src/assets";
// Customizable Area End

class StoreSectionOneTab extends StoreSectionOneTabController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  showErr = () => {
    return (
      <>
        {this.state.showErrMsg ? (
          <Box className={this.props.classes.commonErrorBox}>
            <Box className={this.props.classes.errMargin}>
              <img src={errorImage} />
            </Box>
            <Box>
              <Typography className={this.props.classes.errorOne}>{this.state.showErrMsg}</Typography>
            </Box>
          </Box>
        ) : (
          ""
        )}
      </>
    );
  };

  showGridNameErr = () => {
    return this.state.gridNameError ? (
      <Typography data-test-id="gridNameErr" className={this.props.classes.error}>
        {this.state.gridNameError}
      </Typography>
    ) : (
      ""
    );
  };

  renderSection1Grid2 = () => {
    const { classes } = this.props;
    return (
      <>
        <Grid container className={classes.margin} spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.padding}>
            <Typography className={classes.headerTwo}>{configJSON.sectionOneGridTwoText}</Typography>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topMargin}>
          <CustomizeBrandInput
            data-test-id="gridTwoName"
            label={configJSON.gridNameLabel}
            id="outlined-start-adornment"
            value={this.state.gridTwoName}
            onChange={this.handleGridNameChange.bind(this, "gridTwoName")}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>
            }}
            inputProps={{
              maxLength: 15,
              style: webStyle.sectionType
            }}
          />
          {this.showGridNameErr()}
        </Grid>
        <Box className={classes.gridImageLabel}>{configJSON.gridImageLabel}</Box>
        <input data-test-id="grid_two_image" type="file" accept=".jpeg, .jpg, .png" ref={this.gridTwoImgRef} className={classes.gridImageInput} value="" onChange={this.handleUploadSectionOneGridImage.bind(this, "gridTwoImage")} />
        {this.state.gridTwoPreview ? (
          <Box className={classes.gridPreviewContainer}>
            <img src={this.state.gridTwoPreview} alt="Image Preview" className={classes.gridPreviewImage} />
            <ClickableBox data-test-id="remove_grid2_image" onClick={this.handleRemoveGridImage.bind(this, "gridTwoImage")} className={classes.removeBannerButton}>
              {configJSON.removeText}
            </ClickableBox>
          </Box>
        ) : (
          <label htmlFor="upload-button">
            <Button variant="outlined" fullWidth data-test-id="open_grid2_image" onClick={this.handleOpenGridImage.bind(this, "gridTwoImage")} className={classes.uploadButton}>
              <Box>
                <img src={file} />
                <Box className={classes.buttonBox}>{configJSON.dropImageText}</Box>
                <Box className={classes.buttonInfo}>{configJSON.fileSizeInfoText}</Box>
                <Box className={classes.buttonInfo}>{configJSON.recommendedSize} 368&times;180</Box>
              </Box>
            </Button>
          </label>
        )}
        {this.state.gridTwoError ? <Typography className={classes.error}>{this.state.gridTwoError}</Typography> : ""}
        <Grid container className={classes.topMargin} spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topMargin}>
            <CustomizeBrandInput
              data-test-id="gridTwolink"
              label={configJSON.gridLink}
              id="outlined-start-adornment"
              value={this.state.gridTwoLink}
              onChange={this.handleGridLink.bind(this, "gridTwoLink")}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>
              }}
            />
            {this.state.gridTwoLinkError ? <Typography className={classes.error}>{this.state.gridTwoLinkError}</Typography> : ""}
            {this.showErr()}
          </Grid>
          <Box className={classes.flexEndContainer}>
            <Grid container spacing={3} className={classes.btnPosition}>
              <Grid item>
                <NextButton data-test-id="next2Ref" onClick={this.handleGrid2Next}>
                  {configJSON.nextBtn}
                </NextButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </>
    );
  };

  renderSection1Grid3 = () => {
    const { classes } = this.props;
    return (
      <>
        <Grid container className={classes.margin} spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.padding}>
            <Typography className={classes.headerTwo}>{configJSON.sectionOneGridThreeText}</Typography>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topMargin}>
          <CustomizeBrandInput
            data-test-id="gridThreeName"
            label={configJSON.gridNameLabel}
            id="outlined-start-adornment"
            value={this.state.gridThreeName}
            onChange={this.handleGridNameChange.bind(this, "gridThreeName")}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>
            }}
            inputProps={{
              maxLength: 15,
              style: webStyle.sectionType
            }}
          />
          {this.showGridNameErr()}
        </Grid>
        <Box className={classes.gridImageLabel}>{configJSON.gridImageLabel}</Box>
        <input data-test-id="grid_three_image" type="file" accept=".jpeg, .jpg, .png" ref={this.gridThreeImgRef} className={classes.gridImageInput} value="" onChange={this.handleUploadSectionOneGridImage.bind(this, "gridThreeImage")} />
        {this.state.gridThreePreview ? (
          <Box className={classes.gridPreviewContainer}>
            <img src={this.state.gridThreePreview} alt="Image Preview" className={classes.gridPreviewImage} />
            <ClickableBox data-test-id="remove_grid3_image" onClick={this.handleRemoveGridImage.bind(this, "gridThreeImage")} className={classes.removeBannerButton}>
              {configJSON.removeText}
            </ClickableBox>
          </Box>
        ) : (
          <label htmlFor="upload-button">
            <Button variant="outlined" fullWidth data-test-id="open_grid3_image" onClick={this.handleOpenGridImage.bind(this, "gridThreeImage")} className={classes.uploadButton}>
              <Box>
                <img src={file} />
                <Box className={classes.buttonBox}>{configJSON.dropImageText}</Box>
                <Box className={classes.buttonInfo}>{configJSON.fileSizeInfoText}</Box>
                <Box className={classes.buttonInfo}>{configJSON.recommendedSize} 368&times;180</Box>
              </Box>
            </Button>
          </label>
        )}
        {this.state.gridThreeError ? <Typography className={classes.error}>{this.state.gridThreeError}</Typography> : ""}
        <Grid container className={classes.topMargin} spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topMargin}>
            <CustomizeBrandInput
              data-test-id="gridThreelink"
              label={configJSON.gridLink}
              id="outlined-start-adornment"
              value={this.state.gridThreeLink}
              onChange={this.handleGridLink.bind(this, "gridThreeLink")}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>
              }}
            />
            {this.state.gridThreeLinkError ? <Typography className={classes.error}>{this.state.gridThreeLinkError}</Typography> : ""}
            {this.showErr()}
          </Grid>
          <Box className={classes.flexEndContainer}>
            <Grid container spacing={3} className={classes.btnPosition}>
              <Grid item>
                <NextButton data-test-id="next3Ref" onClick={this.handleGrid3Next}>
                  {configJSON.nextBtn}
                </NextButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.showGrid1 && (
          <>
            <Grid container className={classes.topMargin} spacing={4}>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topMargin}>
                <Typography className={classes.header}>{configJSON.sectionOneText}</Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topMargin}>
                <CustomizeBrandInput
                  data-test-id="seectionType"
                  label={configJSON.sectionTypeLabel}
                  id="outlined-start-adornment"
                  value={this.state.sectionType}
                  disabled
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>
                  }}
                  inputProps={{
                    style: webStyle.sectionType
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.margin} spacing={4}>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.padding}>
                <Typography className={classes.headerTwo}>{configJSON.sectionOneGridOneText}</Typography>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topMargin}>
              <CustomizeBrandInput
                data-test-id="gridOneName"
                label={configJSON.gridNameLabel}
                id="outlined-start-adornment"
                value={this.state.gridOneName}
                onChange={this.handleGridNameChange.bind(this, "gridOneName")}
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start"></InputAdornment>
                }}
                inputProps={{
                  maxLength: 15,
                  style: webStyle.sectionType
                }}
              />
              {this.showGridNameErr()}
            </Grid>
            <Box className={classes.gridImageLabel}>{configJSON.gridImageLabel}</Box>
            <input data-test-id="grid_one_image" type="file" className={classes.gridImageInput} value="" accept=".jpeg, .jpg, .png" ref={this.gridOneImgRef} onChange={this.handleUploadSectionOneGridImage.bind(this, "gridOneImage")} />
            {this.state.gridOnePreview ? (
              <Box className={classes.gridPreviewContainer}>
                <img src={this.state.gridOnePreview} alt="Image Preview" className={classes.gridPreviewImage} />
                <ClickableBox data-test-id="remove_grid1_image" onClick={this.handleRemoveGridImage.bind(this, "gridOneImage")} className={classes.removeBannerButton}>
                  {configJSON.removeText}
                </ClickableBox>
              </Box>
            ) : (
              <label htmlFor="upload-button">
                <Button variant="outlined" fullWidth data-test-id="open_grid1_image" onClick={this.handleOpenGridImage.bind(this, "gridOneImage")} className={classes.uploadButton}>
                  <Box>
                    <img src={file} />
                    <Box className={classes.buttonBox}>{configJSON.dropImageText}</Box>
                    <Box className={classes.buttonInfo}>{configJSON.fileSizeInfoText}</Box>
                    <Box className={classes.buttonInfo}>{configJSON.recommendedSize} 368&times;180</Box>
                  </Box>
                </Button>
              </label>
            )}
            {this.state.gridOneError ? <Typography className={classes.error}>{this.state.gridOneError}</Typography> : ""}
            <Grid container className={classes.topMargin} spacing={4}>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topMargin}>
                <CustomizeBrandInput
                  data-test-id="gridOnelink"
                  label={configJSON.gridLink}
                  id="outlined-start-adornment"
                  value={this.state.gridOneLink}
                  onChange={this.handleGridLink.bind(this, "gridOneLink")}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>
                  }}
                />
                {this.state.gridOneLinkError ? <Typography className={classes.error}>{this.state.gridOneLinkError}</Typography> : ""}
                {this.state.showErrMsg ? (
                  <Box className={classes.commonErrorBox}>
                    <Box className={classes.errMargin}>
                      <img src={errorImage} />
                    </Box>
                    <Box>
                      <Typography className={classes.errorOne}>{this.state.showErrMsg}</Typography>
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
              </Grid>
              <Box className={classes.flexEndContainer}>
                <Grid container spacing={3} className={classes.btnPosition}>
                  <Grid item>
                    <NextButton data-test-id="next1Ref" onClick={this.handleGrid1Next}>
                      {configJSON.nextBtn}
                    </NextButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </>
        )}
        {this.state.showGrid2 && this.renderSection1Grid2()}
        {this.state.showGrid3 && this.renderSection1Grid3()}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  sectionType: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    fontWeight: 500,
    color: "#000"
  }
};

const styles = (theme: Theme) => ({
  topMargin: {
    marginTop: "20px"
  },
  btnPosition: {
    justifyContent: "flex-end",
    padding: "0 16px"
  },
  gridImageLabel: {
    marginTop: "30px",
    color: "#9a9a9a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "12px",
    fontWeight: 600
  },
  gridImageInput: {
    display: "none"
  },
  gridPreviewContainer: {
    width: "fit-content",
    position: "relative" as const,
    marginTop: "30px"
  },
  gridPreviewImage: {
    maxWidth: "100%",
    maxHeight: "300px",
    alignSelf: "flex-start"
  },
  removeBannerButton: {
    borderRadius: "5px",
    width: "80px",
    background: "#fcaca7",
    padding: "10px 20px",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    marginTop: "10px"
  },
  uploadButton: {
    width: "100%",
    height: "210px",
    padding: "20px 0px",
    margin: "10px 0px",
    border: "none",
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23FBBC98FF' stroke-width='3' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    borderRadius: "5px",
    textTransform: "none" as const,
    fontFamily: `"Lato", sans-serif`
  },
  buttonBox: {
    fontSize: "27px",
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`
  },
  buttonInfo: {
    color: "#9a9a9a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "16px"
  },
  flexEndContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: "60px"
  },
  error: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: "12px",
    marginTop: "5px",
    color: "red"
  },
  header: {
    marginTop: "-30px",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "24px",
    fontWeight: 600
  },
  headerTwo: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: "20px",
    fontWeight: 600
  },
  margin: {
    margin: "16px 0"
  },
  padding: {
    padding: "16px 0 !important"
  },
  errorOne: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: "12px",
    color: "red"
  },
  commonErrorBox: {
    flexDirection: "row" as const,
    flexWrap: "nowrap" as const,
    display: "flex",
    paddingTop: "16px",
    alignItems: "center"
  },
  errMargin: {
    marginRight: "10px"
  }
});

export { StoreSectionOneTab };
export default withStyles(styles)(StoreSectionOneTab);

// Customizable Area End
