export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const mobilePhone=require("../assets/galaxyPhone.png")
export const xpress=require("../assets/xpress.png")
export const grid=require("../assets/grid.png")
export const cross= require("../assets/plus.png")
export const deleteIcon= require("../assets/deleteIcon.png")
export const deleteIconPng= require("../assets/Delete.png")
export const roundedcircle= require("../assets/roundedcircle.jpg")
export const demoImg =require("../assets/demoImg.png")
export const editIcon= require("../assets/editIcon.png")
export const successImg = require("../assets/success.png");
export const pattern = require("../assets/pattern.png");
export const privacy = require("../assets/Privacy.png");
export const ExpressLogo = require("../assets/Expresslogo.png");
export const checkbox = require("../assets/Checkbox3.png");
export const checkboxTicked = require("../assets/Checkbox2.png")

