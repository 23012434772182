import React from "react";

// Customizable Area Start
import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, Slider, Typography, withStyles } from "@material-ui/core";
import CategoriesSideBarController,{ Props } from "./CategoriesSideBarController.web";
import { orange } from "@material-ui/core/colors";
import { Rating } from "@material-ui/lab";
import { IBrandList, ICategory } from "./ProductCatalogueController.web";
import { configJSON } from "../CatalogueController";
import CheckIcon from '@material-ui/icons/Check';
// Customizable Area End

export type CategoryList = {
    list: ICategory[];
    title: string;
    itemList: string[]
}

export type BrandList = {
    list: IBrandList[];
    title: string;
    handleChange: (itemId: string) => (event: React.ChangeEvent<HTMLInputElement>) => void ;
    itemList: string[];
    key: string;
}

export const AirbnbSlider = withStyles({
    root: {
        color: '#3a8589',
        height: 3,
        padding: '13px 0',
    },
    thumb: {
        height: 27,
        width: 27,
        backgroundColor: '#fff',
        border: '1px solid currentColor',
        marginTop: -12,
        marginLeft: -13,
        boxShadow: '#ebebeb 0 2px 2px',
        '&:focus, &:hover, &$active': {
            boxShadow: '#ccc 0 2px 3px 1px',
        },
        '& .bar': {
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    active: {},
    track: {
        height: 3,
    },
    rail: {
        color: '#d8d8d8',
        opacity: 1,
        height: 3,
    },
})(Slider);
export function AirbnbThumbComponent(props: {}) {
    return (
        <span {...props}>
            <span className="bar" />
            <span className="bar" />
            <span className="bar" />
        </span>
    );
}

class CategoriesSideBar extends CategoriesSideBarController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    filterComponentOne({ title, list, itemList }: CategoryList) {
        const {classes} = this.props;
        return <>
            {list && list.length > 0 && <Box date-test-id="sideBarRef1" className={this.props.classes.container}>
                <Typography variant="h6" className={classes.titleText}>{title}</Typography>
                <FormControl component="fieldset">
                    <FormGroup>
                        {list && list.map((item:ICategory, index: number) => {
                            const keyValue = item.attributes.name;
                            return <Box className={this.props.classes.listWrapper} key={`filter-comp${index}`}>
                                <FormControlLabel
                                    data-test-id="formcontrolRef1"
                                    control={<Checkbox value={item.attributes.id}
                                        checked={itemList.includes(item.id)}
                                        onChange={this.handleChangeCheckBox.bind(this,item.id)}
                                        className={this.props.classes.checkBox}
                                        name={keyValue} data-test-id="checkboxOne" />}
                                    label={<Typography variant="body1" className={classes.optionText}>{keyValue}</Typography>}
                                    className={this.props.classes.checkBoxFormControl}
                                />
                            </Box>
                        })}
                    </FormGroup>
                </FormControl>
            </Box>}
            <Divider />
        </>
    }

    filterComponentTwo({ title, list, handleChange, itemList, key }: BrandList) {
        const {classes} = this.props;
        return <>
            {list && list.length > 0 && <Box date-test-id="sideBarRef2" className={this.props.classes.container}>
                <Typography variant="h6" className={classes.titleText}>{title}</Typography>
                <FormControl component="fieldset">
                    <FormGroup>
                        {list && list.map((item:IBrandList, index: number) => {
                            const keyValue = item.attributes.brand_name;
                            return <Box className={this.props.classes.listWrapper} key={`filter-comp${index}`}>
                                <FormControlLabel
                                    data-test-id="formcontrolRef2"
                                    control={<Checkbox value={item.attributes.id}
                                        checked={itemList.includes(item.id)}
                                        onChange={handleChange(item.id)}
                                        className={this.props.classes.checkBox}
                                        name={keyValue} data-test-id={`checkboxTwo`} />}
                                    label={<Typography variant="body1" className={classes.optionText}>{keyValue}</Typography>}
                                    className={this.props.classes.checkBoxFormControl}
                                />
                            </Box>
                        })}
                    </FormGroup>
                </FormControl>
            </Box>}
            <Divider />
        </>
    }

    ratingComponent() {
        const {classes} = this.props;
        return <React.Fragment >
            <Typography variant="h6" className={classes.titleText}>Rating</Typography>
            <FormControl component="fieldset">
                <FormGroup data-test-id="FormGroupRef">
                    {[...Array(5).keys()].map(i => i + 1).map((item: number, index: number) => {
                        return <Box className={this.props.classes.listWrapper} key={`${index}-form-group`}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                    data-test-id="rating"
                                    className={this.props.classes.checkBox}
                                    onChange={this.handleChange(index)}
                                    checked={this.state.ratingValues[index]} 
                                    value={`checkbox-${index}`}
                                />}
                                label={<Rating
                                    name="customized-color"
                                    precision={0.5}
                                    value={5 - index}
                                    readOnly
                                />}
                                className={this.props.classes.checkBoxFormControl}
                            />
                        </Box>

                    })}
                </FormGroup>
            </FormControl>
        </React.Fragment>
    }
    priceComponent() {
        return <React.Fragment >
            <Typography variant="h6">Price</Typography>
            <Typography variant="caption">
                AED {isNaN(this.state.priceRange[0])? Number(this.props.productList.min_range):this.state.priceRange[0]} - AED {isNaN(this.state.priceRange[1])? Number(this.props.productList.max_range):this.state.priceRange[1]}
            </Typography>
            <Box>
                <AirbnbSlider
                    data-test-id="AirbnbSliderRef"
                    ThumbComponent={AirbnbThumbComponent}
                    className={this.props.classes.sliderColor}
                    onChange={this.handlePriceSliderChange}
                    value={[isNaN(this.state.priceRange[0])? Number(this.props.productList.min_range):this.state.priceRange[0],isNaN(this.state.priceRange[1])? Number(this.props.productList.max_range):this.state.priceRange[1]]}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    step={1}
                    max={Number(this.props.productList.max_range)}
                    min={Number(this.props.productList.min_range)}
                    onChangeCommitted={this.handleChangeCommitted}

                /> </Box>
        </React.Fragment>
    }

    colorComponent() {
        const {classes} = this.props;
        return <React.Fragment key={`color-component`}>
            <Typography variant="h6" className={classes.titleText}>Colors</Typography>
            <Grid container spacing={1} className={classes.colorGrid}>
                {configJSON.ProductColorOptions.map((item: {[colorKey:string]: string}, index: number) => {
                    return <Grid className={`${this.props.classes.listWrapper} ${classes.colorWrapper}`} key={`${index}-color-group`}>
                        <Box className={classes.colorBox}
                          data-test-id="colorCheck"
                          onClick={()=>this.props.onSetColorFilter(item.title)}
                          style={{ background: item.value}}
                        ></Box>
                        {this.props.colorFilter.includes(item.title) && <CheckIcon data-test-id="colorChecked" onClick={()=>this.props.onSetColorFilter(item.title)} className={classes.colorBox} style={{ position: "absolute", color: "white" }}/>}
                    </Grid>
                })}

            </Grid>
        </React.Fragment>
    }

    renderCustomField(customFieldsData: { [key: string]: string[] }) {
        const {classes} = this.props;
        return (
          <>
            {customFieldsData && Object.entries(customFieldsData).map(([item], outerIndex: number) => (
              <Box key={`outerBox-${outerIndex}`} data-test-id="sideBarRef" className={this.props.classes.container}>
                <Typography variant="h6" className={classes.customTitle}>{item}</Typography>
                <FormControl component="fieldset">
                  <FormGroup>
                    {customFieldsData[item].map((value: string, innerIndex: number) => (
                      <Box key={`innerBox-${innerIndex}`} className={this.props.classes.listWrapper}>
                        <FormControlLabel
                          control={<Checkbox
                            value={this.props.customFieldValues.includes(item)}
                            onChange={this.handleChangeCustomFilter.bind(this,value)}
                            className={this.props.classes.checkBox}
                            name={value} data-test={`checkbox-${value}`} />}
                          label={<Typography variant="body1" className={classes.optionText}>{value}</Typography>}
                          className={this.props.classes.checkBoxFormControl}
                        />
                      </Box>
                    ))}
                  </FormGroup>
                </FormControl>
                <Divider />
              </Box>
            ))}
          </>
        );
      }

    renderOutOfStock = () => {
        return <Box className={this.props.classes.listWrapper} style={{ marginTop: "5px"}} key={`form-group`}>
            <FormControlLabel
                control={
                    <Checkbox
                        data-test-id="outOfStock"
                        onChange={this.props.onSetOutOfStocks}
                        checked={this.props.out_of_stocks}
                        className={this.props.classes.checkBox}
                    />}
                label={<Typography variant="body1" className={this.props.classes.optionText} style={{ fontSize: "17px" }}>Out of stock items</Typography>}
                className={this.props.classes.checkBoxFormControl}
            />
        </Box>
    }

    render() {
        const { classes } = this.props;
        return (
            <Box className={classes.contentWrapper}>
                {this.filterComponentOne({ title: "Sub Category", list: this.props.subCategoryList, itemList: this.props.subCatagoryItemsIds})}
                {this.filterComponentTwo({ title: "Brands", list: this.props.brandList, handleChange: this.handleChangeBrands, itemList: this.props.brandItemsIds, key: "brand_name" })}
                {(Number(this.props.productList.max_range) !== 0 || Number(this.props.productList.min_range) !== 0) && <>
                    <Box className={classes.container}>{this.priceComponent()}</Box>
                    <Divider />
                </>}
                <Box className={classes.container}>{this.colorComponent()}</Box>
                <Divider />
                <Box className={classes.container}>{this.ratingComponent()}</Box>
                <Divider />
                {this.renderCustomField(this.props.customFieldsData,)}
                <Divider />
                {this.renderOutOfStock()}
            </Box>
        )
    }
    // Customizable Area End
}

// Customizable Area Start
const styles = {
    checkBox: {
        padding:'6px',
        color: '#666',
        '&$checked': {
            color: orange[600],
        },
        '&.Mui-checked': {
            color: orange[600],
        },
    },
    sliderColor: {
        color: orange[600],
    },
    checkBoxFormControl: {
        fontSize: "14px",
        color: "#000",
        '&MuiFormControlLabel-label': {
            color: "#000",

        },
    },
    contentWrapper: {
        displax: "flex",
        padding: "0 20px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        height: "100%"
    },
    listWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    headerText: {
        padding: "20px 0px 15px 0px",
        fontSize: "18px",
        fontWeight: 400 as const,
        fontFamily: `"Lato", sans-serif` as const
    },
    container: {
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column" as const,
        position: "relative" as const,
        background: "white",
        padding: "12px 0px"
    },
    orderTextWrapper: {
        width: "100%",
        display: "flex",
        flexDirection: "row" as const,
        flexWrap: "wrap" as const,
        justifyContent: "space-between",
        alignItems: "center" as const,
        marginBottom: 10
    },
    ordersText: {
        fontSize: "27px",
        fontWeight: 700 as const,
        fontFamily: `"Lato", sans-serif` as const
    },
    searchWrapper: {
        padding: "20px 0px 15px 0px"
    },
    searchOrderText: {
        color: '#33333A',
        fontSize: '12px',
        fontWeight: 400 as const
    },
    searchBoxWrapper: {
        width: '65%',
        position: 'relative' as const
    },
    titleText: { 
        color: "#33333a", 
        fontFamily:"Helvetica, sans-serif" ,
        fontWeight:400,
        fontSize:'20px'
    },
    optionText: { 
        color: "#33333a", 
        fontFamily:"Lato, sans-serif" ,
        fontSize: '14px',
        fontWeight:400
    },
    customTitle: { 
        color: "#33333a", 
        fontFamily:"Helvetica, sans-serif" ,
        fontWeight:400,
        fontSize:'20px',
        textTransform: 'capitalize' as const
    },
    colorGrid: { 
        margin: '10px 0 -10px', 
        width:'100%' 
    },
    colorWrapper: {
        width:'18%', 
        marginBottom:'16px'
    },
    colorBox: { 
        height: '20px', 
        width: '20px', 
        borderRadius: '2px' 
    }
};

export { CategoriesSideBar }

export default withStyles(styles)(CategoriesSideBar);
// Customizable Area End