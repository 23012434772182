import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { GetCookies, SetCookies } from "../../../framework/src/WebUtilities";

interface InputFieldErr {
    [key: string]: string
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    match: any;
    location: any;
    classes: {
        personName: string,
        personEmail: string,
        active: string,
        menuList: string,
        rightSide: string,
        leftSide: string,
        papperClasses: string,
        papperClassesOne: string
    }
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    data: any[];
    passwordHelperText: string;
    enablePasswordField: boolean;
    firstName: any;
    lastName: any;
    email: any;
    phoneNumber: any;
    currentCountryCode: any;
    enableReTypePasswordField: boolean;
    enableNewPasswordField: boolean;
    buyerId: string;
    token: string;
    edtEmailEnabled: boolean;
    llDoChangePwdContainerVisible: boolean;
    llChangePwdDummyShowContainerVisible: boolean;
    currentPasswordText: any;
    newPasswordText: any;
    reTypePasswordText: any;

    edtMobileNoEnabled: boolean;
    countryCodeEnabled: boolean;

    saveButtonDisable: boolean;
    showPassword: boolean;
    showNewPassword: boolean;
    languageSelector: string;
    errorMessages1: string[];
    successMsg1: string;
    currentPasswordTxt: any;
    newPasswordTxt: any;
    selectedMenu: string;
    showVerifyDeleteModal: boolean;
    inputFieldError: InputFieldErr;
    showPannel: any;
    addId: string;
    cartId: string,
    address: string
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class UserMenuController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start




    apiCallMessageUpdateProfileRequestId: any;
    validationApiCallId: string = "";
    apiGetAllBuyerInfoId: string = "";
    apiChangePhoneValidation: any;
    registrationAndLoginType: string = "";
    userAttr: any;
    apiDeleteUserCallId: string = "";
    apiUpdateBuyerProfileCallId: string = "";
    inputRef: any;
    authToken: any;
    uniqueSessionRequesterId: any;
    userProfileGetApiCallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];


        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            currentCountryCode: configJSON.hintCountryCode,
            data: [],

            edtEmailEnabled: true,
            llDoChangePwdContainerVisible: false,
            passwordHelperText: "",
            enablePasswordField: true,
            enableReTypePasswordField: true,
            enableNewPasswordField: true,
            llChangePwdDummyShowContainerVisible: false,

            currentPasswordText: "",
            newPasswordText: "",
            reTypePasswordText: "",
            buyerId: '',
            token: '',

            saveButtonDisable: false,
            showPassword: false,
            showNewPassword: false,
            languageSelector: "",
            selectedMenu: "Profile",
            edtMobileNoEnabled: true,
            countryCodeEnabled: true,

            showVerifyDeleteModal: false,
            errorMessages1: [],
            inputFieldError: {
                "First Name": "",
                "Last Name": "",
                "curPassword": "",
                "newPassword": "",
            },
            successMsg1: "",
            currentPasswordTxt: "",
            newPasswordTxt: "",
            showPannel: "",
            addId: '',
            cartId: '',
            address: ''
        };


        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    // Customizable Area Start
    menuSelector(item: string) {
        SetCookies('isReview', 'false', 10);
        this.setState({ selectedMenu: item })
    }

    async componentDidMount(): Promise<void> {

        await this.getToken();
        this.getUserProfile2();
        const pathname = window.location.pathname;

        const lastStr = pathname.split("/")
        this.setState({ selectedMenu: lastStr[lastStr.length - 1] || "Profile" })

    }
    async componentDidUpdate(prevProps: Props, prevState: S): Promise<void>  {
        // Check if the pathname has changed
        const pathname = window?.location?.pathname;

        const lastStr = pathname.split("/")
        if (lastStr[lastStr.length - 1] !== prevState.selectedMenu) {
          this.setState({
            selectedMenu: lastStr[lastStr.length - 1],
          });
        }
        
      }


    getToken = async () => {
        const token = GetCookies("ba");
        this.setState({ token })
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("on recieive==>" + JSON.stringify(message));


        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.apiGetAllBuyerInfoId) {
                    this.buyerInfo(responseJson)
                }

            }
        }

        // Customizable Area End
    }

    getUserProfile2() {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        )
        const headers = {
            token: this.state.token
        }
        this.apiGetAllBuyerInfoId = requestMessage.messageId
        const endpoint = configJSON.userProfileDetailEndpoint + this.state.token

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint)
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetUserProfile,
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers),
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    buyerInfo(responseJson: any) {

        const profileData = responseJson?.data?.attributes
        this.setState({
            firstName: profileData?.first_name || "",
            lastName: profileData?.last_name || "",
            email: profileData?.email || "",
            languageSelector: profileData?.language ? profileData?.language : "English",
            buyerId: responseJson?.data?.id || ""
        })
    }

    userProfiles = (info: any) => {
        const { first_name = "", last_name = "", email = "", language = "" } = info.attributes
        this.setState({ firstName: first_name, lastName: last_name, email: email, buyerId: info.id })
    }

    handleCartId = (id:string) => {
        this.setState({cartId: id})
    }

    handleDefaultAddress = (address: string) => {
        this.setState({address: address})
    }

    // Customizable Area End

}
