import React from "react";
import { Box, Button, Checkbox, createTheme, Drawer, FormControl, MenuItem, Switch, TextField } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { createStyles, withStyles } from "@material-ui/styles";
import { DataGrid } from "@mui/x-data-grid";
import { withStyles as styleWithStyle } from "@material-ui/core/styles";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";

export const theme = createTheme({
  typography: {
    body2: {
      fontSize: 12,
      fontFamily: `"Lato", sans-serif`
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1460
    }
  },
  overrides: {
    MuiContainer: {
      root: {
        fontFamily: `"Lato", sans-serif`,
        position: "relative"
      }
    }
  }
});

export const CustomizeDrawer = withStyles(() => ({
  root: {
    "& .MuiDrawer-paper": {
      width: 260,
      overflow: "visible"
    }
  }
}))(Drawer);

export const CustomizeInput = withStyles(() => ({
  root: {
    "& .MuiInputBase-input": {
      color: "#999999"
    },
    "& .MuiInputBase-root": {
      border: "1px solid #DDE3E8"
    },
    "& .MuiInputBase-root.Mui-error": {
      border: "1px solid #f44336"
    },
    "& .MuiInput-formControl": {
      // border: "1px solid #DDE3E8",
      margin: 0,
      padding: "10px 0px",
      borderRadius: "5px"
    },
    "& .MuiInputLabel-root": {
      top: -20,
      left: 0,
      color: "#33333A",
      fontFamily: `"Lato", sans-serif`,
      fontWeight: 600,
      fontSize: 14
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#f44336"
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline on hover
    }
  }
}))(TextField);

export const CustomBackButton = withStyles(() => ({
  root: {
    width: "130px",
    padding: "10px 0px",
    borderRadius: 5,
    background: "#3e3e3e",
    textTransform: "none",
    color: "white",
    fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    fontSize: 16,
    fontWeight: "bolder",
    "&:hover": {
      background: "#3e3e3e"
    }
  }
}))(Button);

export const CustomDeleteButton = withStyles(() => ({
  root: {
    width: "130px",
    padding: "10px 0px",
    borderRadius: 5,
    background: "#f44336",
    textTransform: "none",
    color: "white",
    fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    fontSize: 16,
    fontWeight: "bolder",
    "&:hover": {
      background: "#f44336"
    }
  }
}))(Button);

export const CustomSubmitReuploadedButton = withStyles(() => ({
  root: {
    width: "130px",
    padding: "10px 0px",
    borderRadius: 5,
    background: "#FF6008",
    textTransform: "none",
    color: "white",
    fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    fontSize: 16,
    fontWeight: "bolder",
    "&:hover": {
      background: "#FF6008"
    }
  }
}))(Button);

export const CustomizeInputVariant2 = withStyles(() => ({
  root: {
    "& .MuiInputBase-input": {
      color: "#999999"
    },
    "& .MuiInput-formControl": {
      border: "1px solid #DDE3E8",
      margin: 0,
      padding: "10px 0px",
      borderRadius: "5px"
    },
    "& .MuiInputLabel-root": {
      top: -20,
      left: 0,
      color: "black",
      fontFamily: `"Lato", sans-serif`,
      fontWeight: 600,
      fontSize: 14
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline on hover
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: 12 // Set the placeholder font size to 12px
    }
  }
}))(TextField);

export const BoxNoScroll = withStyles(() => ({
  root: {
    "&::-webkit-scrollbar": { display: "none" },
    scrollbarWidth: "none",
    "-ms-overflow-style": "none"
  }
}))(Box);

export const BoxWithHoverStyle = withStyles(() => ({
  root: {
    width: "100%",
    height: "45px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "30px",
    fontSize: 14,
    color: "#999999",
    fontFamily: `"Lato", sans-serif`,
    "&:hover": {
      color: "#000000",
      fontWeight: 1000
    }
  }
}))(Box);

export const CustomizeBrandInput = withStyles(() => ({
  root: {
    "& .MuiInputBase-input": {
      fontSize: 14,
      fontFamily: '"Lato", sans-serif',
      fontWeight: 500
    },
    "& .MuiInput-formControl": {
      border: "1px solid #b1b3b5",
      margin: 0,
      padding: "5px 0px 5px 10px",
      borderRadius: "5px"
    },
    "& .MuiInputLabel-root": {
      top: -22,
      left: 0,
      color: "#9a9a9a",
      fontFamily: `"Lato", sans-serif`,
      fontWeight: 600,
      fontSize: 16
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline on hover
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: 12 // Set the placeholder font size to 12px
    }
  }
}))(TextField);

export const CustomizeShippingInput = withStyles(() => ({
  root: {
    "& .MuiInputBase-input": {
      fontSize: 16,
      fontFamily: '"AvenirNext-Regular", sans-serif',
      fontWeight: 400,
      color: "#666666"
    },
    "& .MuiInput-formControl": {
      border: "1px solid #b1b3b5",
      margin: 0,
      padding: "5px 0px 5px 10px",
      borderRadius: "5px"
    },
    "& .MuiInputLabel-root": {
      top: -22,
      left: 0,
      color: "#666666",
      fontFamily: `"Lato", sans-serif`,
      fontWeight: 400,
      fontSize: 14
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline on hover
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: 12 // Set the placeholder font size to 12px
    },
    "internal-autofill-selected": {
      backgroundColor: "none"
    }
  }
}))(TextField);

export const OrangeButton = withStyles(() => ({
  root: {
    textTransform: "none",
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 700,
    fontSize: 16,
    background: "#FF6008",
    color: "white",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    padding: "15px 50px",
    "&:hover": {
      backgroundColor: "#ff7f50"
    }
  }
}))(Button);

export const CustomMenuItem = withStyles(() => ({
  root: {
    color: "#2e2d2d",
    padding: "15px 30px",
    fontSize: 14,
    fontFamily: '"Lato", sans-serif',
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#ff7020b0"
    },
    "& .Mui-selected": {
      backgroundColor: "#ff7020b0"
    },
    "&:selection": {
      backgroundColor: "#ff7020b0"
    },
    "&.MuiListItem-root.Mui-selected": {
      backgroundColor: "#ff7020b0"
    }
  }
}))(MenuItem);

export const MenuItemTwo = withStyles(() => ({
  root: {
    color: "#2e2d2d",
    padding: "15px 14px",
    fontSize: 14,
    fontFamily: `"Lato", sans-serif`,
    "&:hover": {
      backgroundColor: "#eb6b2e",
      color: "white"
    },
    "& .Mui-selected": {
      backgroundColor: "#eb6b2e",
      color: "white"
    },
    "&:selection": {
      backgroundColor: "#eb6b2e",
      color: "white"
    },
    "&.MuiListItem-root.Mui-selected": {
      backgroundColor: "#eb6b2e",
      color: "white"
    }
  }
}))(MenuItem);

export const CustomFormControl = withStyles(() => ({
  root: {
    "& .MuiInput-formControl": {
      border: "1px solid #D8D8D8",
      margin: 0,
      padding: "5px 0px 5px 0px",
      borderRadius: "5px"
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline on hover
    },
    "& .MuiSelect-select:focus": {
      background: "none"
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: 15,
      fontSize: 14,
      fontWeight: 500,
      fontFamily: `"Lato", sans-serif`
    }
  }
}))(FormControl);
export const FormControlTwo = withStyles(() => ({
  root: {
    "& .MuiInput-formControl": {
      border: "1px solid #b1b3b5",
      margin: 0,
      padding: "5px 0px 5px 0px",
      borderRadius: "5px"
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline on hover
    },
    "& .MuiSelect-select:focus": {
      background: "none"
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: 15,
      fontSize: 14,
      fontWeight: 500,
      fontFamily: `"Lato", sans-serif`
    }
  }
}))(FormControl);

export const CustomExpandMoreIcon = withStyles({
  root: {
    fill: "rgb(207, 207, 207)", // Change the color of the icon here
    marginRight: 15
  }
})(ExpandMoreIcon);

export const CustomExpandMoreIconTwo = withStyles({
  root: {
    fill: "#fff", // Change the color of the icon here
    marginRight: 9
  }
})(ExpandMoreIcon);

export const SearchIconSVG = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.9692 11.2958L15.8572 15.184C16.0417 15.3685 16.0417 15.6736 15.8572 15.8581C15.765 15.9539 15.6408 16 15.5202 16C15.3996 16 15.2754 15.9503 15.1832 15.8581L11.2952 11.9698C10.0961 13.0129 8.53168 13.6443 6.82179 13.6443C3.06147 13.6443 0 10.5827 0 6.82217C0 3.06164 3.05792 0 6.82179 0C10.5821 0 13.6436 3.05809 13.6436 6.82217C13.6436 8.53215 13.0121 10.0967 11.9692 11.2958ZM6.81825 0.957871C3.5865 0.957871 0.954271 3.5867 0.954271 6.82217C0.954271 10.0576 3.5865 12.69 6.81825 12.69C10.0535 12.69 12.6822 10.0541 12.6822 6.82217C12.6822 3.59024 10.0535 0.957871 6.81825 0.957871Z"
        fill="#585858"
      />
    </svg>
  );
};

export const CustomMenuItemBox = withStyles(() => ({
  root: {
    padding: "20px 30px",
    fontSize: 14,
    color: "#2e2d2d",
    fontFamily: `"Lato", sans-serif`,
    "&:hover": {
      background: "#ff7020b0",
      cursor: "pointer"
    }
  }
}))(Box);

export const CustomizeInputSearchBox = withStyles(() => ({
  root: {
    "& .MuiInputBase-input": {
      fontSize: 14,
      fontFamily: '"Lato", sans-serif',
      fontWeight: 500
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#f44336"
    },
    "& .MuiInputBase-root.Mui-error": {
      border: "1px solid #f44336"
    },
    "& .MuiInputBase-root": {
      border: "1px solid #DDE3E8"
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline
    },
    "& .MuiInputLabel-root": {
      top: -20,
      left: 0,
      color: "#33333A",
      fontWeight: 600,
      fontSize: 14,
      fontFamily: `"Lato", sans-serif`
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline on hover
    },
    "& .MuiInput-formControl": {
      margin: 0,
      padding: "7px 0px",
      borderRadius: "5px"
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#585858",
      fontFamily: "Lato, sans-serif",
      fontSize: "12px",
      fontWeight: 400
    }
  }
}))(TextField);

export const CustomizeInputSearchBoxTwo = withStyles(() => ({
  root: {
    "& .MuiFormLabel-root.Mui-error": {
      color: "#f44336"
    },
    "& .MuiInputBase-root.Mui-error": {
      border: "1px solid #f44336"
    },
    "& .MuiInputBase-root": {
      border: "1px solid #DDE3E8"
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline
    },
    "& .MuiInputLabel-root": {
      top: -20,
      left: 0,
      color: "#666666",
      fontSize: 14,
      fontWeight: 500,
      fontFamily: `"Lato", sans-serif`
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline on hover
    },
    "& .MuiInput-formControl": {
      margin: 0,
      padding: "3px 0px",
      borderRadius: "5px"
    }
  }
}))(TextField);

export const CustomDataGrid = withStyles(() => ({
  root: {
    width: "100%",
    border: "none", // Set border to none for the root of DataGrid
    "& .MuiDataGrid-columnsContainer": {
      border: "none",
      height: 20,
      lineHeight: "0px"
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none" // Hide the column separators
    },
    "& .MuiDataGrid-iconButtonContainer": {
      display: "none" // Hide the icon button container
    },
    "& .MuiDataGrid-cell": {
      border: "none" // Remove border on DataGrid cells
    },
    "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within": {
      outline: "none" // Remove the outline on focus-within for both columnHeader and cell
    },
    "& .MuiDataGrid-columnHeaderTitleContainer, ": {
      padding: 0
    },
    "& .MuiDataGrid-root, .MuiDataGrid-footerContainer": {
      display: "none" // Hide the
    },
    "& .header-cell": {
      fontFamily: `"Lato", sans-serif`,
      fontSize: 14,
      fontWeight: 1000,
      color: "#33333A",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  }
}))(DataGrid);

export const FitContentOrangeButton = withStyles(() => ({
  root: {
    textTransform: "none",
    backgroundColor: "#FF6008",
    "&:hover": {
      backgroundColor: "#FF6008"
    },
    color: "white",
    padding: "15px 30px",
    borderRadius: "8px",
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "'Montserrat', sans-serif",
    width: "fit-content"
  }
}))(Button);

export const CustomScrollbar = withStyles(() => ({
  root: {
    // Add the scrollbar styles here
    "&::-webkit-scrollbar": {
      width: "5px" // Width of the scrollbar track
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#d8d8d8", // Color of the thumb
      borderRadius: "4px", // Border radius of the thumb
      width: "3px" // Width of the thumb
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1", // Color of the track
      borderRadius: "4px", // Border radius of the track
      width: "4px", // Width of the track
      boxShadow: "inset 0 0 0px 1px white"
    }
  }
}))(Box);

export const FitContentDarkButton = withStyles(() => ({
  root: {
    textTransform: "none",
    backgroundColor: "#333438",
    "&:hover": {
      backgroundColor: "#333438"
    },
    color: "white",
    padding: "0px 40px",
    borderRadius: 3,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: `"Lato", sans-serif`,
    width: "fit-content"
  }
}))(Button);

export const AddNewProductButton = withStyles(() => ({
  root: {
    textTransform: "none",
    backgroundColor: "#14bd9e",
    "&:hover": {
      backgroundColor: "#14bd9e"
    },
    color: "white",
    padding: "7px 10px 7px 7px",
    borderRadius: 3,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: `"Lato", sans-serif`,
    width: "fit-content"
  }
}))(Button);

export const CustomDeleteBox = withStyles(() => ({
  root: {
    textTransform: "none",
    color: "white",
    padding: "0px 80px 0px 0px",
    borderRadius: 3,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: `"Lato", sans-serif`,
    width: "fit-content"
  }
}))(Box);

export const CustomDataGridWithColumnBackground = withStyles(() => ({
  root: {
    border: "none", // Set border to none for the root of DataGrid
    "& .MuiDataGrid-columnsContainer": {
      border: "1px solid #f1f1f1",
      height: 20,
      lineHeight: "0px",
      display: "flex",
      background: "#fbfbfb"
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none" // Hide the column separators
    },
    "& .MuiDataGrid-iconButtonContainer": {
      display: "none" // Hide the icon button container
    },
    "& .MuiDataGrid-cell": {
      border: "none" // Remove border on DataGrid cells
    },
    "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within": {
      outline: "none" // Remove the outline on focus-within for both columnHeader and cell
    },
    "& .MuiDataGrid-columnHeaderTitleContainer, ": {
      padding: 0
    },
    "& .MuiDataGrid-root, .MuiDataGrid-footerContainer": {
      display: "none" // Hide the
    },
    "& .header-cell": {
      fontFamily: `"Lato", sans-serif`,
      fontSize: 14,
      fontWeight: 1000,
      color: "#33333A",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  }
}))(DataGrid);

export const CustomizeProductInput = withStyles(() => ({
  root: {
    "& .MuiInputBase-root": {
      border: "1px solid #C8C8C8"
    },
    "& .MuiInputBase-root.Mui-error": {
      border: "1px solid #f44336"
    },
    "& .MuiInput-formControl": {
      // border: "1px solid #DDE3E8",
      margin: 0,
      padding: "5px 0px",
      borderRadius: "5px"
    },
    "& .MuiInputLabel-root": {
      top: -22,
      left: 0,
      color: "#a3a3a3",
      fontFamily: `"Lato", sans-serif`,
      fontWeight: 600,
      fontSize: 16
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#f44336"
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline on hover
    }
  }
}))(TextField);

export const AddNewButton = withStyles(() => ({
  root: {
    height: 44,
    padding: "0px 20px",
    background: "#10BC9E",
    borderRadius: 4,
    fontFamily: `"Lato", sans-serif`,
    color: "white",
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    "&:hover": {
      background: "#10BC9E"
    }
  }
}))(Button);

export const DatePickerButton = withStyles(() => ({
  root: {
    height: 44,
    padding: "0px 15px 0px 13px",
    background: "none",
    borderRadius: 4,
    border: "1px solid #b1b3b5",
    fontFamily: `"Lato", sans-serif`,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "nowrap",
    textTransform: "none",
    "&:hover": {
      background: "none"
    }
  }
}))(Button);

export const IOSSwitch = withStyles(() =>
  createStyles({
    root: {
      width: 92,
      minWidth: 92,
      maxWidth: 92,
      height: 29,
      padding: 0,
      position: "relative",
      margin: theme.spacing(1)
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(62px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#55a246",
          opacity: 1,
          border: "none"
        }
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff"
      }
    },
    thumb: {
      width: 22,
      height: 22,
      marginTop: 2.5,
      marginLeft: 2.5,
      background: "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(181,181,181,1) 85%)",
      boxShadow: "none"
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: "#ff4747",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"])
    },
    checked: {},
    focusVisible: {}
  })
)(({ classes, ...props }: any) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});

export const InputOne = withStyles(() => ({
  root: {
    "& .MuiInputBase-input": {
      fontSize: 14,
      fontFamily: '"Lato", sans-serif',
      fontWeight: 500
    },
    "& .MuiInput-formControl": {
      border: "1px solid #b1b3b5",
      margin: 0,
      padding: "10px 0px 5px 10px",
      borderRadius: "5px"
    },
    "& .MuiInputLabel-root": {
      top: -22,
      left: 0,
      color: "#9a9a9a",
      fontFamily: `"Lato", sans-serif`,
      fontWeight: 600,
      fontSize: 16
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline on hover
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: 14, // Set the placeholder font size to 12px
      color: "black !important",
      fontFamily: `"Lato", sans-serif`,
      opacity: 1
    }
  }
}))(TextField);

export const SupportSelectionButton = withStyles(() => ({
  root: {
    background: "linear-gradient(180deg, rgb(235 235 235 / 26%) 0%, rgb(199 199 199 / 27%) 100%)",
    textTransform: "uppercase",
    color: "#727272",
    width: 190,
    height: 102,
    display: "flex",
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    fontStyle: "normal",
    fontWeight: 700,
    fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    fontSize: 16,
    "&:hover": {
      background: "linear-gradient(180deg, rgb(235 235 235 / 26%) 0%, rgb(199 199 199 / 27%) 100%)"
    }
  }
}))(Button);

export const InputDesign1 = withStyles(() => ({
  root: {
    "& .MuiInput-formControl": {
      // border: "1px solid #DDE3E8",
      margin: 0,
      padding: "10px 0px",
      borderRadius: "5px"
    },
    "& .MuiInputBase-input": {
      fontSize: 15,
      fontFamily: '"Lato", sans-serif',
      fontWeight: 500,
      color: "#333333"
    },
    "& .MuiInputLabel-root": {
      top: -20,
      left: 0,
      color: "#33333A",
      fontFamily: `"Lato", sans-serif`,
      fontWeight: 600,
      fontSize: 15,
      opacity: 0.6,
      letterSpacing: 0.3
    },
    "& .MuiInputBase-root": {
      border: "1px solid #DDE3E8"
    },
    "& .MuiInputBase-root.Mui-error": {
      border: "1px solid #f44336"
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#f44336"
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline on hover
    }
  }
}))(TextField);

export const InputDesign2 = withStyles(() => ({
  root: {
    "& .MuiInputBase-input": {
      fontSize: 15,
      fontFamily: '"Lato", sans-serif',
      fontWeight: 500,
      color: "#333333"
    },
    "& .MuiInput-formControl": {
      // border: "1px solid #DDE3E8",
      margin: 0,
      padding: "10px 0px",
      borderRadius: "5px"
    },
    "& .MuiInputLabel-root": {
      top: -20,
      left: 0,
      color: "#33333A",
      fontFamily: `"Lato", sans-serif`,
      fontWeight: 600,
      fontSize: 15,
      opacity: 0.6,
      letterSpacing: 0.3
    },
    "& .MuiInputBase-root": {
      border: "1px solid #DDE3E8"
    },
    "& .MuiInputBase-root.Mui-error": {
      border: "1px solid #f44336"
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: 14, // Set the placeholder font size to 12px
      color: "#333 !important",
      fontFamily: `"Lato", sans-serif`,
      fontWeight: 500,
      opacity: 1
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#f44336"
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline on hover
    }
  }
}))(TextField);

export const GenerateButton = withStyles(() => ({
  root: {
    background: "#33333A",
    borderRadius: 4,
    fontFamily: `"Lato", sans-serif`,
    textTransform: "none",
    padding: "9px 35px",
    "&:hover": {
      background: "#444"
    }
  }
}))(Button);

export const BlackButton = withStyles(() => ({
  root: {
    textTransform: "none",
    fontFamily: "'Montserrat Variable', sans-serif",
    fontWeight: 700,
    fontSize: 16,
    background: "#444",
    color: "white",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    padding: "15px 50px",
    "&:hover": {
      background: "#444"
    }
  }
}))(Button);

export const NextButton = withStyles(() => ({
  root: {
    textTransform: "none",
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 700,
    fontSize: 16,
    background: "#FF6008",
    color: "white",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    padding: "10px 50px",
    "&:hover": {
      backgroundColor: "#ff7f50"
    }
  }
}))(Button);

export const CustomWhiteButton = withStyles(() => ({
  root: {
    textTransform: "none",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.8)"
    },
    color: "#000",
    padding: "8px 32px",
    borderRadius: '20px',
    fontSize: 14,
    fontWeight: 400,
    fontFamily: `"Lato", sans-serif`,
    width: "fit-content"
  }
}))(Button);

export const RadioCheckbox:any = styleWithStyle({
  root: {
    color: "#FF6008", // Change color here
    "&$checked": {
      color: "#FF6008" // Change checked color here if needed
    }
  },
  checked: {}
})(props => <Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} {...props} />);

export const CustomAnalyticsExpandMoreIcon = withStyles({
  root: {
    fill: "rgba(0,0,0,0.54)", // Change the color of the icon here
    marginRight: 6
  }
})(ExpandMoreIcon);
