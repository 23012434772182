// Customizable Area Start
import React from "react";
import WeeklyDealsController, {
    IDealProductList,
  ISubCategoryList,
  Props,
  configJSON,
} from "./WeeklyDealsController.web";
import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Select,
  Theme,
  Typography,
  withStyles,
  styled
} from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { orange } from "@material-ui/core/colors";
import HeaderWeb from "../../landingpage/src/Header.web";
import FooterWeb from "../../landingpage/src/Footer.web";
import { ClickableBox } from "../../otp-input-confirmation/src/CustomElementStyling";
import {
  FormControlTwo,
  MenuItemTwo,
} from "../../dashboard/src/CustomizedElementStyle";
import ProductList from "./BuyerSearchProduct/ProductList.web";
import { IBrandList } from "./BuyerSearchProduct/ProductCatalogueController.web";
import { logoImg } from "./assets";
import AppsIcon from "@material-ui/icons/Apps";
import {
  AirbnbSlider,
  AirbnbThumbComponent,
} from "./BuyerSearchProduct/CategoriesSideBar.web";
import { Rating } from "@material-ui/lab";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
const ShopByBrandSliderDeal = styled(Slider)({
  "& .slick-prev::before": {
    fontSize: 35,
    opacity: 1,
    color: "rgb(255, 255, 255)"
  },
  "& .slick-next::before": {
    fontSize: 35,
    opacity: 1,
    color: "rgb(255, 255, 255)"
  },
  "& .slick-track": {
    display: "flex"
  },
  "& .slick-slide": {
    height: "unset"
  },

  "& .slick-slide > div > div": {
    borderRadius: 10
  },
  "& .slick-slide > div": {
    display: "flex",
    margin: "0 8px",
    gap: 10
  },
  "& .slick-next": {
    top: "42%",
    color: "#000",
    backgroundColor: "#fff",
    padding: "4px",
    fontSize: "18px",
    borderRadius: '50%',
    "& >path": {
    }
  },
  "& .slick-prev": {
    top: "42%",
    color: "#000",
    backgroundColor: "#fff",
    padding: "4px",
    fontSize: "18px",
    borderRadius: '50%'
  },
});
// Customizable Area End

class WeeklyDeals extends WeeklyDealsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  ratingFilter() {
    const { classes } = this.props;
    return (
      <>
        <Typography variant="h6" className={classes.headerText}>
          Rating
        </Typography>
        <FormControl component="fieldset">
          <FormGroup data-test-id="ratingFilter">
            {[...Array(5).keys()]
              .map((value) => value + 1)
              .map((item: number, index: number) => {
                return (
                  <Box
                    className={this.props.classes.optionWrapper}
                    key={`${index}-form-group`}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          data-test-id="rating"
                          className={classes.checkbox}
                          onChange={this.handleChangeRating(index)}
                          checked={this.state.ratings[index]}
                          value={`checkbox-${index}`}
                        />
                      }
                      label={
                        <Rating
                          name="customized-color"
                          precision={0.5}
                          value={5 - index}
                          readOnly
                        />
                      }
                      className={this.props.classes.checkBoxFormControl}
                    />
                  </Box>
                );
              })}
          </FormGroup>
        </FormControl>
      </>
    );
  }

  priceFilter = () => {
    return (
      <>
        <Typography variant="h6" className={this.props.classes.headerText}>
          Price
        </Typography>
        <Typography variant="caption">
          $
          {this.state.minRange
            ? Number(this.state.minRange)
            : this.state.prodPiceRange[0]}{" "}
          - $
          {this.state.maxRange
            ? Number(this.state.maxRange)
            : this.state.prodPiceRange[1]}
        </Typography>
        <Box>
          <AirbnbSlider
            data-test-id="priceSlider"
            ThumbComponent={AirbnbThumbComponent}
            className={this.props.classes.slider}
            onChange={this.handlePriceChange}
            value={[
              Number(
                this.state.minRange
                  ? this.state.minRange
                  : this.state.prodPiceRange[0]
              ),
              Number(
                this.state.maxRange
                  ? this.state.maxRange
                  : this.state.prodPiceRange[1]
              ),
            ]}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            step={1}
            max={Number(this.state.priceData.max_range)}
            min={Number(this.state.priceData.min_range)}
            onChangeCommitted={this.handlePriceChangeCommitted}
          />
        </Box>
      </>
    );
  };

  colorFilter() {
    const { classes } = this.props;
    const colorValues = configJSON.colorHexValues;
    return (
      <React.Fragment key={`${colorValues}-color-component`}>
        <Typography variant="h6" className={classes.headerText}>
          Colors
        </Typography>
        <Grid container spacing={1} className={classes.colorGrid}>
          {colorValues.map((item: string, index: number) => {
            return (
              <Grid
                className={`${classes.colorWrap} ${classes.colorBox}`}
                key={`${index}-color-group`}
              >
                <Box
                  className={classes.color}
                  style={{ background: item }}
                ></Box>
              </Grid>
            );
          })}
        </Grid>
      </React.Fragment>
    );
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const settings = {
      dots: false,
      centerMode: false,
      speed: 500,
      infinite: true,
      slidesToScroll: 1,
      boxShadow: "0px 1px 3px #EBEBEB",
      slidesToShow: Math.min(this.state.brandData.length, 6),
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            
          }
        },
        {
          breakpoint: 786,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
            infinite: true,
           
          }
        }
      ],
      prevArrow: <ArrowBackIos style={{
        color: 'black',
        fontSize: '18px'
    }} />,
    nextArrow: <ArrowForwardIos style={{
        color: 'black',
        fontSize: '18px'
    }} />
    };
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box className={classes.wrapper}>
          <HeaderWeb navigation={this.props.navigation} id={this.props.id} />
          <Container className={classes.containerBox}>
            <Box className={classes.rootBox}>
              <nav className={classes.navDrawer} aria-label="mailbox folders">
                <Box className={classes.mainContainer}>
                {!this.state.isBrandPage && (<>
                  <Box
                    data-test-id="subCategoryList"
                    className={classes.categoryContainer}
                  >
                    <Typography variant="h6" className={classes.headerText}>
                      {"Sub Category"}
                    </Typography>
                    {this.state.subCategoryList.length > 0 &&
                      this.state.subCategoryList.map(
                        (item: ISubCategoryList, index: number) => {
                          return (
                            <>
                              <FormControl component="fieldset">
                                <FormGroup>
                                  <Box
                                    className={classes.optionWrapper}
                                    key={`filter-comp${index}`}
                                  >
                                    <FormControlLabel
                                      data-test-id="subCategoryFilter"
                                      control={
                                        <Checkbox
                                          value={item.id}
                                          checked={this.state.selectedSubCategory.includes(item?.id)}
                                          onChange={this.handleSubCategoryChange.bind(this,item?.id)}
                                          className={classes.checkbox}
                                          name={item.attributes.name}
                                          data-test-id="checkboxOne"
                                        />
                                      }
                                      label={
                                        <Typography
                                          variant="body1"
                                          className={classes.optionLabel}
                                        >
                                          {item.attributes.name}
                                        </Typography>
                                      }
                                      className={classes.checkBoxFormControl}
                                    />
                                  </Box>
                                </FormGroup>
                              </FormControl>
                            </>
                          );
                        }
                      )}
                  </Box>
                  <Divider />
                   <Box
                    data-test-id="brandList"
                    className={classes.categoryContainer}
                  >
                     <Typography
                              variant="h6"
                              className={classes.headerText}
                            >
                              {"Brands"}
                            </Typography>
                    {this.state.brandData.length > 0 &&
                      this.state.brandData.map((item: IBrandList) => {
                        return (
                          <>
                            <FormControl component="fieldset">
                              <FormGroup>
                                <Box
                                  className={classes.optionWrapper}
                                  key={item.attributes?.id}
                                >
                                  <FormControlLabel
                                    data-test-id="brandFilter"
                                    control={
                                      <Checkbox
                                        value={item.attributes?.id}
                                        checked={this.state.selectedBrandIds.includes(
                                          item.attributes?.id
                                        )}
                                        onChange={this.handleBrandChange.bind(
                                          this,
                                          item.attributes?.id
                                        )}
                                        className={classes.checkbox}
                                        name={item.attributes.brand_name}
                                        data-test-id="checkboxTwo"
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant="body1"
                                        className={classes.optionLabel}
                                      >
                                        {item.attributes.brand_name}
                                      </Typography>
                                    }
                                    className={classes.checkBoxFormControl}
                                  />
                                </Box>
                              </FormGroup>
                            </FormControl>
                          </>
                        );
                      })}
                  </Box>
                  <Divider />
                </>)}
                  {(Number(this.state.priceData.max_range) !== 0 ||
                    Number(this.state.priceData.min_range) !== 0) && (
                    <>
                      <Box className={classes.filterContainer}>
                        {this.priceFilter()}
                      </Box>
                      <Divider />
                    </>
                  )}
                  <Box className={classes.filterContainer}>
                    {this.colorFilter()}
                  </Box>
                  <Divider />
                  <Box className={classes.filterContainer}>
                    {this.ratingFilter()}
                  </Box>
                  <Divider />
                </Box>
              </nav>
              <Box className={classes.rightContent}>
                <Box>
                  <Container className={classes.rightContainer}>
                    <Box className={classes.flexOne}>
                      <Typography
                      data-test-id="home"
                        onClick={this.navigateToHome}
                        className={classes.homeText}
                      >
                        Home
                      </Typography>
                      <Typography className={classes.dealName}>
                        {" / "}{this.state.isBrandPage ? "Brands" : "Deals"}
                      </Typography>
                    </Box>
                    {!this.state.isBrandPage && (
                      <>
                      <Typography className={classes.shopText}>
                      Shop by Brand
                    </Typography>
                        <Grid container spacing={2} style={{display:"block"}} data-test-id="slider-container"  className={classes.shopByBrandContainer}>
                          <ShopByBrandSliderDeal {...settings}>
                          {this.state.brandData &&
                        this.state.brandData.map(
                          (brand: IBrandList, index: number) => {
                            return (
                              <Grid
                                data-test-id="brand-item-id"
                                item
                                key={`brand-list-${index}`}
                                xs={12}
                                sm={6}
                                lg={2}
                                className={classes.brandContainer}
                              >
                                <ClickableBox test-dataId="brand-option" className={classes.brandBox} onClick={() => this.handleBrandSelect(
                                  brand.attributes.id
                                )}>
                                  <Box className={classes.brandButton}>
                                    <img
                                      data-test-id="oppoRef"
                                      className={classes.brandImg}
                                      src={
                                        brand.attributes.brand_image ?? logoImg
                                      }
                                    />
                                  </Box>
                                  <Typography className={classes.brandName}>
                                    {brand.attributes.brand_name}
                                  </Typography>
                                </ClickableBox>
                              </Grid>
                            );
                          }
                        )}
                          </ShopByBrandSliderDeal>
                        </Grid>
                      </>
                    )}
                  </Container>
                </Box>
                <Box className={classes.boxOne}>
                  <Grid container spacing={3} className={classes.grid}>
                    <Grid item xs={6}>
                      <FormControlTwo className={classes.formWidth}>
                        <Box className={classes.displayText}>{"Display"}</Box>
                        <Select
                          className={classes.selectBackground}
                          data-test-id="selectPerPage"
                          labelId="demo-simple-select-outlined-label"
                          defaultValue="50"
                          id="demo-simple-select-outlined"
                          value={this.state.perPage}
                          onChange={this.handleProductsPerPage}
                          inputProps={{
                            classes: {
                              select: classes.displaySel,
                            },
                          }}
                          MenuProps={{
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            PaperProps: {
                              style: webStyle.menuStyle,
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          <MenuItemTwo value={50}>50 per Page</MenuItemTwo>
                          <MenuItemTwo value={40}>40 per page</MenuItemTwo>
                          <MenuItemTwo value={30}>30 per page</MenuItemTwo>
                        </Select>
                      </FormControlTwo>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlTwo className={classes.formWidth}>
                        <Box className={classes.displayText}>{"Sort By"}</Box>
                        <Select
                          className={classes.selectBackground}
                          data-test-id="selectRecommonded"
                          labelId="demo-simple-select-outlined-label"
                          defaultValue="recommended"
                          value={this.state.sortByDeal}
                          onChange={this.handleSortByDealOption}
                          inputProps={{
                            classes: {
                              select: classes.displaySel,
                            },
                          }}
                          id="demo-simple-select-outlined"
                          placeholder="Recommonded"
                          MenuProps={{
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            PaperProps: {
                              style: webStyle.menuStyle,
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          <MenuItemTwo value="recommended">
                            Recommended
                          </MenuItemTwo>
                          <MenuItemTwo value="whats_new">
                            What's New
                          </MenuItemTwo>
                          <MenuItemTwo value="popularity">
                            Popularity
                          </MenuItemTwo>
                          <MenuItemTwo value="customer_rating">
                            Customer Rating
                          </MenuItemTwo>
                          <MenuItemTwo value="high_to_low">
                            High to Low
                          </MenuItemTwo>
                          <MenuItemTwo value="low_to_high">
                            Low to High
                          </MenuItemTwo>
                        </Select>
                      </FormControlTwo>
                    </Grid>
                  </Grid>
                  <Box className={classes.boxTwo}>
                    <Box className={classes.displayText}>{"Grid"}</Box>
                    <AppsIcon className={classes.appIcon} />
                  </Box>
                </Box>
                <Divider />
                <Grid container spacing={2}>
                  {this.state.dealProductList &&
                    this.state.dealProductList.map((product: IDealProductList) => (
                      <Grid item xs={12} sm={6} md={3} key={product.id}>
                        <ProductList
                        data-test-id="productList"
                          navigation={this.props.navigation}
                          id="product-list-categories"
                          item={product}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </Box>
          </Container>
          <FooterWeb
            navigation={this.props.navigation}
            id={this.props.id}
            categories={[]}
            token={""}
          />
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  menuStyle: {
    border: "1px solid #b1b3b5",
    padding: 0,
    boxShadow: "none" as const,
    borderRadius: 5,
    background: "white",
    overflowY: "auto" as const,
    marginTop: "5px",
    maxHeight: "400px",
  },
};

const styles = (theme: Theme) => ({
  navDrawer: {
    padding: "20px 0 0 20px",
    [theme.breakpoints.up("sm")]: {
      width: 240,
      flexShrink: 0,
    },
  },
  rootBox: {
    fontFamily: `"Lato", sans-serif`,
    display: "flex",
    backgroundColor: "#fefaf9",
    padding: "20px",
  },
  wrapper: {
    background: "#fff",
    width: "100%",
    overflowX: "hidden" as const,
  },
  containerBox: {
    maxWidth: "1700px",
    padding: "0",
    margin: "0 auto",
  },
  mainContainer: {
    displax: "flex",
    padding: "0 20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    height: "100%",
  },
  categoryContainer: {
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as const,
    position: "relative" as const,
    background: "white",
    padding: "12px 0px",
  },
  headerText: {
    color: "#33333a",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 400,
    fontSize: "20px",
  },
  optionWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  checkbox: {
    padding: "6px",
    color: "#666",
    "&$checked": {
      color: orange[600],
    },
    "&.Mui-checked": {
      color: orange[600],
    },
  },
  optionLabel: {
    color: "#33333a",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
  },
  rightContainer: {
    width: "100%",
    marginBottom: "10px",
  },
  flexOne: {
    display: "flex",
  },
  homeText: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    color: "#585858",
    cursor: "pointer",
  },
  dealName: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    color: "#585858",
  },
  shopText: {
    fontFamily: `"Lato-Heavy", sans-serif`,
    marginTop: "20px",
    fontSize: "32px",
    color: "#33333a",
    fontWeight: 400,
  },
  brandContainer: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  brandBox: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
    alignItems: "center",
  },
  boxOne: {
    display: "flex",
    width: "450px",
    justifyContent: "space-between",
    flexDirection: "row" as const,
    marginLeft: "auto",
    marginBottom: "15px",
  },
  grid: {
    display: "flex",
    justifyContent: "space-between" as const,
    flexDirection: "row" as const,
    marginLeft: "auto",
  },
  formWidth: {
    width: "100%",
  },
  displayText: {
    color: "#666666",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 400,
    fontSize: 12,
    marginBottom: 10,
  },
  displaySel: {
    fontSize: "16px !important",
    fontFamily: `"Avenir-Medium", sans-serif !important` as const,
    color: "#657A8E !important",
    fontWeight: 400,
  },
  boxTwo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    flexDirection: "column" as const,
    margin: "0 10px",
  },
  appIcon: {
    color: "#eb6b2e",
    fontSize: "40px",
  },
  selectBackground: {
    background: "#eee",
  },
  rightContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  brandButton: {
    height: "90px",
    width: "90px",
    borderRadius: "50%",
    border: "none",
    background: "white",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    padding: "10px",
  },
  brandImg: {
    width: "100%",
    objectFit: "fill" as const,
    borderRadius: "50%",
  },
  slider: {
    color: orange[600],
  },
  colorWrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  colorGrid: {
    margin: "10px 0 -10px",
    width: "100%",
  },
  colorBox: {
    width: "18%",
    marginBottom: "16px",
  },
  color: {
    height: "20px",
    width: "20px",
    borderRadius: "2px",
  },
  filterContainer: {
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as const,
    position: "relative" as const,
    background: "white",
    padding: "12px 0px",
  },
  shopByBrandContainer: {
    padding: "16px 32px",
    '& .slick-current': {
        paddingLeft: 40,
        [theme.breakpoints.down('xs')]:{
            padding: 10
        },
    },
    '& .slick-list': {
        height: 180,
        [theme.breakpoints.down('xs')]:{
            height: 180,
        }
    },
},
  brandContainerOne: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  }
});

export default withStyles(styles)(WeeklyDeals);
export { WeeklyDeals };
// Customizable Area End
