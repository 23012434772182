import React from "react";

// Customizable Area Start
import { Box, FormControlLabel, Checkbox, InputAdornment, Grid, Button, Typography, TextField, styled } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { BoxNoScroll } from "../CustomizedElementStyle";
// Customizable Area End

import SellerSuggestionFeedbackController, { Props } from "./SellerSuggestionFeedbackController";
import { errorImage } from "../../../bulkuploading/src/assets";

export default class SellerSuggestionFeedback extends SellerSuggestionFeedbackController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <Box sx={{ padding: "20px 0px 15px 0px", fontSize: "18px", fontWeight: 1000, fontFamily: `"Lato", sans-serif` }}>Suggestion & Feedback</Box>
        <BoxNoScroll sx={{ paddingBottom: 20, overflow: "auto" }}>
          <Box sx={{ borderRadius: "8px", display: "flex", flexDirection: "column", padding: "30px", overflow: "auto" }} style={{ background: "white" }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", marginBottom: 10 }}>
              <Box sx={{ fontSize: "27px", fontWeight: 1000, fontFamily: `"Lato", sans-serif` }}>Suggestion & Feedback</Box>
            </Box>
            <Box sx={{ display: "flex", width: "100%", flexDirection: "row", flexWrap: "wrap" }}>
              <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", alignItems: "center", marginRight: 33, padding: "30px 0px" }}>
                <FormControlLabel
                  label=""
                  data-test-id="suggestionCheckboxRef"
                  checked={this.state.activeCheckbox === "Suggestion" ? true : false}
                  onChange={() => this.handleOnCheckBoxChange("Suggestion")}
                  control={<Checkbox icon={<FiberManualRecordIcon style={{ color: "white" }} />} checkedIcon={<FiberManualRecordIcon style={{ color: "#ff6008", fontSize: 27 }} />} name="checkedH" style={{ border: `1px solid ${this.state.activeCheckbox === "Suggestion" ? "#FF6008" : "#33333a"}`, width: "5px", height: "5px" }} />}
                  style={{ marginRight: "10px", paddingLeft: "10px" }}
                />
                <Box sx={{ fontSize: 16, fontWeight: 500, fontFamily: `"Lato", sans-serif`, marginLeft: 3, color: this.state.activeCheckbox === "Suggestion" ? "#FF6008" : "#33333a" }}>Suggestion</Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", alignItems: "center", marginRight: 33, padding: "30px 0px" }}>
                <FormControlLabel
                  label=""
                  data-test-id="feedbackCheckboxRef"
                  checked={this.state.activeCheckbox === "Feedback" ? true : false}
                  onChange={() => this.handleOnCheckBoxChange("Feedback")}
                  control={<Checkbox icon={<FiberManualRecordIcon style={{ color: "white" }} />} checkedIcon={<FiberManualRecordIcon style={{ color: "#ff6008", fontSize: 27 }} />} name="checkedH" style={{ border: `1px solid ${this.state.activeCheckbox === "Feedback" ? "#FF6008" : "#33333a"}`, width: "5px", height: "5px" }} />}
                  style={{ marginRight: "10px", paddingLeft: "10px" }}
                />
                <Box sx={{ fontSize: 16, fontWeight: 500, fontFamily: `"Lato", sans-serif`, marginLeft: 3, color: this.state.activeCheckbox === "Feedback" ? "#FF6008" : "#33333a" }}>Feedback</Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", alignItems: "center", marginRight: 33, padding: "30px 0px" }}>
                <FormControlLabel
                  label=""
                  data-test-id="queryCheckboxRef"
                  checked={this.state.activeCheckbox === "Query" ? true : false}
                  onChange={() => this.handleOnCheckBoxChange("Query")}
                  control={<Checkbox icon={<FiberManualRecordIcon style={{ color: "white" }} />} checkedIcon={<FiberManualRecordIcon style={{ color: "#ff6008", fontSize: 27 }} />} name="checkedH" style={{ border: `1px solid ${this.state.activeCheckbox === "Query" ? "#FF6008" : "#33333a"}`, width: "5px", height: "5px" }} />}
                  style={{ marginRight: "10px", paddingLeft: "10px" }}
                />
                <Box sx={{ fontSize: 16, fontWeight: 500, fontFamily: `"Lato", sans-serif`, marginLeft: 3, color: this.state.activeCheckbox === "Query" ? "#FF6008" : "#33333a" }}>Ask a Query</Box>
              </Box>
            </Box>
            <Box sx={{ width: "72px", border: "1px solid #EFEFF4" }}></Box>
            <Box sx={{ marginBottom: 70, width: "100%" }}>
    
              <CustomizeTextField
                data-test-id="detailsRef"
                style={{ marginTop: "45px" }}
                label="Details"
                multiline
                placeholder="Type here"
                rows={10}
                value={this.state.detail}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleInputOnChange("detail", event.target.value)}
                id="outlined-start-adornment"
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start"></InputAdornment>
                }}
                InputLabelProps={{
                  style: { fontSize: "15px" }
                }}
              />
            </Box>
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomizeTextField
                  data-test-id="firstNameRef"
                  label="First name"
                  value={this.state.first_name}
                  onChange={event => this.handleInputOnChange("first_name", event.target.value)}
                  id="outlined-start-adornment"
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>
                  }}
                  InputLabelProps={{
                    style: { fontSize: "15px" }
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomizeTextField
                  data-test-id="lastNameRef"
                  label="Last name"
                  value={this.state.last_name}
                  onChange={event => this.handleInputOnChange("last_name", event.target.value)}
                  id="outlined-start-adornment"
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>
                  }}
                  InputLabelProps={{
                    style: { fontSize: "15px" }
                  }}
                />
              </Grid>
            </Grid>
            <CustomizeTextField
              data-test-id="emailRef"
              style={{ marginTop: "45px" }}
              label="Email"
              value={this.state.email}
              onChange={event => this.handleInputOnChange("email", event.target.value)}
              id="outlined-start-adornment"
              fullWidth
              inputProps={{
                readOnly: true
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>
              }}
              InputLabelProps={{
                style: { fontSize: "15px" }
              }}
            />
            <Box sx={{ width: "100%", height: 70, minHeight: 70, display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
              {this.state.errorMessages.map((item, index) => {
                return (
                  <Box sx={{ flexWrap: "no-wrap", paddingTop: "10px", display: "flex", alignItems: "center" }} key={index}>
                    <Box sx={{ marginRight: "11px" }}>
                      <img src={errorImage} />
                    </Box>
                    <Box>
                      <Typography variant="body2" style={{ color: "#D74D4D" }}>
                        {item}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
              {this.state.successMsg.map((item, index) => {
                return (
                  <Box key={index}>
                    <Typography variant="body2" style={{ color: "rgb(61 168 46)" }}>
                      {item}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={this.handleOnSubmit}
                data-test-id="submitRef"
                style={{
                  textTransform: "none",
                  fontFamily: "'Lato', sans-serif",
                  fontWeight: 700,
                  fontSize: "16px",
                  background: "#FF6008",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "8px",
                  padding: "15px 120px"
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </BoxNoScroll>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  TextareaAutosize: {
    marginTop: "10px",
    height: "181px",
    width: "100%",
    borderRadius: "5px",
    padding: "10px",
    border: "1px solid #DDE3E8",
    resize: "none",
    color: "#999999",
    fontSize: "14px",
    "&::placeholder": {
      fontSize: "14px",
      color: "#999999",
      opacity: "0.9",
      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`
    }
  } as const
};
const CustomizeTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    color: "#999999",
    fontSize: "14px"
  },
  "& .MuiInput-formControl": {
    border: "1px solid #DDE3E8",
    margin: 0,
    padding: "10px 0px",
    borderRadius: "5px"
  },
  "& .MuiInputLabel-root": {
    top: -20,
    left: 0,
    color: "black",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 600,
    fontSize: 14
  },
  "& .MuiInput-underline:before, & .MuiInput-underline:after": {
    borderBottom: "none"
  },
  "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
    borderBottom: "none"
  },
  "& .MuiInputBase-input::placeholder": {
    fontSize: 14
  }
});
// Customizable Area End
