import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

// Customizable Area Start
import { createRef } from "react";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activePanel: string;
  activeScreen: string;
  product_id: string;
  productInfo: any[];
  isVariant:boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerAddNewProductController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  sectionRef: React.RefObject<HTMLDivElement>;
  getProductInfoApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.ReceiveMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      activePanel: "productProfile",
      activeScreen: "productProfile",
      product_id: "",
      productInfo: [],
      isVariant:false
    };

    this.sectionRef = createRef();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const id = message.getData(getName(MessageEnum.ProductData));
      if (id) {
        const parsedId = JSON.parse(id);
        this.setState({ product_id: parsedId });
      }
    }
    if (getName(MessageEnum.ReceiveMessage)) {
      const msg = message.getData(getName(MessageEnum.ChangeActivePanelProductDetails)) || false;
      if (msg) {
        this.setState({ activePanel: msg.activePanel, activeScreen: msg.activeScreen,isVariant:msg.isVariant });
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.activeScreen !== this.state.activeScreen) {
      if (this.sectionRef.current) {
        this.sectionRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }
 
  handleTabChange = async (value: number) => {
    const productId = await getStorageData("catalog_id");
    const productContent = await getStorageData("product_content");
    if (value === 0) {
      this.setState({ activePanel: configJSON.productProfileMenu });
    }
    if (value === 1 && productId) {
      this.setState({ activePanel: configJSON.productContentMenu });
    }
    if (value === 2 && productId && productContent) {
      this.setState({ activePanel: configJSON.variantMenu });
    }
    if (value === 3 && productId && productContent) {
      this.setState({ activePanel: configJSON.barcodeMenu });
    }
    if (value === 4 && productId && productContent) {
      this.setState({ activePanel: configJSON.offerMenu });
    }
    if (value === 5 && productId && productContent) {
      this.setState({ activePanel: configJSON.dealsMenu });
    }
  };
  // Customizable Area End
}
