import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configText = require("../src/config.js");
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  match: any;
  userId:string
  classes: {
    header: string,
    container: string,
    middleSec: string,
    wrap: string,
    text: string,
    addBtn: string,
    image: string
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  showAddressForm:boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerAddSingleController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
        showAddressForm:false,        
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  handleGoToCategorizeProducts = () => {
    this.setState({showAddressForm:true})
  };
  // Customizable Area End
}
