import React from "react";

// Customizable Area Start
import { Avatar, Box, ClickAwayListener, Grid, InputAdornment } from "@material-ui/core";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { ClickableBox } from "../../../mobile-account-login/src/CustomElementStyling";
import { bellImage, emptyProfileImg, searchIcon } from "../assets";
import { BoxWithHoverStyle, CustomizeInputSearchBox } from "../CustomizedElementStyle";
// Customizable Area End

import SellerNavigationBarController, { Props, configJSON } from "./SellerNavigationBarController";

export default class SellerNavigationBar extends SellerNavigationBarController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <Box style={webStyles.searchBoxWrapper}>
          {this.state.expandBar && (
            <ClickableBox onClick={this.handleShowMenuBar} data-test-id="showMenuRef" style={webStyles.expandContainer}>
              <ArrowForwardIos style={webStyles.arrow} />
            </ClickableBox>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7} lg={7}>
             {!this.props.noSearchBar && <CustomizeInputSearchBox
                style={{ marginLeft: this.state.expandBar ? "40px" : "0", width: this.state.expandBar ? "86%" : "100%" }}
                data-test-id="globalSearchValue"
                id="outlined-start-adornment"
                value={this.state.searchValue}
                onChange={this.handleGlobalSearch}
                placeholder={configJSON.globalSearchPlaceholderText}
                fullWidth
                autoComplete="off"
                InputProps={{
                  startAdornment: <InputAdornment position="start" style={webStyles.marginOne}></InputAdornment>,
                  endAdornment: (
                    <InputAdornment position="end" style={webStyles.paddingOne}>
                      <img src={searchIcon} alt="searchIcon" />
                    </InputAdornment>
                  ),
                }}
              />}
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} style={webStyles.rightContainer}>
              <Box sx={webStyles.languageBox}>
                {configJSON.languageText}
                <br />
                {configJSON.englishText}
              </Box>
              <Box sx={webStyles.notificationBox}>
                <Box style={webStyles.bell}></Box>
                <img src={bellImage} />
              </Box>
              <ClickAwayListener data-test-id="clickAwayRef" onClickAway={this.handleClickAway}>
                <ClickableBox style={webStyles.box}>
                  <Box data-test-id="openMenuRef" onClick={this.handleOpenProfileMenu}>
                    <Avatar src={this.props.profilePicture} />
                  </Box>
                  <Box style={webStyles.profileContainer}></Box>
                  {this.state.openProfileMenu && (
                    <Box style={webStyles.profileWrapper}>
                      <BoxWithHoverStyle data-test-id="myAccountRef" onClick={this.handleNavigateToSellerMyAccount}>
                        {configJSON.myAccountText}
                      </BoxWithHoverStyle>
                      <BoxWithHoverStyle data-test-id="signOutRef" onClick={this.handleSignOut}>
                        {configJSON.signOutText}
                      </BoxWithHoverStyle>
                    </Box>
                  )}
                </ClickableBox>
              </ClickAwayListener>
            </Grid>
          </Grid>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  searchBoxWrapper: {
    borderRadius: "8px",
    backgroundColor: "white",
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    padding: "16px",
    position: "relative" as const,
  },
  expandContainer: {
    width: 31,
    height: 31,
    borderRadius: "100%",
    position: "absolute" as const,
    background: "#FF6008",
    border: "6px solid #F2F3F4",
    left: 7,
    top: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  arrow: {
    color: "white",
    marginLeft: "4px",
  },
  marginOne: {
    marginRight: 20,
  },
  paddingOne: {
    paddingRight: 20,
  },
  rightContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  languageBox: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: 14,
    fontWeight: 700,
    color: "#51516C",
    marginRight: 20,
  },
  notificationBox: {
    marginRight: 20,
    height: "100%",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  bell: {
    width: 17,
    height: 17,
    borderRadius: "100%",
    backgroundColor: "#FF6008",
    position: "absolute" as const,
    top: 3,
    right: -5,
  },
  box: {
    width: 38,
    height: 38,
    borderRadius: 100,
    position: "relative" as const,
  },
  profileContainer: {
    width: 10,
    height: 10,
    borderRadius: "100%",
    border: "2px solid white",
    position: "absolute" as const,
    backgroundColor: "#71DD37",
    bottom: 0,
    right: 0,
  },
  profileWrapper: {
    position: "absolute" as const,
    width: "228px",
    height: "124px",
    bottom: -144,
    right: 4,
    boxShadow: "0px 5px 18px rgba(0, 0, 0, 0.174425)",
    borderRadius: "5px",
    background: "white",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    zIndex: 999,
  },
};
// Customizable Area End
