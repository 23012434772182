import { v4 as uuidv4 } from 'uuid';
import { runEngine } from '../../../framework/src/RunEngine';
import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum';

export default class SellerDashboardAdapter {
  send: (message: Message) => void;

  constructor() {
    const blockId = uuidv4();
    this.send = message => runEngine.sendMessage(blockId, message);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.NavigationSellerDashboardMessage)
    ]);
  }

  convert = (from: Message): Message => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'SellerDashboard'
    );

    to.addData(
      getName(MessageEnum.NavigationPropsMessage),
      from.getData(getName(MessageEnum.NavigationPropsMessage))
    );

    const raiseMessage:Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));

    raiseMessage.addData(
      getName(MessageEnum.UpdatedSellerProfileInfo),
      from.getData(getName(MessageEnum.UpdatedSellerProfileInfo))
    );

    raiseMessage.addData(
      getName(MessageEnum.ShowSellerDashboardMenu),
      from.getData(getName(MessageEnum.ShowSellerDashboardMenu))
    );

    raiseMessage.addData(
      getName(MessageEnum.ChangeMenuPanel),
      from.getData(getName(MessageEnum.ChangeMenuPanel))
    );

    raiseMessage.addData(
      getName(MessageEnum.WarehouseData),
      from.getData(getName(MessageEnum.WarehouseData))
    );

    raiseMessage.addData(
      getName(MessageEnum.SearchData),
      from.getData(getName(MessageEnum.SearchData))
    );

    raiseMessage.addData(
      getName(MessageEnum.ProductData),
      from.getData(getName(MessageEnum.ProductData))
    );

    raiseMessage.addData(
      getName(MessageEnum.warehouseListExist),
      from.getData(getName(MessageEnum.warehouseListExist))
    );

    raiseMessage.addData(
      getName(MessageEnum.createBrandFromAddProduct),
      from.getData(getName(MessageEnum.createBrandFromAddProduct))
    );

    raiseMessage.addData(
      getName(MessageEnum.BrandData),
      from.getData(getName(MessageEnum.BrandData))
    );

    to.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    return to;
  };

  receive(from: string, message: Message): void {
    this.send(this.convert(message));
  }
}
