// Customizable Area Start
import { withStyles } from "@material-ui/styles";
import React from "react";
import FBBStockMgmtTabsController, {
  Props,
  configJSON,
} from "./FBBStockMgmtTabsController.web";
import {
  Box,
  Grid,
  InputAdornment,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  OrangeButton,
  SearchIconSVG,
} from "../../dashboard/src/CustomizedElementStyle";
import { mobileImg, spaceIcon } from "./assets";
import MoreVertIcon from "@material-ui/icons/MoreVert";

function StockTabPanel(props: any) {
  const {value, index, children, ...other } = props;

  return (
    <div
    hidden={value !== index}
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface RequestTableHeadProps {
    numSelected?: number;
  
    onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    orderBy?: string;
    rowCount?: number;
    classes:any;
  }

  function RequestTableHead(props: RequestTableHeadProps) {
    const { onSelectAllClick, numSelected, rowCount } = props;
  
    return (
      <TableHead>
        <TableRow>
          <TableCell style={webStyle.requestHeaderText}>
            <div style={webStyle.requestHead} >
              <input
              style={webStyle.checkBox}
              type="checkbox" 
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              /> 
              <span style={webStyle.requestDateHeader}>{configJSON.requestDateLabel}</span>
            </div>
          </TableCell>
          <TableCell style={webStyle.requestHeaderText}>{configJSON.requestDetailsLabel}</TableCell>
          <TableCell style={webStyle.requestHeaderText}> {configJSON.imageLabel}</TableCell>
          <TableCell style={webStyle.requestHeaderText}>{configJSON.productNameLabel}</TableCell>
          <TableCell style={webStyle.stockHeaderText}>{configJSON.stockQtyLabel}</TableCell>
          <TableCell style={webStyle.requestHeaderText}>{configJSON.requestStatusLabel}</TableCell>
          <TableCell style={webStyle.requestHeaderTextTwo}>{configJSON.actionLabel}</TableCell>
        </TableRow>
      </TableHead>
    );
  } 
// Customizable Area End

class FBBStockMgmtTabs extends FBBStockMgmtTabsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const { classes } = this.props;
    return (
      <>
        <Box className={classes.storageWrapper}>
          <Tabs
          data-test-id="tabChange"
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: webStyle.tabIndicator
            }}
          >
            <Tab
              label={configJSON.myStorageLabel}
              className={classes.tabsLabel}
              style={{
                textTransform: "capitalize",
                backgroundColor: this.setBackground(0),
              }}
              {...tabProps(0)}
            />
            <Tab
              label={configJSON.myRequestsLabel}
              className={classes.tabsLabel}
              style={{
                textTransform: "capitalize",
                backgroundColor: this.setBackground(1)
              }}
              {...tabProps(1)}
            />
          </Tabs>
          <StockTabPanel
            value={this.state.tabValue}
            index={0}
            className={classes.bckGround}
          >
            <Grid container className={classes.storageHeader}>
              <Grid item xs={5}>
                <Typography
                  className={classes.totalStorageText}
                >
                  {configJSON.myStorageLabel}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography
                  className={classes.desc}
                >
                  {configJSON.storageDescription}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.storageBox}
            >
              <Grid
                item
                xs={12}
                className={classes.totalStorageText}
              >
                {configJSON.totalStorageUsed}
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.box}
              >
                <Box sx={webStyle.container}>
                  <Box sx={webStyle.leftSide}>
                    <Box sx={webStyle.partOne}>
                      <Box>
                        <img alt="storageIcon" src={spaceIcon} />
                      </Box>
                      <Box sx={webStyle.spaceText}>{configJSON.utilizedSpaceText}</Box>
                    </Box>
                    <Box>
                      <Box sx={webStyle.spaceNumber}>
                        40 ft<sup>3</sup>
                      </Box>
                      <Box className={classes.infoText}>{configJSON.extraInfoText}</Box>
                    </Box>
                  </Box>
                  <Box sx={webStyle.rightSide}>
                    <Box><MoreVertIcon style={webStyle.optionIcon}/></Box>
                    <Box sx={webStyle.viewBtnContainer}>
                      <OrangeButton style={webStyle.viewBtn}>
                        {configJSON.viewNowBtnText}
                      </OrangeButton>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </StockTabPanel>
          <StockTabPanel
            value={this.state.tabValue}
            index={1}
            className={classes.bckGround}
          >
            <Grid container>
              <Grid item xs={12} style={webStyle.textWrapper}>
                <Box sx={webStyle.requestText}>{configJSON.myRequestsLabel}</Box>
              </Grid>
            </Grid>
            <Box sx={webStyle.searchContainer}>
              <Typography style={webStyle.searchOrderText}>
                {configJSON.searchRequestText}
              </Typography>
              <Grid container style={styles.storageHeader}>
                <Grid item xs={12} lg={8} md={8} sm={12} style={webStyle.searchBoxWrapper}>
                  <SearchBox
                    data-test-id="searchValue"
                    id="outlined-start-adornment"
                    value={this.state.searchRequestId}
                    onChange={this.handleSearchRequest}
                    placeholder={configJSON.requestIdPlaceholder}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={webStyle.checkBox}
                        ></InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ paddingRight: 20 }}
                        >
                          <SearchIconSVG />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={4} md={4} sm={12} style={webStyle.optionsWrapper}>
                  <Box sx={webStyle.options} style={webStyle.checkBox}>
                    {configJSON.filterBtnText}
                  </Box>
                  <Box sx={webStyle.options}>{configJSON.sortingLabel}</Box>
                </Grid>
              </Grid>

              <Box style={webStyle.topPadding}>
        <TableContainer style={webStyle.tableContainer}>
          <Table aria-label="simple table" stickyHeader style={webStyle.stickyHeader} >
          <RequestTableHead
            data-test-id="reqTableHeader"
            numSelected={this.state.selectedRequests.length}
            onSelectAllClick={this.handleSelectAllRequests}
            rowCount={this.state.requestsList && this.state.requestsList.length} classes={undefined} 
          />
          <TableBody className={classes.bckGround}>
            {this.state.requestsList.length > 0 && this.state.requestsList.map((item:any) => {
              const isItemSelected = this.isSelectedRequests(item.id);
              return(
              <TableRow data-test-id="reqList" key={item.id} style={webStyle.tableRow}>
                <TableCell component="th" scope="row"
                  style={webStyle.tableCellContent}>
                  <div
                  >
                    <input
                    data-test-id="checkBox"
                      style={webStyle.checkBox} 
                      type="checkbox" 
                      id={item.id}
                      onChange={() =>
                        this.handleClickOrdersRow(item.id)
                      }
                      checked={isItemSelected}
                    /> 
                  </div>
                  <div style={webStyle.dateWrapper}>
                      <Box style={webStyle.day}>2 days ago</Box> 
                      <Box style={{...webStyle.buyer, width:'90px'}}>{item.attributes.date}</Box>
                  </div>
                </TableCell>
                 <TableCell style={webStyle.tableRowHeaderText}>
                <Box style={webStyle.buyer}>{item.attributes.details}</Box>
                  <Box style={webStyle.detailsWrapper}>
                    <Typography style={webStyle.buyer}>{configJSON.fullFillmentLabel}</Typography>
                    <Typography style={webStyle.buyerName}>{configJSON.byEzzyText}</Typography>  
                  </Box>
                  <Box style={webStyle.detailsWrapper}>
                    <Typography style={webStyle.buyer}>{configJSON.stockVolumeLabel}</Typography>
                    <Typography style={webStyle.buyerName}>75 x 45 x 125 cm </Typography>
                  </Box>
                  <Box style={webStyle.detailsWrapper}>
                    <Typography style={webStyle.buyer}>{configJSON.stockWeightLabel}</Typography>
                    <Typography style={webStyle.buyerName}>750 gms</Typography> 
                  </Box>
                </TableCell>
                
                <TableCell style={webStyle.tableRowHeaderText}>
                  <img
                    src={mobileImg}
                    style={webStyle.prodImage}
                    alt="img"
                  />
                </TableCell>
                
                <TableCell style={webStyle.tableRowHeaderText}>
                  <Box style={webStyle.buyer}>
                    OPPO A31 
                    <Typography style={webStyle.buyer}> 
                    (Mystery Black, 6GB RAM,
                    128GB Storage)
                    </Typography> 
                  </Box>
                  <Box style={webStyle.detailsWrapper}>
                    <Typography style={webStyle.buyer}>{configJSON.pskuLabel}</Typography>
                    <Typography style={webStyle.buyerName}>PS1200KP</Typography>
                  </Box>
                  <Box style={webStyle.detailsWrapper}>
                    <Typography style={webStyle.buyer}>{configJSON.unitPriceInAED}</Typography>
                    <Typography style={webStyle.buyerName}>4198</Typography>
                  </Box>
                </TableCell>
                <TableCell style={webStyle.stockText}><Box style={webStyle.customerOption}>1000</Box></TableCell>
                <TableCell style={webStyle.verticalAlign}>
                  {item.attributes.status === configJSON.draftText ?
                  <Box style={webStyle.pendingOrderStatus} >
                    {configJSON.draftText}
                  </Box> :
                  <Box style={webStyle.completedOrderStatus} >
                  {configJSON.requestedText}
                </Box>
                  }</TableCell>
                <TableCell style={webStyle.verticalAlign}>
                  <Box style={item.attributes.status === configJSON.requestedText ? webStyle.btnWrapper : webStyle.viewOption}>
                    {configJSON.viewText}
                  </Box>
                  {item.attributes.status === configJSON.requestedText && 
                    <OrangeButton onClick={this.handlePrintBtn} style={webStyle.printLabelBtn}>
                    {configJSON.printLabelPath}
                  </OrangeButton>}
                </TableCell>
              </TableRow>

            )})}
          </TableBody>
          </Table>
        </TableContainer>
        </Box>
            </Box>
          </StockTabPanel>
        </Box>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = {
  storageWrapper: {
    backgroundColor: "rgb(242,243,244)",
    padding: "0 0 15px 0",
  },
  tabsLabel: {
    textTransform: "capitalize" as const,
    fontSize: "14px",
    fontWeight: 700 as const,
    color: "#727272",
  },
  bckGround: {
    backgroundColor: "white",
  },
  storageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  spaceWrapper: {
    border: "0.5px solid rgba(101, 122, 142, 0.320241)",
    marginTop: "60px",
    padding: "20px",
    width: "40%",
    height: "400px",
    borderRadius: "8px",
  },
  totalStorageText: {
    fontSize: "22px",
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`,
  },
  desc: {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: `"Lato", sans-serif`,
    color: "#747474",
  },
  storageBox: {
    border: "0.5px solid rgba(101, 122, 142, 0.320241)",
    marginTop: "60px",
    padding: "20px",
    width: "48%",
    borderRadius: "8px",
    "@media (max-width: 940px)": {
      width: "100%"
    }
  },
  box: {
    border: "0.5px solid rgba(101, 122, 142, 0.320241)",
    padding: "20px",
    marginTop: "20px",
    borderRadius: "8px",
    marginBottom: "150px",
  },
  infoText: {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: `"Lato", sans-serif`,
    color: "rgb(101, 122, 142)",
    "@media (max-width: 940px)": {
      fontSize: "12px",
    }
  },
};

const webStyle = {
  spaceText: {
    fontSize: "20px",
    fontWeight: 400,
    fontFamily: `"Lato", sans-serif`,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  leftSide: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  partOne: {
    marginBottom: "15px",
  },
  spaceNumber: {
    color: "rgb(255,96,8)",
    fontSize: "22px",
    fontWeight: 600,
    fontFamily: `"Lato", sans-serif`,
  },
  rightSide: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  viewBtnContainer: {
    alignItems: "flex-end",
  },
  viewBtn: {
    padding: "10px 30px"
  },
  printLabelBtn: {
    padding: "5px 0px",
    fontSize: 14, 
    fontWeight: 500,
    marginTop:'20px',
    borderRadius:'8px',
    width:'100px',
    fontFamily: `"Lato", sans-serif`,
  },
  textWrapper: {
    width: "100%",
    marginBottom: 10,
  },
  requestHeaderText : {
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: '14px',
    textAlign: 'left' as const,
    fontWeight: 700 as const,
    color: '#333',
    backgroundColor: 'rgba(123,123,123,0.07)',
    padding:'16px 10px'
  },
  requestText: {
    fontSize: "24px",
    fontWeight: 700 as const,
    fontFamily: `"Lato", sans-serif` as const,
  },
  searchContainer: {
    padding: "20px 0px 15px 0px",
  },
  searchOrderText: {
    color: "#33333A",
    fontSize: "12px",
    fontWeight: 400 as const,
    fontFamily: `"Lato", sans-serif` as const,
    marginBottom:'4px'
  },
  searchBoxWrapper: {
    width: "65%",
    position: "relative" as const,
  },
  optionsWrapper: {
    display: "flex",
    flexDirection: "row" as const,
    justifyContent : 'flex-end' as const
  },
  options: {
    padding: "7px 23px",
    color: "#657A8E",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 16,
    fontWeight: 500 as const,
    border: "1px solid rgba(101, 122, 142, 0.320241)",
    borderRadius: 5,
    backgroundColor: "rgb(242,243,244)",
  },
  requestHead: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  dateWrapper : {
    display:'block',
    alignItems:'flex-start'
  },
  detailsTableHead: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  checkBox: {
    marginRight:'20px' 
  },
  tableContainer: {
    border: '0.5px solid #ccc',
    borderRadius:'8px'
  },
  tableCellContent: {
    display:'flex',
    alignItems:'flex-start', 
    borderBottom:'0',
    padding: "16px 10px"
  },
  day: {
    fontWeight: 700 as const,
    color: '#000',
    fontSize:'14px',
    marginBottom:'8px'
  },
  buyer: {
    fontSize:'14px',
    color: '#697A8D',
    fontWeight: 400 as const
  },
  buyerName: {
    fontSize:'14px',
    color: '#000',
    fontWeight: 400 as const
  },
  detailsWrapper: {
    display: 'flex', 
    flexDirection:'column' as const,
    alignItems:'flex-start', 
    marginTop:'10px'
  },
  tableRowHeaderText : {
    color : 'rgb(101, 122, 142)',
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: '12px',
    textAlign: 'left' as const,
    verticalAlign: 'top',
    padding:'16px 10px'
  },
  customerOption : {
    fontWeight: 700 as const,
    color: '#000',
    display:'flex', 
    alignItems:'flex-start', 
    borderBottom:'0',
    fontSize:'14px',
  },
  viewOption: {
    alignItems:'center',
    justifyContent:'center',
    padding: "5px 23px", 
    color: "#657A8E", 
    fontFamily: `"Lato", sans-serif`, 
    fontSize: 14, 
    fontWeight: 500, 
    border: '0px solid rgba(101, 122, 142, 0.320241)',
    backgroundColor: 'rgb(242,243,244)', 
    borderRadius: 5,
    display:'flex',  
    borderBottom:'0',
  },
  prodImage: {
    height: '80px',
    width: '80px',
    objectFit: 'fill' as const,
    borderRadius: '8px'
  },
  pendingOrderStatus: {
    padding: "4px 16px", 
    color: "#657A8E", 
    fontFamily: `"Lato", sans-serif` as const, 
    fontSize: 14, 
    fontWeight: 500 as const, 
    border: '0px solid rgba(101, 122, 142, 0.320241)',
    backgroundColor: 'rgb(242,243,244)', 
    borderRadius: 5,
    borderBottom:'0',
    textAlign: 'center' as const,
    textTransform:'uppercase' as const
  },
  completedOrderStatus: {
    padding: "4px 16px", 
    color: "#60c32b", 
    fontFamily: `"Lato", sans-serif` as const, 
    fontSize: 14, 
    fontWeight: 500 as const, 
    border: '0px solid #ec9e31',
    backgroundColor: '#d4f4c3', 
    borderRadius: 5,
    display:'flex', 
    alignItems:'flex-start', 
    borderBottom:'0',
    textTransform:'uppercase' as const
  },
  btnWrapper : {
    alignItems:'center',
    justifyContent:'center',
    padding: "5px 23px", 
    color: "#657A8E", 
    fontFamily: `"Lato", sans-serif`, 
    fontSize: 14, 
    fontWeight: 500, 
    border: '0px solid rgba(101, 122, 142, 0.320241)',
    backgroundColor: 'rgb(242,243,244)', 
    borderRadius: 5,
    display:'flex',  
    borderBottom:'0',
  },
  optionIcon: {
    fill: 'rgba(0,0,0,0.54)' as const,
    marginTop:'-8px',
    marginRight:'-15px'
  },
  requestHeaderTextTwo: {
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: '14px',
    textAlign: 'center' as const,
    fontWeight: 700 as const,
    color: '#333',
    backgroundColor: 'rgba(123,123,123,0.07)',
    padding:'16px 10px'
  },
  requestDateHeader : {
    width:'85px'
  },
  verticalAlign: {
    verticalAlign:'top',
    padding:'16px 10px'
  },
  tableRow: {
    verticalAlign:'top',
    borderBottom : '1px solid rgb(224, 224, 224)'
  },
  stickyHeader: {
    borderCollapse: "collapse" as const
  },
  searchStyle: {
    '.MuiInputBase-input' : {
      color: "#000",
      fontFamily: "Lato, sans-serif",
      fontSize: "14px",
      fontWeight: 500
    }
  },
  tabIndicator: {
    backgroundColor: "rgb(255,96,8)",
    top: 0,
  },
  topPadding: {
    paddingTop:'20px'
  },
  stockHeaderText : {
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: '14px',
    textAlign: 'center' as const,
    fontWeight: 700 as const,
    color: '#333',
    backgroundColor: 'rgba(123,123,123,0.07)',
    padding:'16px 10px',
    width:'80px'
  },
  stockText: {
    verticalAlign:'top',
    padding:'16px 10px',
    display: "flex",
    alignItems: 'center',
    justifyContent: "center",
    borderBottom: '0'
  }
};

const SearchBox = withStyles(() => ({
  root: {
    "& .MuiInputBase-input": {
      color: '#000',
      fontSize: 14,
      fontFamily: '"Lato", sans-serif',
      fontWeight: 500
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#f44336"
    },
    "& .MuiInputBase-root.Mui-error": {
      border: "1px solid #f44336"
    },
    "& .MuiInputBase-root": {
      border: "1px solid #DDE3E8"
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline
    },
    "& .MuiInputLabel-root": {
      top: -20,
      left: 0,
      color: "#000",
      fontWeight: 600,
      fontSize: 14,
      fontFamily: `"Lato", sans-serif`
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline on hover
    },
    "& .MuiInput-formControl": {
      margin: 0,
      padding: "7px 0px",
      borderRadius: "5px"
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#000",
      fontFamily: "Lato, sans-serif",
      fontSize: "14px",
      fontWeight: 500,
      opacity: 'initial'
    }
  }
}))(TextField);

export { FBBStockMgmtTabs };
export default withStyles(styles)(FBBStockMgmtTabs);
// Customizable Area End
