export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const forword = require("../assets/forword.png");
export const search = require("../assets/search.png");
export const backarrow = require("../assets/backarrow.png");
export const filtersorting = require("../assets/filtersorting.png");
export const leftarrow = require("../assets/leftarrow.png");
export const rightarrow = require("../assets/rightarrow.png");
export const Sucessfull = require("../assets/Sucessfull.png");
export const star = require("../assets/star.png");
export const profileplaceholder = require("../assets/profileplaceholder.png");
export const fillstar = require("../assets/fillstar.png");
export const empty = require("../assets/empty.png");
export const dummyMobile = require("../assets/dummyMobile.png");
export const ExpressLogo = require("../assets/Expresslogo.png");
