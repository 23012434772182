
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { GetCookies } from "../../../framework/src/WebUtilities";


interface InputFieldErr {
    [key: string]: string
}

interface DocumentsData {
    id: string;
    type: string;
    attributes: any
  }
  interface SuccessResponse {
    data: DocumentsData[];
    meta: {
      message: string;
    };
  }

  interface CartSuccessResponse {
    data: DocumentsData;
    meta: {
      message: string;
    };
  }


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    userId: string;
    classes : Record<string,string>
    cartId: (id:string) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start

    token: string,
    userID: string;
    successMsg1: string;
    errorMessages1: string;
    // inputFieldError: InputFieldErr;
    showVerifyDeleteModal: boolean;
    checkoutModal: boolean;
    selectedProductDetails: any;
    selectedValue: string;
    whishList: any[];

    // Customizable Area End
}

interface SS {
    id: any;
}

export default class UserWishlistController extends BlockComponent<
    Props,
    S,
    SS
> {
    getApiWhishlistCallId: string ="";
    removeWishlistApiCallId: string ="";
    addToCartCallId: string ="";
    addToWishlist: string ="";
    
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: "",
            errorMessages1: "",
            selectedProductDetails: "",
            successMsg1: "",
            userID: "",
            whishList: [],
            showVerifyDeleteModal: false,
            checkoutModal: false,
            selectedValue: "0"
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorJson = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        this.handleSuccessResponse(responseJson,apiRequestCallId,errorJson)

        }
        // Customizable Area End
    }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
        await this.getToken();
        this.userIdGetter(this.props.userId)
         await this.handleWhishListApiCall();
  }

  handleWhishListApiCall = async () => {
    const headers = {
    //   "Content-Type": configJSON.contenttype,
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getApiWhishlistCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.whishlistApiEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAddToWhishListApiCall = async (id:any) => {
    const headers = {
      "Content-Type": configJSON.contenttype,
      token: this.state.token
    };
    const body = {
      favouriteable_id: id  
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addToWishlist = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.whishlistApiEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiPostMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  removeWhishListApiCall = async (id:string|number) => {
    const headers = {
      "Content-Type": configJSON.contenttype,
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.removeWishlistApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.removeWhishlistApiEndpoint(id));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addToCartApiCall = async (catalogue_id:string|number) => {
    const headers = {
      "Content-Type": configJSON.contenttype,
      token: this.state.token
    };
    const body ={    // use this api for create(add item to cart) and also update (update item in cart)
        "order_items": {
          catalogue_id,
          "quantity": 1   // please pass the initial value 1 always if user increases pass the number which entered
        }
      }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addToCartCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addTocart);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiPostMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleSuccessResponse = (responseJson: any, apiRequestCallId: any,errorJson:any) => {

    switch (apiRequestCallId) {
      case this.getApiWhishlistCallId:
        this.handleWhishlistApiCallResponse(responseJson,errorJson);
        break;

      case this.removeWishlistApiCallId:
        this.handleRemoveWhishListApiResponse(responseJson,errorJson);
        break;

      case this.addToCartCallId:
        this.handleAddToCartApiResponse(responseJson,errorJson);
        break;

      case this.addToWishlist:
        this.handleAddToWishlistApiResponse(responseJson,errorJson);
        break;

      default: break;
    }
  }

  handleWhishlistApiCallResponse = (responseJson: SuccessResponse,errorJson:any) => {
      if (responseJson) {
          this.setState({ whishList: responseJson.data });
         
      } else {
        this.setState({whishList:[]})
        this.parseApiCatchErrorResponse(errorJson);
      }
  };

  handleAddToCartApiResponse = async (responseJson: CartSuccessResponse,errorJson:any) => {
    if (responseJson) {
        this.setState({ checkoutModal: true })
        this.props.cartId(responseJson.data.id)
    } else {
      this.parseApiCatchErrorResponse(errorJson);
    }
   
  };

  handleAddToWishlistApiResponse = async (responseJson: SuccessResponse,errorJson:any) => {
    if (responseJson) {
      this.handleWhishListApiCall();
    } else {
      this.parseApiCatchErrorResponse(errorJson);
    }
   
  };

  handleRemoveWhishListApiResponse = async (responseJson: SuccessResponse,errorJson:any) => {
    if (responseJson) {
        await this.handleWhishListApiCall();
    } else {
      this.parseApiCatchErrorResponse(errorJson);
    }  
  };

   userIdGetter(userId: any) {
    this.setState({userID:userId }) 
   }

    getToken = async () => {
        const token = GetCookies("ba");
        this.setState({ token })
    }

    async handleOpenVerifyDeleteModal(productDetails: any) {
        // await this.removeWhishListApiCall(productDetails?.id);
        this.setState({ showVerifyDeleteModal: true, selectedProductDetails: productDetails });
    }

    handleSelect(value: string) {
        this.setState({ selectedValue: value })
    }

    handleCloseCheckout=()=> {
        this.setState({ checkoutModal: false})
        this.props.cartId('')
    }

    handleNavigateToCheckout=()=> {
        this.props.navigation.navigate('ShoppingCart')
    }

    async handleUndoRemoveCall() {
        await this.handleAddToWhishListApiCall(this.state.selectedProductDetails?.attributes?.catalogue?.data?.id)
        this.setState({ selectedProductDetails: null ,showVerifyDeleteModal: false});
    }

    async handleCloseUndoModal() {
      this.setState({ showVerifyDeleteModal: false,selectedProductDetails: null });
    }

    handleViewProduct = (itemId: string) => {
      this.props.navigation.navigate("ProductQuickview", {id: itemId })
    }

}