import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("../src/config.js");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    container: string;
    overview: string;
    down: string;
    title: string;
    leftContainer: string;
    rightContainer: string;
    ratingContainer: string;
    rating: string;
    ratingNumber: string,
    feedback: string,
    overallRating: string,
    color: string,
    reviewer: string,
    nextBtn: string,
    previousBtn: string,
    progressBarContainers: string,
    progressBar: string,
    fiveStarColor: string,
    fourStarColor: string,
    threeStarColor: string,
    twoStarColor: string,
    oneStarColor: string,
    starIcon: string,
    review: string,
    topRightContainer : string,
    storelink: string,
    linkColor: string,
    bottomLeftContainer: string,
    ratingBox: string,
    star: string,
    shareText: string,
    sellerFeedback: string,
    marginTop: string,
    marginTopTwo: string,
    marginTopThree: string,
    reviewBox: string,
    btnWrapper: string,
    sellerIcon: string,
    icon: string,
    info: string,
    etradeContainer: string,
    text: string,
    percent: string,
    count: string,
    wrap: string,
    boxPadding: string,
    sellerLogo: string
  };
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  ratingValue: number;
  fullTextIndices: number[]
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerStorefrontController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.ReceiveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      ratingValue: 4,
      fullTextIndices: []
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  reviewInfo = [
    {
        id: '1',
        attributes: {
            name: 'jerald',
            date: '27 October, 2023',
            rating: 1,
            review: "The product is awful. The wall clock glass, side beading and clock body are joined together by pressing. It may break at any time. No screws nothing. It is on s lorem ispum dolor sit amet"
        }
    },
    {
        id: '2',
        attributes: {
            name: 'annie',
            date: '27 October, 2023',
            rating: 4,
            review: "Nice"
        }
    }
  ]

  handleIndices = (index: number) => {
    const updatedIndices = [...this.state.fullTextIndices];
    const currentIndex = updatedIndices.indexOf(index);
  
    if (currentIndex === -1) {
      updatedIndices.push(index);
    } else {
      updatedIndices.splice(currentIndex, 1);
    }
  
    this.setState({ fullTextIndices: updatedIndices });
  }

  getColorProgressBar = (index: number) => {
    const {classes} = this.props;
    if (index === 0){
        return classes.fiveStarColor
    }
    if (index === 1){
        return classes.fourStarColor
    }
    if (index === 2){
        return classes.threeStarColor
    }
    if (index === 3){
        return classes.twoStarColor
    }
    else return classes.oneStarColor
  } 
  // Customizable Area End
}
