// Customizable Area Start
import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import SellerFooterController, {Props} from "./SellerFooterController.web";
// Customizable Area End

class SellerFooter extends SellerFooterController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
      }
    
      // Customizable Area Start
      renderByEzzyRights = () => {
        return(
          <Grid style={webStyle.noBorder} item lg={"auto"} md={12} xs={12} sm={12} >
            <Box sx={webStyle.credits}>@ 2024 ByEzzy.com All rights reserved</Box>
          </Grid>
        )
      }
    
      footer = () => {
        return (
          <Grid container style={webStyle.footerContainer}>
            <Grid item lg={12} sm={12} xs={12} style={webStyle.footerGrid}>
              <Box sx={webStyle.footerBox}>
                <Grid justifyContent="space-between" spacing={0} container >
                  <Grid item>
                    <Grid spacing={0} container>
                      <Grid item style={webStyle.noBorder}>
                        <Box
                          sx={webStyle.sellText}>
                          Sell with ByEzzy
                        </Box>
                        <Grid container spacing={0}>
                          <Grid item>
                            <Box
                              sx={{
                                marginBottom: "20px",
                                fontSize: 14,
                                color: "white",
                                marginRight: "40px",
                              }}>
                              <Grid  spacing={1} container direction="column">
                                <Typography style={webStyle.textOne} data-test-id="cost" onClick={() => this.setFooterTitle("Cost & Commission details")}>Cost & Commission details</Typography>
                                <Typography style={webStyle.textOne} data-test-id="guide" onClick={() => this.setFooterTitle("Beginner's guide")}>Beginner's guide</Typography>
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box
                              sx={{
                                marginBottom: "20px",
                                marginRight: "40px",
                                fontSize: 14,
                                color: "white",
                              }}>
                              <Grid container spacing={1} direction="column">
                              <Typography style={webStyle.textOne} data-test-id="fulfillment" onClick={() => this.setFooterTitle("Fulfillment by ByEzzy")}>Fulfillment by ByEzzy</Typography>
                              <Typography style={webStyle.textOne} data-test-id="advertise" onClick={() => this.setFooterTitle("Advertise on ByEzzy")}>Advertise on ByEzzy</Typography>
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box
                              sx={{
                                fontSize: 14,
                                marginRight: "40px",
                                color: "white",
                                marginBottom: "20px",
                              }}>
                              <Grid container spacing={1} direction="column">
                              <Typography style={webStyle.textOne}  data-test-id="brand-store" onClick={() => this.setFooterTitle("Brand store")}>Brand store</Typography>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item style={{ marginBottom: "20px", border: "none" }}>
                        <Box
                          sx={{
                            fontSize: 18,
                            fontWeight: 600,
                            marginBottom: "20px",
                          }}>
                          ByEzzy Legal
                        </Box>
                        <Grid container spacing={1}>
                          <Grid item>
                            <Box sx={{ color: "white", fontSize: 14, marginRight: "40px", marginLeft: '5px' }}>
                              <Grid container spacing={1} direction="column">
                                <Typography data-test-id="privacy-one" style={webStyle.textOne} onClick={this.handlePrivacy}>
                                  Privacy notice
                                </Typography>
                                <Typography data-test-id="terms-one" style={webStyle.textOne} onClick={this.handleTerms}>
                                  Terms of service
                                </Typography>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {this.renderByEzzyRights()}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        );
      };
      // Customizable Area End
    render() {
        // Customizable Area Start
      // Customizable Area End
      return(
        // Customizable Area Start
        <>
        {this.footer()}
        </>
        // Customizable Area End
      )
    }  
}

// Customizable Area Start
const webStyle = {
    credits: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      fontWeight: 500,
      fontSize: 14,
      color: "white"
    },
    textOne: {
      fontFamily: "Lato, sans-serif",
      fontSize: '14px',
      color: 'white',
      cursor: 'pointer'
    },
    footerContainer: {
      padding: '0  40px', 
      background: 'rgb(243, 115, 43)', 
      color: 'white', 
      fontFamily: 'Lato, sans-serif'
    },
    footerGrid: { 
      padding: "49px 0px" 
    },
    footerBox: { 
      border: "none", 
      width: "100%" 
    },
    noBorder: { 
      border: "none" 
    },
    sellText: {
      fontSize: 18,
      fontWeight: 600,
      marginBottom: "20px",
    }
  };

export default SellerFooter  
// Customizable Area End