import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("../src/config.js")

export interface FormValues {
  requestExchange: string;
  requestType: string;
  returnReason: string;
  description: string;
}

export const initialValues = {
  requestExchange: '',
  requestType: '',
  returnReason: '',
  description: '',
};
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes : {
    containerWrraper : string,
    formWrraper : string,
    selectEmpty : string,
    fieldContainer : string,
    fieldWrraper : string,
    titleClass : string,
    subtitleClass : string,
    titleWrraper : string,
    buttonContainer : string,
    errorStyle : string,
    formControl : string,


  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  menuOpen:any;
  menuOpen2:any;
  // Customizable Area End
}
interface SS {
  id: any;
}


export default class ReturnAndWarrentyClaimsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      menuOpen:false,
      menuOpen2:false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  
  handleMenuOpen = () => {
    this.setState({ menuOpen: true });
  };
  
  handleMenuOpen2 = () => {
    this.setState({ menuOpen2: true });
  };

  handleMenuClose = () => {
    this.setState({ menuOpen: false });
    this.setState({ menuOpen2: false });
  };

  onSubmit = (values: FormValues, { resetForm }: { resetForm: () => void }) => {
    // Handle form submission logic here
    console.log(values);
    // Reset the form after submission
    resetForm();
  };
    // Customizable Area End
}
