Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.contentType = "application/json";
exports.apiSignUpEndpoint = "/account_block/accounts";
exports.postMethod = "POST";
exports.apiEmailLinkEndpoint = "account_block/accounts/resend_email?token=";
exports.apiVerifyEmailLinkEndpoint = "account_activation?token="; 
exports.getMethod = "GET";
exports.phoneNumberErrFormat = "Please enter a valid phone number in the format 971-xx-xxxxxxx, where 'xx' represents digits."
exports.phoneFormatRegex = /^971-\d{2}-\d{7}$/;
exports.phoneNumberLabel = "Phone number";
exports.passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[^\s]{8,32}$/;
exports.emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.nameRegex =/^[a-zA-Z]+$/
exports.companyNameRegex =  /^[a-zA-Z0-9 .]+$/;
exports.firstNameValidationMessage = 'First name should not contains special characters and numbers';
exports.lastNameValidationMessage = 'Last name should not contains special characters and numbers';
exports.companyNameValidationMessage = 'Invalid company name. only letters, numbers, spaces, and dots are allowed.';
exports.genericErrMsg = "Please provide all of the required information!";
exports.textLabel = "text";
exports.sellerHomeText = "SellerHomeDashboard";
exports.addProductPath = "add-product";
exports.featuresText = "Features";
exports.whyusText = "Why us?";
exports.pricingText = "Pricing";
exports.signupForFreeText = "Sign Up for free";
exports.signupForFreeAboveText = "First 30 sellers will enjoy a complimentary marketing campaign";
exports.signUpForFreeBelowText = "for 3 months to boost your visibility"
exports.sellerAccCreateButByerAccExists = "Seller account created, but buyer account already exists";
exports.sellerAlreadyLoggedIn = "A seller is already logged in. Please sign out to create a new account."
exports.emailAlreadyTaken = "Email has already been taken";
exports.firstNameRequired = "First name is required";
exports.firstNameValidationErr = "First name must not exceed 50 characters";
exports.lastNameRequired = "Last name is required";
exports.lastNameValidationErr = "Last name must not exceed 50 characters"
exports.companyNameRequired = "Company name is required";
exports.companyNameValidationErr = "Company name must not exceed 50 characters";
exports.invalidEmailText = "Invalid email";
exports.emailReqText = "Email is required";
exports.emailValidationErr = "Email must not exceed 256 characters";
exports.phoneNumberReqText = "Phone number is required";
exports.minPasswordText = "Password should be at least 8 characters long";
exports.confirmPassReq = "Confirm password required";
exports.acceptTermsConditionsText = "Please accept Terms & Conditions and Privacy Statement!"
exports.welcomeToByEzzyText = "Welcome to ByEzzy!";
exports.joinUaeText = "Join the UAE's next generation e-commerce marketplace";
exports.marketPlaceText = "GROW YOUR SALES?";
exports.startSellingText = "Sell With Us. Tap Into More than 100,000 New Potential Customers.";
exports.growYourBusinessText = "Grow Your Business.";
exports.registerForFreeText = "Register for free and start selling";
exports.listingProductsText = "by listing your products.";
exports.rightsReservedText = "@ 2024 ByEzzy.com All rights reserved";
exports.privacyNoticeText = "Privacy notice";
exports.termsOfServiceText = "Terms of service";
exports.byEzzyLegalText = "ByEzzy Legal";
exports.sellWithByezzyText = "Sell with ByEzzy";
exports.staticApiEndpoint = "/bx_block_termsandconditions/seller_static_pages";
exports.backHomeText = "Back to Home";
exports.supportDocumentsApiEndpoint = "bx_block_support/support_documents";
// Customizable Area End
