//@ts-nocheck
import React from "react";

// Customizable Area Start
import { Box, FormControlLabel, Grid, FormHelperText, Radio, RadioGroup, withStyles, Button } from "@material-ui/core";
import { BoxNoScroll } from "../CustomizedElementStyle";
// Customizable Area End

import SellerBulkProductUploadController, { Props } from "./SellerBulkUploadController";

export default class SellerBulkProductUpload extends SellerBulkProductUploadController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  // Customizable Area Start
  render() {
    const { parentComp } = this.props;
    const CustomRadio = withStyles({
      root: {
        color: "gray",
        borderRadius: "0",
        width: "37px",
        height: "37px",
        fontSize: "40px",
        "&$checked": {
          color: "#5f2204"
        },
        "& svg": {
          width: "37px",
          height: "37px",
          fontSize: "40px"
        }
      },
      checked: {}
    })(props => <Radio color="default" {...props} />);

    return (
      <>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box style={webStyle.headerStyle}>Product Catalog</Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}></Box>
        </Box>
        <BoxNoScroll style={{ paddingBottom: 20, overflow: "auto" }}>
          {!this.state.nxtBtnClicked && (
            <Box style={webStyle.headingBoxStyle as React.CSSProperties}>
              <div style={webStyle.subHeaderStyle}>Choose template</div>
              <div style={webStyle.chooseStyle}>Choose the template based on which language you want to provide data. </div>

              <Grid container spacing={2} style={{ paddingInline: "45px", margin: "0px" }}>
                <RadioGroup aria-label="gender" style={webStyle.radioGrp} data-test-id="radioGrp" value={this.state.value} onChange={e => this.handleRadio(e.target.value)}>
                  <Grid item xs={12} sm={4} md={4} lg={4} style={{ flexGrow: 1, flexBasis: "50%", marginRight: "20px" }}>
                    <FormControlLabel
                      value="english"
                      data-test-id="formControl"
                      style={{ ...webStyle.formCntrlLbl, backgroundColor: this.state.value === "english" ? "#ffb084" : "", border: this.state.value === "english" ? "2px solid #ffb084" : "2px solid #cfcfcf" }}
                      control={<CustomRadio value="english" />}
                      label={
                        <div>
                          <FormHelperText style={{ ...webStyle.formHlpTxt, color: this.state.value === "english" ? "#5f2204" : "#7C7C7C" }}>Download Template</FormHelperText>
                          <FormHelperText
                            style={{
                              color: this.state.value === "english" ? "#5f2204" : "#8B8B8B",
                              fontFamily: "lato",
                              fontSize: "14px"
                            }}
                          >
                            Template for bulk product uploading
                          </FormHelperText>
                        </div>
                      }
                    ></FormControlLabel>
                  </Grid>
                </RadioGroup>
              </Grid>
              <Box style={{ ...webStyle.btnContainer }} width={"98%"}>
                <Button style={{ width: "150px", backgroundColor: "#444", color: "white", fontSize: "20px", fontWeight: 700, borderRadius: "8px", fontFamily: "lato", textTransform: "none", height: "60px" }} onClick={this.handleCancel}>
                  Cancel
                </Button>
                <Button style={{ width: "150px", backgroundColor: "#FF6008", color: "white", fontSize: "20px", fontWeight: 700, borderRadius: "8px", fontFamily: "lato", textTransform: "none", height: "60px" }} data-test-id="nxtBtn" onClick={this.handleNext}>
                  Next
                </Button>
              </Box>
            </Box>
          )}
          <Box sx={webStyle.footerNavBulk}>
            <Box sx={webStyle.itemNavBulk}>Upgrade</Box>
            <Box sx={webStyle.itemNavBulk}>More Themes</Box>
            <Box sx={webStyle.itemNavBulk}>Support</Box>
            <Box sx={webStyle.itemNavBulk}>Contact</Box>
          </Box>
        </BoxNoScroll>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  subHeaderStyle: {
    padding: "2rem 2rem",
    fontSize: "36px",
    fontWeight: 700,
    fontFamily: "Lato, sans-serif",
    borderBottom: "1px solid #F2F3F4"
  },
  headerStyle: {
    padding: "20px 0px 15px 0px",
    fontSize: "18px",
    fontWeight: 600,
    fontFamily: "Lato, sans-serif"
  },

  chooseStyle: {
    padding: "2rem 2rem",
    fontSize: "12px",
    fontWeight: 900,
    fontFamily: "Lato, sans-serif",
    color: "#666666",
    paddingBottom: "15px"
  },
  radioGrp: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  headingBoxStyle: {
    // minHeight: 753,
    borderRadius: "8px",
    background: "white",
    display: "flex",
    flexDirection: "column",
    padding: "0px 0px 30px 0px",
    position: "relative"
  },

  formCntrlLbl: {
    gap: 2,
    borderRadius: "15px",
    border: "2px solid gray",
    padding: "15px 15px",
    width: "230px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  btnContainer: { display: "flex", justifyContent: "end", marginTop: "30%", gap: "24px" },
  formHlpTxt: {
    color: "#7C7C7C",
    fontWeight: 600,
    fontFamily: "lato",
    fontSize: "16px",
    lineHeight: "20px"
  },
  webStyle: {
    width: "40px",
    height: "40px",
    fontSize: "30px"
  },
  footerNavBulk: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    color: "#B1B2B4",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px"
  },
  itemNavBulk: {
    padding: "50px 0px 10px 20px",
    fontWeight: 600
  }
};

// Customizable Area End
