import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("../src/config.js")

interface IRequestList {
  id: number,
  attributes: {
    stock_condition: string,
    days:string,
    request_id: string,
    product_name: string,
    brand: string,
    date: string,
    psku: string,
    price: string,
    unit: string
  }
}

interface IDamageList {
  id: number,
  attributes: {
    days:string,
    order_id: string,
    product_name: string,
    brand: string,
    date: string,
    psku: string,
    price:string,
    unit: string,
    damage_description: string
  }
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes : {
    tabsLabel: string,
    storageHeaderDamage: string,
    byezzyWrap: string,
    byezzy: string,
    text: string,
    overview: string,
    topMargin: string,
    searchContainer: string,
    searchDamagesText: string,
    searchDamages: string,
    searchInput: string,
    flex: string,
    tableHeaderText: string,
    checkBox: string,
    tableRowContainer: string,
    prodName: string,
    orderId: string,
    table: string,
    returnOption: string,
    centerOne: string,
    centerTwo: string,
    action: string,
    btnWrap: string
  }
  handlePrintLabel: (value: boolean) => void
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  authToken: string;
  tabValue: number;
  searchRequestId: string;
  selectedReq: number[];
  requestsList: IRequestList[];
  selectedReturnReq: number[],
  damageList: IDamageList[],
  searchReturnReqId: string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class FBBDamagesAndReturnsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      authToken: "",
      tabValue: 0,
      searchRequestId: '',
      selectedReq: [],
      requestsList: [],
      selectedReturnReq: [],
      damageList: [],
      searchReturnReqId: ""
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  damageList = [
    {
      id: 1,
      attributes: {
        days:"2 days ago",
        order_id: "ABC1234567",
        product_name: "OPPO A31",
        brand: "Mystery Black, 6GB RAM, 128GB Storage",
        date: '23/05/23 01:26PM GST',
        psku: "PS1200KP",
        price: "4198",
        unit: "2",
        damage_description: "Product received in damage condition at the warehouse. Packing not done properly."
      }
    },
    {
      id: 2,
      attributes: {
        days:"2 days ago",
        order_id: "ABC1234567",
        product_name: "OPPO A31",
        brand: "Mystery Black, 6GB RAM, 128GB Storage",
        psku: "PS1200KP",
        date: '23/05/23 01:26PM GST',
        price: "4198",
        unit: "1",
        damage_description: "Product received in damage condition at the warehouse. Packing not done properly."
      }
    }
  ]

  returnsList = [
    {
      id: 1,
      attributes: {
        stock_condition: "Good",
        days:"2 days ago",
        request_id: "FD65456DFDF456F",
        product_name: "OPPO A31",
        brand: "Mystery Black, 6GB RAM, 128GB Storage",
        date: '23/05/23 01:26PM GST',
        psku: "PS1200KP",
        price: "4198",
        unit: "2"
      }
    },
    {
      id: 2,
      attributes: {
        stock_condition: "Damaged",
        days:"2 days ago",
        request_id: "FD65456DFDF456F",
        product_name: "OPPO A31",
        brand: "Mystery Black, 6GB RAM, 128GB Storage",
        psku: "PS1200KP",
        date: '23/05/23 01:26PM GST',
        price: "4198",
        unit: "1"
      }
    }
  ]

  handleClickReturnRow = (num: number) => {
    const selectedIndex = this.state.selectedReturnReq.indexOf(num);

    let newSelected: number[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selectedReturnReq, num);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selectedReturnReq.slice(1));
    } else if (selectedIndex === this.state.selectedReturnReq.length - 1) {
      newSelected = newSelected.concat(
        this.state.selectedReturnReq.slice(0, -1)
      );
    }
    this.setState({
      selectedReturnReq: newSelected,
    });
  };

  handleClickDamageRow = (num: number) => {
    const selectedIndex = this.state.selectedReq.indexOf(num);

    let newSelected: number[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selectedReq, num);
    } 
    else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selectedReq.slice(1));
    } else if (selectedIndex === this.state.selectedReq.length - 1) {
      newSelected = newSelected.concat(
        this.state.selectedReq.slice(0, -1)
      );
    }
    this.setState({
      selectedReq: newSelected,
    });
  };

  handleTabChange = (event:React.ChangeEvent<{}>, value:number) => {
    this.setState({tabValue: value})
  }

  handleGetToken = async () => {
    const authToken = await getStorageData("authToken");
    this.setState({ authToken });
  };

  handleSelectAllDamageRequest = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = this.state.damageList.map((n: {id: number}) => n.id);
      this.setState({ selectedReq: newSelected });

    } else {
      this.setState({ selectedReq: [] });
    }
  };

  handleSelectAllReturnReq = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = this.state.requestsList.map((n: {id: number}) => n.id);
      this.setState({ selectedReturnReq: newSelected });

    } else {
      this.setState({ selectedReturnReq: [] });
    }
  };

  async componentDidMount(): Promise<void> {
    this.handleGetToken();
    this.setState({
      requestsList : this.returnsList,
      damageList: this.damageList
    })
  }

  isSelectedReq = (num: number) => {
    if(this.state.selectedReq.indexOf(num) !== -1) return true;
    else return false
  }

  isSelectedReturnReq = (num: number) => {
    if(this.state.selectedReturnReq.indexOf(num) !== -1) return true;
    else return false
  }

  setDamageTabBackgrnd = (value: number) => {
    return this.state.tabValue === value ? "white" : "rgb(242,243,244)"
  }

  handleSearchRequestId = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchRequestId : event.target.value})
  }

  handleSearchReturnReqId = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchReturnReqId : event.target.value})
  }
  // Customizable Area End
}
