Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.searchApiContentType = 'application/json'
exports.getSearchApiEndPoint = 'search/users'
exports.errorTitle = 'Error';
exports.getSuggestionApiEndPoint = "bx_block_dashboard/global_searches/search";
exports.httpPostMethod = 'POST';
exports.noSearchResultFound = "No Search Result Found"
// Customizable Area End
