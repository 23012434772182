import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { GetCookies } from "framework/src/WebUtilities";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
export const configJSON = require("../src/config.js")

export interface ShipDetails {
  id: number | string,
  shipping_details?: null | string,
  order_id?: number,
  created_at?: string,
  updated_at?: string,
  courier_name: string,
  tracking_number: string
} 

export interface IOrderDetails {
  id: number | any,
  order_number: string,
  ordered_at: string,
  total_items: number,
  total_fees: number,
  total_tax: number,
  time_passed_since_order_placed: string,
  final_price: number,
  accepted: boolean,
  shipping_address: {
    data: {
      attributes: {
        address_1: string,
        address_2: string,
        city: string,
        phone_number: number,
        state: string,
        zip_code: number
      }
    } | null
  },
  order_status: {
    data: {
      id: string,
      attributes: {
        name: string
      }
    }
  },
  seller: {
    data: {
      id: string,
      attributes: {
        full_name: string
      }
    }
  },
  customer: {
    data: {
      id: string,
      attributes: {
        full_name: string
      }
    }
  },
  order_items: {
    data: [
      {
        id: string,
        attributes: {
          quantity: number,
          price: number,
          item: {
            catalogue: {
              data: {
                id: string,
                attributes: {
                  product_image: string,
                  product_title: string,
                  brand: {
                    brand_name: string
                  },
                  product_content: {
                    product_attributes: {
                      unique_psku: string
                    }
                  }
                }
              }
            }
          }
        }
      }
    ]
  },
  shipped_order_details: Array<ShipDetails>
}

export interface IOrderItem {
  id: string,
  attributes: {
    quantity: number,
    price: number,
    item: {
      catalogue: {
        data: {
          id: string,
          attributes: {
            product_image: string,
            product_title: string,
            brand: {
              brand_name: string
            }
            product_content: {
              product_attributes: {
                unique_psku: string
              }
            }
          }
        }
      }
    }
  }
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    wrapper: string,
    headerWrapper: string,
    orderDetailsHeader: string,
    orderDetailsText: string,
    viewOption: string,
    btnWrapper: string,
    printLabelBtn: string,
    componentWrapper: string,
    orderBox: string,
    left: string,
    leftContainer: string,
    right: string,
    prodImage: string,
    productDetails: string,
    productInfo: string,
    productName: string,
    color: string,
    brandName: string,
    quantity: string,
    detail: string,
    completedOrderStatus: string,
    topRight: string,
    bottomRight: string,
    orderSummary: string,
    cancelledOrderStatus: string,
    pendingOrderStatus: string,
    padding: string,
    prodImageContainer: string,
    imageBox: string,
    detailValue: string,
    priceValue: string,
    priceBox: string,
    refundBtn: string,
    refundWrap: string,
    orderBox1: string,
    productBox: string,
    itemWrapper: string,
    leftMargin: string,
    customerWidth: string,
    summaryText: string,
    totalQty: string,
    quantitySec: string,
    dividerOne: string,
    dividerTwo: string,
    bold: string,
    main: string,
    border: string
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  authToken: string;
  orderDetails: IOrderDetails,
  orderId: string,
  openShip: boolean,
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerPartnerOrderDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  updateOrderApiCallId: string = ""
  getOrderDetailsApiCallId: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      openShip: false,
      authToken: "",
      orderDetails: {
        id: "",
        order_number: "",
        ordered_at: "",
        total_items: 0,
        total_fees: 0,
        total_tax: 0,
        time_passed_since_order_placed: "",
        final_price: 0,
        accepted: false,
        shipping_address: {
          data: {
            attributes: {
              address_1: "",
              address_2: "",
              city: "",
              phone_number: 0,
              state: "",
              zip_code: 0
            }
          }
        },
        order_status: {
          data: {
            id: "",
            attributes: {
              name: ""
            }
          }
        },
        seller: {
          data: {
            id: "",
            attributes: {
              full_name: ""
            }
          }
        },
        customer: {
          data: {
            id: "",
            attributes: {
              full_name: ""
            }
          }
        },
        order_items: {
          data: [
            {
              id: "",
              attributes: {
                quantity:1,
                price: 0,
                item: {
                  catalogue: {
                    data: {
                      id: "",
                      attributes: {
                        product_image: "",
                        product_title: "",
                        brand: {
                          brand_name: ''
                        },
                        product_content: {
                          product_attributes: {
                            unique_psku: ""
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          ]
        },
        shipped_order_details: []
      },
      orderId: ''
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.getOrderDetailsApiCallId) {
        this.handleOrderResponse(responseJson)
        this.setState({ openShip: false})
      }
      if (apiRequestCallId === this.updateOrderApiCallId) {
        this.getOrderDetails(this.state.orderId)
      }
    }
  }

  handleOrderResponse = (responseJson:any) => {
    if(responseJson) {
      this.setState({ orderDetails: responseJson[0]})
    }
  }

  async componentDidMount(): Promise<void> {
    const orderId = GetCookies('sellerOrderId')
    this.setState({orderId: orderId})
    if(orderId) {
      this.getOrderDetails(orderId)
    }
  }

  getOrderDetails = async (orderId:string) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: await getStorageData("authToken"),
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrderDetailsApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/orders/${orderId}/show_seller_order`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(message.id, message);
  }

  getOrderDate = (value: string) => {
    const parsedDate = new Date(value);
    const day = parsedDate.getDate();
    const month = parsedDate.getMonth() + 1; 
    const year = parsedDate.getFullYear();
    return `${day}/${month}/${year}`;
  }

  getOrderTime = (value: string) => {
    const parsedDate = new Date(value);
    const hour = parsedDate.getHours().toString().padStart(2, '0');
    const minute = parsedDate.getMinutes().toString().padStart(2, '0');
    
    const ampm = hour >= '12' ? 'PM' : 'AM';
    const setHour = (parseInt(hour) % 12) || 12;
    const formattedHour = setHour.toString().length === 1 ? `0${setHour}`: setHour

    return `${formattedHour}:${minute} ${ampm}`;
  }

  getOrderDetailsStatus = (status:string) => {
    if (status === configJSON.orderedText) {
      return configJSON.paymentDoneText
    }
    if(status === configJSON.processingText){
      return configJSON.processingText
    }
    if(status === configJSON.shippedText){
      return configJSON.shippedText
    }
    if(status === configJSON.deliveredText){
      return configJSON.deliveredText
    }
    if(status === configJSON.rejectedText){
      return configJSON.rejectedText
    }
    if(status === configJSON.cancelledText) {
      return configJSON.cancelledText
    }
  }

  getOrderDetailsStatusClass = (status:string) => {
    if ((status === configJSON.orderedText || status === configJSON.deliveredText)) {
      return this.props.classes.completedOrderStatus
    }
    if((status === configJSON.processingText) || (status === configJSON.rejectedText) || (status === configJSON.cancelledText)){
      return this.props.classes.cancelledOrderStatus
    }
    if(status === configJSON.shippedText){
      return this.props.classes.pendingOrderStatus
    }
  }

  handleAcceptBtn = () => {
    this.updateOrder(this.state.orderId,'processing')
  }

  handleRejectOrderBtn = () => {
    this.updateOrder(this.state.orderId,'rejected')
  }

  renderHighestIdObj = (orders: Array<ShipDetails>) => {
    return orders.reduce((max, obj) => obj.id > max.id ? obj : max, orders[0]) || {id: "", tracking_number: "", courier_name: ""};
  }

  updateOrder = async (orderId:string, status: string) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: await getStorageData("authToken"),
    };
    const httpBody = {
      "orders": {
        "status": status ,
        "accepted": true
      }
    }
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateOrderApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/orders/${orderId}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePatch
    );
    runEngine.sendMessage(message.id, message);
  }

  handleRefundButton = () => {}

  getButtonWrapStyles = (status: string) => {
    return status === configJSON.rejectedText ? this.props.classes.refundWrap: this.props.classes.headerWrapper
  }

  handleOpenShipModal = (value: boolean) => {
    this.setState({ openShip: value})
  }
  // Customizable Area End
}
