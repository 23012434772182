import React from "react";

// Customizable Area Start
import { theme } from "../../dashboard/src/CustomizedElementStyle";
import { ThemeProvider } from "@material-ui/core/styles";
import { Box, Container, Grid, Divider, Hidden } from "@material-ui/core";
import WhyusController, { Props } from "./WhyusController.web";
import DOMPurify from "dompurify";
import { objectsImg } from "./EmailAccountRegistrationController";
import SellerFooter from "./SellerFooter.web";
import SellerLandingNavbar from "./SellerLandingNavbar.web";
// Customizable Area End

class Whyus extends WhyusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  backgroundImg = () => {
    return(
      <Hidden smDown>
        <Box sx={webStyle.imgBox}>
          <img src={objectsImg} width={"90%"} />
        </Box>
      </Hidden>
    )
  }
  // Customizable Area End

  // Customizable Area Start
  render() {
    // Customizable Area Start
    return (
      <>
      <SellerLandingNavbar navigation={this.props.navigation} id={this.props.id} />
        <ThemeProvider theme={theme}>
          <Box style={{  justifyContent: "center", width: "100%", display: "flex" }}>
          {this.backgroundImg()}
            <Container style={{ position: "relative", textAlign: "start", maxWidth: "96vw" }}>
                {this.state.whyUsContent &&
                <Grid container style={{ marginTop: 160 }}>
                <Grid item lg={12} sm={12} xs={12} style={{marginBottom:'60px'}}>
                  <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.whyUsContent.content)}} data-test-id="whyusContent" >
                  </div>
                  </Grid>
                  </Grid>
                }
            </Container>
          </Box>
          <Divider />
          <SellerFooter navigation={this.props.navigation} id={this.props.id} />
        </ThemeProvider>
      </>
    );
    // Customizable Area End
  }
  // Customizable Area End
}

// Customizable Area Start
export default Whyus;
const webStyle = {
  imgBox: { 
    top: 176, 
    left: 25, 
    position: "absolute" as const,
    zIndex: 0 
  }
};
// Customizable Area End
