import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

// Customizable Area Start
import { InputProps } from "@material-ui/core/Input";
import { createRef } from "react";
import { getStorageData, setStorageData } from "../../../../framework/src/Utilities";
import { ICreateBrandErrorReponse, ICreateBrandSuccessReponse } from "./Interfaces";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  brand_name: string;
  brand_name_arabic: string;
  brand_year: string;
  brand_website: string;
  branding_tradmark_certificate: File | "";
  errorMsg: string;
  successMsg: any;
  authToken: string;
  listResponse: any;
  toShowBrandCatelog: boolean;
  rowData: any;
  loading: boolean;
  openModal: boolean;
  rowId: string;
  activeStep: string;
  openErrorPopup: boolean,
  delBrandErr: string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerBrandCatalogListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  inputBrandRef: React.RefObject<InputProps & { click: Function }>;
  apiCreateBrandCallId: string = "";
  apiDeleteBrandCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      brand_name: "",
      brand_name_arabic: "",
      brand_year: "",
      brand_website: "www.",
      branding_tradmark_certificate: "",
      errorMsg: "",
      successMsg: "",
      authToken: "",
      listResponse: [],
      toShowBrandCatelog: false,
      rowData: {},
      loading: false,
      openModal: false,
      rowId: "",
      activeStep: "brandList",
      openErrorPopup: false,
      delBrandErr: ''
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.inputBrandRef = createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId === this.apiDeleteBrandCallId) {
        this.handleDeleteBrandResponse(responseJson);
      }
      if (apiRequestCallId === this.apiCreateBrandCallId) {
        this.handleCreateBrandResponse(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  handleCreateBrandResponse = (responseJson: unknown) => {
    this.setState({ loading: false });
    const successData = responseJson as ICreateBrandSuccessReponse;
    const errorData = responseJson as ICreateBrandErrorReponse;
    this.setState({ errorMsg: "", successMsg: "" });
    if ("data" in successData) {
      this.setState({ listResponse: successData.data });
      return this.setState({ loading: false });
    }
    if ("errors" in errorData) {
      return this.setState({ errorMsg: errorData.errors[0] });
    }
    return this.setState({ errorMsg: "Something went wrong. Please try again later!" });
  };

  handleDeleteBrandResponse = (responseJson: unknown) => {
    const successData = responseJson as any;
    if(successData.message === "Brand deleted successfully") {
      this.getBrandList()
      toast.success("Brand deleted successfully!", {
        progress: undefined,
        autoClose: 3000, 
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        pauseOnHover: true,
      });
    }
    else if(successData.errors[0] === configJSON.delBrandErrMsg) {
      this.getBrandList()
      this.setState({openErrorPopup: true, delBrandErr: configJSON.delBrandErrorText})
    }
  };

  getBrandList = () => {
    const headers = {};
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCreateBrandCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/brands/seller_brand_listing?token=${this.state.authToken}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEdit(row: any, flag: boolean) {
    row.updateFlag = true;
    this.setState({ rowData: row, toShowBrandCatelog: flag });
    localStorage.removeItem("rowData");
    localStorage.setItem("rowData", JSON.stringify(row));
    this.props.navigation.navigate(configJSON.sellerHomeText, { path: configJSON.brandCatalogMenu });

    const msg: Message = new Message(getName(MessageEnum.NavigationSellerDashboardMessage));
    msg.addData(getName(MessageEnum.ChangeMenuPanel), configJSON.brandCatalogMenu);
    this.send(msg);
  }

  handleDelete(rowId: any) {
    this.setState({ openModal: false });

    const formData = new FormData();
    formData.append("id", rowId);
    const headers = { token: this.state.authToken };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiDeleteBrandCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_catalogue/brands/delete_seller_brand");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleModal(rowId: any) {
    this.setState({ openModal: true, rowId: rowId });
  }
  handleModalClose() {
    this.setState({ openModal: false });
  }
  handleGoToSellerBrandCatalog = () => {
    localStorage.removeItem("rowData");
    this.props.navigation.navigate(configJSON.sellerHomeText, { path: configJSON.brandCatalogMenu });
    this.setState({ toShowBrandCatelog: true });
  };

  handleGetToken = async () => {
    const authToken = await getStorageData("authToken");

    this.setState({ authToken: authToken }, () => this.getBrandList());
  };

  openProductList = async(rowId: any) => {
    await setStorageData("selectedBrand", rowId)
    this.props.navigation.navigate(configJSON.sellerHomeText, {path:"brand-catalog"})
  };

  async componentDidMount(): Promise<void> {
    this.setState({ loading: true });
    this.handleGetToken();
  }

  handleDeleteErrModal = () => {
    this.setState({openErrorPopup: false})
  }
  // Customizable Area End
}
