import React from "react";

// Customizable Area Start
import { Box, Button, Grid, Link, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { BoxNoScroll, FormControlTwo, MenuItemTwo } from "../CustomizedElementStyle";
// Customizable Area End

import SellerCouponsController, { Props } from "./SellerCouponsController";

export default class SellerCoupons extends SellerCouponsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  couponsTable() {
    return (
      <>
        <Paper style={{ width: "100%", overflow: "hidden", marginTop: "25px", marginBottom: "25px" }}>
          <TableContainer style={{ maxHeight: 440 }}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={webStyle.tabelHeadeing}>COUPONS NAME</TableCell>
                  <TableCell style={webStyle.tabelHeadeing}>COUPONS CODE</TableCell>
                  <TableCell style={webStyle.tabelHeadeing}>DISCOUNT (%)</TableCell>
                  <TableCell style={webStyle.tabelHeadeing}>START DATE</TableCell>
                  <TableCell style={webStyle.tabelHeadeing}>END DATE</TableCell>
                  <TableCell style={webStyle.tabelHeadeing}>ACTION</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.couponCodeList &&
                  this.state.couponCodeList.map((item: any, index: number) => {
                    return (
                      <TableRow key={item.id}>
                        {/* @ts-ignore */}
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.code}</TableCell>
                        <TableCell>{item.discount}</TableCell>
                        <TableCell>{item.startDate}</TableCell>
                        <TableCell>{item.endDate}</TableCell>
                        <TableCell>
                          <Link component="button" variant="body2" style={{ color: "#FF6008", fontStyle: "italic", textDecoration: "underline" }}>
                            List your product
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          <Button
            data-test-id="nextRef"
            onClick={() => this.handleStep("selectSKU")}
            style={{
              textTransform: "none",
              fontFamily: "'Montserrat Variable', sans-serif",
              fontWeight: 700,
              fontSize: 16,
              background: "#FF6008",
              color: "white",
              display: "flex",
              alignItems: "center",
              borderRadius: "8px",
              padding: "15px 50px"
            }}
          >
            Next
          </Button>
        </Box>
      </>
    );
  }

  skuDropdownComponent() {
    return (
      <>
        <Grid item lg={6} sm={12} xs={12}>
          <FormControlTwo fullWidth={true} style={{ marginTop: "25px", height: 280 }}>
            <Box sx={{ color: "#9a9a9a", fontFamily: `"Lato", sans-serif`, fontWeight: 600, fontSize: 12, marginBottom: 10 }}>{"Select SKU*"}</Box>
            <Select
              data-test-id="selectSKU"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              placeholder="Select SKU"
              defaultValue=""
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    background: "white",
                    border: "1px solid #b1b3b5",
                    boxShadow: "none",
                    borderRadius: 5,
                    marginTop: "5px",
                    padding: 0,
                    maxHeight: "400px", // Set the maximum height limit for the menu
                    overflowY: "auto" // Add a scroll if the items overflow
                  }
                }
              }}
            >
              <MenuItemTwo value={"sku1"}>Sku 1</MenuItemTwo>
              <MenuItemTwo value={"sku2"}>Sku 2</MenuItemTwo>
            </Select>
          </FormControlTwo>
        </Grid>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          <Button
            data-test-id="saveContinueRef"
            onClick={() => this.handleStep("selectSKU")}
            style={{
              textTransform: "none",
              fontFamily: "'Montserrat Variable', sans-serif",
              fontWeight: 700,
              fontSize: 16,
              background: "#FF6008",
              color: "white",
              display: "flex",
              alignItems: "center",
              borderRadius: "8px",
              padding: "15px 50px"
            }}
          >
            Save & Continue
          </Button>
        </Box>
      </>
    );
  }
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <Box sx={{ padding: "20px 0px 15px 0px", fontSize: "18px", fontWeight: 400, fontFamily: `"Lato", sans-serif` }}>Promotions & Ads</Box>
        <BoxNoScroll style={{ paddingBottom: 20, overflow: "auto" }}>
          <Box style={{ borderRadius: "8px", display: "flex", flexDirection: "column", padding: "30px", overflowY: "auto", overflowX: "hidden", position: "relative", background: "white" }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", marginBottom: 10 }}>
              <Box sx={{ fontSize: "27px", fontWeight: 700, fontFamily: `"Lato", sans-serif` }}>Coupons Codes</Box>
              <Box sx={{ fontSize: "27px", fontWeight: 700, fontFamily: `"Lato", sans-serif` }}>
                <Button
                  data-test-id="filterRef"
                  style={{
                    textTransform: "none",
                    fontFamily: "'Montserrat Variable', sans-serif",
                    fontWeight: 700,
                    fontSize: 16,
                    background: "#f7f7f7",
                    color: "#9ba1aa",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "8px",
                    border: "1px solid #9ba1aa",
                    padding: "12px 45px"
                  }}
                >
                  {" "}
                  Filter
                </Button>
              </Box>
            </Box>
            <Box style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", width: "100%", background: "linear-gradient(180deg, #F8F8F8 0%, #EEE 100%)", overflowX: "auto" }}>
              <Box data-test-id="allCouponsRef" onClick={() => this.handlePanel("allCoupons")} style={this.state.activePanel === "allCoupons" ? webStyle.productDetailsColumnSelected : webStyle.productDetailsColumn}>
                All Coupons
              </Box>
              <Box data-test-id="myCouponsRef" onClick={() => this.handlePanel("myCoupons")} style={this.state.activePanel === "myCoupons" ? webStyle.productDetailsColumnSelected : webStyle.productDetailsColumn}>
                My Coupons
              </Box>
            </Box>
            {this.state.activeStep === "couponTable" && this.couponsTable()}
            {this.state.activeStep === "selectSKU" && this.skuDropdownComponent()}
          </Box>
        </BoxNoScroll>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  tabelHeadeing: {
    fontWeight: 600,
    fontSize: "15px",
    backgroundColor: "#f0f0f0"
  },
  productDetailsColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 56,
    width: "100%",
    fontSize: 14,
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`,
    color: "#000",
    background: "linear-gradient(180deg, #F8F8F8 0%, #EEE 100%)"
  },
  productDetailsColumnSelected: {
    height: 56,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#ffa06a",
    color: "black",
    fontSize: 14,
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`
  }
};
// Customizable Area End
