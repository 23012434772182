import React from "react";

// Customizable Area Start
import { Container, Box, Grid, Button, Modal } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "typeface-lato";
import Error from "@material-ui/icons/Error";
import { CustomBox, ClickableBox, OtpInput } from "./CustomElementStyling";
import { CustomSubmitButton } from '../../mobile-account-login/src/CustomElementStyling';
import Loader from "../../../components/src/Loader";

const theme = createTheme({
  typography: {},
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1460
    }
  },
  overrides: {
    MuiContainer: {
      root: {
        fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
        textAlign: "center",
        color: "#575656"
      }
    }
  }
});
import { CustomSnackbar } from "../../../components/src/CustomCheckBox.web";
import { errorImg } from "./OTPInputAuthController";

// Customizable Area End

import OTPInputAuthController, {
  Props,
  emailImg,
  successImg
} from "./OTPInputAuthController";

export default class OTPInputAuthWeb extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  CloseElement = (modalType: string) => {
    return (
      <ClickableBox>
        <CustomBox
          data-test-id={`closeButtonRef${modalType}`}
          sx={{
            width: "40px",
            height: "40px",
            position: "absolute",
            right: 0,
            borderRadius: "100%",
            marginTop: "-20px",
            marginRight: "5%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          onClick={this.handleCloseModal}
        >
          <Close />
        </CustomBox>
      </ClickableBox>
    )
  }
  OtpModal = () => {
    return (
      <Modal
        open={this.state.showOTPModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        hideBackdrop={true}
      >
        <CustomBox
          data-test-id="test"
          sx={webStyle.modalMainWrapper}
        >
            <CustomSnackbar open={this.state.isResendOtp} message={"OTP has been resent successfully."}/>
          <Box sx={webStyle.blurredBackground}></Box>
          <CustomBox
            sx={webStyle.modalContainer}
            style={{ background: "white" }}
          >
            {this.CloseElement('OtpModal')}
            <CustomBox sx={{ width: "85%" }}>
              <CustomBox sx={{ fontWeight: "bold", fontSize: '20px', color: "#3E3E3E" }}>
                OTP Verification
              </CustomBox>
              <CustomBox sx={{ marginTop: "15px", fontSize: "12px", color: "#3E3E3E", fontWeight: "medium", }}>
                Please enter 5 digit code sent to your phone number {`+${this.state.sellerPhoneCountryCode}-${this.state.sellerBasePhoneNumber.slice(0, 4)} ${this.state.sellerBasePhoneNumber.slice(4)}`}
              </CustomBox>
              
              <Grid
                container
                spacing={2}
                justifyContent="center"
                style={{ marginTop: "60px", marginBottom: "20px", flexWrap: "nowrap" }}
              >

                <Grid item lg={3} sm={3} xs={3}>
                  <OtpInput
                    data-test-id="inputOtp1"
                    placeholder="-"
                    value={this.state.inputValues[0]}
                    ref={this.inputRefs[0]}
                    inputProps={{ maxLength: 1 }}
                    onChange={event => this.handleInputOnChange(0, event)}
                    onKeyDown={event => this.handleInputOnPress(0, event)}
                  />
                </Grid>
                <Grid item lg={3} sm={3} xs={3}>
                  <OtpInput
                    data-test-id="inputOtp2"
                    placeholder="-"
                    value={this.state.inputValues[1]}
                    ref={this.inputRefs[1]}
                    inputProps={{ maxLength: 1 }}
                    onChange={event => this.handleInputOnChange(1, event)}
                    onKeyDown={event => this.handleInputOnPress(1, event)}
                  />
                </Grid>
                <Grid item lg={3} sm={3} xs={3}>
                  <OtpInput
                    data-test-id="inputOtp3"
                    placeholder="-"
                    value={this.state.inputValues[2]}
                    ref={this.inputRefs[2]}
                    inputProps={{ maxLength: 1 }}
                    onChange={event => this.handleInputOnChange(2, event)}
                    onKeyDown={event => this.handleInputOnPress(2, event)}
                  />
                </Grid>
                <Grid item lg={3} sm={3} xs={3}>
                  <OtpInput
                    data-test-id="inputOtp4"
                    placeholder="-"
                    value={this.state.inputValues[3]}
                    ref={this.inputRefs[3]}
                    inputProps={{ maxLength: 1 }}
                    onChange={event => this.handleInputOnChange(3, event)}
                    onKeyDown={event => this.handleInputOnPress(3, event)}
                  />
                </Grid>
                <Grid item lg={3} sm={3} xs={3}>
                  <OtpInput
                  id="OtPCk"
                    data-test-id="inputOtp5"
                    placeholder="-"
                    value={this.state.inputValues[4]}
                    ref={this.inputRefs[4]}
                    inputProps={{ maxLength: 1 }}
                    onChange={event => this.handleInputOnChange(4, event)}
                    onKeyDown={event => this.handleInputOnPress(4, event)}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <CustomBox sx={{ fontWeight: "600", fontSize: "12px", color: "#061B6F" }}>
                    {this.state.minutes}:
                    {this.state.seconds < 10
                      ? `0${this.state.seconds}`
                      : this.state.seconds}{" "}
                    min
                  </CustomBox>
                </Grid>
                <Grid item>
                  <CustomBox
                    sx={{ display: "flex", flexDirection: "row", flex: "wrap" }}
                  >
                    <CustomBox sx={{ fontSize: 12, color: "#565656" }}>Did not get OTP ?</CustomBox>
                    <ClickableBox
                      data-test-id="resendNowOtpRef"
                      sx={{ marginLeft: "8px", fontWeight: "800", fontSize: 13, color: "#FF6008" }}
                      style={{ color: "rgba(255, 140, 51, 1)" }}
                      onClick={this.handleResendOtp}
                    >
                      Resend now
                    </ClickableBox>
                  </CustomBox>
                </Grid>
              </Grid>
            </CustomBox>
            <CustomBox
              sx={{
                maxHeight: "110px",
                minHeight: "110px",
                display: "flex",
                flexDirection: "column"
              }}
            >
              {this.state.errorMessages.map((item, index) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "no-wrap",
                      alignItems: "center",
                      marginTop: "15px"
                    }}
                    key={index}
                  >
                    <Box sx={{ marginRight: "5px" }}>
                      <Error color="secondary" />
                    </Box>
                    <Box sx={{ fontSize: 14, color: "red" }}>{item}</Box>
                  </Box>
                );
              })}
            </CustomBox>
            <CustomSubmitButton
              data-test-id="otpSubmitButton"
              style={{
                width: "100%",
              }}
              onClick={this.handleSubmitOtp}
            >
              Submit
            </CustomSubmitButton>
          </CustomBox>
        </CustomBox>
      </Modal>
    );
  };

  SuccessModal = (modal: boolean, header: string, subheader: any, buttonRef: string) => {
    return (
      <Modal
        open={modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        
        <CustomBox data-test-id="test" sx={webStyle.modalMainWrapper}>
        <Box sx={webStyle.blurredBackground}></Box>
          <CustomBox sx={webStyle.verifiedModalContainer} style={{ background: "white", height: "350px" }}>
            {this.CloseElement(buttonRef)}
            <CustomBox sx={{width: "100%", height: "100%", display: "flex", justifyContent: header === 'OTP Verified' ? 'flex-end' : 'center', flexDirection: "column"}}>
              <CustomBox sx={webStyle.verifiedImageWrapper}>
                {this.state.showEmailFailedModal ? (
                  <CustomBox sx={{marginBottom: '24px'}}>
                    <img style={{height: '30px', width: '30px'}} src={errorImg} alt="error image" />
                </CustomBox>
                ) : (
                  <CustomBox sx={webStyle.verifiedImageContainer}>
                    <img src={successImg} alt="success image" />
                  </CustomBox>
                )}
                <CustomBox sx={{ marginBottom: "10px", fontWeight: 'bold', fontSize: '20px', color: "#565656" }}>
                  {header}
                </CustomBox>
                <CustomBox sx={{ marginBottom: "30px", fontSize: "12px", color: "#565656" }}>
                  {subheader}
                </CustomBox>
              </CustomBox>
              {header === 'OTP Verified' && (
                <CustomSubmitButton data-test-id="verifyYourEmailRef" onClick={() => this.handleSendVerificationEmail()}>Verify your Email</CustomSubmitButton>
              )} 
            </CustomBox>
          </CustomBox>
        </CustomBox>
      </Modal>
    );
  };

  SendEmailVerificationModal = () => {
    return (
      <Modal
        open={this.state.showVerifyEmailModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <CustomBox
          data-test-id="test"
          sx={webStyle.modalMainWrapper}
        >
           <Box sx={webStyle.blurredBackground}></Box>
          <CustomBox
            sx={webStyle.sendEmailVerifModal}
          >
            {this.CloseElement('SendEmailVerificationModal')}
            <CustomBox
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                height: "100%",
                alignItems: "center"
              }}
            >
              <CustomBox
                sx={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "100%",
                  marginBottom: "20px"
                }}
              >
                <img src={emailImg} alt="email image" />
              </CustomBox>
              <CustomBox
                sx={{ marginBottom: "15px", fontWeight: "bold", fontSize: "20px", color: "#565656" }}
              >
                Verify your email address
              </CustomBox>
              <CustomBox sx={{ marginBottom: "10px", fontSize: "12px", color: "#565656", fontWeight: "400" }}>
                Please check your inbox for the verification link.
              </CustomBox>
              <CustomBox sx={{ marginBottom: "35px", fontSize: "12px", color: "#565656" }}>
                Email sent to {this.state.sellerEmail}
              </CustomBox>
              <CustomBox
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "fit-content",
                  fontSize: "12px",
                  color: "#565656",
                  fontWeight: "400"
                }}
              >
                <CustomBox>Did not get a link ?</CustomBox>
                <ClickableBox>
                  <CustomBox
                    data-test-id="resendNowEmailRef"
                    sx={{ marginLeft: "5px", fontWeight: "bold" }}
                    style={{ color: "#FF6008", fontSize: "13px" }}
                    onClick={() => { this.handleResendEmail() }}
                  >
                    Resend now
                  </CustomBox>
                </ClickableBox>
              </CustomBox>
              <CustomBox sx={{
                height: "60px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                fontSize: "12px",
                padding: "10px 0px",
                color: "rgb(24 81 11)"
              }}>
                {this.state.errorMessages.map((item, index) => {
                  return <CustomBox key={index}>{item}</CustomBox>
                })}
              </CustomBox>
              <CustomBox sx={{ fontSize: "10px", color: "#565656", fontWeight: "400" }}>
                Can't find? Please check your spam folder.
              </CustomBox>
            </CustomBox>
          </CustomBox>
        </CustomBox>
      </Modal>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CustomBox sx={webStyle.mainWrappers}>
          <Container maxWidth="lg">
            <Box sx={{ position: 'relative', width: '50px', left: '45%', top: '100%', marginTop: '20%' }}>
              <Loader loading={this.state.loading} />
            </Box>
            {this.OtpModal()}
            {this.SuccessModal(
              this.state.showOTPSuccessModal,
              'OTP Verified',
              'You are being redirected.',
              'OTPVerificationModal'
            )}
            {this.SuccessModal(
              this.state.showEmailSuccessModal,
              'Email Verified',
              'You are being redirected.',
              'EmailSuccessModal'
            )}
            {this.SuccessModal(this.state.showEmailFailedModal, 'Failed to verify email', this.state.emailFailedError, '', )}
            {this.SendEmailVerificationModal()}
          </Container>
        </CustomBox>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrappers: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    width: "100%",
    position: "fixed"
  },
  modalMainWrapper: {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  blurredBackground: {
    position: "absolute",
    zIndex: -1,
    backgroundColor: "rgba(0,0,0,0.1)",
    width: "100%",
    height: "100%",
    backdropFilter: "blur(8px)"
  },
  modalContainer: {
    width: "450px",
    maxWidth: "450px",
    borderRadius: "10px",
    position: "relative",
    padding: "50px",
  },
  sendEmailVerifModal: {
    width: "540px",
    maxWidth: "540px",
    borderRadius: "10px",
    position: "relative",
    padding: "50px 50px 32px 50px",
    height: "400px",
    backgroundColor: "white"
  },
  verifiedModalContainer: {
    width: "450px",
    maxWidth: "450px",
    borderRadius: "10px",
    position: "relative",
    padding: "40px",
    display: 'flex'
  },
  verifiedImageWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  verifiedImageContainer: {
    width: "100px",
    height: "100px",
    marginBottom: "24px"
  }
};
// Customizable Area End
