// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON2 = require("./config");

export interface IPrivacyNotice {
    id: string,
    title: string,
    content: string
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  privacyNotice: IPrivacyNotice
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class PrivacyNoticeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPrivacyNoticeCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      privacyNotice: {
        id: '',
        title: '',
        content: ''
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getSellerPrivacyNotice()
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) ;
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId === this.apiPrivacyNoticeCallId) {
        this.handlePrivacyNoticeResponse(responseJson);
      }
    }
  }

  handlePrivacyNoticeResponse = (responseJson:IPrivacyNotice[]) => {
    if(responseJson.length) {
        const privacyNotice = responseJson.filter((item:IPrivacyNotice) => {
            if(item.title === "Privacy notice") {
                return item
            }
        })
        if(privacyNotice.length) this.setState({privacyNotice : privacyNotice[0]})
    }
  }

  // Customizable Area End

  // Customizable Area Start
  getSellerPrivacyNotice = async() => {
    const headers = { token: await getStorageData("authToken"), "Content-Type": configJSON2.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiPrivacyNoticeCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON2.getApiMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON2.sellerStaticApiEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleBackBtn = () => {
    this.props.navigation.navigate("FeaturesHeader")
  }
  // Customizable Area End
}
