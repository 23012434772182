// Customizable Area Start
import React from "react";
import CreateStockIntakeReqController, { Props } from "./CreateStockIntakeReqController.web";
import { Box, Grid, InputAdornment, Select } from "@material-ui/core";
import { CustomExpandMoreIcon, CustomFormControl, CustomMenuItem, CustomizeBrandInput, OrangeButton } from "../../dashboard/src/CustomizedElementStyle";
import { configJSON } from "./CatalogueController";
// Customizable Area End

class CreateStockIntakeRequest extends CreateStockIntakeReqController {
    constructor(props:Props){
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        return (
            <>
            <Box style={webStyle.mainWrapper}>
                <Box sx={webStyle.headerContainer}>
                    <Box sx={webStyle.headerText}>{configJSON.fulFilledByEzzyText}/{configJSON.fbbStockMgmtText} /<span style={webStyle.stockReqText}>{configJSON.stockPath}</span></Box>
                </Box>
            <Box style={webStyle.bckGround}>
                <Box style={webStyle.formWrapper}>
                <Box data-test-id="formHeader" sx={webStyle.formHeader}>{configJSON.stockPath}</Box>
                </Box>

                <Box sx={webStyle.description}>{configJSON.stockDescText}</Box>
            
                <Box sx={webStyle.formWidth}></Box>
                <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomFormControl style={{ width:'90%' }}>
                        <Box sx={webStyle.formLabel}>{configJSON.selectproductLabel}</Box>
                        <Select
                            data-test-id="selectproduct"
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            name="productName"
                            value={this.state.selectedProduct}
                            onChange={this.handleSelectProduct}
                            defaultValue=""
                            IconComponent={CustomExpandMoreIcon}
                            MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            PaperProps: {
                                style: webStyle.selectProduct,
                            },
                            }}>
                            {this.state.data.map((item) => {
                            return (
                                <CustomMenuItem value={item.attributes.name} key={item.attributes.name}>
                                {item.attributes.name}
                                </CustomMenuItem>
                            );
                            })}
                        </Select>
                        </CustomFormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomFormControl style={{ width:'90%', paddingTop:'26px', wordWrap: 'break-word',   overflowWrap: 'break-word', whiteSpace: 'normal'}}>
                        <CustomizeBrandInput
                        data-test-id="stockValue"
                        label={configJSON.stockValueLabel}
                        id="outlined-start-adornment"
                        value={this.state.stockValue}
                        onChange={this.handleStockValue}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                        />
                        </CustomFormControl>
                    </Grid>
                </Grid>
                <Box sx={webStyle.fieldWrapper}></Box>
                <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomFormControl style={webStyle.fieldContainer}>
                    <CustomizeBrandInput
                    data-test-id="stockQuantity"
                    label={configJSON.stockQuantityLabel}
                    id="outlined-start-adornment"
                    value={this.state.quantity}
                    onChange={this.handleQuantity}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    />
                </CustomFormControl>   
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomFormControl style={{...webStyle.fieldContainer, width:'90%', paddingTop:'26px', wordWrap: 'break-word',   overflowWrap: 'break-word', whiteSpace: 'normal'}}>
                    <CustomizeBrandInput
                    data-test-id="productLength"
                    label={configJSON.productLengthLabel}
                    id="outlined-start-adornment"
                    value={this.state.productLength}
                    onChange={this.handleProductLength}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    />
                </CustomFormControl>  
                </Grid>
                </Grid>
                <Box sx={webStyle.fieldWrapper}></Box>
                <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomFormControl style={webStyle.fieldContainer}>
                    <CustomizeBrandInput
                    data-test-id="productWidth"
                    label={configJSON.productWidthLabel}
                    id="outlined-start-adornment"
                    value={this.state.productWidth}
                    onChange={this.handleProductWidth}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    />
                </CustomFormControl>   
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomFormControl style={{...webStyle.fieldContainer, width:'90%', paddingTop:'26px', wordWrap: 'break-word',   overflowWrap: 'break-word', whiteSpace: 'normal'}}>
                    <CustomizeBrandInput
                    data-test-id="productHeight"
                    label={configJSON.productHeightLabel}
                    id="outlined-start-adornment"
                    value={this.state.productHeight}
                    onChange={this.handleProductHeight}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    />
                </CustomFormControl>  
                </Grid>
                </Grid>
                <Box sx={webStyle.fieldWrapper}></Box>
                <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomFormControl style={{ width:'90%', paddingTop:'10px', marginBottom:'150px' }}>
                    <CustomizeBrandInput
                    data-test-id="productWeight"
                    label={configJSON.productWeightLabel}
                    id="outlined-start-adornment"
                    value={this.state.productWeight}
                    onChange={this.handleProductWeight}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    />
                </CustomFormControl>   
                </Grid> 
                </Grid>
                <Box sx={webStyle.viewBtnContainer}>
                <OrangeButton style={webStyle.viewBtn}>
                    {configJSON.submitRequestBtn}
                </OrangeButton>
            </Box>
            </Box>  
            
            </Box>  
            </>
        )
    }
    // Customizable Area End
}

// Customizable Area Start
const webStyle = {
    mainWrapper : {
        overflowY: "auto" as const,
        overflowX: "hidden" as const,
        marginBottom:'30px'
    },
    headerContainer: {
        display: "flex", 
        justifyContent: "space-between", 
        alignItems: "center",
        margin:'30px 0px 20px'
    },
    headerText : {
        fontSize:'16px',
        fontWeight: 600 , 
        fontFamily: `"Lato", sans-serif` ,
        color: '#747474'
    },
    stockReqText : {
        color: 'rgb(255, 96, 8)'
    },
    bckGround : {
        backgroundColor: 'white',
        padding:'20px',
        borderRadius: '8px',
    },
    description: {
        fontSize: '14px',
        fontFamily:  `"Lato", sans-serif`,
        color : '#747474',
        fontWeight: 500
    },
    viewBtnContainer : {
        alignItems:'flex-end'
    },
    viewBtn: {
        padding:'10px 30px'
    },
    formWrapper : {
        width: "100%", 
        display: "flex", 
        flexDirection: "row" as const, 
        flexWrap: "wrap" as const, 
        justifyContent: "space-between", 
        alignItems: "center", 
        marginBottom: 10 
    },
    formHeader : {
        fontSize: "28px", 
        fontWeight: 700, 
        fontFamily: `"Lato", sans-serif`
    },
    formWidth: {
        width: "100%", 
        height: 50 
    },
    formLabel : {
        color: "#9a9a9a", 
        fontFamily: `"Lato", sans-serif`, 
        fontWeight: 600, 
        fontSize: 12, 
        marginBottom: 10
    },
    selectProduct : {
        background: "#F6F6F6",
        border: "1px solid rgba(151, 151, 151, 0.147084)",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0468298)",
        marginTop: "8px",
        maxHeight: "400px",  
        overflowY: "auto" as const, 
    },
    fieldWrapper : {
        width: "100%", 
        height: 50 
    },
    fieldContainer: {
        width:'90%', 
        paddingTop:'10px'
    }
}
export {CreateStockIntakeRequest}
export default CreateStockIntakeRequest
// Customizable Area End