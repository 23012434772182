// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { createRef } from "react";
export const configJSON = require("../../src/config.js");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    space: string;
    mainText: string;
    selectQtyContainer: string;
    quantityItem: string;
    label: string;
    searchContainer: string;
    border: string;
    btnSection: string;
    btnGrid: string;
    text: string;
    flex: string;
    spaceOne: string;
    catImgLabel: string;
    dropImage: string;
    catPreviewContainer: string;
    catPreview: string;
    removeImg: string;
    uploadCategoryImg: string;
    boxOne: string;
    boxTwo: string,
    spaceTwo: string
  };
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  quantityList: string[];
  quantity: string;
  productOne: string;
  productTwo: string;
  isNext: boolean;
  errorMsg: string;
  categoryName: string;
  categoryImgPreview: string | null;
  categoryErr: string;
  categoryLink: string;
  catLinkErr: string;
  categoryImage: string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class StoreCategoriesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  categoryImgRef: React.RefObject<HTMLInputElement>;
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.ReceiveMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      quantityList: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      quantity: "",
      productOne: "",
      productTwo: "",
      isNext: false,
      errorMsg: "",
      categoryName: "",
      categoryImgPreview: "",
      categoryErr: "",
      categoryLink: "",
      catLinkErr: "",
      categoryImage: "",
    };

    this.categoryImgRef = createRef();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area End

  // Customizable Area Start
  handleSelectQuantity = (event: React.ChangeEvent<{ name?: string ; value: unknown | string }>) => {
    this.setState({ quantity: event.target.value as string });
  };

  handleSearchProductOne = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ productOne: event.target.value });
  };

  handleSearchProductTwo = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ productTwo: event.target.value });
  };

  handleCategoryNext = () => {
    const { productOne, productTwo, quantity } = this.state;
    if (productOne && productTwo && quantity) {
      this.setState({ isNext: true, errorMsg: "" });
    } else this.setState({ errorMsg: configJSON.fillAllFieldsText });
  };

  handleCategoryName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ categoryName: event.target.value });
  };

  handleUploadCatImage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const targetElement = event.target as HTMLInputElement;
    const file: File = (targetElement.files as FileList)[0];
    if (!file) return;
    const fileSize = file.size / (1024 * 1024);
    if (fileSize > 5) {
        return this.setState({ categoryErr: configJSON.imageSizeErrorText });
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      return this.setState({
        categoryImgPreview: reader.result as string,
        categoryErr: "",
      });
    };
    reader.readAsDataURL(file);

    this.setState({ categoryImage : file } as unknown as Pick<S, keyof S>);
  };

  handleOpenCategoryImage = () => {
    if (this.categoryImgRef && this.categoryImgRef.current) {
      return this.categoryImgRef.current.click();
    }
  };

  handleRemoveCatImage = () => {
    this.setState({ categoryImgPreview: null, categoryImage: "" });
  };

  handleCategoryLink = (event: React.ChangeEvent<HTMLInputElement>) => {
    const link = event.target.value;
    this.setState({ categoryLink: link } as unknown as Pick<S, keyof S>);
    const urlPattern = /^(http:\/\/|https:\/\/)/;
    if (link === "" || !urlPattern.test(link)) {
      this.setState({ catLinkErr: configJSON.validUrlErrorText });
    } else this.setState({ catLinkErr: "" });
  };

  //Customizable Area End
}
