import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { GetCookies } from "../../../framework/src/WebUtilities";
import { setStorageData } from "../../../framework/src/Utilities";
import { UpdatedItem } from "../../catalogue/src/FulfilledByPartnerReturnsController.web";

// Customizable Area Start
import { SetCookies } from "framework/src/WebUtilities";
export interface OrderData {
    order_details: {
        id: number;
        delivered_at: string,
        order_number: string,
        order_placed_at: string,
        order_status: {
            data: {
                attributes: {
                    name: string
                }
            }
        },
        order_item_details: {
            id: number | string,
            price: string,
            order_status : {
                data: {
                    attributes: {
                        name: string
                    }
                }
            },
            catalogue: {
                data: {
                    id: string,
                    attributes: {
                        product_image: string,
                        product_title: string,
                        seller: {
                            data: {
                                id: string
                            }
                        }
                    }
                }
            }
        },
        shipping_address: {
            data: {
                attributes: {
                    address_1 : string
                }
            }
        },
        customer: {
            data: {
                attributes: {
                    full_name : string
                }
            }
        }
    }
}

interface IItemStatus {
    data: {
        id: string,
        attributes: {
            id: string,
            name: string
        }
    }[]
}

interface IHistory {
    orders : OrderData[];
    total_count: number;
}
type Pagination = {
    per_page: undefined | number,
    current_page: undefined | number,
    next_page: undefined | number,
    prev_page: undefined | number,
    total_pages: undefined | number,
    total_count: undefined | number
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    userId: string;
    classes:{
        historyContainer:string;
        paginationUl: string;
        paginationButton: string;
        lineEcllipse: string,
        historyBox: string,
        imageBox: string
    }
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string,
    historyList: OrderData[],
    showAddReviews: boolean;
    loading: boolean;
    orderStatus: {
        data: {
            id: string,
            attributes: {
                name: string
            }
        }
    };
    orderHistoryPagination:Pagination,
    itemId: string
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class UserOrderHistoryController2 extends BlockComponent<
    Props,
    S,
    SS
> {
    apiCallIdSearchUsrList: string = "";
    apiCallIdGetMessageCount: string = "";
    apiCallIdSendMessage: string = "";
    apiCallIdAllMessage: string = "";
    apiUpdateBuyerProfileCallId: string = "";
    getApiHistorylistCallId: string= "";
    downloadOrderInvoiceApiCallId: string= "";
    getStatusIdsApiCallId: string= "";
    updateItemStatusApiCallId : string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.state = {
            token: "",
            loading: false,
            historyList: [],
            showAddReviews: false,
            orderStatus: {
                data: {
                    id: "",
                    attributes: {
                        name: ""
                    }
                }
            },
            orderHistoryPagination:{
                per_page: 10,
                current_page: 1,
                next_page: undefined,
                prev_page: undefined,
                total_pages: undefined,
                total_count: undefined
              },
              itemId: ''
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorJson = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId === this.getApiHistorylistCallId) {
                this.handleHistorylistApiCallResponse(responseJson, errorJson);
            }
            if( apiRequestCallId === this.downloadOrderInvoiceApiCallId) {
                if(!responseJson.errors){
                    this.setState({ loading: false})
                    window.open(responseJson.data.attributes.invoice_pdf, "_self")
                }
            }
            if (apiRequestCallId === this.getStatusIdsApiCallId) {
                this.handleStatusIdRes(responseJson)
            }
            if (apiRequestCallId === this.updateItemStatusApiCallId) {
                this.handleUpdatedStatusRes(responseJson)
            }

        }
        // Customizable Area End
    }

    // Customizable Area Start
    handleUpdatedStatusRes = (responseJson: UpdatedItem) => {
        if(responseJson.data) {
            this.historyListApiCall()
        }
      }
    
      handleStatusIdRes = (responseJson: IItemStatus) => {
        if(responseJson.data) {
            const statusId = responseJson.data.filter((item: {attributes: {id: string, name: string}}) => {
                if(item.attributes.name === "Return") {
                    return item
                }
            })
            this.updateItemStatus(statusId[0].id)
        }
      }

    handleWriteAReview = async(itemDetails: { id: number | string, order_status: {data: {attributes: {name:string}}},catalogue: {data: {id:string, attributes: {seller: {data: {id: string}}}}}}, orderNumber: string, orderPlaced: string, productTitle: string, productImage: string, address: string) => {
        const catalogueId = itemDetails.catalogue.data.id
        const orderItemStatus = itemDetails.order_status.data
        const sellerId = itemDetails.catalogue.data.attributes.seller.data.id
        await setStorageData("order_item_id",JSON.stringify({
            order_number: orderNumber,
            order_placed: orderPlaced,
            catalogue_id: catalogueId,
            order_title:productTitle,
            product_image: productImage,
            seller_id: sellerId,
            item_status: orderItemStatus ? orderItemStatus?.attributes?.name : '',
            order_item_id : itemDetails.id,
            address : address
        }))
        SetCookies("isReview", 'true', 10);
        this.setState({ showAddReviews: true })
    }

    async componentDidMount(): Promise<void> {
        await this.getToken();
        await this.historyListApiCall();
    }

    isReview = () => {
        return GetCookies('isReview') === 'true' ? true : false;
    }

    getToken = async () => {
        const token = GetCookies("ba");
        this.setState({ token })
    }

    handleHistorylistApiCallResponse = (responseJson: IHistory, errorJson: unknown) => {
        this.setState({ loading: false })
        if (responseJson.total_count > 0) {
            this.setState({ historyList: responseJson.orders,
                orderHistoryPagination: {...this.state.orderHistoryPagination,total_pages: Math.ceil(responseJson.total_count / 10)}
            });
        } else {
            this.setState({ historyList: []});
            this.parseApiCatchErrorResponse(errorJson);
        }
    };

    historyListApiCall = async () => {
        this.setState({ loading: true })
        const headers = {
              "Content-Type": configJSON.contenttype,
            token: this.state.token
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getApiHistorylistCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.historylistApiEndpoint(`?page=${this.state.orderHistoryPagination.current_page}&per_page=${this.state.orderHistoryPagination.per_page}&filter_by[]=completed&filter_by[]=cancelled&filter_by[]=ordered&filter_by[]=shipped&filter_by[]=delivered&filter_by[]=rejected&filter_by[]=processing&filter_by[]=refunded`));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiGetMethod);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleShowReview = () => {
        this.setState({showAddReviews: false})
    }

    handleOrderHistoryPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        this.setState(prevState => ({
          orderHistoryPagination:{
            ...prevState.orderHistoryPagination,
            current_page:page
          }
        }), () => {
            this.historyListApiCall()
        })
      }
      
      handlePaginationButton = (page: number) => {
        this.setState(prevState => ({
            orderHistoryPagination:{
              ...prevState.orderHistoryPagination,
              current_page:page
            }
          }), () => {
              this.historyListApiCall()
          })
        }
    handleQuickProductView = (productId: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "SingleProductQuickview");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
        this.send(message)
      }

    handleDownloadOrderInvoice = (order_id:number,order_item_id: number) => {
        this.setState({ loading: true })
        const headers = {
            token: this.state.token
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.downloadOrderInvoiceApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.downloadOrderInvoice}?order_id=${order_id}&order_item_id=${order_item_id}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiGetMethod);
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

      handleReturnItem = (itemId: string) => {
        this.setState({itemId: itemId})
        this.getStatusId()
      }

      getStatusId = async() => {
        const headers = {
          "Content-Type": configJSON.productApiContentType,
          token: GetCookies("ba"),
        };
        const message = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getStatusIdsApiCallId = message.messageId;
        message.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.statusIdsApiEndPoint 
        );
        message.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        message.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        runEngine.sendMessage(message.id, message);
      }

      updateItemStatus =  (statusId: string) => {
        const httpBody = {
            "order_status_id": statusId
        }
        const headers = {
            "Content-Type": configJSON.contentTypeApiUpdateUser,
            token: GetCookies("ba"),
          };
          const message = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.updateItemStatusApiCallId = message.messageId;
          message.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addTocart + `/${this.state.itemId}`
          );
          message.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          message.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
          );
          message.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchMethod
          );
          runEngine.sendMessage(message.id, message);
      }
    // Customizable Area End
}

