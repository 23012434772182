Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.postMethod = 'POST';
exports.apiSignInUserEndpoint = '/bx_block_login/logins';
exports.contentType = 'application/json';
exports.apiSendOtpEndpoint = 'send_login_otp';
exports.apiVerifyOtpEndpont = '/account_block/accounts/sms_otp_confirmations';
exports.apiGetUsersInfoEndpoint = '/account_block/accounts/';
exports.getMethod = 'GET';
// Customizable Area End
