// Customizable Area Start
import { withStyles } from "@material-ui/styles";
import React from "react";
import FulfilledByPartnerReturnsController, {
  IReturnDetails,
  Props,
  configJSON,
} from "./FulfilledByPartnerReturnsController.web";
import { styles, webStyle } from "./SellerPartnerOrderManagement.web";
import { Box, Button, Grid, IconButton, InputAdornment, Menu, MenuItem, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { CustomizeInputSearchBox, SearchIconSVG } from "../../dashboard/src/CustomizedElementStyle";
import { Loader2 } from "../../../components/src/Loader.web";
import TruncatedTextWithTooltip from "../../../components/src/CommonComponents/TruncatedTextWithTooltip.web"
import { Pagination } from "@material-ui/lab";
import { closeIcon, reasonIcon, editImage, returnCheck, returnUncheck, shippingIcon } from "./assets";
import ReturnItemDetails from "./ReturnItemDetails.web";

interface ReturnTableProps {
  numSelected?: number;

  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  orderBy?: string;
  rowCount?: number;
  classes: Record<string, string>
}

function PartnerReturnsTableHead(props: ReturnTableProps) {
  const {classes} = props;
  return (
    <TableHead data-test-id="table" style={styles.tableBackgrnd}>
      <TableRow>
        <TableCell className={classes.tableHeading}>
          <div className={classes.tableHead}>
            <span>
              {configJSON.dateAndTimeLabel}
            </span>
          </div>
        </TableCell>
        <TableCell className={classes.tableHeading}>
          {configJSON.orderDetailsLabel}
        </TableCell>
        <TableCell className={classes.tableHeading}>
          {" "}
          {configJSON.imageLabel}
        </TableCell>
        <TableCell className={classes.tableHeading}>
          {configJSON.productNameLabel}
        </TableCell>
        <TableCell className={classes.tableHeading}>
          {configJSON.reasonForReturnLabel}
        </TableCell>
        <TableCell className={classes.tableHeading}>
          {configJSON.orderStatusLabel}
        </TableCell>
        <TableCell className={classes.tableHeading}>
          {configJSON.actionLabel}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
// Customizable Area End

export class FulfilledByPartnerReturns extends FulfilledByPartnerReturnsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const {classes} = this.props;
    const roundedReturnCount = this.getReturnsRoundedCount()
    return (
      <>
      <Box style={styles.contentWrapper}>
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Typography style={styles.tab}>
              {configJSON.fulfilledByPartnerLabel}
            </Typography>
          </Grid>
          <>
          <Grid container style={styles.container}>
            <Grid item xs={12} sm={12} md={12} lg={12} style={styles.orderTextWrapper}>
              <Box style={styles.ordersText}>
                {configJSON.manageOrdersText}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              lg={8}
              md={8}
              sm={12}
              style={styles.searchWrapper}
            >
              <Typography className={classes.searchText}>
                {configJSON.searchOrderIdText}
              </Typography>
              <Grid style={styles.searchBoxWrapper}>
                <CustomizeInputSearchBox
                  id="outlined-start-adornment"
                  className={classes.searchBox}
                  value={this.state.searchVal}
                  onChange={async(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ searchVal : event.target.value})
                    this.handleSearchReturnOrders(event)
                  }}
                  placeholder={configJSON.searchOrderPlaceholder}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={webStyle.rightMargin}
                      ></InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={webStyle.rightPadding}
                      >
                        <SearchIconSVG />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Box style={styles.pendingOrdersWrapper}>
                      {this.getReturnOrderNumber()}
                      <Box style={styles.optionsWrapper}>
                        <Button
                        id="filter-menu"
                        data-test-id="filterBtn"
                          style={styles.options}
                          className={classes.rightMargin}
                          onClick={this.setReturnFilter.bind(this)}
                        >
                          {configJSON.filtersLabel}
                        </Button>
                        <Menu
                        id="filter-menu"
                        anchorEl={this.state.openReturnFilterAnchor}
                        open={Boolean(this.state.openReturnFilterAnchor)}
                        onClose={this.handleCloseReturnFilters}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        getContentAnchorEl={null}
                        PaperProps={{
                          style: webStyles.filterMenuPropStyle
                        }}
                      >
                      <Typography style={styles.statusText}>{configJSON.orderStatusLabel}</Typography>
                        {configJSON.returnFilterOptions.map((option:string, index: number) => {
                          return(
                            <MenuItem data-test-id="filterOption" value={option} className={classes.checkBoxText} onClick={() => this.handleReturnFilters(option)} style={{margin:'-2px'}}>
                        {this.getSelectedFilter(option) ? <img src={returnCheck} style={{marginRight: '10px'}}/> : <img src={returnUncheck} style={{marginRight: '10px'}}/> }
                          {option}
                        </MenuItem>
                          )
                        })}
                       </Menu>
                        <Button
                        id="menu"
                        data-test-id="sortBtn"
                          style={styles.options}
                          onClick={this.setReturnSortValue.bind(this)}
                        >{configJSON.sortingLabel}
                        </Button>
                        <Menu
                          data-test-id="sortMenu"
                          id="menu"
                          anchorEl={this.state.openReturnSortAnchorEl}
                          keepMounted
                          PaperProps={{
                            style: webStyle.sortMenuStyle
                          }}
                          open={Boolean(this.state.openReturnSortAnchorEl)}
                          onClose={this.handleCloseReturnSortMenu}
                          
                        >
                          {configJSON.sortOrderOptions.map((item: { label: string; value: string }) => {
                            return (
                              <MenuItem data-test-id="sortOption" style={this.getReturnSortStyle(item.value)} onClick={this.handleReturnSorting.bind(this, item.value)}>
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </Menu>
                      </Box>
                    </Box>
          </Grid>
          <Box style={styles.ordersWrapper}>
            {this.state.loading ?
            <Loader2 loading={this.state.loading}/>
            :  
            <Box style={{ padding: "30px", backgroundColor: '#fff'}}>
              <TableContainer style={styles.tableContainer}>
                <Table aria-label="simple table">
                  <PartnerReturnsTableHead
                    data-test-id="tableHeader"
                    classes={this.props.classes}
                  />
                  
                  <TableBody style={styles.layout}>
                    {this.state.returnDetails.length > 0 ?
                      this.state.returnDetails.map((item: IReturnDetails) => {
                        const orderStatus = item.order_details.order_item_details && item.order_details.order_item_details.order_status.data.attributes.name
                        const prodImage = item.order_details.order_item_details.catalogue.data?.attributes?.product_image
                        const productTitle = item.order_details.order_item_details.catalogue.data?.attributes?.product_title
                        const uniquePesku = item.order_details.order_item_details.catalogue.data?.attributes?.product_content?.product_attributes?.unique_psku;
                        return (
                          <TableRow 
                          data-test-id="list"
                            key={item.id}
                            style={webStyle.tableHeader}
                          >
                            <TableCell
                              component="td"
                              style={styles.tableCellContent}
                            >
                              <div style={styles.dateWrapper}>
                                <Box style={styles.day}>
                                  {item.order_details.time_passed_since_order_placed}
                                </Box>
                                <Box style={styles.buyer}>
                                  {this.getReturnsDate(item.order_details.order_placed_at)}
                                </Box>
                                <Box style={styles.gst}>
                                  {`${this.getReturnsTime(item.order_details.order_placed_at)} GST`}
                                </Box>
                              </div>
                            </TableCell>
                            <TableCell
                              style={styles.tableRowHeaderText}
                            >
                              <Box style={styles.buyer}>
                                {uniquePesku}
                              </Box>
                              <Box style={styles.detailsWrapper}>
                                <Typography style={styles.buyer}>
                                  {configJSON.buyerNameLabel}
                                </Typography>
                                <Typography
                                  style={styles.buyerName}
                                >
                                  {item.order_details.customer?.data?.attributes?.full_name}
                                </Typography>
                              </Box>
                              <Box style={styles.detailsWrapper}>
                                <Typography style={styles.buyer}>
                                  {configJSON.sellerNameLabel}
                                </Typography>
                                <Typography
                                  style={styles.buyerName}
                                >
                                  {item.order_details.seller?.data?.attributes?.full_name}
                                </Typography>
                              </Box>
                              <Box style={styles.detailsWrapper}>
                                <Typography style={styles.buyer}>
                                  {configJSON.fullFillmentLabel}
                                </Typography>
                                <Typography
                                  style={styles.buyerName}
                                >
                                  {configJSON.partnerText}
                                </Typography>
                              </Box>
                            </TableCell>

                            <TableCell 
                                data-test-id="getOrderDetail"
                                style={{ padding:'16px 8px' }}
                                >
                              <Box style={styles.imageBox}>
                              <img
                                src={prodImage}
                                style={styles.prodImage}
                                alt="img"
                              />
                              </Box>
                            </TableCell>

                            <TableCell 
                                style={styles.prodTitle} 
                                data-test-id="getOrderDetail"
                                >
                              <Typography
                                style={styles.buyer}
                              >
                                 <TruncatedTextWithTooltip text={productTitle} maxChar={50} />                       
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box
                                className={classes.returnReason}
                              >
                                {this.getReturnReason(item.order_details.return_reason_details)}
                              </Box>
                            </TableCell>
                            <TableCell style={webStyle.statusWrap}>
                              <Grid container style={webStyle.statusGrid}>
                              <Box className={classes.status}>
                                <Typography className={classes.statusText}>{this.getReturnOrderStatus(orderStatus)}</Typography>
                              </Box>
                              
                              <IconButton
                              style={webStyle.editIcon}
                        aria-label="more"
                        data-test-id="editReturnIcon"
                        disabled={orderStatus === "QC Failed"}
                        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.handleEditStatus(event, orderStatus, item.order_details.order_item_details.id, item.order_details.return_reason_details)}
                      >
                        <img src={editImage} alt="edit_icon" style={{height:'12px', width:'11px'}} />
                      </IconButton>
                      </Grid>
                      <Menu
                        id="long-status-menu"
                        anchorEl={this.state.returnAnchorEl}
                        open={Boolean(this.state.returnAnchorEl)}
                        onClose={this.handleCloseMenu}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        getContentAnchorEl={null}
                        PaperProps={{
                          className: classes.editReturnOptions,
                        }}
                      >
                        {configJSON.returnEditOptions.map((option: string, index: number) => {
                          return(
                            <MenuItem data-test-id="statusEdit" disabled={this.state.returnDetailsType.includes(option)} className={classes.checkBoxText} style={{padding:'4px 12px', color: this.setMenuStyle(index)}} onClick={() => this.handleCheckBox(index, option)}>
                              {this.isChecked(index, option) ? <img src={returnCheck} style={{marginRight: '10px'}}/> : <img src={returnUncheck} style={{marginRight: '10px'}}/> }
                              {option}
                            </MenuItem>
                          )
                        })}
                        </Menu>
                            </TableCell>
                            <TableCell style={styles.btnWrapper}>
                            <Button data-test-id="viewBtn" className={classes.viewOpt} onClick={() => this.handleViewOrderBtn(item.order_details.id)}>{"View order"}</Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    :
                    <TableRow>
                  <TableCell colSpan={7} >
                    <Typography variant="h6" style={webStyle.text}>
                      {configJSON.noDataAvailableText}
                    </Typography>
                  </TableCell>
                </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              {this.state.returnDetails?.length > 0 && (
            <Pagination
              style={styles.paginate}
              data-test-id="pagination"
              count={roundedReturnCount}
              page={this.state.pageNumber}
              onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                this.setState({ pageNumber: value }, () => this.getReturnDetails());
              }}
              shape="rounded"
              siblingCount={0}
              boundaryCount={1}
            />
          )}
            </Box>
          }
          </Box>
          </>
        </Box>
        <Modal
        open={this.state.openShippingDetailsModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className={classes.mainModalWrapper}>
          <Box className={classes.blurredBckgnd}></Box>
          
          <Box
            className={`${classes.modalContainerMain} ${classes.modalBoxOne}`}
          >
           
            <Box
              className={classes.modalBoxTwo}
            >
              <Box style={{display:'flex', justifyContent:'flex-end'}}
              >
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  style={{padding: '20px 30px'}}
                >
                  <img src={closeIcon} data-test-id="closeShippingIcon" onClick={this.handleCloseShippingModal} />
                </IconButton>
              </Box> 
              <Box
                className={`${classes.imageWrapperMain} ${classes.boxOne}`}
              >
                <Box className={classes.imageContainer}>
                  <img className={classes.shippingImg} src={shippingIcon} alt="shipping image" />
                </Box>
                <Box
                  className={classes.shippingText}
                >
                  {configJSON.enterShippingDetailsText}
                </Box>
                <TextField
                data-test-id="shippingDetailsRef"
                className={classes.shippingField}
                multiline
                placeholder="Enter details"
                minRows={4}
                maxRows={4}
                value={this.state.shippingDetails}
                onChange={(event:React.ChangeEvent<HTMLInputElement>) => this.handleShippingInput(event.target.value)}
                id="outlined-start-adornment"
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start"></InputAdornment>
                }}
                InputLabelProps={{
                  style: webStyles.textFieldOne
                }}
              />
              <Button 
              data-test-id="shippingSubmitBtn"
              className={this.getShippingSubmitBtnStyle()}
              onClick={this.handleSubmitShippingDetails}
              >Submit</Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={this.state.openReturnReasonModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className={classes.mainModalWrapper}>
          <Box className={classes.blurredBckgnd}></Box>
          
          <Box
            className={`${classes.modalContainerMain} ${classes.modalBoxOne}`}
          >
           
            <Box
              className={classes.modalBoxTwo}
            >
              <Box style={{display:'flex', justifyContent:'flex-end'}}
              >
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  style={{padding: '20px 30px'}}
                >
                  <img src={closeIcon} data-test-id="closeRejectionIcon" onClick={this.handleCloseReasonModal} />
                </IconButton>
              </Box> 
              <Box
                className={`${classes.imageWrapperMain} ${classes.boxOne}`}
              >
                <Box className={classes.imageContainer}>
                  <img className={classes.returnImg} src={reasonIcon} alt="return image" />
                </Box>
                <Box
                  className={classes.shippingText}
                >
                  {configJSON.enterReturnReasonText}
                </Box>
                <TextField
                data-test-id="rejectionDetailsRef"
                className={classes.shippingField}
                multiline
                placeholder="Enter reason"
                minRows={4}
                maxRows={4}
                value={this.state.reasonOfRejection}
                onChange={(event:React.ChangeEvent<HTMLInputElement>) => this.handleReturnInput(event.target.value)}
                id="outlined-start-adornment"
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start"></InputAdornment>
                }}
                InputLabelProps={{
                  style: webStyles.textFieldOne
                }}
              />
              <Button 
              data-test-id="rejectionSubmitBtn"
              className={this.getRejectionSubmitBtnStyle()}
              onClick={this.handleSubmitRejectionReason}
              >Submit</Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      {this.state.showOrderDetails && 
        <ReturnItemDetails navigation={this.props.navigation} id={this.props.id} classes={undefined} />
      }
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const webStyles = {
  textFieldOne: { 
    fontSize: "16px", 
    fontWeight: 400, 
    fontFamily: "Poppins, sans-serif", 
    color: '#999' 
  },
  filterMenuPropStyle: {
    marginTop:'12px',
    boxShadow: '0px 5px 18px rgba(0,0,0,0.17)',
    width: '18.5ch',
    overflowY: 'hidden' as const
  }
}

export const returnStyles = {
  searchBox: {
    "& .MuiInputBase-input::placeholder": {
      fontFamily: `"Avenir-Medium", sans-serif !important` as const,
      color: '#333333 !important',
      fontSize:'14px !important',
      opacity: 'initial'
    }
  },
  checkBoxText : {
    color: '#999999',
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Poppins, sans-serif" as const,
    textTransform: 'none' as const,
    '&:hover': {
      color:'#000 !important' 
    } 
  },
  returnReason: {
    fontFamily: `"Poppins", sans-serif` as const,
    fontWeight: 400 as const,
    color: "#000",
    display: "flex",
    alignItems: "flex-start",
    borderBottom: "0",
    fontSize: "14px",
  },
  status: {
    border: '1px solid #e2e2e2',
    padding: '6px 12px',
    textTransform: 'uppercase' as const,
    borderRadius: '4px'
  },
  statusText: {
    color: '#141414',
    fontFamily: `"Poppins", sans-serif` as const,
    fontSize: '13px',
    fontWeight: 400,
    width: '120px',
    textAlign: 'center' as const
  },
  viewOpt: {
    padding: "5px 20px", 
    color: "#657A8E", 
    fontFamily: `"Lato", sans-serif` as const, 
    fontSize: 14, 
    fontWeight: 500 as const, 
    border: '0px solid rgba(101, 122, 142, 0.320241)',
    backgroundColor: 'rgb(242,243,244)', 
    borderRadius: 5,
    display:'flex', 
    alignItems:'center',
    justifyContent:'center', 
    borderBottom:'0',
    cursor: 'pointer',
    textTransform: 'none' as const
  },
  tableHeading: {
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: "14px",
    textAlign: "left" as const,
    fontWeight: 700 as const,
    color: "#333",
    padding: '16px 10px',
    "&.MuiTableCell-stickyHeader": {
      backgroundColor: "rgba(123,123,123,0.07)",
    },
  },
  editReturnOptions: {
    marginTop:'12px',
    boxShadow: '0px 2px 8px rgba(177,177,177,0.5)',
    width: '165px',
    height: 'auto',
    marginLeft: '14px'
  },
  searchText : {
    color: "#33333A",
    fontSize: "12px",
    fontWeight: 500 as const,
    fontFamily: `"Inter", sans-serif` as const,
    marginBottom:'4px'
  },
  rightMargin: { 
    marginRight: 20
  },
  rightPadding: { 
    paddingRight: 20 
  },
  filterCheckbox: {
    '&.Mui-checked': {
      color: '#ff6008',
    }
  },
  mainModalWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    position: "relative" as const,
  },
  blurredBckgnd: {
    width: "100%",
    zIndex: -1,
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.1)",
    position: "absolute" as const,
    backdropFilter: "blur(8px)",
  },
  modalContainerMain: {
    maxWidth: "757px",
    position: "relative" as const,
    width: "757px",
    display: "flex",
  },
  imageWrapperMain: {
    flexDirection: "column" as const,
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginTop: '-30px'
  },
  imageContainer: {
    marginBottom: "24px",
    display:'flex',
    alignItems: 'center'
  },
  modalText: {
    fontFamily: `"Lato", sans-serif` as const,
    marginBottom: "10px",
    fontWeight: "bold" as const,
    fontSize: "20px",
    color: "#565656",
  },
  description: {
    fontFamily: `"Helvetica", sans-serif` as const,
    marginBottom: "10px",
    fontSize: "12px",
    color: "#565656",
  },
  lastText : {
    fontFamily: `"Helvetica", sans-serif`,
    marginBottom: "70px",
    fontSize: "12px",
    color: "#565656",
  },
  modalBoxOne: {
    background: "white",
    height: "425px",
  },
  modalBoxTwo: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column" as const
  },
  boxOne: { 
    height: "100%" 
  },
  shippingImg: {
    width: '138px',
    height: '86px'
  },
  returnImg: {
    width: '75px',
    height: '94px'
  },
  shippingText: {
    color: '#141414',
    fontFamily: `"Poppins", sans-serif` as const,
    fontSize: '22px',
    fontWeight: 500,
    textAlign: 'center' as const
  },
  submitInactive: {
    color: '#ffffff',
    backgroundColor: '#999',
    fontFamily : `"Montserrat", sans-serif` as const,
    fontSize: '20px',
    fontWeight: 700,
    textAlign: 'center' as const,
    padding: '8px 46px',
    textTransform: 'capitalize' as const,
    cursor: 'none',
    '&:hover' : {
      color: '#ffffff',
      backgroundColor: '#999',
      cursor: 'none'
    }
  },
  submitActive : {
    color: '#fff',
    backgroundColor: '#ff6008',
    fontFamily : `"Montserrat", sans-serif` as const,
    fontSize: '20px',
    fontWeight: 700,
    textAlign: 'center' as const,
    padding: '8px 46px',
    textTransform: 'capitalize' as const,
    '&:hover': {
      color: '#fff',
      backgroundColor: '#ff6008'
    }
  },
  shippingField: {
    margin: "24px",
    width: '92%',
    "& .MuiInput-formControl": {
      border: "1px solid #DDE3E8",
      margin: 0,
      padding: "10px 0px",
      borderRadius: "5px"
    },
    '&.MuiInputBase-input:focus':{
      border: "1px solid #DDE3E8",
    }
  }
}

export default withStyles(returnStyles)(FulfilledByPartnerReturns);
// Customizable Area End
