import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IChangePWData, IChangePWDataError, IPostFWPData, IPostLoginDataError } from "./Interfaces";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  showForgotPwModal: boolean;
  showVerifyEmailModal: boolean;
  showChangePwModal: boolean;
  showChangePwSuccessModal: boolean;
  email: string;
  errMsg: string;
  isFetching: boolean;
  token: string;
  showNewPass: boolean;
  showConfirmPass: boolean;
  confirm_password: string;
  new_password: string;
  successMsg: string;
  isResendPw: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerForgotPasswordController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiPostForgotPasswordCallId: string = "";
  apiPatchChangePasswordCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.ReceiveMessage)];

    this.state = {
      showForgotPwModal: false,
      showChangePwModal: false,
      showVerifyEmailModal: false,
      showChangePwSuccessModal: false,
      email: "",
      errMsg: "",
      token: "",
      showNewPass: false,
      showConfirmPass: false,
      new_password: "",
      confirm_password: "",
      isFetching: false,
      isResendPw: false,
      successMsg: ""
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      this.setState({ isFetching: false });
      if (apiRequestCallId === this.apiPostForgotPasswordCallId) {
        this.handlePostForgotPassword(responseJson);
      }
      if (apiRequestCallId === this.apiPatchChangePasswordCallId) {
        this.handleChangePasswordResponse(responseJson);
      }
    }
    if (getName(MessageEnum.ReceiveMessage)) {
      const msg = message.getData(getName(MessageEnum.OpenForgoPasswordModal)) || false;
      if (msg) {
        this.setState({ showForgotPwModal: true, errMsg: "", successMsg: "", isFetching: false, isResendPw: false, email: "" });
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  handleChangePasswordResponse = (responseJson: unknown) => {
    const successData = responseJson as IChangePWData;
    const errorData = responseJson as IChangePWDataError;
    if ("meta" in successData) {
      this.setState({ showChangePwSuccessModal: true, showForgotPwModal: false });
      setTimeout(() => {
        if (successData.data.attributes.user_type === "buyer") {
          const msg: Message = new Message(getName(MessageEnum.NavigationHomePageMessage));
          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(msg);
          const msg2: Message = new Message(getName(MessageEnum.SendMessage));
          msg2.addData(getName(MessageEnum.OpenBuyerEmailLoginModal), true);
          this.send(msg2);
        } else {
          this.props.navigation?.navigate("FeaturesHeader")
        }
      }, 2000);
      return;
    }
    if ("errors" in errorData) {
      return this.setState({ errMsg: errorData.errors[0].token});
    }
    this.setState({ errMsg: configJSON.somethingWentWrongText });
  };

  handlePostForgotPassword = (responseJson: unknown) => {
    const successData = responseJson as IPostFWPData;
    const errorData = responseJson as IPostLoginDataError;
    if ("message" in successData) {
      return this.setState({ showChangePwModal: false, showVerifyEmailModal: true, successMsg: configJSON.resetPassLinkSuccessText });
    }
    if ("errors" in errorData) {
      return this.setState({ errMsg: errorData.errors[0].profile });
    }
    this.setState({ errMsg: configJSON.somethingWentWrongText });
  };

  handleCloseFWPModal = () => {
    const path = window.location.pathname
    if(path === '/buyer/forgotpassword' || path?.split("/")[1] !== 'seller') {
      this.props.navigation.navigate("Home")
    }
    else  this.props.navigation?.navigate("FeaturesHeader")
    this.setState({ showForgotPwModal: false, showChangePwModal: false, showVerifyEmailModal: false, showChangePwSuccessModal: false });
  };

  handleOnInputChange = (input: string, value: string) => {
    this.setState(({ [input]: value } as unknown) as Pick<S, keyof S>);
  };
  handleKeyDown = async(e:any)=>{
    if (e.key === "Enter") {
      this.handleOnSubmit();
    }
  }
  handleOnSubmit = () => {
    this.setState({ errMsg: "", successMsg: "" });
    if (!this.state.email) {
      return this.setState({ errMsg: configJSON.emailRequiredText });
    }
    this.postForgotPassword();
  };

  handleShowNewPass = () => {
    this.setState({ showNewPass: !this.state.showNewPass });
  };

  handleShowConfirmPass = () => {
    this.setState({ showConfirmPass: !this.state.showConfirmPass });
  };
  handleKeySetDown = async(e:any)=>{
    if (e.key === "Enter") {
      this.handleOnSave();
    }
  }
  handleOnSave = () => {
    this.setState({ errMsg: "", successMsg: "" });
    const { new_password, confirm_password } = this.state;
    if (!new_password || !confirm_password) {
      return this.setState({ errMsg: configJSON.fieldRequiredText });
    }
    if (this.state.new_password !== this.state.confirm_password) {
      return this.setState({ errMsg: "Passwords do not match." });
    }
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/.test(new_password)) {
      return this.setState({ errMsg: "Password must have at least one lowercase letter, one uppercase letter, one digit, one special character (@$!%*?&), and no spaces. It should be between 8 and 32 characters long." });
    }
    this.patchChangePassword();
  };

  resendFWPEmailLink = () => {
    this.setState({ errMsg: "", successMsg: "", isResendPw: true });
    this.postForgotPassword();
  };

  postForgotPassword = async() => {
    const path = window.location.pathname.split("/")[1]
    this.setState({ isFetching: true });
    const headers = { "Content-Type": configJSON.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiPostForgotPasswordCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiPostForgotPasswordEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          email: this.state.email,
          user_type: path === "seller" ? "seller" : "buyer"
        }
      })
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  patchChangePassword = () => {
    this.setState({ isFetching: true });
    const headers = { "Content-Type": configJSON.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiPatchChangePasswordCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiPatchPasswordEndpoint + this.state.token);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          new_password: this.state.new_password,
          confirm_password: this.state.confirm_password
        }
      })
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount(): Promise<void> {
    try {
      const pageUrl = window.location.href;
      const verifyParam = pageUrl.split("?")[1].split("=")[1];
      if (verifyParam) {
        this.setState({ showChangePwModal: true, token: verifyParam });
      }
    } catch (error) {}
  }
  // Customizable Area End
}
