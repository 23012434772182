import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activePanel: string;
  activeStep: string;
  couponCodeList: Array<Object>;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerCouponsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      activePanel: "allCoupons",
      activeStep: "couponTable",
      couponCodeList: [
        { id: 1, name: "Ali", code: "ALI2023", discount: "20", startDate: "9 Apr 2022", endDate: "9 Apr 2023" },
        { id: 2, name: "Summer", code: "sum2023", discount: "20", startDate: "9 Apr 2022", endDate: "9 Apr 2023" },
        { id: 2, name: "Test", code: "test2023", discount: "20", startDate: "9 Apr 2022", endDate: "9 Apr 2023" }
      ]
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  handlePanel(panel: string) {
    this.setState({ activePanel: panel });
  }

  handleStep(step: string) {
    this.setState({ activeStep: step });
  }
  // Customizable Area End

  // Customizable Area Start
  // Customizable Area End
}
