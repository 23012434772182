// Customizable Area Start
import StoreCategoriesController, { Props, configJSON } from "./StoreCategoriesController.web";
import { Box, Button, Grid, InputAdornment, Select, Typography, withStyles } from "@material-ui/core";
import { CustomExpandMoreIcon, CustomizeBrandInput, CustomizeInputSearchBox, FormControlTwo, MenuItemTwo, NextButton, SearchIconSVG } from "../CustomizedElementStyle";
import { errorImage } from "../../../mobile-account-login/src/assets";
import { ClickableBox } from "../../../mobile-account-login/src/CustomElementStyling";
import React from "react";
import { file } from "../assets";
// Customizable Area End

class StoreCategories extends StoreCategoriesController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
       
          {!this.state.isNext ? (
            <>
             <Grid container className={classes.space} spacing={4}>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.space}>
                <Typography className={classes.mainText}>{configJSON.productListingText}</Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.label}>{configJSON.selectQuantityLabel}</Typography>
                <FormControlTwo fullWidth={true}>
                  <Select
                    data-test-id="selectQty"
                    name="selectedRole"
                    labelId="demo-simple-select-outlined-label"
                    id="role-select-dropdown-csv-popup"
                    inputProps={{
                      "aria-label": "Without label",
                      classes: {
                        select: classes.selectQtyContainer,
                      },
                    }}
                    value={this.state.quantity}
                    onChange={this.handleSelectQuantity}
                    IconComponent={() => <CustomExpandMoreIcon style={webStyle.iconColor} />}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        style: webStyle.selectQty,
                      },
                    }}>
                    <MenuItemTwo value="" disabled className={classes.quantityItem}>
                      {configJSON.selectQuantityLabel}
                    </MenuItemTwo>
                    {this.state.quantityList.map((item: string) => {
                      return (
                        <MenuItemTwo value={item} className={classes.quantityItem}>
                          {item}
                        </MenuItemTwo>
                      );
                    })}
                  </Select>
                </FormControlTwo>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.label}>{configJSON.productOneLabel}</Typography>
                <Grid item xs={12} lg={12} md={12} sm={12} className={classes.searchContainer}>
                  <CustomizeInputSearchBox
                    data-test-id="productOne"
                    id="outlined-start-adornment"
                    value={this.state.productOne}
                    onChange={this.handleSearchProductOne}
                    className={classes.border}
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start" style={webStyle.marginRight}></InputAdornment>,
                      endAdornment: (
                        <InputAdornment position="end" style={webStyle.paddingRight}>
                          <SearchIconSVG />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.label}>{configJSON.productTwoLabel}</Typography>
                <Grid item xs={12} lg={12} md={12} sm={12} className={classes.searchContainer}>
                  <CustomizeInputSearchBox
                    data-test-id="productTwo"
                    id="outlined-start-adornment"
                    value={this.state.productTwo}
                    onChange={this.handleSearchProductTwo}
                    className={classes.border}
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start" style={webStyle.marginRight}></InputAdornment>,
                      endAdornment: (
                        <InputAdornment position="end" style={webStyle.paddingRight}>
                          <SearchIconSVG />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              {this.state.errorMsg ? (
                <Box className={classes.flex}>
                  <img src={errorImage} style={webStyle.errorImg} />
                  <Typography data-test-id="error" className={classes.text}>{this.state.errorMsg}</Typography>
                </Box>
              ) : (
                ""
              )}
              <Box className={classes.btnSection}>
                <Grid container spacing={3} className={classes.btnGrid}>
                  <Grid item>
                    <NextButton data-test-id="next_button" onClick={this.handleCategoryNext}>
                      {configJSON.nextBtn}
                    </NextButton>
                  </Grid>
                </Grid>
              </Box>
              </Grid>
            </>
          ) : (
            <>
            <Grid container className={classes.space} spacing={4}>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.space}>
                <Typography className={classes.mainText}>{configJSON.categoryOneLabel}</Typography>
              </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.spaceTwo}>
                <CustomizeBrandInput
                  data-test-id="categoryName"
                  label={configJSON.categoryNameLabel}
                  value={this.state.categoryName}
                  id="outlined-start-adornment"
                  onChange={this.handleCategoryName}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                  inputProps={{
                    style: webStyle.inputText,
                  }}
                />
              </Grid>
              <Box className={classes.catImgLabel}>{configJSON.categoryImageLabel}</Box>
              <input 
                data-test-id="categoryImage" 
                type="file" 
                className={classes.dropImage} 
                accept=".jpeg, .jpg, .png" 
                value="" 
                ref={this.categoryImgRef} 
                onChange={this.handleUploadCatImage} 
              />
              {this.state.categoryImgPreview ? (
                <Box className={classes.catPreviewContainer}>
                  <img src={this.state.categoryImgPreview} alt="Image Preview" className={classes.catPreview} />
                  <ClickableBox data-test-id="remove_category_image" className={classes.removeImg} onClick={this.handleRemoveCatImage}>
                    {configJSON.removeText}
                  </ClickableBox>
                </Box>
              ) : (
                <label htmlFor="upload-button">
                  <Button variant="outlined" fullWidth data-test-id="open_cat_image" onClick={this.handleOpenCategoryImage} className={classes.uploadCategoryImg}>
                    <Box>
                      <img src={file} />
                      <Box className={classes.boxOne}>{configJSON.dropImageText}</Box>
                      <Box className={classes.boxTwo}>{configJSON.fileSizeInfoText}</Box>
                    </Box>
                  </Button>
                </label>
              )}
              {this.state.categoryErr ? <Typography className={classes.text}>{this.state.categoryErr}</Typography> : ""}
              <Grid container className={classes.space} spacing={4} style={webStyle.bottom}>
                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.space}>
                  <CustomizeBrandInput
                    data-test-id="categoryLink"
                    label={configJSON.categoryLink}
                    id="outlined-start-adornment"
                    value={this.state.categoryLink}
                    onChange={this.handleCategoryLink}
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                  />
                  {this.state.catLinkErr ? <Typography className={classes.text}>{this.state.catLinkErr}</Typography> : ""}
                </Grid>
              </Grid>
            </>
          )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  selectQty: {
    maxHeight: "400px",
    borderRadius: 5,
    background: "white",
    border: "1px solid #b1b3b5",
    boxShadow: "none",
    marginTop: "5px",
    padding: 0,
    overflowY: "auto" as const,
  },
  iconColor: {
    fill: "#666",
  },
  marginRight: {
    marginRight: "20px",
  },
  paddingRight: {
    paddingRight: "20px",
  },
  errorImg: {
    height: "19px",
  },
  inputText: {
    fontSize: "14px",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 500,
    color: "#000",
  },
  bottom: {
    marginBottom: '30px'
  }
};

const styles = {
  space: {
    marginTop: "20px",
  },
  mainText: {
    fontSize: "24px",
    fontWeight: 600,
    marginTop: "-30px",
    fontFamily: `"Lato", sans-serif`,
  },
  selectQtyContainer: {
    fontSize: "14px !important",
    fontFamily: `"Lato", sans-serif !important` as const,
    color: "#333333 !important",
    opacity: "initial",
  },
  quantityItem: {
    fontSize: "14px !important",
    fontFamily: `"Lato", sans-serif !important` as const,
    color: "#33333a !important",
  },
  label: {
    fontSize: "12px",
    color: "#9a9a9a",
    fontWeight: 600 as const,
    fontFamily: `"Lato", sans-serif` as const,
    marginTop: "-10px",
    marginBottom: "4px",
  },
  searchContainer: {
    position: "relative" as const,
  },
  border: {
    "& .MuiInputBase-root": {
      borderColor: "#b1b3b5",
    },
  },
  btnSection: {
    padding: "40px 16px 10px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "60px",
  },
  btnGrid: {
    justifyContent: "flex-end",
  },
  text: {
    color: "red",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "12px",
    marginLeft: "10px",
  },
  flex: {
    display: "flex",
    marginLeft: "20px",
    alignItems: "center",
  },
  spaceOne: {
    marginTop: "10px",
  },
  dropImage: {
    display: "none",
  },
  catPreviewContainer: {
    marginTop: "30px",
    width: "fit-content",
    position: "relative" as const,
  },
  catPreview: {
    maxWidth: "100%",
    maxHeight: "300px",
    alignSelf: "flex-start",
  },
  removeImg: {
    padding: "10px 20px",
    borderRadius: "5px",
    width: "80px",
    background: "#fcaca7",
    fontSize: "14px",
    fontFamily: `"Lato", sans-serif`,
    marginTop: "10px",
  },
  uploadCategoryImg: {
    border: "none",
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23FBBC98FF' stroke-width='3' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    width: "100%",
    borderRadius: "5px",
    fontFamily: `"Lato", sans-serif`,
    height: "210px",
    padding: "20px 0px",
    margin: "10px 0px",
    textTransform: "none" as const,
  },
  boxOne: {
    fontSize: "27px",
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`,
  },
  boxTwo: {
    color: "#9a9a9a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "16px",
  },
  catImgLabel: {
    marginTop: "30px",
    color: "#9a9a9a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "12px",
    fontWeight: 600,
  },
  spaceTwo: {
    marginTop: "40px",
  }
};

export { StoreCategories };
export default withStyles(styles)(StoreCategories);
// Customizable Area End
