import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("../src/config.js")
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes : {
    headerContainer : string,
    headerText: string,
    container : string,
    orderTextWrapper: string,
    ordersText : string,
    searchWrapper: string,
    searchOrderText: string,
    searchBoxWrapper: string,
    contentWrapper : string
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  authToken: string;
  searchValue: string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerFullfilledByEzzyController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      authToken: "",
      searchValue:''
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  handleOnSearch = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({searchValue: event.target.value})
  }

  handleGetToken = async () => {
    const authToken = await getStorageData("authToken");
    this.setState({ authToken });
  };

  async componentDidMount(): Promise<void> {
    this.handleGetToken();
  }
  // Customizable Area End
}
