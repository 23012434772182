import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { IWarehouse, IWarehouseList } from "./StockLogsController.web";

// Customizable Area Start
import React from 'react';
export const configJSON = require("../src/config.js")
import * as Yup from "yup";
import { Typography } from "@material-ui/core";

interface ICreateDeliveryRes {
  meta: {
    message: string
  },
  errors: string[]
}

interface IFormValues {
  name: string
  addressOne: string
  addressTwo: string
  orderNumber: string
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes : {
    newTabText: string,
    newWrapper: string,
    newHeaderWrapper: string,
    newHeaderText: string,
    newFieldWrapper: string,
    newFieldContainer: string,
    newTopMargin: string,
    newButtonContainer: string,
    submitBtn: string,
    mainModalWrapper: string,
    blurredBckgnd: string,
    modalContainerMain: string,
    imageWrapperMain: string,
    imageContainer: string,
    modalText: string,
    description: string,
    lastText: string,
    nameBox: string,
    fielderror: string,
    root: string,
    commonErr: string
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  warehouseName: string,
  orderNo: string,
  addressLineOne: string,
  addressLineTwo: string,
  isSubmit: boolean,
  warehouseNameList: IWarehouseList[],
  warehouseId: string,
  commonErr: string[]
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class CreateDeliveryRequestController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getWarehouseNamesApiCallId: string = "";
  createDeliveryReqApiCallId: string = ""
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.ReceiveMessage), getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
        warehouseName: '',
        orderNo: '',
        addressLineOne: '',
        addressLineTwo: '',
        isSubmit: false,
        warehouseNameList: [],
        warehouseId: '',
        commonErr: []
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.getWarehouseNamesApiCallId) {
        this.handleWarehouseRes(responseJson);
      }
      if (apiRequestCallId === this.createDeliveryReqApiCallId) {
        this.handleCreateDeliveryReq(responseJson)
      }
    }
  }

  handleCreateDeliveryReq = (responseJson: ICreateDeliveryRes) => {
    if(responseJson?.meta?.message === configJSON.reqCreatedSuccessfullyText) {
      this.setState({isSubmit: true})
      return setTimeout(() => {
        this.handleGoToRequestListPage();
      }, 1500);
    }
    if(responseJson && responseJson.errors){
      this.setState({commonErr: responseJson.errors})
    }
  }

  handleGoToRequestListPage = () => {
    this.props.navigation.navigate(configJSON.sellerHomeText, {path: configJSON.requestListPath})
    const message: Message = new Message(getName(MessageEnum.NavigationSellerDashboardMessage));
    message.addData(getName(MessageEnum.ChangeMenuPanel), configJSON.requestListPath);
    this.send(message);
  }

  schema = Yup.object().shape({
    name: Yup.string().required('Warehouse name is required'),
    orderNumber: Yup.string().required('This field is required'),
    addressOne: Yup.string().required('This field is required')
    .trim()
    .matches(/^[a-zA-Z0-9 ]+$/, "Address 1 should be alphanumeric")
    .max(50, 'Max 50 characters allowed'),
    addressTwo: Yup.string().required('This field is required')
    .trim()
    .matches(/^[a-zA-Z0-9 ]+$/, "Address 2 should be alphanumeric")
    .max(50, 'Max 50 characters allowed')
  })

  handleWarehouseRes = (responseJson: IWarehouse) => {
    if(responseJson.data) {
      this.setState({ warehouseNameList : responseJson.data })
    } 
  }

  async componentDidMount(): Promise<void> {
    this.getWarehouseNames()
  }

  handleWarehouseName = (event: React.ChangeEvent<{value: unknown | string}>) => {
    const value = event.target.value
    if(value) {
      const warehouseNumber = this.state.warehouseNameList.map((item: IWarehouseList) => {
        if(item.attributes.warehouse_name === value) {
          return item.attributes.id
        }
      })
      this.setState({ warehouseName: value as string, warehouseId: warehouseNumber[0] as string
      })
    }
    else this.setState({warehouseName: ''})
  }

  handleOrderNumber = (event: React.ChangeEvent<HTMLInputElement>)  => {
    this.setState({ orderNo: event.target.value})
  }

  handleAddressLineOne = (event: React.ChangeEvent<HTMLInputElement>)  => {
    this.setState({ addressLineOne: event.target.value})
  }

  handleAddressLineTwo = (event: React.ChangeEvent<HTMLInputElement>)  => {
    this.setState({ addressLineTwo: event.target.value})
  }

  handleSubmitBtn = (values:IFormValues) => {
    if(values) {
      this.createDeliveryReq()
    }
  } 

  handleCloseIcon = () => {
    this.setState({isSubmit : false})
    this.handleGoToRequestListPage()
  }

  getWarehouseNames = async () => {
    const headers = {
      token: await getStorageData('authToken')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getWarehouseNamesApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sellerWarehouseApiEndPoint.list);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createDeliveryReq = async() => {
    const headers = {
      "Content-Type": "application/json",
      token: await getStorageData('authToken')
    };
    const httpBody = {
      "data": {
          "attributes": {
              "warehouse_id": this.state.warehouseId,
              "warehouse_name":this.state.warehouseName,
              "order_number": this.state.orderNo,
              "address_1": this.state.addressLineOne,
              "address_2": this.state.addressLineTwo,
              "geo_location": ""  
             }
      }
  }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createDeliveryReqApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.createDeliveryReqEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  displayError = (touched:  boolean | undefined, errors: string | undefined) => {
    if (touched && errors) {
      return <Typography data-test-id="error" className={this.props.classes.fielderror}>{errors}</Typography>;
    } else {
      return null;
    }
  };
  // Customizable Area End
}
