// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";
// Customizable Area End

// Customizable Area Start
export interface ICompareSales {
  id: string,
  heading: string, 
  total_order_item: string, 
  units_ordered: string, 
  order_product_sales: string, 
  avg_units: string, 
  avg_sales: string
}

export interface ISales {
  id: string, 
  date: string, 
  ordered_product_sales: string, 
  units_ordered: string, 
  total_order_item: string, 
  avg_sales_per_order_item: string, 
  avg_units_per_order_item:string, 
  avg_selling_price: string, 
  sessions_total: string, 
  order_item_session_per: string, 
  avg_offer_count: string
}

export interface IReturnReport {
  id: string,
  date:string, 
  ordered_product_sales: string,
  units_ordered: string, 
  total_order_item: string, 
  units_refunded: string, 
  refund_rate: string, 
  feedback_received: string, 
  negative_feedback_received: string, 
  received_negative_feedback_rate: string,
  az_claims_granted: string, 
  claims_amount: string
}

export interface ISalesTraffic {
  id: string, 
  parent_besku: string, 
  child_besku: string, 
  title: string, 
  sku: string, 
  sessions_total: string, 
  session_per: string, 
  page_views_total:string,
  page_views_percentage_total: string, 
  featured_offer: string, 
  units_ordered:string, 
  unit_session_per: string, 
  ordered_product_sales: string, 
  total_order_items:string 
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {}
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  reportType: string,
  fromDate: Date | null,
  toDate: Date | null,
  compareSalesList : ICompareSales[],
  salesList: ISales[],
  returnsList: IReturnReport[],
  salesTrafficList: ISalesTraffic[],
  txtInputValue: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      // Customizable Area End
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      reportType: 'Sales report',
      fromDate: null,
      toDate: null,
      compareSalesList: [],
      salesList: [],
      returnsList: [],
      salesTrafficList : [],
      txtInputValue:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    // Customizable Area Start
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }
  // Customizable Area End

  // Customizable Area Start
  compareSalesList = [
    {id: '1', heading: "Today so far", total_order_item: '3', units_ordered: '3', order_product_sales: '217.25', avg_units: '1', avg_sales: '72.42'},
    {id: '2', heading: "Yesterday", total_order_item: '1', units_ordered: '1', order_product_sales: '33.25', avg_units: '1', avg_sales: '33.25'},
    {id: '3', heading: "Same day last week", total_order_item: '0', units_ordered: '0', order_product_sales: '0.00', avg_units: '0', avg_sales: '0.00'},
    {id: '4', heading: "Same day last year", total_order_item: '0', units_ordered: '0', order_product_sales: '0.00', avg_units: '0', avg_sales: '0.00'},
    {id: '5', heading: "+ % change from yesterday",total_order_item: '200%', units_ordered: '200%', order_product_sales: '553.38%', avg_units: '0%', avg_sales: '117.8%'},
    {id: '6', heading: "+ % change from same day last week",total_order_item: 'N/A', units_ordered: 'N/A', order_product_sales: 'N/A', avg_units: 'N/A', avg_sales: 'N/A'},
    {id: '7', heading: "+ % change from same day last year",total_order_item: 'N/A', units_ordered: 'N/A', order_product_sales: 'N/A', avg_units: 'N/A', avg_sales: 'N/A'},
  ]

  salesList = [
    {id: '1', date:'23/05/2024', ordered_product_sales: '253.55', units_ordered: '4', total_order_item: '4', avg_sales_per_order_item: '63.39', avg_units_per_order_item:'1', avg_selling_price: '63.39', sessions_total: '25', 'order_item_session_per': '16%', avg_offer_count: '14'},
    {id: '2', date:'25/05/2024', ordered_product_sales: '253.55', units_ordered: '4', total_order_item: '4', avg_sales_per_order_item: '63.39', avg_units_per_order_item:'1', avg_selling_price: '63.39', sessions_total: '25', 'order_item_session_per': '16%', avg_offer_count: '14'},
    {id: '3', date:'26/05/2024', ordered_product_sales: '253.55', units_ordered: '4', total_order_item: '4', avg_sales_per_order_item: '63.39', avg_units_per_order_item:'1', avg_selling_price: '63.39', sessions_total: '25', 'order_item_session_per': '16%', avg_offer_count: '14'},
    {id: '4', date:'31/05/2024', ordered_product_sales: '253.55', units_ordered: '4', total_order_item: '4', avg_sales_per_order_item: '63.39', avg_units_per_order_item:'1', avg_selling_price: '63.39', sessions_total: '25', 'order_item_session_per': '16%', avg_offer_count: '14'},
    {id: '5', date:'03/06/2024', ordered_product_sales: '253.55', units_ordered: '4', total_order_item: '4', avg_sales_per_order_item: '63.39', avg_units_per_order_item:'1', avg_selling_price: '63.39', sessions_total: '25', 'order_item_session_per': '16%', avg_offer_count: '14'},    
    {id: '6', date:'05/06/2024', ordered_product_sales: '253.55', units_ordered: '4', total_order_item: '4', avg_sales_per_order_item: '63.39', avg_units_per_order_item:'1', avg_selling_price: '63.39', sessions_total: '25', 'order_item_session_per': '16%', avg_offer_count: '14'},
    {id: '7', date:'07/06/2024', ordered_product_sales: '253.55', units_ordered: '4', total_order_item: '4', avg_sales_per_order_item: '63.39', avg_units_per_order_item:'1', avg_selling_price: '63.39', sessions_total: '25', 'order_item_session_per': '16%', avg_offer_count: '14'},
    {id: '8', date:'15/06/2024', ordered_product_sales: '253.55', units_ordered: '4', total_order_item: '4', avg_sales_per_order_item: '63.39', avg_units_per_order_item:'1', avg_selling_price: '63.39', sessions_total: '25', 'order_item_session_per': '16%', avg_offer_count: '14'}
  ]

  returnList = [
    { id: '1',date:'23/05/2024', ordered_product_sales: '253.55',units_ordered: '4', total_order_item: '4', units_refunded: '1', refund_rate: '25%', feedback_received: '0', negative_feedback_received: '0', received_negative_feedback_rate: '0%', az_claims_granted: '0', claims_amount: '0.00' },
    { id: '2',date:'23/05/2024', ordered_product_sales: '253.55',units_ordered: '4', total_order_item: '4', units_refunded: '1', refund_rate: '25%', feedback_received: '0', negative_feedback_received: '0', received_negative_feedback_rate: '0%', az_claims_granted: '0', claims_amount: '0.00' },
    { id: '3',date:'23/05/2024', ordered_product_sales: '253.55',units_ordered: '4', total_order_item: '4', units_refunded: '1', refund_rate: '25%', feedback_received: '0', negative_feedback_received: '0', received_negative_feedback_rate: '0%', az_claims_granted: '0', claims_amount: '0.00' },
    { id: '4',date:'23/05/2024', ordered_product_sales: '253.55',units_ordered: '4', total_order_item: '4', units_refunded: '1', refund_rate: '25%', feedback_received: '0', negative_feedback_received: '0', received_negative_feedback_rate: '0%', az_claims_granted: '0', claims_amount: '0.00' },
    { id: '5',date:'23/05/2024', ordered_product_sales: '253.55',units_ordered: '4', total_order_item: '4', units_refunded: '1', refund_rate: '25%', feedback_received: '0', negative_feedback_received: '0', received_negative_feedback_rate: '0%', az_claims_granted: '0', claims_amount: '0.00' },
    { id: '6',date:'23/05/2024', ordered_product_sales: '253.55',units_ordered: '4', total_order_item: '4', units_refunded: '1', refund_rate: '25%', feedback_received: '0', negative_feedback_received: '0', received_negative_feedback_rate: '0%', az_claims_granted: '0', claims_amount: '0.00' },
    { id: '7',date:'23/05/2024', ordered_product_sales: '253.55',units_ordered: '4', total_order_item: '4', units_refunded: '1', refund_rate: '25%', feedback_received: '0', negative_feedback_received: '0', received_negative_feedback_rate: '0%', az_claims_granted: '0', claims_amount: '0.00' },
    { id: '8',date:'23/05/2024', ordered_product_sales: '253.55',units_ordered: '4', total_order_item: '4', units_refunded: '1', refund_rate: '25%', feedback_received: '0', negative_feedback_received: '0', received_negative_feedback_rate: '0%', az_claims_granted: '0', claims_amount: '0.00' }
  ]

  salesTrafficList = [
    { id: '1', parent_besku: 'BOCHFN3ZX6', child_besku: 'BOCHFN3ZX6', title: 'DUGYLUV Dog Bed', sku: 'bed2323', sessions_total: '232', session_per: '13.65%', page_views_total:'342', page_views_percentage_total: '12.93%', featured_offer: '97.7%', units_ordered:'23', unit_session_per: '9.91%', ordered_product_sales: '2,618.00', total_order_items:'23' },
    { id: '2', parent_besku: 'BOCHFN3ZX6', child_besku: 'BOCHFN3ZX6', title: 'DUGYLUV Dog Bed', sku: 'bed2323', sessions_total: '232', session_per: '13.65%', page_views_total:'342', page_views_percentage_total: '12.93%', featured_offer: '97.7%', units_ordered:'23', unit_session_per: '9.91%', ordered_product_sales: '2,618.00', total_order_items:'23' },
    { id: '3', parent_besku: 'BOCHFN3ZX6', child_besku: 'BOCHFN3ZX6', title: 'DUGYLUV Dog Bed', sku: 'bed2323', sessions_total: '232', session_per: '13.65%', page_views_total:'342', page_views_percentage_total: '12.93%', featured_offer: '97.7%', units_ordered:'23', unit_session_per: '9.91%', ordered_product_sales: '2,618.00', total_order_items:'23' },
    { id: '4', parent_besku: 'BOCHFN3ZX6', child_besku: 'BOCHFN3ZX6', title: 'DUGYLUV Dog Bed', sku: 'bed2323', sessions_total: '232', session_per: '13.65%', page_views_total:'342', page_views_percentage_total: '12.93%', featured_offer: '97.7%', units_ordered:'23', unit_session_per: '9.91%', ordered_product_sales: '2,618.00', total_order_items:'23' },
    { id: '5', parent_besku: 'BOCHFN3ZX6', child_besku: 'BOCHFN3ZX6', title: 'DUGYLUV Dog Bed', sku: 'bed2323', sessions_total: '232', session_per: '13.65%', page_views_total:'342', page_views_percentage_total: '12.93%', featured_offer: '97.7%', units_ordered:'23', unit_session_per: '9.91%', ordered_product_sales: '2,618.00', total_order_items:'23' },
    { id: '6', parent_besku: 'BOCHFN3ZX6', child_besku: 'BOCHFN3ZX6', title: 'DUGYLUV Dog Bed', sku: 'bed2323', sessions_total: '232', session_per: '13.65%', page_views_total:'342', page_views_percentage_total: '12.93%', featured_offer: '97.7%', units_ordered:'23', unit_session_per: '9.91%', ordered_product_sales: '2,618.00', total_order_items:'23' },
    { id: '7', parent_besku: 'BOCHFN3ZX6', child_besku: 'BOCHFN3ZX6', title: 'DUGYLUV Dog Bed', sku: 'bed2323', sessions_total: '232', session_per: '13.65%', page_views_total:'342', page_views_percentage_total: '12.93%', featured_offer: '97.7%', units_ordered:'23', unit_session_per: '9.91%', ordered_product_sales: '2,618.00', total_order_items:'23' },
    { id: '8', parent_besku: 'BOCHFN3ZX6', child_besku: 'BOCHFN3ZX6', title: 'DUGYLUV Dog Bed', sku: 'bed2323', sessions_total: '232', session_per: '13.65%', page_views_total:'342', page_views_percentage_total: '12.93%', featured_offer: '97.7%', units_ordered:'23', unit_session_per: '9.91%', ordered_product_sales: '2,618.00', total_order_items:'23' }
  ]

  async componentDidMount(): Promise<void> {
    this.setState({compareSalesList: this.compareSalesList, salesList: this.salesList, returnsList: this.returnList, salesTrafficList: this.salesTrafficList})
  }
    
  handleChangeReportType = (event: React.ChangeEvent<{ name?: string ; value: unknown; }>) => {
    this.setState({ reportType: event.target.value as string })
  }

  fromDate = (date:  Date | null) => {
    this.setState({
      fromDate: date,
    });
  };

  toDate = (date:  Date | null) => {
    this.setState({
      toDate: date,
    });
  };
  // Customizable Area End
}
