// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Modal,
  Radio,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import HeaderWeb from "../../../landingpage/src/Header.web";
import ShippingAddressController, {
  Props,
  configJSON,
} from "./ShippingAddressController.web";
import { deleteIcon, editIcon } from "../assets";
import {address, background, cartBox, cartBoxDes, cartText, detail, finalPriceHeader, horizontal, link, path, priceContainer, span, wrapper, zoom} from "./commomstyles";
import { CustomBackButton, CustomDeleteButton, CustomScrollbar, OrangeButton } from "../../../dashboard/src/CustomizedElementStyle";
import { ScrollView } from "react-native";
import Footer from "../../../landingpage/src/Footer.web";
import { GetCookies } from "../../../../framework/src/WebUtilities";
import { IAddress } from "./OrderOverviewController.web";
// Customizable Area End

class ShippingAddress extends ShippingAddressController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {total_fees, total_tax, final_price} = this.state.priceData
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      <ScrollView>
        <HeaderWeb navigation={this.props.navigation} id={this.props.id} />
        <Container className={classes.zoom}>
        <Box className={classes.backgroundColor}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.wrapper}>
              <Typography data-test-id="home" onClick={this.handleHomeClick} className={`${classes.addressPath} ${classes.navigate}`}>
              {configJSON.home}
              </Typography>
              <Typography data-test-id="cart" onClick={this.handleCartRedirection} className={`${classes.addressPath} ${classes.navigate}`}> / {configJSON.myCartText} 
              </Typography>
              <span className={classes.span}>/ {configJSON.address}</span>
            </Grid>
          </Grid>
          <Box className={classes.box} >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8} lg={9} className={classes.leftContainer}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={classes.addressText}>
                      {configJSON.shippingAddressLabel}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className={classes.descGrid}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={classes.boxDes}>
                      {""}
                    </Typography>
                  </Grid>
                  <Button onClick={this.handleAddAddressBtn} data-test-id="addNewAddress" className={`${classes.addNewAddressBtn} ${classes.newLink}`}>
                    + ADD NEW ADDRESS
                  </Button>
                  <Divider
                    orientation="horizontal"
                    className={classes.horizontalSec}
                  />
                  <Divider className={classes.divider} orientation="vertical" />
                </Grid>
                {this.state.addressList.length > 0 &&
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.shippingAddLabel}>
                    {configJSON.shippingAddressLabel}
                  </Typography>
                </Grid>
                }

                <CustomScrollbar className={classes.addressScroll}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} >

                    {this.state.addressList.map((item: IAddress) => {
                      const addressId = GetCookies('setAddress')
                      return (
                        <>
                          <Grid container>
                            <Grid item xs={12} sm={6} md={7} lg={7}  className={classes.tableCell}>
                              <Box>
                              <Radio
                                name="address"
                                className={classes.radio}
                                checked={item.id === (this.state.setOrderId || addressId)}
                                onChange={() =>
                                  this.handleChangeAddress(item)
                                }
                              />
                              </Box>
                            <Box className={classes.addressWrapper}>
                              <Typography className={classes.name}>
                                {item.attributes.first_name} {item.attributes.last_name}
                              </Typography>
                              <Typography className={classes.summaryDesc}>
                                {item.attributes.address_1} {item.attributes.address_2}, {item.attributes.city} ,{item.attributes.phone_number} {(item.attributes.state)}
                              </Typography>
                            </Box>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} lg={2} ></Grid>
                            <Grid item xs={12} sm={4} md={3} lg={3} className={classes.btnWrap}>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.deleteWrapper}>
                                <Box data-test-id="deleteIcon" onClick={() => this.handleOpenModal(item.id)} className={classes.deleteWrapper}>
                            <img src={deleteIcon} alt="delIcon" className={classes.delIcon} />
                                <Typography className={classes.deleteText}>{configJSON.deleteBtnLabel}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.editWrapper}>
                                <Box className={classes.editContainer}>
                                <Box data-test-id="editBtn" onClick={() => this.handleEditAddress(item.id)} className={classes.link}>
                            <img src={editIcon} alt="editIcon" className={classes.delIcon}/>
                                <Typography className={classes.editText}>{configJSON.editBtnLabel}</Typography>
                                </Box>
                                </Box>
                            </Grid>
                            </Grid>
                          </Grid>
                          <Divider className={`${classes.horizontal} ${classes.horizontalLine}`}/>
                        </>
                      );
                    })}
                  </Grid>
                </Grid>
                </CustomScrollbar>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3} className={classes.orderSummaryWrap}>
                <Grid container className={classes.summaryGrid}>
                    <Typography className={classes.summary}>{configJSON.orderSummaryLabel}</Typography>
                    <Typography className={classes.summaryDesc}>{""}</Typography>
                </Grid>
                <Grid container className={classes.priceWrapper}>
                        <Grid item xs={12} sm={12} md={12} lg={12} className={`${classes.text} ${classes.bottom}`}>
                            <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Typography className={classes.priceHeader} >{configJSON.totalPrice}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.align}>
                            <Typography className={classes.priceHeaderOne}>AED {total_fees}</Typography>
                            </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} className={`${classes.text} ${classes.gridOne}`}>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography className={classes.priceHeader}>{configJSON.tax}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.align}>
                            <Typography className={classes.priceHeaderOne}>{configJSON.AedText} {total_tax}</Typography>
                            </Grid>
                            </Grid>
                        </Grid>
                        <Divider className={classes.horizontal}/>
                        <Grid item xs={12} sm={12} md={12} lg={12} className={`${classes.text} ${classes.gridOne}`}>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography className={classes.priceHeader}>{configJSON.total}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.align}>
                        <Typography className={`${classes.price_header} ${classes.rightAlign}`}>{configJSON.AedText} {final_price}</Typography>
                        </Grid>
                        </Grid>
                    </Grid>
                    </Grid>
                    <OrangeButton data-test-id="placeOrder" disabled={this.state.disabled} onClick={this.handlePlaceOrderBtn} className={classes.nextBtn}>{configJSON.paymentNowBtn}</OrangeButton>
                    {this.state.setErrorMsg ? <Typography data-test-id="errorMsg" className={classes.errorMsg}>{configJSON.pleaseSelectShippingAddressText}</Typography> : ''}
                    {this.state.disabled && <Typography 
                      style={{
                        color: 'red',
                        textAlign: 'center',
                        marginTop: '4px',
                        fontSize: "14px",
                        fontWeight: 500
                      }}>
                      {configJSON.removeOutOfStocks}
                    </Typography>}
              </Grid>
            </Grid>
          </Box>
        </Box>
        </Container>
        <Footer navigation={this.props.navigation} id={this.props.id} categories={[]} token={""} />
        </ScrollView>
        <Modal open={this.state.isOpen}>
                <Box className={classes.modalWrapper}>
                    <Box className={classes.modal}>
                        <Box className={classes.modalText}>{configJSON.delAddressConfirmationText}</Box>
                        <Box className={classes.modalBtnWrapper}>
                            <CustomBackButton data-test-id="back" onClick={this.handleCloseModal}>
                                {configJSON.ReturnAndWarrenty.cancelButton}
                            </CustomBackButton>
                            <CustomDeleteButton data-test-id="delete" onClick={this.handleDeleteAddress}>
                                {configJSON.deleteBtnLabel}
                            </CustomDeleteButton>
                        </Box>
                    </Box>
                </Box>
        </Modal>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = (theme:Theme) => ({
  modalWrapper: { 
    width: "100%", 
    height: "100vh", 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center " ,
    background: "rgba(0,0,0,0.1)" 
  },
  modal : { 
    width: "300px", 
    height: "auto", 
    display: "flex", 
    flexDirection: "column" as const, 
    justifyContent: "center", 
    borderRadius: "8px", 
    padding: "50px" ,
    background: "white" 
  },
  modalText: {
    marginBottom: "20px", 
    textAlign: "center" as const, 
    fontFamily: `"Lato", sans-serif`
  },
  modalBtnWrapper: { 
    width: "100%", 
    display: "flex", 
    justifyContent: "space-between" 
  },
  horizontal: horizontal,
  addressPath: path,
  backgroundColor: background,
  span: span,
  addressText: {...cartText,
    [theme.breakpoints.down("md")]: {
      fontSize: "28px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px"
    }
  },
  box: cartBox,
  boxDes: cartBoxDes,
  divider: {
    height: "90%",
    position: "absolute" as const,
    left: "102%",
    [theme.breakpoints.down("md")] : {
      display: 'none'
    }
  },
  addNewAddressBtn: {
    margin: "40px 0px 20px",
    height: "40px",
    borderRadius: "5px",
    border: "1px solid #ff6008",
    background: "#fffaf7",
    color: "#ff6008",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: "12px",
    fontWeight: 400,
    letterSpacing: "2px",
    width: "100%",
    padding: "8px 0",
  },
  horizontalSec: {
    marginTop: "10px",
    width: "100%",
  },
  shippingAddLabel: {
    margin: "20px 0",
    color: "#030303",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: "23px",
    fontWeight: 700,
    [theme.breakpoints.down("md")]: {
      fontSize: "20px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px"
    }
  },
  radio: {
    color: "#FF6008",
    marginRight:'-20px',
    paddingLeft : '0',
    "&.Mui-checked": {
      color: "#FF6008",
    },
  },
  tableCell: {
    display: "flex",
    justifyContent: "flex-start",
  },
  addressWrapper: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "flex-start",
    justifyContent: "center",
    marginLeft: '22px'
  },
  name: {
    color: "#33333a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "18px",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "16px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px"
    }
  },
  summaryDesc: {...address,
    wordBreak: 'break-all' as const,
    maxWidth: '500px',
  [theme.breakpoints.down("md")]: {
      fontSize: "14px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      marginRight:'20px'
    }
  },
  deleteWrapper : {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    [theme.breakpoints.down("xs")]: {
      justifyContent: 'flex-start',
      marginLeft: '16px'
    }
  },
  editContainer : {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    [theme.breakpoints.down("xs")]: {
      justifyContent: 'flex-start',
    }
  },
  btnWrap: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    [theme.breakpoints.down("xs")]: {
      marginTop:'20px'
    }
  },
  editWrapper:{
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    marginRight:'10px',
    [theme.breakpoints.down("xs")]: {
      justifyContent: 'flex-start',
    }
  },
  delIcon : {
    paddingRight: '10px'
  },
  deleteText: {
    color: '#e30303',
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    fontWeight: 400,
    cursor: 'pointer'
  },
  editText: {
    color: '#147bd1',
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    fontWeight: 400,
  },
  text: {
    display:'flex',
    alignItems:'flex-start',
    flexDirection:'column' as const,
    justifyContent: 'space-between'
  },
  priceHeader: detail,
  nextBtn: {
    padding : '16px 16px',
    marginTop:'20px',
    fontSize:'12px',
    fontFamily: `"Montserrat", sans-serif`,
    fontWeight: 700 as const,
    width:'100%',
    textTransform: 'uppercase' as const,
    letterSpacing: '2px',
    "&:hover": {
      backgroundColor: '#ff7f50',
    }
  },
  priceWrapper: priceContainer,
  bottom: {
    paddingTop:'50px',
    display:'flex',
    alignItems:'flex-start',
    flexDirection:'column' as const,
    justifyContent: 'space-between'
  },
  summary: {
    color: '#33333a',
    fontSize:'24px',
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 600 as const,
    [theme.breakpoints.down("md")]: {
      fontSize: "20px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px"
    }
  },
  link : {
    textDecorationLine: 'none',
    display:'flex'
  },
  errorMsg: {
    color: "red",
    textAlign: "center" as const,
    fontSize: "12px",
    marginTop: "5px",
  },
  orderSummaryWrap: {
    margin:'460px 0px 30px 0px', 
    padding:'8px 28px 8px 60px !important',
    [theme.breakpoints.down("md")]: {
      width: "30%",
      justifyContent: "flex-end",
    },
    [theme.breakpoints.down("sm")]: {
      margin: '50px 40px',
      padding:'0 !important'
    },
    [theme.breakpoints.down("xs")]: {
      margin: '30px 20px',
      padding:'0 !important'
    },
  },
  price_header: finalPriceHeader,
  line: {
    borderRight: '1px solid #e4e4e4',
    height:'100%',
    marginTop:'20px'
  },
  align: {
    display:'flex',
    justifyContent: 'flex-end'
  },
  priceHeaderOne: {
    color: '#545454',
    fontSize:'13px',
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 400 as const,
    textAlign: 'right' as const
  },
  navigate: {...link,
    cursor: 'pointer'
  },
  wrapper : wrapper,
  zoom: zoom,
  addressScroll: {
    width: "100%", 
    height: "550px", 
    overflowY: "auto" as const,
    overflowX:'hidden' as const,
  },
  leftContainer: {
    position:'relative' as const, 
    padding:'8px 20px 8px 8px'
  },
  descGrid: { 
    justifyContent: "space-between" 
  },
  newLink: {
    width:'100%'
  },
  horizontalLine: {
    margin:'16px 0'
  },
  summaryGrid: {
    display:'flex', 
    flexDirection:'column' as const, 
    alignItems:'flex-start', 
    justifyContent:'center'
  },
  gridOne: {
    display:'flex', 
    alignItems:'flex-end'
  },
  rightAlign: { 
    textAlign:'right' as const
  }
});

export { ShippingAddress };
export default withStyles(styles)(ShippingAddress);
// Customizable Area End
