import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { ICheckUserInfoSuccessResponse, IDocsStatusData, IDocsStatusResponse } from "./SellerDashboardComponents/Interfaces";
import { SetCookies } from "../../../framework/src/WebUtilities";
export const configJSON2 = require("../../catalogue/src/config.js");
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeMenu: string;
  authToken: string;
  seller_id: string;
  isProductManagementActive: boolean;
  isStoreManagementActive: boolean;
  showMenuPanel: boolean;
  isFulfilledByByEzzyActive: boolean;
  isFulfilledByPartnerActive: boolean;
  profile_picture: string | null;
  isWarehouseListExist: boolean;
  windowWidth: number
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerHomeDashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetSellerInfo: string = "";
  getWarehouseListId: string = "";
  apiCheckDocsHomeCallId: string = ""
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      activeMenu: "products",
      isProductManagementActive: true,
      authToken: "",
      seller_id: "",
      showMenuPanel: true,
      isStoreManagementActive: false,
      isFulfilledByByEzzyActive: false,
      isFulfilledByPartnerActive: false,
      profile_picture: null,
      isWarehouseListExist: false,
      windowWidth: window.innerWidth
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    this.handleNavigationReceive(message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || [];
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId === this.apiGetSellerInfo) {
        this.handleGetSellerInfo(responseJson);
      }
      if (this.getWarehouseListId === apiRequestCallId) {
        this.handleWarehouselistApiResponse(responseJson);
      }
      if (apiRequestCallId === this.apiCheckDocsHomeCallId) {
        this.handleUserDocsResponse(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  handleGetSellerInfo = (responseJson: unknown) => {
    const successData = responseJson as ICheckUserInfoSuccessResponse;
    if ("data" in successData) {
      const { attributes } = successData.data;
      SetCookies("seller_email", attributes.email, 7);
      SetCookies("seller_first_name", attributes.first_name, 7);
      SetCookies("seller_last_name", attributes.last_name, 7);
      return;
    }
  };

  handleChangeMenu = (activeMenu: string) => {
    if(activeMenu === "discount-offers") {
      this.handlePromotionAndAds()
    }
    this.props.navigation.navigate(configJSON.sellerHomeText, {path: activeMenu})
    this.setState({ activeMenu });
    if(window.innerWidth < 600)
    {
      this.setState({showMenuPanel: true}, () => this.handleShowMenuPanel())
    }
  };

  handlePromotionAndAds = () => {
    this.setState({ activeMenu: "add-product" }, () => {
      const message: Message = new Message(getName(MessageEnum.SendMessage));
      message.addData(getName(MessageEnum.ChangeActivePanelProductDetails), { activePanel: "deals", activeScreen: "deals" });
      this.send(message);
    });
  };

  handleGetSellerProfileInfo = async () => {
    const seller_id = await getStorageData("seller_id");
    const authToken = await getStorageData("authToken");
    const profile_picture = await getStorageData("profile_picture");
    if (!seller_id || !authToken) {
      return;
    }
    this.setState({ authToken, seller_id, profile_picture });
    this.handleWarehouseListApiCall();
  };

  handleShowMenuPanel = () => {
    if (this.state.showMenuPanel) {
      const message: Message = new Message(getName(MessageEnum.NavigationSellerNavigationBarMessage));
      message.addData(getName(MessageEnum.HideSellerNavigationBar), true);
      this.send(message);
    }
    this.setState({ showMenuPanel: !this.state.showMenuPanel });
  };

  async componentDidMount(): Promise<void> {
    const token = await getStorageData('authToken');
    if(token === 'null' || !token) {
      this.props.navigation.navigate("FeaturesHeader");
    }
    window.addEventListener('resize', this.handleResize);
    this.checkDocsHomeUser()
    this.handleCheckActiveMenu()
    this.handleGetSellerProfileInfo();
  }

  handleResize = () => {
    this.setState({windowWidth: window.innerWidth}, () => this.setRightStyle())
  }

  handleCheckActiveMenu = () => {
    if (this.state.activeMenu === configJSON.singleProductSelCategoryPath || this.state.activeMenu === configJSON.bulkSelectCategoryPath || this.state.activeMenu === "searchbrand" || this.state.activeMenu === "products" || this.state.activeMenu === "search-product" || this.state.activeMenu === "add-product" || this.state.activeMenu === configJSON.bulkUploadFilePath || this.state.activeMenu === configJSON.bulkUploadSearchBrandPath || this.state.activeMenu === configJSON.bulkUploadDownloadTempId) {
      return this.setState({ isFulfilledByPartnerActive: false, isProductManagementActive: true, isFulfilledByByEzzyActive: false });
    }
    else if (this.state.activeMenu === configJSON.createStorePath || this.state.activeMenu === configJSON.storePath) {
      return this.setState({ isFulfilledByPartnerActive: false, isStoreManagementActive: true, isFulfilledByByEzzyActive: false });
    }
    else if (this.state.activeMenu === configJSON.storagePath || this.state.activeMenu === configJSON.byezzyOrdersPath || this.state.activeMenu === configJSON.createStockReqPath || this.state.activeMenu === "fees-disputes" || this.state.activeMenu === "damages-returns") {
      return this.setState({ isFulfilledByPartnerActive: false, isFulfilledByByEzzyActive: true, isProductManagementActive: false });
    }
    else if (this.state.activeMenu === configJSON.partnerOrdersPath || this.state.activeMenu === configJSON.partnerOrderDetailsPath || this.state.activeMenu === configJSON.returnsPath || this.state.activeMenu === configJSON.returnDetailsPath || this.state.activeMenu === configJSON.warehousePath || this.state.activeMenu === configJSON.createWarehousePath || this.state.activeMenu === configJSON.warehouseListPath || this.state.activeMenu === configJSON2.stocklogsPath || this.state.activeMenu === configJSON2.deliveryReqPath || this.state.activeMenu === configJSON.createDeliveryReqPath || this.state.activeMenu === configJSON.requestListPath) {
      return this.setState({ isFulfilledByPartnerActive: true, isFulfilledByByEzzyActive: false, isProductManagementActive: false });
    }
    else this.setState({ isFulfilledByPartnerActive: false, isProductManagementActive: false, isFulfilledByByEzzyActive: false });
  };

  getSellerInfo = async () => {
    const headers = { "Content-Type": configJSON.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetSellerInfo = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCheckLoggedUserEndpoint + (await getStorageData("authToken")));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleWarehouselistApiResponse = (responseJson: {data: { id: string}[]}) => {
    if (responseJson.data && responseJson.data.length > 0) {
      this.setState({ isWarehouseListExist: true });
    } else {
      this.setState({ isWarehouseListExist: false });
    }
  };

  handleChangeWarehouseMenu = () => {
      this.handleChangeMenu(configJSON.warehouseListPath);
  };

  handleWarehouseListApiCall = async () => {
    const headers = {
      token: this.state.authToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getWarehouseListId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sellerWarehouseApiEndPointList);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    const path = window.location && window.location.pathname;
    const tabName = path.split("/")
    if (tabName[tabName.length - 1] !== prevState.activeMenu) {
      this.setState({
        activeMenu: tabName[tabName.length - 1],
      });

    this.handleCheckActiveMenu()
    }
    this.getSellerInfo();
  }

  handleLogo = () => {
    this.props.navigation.navigate("SellerHomeDashboard", {path:"products"})
  }

  setRightStyle = () => {
    let marginLeft;
    const {windowWidth} = this.state
    if(windowWidth <= 600) {
      marginLeft = 0
    }
    else if(windowWidth > 600){
      marginLeft = this.state.showMenuPanel ? '260px' : '0px'
    }
    return marginLeft
  }

  handleNavigationReceive = (message: Message) =>{
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const showMenuPanel = message.getData(getName(MessageEnum.ShowSellerDashboardMenu)) || false;
      if (showMenuPanel) {
        this.setState({ showMenuPanel: !this.state.showMenuPanel });
      }
      const menuPanel = message.getData(getName(MessageEnum.ChangeMenuPanel)) || false;
      if (menuPanel) {
        this.setState({ activeMenu: menuPanel });
      }
      const warehouseListItems = message.getData(getName(MessageEnum.warehouseListExist)) || [];
      if (warehouseListItems) {
        this.setState({ isWarehouseListExist: warehouseListItems.length > 0 });
      }
    }
  }

  checkDocsHomeUser = async () => {
    const sellerId = await getStorageData("seller_id");
    const auth_Token = await getStorageData("authToken");
    if (!sellerId || !auth_Token) {
      return;
    }
    const headersHom = { "Content-Type": configJSON.contentType, token: auth_Token };
    const requestMessageHome = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCheckDocsHomeCallId = requestMessageHome.messageId;
    requestMessageHome.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessageHome.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiGetSellerDocumentsStatusEndpoint + sellerId + "/seller_documents");
    requestMessageHome.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headersHom));
    runEngine.sendMessage(requestMessageHome.id, requestMessageHome);
  };

  handleUserDocsResponse = (responseJson: unknown) => {
    const resp = responseJson as IDocsStatusResponse & { error: string};
    if(resp.error) {
      this.props.navigation.navigate("BulkUploading")
    }
    if ("data" in resp) {
      const docs = resp.data.reduce((newArr: unknown[], value: IDocsStatusData) => [...newArr, value.attributes.approved], []);      
      if(resp.data.length === 0) {
        this.props.navigation.navigate("SellerLanding")
      } else if (resp.data.length < 5){
        this.props.navigation.navigate("BulkUploading")
      }else if (resp.data.length === 5 && (docs.includes(false) || docs.includes(null))) {
        this.props.navigation.navigate("SellerDashboard", {path: "myprofile"})
      }
    }
  };

  // Customizable Area End
}
