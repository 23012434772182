//@ts-nocheck
import React from "react";

// Customizable Area Start
import { Box, Grid, ThemeProvider } from "@material-ui/core";
import { theme, CustomizeDrawer } from "./CustomizedElementStyle";
import { byezzyLogo } from "./assets";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SellerNavigationBar from "./SellerDashboardComponents/SellerNavigationBar.web";
import { ClickableBox } from "../../otp-input-confirmation/src/CustomElementStyling";
import SellerBrandCatalog from "./SellerHomeDashboardComponents/SellerBrandCatalog.web";
import SellerBrandCatalogList from "./SellerHomeDashboardComponents/SellerBrandCatalogList.web";
import { Link } from "react-router-dom";
import { styledAddBrand as SellerAddBrandProduct } from "../../catalogue/src/SellerAddBrandProduct.web";
import { styledCategories as SellerCategorize } from "./SellerHomeDashboardComponents/SellerCategorize.web";
import { styledSearchBrand as SearchBrand } from "./SellerHomeDashboardComponents/SearchBrand.web";
import { styledAddSingle as SellerAddSingle } from "./SellerHomeDashboardComponents/SellerAddSingle.web";
import SellerDragDropFile from "./SellerHomeDashboardComponents/SellerDragDropFile.web";
import { styledProductName as SearchProductName } from "./SellerHomeDashboardComponents/SearchProductName.web";
import SellerAddNewProduct from "./SellerHomeDashboardComponents/SellerAddNewProduct.web";
import SellerCoupons from "./SellerHomeDashboardComponents/SellerCoupons.web";
import TermsAndCondition from "./Others/TermsAndCondition.web";
import PrivacyPolicy from "./Others/PrivacyPolicy.web";
import Contatus from "./Others/Contatus.web";
import Welcome from "./StoreManagement/Welcome";
import CreateStorePage from "./StoreManagement/CreateStorePage.web";
import CreateTabs from "./StoreManagement/CreateTabs.web";
import SellerFullfilledByEzzy from "../../catalogue/src/SellerFullfilledByEzzy.web";
import FBBStockManagementStorage from "../../catalogue/src/FBBStockManagementStorage.web";
import HappinessRating from "../../catalogue/src/HappinessRating.web";
import SellerBulkProductUpload from "./SellerHomeDashboardComponents/SellerBulkUpload.web";
// Customizable Area End

import SellerHomeDashboardController, { Props, configJSON } from "./SellerHomeDashboardController";
import SellerPartnerOrderManagement from "../../catalogue/src/SellerPartnerOrderManagement.web";
import WarehouseHome from "../../catalogue/src/WarehouseHome.web";
import WarehouseList from "../../catalogue/src/WarehouseList.web";
import CreateWarehouse from "../../catalogue/src/CreateWarehouse.web";
import StockLogs from "../../catalogue/src/StockLogs.web";
import DeliveryRequestHome from "../../catalogue/src/DeliveryRequestHome.web";
import DeliveryRequestList from "../../catalogue/src/DeliveryRequestList.web";
import {withStyleCreateDelivery as CreateDeliveryRequest}  from "../../catalogue/src/CreateDeliveryRequest.web";
import SellerPartnerOrderDetails from "../../catalogue/src/SellerPartnerOrderDetails.web";
import CreateStockIntakeRequest from "../../catalogue/src/CreateStockIntakeRequest.web";
import FBBFeesAndDisputes from "../../catalogue/src/FBBFeesAndDisputes.web";
import FBBDamagesAndReturns from "../../catalogue/src/FBBDamagesAndReturns.web";
import SellerSidebar from "./SellerSidebar.web";
import StoreTabs from "../../dashboard/src/StoreManagement/StoreTabs.web";
import FulfilledByPartnerReturns  from "../../catalogue/src/FulfilledByPartnerReturns.web";
import ReturnItemDetails from "../../catalogue/src/ReturnItemDetails.web";
import Analytics from "../../analytics/src/Analytics.web";
import Promotions from "./Promotions.web";

export default class SellerHomeDashboard extends SellerHomeDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentToRender() {
    const { activeMenu } = this.state;
    const menuComponentMap = {
      "create-delivery-request": <CreateDeliveryRequest navigation={this.props.navigation} id={"create-delivery-request"} />,
      requestlist: <DeliveryRequestList navigation={this.props.navigation} id={"delivery-request-list"} />,
      "delivery-request": <DeliveryRequestHome navigation={this.props.navigation} id={"delivery-requests"} />,
      "stocklogs": <StockLogs navigation={this.props.navigation} id={"stockLogs"} />,
      "add-warehouse": <CreateWarehouse navigation={this.props.navigation} id={"create-warehouse"} />,
      "warehouse-list": <WarehouseList navigation={this.props.navigation} id={"wareHouseList"} />,
      warehouse: <WarehouseHome navigation={this.props.navigation} id={"seller-home-fulfilled-partner-wareHouse"} />,
      "partner-returns": <FulfilledByPartnerReturns navigation={this.props.navigation} id={"seller-home-fulfilled-partner-returns"} />,
      "returns-details": <ReturnItemDetails navigation={this.props.navigation} id={"seller-home-fulfilled-partner-returns-details"} />,
      "partner-orders": <SellerPartnerOrderManagement navigation={this.props.navigation} id={"seller-home-fulfilled-partner"} />,
      "partner-order-details": <SellerPartnerOrderDetails navigation={this.props.navigation} id={"seller-home-fulfilled-partner-order-details"} />,
      "byezzy-orders": <SellerFullfilledByEzzy navigation={this.props.navigation} id={"seller-home-fulfilled-byezzy"} />,
      "storage": <FBBStockManagementStorage navigation={this.props.navigation} id={"seller-home-fulfilled-byezzy-fbbStoreMgmt"} />,
      "create-stock-request": <CreateStockIntakeRequest navigation={this.props.navigation} id={"seller-home-fulfilled-byezzy-stock-req"} />,
      brandlist: <SellerBrandCatalogList navigation={this.props.navigation} id={"seller-home-brand-catalog-list"} />,
      "create-brand": <SellerBrandCatalog navigation={this.props.navigation} id={"seller-home-brand-catalog"} />,
      promocodeCoupons: <SellerCoupons navigation={this.props.navigation} id={"seller-home-brand-catalog"} />,
      "brand-catalog": <SellerAddBrandProduct navigation={this.props.navigation} id={"seller-home-brand-catalog"} />,
      "select-category": <SellerCategorize navigation={this.props.navigation} id={"seller-home-add-single-product"} />,
      "searchbrand": <SearchBrand navigation={this.props.navigation} id={"seller-search-brand"} />,
      products: <SellerAddSingle navigation={this.props.navigation} id={"seller-add-single-home"} />,
      "search-product": <SearchProductName navigation={this.props.navigation} id={"seller-search-product-name"} />,
      "add-product": <SellerAddNewProduct navigation={this.props.navigation} id={"seller-add-new-product"} />,
      "terms-conditions": <TermsAndCondition navigation={this.props.navigation} id={"terms-and-condition"} />,
      "privacy-policy": <PrivacyPolicy navigation={this.props.navigation} id={"privacy-policy"} />,
      "contact-us": <Contatus navigation={this.props.navigation} id={"contact-us"} language="english" />,
      "store": <Welcome navigation={this.props.navigation} id={"welcome"} />,
      "create-store": <CreateStorePage navigation={this.props.navigation} id={"create-store-page"} />,
      // "create-tabs": <CreateTabs navigation={this.props.navigation} id={"create-store-tabs"} />,
      "create-tabs": <StoreTabs navigation={this.props.navigation} id={"create-store-tabs"} />,
      "happiness-rating": <HappinessRating navigation={this.props.navigation} id={"create-store-tabs"} />,
      "bulkupload-select-category": <SellerCategorize navigation={this.props.navigation} id={"seller-home-bulk-upload-product"} />,
      "bulkupload-search-brand": <SearchBrand navigation={this.props.navigation} id={"seller-home-bulk-upload-product-1"} />,
      "bulkupload-download-template": <SellerBulkProductUpload navigation={this.props.navigation} id={"seller-home-bulk-upload-product-2"} />,
      "bulkupload-template": <SellerDragDropFile navigation={this.props.navigation} id={"seller-home-bulk-upload-product-3"} />,
      "deals" : <Promotions navigation={this.props.navigation} id={"seller-promotions"} />,
      "fees-disputes": <FBBFeesAndDisputes navigation={this.props.navigation} id={"seller-home-fbb-fees-and-disputes"} />,
      "damages-returns": <FBBDamagesAndReturns navigation={this.props.navigation} id={"seller-home-fbb-damages-and-returns"} />,
      "analytics" : <Analytics navigation={this.props.navigation} id={"analytics"} />
    };

    return menuComponentMap[activeMenu] || null;
  }

  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.mainWrapper}>
          <CustomizeDrawer variant="persistent" anchor="left" open={this.state.showMenuPanel}>
            <Grid container direction="column" style={webStyle.container} spacing={5}  alignItems="flex-start">
            
              <Grid item style={{ position: "relative" }}>
                <ClickableBox data-test-id="hideMenuRef" onClick={this.handleShowMenuPanel} style={webStyle.arrowBox}>
                  <ArrowBackIosIcon style={webStyle.arrow} />
                </ClickableBox>
                <Box data-test-id="logo" onClick={this.handleLogo}>
                <img src={byezzyLogo} style={webStyle.logoStyle} />
                </Box>
                <Box style={{...webStyle.mainBox, display: this.state.windowWidth > 600 ? 'block': 'none' }}></Box>
              </Grid>
                <SellerSidebar data-test-id="sidebar" menu={this.handleChangeMenu} navigation={this.props.navigation} id={"seller-sidebar"} activeMenu={this.state.activeMenu}
                handleWarehouseMenu={this.handleChangeWarehouseMenu} isPartnerCatalogActive={this.state.isProductManagementActive} isByEzzyActive={this.state.isFulfilledByByEzzyActive} isPartnerActive={this.state.isFulfilledByPartnerActive}/>
              </Grid>
          </CustomizeDrawer>
          <Box style={{...webStyle.right, marginLeft : this.setRightStyle()}}>
            <Box style={webStyle.navContainer}>
              <SellerNavigationBar navigation={this.props.navigation} id={"seller-navigation-bar"} profilePicture={this.state.profile_picture} />
            </Box>
            <Box style={webStyle.rightSide}>{this.componentToRender()}</Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle = {
  mainWrapper: {
    display: "flex",
    background: "#fff",
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    position: "relative" as const
  },
  container: { 
    width: "100%", 
    margin: 0, 
    position: "relative" ,
    alignItems: "flex-start",
    direction:"column"
  },
  gridPosition: { 
    position: "relative" as const
  },
  arrowBox: { 
    width: 31, 
    height: 31, 
    borderRadius: "100%", 
    position: "absolute" as const, 
    background: "#FF6008", 
    border: "6px solid #F2F3F4", 
    right: -150, 
    top: 36, 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center" 
  },
  arrow: { 
    color: "white", 
    marginLeft: "8px" 
  },
  mainBox: { 
    position: "absolute" as const, 
    width: "4px", 
    height: "35px", 
    top: 111, 
    right: -122, 
    borderRadius: "5px 0px 0px 5px",
    background: "#FF6008"
  },
  navContainer: { 
    width: "100%", 
    backgroundColor: "#F2F3F4", 
    display: "flex", 
    flexDirection: "column" 
  },
  rightSide: { 
    width: "100%", 
    overflow: "hidden", 
    backgroundColor: "#F2F3F4", 
    display: "flex", 
    flexDirection: "column" 
  },
  logoStyle: {
    width:'102px',
    height:'85px',
    margin: '-10px 0 0',
    cursor: 'pointer'
  },
  sideBar : {
    '@media (max-width:600px)' : {
      display: 'none'
    }
  },
  right: {
    width: "100%", 
    overflow: "auto" as const, 
    padding: "20px 20px 0px 20px", 
    backgroundColor: "#F2F3F4", 
    display: "flex",  
    flexDirection: "column" as const
  }
};
// Customizable Area End
