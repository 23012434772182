// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { createRef } from "react";
export const configJSON = require("../../src/config.js");

export interface TabComponent {
  [key: string]: JSX.Element; 
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    active: string,
    inactive: string,
    headerText: string,
    noScroll: string,
    box: string,
    topContainer: string,
    storeText: string,
    previewBox: string,
    img: string,
    line: string,
    scroll: string,
    horizontal: string,
    dialog: string,
    closeBox: string,
    close: string,
    page: string,
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeTab: string,
  activeScreen: string
  isPreviewOpen: boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class StoreTabsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createTabApiCallId: string = "";
  getMenuTabApiCallId: string = "";
  headerRef: React.RefObject<HTMLDivElement>;
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.ReceiveMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      activeTab: "header",
      activeScreen: "header",
      isPreviewOpen: false
    };

    this.headerRef = createRef();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area End

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.ReceiveMessage)) {
      const msg = message.getData(getName(MessageEnum.ChangeActiveTabStore)) || false;
      if (msg) {
        this.setState({ activeTab: msg.activeTab, activeScreen: msg.activeScreen });
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.activeTab !== this.state.activeTab) {
      if (this.headerRef.current) {
        this.headerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }

  handleTabChange = (value: string) => {
    this.setState({ activeTab: value })
  }

  handleTemplatePreview = () => {
    this.setState({isPreviewOpen: true})
  }

  handleClose = () => {
    this.setState({isPreviewOpen: false})
  }
  // Customizable Area End
}