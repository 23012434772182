import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

// Customizable Area Start
import { getStorageData } from "../../../../framework/src/Utilities";
import { IErrorData, ITermsAndPolicyData } from "./Interfaces";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  content: string[];
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class TermsAndConditionController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetTermsAndConditionCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.ReceiveMessage)];

    this.state = {
      content: ["..."],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || [];
      if (apiRequestCallId === this.apiGetTermsAndConditionCallId) {
        this.handleGetTermsAndConditionResponse(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  handleGetTermsAndConditionResponse = (responseJson: unknown) => {
    const successData = responseJson as ITermsAndPolicyData[];
    const errorData = responseJson as IErrorData;
    if ("errors" in errorData) {
      return this.setState({ content: ["..."] });
    }
    if (successData.length > 0) {
      const termsCondition = successData.filter((item) => item.page_title === "Terms and Conditions");
      if (termsCondition.length > 0) {
        return this.setState({ content: termsCondition[0].content.split(/\r?\n+/).filter((item) => item !== "") });
      }
    }
  };

  getTermsAndCondition = async () => {
    const headers = { token: await getStorageData("authToken"), "Content-Type": configJSON.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetTermsAndConditionCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiTermsAndPolicyEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount(): Promise<void> {
    this.getTermsAndCondition();
  }
  // Customizable Area End
}
