import React from "react";

// Customizable Area Start
import { BoxNoScroll, CustomizeBrandInput, theme } from "../../dashboard/src/CustomizedElementStyle";
import { ThemeProvider } from "@material-ui/core/styles";
import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, Grid, IconButton, Input, InputAdornment, RadioGroup, Typography } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { errorImage } from "../../bulkuploading/src/assets";
import CommonNav from "./CommonNav.web";
import CloseIcon from "@material-ui/icons/Close";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader2 } from "../../../components/src/Loader.web";
// Customizable Area End

import HomeContactUsController, { Props, initialValues } from "./HomeContactUsController";
import { file } from "./assets";

class HomeContactUs extends HomeContactUsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required").max(50, "Title must not exceed 50 characters"),
    email: Yup.string().email("Invalid email").required("Email is required").max(256, "Email must not exceed 256 characters"),
    description: Yup.string().required("Description is required").max(256, "Description must not exceed 256 characters"),
    image: Yup.mixed()
      // .required('Please upload an image.')
      .test("fileType", "Invalid file type. Only PNG, JPG, and JPEG are allowed.", (value) => {
        if (!value) return true; // Allow undefined values (not selected file)
        return value && ["image/png", "image/jpg", "image/jpeg"].includes(value.type);
      })
      .test("fileSize", "Please upload an image less than 5MB.", (value) => !value || value.size / (1024 * 1024) <= 5),
  });

  returnError = (touched: any, errors: any) => {
    if (touched && errors) {
      return <Typography style={webStyles.errorStyle}>{errors}</Typography>;
    } else {
      return null;
    }
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <ToastContainer />
        {this.state.isFetching ? <Loader2 loading={this.state.isFetching} /> : <></>}
        <ThemeProvider theme={theme}>
          <Box style={{ width: "100%", display: "flex", justifyContent: "center", overflowX: "hidden", overflowY: "auto" }}>
            <Container maxWidth={"lg"}>
              <Formik initialValues={initialValues} key={this.state.FormKey} data-form-key={this.state.FormKey} validationSchema={this.validationSchema} validateOnMount={true} validateOnChange={true} validateOnBlur={true} onSubmit={(values) => this.handleSubmit(values)} data-test-id="formik">
                {({ handleChange, errors, setFieldTouched, touched, setFieldValue, values, handleBlur }) => (
                  <Form translate={undefined} ref={this.formRef}>
                    <CommonNav navigation={this.props.navigation} id={"home-contact-us-nav"} />
                    <BoxNoScroll sx={{ overflow: "auto", paddingBottom: 20.1 }}>
                      <Box style={{ background: "white" }} sx={{ borderRadius: "8px", display: "flex", flexDirection: "column", padding: "30px", overflow: "auto" }}>
                        <Box sx={{ marginBottom: 10, width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }}>
                          <Box sx={{ fontSize: "27px", fontWeight: 1000, fontFamily: `"Lato", sans-serif` }}>Contact Us</Box>
                        </Box>
                        <Box sx={{ flexWrap: "wrap", display: "flex", width: "100%", flexDirection: "row" }}>
                          <FormControl component="fieldset">
                            <RadioGroup row aria-label="gender" name="radioGroup">
                              <Box sx={{ flexDirection: "row", display: "flex", flexWrap: "nowrap", alignItems: "center", marginRight: 33, padding: "30px 0px" }}>
                                <FormControlLabel
                                  data-test-id="feedback_ref"
                                  label=""
                                  value="feedback"
                                  checked={this.state.activeCheck === "feedback"}
                                  onChange={() => this.handleOnCheckChange("feedback")}
                                  control={<Checkbox icon={<FiberManualRecordIcon style={{ color: "white" }} />} checkedIcon={<FiberManualRecordIcon style={{ color: "#ff6008", fontSize: 27 }} />} name="checkedH" style={{ border: `1px solid ${this.getColorForFeedback()}`, width: "5px", height: "5px" }} />}
                                  style={{ marginRight: "10px", paddingLeft: "10px" }}
                                />
                                <Box sx={{ fontSize: 16, fontWeight: 500, fontFamily: `"Lato", sans-serif`, marginLeft: 3, color: "#33333A" }}>Feedback</Box>
                              </Box>
                              <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", alignItems: "center", marginRight: 33, padding: "30px 0px" }}>
                                <FormControlLabel
                                  data-test-id="query_ref"
                                  label=""
                                  value={"query"}
                                  checked={this.state.activeCheck === "query"}
                                  onChange={() => this.handleOnCheckChange("query")}
                                  control={<Checkbox icon={<FiberManualRecordIcon style={{ color: "white" }} />} checkedIcon={<FiberManualRecordIcon style={{ color: "#ff6008", fontSize: 27 }} />} name="checkedH" style={{ border: `1px solid ${this.getColorForQuery()}`, width: "5px", height: "5px" }} />}
                                  style={{ marginRight: "10px", paddingLeft: "10px" }}
                                />
                                <Box sx={{ fontSize: 16, fontWeight: 500, fontFamily: `"Lato", sans-serif`, marginLeft: 3, color: "#33333A" }}>Query</Box>
                              </Box>
                              <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", alignItems: "center", marginRight: 33, padding: "30px 0px" }}>
                                <FormControlLabel
                                  data-test-id="complaint_ref"
                                  label=""
                                  value={"complaint"}
                                  checked={this.state.activeCheck === "complaint"}
                                  onChange={() => this.handleOnCheckChange("complaint")}
                                  control={<Checkbox icon={<FiberManualRecordIcon style={{ color: "white" }} />} checkedIcon={<FiberManualRecordIcon style={{ color: "#ff6008", fontSize: 27 }} />} name="checkedH" style={{ border: `1px solid ${this.getColorForComplaint()}`, width: "5px", height: "5px" }} />}
                                  style={{ marginRight: "10px", paddingLeft: "10px" }}
                                />
                                <Box sx={{ fontSize: 16, fontWeight: 500, fontFamily: `"Lato", sans-serif`, marginLeft: 3, color: "#33333A" }}>Complaint</Box>
                              </Box>
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Grid container spacing={4} xs={12} sm={6}>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box sx={webStyles.label}>{"Title"}</Box>
                            <CustomizeBrandInput
                              data-test-id="title_ref"
                              name="title"
                              placeholder="Enter title"
                              onChange={(e) => {
                                handleChange(e);
                                setFieldTouched("title", true, false);
                              }}
                              onBlur={handleBlur}
                              id="outlined-start-adornment"
                              fullWidth
                              InputProps={{
                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                              }}
                            />
                            {this.returnError(touched.title, errors.title)}
                          </Grid>
                          <Grid item lg={12} sm={12} xs={12}>
                            <Box sx={webStyles.label}>{"Description"}</Box>
                            <CustomizeBrandInput
                              data-test-id="description_ref"
                              multiline
                              placeholder="Type here..."
                              minRows={6}
                              name="description"
                              onChange={(e) => {
                                handleChange(e);
                                setFieldTouched("description", true, false);
                              }}
                              onBlur={handleBlur}
                              id="outlined-start-adornment"
                              fullWidth
                              InputProps={{
                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                              }}
                            />
                            {this.returnError(touched.description, errors.description)}
                          </Grid>
                          <Grid item lg={12} sm={12} xs={12}>
                            <Box sx={webStyles.label}>{"Email"}</Box>
                            <CustomizeBrandInput
                              data-test-id="email_ref"
                              name="email"
                              placeholder="Enter email"
                              onChange={(e) => {
                                handleChange(e);
                                setFieldTouched("email", true, false);
                              }}
                              onBlur={handleBlur}
                              id="outlined-start-adornment"
                              fullWidth
                              InputProps={{
                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                              }}
                            />
                            {this.returnError(touched.email, errors.email)}
                          </Grid>
                          <Grid item lg={12} sm={12} xs={12}>
                            <Box sx={webStyles.label}>Upload</Box>
                            <Input
                              data-test-id="fileInput"
                              value=""
                              type="file"
                              name="image"
                              style={{
                                display: "none",
                              }}
                              inputProps={{
                                "aria-label": "upload documents",
                                accept: ".png, .jpg, .jpeg",
                                ref: this.inputRef,
                              }}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("image", event.currentTarget?.files?.[0]);
                                setFieldTouched("image", true, false);
                              }}
                            />
                            <label htmlFor="upload-button">
                              <Button
                                fullWidth
                                data-test-id="fileButtonRef"
                                variant="outlined"
                                onClick={() => this.handleFileOpenInput()}
                                style={{
                                  borderRadius: "5px",
                                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23FBBC98FF' stroke-width='3' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                                  width: "100%",
                                  height: 167,
                                  padding: "20px 0px",
                                  border: "none",
                                  margin: "10px 0px",
                                  textTransform: "none",
                                  fontFamily: `"Lato", sans-serif`,
                                }}>
                                <Box>
                                  <img src={file} />
                                  <Box sx={{ fontWeight: 400, fontSize: "27px", fontFamily: `"Lato", sans-serif` }}>Upload Document</Box>
                                  <Box sx={{ fontFamily: `"Lato", sans-serif`, fontSize: 16, color: "#9a9a9a" }}>{"Maximum file size- 5mb"}</Box>
                                </Box>
                              </Button>
                            </label>
                            {this.returnError(touched.image, errors.image)}
                          </Grid>
                          <Grid item lg={12} sm={12} xs={12}>
                            <Grid container direction="column" spacing={2}>
                              {values?.image && (
                                <Grid item sm={12} xs={12}>
                                  <Box style={{ justifyContent: "space-between", display: "flex", alignItems: "center", background: "#f5f5f5", borderRadius: 5, flexWrap: "nowrap", padding: "0px 0px 0px 20px" }}>
                                    <img src={URL.createObjectURL(values?.image)} alt={`Uploaded preview`} style={{ height: "100%", width: "70px" }} />

                                    <Box style={{ fontFamily: `"Lato", sans-serif` }}>{values?.image.name}</Box>
                                    <IconButton onClick={() => setFieldValue("image", null)} data-test-id="removeButtonRef">
                                      <CloseIcon />
                                    </IconButton>
                                  </Box>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Box sx={{ width: "100%", height: 70, minHeight: 70, display: "flex", alignItems: "center" }}>
                          {this.state.errMsg.map((msg, index) => {
                            return (
                              <Box data-test-id="errorMessageImagerRef" sx={{ display: "flex", flexWrap: "no-wrap", paddingTop: "10px", flexDirection: "row", alignItems: "center" }} key={index}>
                                <Box sx={{ marginRight: "11.1px" }}>
                                  <img src={errorImage} />
                                </Box>
                                <Box>
                                  <Typography variant="body2" style={{ color: "#D74D4D" }}>
                                    {msg}
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          })}
                          {this.state.successMessage.map((msg, index) => {
                            return (
                              <Box key={index} data-test-id="successMessageRef">
                                <Typography style={{ color: "rgb(61 168 46)" }} variant="body2">
                                  {msg}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Box>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                          <Button
                            disabled={this.state.isFetching}
                            type="submit"
                            data-test-id="submit_ref"
                            style={{
                              fontWeight: 700,
                              textTransform: "none",
                              fontFamily: "'Montserrat Variable', sans-serif",
                              fontSize: 16,
                              background: "#FF6008",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              borderRadius: "8px",
                              padding: "15px 120px",
                            }}>
                            Submit
                          </Button>
                        </Box>
                      </Box>
                    </BoxNoScroll>
                  </Form>
                )}
              </Formik>
            </Container>
          </Box>
        </ThemeProvider>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const webStyles = {
  label: {
    color: "rgb(111, 111, 111)",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 600,
    fontSize: 13,
    marginBottom: 8,
  },
  errorStyle: {
    color: "red",
    textAlign: "left" as const,
    fontSize: "12px",
    marginTop: "5px",
    fontFamily: `"Lato", sans-serif`,
  },
};
export default HomeContactUs;
// Customizable Area End
