Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid";

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";

exports.apiSubmitContactEndpoint = "bx_block_support/supports";
exports.postMethod = "POST";
exports.getMethod = "GET";
exports.apiStaticPagesEndpoint = "bx_block_support/static_pages";

exports.apiContactUsEndPoint = "bx_block_contact_us/contacts";
exports.formContentType = 'multipart/form-data';
exports.backToHomeText = "Back to Home";
exports.staticPages = ["about-us", "testimonials", "contact", "privacy-policy","support", "terms-conditions", "shipping-returns"]
// Customizable Area End