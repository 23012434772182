import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { createRef } from "react";
import { toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";
import { IWarehouseData } from "./WarehouseListController.web";

// Customizable Area Start
export const configJSON = require("../src/config.js")

export interface WarehouseInfo {
  data: IWarehouseData,
  errors: {
    [keyValue: string] : string
  }[]
}

export interface IFormValues {
  name: string,
  processingDays: string,
  addressOne: string,
  addressTwo: string,
  contactPerson: string,
  contactNumber: string,
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    tabText: string,
    wrapper: string,
    headerWrapper: string,
    headerText: string,
    fieldWrapper: string,
    fieldContainer: string,
    topMargin: string,
    buttonContainer: string,
    backButton: string,
    submitButton: string,
    errorStyle: string,
    boxscroll: string
  },
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  warehouseId: string,
  name: string,
  processingDays: string,
  addressOne: string,
  addressTwo: string,
  contactPerson: string,
  contactNumber: string
  loading: boolean,
  token: string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class CreateWarehouseController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  formRef: React.RefObject<HTMLFormElement>;
  apiPostCreateWarehouse: string = "";
  apiPutUpdateWarehouse: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.ReceiveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.SessionResponseMessage),];

    this.state = {
      warehouseId: '',
      name: '',
      processingDays: '',
      addressOne: '',
      addressTwo: '',
      contactPerson: '',
      contactNumber: '',
      token: '',
      loading: false
    };

    this.formRef = createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));


    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {

      const menuPanel = message.getData(getName(MessageEnum.WarehouseData)) || false;
      if (menuPanel) {
        const warehouseData = JSON.parse(menuPanel);
        this.setState({
          warehouseId: warehouseData?.id,
          name: warehouseData?.warehouse_name,
          processingDays: warehouseData?.processing_days,
          addressOne: warehouseData?.warehouse_address_1,
          addressTwo: warehouseData?.warehouse_address_2,
          contactPerson: warehouseData?.contact_person,
          contactNumber: this.setContactNumber(warehouseData?.contact_number),
        })
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiPostCreateWarehouse) {
        this.handleCreateWarehouseResponse(responseJson, errorResponse)
      }
      if (apiRequestCallId === this.apiPutUpdateWarehouse) {
        this.handleUpdateWarehouseResponse(responseJson, errorResponse)
      }


    }

    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    await this.getToken();
  }

  getToken = async () => {
    const token = await getStorageData("authToken");
    this.setState({ token })
  }

  setContactNumber = (value:string) => {
    const number = value;

        if (number.indexOf('-') === -1) {
            const countryCode = number.slice(0, 3);
            const regionCode = number.slice(3, 5);
            const phoneNumber = number.slice(5);
    
            const formattedContact = `${countryCode}-${regionCode}-${phoneNumber}`;
            return formattedContact
        } 
        else return number
  }

  handleContactNumberChange = (event:React.ChangeEvent<HTMLInputElement>,setFieldValue:(field: string, value: string) => void) => {
      const number = event.target.value;
      const formattedNumber = this.handleContactNumber(number);
      const cursorPosition:number = event.target.selectionStart as number;
      const inputElement = event.target
      if (number === "") {
          setFieldValue('contactNumber', "");
      } else {
        
          setFieldValue('contactNumber', formattedNumber.length <= 14 ? formattedNumber : formattedNumber.slice(0, 14));
          setTimeout(() => {
            const addedChars = formattedNumber.length - number.length;
            const newCursorPosition = cursorPosition + addedChars;
        
            if (inputElement.setSelectionRange) {
                inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
            }
        }, 0);
      }
  }

  handleContactNumber = (value:string) => {
    const numValue = value.replace(/\D/g, "");
    const truncatedValue = numValue.slice(0, 12);
    let formattedValue = truncatedValue.slice(0, 3) + (truncatedValue.length > 3 ? "-" + truncatedValue.slice(3, 5) : "") + (truncatedValue.length > 5 ? "-" + truncatedValue.slice(5) : "");
    return formattedValue;
}

  handleCreateWarehouseResponse = (responseJson: WarehouseInfo, errorResponse: WarehouseInfo) => {
    this.setState({ loading: false })
    if ("data" in responseJson) {
      toast.success("Warehouse created successfully!", {
        progress: undefined,
        autoClose: 3000, // milliseconds
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        pauseOnHover: true,
      });

      setTimeout(() => {
        this.props.navigation.navigate(configJSON.sellerHomeText, {path: configJSON.warehouseListPath})
      }, 2000);
    }
    if ("errors" in responseJson || errorResponse) {
      const keyValue = Object.keys(responseJson.errors[0])[0];
      toast.error(responseJson?.errors?.[0]?.[keyValue] ? `${JSON.stringify(responseJson.errors?.[0]?.[keyValue])}` : "Failed to create warehouse. Please try again later!", {
        draggable: true,
        progress: undefined,
        autoClose: 3000, // milliseconds
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: false,
        position: "top-right",
      });
    }
  };

  handleUpdateWarehouseResponse = (responseJson: WarehouseInfo, errorResponse: WarehouseInfo) => {
    this.setState({ loading : false })
    if ("data" in responseJson) {
      toast.success("Warehouse updated successfully!", {
        closeOnClick: true,
        hideProgressBar: false,
        position: "top-right",
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        autoClose: 3000, // milliseconds
      });

      setTimeout(() => {
        this.props.navigation.navigate(configJSON.sellerHomeText, {path: configJSON.warehouseListPath})
      }, 2000);
    }
    if ("errors" in responseJson || errorResponse) {
      const keyValue = Object.keys(responseJson.errors[0])[0];
      toast.error( responseJson?.errors?.[0]?.[keyValue] ? `${JSON.stringify(responseJson.errors?.[0]?.[keyValue])}` : "Failed to update warehouse. Please try again later!", {
        draggable: true,
          position: "top-right",
        autoClose: 3000, // milliseconds
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        hideProgressBar: false,
      });
    }
  };

  handleBackBtn = () => {
    this.props.navigation.navigate(configJSON.sellerHomeText,  {path: configJSON.warehouseListPath})
  }

  submitForm = (values: IFormValues) => {
    if (this.state.warehouseId) {
      this.updateWarehouse(values)
    } else {
      this.createWarehouse(values)
    }
  }

  createWarehouse = (values: IFormValues) => {
    const { name, processingDays, addressOne, addressTwo, contactPerson, contactNumber } = values;
    this.formRef.current?.scrollIntoView({ behavior: 'smooth' });
    this.setState({ loading: true })
    const headers = { token: this.state.token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    const formData = new FormData();
    formData.append("warehouse[warehouse_type]", "1");
    formData.append("warehouse[warehouse_name]", name);
    formData.append("warehouse[warehouse_address_1]", addressOne); 
    formData.append("warehouse[warehouse_address_2]", addressTwo);
    formData.append("warehouse[contact_person]", contactPerson);
    formData.append("warehouse[contact_number]", contactNumber);
    formData.append("warehouse[processing_days]", processingDays);
    this.apiPostCreateWarehouse = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sellerWarehouseApiEndPoint.create);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateWarehouse = (values: IFormValues) => {
    const { name, processingDays, addressOne, addressTwo, contactPerson, contactNumber } = values;
    this.formRef.current?.scrollIntoView({ behavior: 'smooth' });
    this.setState({ loading: true })
    const headers = { token: this.state.token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    const formData = new FormData();
    formData.append("warehouse[id]", this.state.warehouseId);
    formData.append("warehouse[warehouse_type]", "1");
    formData.append("warehouse[warehouse_name]", name);
    formData.append("warehouse[warehouse_address_2]", addressTwo);
    formData.append("warehouse[warehouse_address_1]", addressOne);
    formData.append("warehouse[contact_person]", contactPerson);
    formData.append("warehouse[contact_number]", contactNumber);
    formData.append("warehouse[processing_days]", processingDays);
    this.apiPutUpdateWarehouse = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sellerWarehouseApiEndPoint.update);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePUT);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
