import React from "react";

// Customizable Area Start
import "typeface-lato";
import {
  Container,
  Box,
  Grid,
  Typography,
  Button,
  Hidden
} from "@material-ui/core";
import {
  responsiveFontSizes,
  ThemeProvider,
  createTheme
} from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    h1: {
      fontSize: "4.2rem",
      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
      margin: 0,
      padding: 0,
      textAlign: "center"
    },
    body1: {
      fontSize: "1rem",
      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1460
    }
  },
  overrides: {
    MuiContainer: {
      root: {
        fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
      }
    }
  }
});
import { ToastContainer } from "react-toastify";

interface IBanner {
  header: string;
  subheader1: string;
  subheader2: string;
  buttonText: string;
  imageBackground: string | undefined;
}
// Customizable Area End

import LandingPageController, {
  Props,
  configJSON,
  manLaptopImg,
  hourGlassManImg
} from "./LandingPageController";
import { byezzyLogoImg } from "../../bulkuploading/src/assets";

export default class SellerLandingPageWeb extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  Banner = ({ header, subheader1, subheader2, buttonText, imageBackground }: IBanner) => {
    return (
      <Grid container spacing={0} direction="row">
        <ToastContainer />

        <Hidden mdDown>
          <Grid item xl={5} lg={5} sm={false} xs={false}>
            <Box
              style={webStyle.leftBox}
            >
              <img src={imageBackground} width={"90%"} />
            </Box>
          </Grid>
        </Hidden>
        <Grid item xl={7} lg={7} sm={12} xs={12}>
          <Box
            style={webStyle.rightBox}
          >
            <Box whiteSpace="normal">
              <Typography
                variant="h1"
                style={webStyle.header}
              >
                {header}
              </Typography>
            </Box>
            <Box style={webStyle.subHeaderBox}>
              <Typography
                variant="body1"
                style={webStyle.subHeaderOne}
              >
                {subheader1}
              </Typography>
            </Box>
            <Box style={webStyle.subHeaderBoxTwo}>
              <Typography
                variant="body1"
                style={webStyle.subHeaderOne}
              >
                {subheader2}
              </Typography>
            </Box>
            <Button
              style={webStyle.button}
              onClick={this.handleBannerOnClickAction}
              data-test-id="goToBulkFormsButton"
            >
              {buttonText}
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider
        theme={responsiveFontSizes(theme, {
          breakpoints: ["xs", "sm"],
          factor: 2
        })}
      >
        <Box
        sx={webStyle.mainWrapper}
        data-test-id="mainBox"
      >
        <Hidden mdDown>
          <Box
            sx={{ ...webStyle.circleShape, ...webStyle.largeCircle }}
            style={{ ...webStyle.orangeBackground }}
          />
        </Hidden>
        <Hidden lgUp xsDown>
          <Box
            sx={{ ...webStyle.circleShape, ...webStyle.mediumCircle }}
            style={{ ...webStyle.orangeBackground }}
          />
        </Hidden>
        <Hidden smUp>
          <Box
            sx={{ ...webStyle.circleShape, ...webStyle.smallCircle }}
            style={{ ...webStyle.orangeBackground }}
          />
        </Hidden>
        <Container maxWidth={"xl"} style={webStyle.relative}>
          <Box sx={webStyle.logoIcon}>
            <img src={byezzyLogoImg} style={webStyle.logoIcon} />
          </Box>
          {this.state.showStartRegistrationBanner &&
            this.Banner({
              header: configJSON.welcomeHeader,
              subheader1: configJSON.completeRegistrationProcessText,
              subheader2: configJSON.startSellingText,
              buttonText: configJSON.startRegistration,
              imageBackground: manLaptopImg
            })}
          {this.state.showWelcomeBackBanner &&
            this.Banner({
              header: configJSON.welcomeBackText,
              subheader1: configJSON.documentVerificationText,
              subheader2: "",
              buttonText: configJSON.goToHomepageTxt,
              imageBackground: hourGlassManImg
            })}
          {this.state.showCongratsBanner &&
            this.Banner({
              header: configJSON.congratulationsText,
              subheader1: configJSON.yourDocumentVerifiedText,
              subheader2: configJSON.sellingWithByezzyText,
              buttonText: configJSON.continueText,
              imageBackground: manLaptopImg
            })}
        </Container>
      </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    background: "#fff",
    width: "100%",
    height: "100vh",
    overflow: "hidden" as const, 
    position: 'relative' as const
  },
  logoIcon: {
    width:'102px',
    height: '85px'
  },
  orangeBackground: {
    background: "#ff6008"
  },
  circleShape: {
    position: "absolute",
    right: 0,
    borderRadius: "50% 0px 0px 50%"
  },
  largeCircle: {
    width: "56%",
    height: "150vh",
    top: "-25%"
  },
  mediumCircle: {
    width: "90%",
    height: "150vh",
    top: "-25%"
  },
  smallCircle: {
    width: "130%",
    height: "100vh",
    bottom: 0,
    borderRadius: "100% 0 0px 0px"
  },
  leftBox: { 
    width: "100%", 
    height: "100vh", 
    display: "flex", 
    alignItems: "center" ,
    background: "none"
  },
  rightBox: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
    background: "none"
  },
  header: { 
    color: "white", 
    fontWeight: 700 
  },
  subHeaderBox: { 
    textAlign: "center" as const, 
    marginTop: "20px" 
  },
  subHeaderOne: { 
    color: "white", 
    fontWeight: 400 
  },
  subHeaderBoxTwo: { 
    textAlign: "center" as const, 
    marginBottom: "35px" 
  },
  button: {
    padding: "10px 50px",
    background: "white",
    textTransform: "none" as const,
    fontSize: 16,
    fontWeight: 600,
    fontFamily: `"Helvetica", "Arial", sans-serif`,
    borderRadius: "5px",
    width: "fit-content",
  },
  relative: {
    position: 'relative' as const
  }
};
// Customizable Area End
