import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../../framework/src/Utilities";
import { IBarcodeData } from "../SellerHomeDashboardComponents/Interfaces";
import { v4 as uuidv4 } from "uuid";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  variant:boolean
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  bar_code: string;
  barcode_id: string;
  successCreatingBarcode: boolean;
  errorMsg: string[];
  barcodeErr: string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class BarcodeController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiCreateBarcodeCallId: string = "";
  apiUpdateBarcodeCallId: string = "";
  getProductApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      bar_code: "",
      barcode_id: "",
      successCreatingBarcode: false,
      errorMsg: [],
      barcodeErr: ""
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area End

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.apiCreateBarcodeCallId) {
        this.handleCreateBarcodeResponse(responseJson);
      }
      if (apiRequestCallId === this.apiUpdateBarcodeCallId) {
        this.handleCreateBarcodeResponse(responseJson);
      }
      if (apiRequestCallId === this.getProductApiCallId) {
        this.handleProductInfoDetails(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  handleProductInfoDetails = async (responseJson: any) => {
    if (responseJson) {
      const barcodeExist = responseJson.data.attributes?.barcode;
      if (barcodeExist?.id) {
        this.setState({ bar_code: barcodeExist?.bar_code, barcode_id: barcodeExist?.id });
      } else {
        this.setState({ bar_code: "", barcode_id: "" });
      }
    }
  };

  handleCreateBarcodeResponse = (responseJson: unknown) => {
    const successData = responseJson as IBarcodeData;
    if ("id" in successData) {
      setStorageData("barcode_id", successData.id);
      setStorageData("bar_code_info", this.state.bar_code);
      const msg: Message = new Message(getName(MessageEnum.SendMessage));
      msg.addData(getName(MessageEnum.ChangeActivePanelProductDetails), { activePanel: "offer", activeScreen: "offer" ,isVariant:this.props.variant });
      this.send(msg);
      return this.setState({ successCreatingBarcode: true });
    }
    this.setState({ errorMsg: ["Something went wrong. Please try again later."] });
  };

  handleBarcodeOnChange = (value: string) => {
    const barCodeRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{1,40}$/;
    this.setState({ bar_code: value, successCreatingBarcode: false, errorMsg: [] });
    if (!barCodeRegex.test(value)) {
      this.setState({ barcodeErr: configJSON.barCodeErrMsg });
    } else this.setState({ barcodeErr: "" });
  };

  handleAddNewBarcode = () => {
    this.setState({ successCreatingBarcode: false, errorMsg: [], bar_code: this.genereteRandomBarcode(), barcodeErr: '' });
  };

  handleOnSubmit = () => {
    if (!this.state.bar_code) {
      this.setState({ errorMsg: ['Generate barcode by pressing "Add new" button.'] });
      return;
    }
    if (this.state.bar_code && this.state.barcodeErr) {
      return;
    } else this.createBarcode();
  };

  genereteRandomBarcode = (): string => {
    // Generate a UUID
    const uuid = uuidv4();
    // Extract only letters and numbers, and convert to uppercase
    const filteredChars = uuid.replace(/[^a-zA-Z0-9]/g, "");
    // Trim to the desired length
    const trimmedUUID = filteredChars.substring(0, 40);
    return trimmedUUID;
  };

  createBarcode = async () => {
    const headers = { "Content-Type": configJSON.contentType, token: await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    if (this.state.barcode_id) {
      this.apiUpdateBarcodeCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateOfferEndpoint + (await getStorageData("catalog_id")) + "/barcodes/" + this.state.barcode_id);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
    } else {
      this.apiCreateBarcodeCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateOfferEndpoint + (await getStorageData("catalog_id")) + "/barcodes");
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    }

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        barcode: {
          bar_code: this.state.bar_code
        }
      })
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getProductInfo = async (id: string) => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: await getStorageData("authToken")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/${id}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleProductCall = (catalogId: any, newProductId: any) => {
    if (catalogId) {
      this.getProductInfo(catalogId);
    } else this.getProductInfo(newProductId);
  };

  async componentDidMount(): Promise<void> {
    const newProductId = await getStorageData("newProductId");
    const catalogId = await getStorageData("catalog_id");
    this.handleProductCall(catalogId, newProductId);
  }
  // Customizable Area End
}
