import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("../src/config.js")
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start

    // Customizable Area End
  }
  interface S {
    // Customizable Area Start
    authToken: string;
    data: any[],
    selectedProduct: string
    stockValue: string,
    quantity: string,
    productLength: string,
    productWidth: string,
    productHeight: string,
    productWeight: string
    // Customizable Area End
  }
  interface SS {
    id: any;
  }
  
  export default class CreateStockIntakeReqController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End
  
    // Customizable Area Start
    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);
  
      this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];
  
      this.state = {
        authToken: "",
        data: [],
        selectedProduct: '',
        stockValue : '',
        quantity: '',
        productLength: '',
        productWidth: '',
        productHeight: '',
        productWeight: ''
      };
  
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
      // Customizable Area End
    }
  
    // Customizable Area Start

    data = [
        {
            id:'1',
            attributes:{
                name: 'Product One'
            }
        },
        {
            id:'2',
            attributes:{
                name: 'Product two'
            }
        },
        {
            id:'3',
            attributes:{
                name: 'Product three'
            }
        },
        {
            id:'4',
            attributes:{
                name: 'Product four'
            }
        },
        {
            id:'5',
            attributes:{
                name: 'Product five'
            }
        }
    ]

    handleSelectProduct = (event:React.ChangeEvent<{name?:string,value: any}>) => {
        this.setState({ selectedProduct : event.target?.value })
    }

    handleStockValue = (event:React.ChangeEvent<{name?:string,value: any}>) => {
        this.setState( {stockValue : event.target?.value})
    }

    handleQuantity = (event:React.ChangeEvent<{name?:string,value: any}>) => {
        this.setState( {quantity : event.target?.value})
    }

    handleProductLength = (event:React.ChangeEvent<{name?:string,value: any}>) => {
        this.setState( {productLength : event.target?.value})
    }

    handleProductWidth = (event:React.ChangeEvent<{name?:string,value: any}>) => {
        this.setState( {productWidth : event.target?.value})
    }

    handleProductHeight = (event:React.ChangeEvent<{name?:string,value: any}>) => {
        this.setState( {productHeight : event.target?.value})
    }

    handleProductWeight = (event:React.ChangeEvent<{name?:string,value: any}>) => {
        this.setState( {productWeight : event.target?.value})
    }
  
    handleGetToken = async () => {
      const authToken = await getStorageData("authToken");
      this.setState({ authToken });
    };
  
    async componentDidMount(): Promise<void> {
      this.handleGetToken();
      this.setState({data: this.data})
    }
    // Customizable Area End
  }