// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { IPrivacyNotice } from "../../termsconditions/src/PrivacyNoticeController.web";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { createRef } from "react";
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  featuresContent: IPrivacyNotice
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class FeaturesLandingpageController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiStaticContentCallId: string = "";
  registerRef: React.RefObject<HTMLDivElement>;
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.ReceiveMessage), getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      featuresContent: {
        title: '',
        id:'',
        content: ''
      }
    };

    this.registerRef = createRef()
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) ;
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId === this.apiStaticContentCallId) {
        this.handleStaticContentResponse(responseJson);
      }
    }
  }

  handleStaticContentResponse = (responseJson:IPrivacyNotice[]) => {
    if(responseJson.length) {
      this.getFeaturesContent(responseJson)
    }
  }

  getFeaturesContent = (responseJson: IPrivacyNotice[]) => {
    const featuresContent = responseJson.filter((item:IPrivacyNotice) => {
      if(item.title === "Features") {
          return item
      }
    })
      if(featuresContent.length) {
        this.setState({featuresContent : featuresContent[0]}, () => console.log("Feature", this.state.featuresContent))
      }
  }

  async componentDidMount(): Promise<void> {
    this.getStaticData()
  }

  getStaticData = async() => {
    const headers = { token: await getStorageData("authToken"), "Content-Type": configJSON.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiStaticContentCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.staticApiEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleBecomeAPartner = () => {
    this.props.navigation.navigate("FeaturesHeader")
  }
  // Customizable Area End
}
