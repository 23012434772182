import React from "react";

// Customizable Area Start
import OTPInputAuthWeb from "../../otp-input-confirmation/src/OTPInputAuth.web";
import SellerLoginEmail from "../../mobile-account-login/src/SellerLoginPage.web";
import "typeface-lato";
import { Box, Grid, Hidden, Button, TextField, FormControl, InputLabel, InputAdornment, IconButton, Input, FormControlLabel, Typography, Link } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import Error from "@material-ui/icons/Error";
import { theme, GreenCheckbox } from "./CustomElementStyling";
import { ClickableBox, VisibilityOffSvg } from "../../mobile-account-login/src/CustomElementStyling";
import SellerForgotPassword from "../../forgot-password/src/SellerForgotPassword.web";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import { Loader3 } from "../../../components/src/Loader.web";
import DOMPurify from "dompurify";
// Customizable Area End

import EmailAccountRegistrationController, { Props, circleImg, objectsImg, configJSON } from "./EmailAccountRegistrationController";

export default class SellerAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  returnErrorMessage = (touched: boolean | undefined, errors: string | undefined) => {
    if (touched && errors) {
      return <Typography style={webStyle.errorStyle}>{errors}</Typography>;
    } else {
      return null;
    }
  };

  validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(configJSON.firstNameRequired)
      .matches(configJSON.nameRegex, configJSON.firstNameValidationMessage)
      .max(50, configJSON.firstNameValidationErr),
    lastName: Yup.string()
      .required(configJSON.lastNameRequired)
      .matches(configJSON.nameRegex, configJSON.lastNameValidationMessage)
      .max(50, configJSON.lastNameValidationErr),
    companyName: Yup.string()
      .required(configJSON.companyNameRequired)
      .matches(configJSON.companyNameRegex, configJSON.companyNameValidationMessage)
      .max(50, configJSON.companyNameValidationErr),
    emailAddress: Yup.string()
      .email(configJSON.invalidEmailText)
      .matches(configJSON.emailRegex, configJSON.invalidEmailText)
      .required(configJSON.emailReqText)
      .max(256, configJSON.emailValidationErr),

    phoneNumber: Yup.string()
      .matches(configJSON.phoneFormatRegex, configJSON.phoneNumberErrFormat)
      .required(configJSON.phoneNumberReqText),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/,
        "Password must have at least one lowercase letter, one uppercase letter, one digit, one special character (@$!%*?&), and no spaces. It should be between 8 and 32 characters long."
      )
      .min(8, configJSON.minPasswordText),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords don't match")
      .required(configJSON.confirmPassReq),
    acceptTerms: Yup.boolean()
      .oneOf([true], configJSON.acceptTermsConditionsText)
      .required(configJSON.acceptTermsConditionsText)
  });

  showPassword = (enable: boolean) => {
    if (enable) {
      return <Visibility className="rotateIcon" />;
    } else {
      return <VisibilityOffSvg />;
    }
  };

  showTermError = (touch: boolean | undefined, errors: string | undefined) => {
    if (touch && errors) {
      return (
        <Box
          sx={webStyle.termErrBox}
        >
          <Box sx={webStyle.marginOne}>
            <Error color="secondary" />
          </Box>
          <Box sx={webStyle.errText}>{errors}</Box>
        </Box>
      );
    } else {
      return <></>;
    }
  };

  hasError = (touched: boolean | undefined, errors: string | undefined) => {
    return touched && Boolean(errors);
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.state.disableSignInButton ? <Loader3 loading={this.state.disableSignInButton} /> : <></>}
        <ToastContainer />
        <OTPInputAuthWeb navigation={this.props.navigation} id={"otp-input-confirmation"} />
        <SellerForgotPassword navigation={this.props.navigation} id={"forgot-password-seller"} />
        <SellerLoginEmail navigation={this.props.navigation} id={"seller-login-email"} />
        <ThemeProvider theme={theme}>
          <Box sx={webStyle.mainWrapper}>
            <Box style={webStyle.outerBox}>
              <Hidden smDown>
                <Box sx={webStyle.imgBoxOne}>
                  <img src={objectsImg} width={"90%"} />
                </Box>
              </Hidden>
              <Hidden smDown>
                <Box sx={webStyle.imgBoxTwo}>
                  <img src={circleImg} />
                </Box>
              </Hidden>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={0} style={webStyle.gridContainerOne}>
                <Grid item lg={6} md={6} sm={12} xs={12} style={webStyle.noBorder}>
                  <Grid container spacing={3} style={webStyle.marginTwo}>
                  <Grid item lg={12} sm={12} xs={12}>
                    {this.state.welcomeContent &&
                    <div data-test-id="welcomeContent" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.welcomeContent.content)}}>
                    </div>
                    }
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12} style={{ border: "none" }}>
                  <Grid container spacing={0}>
                    <Grid item lg={12} sm={12} xs={12} style={{ height: "34px" }} />
                    <Grid container spacing={0}>
                      <Grid item lg={2} sm={false} xs={false} />
                      <Grid item lg={10} sm={12} xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            borderRadius: "10px"
                          }}
                          style={{ background: "white" }}
                        >
                          <Box
                            sx={{
                              maxWidth: "600px",
                              minHeight: "500px",
                              width: "100%",
                              padding: "40px"
                            }}
                          >
                            <Grid container spacing={0} direction="column">
                              <Grid item>
                                <Box sx={{ fontSize: 18, fontWeight: 600 }}>Sign Up</Box>
                              </Grid>
                              <Grid item>
                                <Box
                                  sx={{
                                    flexDirection: "row",
                                    display: "flex",
                                    marginTop: "15px",
                                    fontSize: 14
                                  }}
                                >
                                  <Box style={{ fontWeight: "bold", fontFamily: "Lato", color: "#757575" }}>Already have an account?</Box>
                                  <ClickableBox
                                    data-test-id="sellerSignIn"
                                    sx={{
                                      marginLeft: "5px",
                                      color: "rgba(253, 97, 12, 1)",
                                      fontWeight: "550"
                                    }}
                                    onClick={this.handleOpenSellerLoginEmail}
                                  >
                                    Sign In
                                  </ClickableBox>
                                </Box>
                              </Grid>
                              <Formik
                                innerRef={this.formikRef}
                                initialValues={{
                                  firstName: this.state.firstName,
                                  lastName: this.state.lastName,
                                  companyName: this.state.companyName,
                                  emailAddress: this.state.emailAddress,
                                  phoneNumber: this.state.phoneNumber,
                                  password: this.state.password,
                                  confirmPassword: "",
                                  acceptTerms: this.state.termsAndCondition
                                }}
                                enableReinitialize={true}
                                validationSchema={this.validationSchema}
                                validateOnMount={true}
                                validateOnChange={true}
                                validateOnBlur={true}
                                onSubmit={this.createSellerAccount}
                                data-test-id="formik"
                              >
                                {({ handleChange, errors, setFieldTouched, touched, setFieldValue, handleBlur, values, setErrors }) => (
                                  <Form
                                    translate={undefined}
                                    // className={classes.formWrraper}
                                  >
                                    <>
                                      <Grid item style={{ marginTop: "20px" }}>
                                        <Box
                                          sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column"
                                          }}
                                        >
                                          <>
                                            <TextField
                                              data-test-id="firstNameInputRef"
                                              label="First name"
                                              value={values.firstName}
                                              style={{
                                                marginBottom: "5px",
                                                fontFamily: `"Lato", sans-serif`
                                              }}
                                              error={this.hasError(touched.firstName, errors.firstName)}
                                              name="firstName"
                                              onChange={event => {
                                                handleChange(event);
                                                setFieldTouched("firstName", true, false);
                                              }}
                                              onBlur={handleBlur}
                                            />
                                            {this.returnErrorMessage(touched.firstName, errors.firstName)}
                                          </>
                                          <>
                                            <TextField
                                              label="Last name"
                                              data-test-id="lastNameInputRef"
                                              value={values.lastName}
                                              style={{ marginBottom: "5px" }}
                                              name="lastName"
                                              error={this.hasError(touched.lastName, errors.lastName)}
                                              onChange={event => {
                                                handleChange(event);
                                                setFieldTouched("lastName", true, false);
                                              }}
                                              onBlur={handleBlur}
                                            />
                                            {this.returnErrorMessage(touched.lastName, errors.lastName)}
                                          </>
                                          <>
                                            <TextField
                                              label="Company/Store name"
                                              data-test-id="companyNameInputRef"
                                              value={values.companyName}
                                              style={{
                                                marginBottom: "5px",
                                                fontFamily: `"Lato", sans-serif`
                                              }}
                                              name="companyName"
                                              error={this.hasError(touched.companyName, errors.companyName)}
                                              onChange={event => {
                                                handleChange(event);
                                                setFieldTouched("companyName", true, false);
                                              }}
                                              onBlur={handleBlur}
                                            />
                                            {this.returnErrorMessage(touched.companyName, errors.companyName)}
                                          </>
                                          <>
                                            <TextField
                                              label="Email address"
                                              data-test-id="emailAddressInputRef"
                                              value={values.emailAddress}
                                              style={{
                                                marginBottom: "5px",
                                                fontFamily: `"Lato", sans-serif`
                                              }}
                                              error={this.hasError(touched.emailAddress, errors.emailAddress)}
                                              name="emailAddress"
                                              onChange={event => {
                                                handleChange(event);
                                                setFieldTouched("emailAddress", true, false);
                                              }}
                                              onBlur={handleBlur}
                                            />
                                            {this.returnErrorMessage(touched.emailAddress, errors.emailAddress)}
                                          </>
                                          <>
                                            <TextField
                                              label="Phone number"
                                              data-test-id="phoneNumberInputRef"
                                              value={values.phoneNumber}
                                              style={{
                                                marginBottom: "5px",
                                                fontFamily: `"Lato",sans-serif`
                                              }}
                                              error={this.hasError(touched.phoneNumber, errors.phoneNumber)}
                                              name="phoneNumber"
                                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handlePhoneNumberChange(event,setFieldValue);
                                                setFieldTouched("phoneNumber", true, false)
                                              }}
                                              onBlur={handleBlur}
                                            />
                                            {this.returnErrorMessage(touched.phoneNumber, errors.phoneNumber)}
                                          </>

                                          <FormControl error={this.hasError(touched.password, errors.password)}>
                                            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                            <Input
                                              id="standard-adornment-password"
                                              data-test-id="passwordInputRef"
                                              type={this.state.showPassword ? "text" : "password"}
                                              value={values.password}
                                              style={{ marginBottom: "5px" }}
                                              name="password"
                                              onChange={event => {
                                                handleChange(event);
                                                setFieldTouched("password", true, false);
                                              }}
                                              error={this.hasError(touched.password, errors.password)}
                                              onBlur={handleBlur}
                                              endAdornment={
                                                <InputAdornment position="end">
                                                  <IconButton data-test-id="showPasswordRef" aria-label="toggle password visibility" onClick={this.handleClickShowPassword} onMouseDown={this.handleMouseDownPassword}>
                                                    {this.showPassword(this.state.showPassword)}
                                                  </IconButton>
                                                </InputAdornment>
                                              }
                                            />
                                            {this.returnErrorMessage(touched.password, errors.password)}
                                          </FormControl>
                                          <FormControl error={this.hasError(touched.confirmPassword, errors.confirmPassword)}>
                                            <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
                                            <Input
                                              id="standard-adornment-passwords"
                                              data-test-id="confirmPasswordInputRef"
                                              type={this.state.showConfirmPassword ? "text" : "password"}
                                              value={values.confirmPassword}
                                              style={{
                                                marginBottom: "5px",
                                                fontFamily: `"Lato",sans-serif`
                                              }}
                                              name="confirmPassword"
                                              error={this.hasError(touched.confirmPassword, errors.confirmPassword)}
                                              onChange={event => {
                                                handleChange(event);
                                                setFieldTouched("confirmPassword", true, false);
                                              }}
                                              onBlur={handleBlur}
                                              endAdornment={
                                                <InputAdornment position="end" data-test-id="adasdqa">
                                                  <IconButton data-test="showConfirmPasswordRef" aria-label="toggle password visibility" onClick={this.handleClickShowConfirmPassword} onMouseDown={this.handleMouseDownPassword}>
                                                    {this.showPassword(this.state.showConfirmPassword)}
                                                  </IconButton>
                                                </InputAdornment>
                                              }
                                            />
                                            {this.returnErrorMessage(touched.confirmPassword, errors.confirmPassword)}
                                          </FormControl>
                                        </Box>
                                      </Grid>
                                      <Grid item>
                                        <Box sx={{ width: "100%", minHeight: "60px" }}>{this.showTermError(touched.acceptTerms, errors.acceptTerms)}</Box>
                                      </Grid>

                                      <Grid item>
                                        <Button
                                          data-test-id="signUpButtonRef"
                                          disabled={this.state.disableSignInButton}
                                          style={{
                                            padding: "15px 0px",
                                            borderRadius: "10px",
                                            marginTop: "10px",
                                            background: "rgba(253, 97, 12, 1)",
                                            textTransform: "none",
                                            fontSize: 16,
                                            fontWeight: 600,
                                            width: "100%",
                                            color: "white",
                                            fontFamily: `"Lato",  sans-serif`
                                          }}
                                          type="submit"
                                        >
                                          Sign Up
                                        </Button>
                                      </Grid>
                                      <Grid item>
                                        <Box
                                          sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            fontSize: 8,
                                            marginTop: "10px"
                                          }}
                                        >
                                          <FormControlLabel
                                            data-test-id="acceptTermRef"
                                            control={
                                              <GreenCheckbox
                                                name="acceptTerms"
                                                checked={values.acceptTerms}
                                                onChange={(event: any) => {
                                                  handleChange(event);
                                                  setFieldTouched("acceptTerms", true, false);
                                                }}
                                                onBlur={handleBlur}
                                                aria-label="checkbox"
                                              />
                                            }
                                            label={
                                              <span
                                                style={{
                                                  fontSize: 11,
                                                  color: "rgba(0, 0, 0, 0.7)",
                                                  fontFamily: `"Lato",sans-serif`
                                                }}
                                              >
                                                I accept the{' '} 
                                                <Link 
                                                style={{
                                                  fontSize: 11,
                                                  fontFamily: `"Lato",sans-serif`,
                                                  display: "inline",
                                                }}
                                                onClick={this.handleGoToTerms}>
                                                  Terms & Conditions{' '}  
                                                </Link>
                                                and{' '}  
                                                <Link 
                                                style={{
                                                  fontSize: 11,
                                                  fontFamily: `"Lato",sans-serif`,
                                                  display: "inline"
                                                }}
                                                onClick={this.handleGoToPrivacy}>
                                                  Privacy Statement.{' '} 
                                                </Link>
                                              </span>
                                            }
                                          />
                                        </Box>
                                      </Grid>
                                    </>
                                  </Form>
                                )}
                              </Formik>
                            </Grid>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container style={{padding: '0  40px', background: 'rgb(243, 115, 43)', color: 'white', fontFamily: 'Lato, sans-serif'}}>
                <Grid item lg={12} sm={12} xs={12} style={{ padding: "49px 0px" }}>
                  <Box sx={{ border: "none", width: "100%" }}>
                    <Grid spacing={0} container justifyContent="space-between">
                      <Grid item>
                        <Grid container spacing={0}>
                          <Grid item style={{ border: "none" }}>
                            <Box
                              sx={{
                                fontSize: 18,
                                fontWeight: 600,
                                marginBottom: "20px",
                              }}>
                              Sell with ByEzzy
                            </Box>
                            <Grid container spacing={0}>
                              <Grid item>
                                <Box
                                  sx={{
                                    marginBottom: "20px",
                                    fontSize: 14,
                                    color: "white",
                                    marginRight: "40px",
                                  }}>
                                  <Grid spacing={1} container direction="column">
                                    <Typography data-test-id="commission" style={webStyle.sellText} onClick={() => this.handleFooterTitle("Cost & Commission details")}>Cost & Commission details</Typography>
                                    <Typography data-test-id="beginnerGuide" style={webStyle.sellText} onClick={() => this.handleFooterTitle("Beginner's guide")}>Beginner's guide</Typography>
                                  </Grid>
                                </Box>
                              </Grid>
                              <Grid item>
                                <Box
                                  sx={{
                                    marginBottom: "20px",
                                    marginRight: "40px",
                                    fontSize: 14,
                                    color: "white",
                                  }}>
                                  <Grid container spacing={1} direction="column">
                                  <Typography data-test-id="fulfill" style={webStyle.sellText} onClick={() => this.handleFooterTitle("Fulfillment by ByEzzy")}>Fulfillment by ByEzzy</Typography>
                                  <Typography data-test-id="advertiseBy" style={webStyle.sellText} onClick={() => this.handleFooterTitle("Advertise on ByEzzy")}>Advertise on ByEzzy</Typography>
                                  </Grid>
                                </Box>
                              </Grid>
                              <Grid item>
                                <Box
                                  sx={{
                                    fontSize: 14,
                                    marginRight: "40px",
                                    color: "white",
                                    marginBottom: "20px",
                                  }}>
                                  <Grid container spacing={1} direction="column">
                                  <Typography data-test-id="brandOne" style={webStyle.sellText} onClick={() => this.handleFooterTitle("Brand store")}>Brand store</Typography>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item style={{ marginBottom: "20px", border: "none" }}>
                            <Box
                              sx={{
                                fontSize: 18,
                                fontWeight: 600,
                                marginBottom: "20px",
                              }}>
                              ByEzzy Legal
                            </Box>
                            <Grid container spacing={1}>
                              <Grid item>
                                <Box sx={{ color: "white", fontSize: 14, marginRight: "40px", marginLeft: '5px' }}>
                                  <Grid container spacing={1} direction="column">
                                    <Typography data-test-id="privacy" style={webStyle.sellText} onClick={this.handleGoToPrivacy}>
                                      Privacy notice
                                    </Typography>
                                    <Typography data-test-id="terms" style={webStyle.sellText} onClick={this.handleGoToTerms}>
                                      Terms of service
                                    </Typography>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={"auto"} md={12} xs={12} sm={12} style={{ border: "none" }}>
                        <Box data-test-id="copyRight" sx={webStyle.credits}>@ 2024 ByEzzy.com All rights reserved</Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              </Grid>
              </Grid>
            </Box>
          </Box>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  errorStyle: {
    fontFamily: "Lato, sans-serif",
    color: "red",
    textAlign: "left" as const,
    fontSize: "12px",
    marginTop: "0px"
  },
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    width: "100%",
    overflowX: "hidden"
  },
  navigation: {
    border: "none",
    height: "50px",
    width: "fit-content",
    alignItems: "center",
    display: "flex",
    fontWeight: 400
  },
  signUpForFree: {
    width: "fit-content",
    padding: "15px 30px",
    fontSize: 16,
    fontWeight: 700,
    color: "white",
    borderRadius: "7px",
    fontFamily: `"Helvetica", "Arial", sans-serif`
  },
  credits: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontWeight: 500,
    fontSize: 14,
    color: "white"
  },
  legalText : {
    fontFamily: "Lato, sans-serif",
    fontSize: '14px',
    color: 'white',
    cursor: 'pointer'
  },
  termErrBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "no-wrap",
    alignItems: "center",
    marginTop: "5px",
    fontFamily: `"Lato",sans-serif`
  },
  marginOne: { 
    marginRight: "5px" 
  },
  errText: { 
    fontSize: 14, 
    color: "red" 
  },
  outerBox: { 
    fontFamily: `"Lato", sans-serif`, 
    color: "#575656", 
    position: "absolute" as const, 
    textAlign: "start" as const,
    marginTop: -50 
  },
  imgBoxOne: { 
    position: "absolute" as const, 
    top: 176, 
    left: 25, 
    zIndex: 0 
  },
  imgBoxTwo: { 
    position: "absolute" as const, 
    top: 0, 
    right: -45, 
    zIndex: 0, 
    width: "62%" 
  },
  gridContainerOne: { 
    margin: "50px 0px", 
    position: "relative" as const,
    padding: "0rem 3rem 0rem 4rem", 
  },
  noBorder: { 
    border: "none" 
  },
  marginTwo: { 
    marginTop: "105px" 
  },
  byEzzyText: { 
    fontSize: 24, 
    fontWeight: 600, 
    color: "#f78e24",
    "@media (max-width: 768px)": {
      "margin-top": "80px",
    }
  },
  innerBoxOne: {
    marginRight: "40px",
    fontSize: 19,
    fontWeight: 500
  },
  boxTwo: {
    marginRight: "40px",
    fontSize: 17,
    fontWeight: 400
  },
  boxThree: {
    marginRight: "40px",
    fontSize: 14,
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.5)"
  },
  marginThree: { 
    marginTop: "10px" 
  },
  signUpBackgrnd: { 
    background: "rgba(253,97,12,255)" 
  },
  byEzzyOption: { 
    marginRight: "40px", 
    marginLeft: "5px",
    fontSize: 14 
  },
  byezzyLegal : {
    marginBottom: "20px",
    fontSize: 18,
    fontWeight: 600
  },
  sellText: {
    fontSize: '14px',
    color: 'white',
    fontFamily: "Lato, sans-serif",
    cursor: 'pointer'
  }
};
// Customizable Area End
