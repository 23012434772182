export const logoImage = require("../assets/logo.png");
export const emailImage = require("../assets/insta.png");
export const fbImage = require("../assets/fb.png");
export const tiktokImage = require("../assets/tiktok.png");
export const whatsappImage = require("../assets/whatsapp.png");
export const linkdinImage = require("../assets/linkedin.png");
export const originalsImage = require("../assets/originalImage.png");
export const bitmapsImage = require("../assets/bitmaps.png");
export const manLaptopImage = require("../assets/manLaptop.png");
export const hourGlassManImage = require("../assets/hourGlassMan.jpg");
export const profileImage = require("../assets/user_icon.svg");
export const objects = require("../assets/objects.png");
export const cat = require("../assets/cat.png");
export const earPods = require("../assets/earpods.png");
export const salesBanner = require("../assets/salesBanner.png");
export const galaxyFold = require("../assets/galaxyFold.png");
export const galaxyPhone = require("../assets/galaxyPhone.png");
export const dealOne = require("../assets/deal1.png");
export const dealTwo = require("../assets/deal2.png");
export const dealThree = require("../assets/deal3.png");
export const locationIcon = require("../assets/placeholder.png");
export const dropDownIcon = require("../assets/dropDownIcon.png");
export const LandingPageBg = require("../assets/landingPageBg.png");
export const LandingPageBg2 = require("../assets/landingPageBg2.png");
export const ProfileSvg = require("../assets/ProfileSvg.svg");
export const fireImage = require("../assets/fire_image.png");
export const viewTopBg = require("../assets/view_view.png");
export const nextIcon = require("../assets/nextIcon.png");
export const backIcon = require("../assets/backIcon.png");
export const blueLogo = require("../assets/byezzy_logo_blue_png.png");