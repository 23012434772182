import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import * as yup from 'yup';
import { GetCookies } from "../../../framework/src/WebUtilities";
import { Message } from "../../../framework/src/Message";
import { InputProps } from "@material-ui/core/Input";
import { createRef } from "react";
import { UpdatedItem } from "../../catalogue/src/FulfilledByPartnerReturnsController.web";
export const configJSON2 = require("../../user-profile-basic/src/config.js");

export interface IReviewAttributes {
  id: string,
  attributes: {
    title: string,
    rating: number,
    description: string,
    review_type: string,
    is_approved: boolean,
    review_images?: string[] | null
  }
}

export interface ItemStatus {
  data: {
      id: string,
      attributes: {
          id: string,
          name: string
      }
  }[]
}

export interface IReviewData {
  id: string,
  type: string,
  attributes: IReviewAttributes
}

export interface IReviewsDataRes {
  data: IReviewAttributes[]
}

export interface FeedbackData {
  feedbackName: string,
  rating: string,
  name: string,
  label1: string,
  label2:string,
  placeholder1:string,
  placeholder2:string
}

interface DeliveryData {
  delivery: {
    title: string,
    description: string,
    rating: string,
    catalogue_id: string,
    review_type: string,
    account_id: string
  }
}

interface SellerData {
  seller: {
    title: string,
    description: string,
    rating: string,
    catalogue_id: string,
    review_type: string,
    account_id: string
  }
}

interface ProductData {
  product: {
    title: string,
    description: string,
    rating: string,
    catalogue_id: string,
    review_type: string,
    account_id: string
  }
}

export interface FormValues {
  productFeedback: {
      title?: string;
      description?: string;
      rating: number;
  };
  sellerFeedback: {
      title?: string;
      description?: string;
      rating: number;
  };
  deliveryFeedback: {
      title?: string;
      description?: string;
      rating: number;
  };
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>
  showReview: (show:boolean) => void 
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  rating: number,
  productFeedbackRating: unknown,
  sellerFeedbackRating: unknown,
  deliveryFeedbackRating: unknown,
  orderNumber: string,
  orderPlacedAt:string ,
  catalogId: string,
  orderTitle: string,
  sellerId: string,
  isReviewModalOpen: boolean,
  productImage: string,
  sellerFeedback: IReviewAttributes,
  productFeedback: IReviewAttributes,
  deliveryFeedback: IReviewAttributes,
  imageErrMsg : string,
  feedbackproductImage: { url: string}[],
  itemStatus: string,
  orderItemId: string,
  shippingAddress: string,
  loading: boolean
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AddProductReviewsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postCreateDeliveryReviews: string = "";
  postCreateProductReviews: string = "";
  postCreateSellerReviews: string = "";
  getReviewsApiCallId: string = "";
  updateProductReviews:string = "";
  imgInputRef: React.RefObject<InputProps & { click: Function }>;
  getItemStatusIdsApiCallId: string = "";
  updateStatusApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      rating: 0,
      productFeedbackRating: 0,
      sellerFeedbackRating: 0,
      deliveryFeedbackRating: 0,
      orderNumber: "",
      orderPlacedAt:"" ,
      catalogId: "",
      orderTitle: "",
      sellerId: "",
      isReviewModalOpen: false,
      productImage: "",
      sellerFeedback: {
        id: '',
        attributes: {
          title: '',
          description: '',
          rating: 0,
          is_approved: false,
          review_type: 'seller',
          review_images: null
        }
      },
      productFeedback: {
        id: '',
        attributes: {
          title: '',
          description: '',
          rating: 0,
          is_approved: false,
          review_type: 'product',
          review_images: null
        }
      },
      deliveryFeedback: {
        id: '',
        attributes: {
          title: '',
          description: '',
          rating: 0,
          is_approved: false,
          review_type: 'delivery',
          review_images: null
        }
      },
      imageErrMsg: '',
      feedbackproductImage: [],
      itemStatus: '',
      orderItemId: '',
      shippingAddress: '',
      loading: false
    };
    this.imgInputRef = createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.getItemStatusIdsApiCallId) {
        this.handleItemStatusIdRes(responseJson)
      }
      if (apiRequestCallId === this.updateStatusApiCallId) {
          this.handleUpdatedItemStatusRes(responseJson)
      }
      this.handleCreateReviewsRes(apiRequestCallId, responseJson)
      if (apiRequestCallId === this.getReviewsApiCallId) {
        this.handleSetReviewsData(responseJson)
      }
      if (apiRequestCallId === this.updateProductReviews) {
        this.handleUpdateProductReviewRes(responseJson)
      }
    }
  }

  handleCreateReviewsRes = (apiRequestCallId:string, responseJson: {review: {data: {id:string}}}) => {
    if (apiRequestCallId === (this.postCreateDeliveryReviews || this.postCreateSellerReviews || this.postCreateProductReviews)) {
      this.handleReviewsResponse(responseJson);
    }
  }

  handleUpdatedItemStatusRes = (responseJson: UpdatedItem) => {
    if(responseJson.data.id) {
      this.setState({itemStatus : '', loading: true})
    }
  }

  handleItemStatusIdRes = (responseJson: ItemStatus) => {
    if(responseJson.data) {
        const statusId = responseJson.data.filter((item: {attributes: {id: string, name: string}}) => {
            if(item.attributes.name === "Return") {
                return item
            }
        })
        this.updateItemStatusToReturn(statusId[0].id)
    }
  }

  handleUpdateProductReviewRes = (responseJson: {data: {id:string}, errors: string[]}) => {
    if(responseJson.data) {
      this.setState({isReviewModalOpen: true})
    }
    if(responseJson.errors) {
      this.setState({ imageErrMsg: responseJson.errors[0]})
    }
  }

  handleSetReviewsData = (responseJson:IReviewsDataRes) => {
    if(responseJson.data.length > 0) {
      responseJson.data.forEach((review: IReviewAttributes) => {
        const {review_type, title, description, rating, review_images, is_approved} = review.attributes
        if(review_type === "seller") {
          this.setState({sellerFeedback : {
            id: review.id,
            attributes: {
              title: title,
              description: description,
              rating: rating,
              is_approved: is_approved,
              review_type: 'seller',
              review_images: review_images
            }
          } })
        }
        if(review_type === "product") {
          this.setState({productFeedback : {
            id: review.id,
            attributes: {
              title: review.attributes.title,
              description: review.attributes.description,
              rating: review.attributes.rating,
              is_approved: review.attributes.is_approved,
              review_type: 'product',
              review_images: review.attributes.review_images
            }
          }
         })
         if(review.attributes.review_images) {
            const feedbackproductImage = review.attributes.review_images?.map((image:string) => ({
              url: image
          }))
          this.setState({feedbackproductImage: feedbackproductImage})
         }
          
        }
        if(review_type === "delivery") {
          this.setState({deliveryFeedback : {
            id: review.id,
            attributes: {
              title: title,
              description: description,
              rating: rating,
              is_approved: is_approved,
              review_type: 'delivery',
              review_images: review_images
            }
          } })
        }
      })
      
    }
  }

  handleReviewsResponse = (responseJson: {review: {data: {id:string}}}) => {
    if(responseJson.review) {
      this.setState({isReviewModalOpen: true})
    }
  }

  handleRatingClick = (formName: string, rating: number) => {
    if(formName === "productFeedback"){
      this.setState({ productFeedbackRating : this.state.productFeedbackRating === rating ? 0 : rating });
    }
    if(formName === "sellerFeedback"){
      this.setState({ sellerFeedbackRating : this.state.sellerFeedbackRating === rating ? 0 : rating });
    }
    if(formName === "deliveryFeedback"){
      this.setState({ deliveryFeedbackRating : this.state.deliveryFeedbackRating === rating ? 0 : rating });
    }
  };

  async componentDidMount(): Promise<void> {
    const itemId = await getStorageData("order_item_id",true)
    if(itemId) {
      this.setState({
        orderNumber: itemId.order_number,
        orderPlacedAt:itemId.order_placed ,
        catalogId: itemId.catalogue_id,
        orderTitle: itemId.order_title,
        productImage: itemId.product_image,
        sellerId: itemId.seller_id,
        itemStatus: itemId.item_status,
        orderItemId: itemId.order_item_id,
        shippingAddress: itemId.address
      })
      this.getReviews(itemId.catalogue_id)
    }
  }

  getReviews = async(itemId: string) => {
    const headers = {
        "Content-Type": configJSON.contenttype,
        token: GetCookies("ba")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getReviewsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.getReviewsApiEndpoint + `${itemId}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), 
      JSON.stringify(headers)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 
      configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  validationSchema = yup.object().shape({
    productFeedback: yup.object().shape({
      title: yup.string()
          .matches(/^(?! )[^\s](?!.* $)(?=.*\S).{2,30}$/, 'Max 30 characters allowed with no extra spaces')
          .nullable(),
      description: yup.string()
          .matches(/^(?! )[^\s](?!.* $)(?=.*\S).{3,200}$/, 'Max 200 characters allowed with no extra spaces')
          .nullable(),
      rating: yup.number()
          .min(1, 'Rating must be at least 1')
          .required('Rating is required')
    }),
    sellerFeedback: yup.object().shape({
      title: yup.string()
          .matches(/^(?! )[^\s](?!.* $)(?=.*\S).{2,30}$/, 'Max 30 characters allowed with no extra spaces')
          .nullable(),
      description: yup.string()
          .matches(/^(?! )[^\s](?!.* $)(?=.*\S).{3,200}$/, 'Max 200 characters allowed with no extra spaces')
          .nullable(),
      rating: yup.number()
          .min(1, 'Rating must be at least 1')
          .required('Rating is required')
    }),
    deliveryFeedback: yup.object().shape({
      title: yup.string()
          .matches(/^(?! )[^\s](?!.* $)(?=.*\S).{2,30}$/, 'Max 30 characters allowed with no extra spaces')
          .nullable(),
      description: yup.string()
          .matches(/^(?! )[^\s](?!.* $)(?=.*\S).{3,200}$/, 'Max 200 characters allowed with no extra spaces')
          .nullable(),
      rating: yup.number()
          .min(1, 'Rating must be at least 1')
          .required('Rating is required')
    })
  });

  handleSubmit = (values: FormValues) => {
    this.postReview(values)
  }

  postReview = async(values:FormValues) => {
    let httpBody = {}
    if(values.productFeedback.rating) {
      httpBody = {
        product: {
          title:values.productFeedback.title ,
          description:  values.productFeedback.description,
          rating: values.productFeedback.rating.toString(),
          catalogue_id: this.state.catalogId,
          review_type:"product",
          account_id: this.state.sellerId
        }
      }
      this.state.productFeedback.id ? this.updateProductReview(values, httpBody as ProductData) : this.postCreateProductReview(httpBody as ProductData, values)
    }
    if(values.sellerFeedback.rating) {
      httpBody = {
        seller: {
          title: values.sellerFeedback.title,
          description: values.sellerFeedback.description ,
          rating: values.sellerFeedback.rating,
          catalogue_id: this.state.catalogId,
          review_type: "seller",
          account_id: this.state.sellerId,
        }
      }
      this.postCreateSellerReview(httpBody as SellerData, values)
    }
    if(values.deliveryFeedback.rating) {
      httpBody = {
        delivery: {
          title: values.deliveryFeedback.title ,
          description: values.deliveryFeedback.description,
          rating: values.deliveryFeedback.rating,
          catalogue_id: this.state.catalogId,
          review_type: "delivery",
          account_id: this.state.sellerId,
        }
      }
      this.postCreateDeliveryReview(httpBody as DeliveryData, values)
    }
  }

  updateProductReview = async(productFeedback: FormValues, prodData: ProductData) => {
    const formData = new FormData()
    if(this.state.feedbackproductImage) {
      formData.append("review[rating]",productFeedback.productFeedback.rating.toString());
      this.updateProductData(formData,productFeedback)
      formData.append("review[catalogue_id]", this.state.catalogId)
      formData.append("review[review_type]", "product")
      formData.append("review[account_id]", this.state.sellerId)
      for(const image of this.state.feedbackproductImage as any) {
        if(image.file) {
          formData.append("review[review_images][]",image.file, image.file.name) 
        }
        else if(image.url) {
          try{
            const response = await fetch(image.url);
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
              const getName = image.url.split("/")
              const name = getName[getName.length - 1]
              const imgFile = {
                file: {},
                url: blobUrl,
                name: name
              };
            formData.append("review[review_images][]",blob, imgFile.name)
          }
          catch (error) {
            console.error("Failed to fetch and process image from URL:", error);
          }
        }
      }

    }
    const httpBody = {
      "review": {
          "rating": productFeedback.productFeedback.rating,
          "title": productFeedback.productFeedback.title,
          "description": productFeedback.productFeedback.description,
          "catalogue_id": this.state.catalogId,
          "review_type": "product",
          "account_id": this.state.sellerId,
          "review_images": []
      }
    }
    let headers = {}
    if(this.state.feedbackproductImage.length > 0) {
      headers = {
        "token": GetCookies("ba")
      };
    }
    else {
      headers = {
        "Content-Type": "application/json",
        "token": GetCookies("ba")
      };
    }
    
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateProductReviews = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/reviews` + `/${this.state.productFeedback.id}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), this.state.feedbackproductImage.length > 0 ? formData: JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpPatchMethod );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateProductData = (formData:FormData,productFeedback: FormValues) => {
    if(productFeedback.productFeedback.title){
      formData.append("review[title]", productFeedback.productFeedback.title.toString())
    }
    if(productFeedback.productFeedback.description) {
      formData.append("review[description]", productFeedback.productFeedback?.description)
    }
  }

  postCreateDeliveryReview = (deliveryData: DeliveryData, values: FormValues) => {
    const endPoint = this.state.deliveryFeedback.id ? `bx_block_catalogue/reviews` + `/${this.state.deliveryFeedback.id}` : `bx_block_catalogue/reviews`
    const reqMethod= this.state.deliveryFeedback.id ? configJSON.httpPatchMethod : configJSON.httpPostMethod
    const httpBodyData = this.state.sellerFeedback.id ? {
      "review": {
          "rating": values.deliveryFeedback.rating,
          "title": values.deliveryFeedback.title,
          "description": values.deliveryFeedback.description,
          "catalogue_id": this.state.catalogId,
          "review_type": "delivery",
          "account_id": this.state.sellerId
      }
    } : deliveryData
    const headers = {
      "Content-Type": "application/json",
      "token": GetCookies("ba")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postCreateDeliveryReviews = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBodyData));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),reqMethod );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  postCreateSellerReview = (sellerData: SellerData, values: FormValues) => {
    const apiEndPoint = this.state.sellerFeedback.id ? `bx_block_catalogue/reviews` + `/${this.state.sellerFeedback.id}` : `bx_block_catalogue/reviews`
    const reqMethod = this.state.sellerFeedback.id ? configJSON.httpPatchMethod : configJSON.httpPostMethod 
    const httpBody = this.state.sellerFeedback.id ? {
      "review": {
          "rating": values.sellerFeedback.rating,
          "title": values.sellerFeedback.title,
          "description": values.sellerFeedback.description,
          "catalogue_id": this.state.catalogId,
          "review_type": "seller",
          "account_id": this.state.sellerId
      }
    } : sellerData

    const headers = {
      "Content-Type": "application/json",
      "token": GetCookies("ba")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postCreateSellerReviews = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),apiEndPoint );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), reqMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  postCreateProductReview = (productData: ProductData, values: FormValues) => {
    const formData = new FormData()
    formData.append("product[rating]",values.productFeedback.rating.toString());
    if(values.productFeedback.title){
      formData.append("product[title]", values.productFeedback.title.toString())
    }
    if(values.productFeedback.description) {
      formData.append("product[description]", values.productFeedback?.description)
    }
    formData.append("product[catalogue_id]", this.state.catalogId)
    formData.append("product[review_type]", "product")
    formData.append("product[account_id]", this.state.sellerId)
    if(this.state.feedbackproductImage) {
      this.state.feedbackproductImage.forEach((imageData: any) => {
        formData.append("product[review_images][]", imageData.file, imageData.file.name)
      })
    }
    const headerOne = {
      "token": GetCookies("ba")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postCreateProductReviews = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/reviews`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headerOne));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpPostMethod );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCloseReview = () => {
    this.setState({isReviewModalOpen: false})
    this.props.showReview(false)
  }

  handleUploadImageFile = (event:any) => {
    const fileArray: { url: string }[] = [];
    const allowedExtension = ["image/jpeg", "image/jpg", "image/png"];
    const allFiles = event.target.files;
    [...allFiles]?.forEach((file: any) => {
      const maxFileSize = 5 * 1024 * 1024
      if (allowedExtension.includes(file.type) && file.size < maxFileSize) {
        this.setState({ imageErrMsg: "" });
        const image = {
          file,
          url: URL.createObjectURL(file),
          name: file.name
        };
        fileArray.push(image);
      }
      else this.setState({ feedbackproductImage: [],imageErrMsg: "Failed to upload image. Only png, jpg, jpeg format is allowed and maximum size is 5mb." });
    });
    this.setState({ feedbackproductImage: [...this.state.feedbackproductImage, ...fileArray] });
  };

  handleOpenImgFileInput = () => {
    if (this.imgInputRef && this.imgInputRef.current) {
      this.imgInputRef.current.click();
    }
  };

  handleRemoveImage = (imageIndex: number) => {
    const updatedImages = this.state.feedbackproductImage.filter((image: {url:string},index:number) => {
      if(index !== imageIndex) {
        return image
      }
    })
    this.setState({feedbackproductImage: updatedImages})
  }

  handleReturnItem = () => {
    this.setState({loading: true})
    this.getItemStatusId()
  }

  getItemStatusId = async() => {
    const headers = {
      "Content-Type": configJSON.ReviewsApiContentType,
      token: GetCookies("ba"),
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getItemStatusIdsApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON2.statusIdsApiEndPoint 
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(message.id, message);
  }

  updateItemStatusToReturn =  (statusId: string) => {
    const httpBody = {
        "order_status_id": statusId
    }
    const headers = {
        "Content-Type": configJSON2.contentTypeApiUpdateUser,
        token: GetCookies("ba"),
      };
      const message = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateStatusApiCallId = message.messageId;
      message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON2.addTocart + `/${this.state.orderItemId}`
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON2.patchMethod
      );
      runEngine.sendMessage(message.id, message);
  }
  // Customizable Area End

}
  