// Customizable Area Start
import React from "react";
import CheckOutOrderController, {
  Props,
  configJSON,
} from "./CheckOutOrderController.web";
import { withStyles } from "@material-ui/styles";
import HeaderWeb from "../../../landingpage/src/Header.web";
import { Box, Container, Divider, Grid, Theme, Typography } from "@material-ui/core";
import {
  background,
  brandName,
  cartBox,
  cartBoxDes,
  cartText,
  color,
  detail,
  finalPriceHeader,
  flex,
  imgWrapper,
  link,
  path,
  priceContainer,
  priceWrapper,
  productName,
  quantity,
  span,
  tableHeaderText,
  text,
  wrapper,
  zoom,
} from "./commomstyles";
import { successImg } from "../assets";
import { OrangeButton } from "../../../dashboard/src/CustomizedElementStyle";
import { Link } from "react-router-dom";
import { ScrollView } from "react-native";
import Footer  from "../../../landingpage/src/Footer.web";
// Customizable Area End

class CheckOutOrder extends CheckOutOrderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { total_fees, total_tax, final_price } = this.state.orderPriceInfo
    const shipAddress = this.state.shipAddress.attributes
    const addressSection = () => {
      return (
        <>
        <ScrollView>
          <Box className={classes.productWrapper}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.headerText}>
                  {configJSON.address}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.headerDesc}>
                  {""}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ paddingTop: "16px" }}
              >
                <Typography className={classes.address}>
                {shipAddress.address_1} {shipAddress.address_2}, {shipAddress.city}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.address}>{shipAddress.phone_number}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.address}>{shipAddress.state}</Typography>
              </Grid>
            </Grid>
          </Box>
          </ScrollView>
        </>
      );
    };

    const orderSummary = (item: any) => {
      const { product_image, product_title, brand } =
        item.attributes.item.catalogue.data.attributes;
      return (
        <Grid data-test-id="list" container spacing={0} className={classes.detailsWrapper}>
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={7}
            className={classes.leftContainer}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              className={classes.imgWrap}
            >
              <img
                style={{ width: "100%", height: "100%" }}
                src={product_image}
                alt="prod_img"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={11}
              lg={11}
              style={{ marginLeft: "25px", paddingRight:'20%' }}
            >
              <Typography className={classes.itemName}>
                {product_title}
              </Typography>
              <Typography className={classes.itemColor}>{""}</Typography>
              <Typography className={classes.productBrand}>
                Brand: {brand.brand_name}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}></Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            className={classes.newQuantity}
          >
            <Box className={classes.priceContainer}>
              <Typography className={classes.priceText}>
                {configJSON.AedText} {item.attributes.price}
              </Typography>
              <Typography className={`${classes.itemColor} ${classes.priceWrap}`}>Off {configJSON.AedText} 0</Typography>
            </Box>
          </Grid>
        </Grid>
      );
    };
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <ScrollView>
        <HeaderWeb navigation={this.props.navigation} id={this.props.id} itemCount={this.state.itemCount}  />
        <Container className={classes.containerWidth}>
        <Box className={classes.overview}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}className={classes.headerLinkSec}>
            <Link to={"/"} rel="noopener" className={classes.linkHome}>
              <Typography className={classes.routes}>
                {configJSON.home} 
                </Typography>
                </Link>
                <Typography className={classes.product}>
                / {configJSON.orderSummaryLabel}
                </Typography>
             
            </Grid>
          </Grid>
          <Box className={`${classes.productWrapper} ${classes.padding}`}>
            <Grid container>
              <Grid item xs={12} sm={12} md={1} lg={1}>
                <img
                  className={classes.successImg}
                  src={successImg}
                  alt="success_icon"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Grid
                  container
                  className={classes.textContainer}
                >
                  <Typography className={classes.heading}>
                    {configJSON.thankYouForPurchaseText}
                  </Typography>
                  <Typography className={classes.headingTwo}>
                    {configJSON.purchaseOrderDes}
                  </Typography>
                  <Typography className={classes.headingThree}>
                    Your order # is : {this.state.orderNumber}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.buttonWrap}
              >
                <OrangeButton onClick={this.handleContinueBtn} className={classes.shoppingBtn}>
                  {configJSON.continueShoppingBtn}
                </OrangeButton>
              </Grid>
              <Divider
                orientation="horizontal"
                className={classes.horizontalDivider}
              />
            </Grid>

            <Grid container spacing={0} className={classes.productContainer}>
              <Grid item xs={12} sm={12} md={10} lg={10}>
                <Typography className={classes.headerLabel}>
                  {configJSON.productLabel}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                lg={2}
                className={classes.priceWrap}
              >
                <Typography className={classes.headerLabel}>
                  {configJSON.priceLabel}
                </Typography>
              </Grid>
            </Grid>
            {this.state.orderItems.map((item: any) => {
              return <>{orderSummary(item)}</>;
            })}

            <Divider
              orientation="horizontal"
              className={classes.horizontalDivider}
            />
            <Grid container className={classes.priceSec}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.buttonWrap}
              >
                <Box className={`${classes.textOne} ${classes.bottomSec}`}>
                  <Typography className={classes.priceHeaderOne}>
                    {configJSON.totalPrice}
                  </Typography>
                  <Typography className={classes.priceHeaderOne}>
                    {configJSON.tax}
                  </Typography>
                  <Typography className={classes.priceHeaderOne}>
                    {configJSON.total}
                  </Typography>
                </Box>
                <Box
                  className={`${classes.textOne} ${classes.finalPriceGrid}`}
                >
                  <Typography className={classes.priceHeaderOne}>
                    {configJSON.AedText} {total_fees}
                  </Typography>
                  <Typography className={classes.priceHeaderOne}>
                    {configJSON.AedText} {total_tax}
                  </Typography>
                  <Typography className={classes.finalPriceHeader}>
                    {configJSON.AedText} {final_price}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {addressSection()}
        </Box>
        </Container>
        <Footer navigation={this.props.navigation} id={this.props.id} categories={[]} token={""} />
        </ScrollView>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = (theme:Theme) => ({
  imgWrap: imgWrapper,
  overview: background,
  routes: path,
  product: span,
  headerText: cartText,
  productWrapper: cartBox,
  padding: {
    padding: "30px 42px 30px 30px",
  },
  heading: {
    color: "#030303",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "32px",
    fontWeight: 700 as const,
  },
  headerDesc: cartBoxDes,
  headingTwo: {
    paddingTop: "8px",
    color: "#585858",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    fontWeight: 400 as const,
  },
  headingThree: {
    paddingTop: "8px",
    color: "#585858",
    fontFamily: `"Lato-Semibold", sans-serif`,
    fontSize: "14px",
    fontWeight: 400 as const,
  },
  shoppingBtn: {
    color: "#fff",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "12px",
    fontWeight: 400 as const,
    textTransform: "uppercase" as const,
    letterSpacing: "2px",
  },
  buttonWrap: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end"
  },
  detailsWrapper: {
    margin: "10px 0px 20px",
  },
  leftContainer: flex,
  itemName: productName,
  itemColor: color,
  productBrand: brandName,
  newQuantitySec: quantity,
  newQuantity: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  priceContainer: priceWrapper,
  priceText: {
    color: "#1c1c1c",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "15px",
    fontWeight: 600,
    textAlign: "end" as const,
  },
  productContainer: {
    margin: "30px 0px 0px",
    display: "flex",
  },
  headerLabel: tableHeaderText,
  horizontalDivider: {
    marginTop: "30px",
    width: "100%",
  },
  textOne: text,
  priceHeaderOne: detail,
  finalPriceHeader: finalPriceHeader,
  priceSec: priceContainer,
  address: {
    wordBreak : "break-all" as const,
    color: "#585858",
    fontFamily: `"Lato-Semibold", sans-serif`,
    fontSize: "16px",
    fontWeight: 400,
  },
  bottomSec: {
    height: "90px",
    display: "flex",
    paddingTop: "30px",
    marginRight: "60px",
  },
  containerWidth: zoom,
  linkHome: link,
  headerLinkSec :wrapper,
  successImg: {
    textAlign:'left' as const,
    width: "100px",
    height: "100px"
  },
  textContainer: {
    marginLeft: "20px",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "flex-start",
  } ,
  priceWrap: { 
    display: "flex", 
    justifyContent: "flex-end" 
  },
  finalPriceGrid: {
    display: "flex",
    alignItems: "flex-end"
  }
});

export { CheckOutOrder };
export default withStyles(styles)(CheckOutOrder);
// Customizable Area End
