// Customizable Area Start
import React from "react";
import SellerStorefrontController, {
  Props, configJSON,
} from "./SellerStorefrontController.web";
import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import HeaderWeb from "../../landingpage/src/Header.web";
import StarIcon from "@material-ui/icons/Star";
import { Rating } from "@material-ui/lab";
import { call, envelope, etrade, location } from "./assets";
import SellerStoreCategories from "./SellerStoreCategories.web";
import { categoryWrapper, checkbox, checkboxFormControl } from "./ShoppingCart/commomstyles";
import { BoxNoScroll } from "../../dashboard/src/CustomizedElementStyle";

interface IReviewInfo {
  id: string,
  attributes: {
    rating: number,
    review: string,
    date: string, 
    name: string
  }
}
// Customizable Area End

class SellerStorefront extends SellerStorefrontController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const ratingPercentage = [61, 15, 4, 3, 17];

    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      <BoxNoScroll style={{ overflowX: 'hidden' }}>
        <HeaderWeb navigation={this.props.navigation} id={this.props.id} />
        <Container className={classes.wrap}>
        <Box data-test-id="component" className={classes.container}>
          <Box className={classes.overview}>
            <Grid container className={classes.boxPadding}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className={classes.leftContainer}
              >
                <Grid container className={classes.info}>
                    <Grid item xs={12} sm={4} md={4} lg={4} className={classes.sellerLogo}>
                    <img className={classes.sellerIcon} src={etrade} alt="eTrade"/>
                    </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    className={classes.etradeContainer}
                  >
                    <Typography className={classes.title}>
                      ETrade Online
                    </Typography>
                    <Box className={classes.ratingContainer}>
                      <p>
                        <span className={classes.rating}>
                          <Rating
                            name="customized-color"
                            precision={0.5}
                            value={4}
                            readOnly
                          />
                          <p className={classes.ratingNumber}>4.0</p>
                        </span>
                      </p>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className={classes.rightContainer}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className={classes.topRightContainer}
                >
                  <Box className={classes.info}>
                    <img className={classes.icon} src={location} alt="location"/>
                    <Typography className={classes.text}>Al-Khabaisi, Dubai</Typography>
                  </Box>
                  <Box className={classes.info}>
                    <img className={classes.icon} src={call} alt="call"/>
                    <Typography className={classes.text}>+979876543210</Typography>
                  </Box>
                  <Box className={classes.info}>
                    <img className={classes.icon} style={{width:'24px'}} src={envelope} alt="envelope"/>
                    <Typography className={classes.text}>etrade@gmail.com</Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className={classes.storelink}
                >
                  <Typography className={classes.linkColor}>
                    Visit the ETrade Online storefront
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.down}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    className={classes.bottomLeftContainer}
                  >
                    <Typography className={classes.feedback}>
                      {configJSON.feedbackText}
                    </Typography>
                    <Box className={classes.ratingBox}>
                      <Typography className={classes.overallRating}>
                        4.0
                      </Typography>
                      <Typography
                        className={classes.star}
                      >
                        <StarIcon />
                      </Typography>
                    </Box>
                    <Typography className={classes.count}>30095 ratings</Typography>
                    <Typography
                      className={`${classes.feedback} ${classes.shareText}`}
                    >
                      {configJSON.shareYourThoughtText}
                    </Typography>
                    <Button
                      className={classes.sellerFeedback}
                    >
                      {configJSON.leaveSellerfeedBckText}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    className={classes.marginTop}
                  >
                    {ratingPercentage.map((percent, index) => (
                      <Box
                        key={index}
                        mb={1}
                        className={classes.progressBarContainers}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{ color: "#8a8a8a" }}
                        >
                          {5 - index}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.starIcon}
                        >
                          &#9733;
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={percent}
                          className={classes.progressBar}
                          classes={{ bar: this.getColorProgressBar(index) }}
                        />
                        <Typography
                          variant="subtitle1"
                          className={classes.percent}
                        >
                          {percent}%
                        </Typography>
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                className={classes.marginTopTwo}
              >
                {this.reviewInfo.map((item: IReviewInfo, index: number) => {
                  const isFullText = this.state.fullTextIndices.includes(index);
                  return (
                    <Grid container key={index} className={classes.marginTopThree}>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <Rating
                          name="customized-color"
                          precision={0.5}
                          value={item.attributes.rating}
                          readOnly
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={9}
                        lg={9}
                        className={classes.reviewBox}
                      >
                        {item.attributes.review.length > 160 ? (
                          <Typography className={classes.review}>
                            "
                            {isFullText
                              ? `${item.attributes.review}`
                              : `${item.attributes.review.slice(0, 160)}...`}
                            "
                          </Typography>
                        ) : (
                          <Typography className={classes.review}>
                            "{item.attributes.review}"
                          </Typography>
                        )}
                        {item.attributes.review.length > 160 && (
                          <Typography
                          data-test-id="readBtn"
                            className={classes.color}
                            onClick={() => this.handleIndices(index)}
                          >
                            {isFullText ? configJSON.readLessText : configJSON.readMoreText}
                          </Typography>
                        )}
                        <Typography className={classes.reviewer}>
                          By {item.attributes.name} on {item.attributes.date}.
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid container className={classes.btnWrapper}>
                  <Grid item xs={12} sm={12} md={2} lg={2}></Grid>
                  <Grid item xs={12} sm={12} md={10} lg={10}>
                    <Button className={classes.previousBtn}>{configJSON.previousText}</Button>
                    <Button className={classes.nextBtn}>{configJSON.nextText}</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <SellerStoreCategories navigation={undefined} id={"storefront-sidebar"}  />
        </Box>
        </Container>
        </BoxNoScroll>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
  categoryWrapper: categoryWrapper,
  checkbox: checkbox,
  checkboxFormControl : checkboxFormControl,
  container: {
    backgroundColor: "rgb(234,233,233)",
  },
  wrap: {
    maxWidth:'1700px', 
    padding:'0', 
    margin:'0 auto'
  },
  overview: {
    margin: "30px 0 0",
    backgroundColor: "#fff",
    borderBottom: "1px solid rgba(0,0,0,0.12)",
  },
  down: {
    marginBottom:'30px',
    padding: "34px 30px",
    backgroundColor: "#fff",
  },
  leftContainer: {
    borderRight: "1px solid rgba(0,0,0,0.12)",
    [theme.breakpoints.down("sm")]: {
      border: 'none'
    },
    [theme.breakpoints.down("xs")]: {
      border: 'none'
    }
  },
  title: {
    color: "#33333a",
    fontWeight: 600,
    fontSize: "2rem",
    fontFamily: `"Lato", sans-serif`
  },
  rightContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "0 60px",
    [theme.breakpoints.down("md")]: {
      padding: '0 24px'
    },
    [theme.breakpoints.down("sm")]: {
      padding: '0 24px'
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: "space-between",
      padding: "0 20px"
    }
  },
  ratingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "-8px",
    [theme.breakpoints.down("xs")]: {
      margin: 0
    }
  },
  rating: {
    display: "flex",
    flexDirection: "row" as const,
    gap: "0.2rem",
    alignItems: "center",
    justifyContent: "center",
    color: "orange",
    marginRight: "35px",
  },
  ratingNumber: {
    color: "rgb(251,98,15)",
    fontWeight: 500 as const,
    fontSize: '14px',
    borderLeft: "1px solid rgba(0,0,0,0.12)",
    marginLeft: "12px",
    paddingLeft: "12px",
    fontFamily: `"Lato", sans-serif`
  },
  feedback: {
    color: "rgb(36, 35, 35)",
    fontWeight: 600,
    fontFamily: `"Lato", sans-serif`,
    fontSize: "24px"
  },
  overallRating: {
    fontSize: "32px",
    fontFamily: `"Lato", sans-serif`,
    color: "orange",
  },
  color: {
    color: "rgb(251,98,15)",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "12px",
    marginTop: "8px",
    cursor: 'pointer'
  },
  reviewer: {
    marginTop: "12px",
    color: "#585858",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    fontWeight: 400,
  },
  review: {
    fontSize: "14px",
    color: '#33333a',
    fontFamily: `"Lato", sans-serif`,
  },
  nextBtn: {
    color: "rgb(251,98,15)",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    border: "none",
    textTransform: "capitalize" as const,
    '&:hover' : {
      backgroundColor: '#ffffff',
      boxShadow: 'none',
    },
  },
  previousBtn: {
    marginLeft: "12px",
    color: "#5a5a5a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    border: "none",
    textTransform: "capitalize" as const,
    '&:hover' : {
      backgroundColor: '#ffffff',
      boxShadow: 'none',
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0
    }
  },
  progressBar: {
    marginLeft: "14px",
    height: "20px",
    borderRadius: "6px",
    backgroundColor: "rgb(244,244,244)",
    width: "220px",
    border: "1px solid #e2e2e2",
  },
  progressBarContainers: {
    display: "flex",
    flexDirection: "row" as const,
    columnGap: "2px",
    alignItems: "center",
  },
  fiveStarColor: {
    backgroundColor: "#11984F",
    height: "100%",
  },
  fourStarColor: {
    backgroundColor: "#11964E",
    height: "100%",
  },
  threeStarColor: {
    backgroundColor: "#2CDD7E",
    height: "100%",
  },
  twoStarColor: {
    backgroundColor: "#F6C215",
    height: "100%",
  },
  oneStarColor: {
    backgroundColor: "#FE1909",
    height: "100%",
  },
  starIcon: {
    color: "rgba(0,0,0,0.26)",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "16px",
    lineHeight: "0",
  },
  topRightContainer: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
  },
  storelink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center"
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center"
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: '14px'
    }
  },
  linkColor: {
    color: "rgb(251,98,15)",
    fontFamily: `"Lato", sans`,
    cursor: 'pointer'
  },
  bottomLeftContainer : {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "flex-start",
    marginTop: "-10px",
  },
  ratingBox: {
    display: "flex", 
    margin: "20px 0 12px"
  },
  star: {
    color: "orange",
    display: "flex",
    alignItems: "center",
    margin: "5px 0 0 6px",
  },
  shareText: { 
    fontSize: "16px",
    fontFamily: `"Lato", sans-serif`, 
    marginTop: "34px" 
  },
  sellerFeedback: {
    borderRadius: "8px",
    border: "1px solid #ccc",
    margin: "16px 0 40px",
    padding: "6px 16px",
    textTransform: "capitalize" as const,
    fontSize: "14px",
    fontFamily: `"Lato", sans-serif`,
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: 'none'
    }
  },
  marginTop: { 
    marginTop: "50px" 
  },
  marginTopTwo: { 
    marginTop: "30px" 
  },
  marginTopThree: { 
    marginTop: "20px" 
  },
  reviewBox: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginLeft: "20px",
    [theme.breakpoints.down("xs")]: {
      marginTop: '10px',
      marginLeft: "0px"
    }
  },
  btnWrapper: {
    display: "flex", 
    marginTop: "20px"
  },
  sellerIcon: {
    marginLeft: '110px',
    height: '22px',
    [theme.breakpoints.down("sm")]: {
      marginLeft: '0px',
    }
  },
  icon: {
    display:'flex',
    alignItems: 'center',
    marginRight: '20px',
    height: '20px'
  },
  info: {
    display:'flex', 
    alignItems:'center',
    [theme.breakpoints.down("md")]: {
      justifyContent: "center"
    },
  },
  boxPadding: {
    padding: "30px 20px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      padding: "20px 10px"
    }
  },
  etradeContainer: { 
    display:'flex', 
    flexDirection:'column' as const, 
    alignItems:'center' ,
    marginTop:'8px'
  },
  text: {
    color: '#33333a',
    fontFamily: `"Lato", sans-serif`, 
  },
  percent: { 
    marginLeft: "8px", 
    fontFamily: `"Lato", sans-serif`, 
    fontWeight: 500 
  },
  count: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: '14px'
  },
  sellerLogo: {
    [theme.breakpoints.down("md")]: {
      display:'flex', 
      alignItems: "center",
      justifyContent: "center"
    }
  }
});

export { SellerStorefront };
export default withStyles(styles)(SellerStorefront);
// Customizable Area End
