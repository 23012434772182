// Customizable Area Start
import React from "react";
import SellerPartnerOrderDetailsController, {
  IOrderItem,
  Props, configJSON,
} from "./SellerPartnerOrderDetailsController.web";
import { Grid, Typography, withStyles, Box, Button, Divider } from "@material-ui/core";
import { OrangeButton } from "../../dashboard/src/CustomizedElementStyle";
import { cancelledOrderStatus, completedOrderStatus, pendingOrderStatus, refundOption } from "./ShoppingCart/commomstyles";
import ShippingDetails from "./ShoppingCart/ShippingDetails.web";
// Customizable Area End

class SellerPartnerOrderDetails extends SellerPartnerOrderDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  buttonContainer = (orderStatus:string) => {
    return(
      <Grid container className={this.getButtonWrapStyles(orderStatus)}>
        {orderStatus === configJSON.rejectedText ? 
          <Box data-test-id="refundBtn" className={this.props.classes.refundBtn} onClick={this.handleRefundButton}>
          {configJSON.refundText}
          </Box>
          :
          <>
          <Grid item xs={12} sm={6} md={6} lg={6} className={this.props.classes.orderDetailsHeader}>
              <Typography className={this.props.classes.orderDetailsText}>
              {configJSON.orderDetailsLabel}
              </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} className={this.props.classes.btnWrapper}>
              <OrangeButton data-test-id="acceptButton" className={this.props.classes.printLabelBtn} disabled={this.state.orderDetails.accepted || (orderStatus === configJSON.rejectedText)} onClick={this.handleAcceptBtn}>
              {configJSON.acceptBtn}
              </OrangeButton>
              <Button data-test-id="rejectButton" className={this.props.classes.viewOption} onClick={this.handleRejectOrderBtn} disabled={this.state.orderDetails.accepted} >{configJSON.rejectBtn}</Button>
          </Grid>
          </>
      }
     </Grid>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {orderDetails} = this.state;
    const { address_1 , address_2, city, state, phone_number } = this.state.orderDetails.shipping_address.data && this.state.orderDetails.shipping_address.data.attributes || {}
    const orderItems = this.state.orderDetails.order_items.data
    const orderStatus = orderDetails.order_status.data.attributes.name
    const highestOrderId = this.renderHighestIdObj(this.state.orderDetails?.shipped_order_details)
    const { tracking_number, courier_name,  } = highestOrderId || {}
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box className={classes.wrapper}>
            {this.buttonContainer(orderStatus)}
            <Box data-test-id="component" className={classes.componentWrapper}>
                <Grid container className={classes.main}>
                    <Grid item lg={4} md={12} sm={12} xs={12} className={classes.border}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container className={classes.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.orderBox}>
                            <Typography className={classes.left}>{configJSON.orderIdText} </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} className={classes.orderBox1}>
                            <Typography className={classes.right}>{orderDetails.order_number}</Typography>
                        </Grid>   
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container className={classes.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.orderBox}>
                            <Typography className={classes.left}>{configJSON.buyerNameLabel} </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} className={classes.orderBox1}>
                            <Typography className={classes.right}>{orderDetails.customer.data.attributes.full_name}</Typography>
                        </Grid>   
                    </Grid>
                </Grid> 
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container className={classes.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.orderBox}>
                            <Typography className={classes.left}>{configJSON.sellerNameLabel} </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} className={classes.orderBox1}>
                            <Typography className={classes.right}>{orderDetails.seller.data.attributes.full_name}</Typography>
                        </Grid>   
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container className={classes.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.orderBox}>
                            <Typography className={classes.left}>{configJSON.fullFillmentLabel} </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} className={classes.orderBox1}>
                            <Typography className={classes.right}>{configJSON.partnerText}</Typography>
                        </Grid>   
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container className={classes.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.orderBox}>
                            <Typography className={classes.left}>{configJSON.dateLabel} </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} className={classes.orderBox1}>
                            <Typography className={classes.right}>{this.getOrderDate(orderDetails.ordered_at)}</Typography>
                        </Grid>   
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container className={classes.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.orderBox}>
                            <Typography className={classes.left}>{configJSON.timeLabel} </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} className={classes.orderBox1}>
                            <Typography className={classes.right}>{`${this.getOrderTime(orderDetails.ordered_at)} GST`}</Typography>
                        </Grid>   
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container className={classes.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.orderBox}>
                            <Typography className={classes.left}>{configJSON.shippingAddressText} </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} className={classes.orderBox1}>
                            <Typography className={classes.right}>{address_1 || ""} {address_2 || ""}, {city || ""}</Typography>
                            <Typography className={classes.right}>{phone_number || ""}</Typography>
                            <Typography className={classes.right}>{state || ""}</Typography>
                        </Grid>   
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container className={classes.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.orderBox}>
                            <Typography className={classes.left}>{configJSON.orderStatusLabel}: </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} className={classes.padding}>
                          <Box className={this.getOrderDetailsStatusClass(
                          orderDetails.order_status.data.attributes.name
                        )}>{this.getOrderDetailsStatus(orderDetails.order_status.data.attributes.name)}
                        </Box>
                        </Grid>   
                    </Grid>
                </Grid>
               {orderDetails.order_status.data.attributes.name === configJSON.shippedText && <>
                <ShippingDetails
                  id="listingShipping" 
                  order_id={this.state.orderId} 
                  navigation={this.props.navigation} 
                  open={this.state.openShip} 
                  onClose={() => this.handleOpenShipModal(false)}
                  onSubmit={()=>this.getOrderDetails(this.state.orderId)}
                  orderDetails={highestOrderId}
                />
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container className={classes.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.orderBox}>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} className={classes.orderBox1}>
                            <Button data-test-id="updateShipButton" 
                              className={this.props.classes.viewOption}
                              onClick={()=>this.handleOpenShipModal(true)}
                              style={{ 
                                textTransform: "capitalize",
                                marginTop: "10px",
                                cursor: "pointer",
                                color: "white",
                                width: "145px",
                                backgroundColor: "#FF6008",
                                height: "20px",
                                margin: '10px 0 0 0',
                                padding: "12.5px 19px",
                                border: "none",
                                fontSize: "14px"
                            }} >{configJSON.updateLabel}</Button>
                        </Grid>
                    </Grid>
                </Grid>
               <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container className={classes.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.orderBox}>
                            <Typography className={classes.left}>{configJSON.courierName}:</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} className={classes.orderBox1}>
                            <Typography className={classes.right}>{courier_name || ""}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container className={classes.leftContainer}>
                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.orderBox}>
                            <Typography className={classes.left}>{configJSON.trackingNumber}:</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} className={classes.orderBox1}>
                            <Typography className={classes.right}>{tracking_number || ""}</Typography>
                        </Grid>
                    </Grid>
                </Grid></>}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={8} >

                <Grid container spacing={1} className={`${classes.productInfo} ${classes.topRight}`} >
                  <Box className={classes.productBox}>
                  {orderItems.map((order:IOrderItem) => {
                    const {product_image, product_title, brand} = order.attributes.item.catalogue.data.attributes
                   return (
                    <>
                    <Box className={classes.itemWrapper} >
                        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.imageBox}>
                          <Box className={classes.prodImageContainer}>
                            <img src={product_image} alt={"product_img"} className={classes.prodImage}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={4} className={classes.productDetails}>
                            <Typography data-test-id="title" className={classes.productName}>{product_title}</Typography>
                            <Typography className={classes.color}>{""}</Typography>
                            <Typography className={classes.brandName}>{configJSON.brandText}: {brand.brand_name}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} className={classes.quantity}>
                            <Typography className={classes.detail}>{configJSON.quantityLabel}</Typography>
                            <Typography className={classes.detailValue}>{order.attributes.quantity}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} className={`${classes.quantity} ${classes.leftMargin}`}>
                            <Typography className={`${classes.detail}`}>{configJSON.customerOptionLabel}</Typography>
                            <Typography className={classes.detailValue}>{configJSON.standardText}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} className={`${classes.quantity} ${classes.leftMargin}`}>
                            <Typography className={classes.detail}>{configJSON.totalPriceLabel}</Typography>
                            <Typography className={classes.detailValue}>{configJSON.AedText} {order.attributes.price}</Typography>
                        </Grid>

                    </Box>
                    </>
                  ) 
                })} 

              </Box>
            </Grid>
            <Grid container spacing={1} className={`${classes.productInfo} ${classes.bottomRight}`}>
                        <Grid item xs={12} sm={12} md={2} lg={6} className={classes.summaryText}>
                            <Typography className={classes.orderSummary}>{configJSON.orderSummaryLabel}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} className={`${classes.quantity} ${classes.totalQty}`}>
                            <Typography className={classes.detail}>{configJSON.totalQuantityLabel}</Typography>
                            <Typography>{orderDetails.total_items}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} className={classes.quantitySec}>
                        <Grid item xs={12} sm={6} md={6} lg={8} className={`${classes.quantity} ${classes.productInfo} ${classes.leftMargin}`}>
                            <Typography className={classes.detail} >{configJSON.totalPriceLabel}:</Typography>
                            <Typography className={classes.detail}>{configJSON.tax}</Typography>
                            <Divider className={classes.dividerOne}/>
                            <Typography className={classes.detail}>{configJSON.total}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={8} className={`${classes.priceBox} ${classes.leftMargin}`}>
                            <Typography className={classes.priceValue}>{configJSON.AedText} {orderDetails.total_fees}</Typography>
                            <Typography className={classes.priceValue}>{configJSON.AedText} {orderDetails.total_tax}</Typography>
                            <Divider className={classes.dividerTwo}/>
                            <Typography className={`${classes.priceValue} ${classes.bold}`}>{orderDetails.final_price}</Typography>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>
                
                </Grid>
            </Box>
                
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles = {
  wrapper: {
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as const,
    padding: "20px 0px",
    position: "relative" as const,
    background: "white",
    overflowX: 'hidden' as const,
    overflowY: 'auto' as const,
    height: '100vh'
  },
  headerWrapper: {
    display:'flex',
    alignItems:'center',
    justifyContent: 'space-between',
    borderBottom: "1px solid rgba(224,224,224,1)"
  },
  orderDetailsHeader: {
    width: "100%",
    display: "flex",
    flexDirection: "row" as const,
    flexWrap: "wrap" as const,
    justifyContent: "space-between",
    alignItems: "center" as const,
    marginBottom: 10,
  },
  orderDetailsText: {
    fontSize: "27px",
    padding:'0 30px',
    fontWeight: 700 as const,
    fontFamily: `"Lato", sans-serif` as const,
  },
  viewOption: {
    marginLeft: '20px',
    padding: "5px 23px",
    color: "#657A8E",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 14,
    fontWeight: 500 as const,
    border: "1px solid rgba(101, 122, 142, 0.320241)",
    backgroundColor: "#fff",
    borderRadius: 5,
    display: "flex",
    textTransform: 'capitalize' as const
  },
  printLabelBtn: {
    padding:'5px 20px',
    fontSize: 14,
    fontWeight: 500,
    fontFamily: `"Lato", sans-serif` as const,
    margin: "20px 0",
    borderRadius: "5px",
  },
  btnWrapper : {
    display:'flex',  
    alignItems:'center',
    justifyContent: 'flex-end',
    flexDirection:'row' as const, 
    borderBottom:'0',
    padding:'0 30px'
  },
  componentWrapper: {
    padding:'40px 0',
    display:'flex',
    flexDirection:'column' as const
  },
  orderBox: {
    padding:'0 40px',
    display:'flex',
    alignItems: 'flex-start',
    flexDirection: 'column' as const
  },
  left: {
    width:'160px',
    color: 'rgb(101, 122, 142)',
    fontSize:'14px',
    fontFamily: `"Lato", sans-serif`,
    paddingTop:'5px'
  },
  right: {
    color:'#000',
    fontSize:'14px',
    fontFamily: `"Lato", sans-serif`,
    paddingTop:'5px',
    wordBreak: 'break-word' as const
  },
  leftContainer: {
    display:'flex',
    alignItems:'flex-start',
    justifyContent:'flex-start',
    borderRight: '1px solid rgba(224,224,224,1)'
  },
  prodImage: {
    height: "100%",
    width: "100%",
    objectFit: "fill" as const
  },
  productDetails: {
    display: 'flex',
    flexDirection:'column' as const,
    alignItems:'flex-start',
    justifyContent: 'space-between',
    marginLeft: '-20px',
    padding:'0 14px',
    gap:'3px',
    '@media (max-width: 860px)': {
      marginLeft: '0px',
    }
  },
  productInfo: {
    display:'flex',
    alignItems:'flex-start'
  },
  productName: {
    color: '#232529',
    fontFamily: `"Lato", sans-serif`,
    fontSize: '15px',
    fontWeight: 400,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all' as const
  },
  color: {
    color: '#969696',
    fontFamily: `"Lato", sans-serif`,
    fontSize: '13px',
    fontWeight: 400
  },
  brandName: {
    color: '#33333a',
    fontFamily: `"Lato", sans-serif`,
    fontSize: '13px',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all' as const
  },
  quantity: {
    display:'flex',
    alignItems:'center',
    flexDirection:'column' as const,
    justifyContent: 'space-between',
    '@media (max-width:460px)' : {
      marginLeft:'10px',
      alignItems: 'revert'
    }
  },
  detail: {
    color: 'rgb(101, 122, 142)',
    fontSize:'14px',
    fontFamily: `"Lato", sans-serif`,
    marginBottom: '8px',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all' as const
  },
  completedOrderStatus: completedOrderStatus,
  pendingOrderStatus: pendingOrderStatus,
  cancelledOrderStatus: cancelledOrderStatus,
  topRight: {
    height:'70vh', 
    overflowY:'auto' as const,
    padding:'10px 20px',
    marginLeft:'10px',
    borderBottom:'1px solid rgba(224,224,224,1)',
    "@media (max-width: 1260px)": {
      height: 'auto'
    }
  },
  bottomRight: {
    height:'50vh',
    padding:'35px 20px',
    '@media (max-width: 860px)': {
      height:'0'
    }
  },
  orderSummary: {
    fontSize:'28px',
    color: 'rgb(101, 122, 142)'
  },
  padding: {
    padding: '5px 24px 0 55px'
  },
  prodImageContainer: {
    width:'50px',
    height:'50px',
    backgroundColor: 'rgb(231,231,231)',
    padding:'8px',
    borderRadius: "8px",
    marginLeft:'10px',
  },
  imageBox: {
    display:'flex',
    alignItems:'center'
  },
  detailValue: {
    fontSize:'15px',
    fontFamily: `"Lato", sans-serif`,
    fontWeight:500,
    color:'#000',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all' as const
  },
  priceValue: {
    marginBottom:'8px',
    fontSize: "14px",
    fontFamily: `"Lato", sans-serif`,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all' as const
  },
  priceBox: {
    display:'flex',
    alignItems:'end',
    flexDirection:'column' as const,
    justifyContent: 'space-between'
  },
  refundBtn: refundOption,
  refundWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    padding: '20px'
  },
  orderBox1: {
    padding:'0 40px 0 55px',
    display:'flex',
    alignItems: 'flex-start',
    flexDirection: 'column' as const
  },
  productBox: {
    height:'70vh', 
    overflowY: 'auto' as const, 
    width:'100%',
    marginLeft:'-10px',
    '@media (max-width:1260px)' : {
      height:'auto'
    }
  },
  itemWrapper: {
    height:'auto',
    display:'flex', 
    width:'100%', 
    padding:'20px 0',
  },
  leftMargin: {
    marginLeft:'20px'
  },
  customerWidth: { 
    width:'108px'
  },
  summaryText: {
    display:'flex',
    alignItems:'center', 
    justifyContent:'center', 
    height:'90px',
    '@media (max-width:860px)' : {
      justifyContent:'flex-start', 
    }
  },
  totalQty: {
    display:'flex',
    height:'60px',
    '@media (max-width:460px)': {
      alignItems: 'revert' as const,
      flexDirection:'row' as const
    }
  },
  quantitySec: { 
    display:'flex', 
    justifyContent:'space-between'
  },
  dividerOne: {
    width:'145%',
    margin:'12px 0', 
    border: '0.5px solid rgba(224,224,224,1)'
  },
  dividerTwo: {
    width:'100%',
    margin:'4px 0 8px', 
    border: '0.5px solid rgba(224,224,224,1)'
  },
  bold: {
    fontWeight:600
  },
  main: {
    display:'flex', 
    alignItems:'flex-start',
    justifyContent:'flex-start'
  },
  border: {
    borderRight:'1px solid rgba(224,224,224,1)'
  }
};

export {SellerPartnerOrderDetails}
export default withStyles(styles)(SellerPartnerOrderDetails);
// Customizable Area End
