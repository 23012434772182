import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("../src/config.js")
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes : {
    card: string,
    root: string,
    prodImag: string,
    buyer: string,
    requestId: string,
    downloadBtn: string,
    productDetails: string,
    productValue: string,
    byEzzyText: string
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  authToken: string;
  cardsData: any[]
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class PrintLabelController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      authToken: "",
      cardsData :[]
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  cardsData = [
    { id:'1', attributes: {title: 'Card 1', content: 'Content for Card 1', productName: "OPPO A31 (Mystery Black, 6GB RAM, 128GB Storage)", requestId:"FD65456DFDF456F", fulfillment:"ByEzzy", stockQty: 1000, price:"4198 AED", stockVolume:"75 x 45 x 125 cm", weight:"750gms"} },
    { id:'2', attributes: {title: 'Card 2', content: 'Content for Card 2', productName: "OPPO A31 (Mystery Black, 6GB RAM, 128GB Storage)", requestId:"FD65456DFDF456F", fulfillment:"ByEzzy", stockQty: 1000, price:"4198 AED", stockVolume:"75 x 45 x 125 cm", weight:"750gms"}  },
    { id:'3', attributes: {title: 'Card 3', content: 'Content for Card 3', productName: "OPPO A31 (Mystery Black, 6GB RAM, 128GB Storage)", requestId:"FD65456DFDF456F", fulfillment:"ByEzzy", stockQty: 1000, price:"4198 AED", stockVolume:"75 x 45 x 125 cm", weight:"750gms"}  }
  ];

  handleGetToken = async () => {
    const authToken = await getStorageData("authToken");
    this.setState({ authToken });
  };

  async componentDidMount(): Promise<void> {
    this.handleGetToken();
    this.setState({cardsData: this.cardsData})
  }
  // Customizable Area End
}
