// Customizable Area Start
import React from "react";
import { Box, Grid, Typography, withStyles } from "@material-ui/core";
import {
  BoxNoScroll,
  OrangeButton,
} from "../../dashboard/src/CustomizedElementStyle";
import { cross } from "../../dashboard/src/assets";
import DeliveryRequestHomeController, { Props, configJSON } from "./DeliveryRequestHomeController.web";
// Customizable Area End

class DeliveryRequestHome extends DeliveryRequestHomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {classes} = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      <Box data-test-id="deliveryRequest" className={classes.screenHeader}>
          <Grid item xs={12} lg={12} md={12} sm={12}>
        <Typography
          className={classes.headerLabel}
        >
          {configJSON.fulfilledByPartnerLabel}
        </Typography>
        </Grid>
        </Box>
        <BoxNoScroll className={classes.layout}>
          <Box
            className={classes.mainWrapper}
          >
            <Box
              className={classes.label}
            >
              <Box
                className={classes.deliveryText}
              >
               {configJSON.deliveryReqLabel}
              </Box>
            </Box>
            <Box
              className={classes.container}
            ></Box>
            <Box className={classes.horizontal}></Box>
            <Box
              className={classes.card}
            >
              <img src={cross} alt="cross_icon"/>
              <Box
                className={classes.textOne}
              >
                {configJSON.welcomeText}
              </Box>
              <Box
                className={classes.para}
              >
                {configJSON.noReqYetText}
              </Box>
              <OrangeButton
                className={classes.reqBtn}
                onClick={this.handleGoToCreateDeliveryReq}
              >
                {configJSON.createNewRequestBtn}
              </OrangeButton>
            </Box>
          </Box>
        </BoxNoScroll>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  screenHeader: {
  },
  headerLabel: {
    fontFamily: `"Lato", sans-serif` as const,
    padding: "20px 0px 15px 0px",
    fontSize: "18px",
    fontWeight: 400,
  },
  layout: { 
    paddingBottom: 20.5, 
    overflow: "auto" 
  }, 
  mainWrapper: {
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as const,
    padding: "30px",
    overflowY: "auto" as const,
    overflowX: "hidden" as const,
    position: "relative" as const,
    background: "white",
  },
  label: {
    width: "100%",
    flexDirection: "row" as const,
    display: "flex",
    flexWrap: "wrap" as const,
    justifyContent: "space-between" as const,
    alignItems: "center",
    marginBottom: 10,
  },
  deliveryText: {
    fontSize: "27.1px",
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif` as const,
  },
  container: {
    width: "calc(100% + 60px)",
    height: 1,
    background: "#EEEEEE",
    position: "absolute" as const,
    left: 0,
    top: 100,
  },
  horizontal: { 
    width: "100%", 
    height: 90 
  },
  card: {
    width: "100%",
    height: 350,
    alignItems: "center",
    display: "flex",
    justifyContent: "center" as const,
    flexDirection: "column" as const,
  },
  textOne: {
    color: "#33333A",
    fontSize: 20,
    fontFamily: `"Lato", sans-serif` as const,
    fontWeight: 400,
    margin: "14px 0px",
  },
  para: {
    color: "#33333A",
    fontSize: 20,
    fontFamily: `"Lato", sans-serif` as const,
    fontWeight: 700,
    marginBottom: 26,
  },
  reqBtn: { 
    fontSize: 20 
  }
}

export {DeliveryRequestHome}

export default withStyles(styles)(DeliveryRequestHome);
// Customizable Area End
