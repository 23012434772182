import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../../framework/src/Utilities";
import { IGetStoreData } from "./Interfaces";
import { toast } from "react-toastify";

// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  getStore_name: string;
  getStore_Year: string;
  showList: boolean;
  authToken: string;
  getStoreDataList: any;
  deleteResponseMsg: string;
  storeName: string;
  editMessage:string;
  storeYear: string;
  isLoading: boolean;
  editMessageCheck:boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class WelcomeController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetStoreDataCallId: string = "";
  apiDeleteStoreCallId: string = "";
  editStoreApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.ReceiveMessage), getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      getStore_name: "",
      getStore_Year: "",
      showList: false,
      authToken: "",
      getStoreDataList: [],
      deleteResponseMsg: "",
      storeName: "",
      editMessage:"",
      storeYear: "",
      isLoading: false,
      editMessageCheck:false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.apiGetStoreDataCallId) {
        this.handleGetStoreDetailsResponse(responseJson);
      }
      if (apiRequestCallId === this.apiDeleteStoreCallId) {
        await removeStorageData("store_id");
        this.handleDeleteStoreResponse(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    await this.handleGetToken();
    let checkEdit=this.props.navigation.history.location?.state?.edit;
    if(checkEdit){
      toast.success('Store updated successfully', {
        position: "top-right",
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
    if(!checkEdit && checkEdit!==undefined){
      toast.success('Store added successfully', {
        position: "top-right",
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
    if(this.state.editMessageCheck){
      setTimeout(() => {
        this.setState((state, props) => ({
          editMessageCheck: true
        }), () => {
          setTimeout(() => {
            this.setState(() => ({
              editMessageCheck: false
            }))
          }, 3000);
        })
      }, 100);
    }
    this.getStoreListingData();
  }
  handleGetToken = async () => {
    const authToken = await getStorageData("authToken");
    this.setState({ authToken: authToken }, () => {});
  };
  handleGetStoreDetailsResponse = (responseJson: IGetStoreData) => {
    this.setState({ isLoading: false });
    if (responseJson) {
      this.setState({
        getStoreDataList: responseJson.data,
        isLoading: false
      });
    }
  };
  handleDeleteStoreResponse = (responseJson: any) => {
    this.setState(
      {
        deleteResponseMsg: responseJson.message
      },
      () => {
        this.getStoreListingData();
      }
    );
  };
  getStoreListingData = () => {
    this.setState({ isLoading: true });
    const headers = { token: this.state.authToken };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetStoreDataCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getStoreNameEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteStoreData = (id: any) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.authToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiDeleteStoreCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.myDealsApiEndPoint.deleteStoreData(id));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onEditStore = (item: any) => {
    setStorageData("store_id", item.id);
    setStorageData("store_data", JSON.stringify(item))
    setStorageData("store_name", item.attributes.store_name)
    this.props.navigation.navigate(configJSON.sellerHomeText, {path: configJSON.createStorePath})
  };
  handleGoToCreateStorePage = () => {
    removeStorageData("store_id");
    removeStorageData("store_data");
    this.props.navigation.navigate(configJSON.sellerHomeText, {path: configJSON.createStorePath})
  };

  // Customizable Area End
}
