// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { createRef } from "react";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("../config.js");

interface IStoreTabResponse {
  data: {
    id: string,
    attributes: {
      logo: string,
      cover_image: string
    }
  }[]
}

interface IHeaderResponse {
  data: {
    id: string, 
    attributes: {}
  },
  errors: string[]
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    logo: string,
    removeBox: string,
    marginOne: string,
    input: string,
    previewBox: string,
    logoPreview: string,
    uploadBtn: string,
    uploadLogoText: string,
    fileType: string,
    errorBox: string,
    rightMargin: string,
    errorColor: string,
    errorWrap: string,
    successTxt: string,
    btnContainer: string,
    btnGrid: string
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  storeName: string,
  storeLogo: File | "",
  logoErrMsg: string,
  errorMsg: string,
  coverImg: File | "",
  logoPreview:  string | null,
  coverPreview: string | null,
  successMsg: string,
  storeId: string,
  token: string,
  headerId: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class StoreHeaderTabController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getStoreTabDetailApiCallId: string = "";
  createHeaderApiCallId: string = ""
  inputCoverImgRef: React.RefObject<HTMLInputElement>;
  logoImgRef:  React.RefObject<HTMLInputElement> ;
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.ReceiveMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
        storeName: "",
        storeLogo: "",
        logoErrMsg: "",
        errorMsg: "",
        coverImg: "",
        logoPreview: "",
        coverPreview: "",
        successMsg: "",
        storeId: "",
        token : "",
        headerId: ""

    };

    this.logoImgRef = createRef();
    this.inputCoverImgRef = createRef();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
  }
  // Customizable Area End

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
        if (apiRequestCallId === this.getStoreTabDetailApiCallId) {
            this.handleStoreTabResponse(responseJson)
        }
        if (apiRequestCallId === this.createHeaderApiCallId) {
            this.handleHeaderRes(responseJson)
        }
    }
  }

  handleHeaderRes = (responseJson: IHeaderResponse) => {
    if(responseJson.data) {
        const message: Message = new Message(getName(MessageEnum.SendMessage));
        message.addData(getName(MessageEnum.ChangeActiveTabStore), { activeTab: "menu", activeScreen: "menu" });
        this.send(message)
    }
    else {
        if ("errors" in responseJson) {
            return this.setState({ errorMsg: responseJson.errors[0] });
        }
    }
  }

  handleStoreTabResponse = (responseJson: IStoreTabResponse) => {
    if(responseJson.data.length > 0) {
        const {logo, cover_image} = responseJson.data[0]?.attributes
        this.setState({
            headerId: responseJson.data[0].id,
            logoPreview : logo,
            coverPreview : cover_image
        })
    }
  }

  handleUploadFiles = (event: React.ChangeEvent<HTMLInputElement>, input:string) => {
    const targetElement = event.target as HTMLInputElement;
    const file: File = (targetElement.files as FileList)[0];
    if (!file) return;
    const fileSize = file.size / (1024 * 1024);
    if (fileSize > 5) {
      if(input === configJSON.storeLogoText) {
        return this.setState({ logoErrMsg: configJSON.imageSizeErrorText });
      }
      else return this.setState({ errorMsg: configJSON.imageSizeErrorText });
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      if (input === configJSON.storeLogoText) {
        this.setState({
          logoPreview: reader.result as string,
          logoErrMsg: "",
          errorMsg: ""
        });
      }
      else {
        this.setState({
          coverPreview: reader.result as string,
          errorMsg: ""
        });
      }
    };
    reader.readAsDataURL(file);

    this.setState(({ [input]: file } as unknown) as Pick<S, keyof S>);
  };

  handleOpenUploadInput = (inputType: string) => {
    if (inputType === configJSON.storeLogoText) {
      if (this.logoImgRef && this.logoImgRef.current) {
        return this.logoImgRef.current.click();
      }
    }
    else {
      if (this.inputCoverImgRef && this.inputCoverImgRef.current) {
        return this.inputCoverImgRef.current.click();
      }
    }
  };

  handleRemoveInputFile = (input: string) => {
    if (input === configJSON.storeLogoText) {
      return this.setState({ logoPreview: null, storeLogo: "" });
    }

    return this.setState({ coverPreview: null, coverImg: "" });
  };

  handleBackBtn = () => {
    this.props.navigation.navigate(configJSON.sellerHomeText, { path: configJSON.createStorePath})
  }

  handleNext = async() => {
    const { storeName, logoPreview, coverPreview } = this.state;
    if (storeName && logoPreview && coverPreview) {
        this.createHeaderInfo()
        const message: Message = new Message(getName(MessageEnum.SendMessage));
        message.addData(getName(MessageEnum.ChangeActiveTabStore), { activeTab: "menu", activeScreen: "menu" });
        this.send(message)
    }
    else {
      this.setState({errorMsg: configJSON.fillAllFieldsText})
    }
  }

  async componentDidMount(): Promise<void> {
    let storeName = await getStorageData("store_name");
    let storeId = await getStorageData("store_id")
    let token = await getStorageData("authToken")
    this.setState({
      storeName: storeName,
      token: token,
      storeId: storeId
    });
    if(storeId) {
        this.getStoreTabDetails(storeId)
    }
  }

  getStoreTabDetails = (id: string) => {
    const headers = { token: this.state.token};
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStoreTabDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + id + "/store_menus");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createHeaderInfo = () => {
    const endPoint = this.state.headerId ? `/store_menus/${this.state.headerId}` : "/store_menus";
    const method = this.state.headerId ? configJSON.patchMethod : configJSON.postMethod
    const headers = { token: this.state.token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createHeaderApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + this.state.storeId + endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    const formData = new FormData();
    formData.append("store_name",this.state.storeName);
    if(this.state.storeLogo) {
      formData.append("logo", this.state.storeLogo);
    }
    if(this.state.coverImg) {
      formData.append("cover_image", this.state.coverImg);
    }
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
 // Customizable Area End
}