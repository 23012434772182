import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { GetCookies } from "../../../framework/src/WebUtilities";

interface IUserAddressList {
    id: string,
    type: string,
    attributes: {
        first_name: string,
        last_name: string,
        address_1: string,
        address_2: string,
        phone_number: string,
        state: string,
        city: string,
        zip_code: string,
        address_type: null,
        account_id: number,
        is_default: boolean
    } 
}

interface IUserAddressRes {
    data: IUserAddressList[]
}
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    userId: string;
    match: any
    // Customizable Area End
}
interface InputFieldErr {
    [key: string]: string
}
interface ICheckUserInfoSuccessResponse2 {
    meta: {
        message: string,

    };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: {
        headerText: string,
        radioBtn: string,
        cellData: string,
        wrapAddress: string,
        userName: string,
        addressData: string,
        buttonContainer: string,
        editContainer: string,
        deleteIcon: string,
        deleteText: string,
        editText: string,
        text: string,
        btnWrap: string,
        saveBtnBox: string,
        saveBtn: string,
        loader: string
    },
    defaultAddress : (address:string) =>  void | undefined
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    showVerifyDeleteModal1: boolean;
    token: string,
    userId: string;
    successMsg1: string;
    errorMessages1: string;
    showAddressForm: boolean;
    userAddresList: any;
    AddressToBeDeleted: string;
    singleAddressInfo: any;
    setAddressId: string,
    loading: boolean,
    addressLoader: boolean,
    setAddress: string,
    accountId: string,
    isDelAddDefault: boolean
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class UserAddressListController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiGetAllBuyerInfoId: string = "";
    apiGetAllBuyerInfoId2: string = "";
    setUserAddressApiCallId: string = ""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.state = {


            token: "",
            userId: "",
            successMsg1: "",
            errorMessages1: "",

            showAddressForm: false,
            userAddresList: [],
            AddressToBeDeleted: "",
            singleAddressInfo: {},
            showVerifyDeleteModal1:false,
            setAddressId: "",
            loading: false,
            addressLoader: false,
            setAddress: '',
            accountId: '',
            isDelAddDefault: false
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("on recieive==>" + JSON.stringify(message));

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            if (apiRequestCallId && responseJson) {

                if (apiRequestCallId === this.apiGetAllBuyerInfoId) {
                    this.userAddressListResponse(responseJson)
                }
                if (apiRequestCallId === this.apiGetAllBuyerInfoId2) {
                    this.userAddressListResponse2(responseJson)
                }
                if (apiRequestCallId === this.setUserAddressApiCallId) {
                    this.handleSetAddressRes(responseJson)
                    this.userAddressList(this.state.userId)
                }
            }
        }

        // Customizable Area End
    }

    // Customizable Area Start
    handleSetAddressRes = (responseJson: any) => {
        if(responseJson.data) {
            this.setState({ setAddress: responseJson.data.attributes.address_1})
            this.props.defaultAddress(responseJson.data.attributes.address_1)
        }
    }

    userAddressListResponse(responseJson: unknown) {
        const successData = responseJson as IUserAddressRes;
        if (successData.data) {
            const filterDefaultAddress = successData.data?.filter((item: any) => {
                if(item.attributes.is_default === true) {
                    return item
                }
            })
            if(filterDefaultAddress.length > 0) {
                this.props.defaultAddress(filterDefaultAddress[0].attributes.address_1)
            }
            else{
                this.props.defaultAddress('')
            }
            this.setState({ userAddresList: successData.data, loading: false});
        }
        else {
            this.setState({ errorMessages1: "Failed to update your Address. Please try again later!" });
        }

    }

    userAddressListResponse2(responseJson: unknown) {
        const successData = responseJson as ICheckUserInfoSuccessResponse2;
        this.setState({showVerifyDeleteModal1:false})
        if (successData.meta.message === "Delivery Address Removed") {
            const accountId = GetCookies('accountId')
            this.userAddressList(accountId)
        }
        else {
            this.setState({ errorMessages1: "Failed to update your Address. Please try again later!" });
        }

    }

    userIdGetter(userID: any) {
        const acountId = GetCookies("accountId")
        this.setState({ userId: acountId })
    }


    getToken = async () => {
        const token = GetCookies("ba");
        this.setState({ token })
    }

    toShowChildComponent = (flag: boolean) => {
        const accountId = GetCookies('accountId')
        this.userAddressList(accountId)
        this.setState({ showAddressForm: flag });
    }

    handleAdNewAddress() {
        this.setState({ showAddressForm: true, loading: false,singleAddressInfo:{}, setAddressId: "" })
    }

    async componentDidMount(): Promise<void> {
        this.userIdGetter(this.props.userId)
        const accountId = GetCookies('accountId')
        this.setState({ userId: accountId})

        this.userAddressList(accountId);
    }

    userAddressList = (id: string) => {
        if(!this.state.addressLoader){
            this.setState({loading: true})
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        )
        this.apiGetAllBuyerInfoId = requestMessage.messageId

        const headers = {
            token: this.state.token
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers),
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/account_block/accounts/" + `${id}` + "/user_delivery_addresses")


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetUserProfile,
        )
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    deleteAddress() {
        const headers = {
            token: this.state.token
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        )
        this.apiGetAllBuyerInfoId2 = requestMessage.messageId
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/account_block/accounts/" + this.props.userId + "/user_delivery_addresses/" + this.state.AddressToBeDeleted)

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers),
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod,
        )
        runEngine.sendMessage(requestMessage.id, requestMessage)

    }

    editAddress(addressInfo: any) {
        this.setState({ loading:false,showAddressForm: true, singleAddressInfo: addressInfo })
    }
    
    handleDelete(){
        this.deleteAddress()
    }
    handleCloseDeleteModal(){
        this.setState({showVerifyDeleteModal1:false, isDelAddDefault: false })

    }

    handleOpenVerifyDeleteModal = (id:any, isDefault: boolean) => {
        this.setState({showVerifyDeleteModal1:true,AddressToBeDeleted: id, isDelAddDefault : isDefault })
    }
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (prevState.showAddressForm !== this.state.showAddressForm) {
            this.userAddressList(this.state.userId)
        }
    }

    handleChangeAdd = async(id: string) => {
        this.setState({ setAddressId: id, addressLoader: true});
        this.setUserAddress(id)
    }

    setUserAddress = async (id: string) => {
        const accountId = GetCookies("accountId")
        const headers = {
          "Content-Type": configJSON.contentTypeApiUpdateUser,
          token: GetCookies("ba"),
        };
        const httpBody = {
          "data": {
            "attributes": {
                "is_default": true
            } 
          }
        }
        const setMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.setUserAddressApiCallId = setMessage.messageId;
        setMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `/account_block/accounts/${accountId}/user_delivery_addresses/${id}`
        );
        setMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        setMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        setMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.patchMethod
        );
        runEngine.sendMessage(setMessage.id, setMessage);
      }

      handleAddress = (address:string) => {
        this.props.defaultAddress(address)
      }
    
}

