// Customizable Area Start
import React from "react";
import CreateDeliveryRequestController, {
  Props,
  configJSON,
} from "./CreateDeliveryRequestController.web";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  BoxNoScroll,
  CustomFormControl,
  CustomizeBrandInput,
  FormControlTwo,
  MenuItemTwo,
  OrangeButton,
} from "../../dashboard/src/CustomizedElementStyle";
import { successIcon } from "./ProductDealsController.web";
import CloseIcon from "@material-ui/icons/Close";
import { IWarehouseList } from "./StockLogsController.web";
import { Form, Formik } from "formik";
// Customizable Area End

class CreateDeliveryRequest extends CreateDeliveryRequestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Formik
        initialValues={{
          name: this.state.warehouseName,
          addressOne: this.state.addressLineOne,
          addressTwo: this.state.addressLineTwo,
          orderNumber: this.state.orderNo
        }}
        enableReinitialize={true}
        validationSchema={this.schema}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={this.handleSubmitBtn}
        data-test-id="formik"
      >
        {({errors,
          touched
        }) => (
          <Form translate={undefined} 
          >
    <Box className={classes.newTabText}>
      {configJSON.fulfilledByPartnerLabel}
    </Box>
    <BoxNoScroll style={webStyle.noScrollBox}>
      <Box data-test-id="createDeliveryReq" className={classes.newWrapper}>
        <Box className={classes.newHeaderWrapper}>
          <Box
            className={classes.newHeaderText}
          >
            {configJSON.wareHouseAddressLabel}
          </Box>
        </Box>
        <Box className={classes.newFieldWrapper}></Box>
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
          <FormControlTwo fullWidth={true}>
                <Box className={classes.nameBox}>{configJSON.warehouseName}</Box>
                <Select
                  data-test-id="warehouse-dropdown"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  placeholder={configJSON.selectWarehouseText}
                  style={webStyle.selectField}
                  value={this.state.warehouseName}
                  onChange={this.handleWarehouseName}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: webStyle.selectWarehouse
                    }
                  }}
                  displayEmpty
                >
                  <MenuItemTwo value="" disabled >{configJSON.selectWarehouseText}</MenuItemTwo>
                  {this.state.warehouseNameList.length > 0 && this.state.warehouseNameList.map((item: IWarehouseList) => <MenuItemTwo data-test-id="warehouselist" value={item.attributes.warehouse_name}>{item.attributes.warehouse_name}</MenuItemTwo>)}
                </Select>
              </FormControlTwo>
              {this.displayError(touched.name, errors.name)}
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomFormControl className={classes.newFieldContainer}>
              <CustomizeBrandInput
                data-test-id="days"
                label={configJSON.orderNumberLabel}
                id="outlined-start-adornment"
                value={this.state.orderNo}
                className={classes.root}
                inputProps={{
                  style: webStyle.inputField
                }}
                onChange={this.handleOrderNumber}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
              />
            </CustomFormControl>
            {this.displayError(touched.name, errors.orderNumber)}
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.newTopMargin}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomFormControl className={classes.newFieldContainer}>
              <CustomizeBrandInput
                data-test-id="addressOne"
                label={configJSON.addressOne}
                id="outlined-start-adornment"
                value={this.state.addressLineOne}
                onChange={this.handleAddressLineOne}
                className={classes.root}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 100
                }}
              />
            </CustomFormControl>
            {this.displayError(touched.name, errors.addressOne)}
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomFormControl className={classes.newFieldContainer}>
              <CustomizeBrandInput
                data-test-id="addressTwo"
                label={configJSON.addressTwo}
                id="outlined-start-adornment"
                className={classes.root}
                value={this.state.addressLineTwo}
                onChange={this.handleAddressLineTwo}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 100
                }}
              />
            </CustomFormControl>
            {this.displayError(touched.name, errors.addressTwo)}
          </Grid>
          {this.state.commonErr.length > 0 ? 
          this.state.commonErr.map((error:string) => {
            return(
              <Typography data-test-id="commonErr" className={classes.commonErr}>{error}</Typography>
            )
          })
          : ''}
        </Grid>
        <Grid container spacing={2} className={classes.newButtonContainer}>
          <OrangeButton
            data-test-id="submitBtn"
            type="submit"
            className={classes.submitBtn}
          >
            Submit
          </OrangeButton>
        </Grid>
      </Box>
    </BoxNoScroll>
    {this.state.isSubmit && (
      <Modal
        open={this.state.isSubmit}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className={classes.mainModalWrapper}>
          <Box className={classes.blurredBckgnd}></Box>
          <Box
            className={classes.modalContainerMain}
            style={webStyle.modalBoxOne}
          >
            <Box
              style={webStyle.modalBoxTwo}
            >
              <Box
                style={webStyle.modalBoxTwo}
              >
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <CloseIcon data-test-id="closeIcon" onClick={this.handleCloseIcon} />
                </IconButton>
              </Box>
              <Box
                className={classes.imageWrapperMain}
                style={webStyle.boxOne}
              >
                <Box className={classes.imageContainer}>
                  <img src={successIcon} alt="success image" />
                </Box>
                <Box
                  className={classes.modalText}
                >
                  {configJSON.reqSubmittedText}
                </Box>
                <Box
                  className={classes.description}
                >
                  {configJSON.modalReqDesc}
                </Box>
                <Box
                  className={classes.lastText}
                >
                  {configJSON.redirectedText}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    )}
    </Form>
        )}
        </Formik>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles = {
  newTabText: {
    fontFamily: `"Lato", sans-serif`,
    padding: "20px 0px 15px 0px",
    fontSize: "18px",
    fontWeight: 400,
  },
  newWrapper: {
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as const,
    padding: "30px",
    overflowY: "auto" as const,
    overflowX: "hidden" as const,
    position: "relative" as const,
    background: "white",
  },
  newHeaderWrapper: {
    width: "100%",
    flexDirection: "row" as const,
    display: "flex",
    flexWrap: "wrap" as const,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  newHeaderText: {
    fontSize: "27.1px",
    fontWeight: 700 as const,
    fontFamily: `"Lato", sans-serif` as const,
  },
  newFieldWrapper: {
    width: "100%",
    height: 50,
  },
  newFieldContainer: {
    width: "100%",
    paddingTop: "10px",
  },
  newTopMargin: {
    marginTop: "30px",
  },
  newButtonContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    marginTop: "100px",
  },
  submitBtn: {
    borderRadius: "8px",
    padding: "8px 23px",
    fontFamily: `"Montserrat", sans-serif`,
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "capitalize" as const,
    marginRight: "20px",
  },
  mainModalWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    position: "relative" as const,
  },
  blurredBckgnd: {
    width: "100%",
    zIndex: -1,
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.1)",
    position: "absolute" as const,
    backdropFilter: "blur(8px)",
  },
  modalContainerMain: {
    borderRadius: "10px",
    maxWidth: "450px",
    position: "relative" as const,
    width: "450px",
    display: "flex",
    padding: "40px",
  },
  imageWrapperMain: {
    flexDirection: "column" as const,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  imageContainer: {
    marginBottom: "24px",
    height: "100px",
    width: "100px",
  },
  modalText: {
    fontFamily: `"Lato", sans-serif` as const,
    marginBottom: "10px",
    fontWeight: "bold" as const,
    fontSize: "20px",
    color: "#565656",
  },
  description: {
    fontFamily: `"Helvetica", sans-serif` as const,
    marginBottom: "10px",
    fontSize: "12px",
    color: "#565656",
  },
  lastText : {
    fontFamily: `"Helvetica", sans-serif`,
    marginBottom: "70px",
    fontSize: "12px",
    color: "#565656",
  },
  nameBox: { 
    color: "#9a9a9a", 
    fontFamily: `"Lato", sans-serif`, 
    fontWeight: 600, 
    fontSize: 12, 
    marginBottom: 6,
    marginTop: '-10px' 
  },
  fielderror: {
    fontFamily: "Lato, sans-serif",
    color: "red",
    textAlign: "left" as const,
    fontSize: "12px",
    marginTop: "5px"
  },
  commonErr: {
    fontFamily: "Lato, sans-serif",
    color: "red",
    textAlign: "left" as const,
    fontSize: "12px",
    margin: "20px 0px 0 10px"
  },
  root: {
    "& .MuiInputBase-input": {
      fontSize: 14,
      fontFamily: '"Lato", sans-serif',
      fontWeight: 500
    },
    "& .MuiInput-formControl": {
      border: "1px solid #b1b3b5",
      margin: 0,
      padding: "5px 0px 6px 10px",
      borderRadius: "5px"
    },
    "& .MuiInputLabel-root": {
      top: -20,
      left: 0,
      color: "#9a9a9a",
      fontFamily: `"Lato", sans-serif`,
      fontWeight: 600,
      fontSize: 16
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" 
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none"
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: 12 
    }
  }
};

const webStyle = {
  selectWarehouse: {
    background: "white",
    boxShadow: "none",
    borderRadius: 5,
    marginTop: "5px",
    padding: '4px 0',
    maxHeight: "400px",
    overflowY: "auto" as const,
    border: '1px solid #b1b3b5',
  },
  selectField: {
    padding:'4px 0', 
    border: '1px solid #b1b3b5'
  },
  inputField: {
    border: "1px solid #b1b3b5 !important"
  },
  noScrollBox: { 
    paddingBottom: 20.5, 
    overflowX: "hidden" as const,
    overflowY: "auto" as const
  },
  modalBoxOne: {
    background: "white",
    height: "350px",
    border: "1px solid black",
  },
  modalBoxTwo: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column" as const
  },
  boxOne: { 
    height: "100%" 
  }
}


const withStyleCreateDelivery = withStyles(styles)(CreateDeliveryRequest);
export { withStyleCreateDelivery };
export default CreateDeliveryRequest

// Customizable Area End
