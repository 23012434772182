// Customizable Area Start
import { Divider, Grid, Typography, withStyles } from "@material-ui/core";
import React from "react";
import PaymentController, { Props, configJSON } from "./PaymentController.web";
import { address, align, bottomContainer, detail, finalPriceHeader, horizontal, nextBtn, priceContainer, text } from "../../catalogue/src/ShoppingCart/commomstyles";
import { paymentMethods } from "../../catalogue/src/assets";
import { OrangeButton } from "../../dashboard/src/CustomizedElementStyle";
import { Loader2 } from "../../../components/src/Loader.web";
// Customizable Area End

class Payment extends PaymentController {
    constructor(props:Props){
        super(props)
    }
    // Customizable Area Start
    // Customizable Area End
    
    render() {
        // Customizable Area Start
        const {classes} = this.props;
        const { total_fees, total_tax, final_price } = this.props.priceDetails
        // Customizable Area End
        return(
            // Customizable Area Start
            <>
                <Grid
                  container
                  className={classes.orderContainer}
                >
                  <Typography className={classes.preview}>
                    {configJSON.orderSummaryLabel}
                  </Typography>
                  <Typography
                    className={classes.description}
                    style={webStyle.desc}
                  >
                    {""}
                  </Typography>
                </Grid>
                <Grid container className={classes.priceSec}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={`${classes.textOne} ${classes.bottomSec}`}
                  >
                    <Grid container>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography className={classes.priceHeaderOne}>
                      {configJSON.totalPrice}
                    </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} className={classes.price}>
                    <Typography className={classes.priceHeaderOne}>
                    {configJSON.AedText} {total_fees}
                    </Typography>
                    </Grid>
                    </Grid>
                    </Grid>
                    <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={`${classes.textOne}`}
                  >
                    <Grid container>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Typography className={classes.priceHeaderOne}>
                      {configJSON.tax}
                    </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} className={classes.price}>
                      <Typography className={classes.priceHeaderOne}>
                      {configJSON.AedText} {total_tax}
                    </Typography>
                      </Grid>
                      </Grid> 
                      </Grid>
                    <Divider className={classes.horizontalLine} />
                    
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.textOne}
                    style={webStyle.bottom}
                  >
                    <Grid container>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Typography
                      className={classes.priceHeaderOne}
                      style={webStyle.final}
                    >
                      {configJSON.total}
                    </Typography>
                    </Grid>
                    <Grid item xs={9} sm={9} md={9} lg={9} className={classes.price}>
                    <Typography
                      className={classes.finalPrice}
                      style={webStyle.final}
                    >
                      {configJSON.AedText} {final_price}
                    </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                </Grid>
                {this.state.loader ?
                <Loader2 loading={this.state.loader} data-test-id="loader"/>
                :
                <OrangeButton onClick={this.handlePaymentButton} disabled={!total_fees} className={classes.payBtn}>
                  {configJSON.paymentNowBtn}
                </OrangeButton>
                }
                <img
                  src={paymentMethods}
                  alt="payment_icon"
                  className={classes.paymentIcons}
                />
            </>
            // Customizable Area End
        )
    }
}

// Customizable Area Start

const webStyle = {
  desc: { 
    fontSize: "14px" 
  },
  final: { 
    margin: "10px 0px"
  },
  bottom: {
    display: "flex",
    alignItems: "flex-end",
  }
}

const styles = {
    orderContainer: {
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "flex-start",
        justifyContent: "center",
    },
    paymentIcons: {
        marginTop: "5px" ,
        width:'100%'
    },
    preview: {
        color: "#33333a",
        fontSize: "24px",
        fontFamily: `"Lato", sans-serif`,
        fontWeight: 600 as const,
        "@media (max-width: 960px)": {
          fontSize: "22px",
        },
        "@media (max-width: 600px)": {
          fontSize: "20px",
        },
        "@media (max-width: 460px)": {
          fontSize: "18px",
        },
    },
    description: address,
    payBtn: nextBtn,
    priceSec: priceContainer,
    horizontalLine: horizontal,
    textOne: text,
    priceHeaderOne: detail,
    price: align,
    bottomSec: bottomContainer,
    finalPrice: finalPriceHeader,
}

export { Payment };
export default withStyles(styles)(Payment);

// Customizable Area End



