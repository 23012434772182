// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { earPods, galaxyPhone } from "../../landingpage/src/assets";
import { GetCookies } from "../../../framework/src/WebUtilities";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
export const configJSON = require("../src/config.js");
import { Image4, Image5, Image6, Image7 } from "../../catalogue/src/assets";

export interface ICategoryList {
  id: string,
  attributes: {
      category: string
  }
}

export interface IDiscountList {
  id: string,
  attributes: {
      discount: string
  }
}

export interface ICategories {
  id: string,
  attributes: {
      name: string,
      productImg : string
  }
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    sideBar: string;
    sidebarHeading: string;
    wrapper: string;
    checkboxOne: string;
    checkboxFormControlOne: string;
    sidebarTitle: string;
    discount: string;
    heading: string,
    rightSide: string,
    viewBtn: string,
    headingContainer: string,
    trendingProd: string,
    paddingTop: string,
    cardPadding: string,
    discountedProducts: string,
    cardPaddingOne: string,
    headingOne: string,
    boxStyle: string,
    shopContainer: string,
    categoryContainer: string,
    shopText: string,
    sliderContainer: string,
    contentWrapper: string,
    categoryImages: string,
    categoryName: string,
    categoryBox: string,
    bookImage: string,
    bottomText: string,
    bottomSlider: string,
    bookDes: string,
    offText: string,
    sliderWrapper: string,
    main: string,
    products: string,
    categoryMargin: string,
    discountOne: string,
    colorsContainer: string,
    colorWrapper: string,
    bookMargin: string,
  };
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  categoryIds: string[],
  categoryList: ICategoryList[],
  discountIds: string[],
  discountList: IDiscountList[],
  categories: ICategories[],
  token: string,
  selectedCategory: string,
  selectedBook: string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerStoreCategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.NavigationPayLoadMessage), getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      categoryIds : [],
      categoryList: [],
      discountIds: [],
      discountList: [],
      categories: [],
      token: '',
      selectedCategory: '',
      selectedBook: ''
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  categoryList = [
    { id: '1', attributes: { category: 'All Categories' } },
    { id: '2', attributes: { category: 'Electronics and Mobiles' } },
    { id: '3', attributes: { category: 'Fashion' } },
    { id: '4', attributes: { category: 'Home & Kitchen' } },
    { id: '5', attributes: { category: 'Pet Supplies' } },
    { id: '6', attributes: { category: 'Toys & Games' } },
    { id: '7', attributes: { category: 'Baby Products' } },
    { id: '8', attributes: { category: 'Office Supplies & Tools' } },
    { id: '9', attributes: { category: 'Beauty & Health' } }
  ];

  discountList = [
    { id: "1", attributes: { discount: "All Deals" } },
    { id: "2", attributes: { discount: "10% off or more" } },
    { id: "3", attributes: { discount: "25% off or more" } },
    { id: "4", attributes: { discount: "50% off or more" } },
    { id: "5", attributes: { discount: "70% off or more" } },
  ];

  colors = [
    "#7877FF",
    "#FF7979",
    "#B2E130",
    "#FFCB6A",
    "#646464",
    "#4CB28D",
    "#E84FED",
    "#51B2EF",
  ];

  latestArrivals = [
    {id : '1', attributes: { productName: 'OPPO A31 (Mystery Black, 6GB RAM, 128GB Storage)', img: galaxyPhone, offprice:'2099.00', price: '3500', discount: '40%', rating:'4.8', number: '26'  }},
    {id : '2', attributes: { productName: 'OPPO A31 (Mystery Black, 6GB RAM, 128GB Storage)', img: galaxyPhone, offprice:'2099.00', price: '3500', discount: '40%', rating:'4.8', number: '26'  }},
    {id : '3', attributes: { productName: 'OPPO A31 (Mystery Black, 6GB RAM, 128GB Storage)', img: galaxyPhone, offprice:'2099.00', price: '3500', discount: '40%', rating:'4.8', number: '26'  }},
    {id : '4', attributes: { productName: 'OPPO A31 (Mystery Black, 6GB RAM, 128GB Storage)', img: galaxyPhone, offprice:'2099.00', price: '3500', discount: '40%', rating:'4.8', number: '26'  }}
  ]

  popularProducts = [
    {id : '1', attributes: { productName: 'Samsung 65 Inch Crystal UHD 4K Smart TV 2023 65CU6000 Black', img: Image4, offprice:'2099.00', price: '3500', discount: '40%', rating:'4.8', number: '26'  }},
    {id : '2', attributes: { productName: 'Samsung 65 Inch Crystal UHD 4K Smart TV 2023 65CU6000 Black', img: Image5, offprice:'2099.00', price: '3500', discount: '40%', rating:'4.8', number: '26'  }},
    {id : '3', attributes: { productName: 'TCL 65 Inch Crystal UHD 4K Smart TV 2023 65CU6000 Black', img: Image6, offprice:'2099.00', price: '3500', discount: '40%', rating:'4.8', number: '26'  }},
    {id : '4', attributes: { productName: 'Hisense 65 Inch Crystal UHD 4K Smart TV 2023 65CU6000 Black', img: Image7, offprice:'2099.00', price: '3500', discount: '40%', rating:'4.8', number: '26'  }}
  ]

  discountedProducts = [
    {id : '1', attributes: { productName: 'syngenta Advion Cockroach Gel Bait 30 g', img: Image4, offprice:'62.0', price: '150', discount: '40%', rating:'4.8', number: '26'  }},
    {id : '2', attributes: { productName: 'ALMA Shoe Rack 5 Shelves with Stainless Steel Frame', img: Image5, offprice:'2099.00', price: '3500', discount: '40%', rating:'4.8', number: '26'  }},
    {id : '3', attributes: { productName: 'Gold Pro Standard Cutlery Set of Spoons, Knives and Forks', img: Image6, offprice:'2099.00', price: '3500', discount: '40%', rating:'4.8', number: '26'  }},
    {id : '4', attributes: { productName: 'Morpho Goose Feather Pillow For Queen Bed', img: Image7, offprice:'2099.00', price: '3500', discount: '40%', rating:'4.8', number: '26'  }}
  ]

  allProducts = [
    {id : '1', attributes: { productName: 'syngenta Advion Cockroach Gel Bait 30 g', img: Image4, offprice:'62.0', price: '150', discount: '40%', rating:'4.8', number: '26'  }},
    {id : '2', attributes: { productName: 'ALMA Shoe Rack 5 Shelves with Stainless Steel Frame', img: Image5, offprice:'2099.00', price: '3500', discount: '40%', rating:'4.8', number: '26'  }},
    {id : '3', attributes: { productName: 'Gold Pro Standard Cutlery Set of Spoons, Knives and Forks', img: Image5, offprice:'2099.00', price: '3500', discount: '40%', rating:'4.8', number: '26'  }},
    {id : '4', attributes: { productName: 'Morpho Goose Feather Pillow For Queen Bed', img: Image6, offprice:'2099.00', price: '3500', discount: '40%', rating:'4.8', number: '26'  }},
    {id : '5', attributes: { productName: 'Morpho Goose Feather Pillow For Queen Bed', img: Image7, offprice:'2099.00', price: '3500', discount: '40%', rating:'4.8', number: '26'  }},
    {id : '1', attributes: { productName: 'syngenta Advion Cockroach Gel Bait 30 g', img: Image4, offprice:'62.0', price: '150', discount: '40%', rating:'4.8', number: '26'  }},
    {id : '2', attributes: { productName: 'ALMA Shoe Rack 5 Shelves with Stainless Steel Frame', img: Image5, offprice:'2099.00', price: '3500', discount: '40%', rating:'4.8', number: '26'  }},
    {id : '3', attributes: { productName: 'Gold Pro Standard Cutlery Set of Spoons, Knives and Forks', img: Image6, offprice:'2099.00', price: '3500', discount: '40%', rating:'4.8', number: '26'  }},
    {id : '4', attributes: { productName: 'Morpho Goose Feather Pillow For Queen Bed', img: Image7, offprice:'2099.00', price: '3500', discount: '40%', rating:'4.8', number: '26'  }},
    {id : '5', attributes: { productName: 'Morpho Goose Feather Pillow For Queen Bed', img: Image4, offprice:'2099.00', price: '3500', discount: '40%', rating:'4.8', number: '26'  }}
  ]

  categories = [
    { id: "1", attributes: { name: "Headphones", productImg: earPods } },
    { id: "2", attributes: { name: "Kitchen", productImg: earPods  } },
    { id: "3", attributes: { name: "Pet Products", productImg:  earPods } },
    { id: "4", attributes: { name: "iPhone Accessories", productImg:earPods   } },
    { id: "5", attributes: { name: "Grocery", productImg: earPods  }},
    { id: "6", attributes: { name: "Beauty" , productImg: earPods }},
    { id: "7", attributes: { name: "Music",productImg: earPods  }},
    { id: "8", attributes: { name: "Electronics", productImg: earPods  } },
  ]

  settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 2,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  bookData = [
    { id: '1',image: earPods, title: "Product 1" },
    { id: '2',image: earPods, title: "Product 2" },
    { id: '3',image: earPods, title: "Product 3" },
    { id: '4',image: earPods, title: "Product 4" },
    { id: '5',image: earPods, title: "Product 5" },
    { id: '6',image: earPods, title: "Product 6" },
    { id: '7',image: earPods, title: "Product 7" },
    { id: '8',image: earPods, title: "Product 8" },
    { id: '9',image: earPods, title: "Product 9" }
  ];

  bookSettings = {
    marginTop: "20px",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      }
    ]
  };

  handleCheckBox = (id: string) => {
    const { categoryIds } = this.state;

    const index = categoryIds.indexOf(id);

    if (index === -1) {
        this.setState({ categoryIds: [...categoryIds, id] });
    } else {
        const updatedCategoryIds = [...categoryIds];
        updatedCategoryIds.splice(index, 1);
        this.setState({ categoryIds: updatedCategoryIds });
    }
  };

  handleDiscountCheckBox = (id: string) => {
    const { discountIds } = this.state;

    const index = discountIds.indexOf(id);

    if (index === -1) {
        this.setState({ discountIds: [...discountIds, id] });
    } else {
        const updatedDiscountIds = [...discountIds];
        updatedDiscountIds.splice(index, 1);
        this.setState({ discountIds: updatedDiscountIds });
    }
  };

  handleCategoryClick = (id: string) => {
    this.setState({ selectedCategory : id})
  }

  handleBookClick = (id: string) => {
    this.setState({ selectedBook : id})
  }

  async componentDidMount(): Promise<void> {
    const token = GetCookies("ba")
    this.setState({categoryList: this.categoryList, discountList: this.discountList, token: token })
  }

  // Customizable Area End
}
