// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activePanel: string;
  activeScreen: string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class PromotionsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.ReceiveMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      activePanel: configJSON.dealsMenu,
      activeScreen: configJSON.dealsMenu,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}
