import React, { forwardRef }  from "react";
import { Box, BoxProps } from "@material-ui/core";
import InputBase, { InputBaseProps } from "@material-ui/core/InputBase";
import { createStyles, withStyles } from "@material-ui/styles";
// import "typeface-lato";

interface CustomInputBaseProps extends InputBaseProps {}
interface CustomBoxProps extends BoxProps {}

const styles = createStyles({
  root: {
    fontSize: 24,
    fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    textAlign: "center",
    height: "52px",
    boxShadow: "0px 0px 15px 1px rgba(0,0,0,0.1)",
    borderRadius: "5px",
    "& input": {
      textAlign: "center"
    },
    "& input::placeholder": {
      opacity: 1,
      fontWeight: 600
    }
  }
});
const boxStyle = createStyles({
  root: {
    fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,  }
});
const clickableBoxStyle = createStyles({
  root: {
    fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    "&:hover": {
      cursor: "pointer"
    }
  }
});

export const OtpInput = withStyles(styles)(
  forwardRef((props: CustomInputBaseProps, ref) => (
    <InputBase {...props} inputRef={ref} />
  ))
);
export const CustomBox = withStyles(boxStyle)(
  forwardRef((props: CustomBoxProps, ref) => (
    <Box {...props} {...{ ref: ref }} />
  ))
);
export const ClickableBox = withStyles(clickableBoxStyle)(
  forwardRef((props: CustomBoxProps, ref) => (
    <Box {...props} {...{ ref: ref }} />
  ))
);
