import React from "react";

// Customizable Area Start
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, IconButton, CircularProgress } from "@material-ui/core";
import { BoxNoScroll, OrangeButton } from "../CustomizedElementStyle";
import { cross } from "../assets";
import EditOutlined from "@material-ui/icons/EditOutlined";
import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined";
export const configJSON = require("../config");
import { ToastContainer } from "react-toastify";

// Customizable Area End

import WelcomeController, { Props } from "./WelcomeController";

class Welcome extends WelcomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
      <ToastContainer/>
        <Box sx={{ fontFamily: `"Lato", sans-serif`, padding: "20px 0px 15px 0px", fontSize: "18px", fontWeight: 400 }}>Store Management</Box>
        <BoxNoScroll style={webStyle.boxScroll}>
          <Box style={webStyle.bigBox}>
            <Box style={webStyle.mainBorder}>
              <Box style={webStyle.border}>{configJSON.createStore}</Box>
              <OrangeButton data-test-id="create_store" style={webStyle.btn} onClick={() => this.handleGoToCreateStorePage()}>
                {configJSON.createNewStore}
              </OrangeButton>
            </Box>
            {this.state.isLoading ? (
              <>
                <Box width={"90%"} display={"flex"} justifyContent={"center"} alignContent={"center"}>
                  <CircularProgress />
                </Box>
              </>
            ) : (
              <>
                {this.state.getStoreDataList.length > 0 ? (
                  <Paper
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      marginTop: "25px",
                      marginBottom: "25px"
                    }}
                  >
                    <TableContainer style={{ maxHeight: "100%", overflowX: "auto" }}>
                      <Table aria-label="simple table" stickyHeader>
                        <CustomizeTableHead>
                          <TableRow style={{ fontWeight: 600 }}>
                            <CustomizeTableHeader>Store Name</CustomizeTableHeader>
                            <CustomizeTableHeader>Store Year</CustomizeTableHeader>
                            <CustomizeTableHeader></CustomizeTableHeader>
                            <CustomizeTableHeader></CustomizeTableHeader>
                          </TableRow>
                        </CustomizeTableHead>
                        <TableBody>
                          {this.state.getStoreDataList.map((item: any) => (
                            <TableRow key={item.id}>
                              <CustomizeTableCell>{item.attributes.store_name}</CustomizeTableCell>
                              <CustomizeTableCell>{item.attributes.store_year}</CustomizeTableCell>
                              <CustomizeTableCell>
                                <IconButton id="editIcon" aria-label="toggle password visibility" edge="end" onClick={() => this.onEditStore(item)}>
                                  <EditOutlined />
                                </IconButton>
                              </CustomizeTableCell>
                              <CustomizeTableCell>
                                <IconButton id="deleteIcon" aria-label="toggle password visibility" edge="end" onClick={() => this.deleteStoreData(item.attributes.id)}>
                                  <DeleteForeverOutlined />
                                </IconButton>
                              </CustomizeTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                ) : (
                  <Box style={webStyle.boxHeight}>
                    <img src={cross} />
                    <Box style={webStyle.welcomeHeading}>{configJSON.welcome}</Box>
                    <Box style={webStyle.letCreate}>{configJSON.letCreateStore}</Box>
                  </Box>
                )}
              </>
            )}
          </Box>
        </BoxNoScroll>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  width: "100%",
  "& .MuiInputBase-root": {
    height: "40px"
  },
  "& input[type=number]": {
    "-moz-appearance": "textfield"
  }
});

const webStyle = {
  boxScroll: {
    paddingBottom: 20.5,
    overflow: "auto" as "auto"
  },

  bigBox: {
    borderRadius: "8px",
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    padding: "30px",
    overflowY: "auto" as "auto",
    overflowX: "hidden" as "hidden",
    position: "relative" as "relative",
    background: "white"
  },

  mainBorder: {
    width: "100%" as "100%",
    display: "flex" as "flex",
    flexDirection: "row" as "row",
    flexWrap: "wrap" as "wrap",
    justifyContent: "space-between" as "space-between",
    alignItems: "center" as "center",
    marginBottom: "10" as "10",
    paddingBottom: "22px" as "22px",
    borderBottom: "1px solid #EEEEEE" as "1px solid #EEEEEE"
  },

  border: {
    fontSize: "27.1px",
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`
  },

  boxHeight: {
    width: "100%",
    height: 600,
    display: "flex" as "flex",
    alignItems: "center" as "center",
    flexDirection: "column" as "column",
    paddingTop: "65px"
  },

  welcomeHeading: {
    color: "#33333A",
    margin: "14px 0px",
    fontSize: "20px",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 400
  },

  letCreate: {
    color: "#33333A",
    fontSize: "20px",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 700,
    marginBottom: "15px"
  },

  btn: {
    color: "white",
    width: "fit-content",
    fontSize: "17px",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 700,
    borderRadius: "8px",
    textTransform: "none" as "none",
    backgroundColor: "#FF6008"
  },

  welcomeShowData: {
    display: "flex" as "flex",
    justifyContent: "space-between" as "space-between",
    textAlign: "center" as "center"
  },

  heading: {
    width: "220px"
  },

  iconBox: {
    width: "350px",
    display: "flex" as "flex",
    justifyContent: "space-between" as "space-between"
  },

  secondHeading: {
    width: "150px"
  },

  edit: {
    width: "150px"
  },

  delete: {
    width: "150px"
  },
  tabelHeadeing: {
    fontWeight: 600,
    fontSize: "15px"
  }
};
const CustomizeTableCell = styled(TableCell)({
  "&.MuiTableCell-body": {
    border: "none",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    fontWeight: 400,
    color: "#697a8d",
    textAlign: "center" as const,
    padding: "16px 12px"
  }
});
const CustomizeTableHeader = styled(TableCell)({
  "&.MuiTableCell-head": {
    fontWeight: 600,
    background: "#f2f3f4",
    fontFamily: `"Lato-Heavy", sans-serif`,
    fontSize: "14px",
    color: "#33333a",
    textAlign: "center" as const
  }
});
const CustomizeTableHead = styled(TableHead)({
  "&.MuiTableHead-root": {
    border: "1px solid rgba(151,151,151,0.33)"
  }
});
export default Welcome;
// Customizable Area End
