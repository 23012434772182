import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
export const configJSON = require("../src/config.js");

interface DocumentsData {
  id: any,
  warehouse_type: string,
  warehouse_name: string,
  warehouse_address: string,
  created_at: string,
  updated_at: string,
  contact_number: any,
  contact_person: any,
  processing_days: any,
  account_id: any

}
interface SuccessResponse {
  seller: { reviews: DocumentsData[],meta:{} }
  product: { reviews: DocumentsData[],meta:{} }
  delivery: { reviews: DocumentsData[],meta:{} }
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    root: string,
    averageRating: string,
    averageRatingTitle: string,
    header: string,
    progressBar: string,
    progreeBarContainers: string,
    starIcon: string,
    progressBarOrange: string,
    reviewListContainer: string,
    happinessContainer: string,
    userRatingHeading: string,
    happinessWrapper: string,
    nameDate: string,
    ratingTitle: string,
    ratingDescription: string,
    ratingStar: string,
    reviewListWrraper: string,
    filterlistWrapper: string,
    checkBoxFormControl: string,
    checkBox: string,
    filterText: string,
    headerStyle: string
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  sellerRatingList: any[],
  deliveryRatingList: any[],
  catalogRatingList: any[],
  sellerAverageRating: any,
  deliveryAverageRating: any,
  catalogAverageRating: any,
  selectedFilters: any,
  loading: boolean,
  token: string,
  filterRatingAnchorEl: null | HTMLElement;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class FulFilledByEzzyOrderManagementController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiHappinessratingList: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      loading: false,
      token: '',
      sellerAverageRating: {},
      deliveryAverageRating: {},
      catalogAverageRating: {},
      sellerRatingList: [],
      deliveryRatingList: [],
      catalogRatingList: [],
      selectedFilters: [],
      filterRatingAnchorEl: null,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.apiHappinessratingList) {
        this.handleHappinessRatinglistApiCallResponse(responseJson, errorJson)
      }

    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const authToken = await getStorageData("authToken");
    this.setState({ token: authToken })
    await this.handleHappinessRatingListApiCall("");
  }

  handleFilterButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ filterRatingAnchorEl: event.currentTarget });
  };

  handleCloseFilterRating = () => {
    this.setState({ filterRatingAnchorEl: null });
  };

  ratePerformance(rating: number): string {
    if (rating < 0 || rating > 5) {
      return "Invalid rating.";
    } else if (rating >= 0 && rating <= 1) {
      return "Very Poor";
    } else if (rating > 1 && rating <= 2) {
      return "Poor";
    } else if (rating > 2 && rating <= 3) {
      return "Good";
    } else if (rating > 3 && rating <= 4) {
      return "Very Good";
    } else if (rating > 4 && rating <= 5) {
      return "Excellent";
    }
    return "";
  }

  handleCheckboxChange = (value: number) => {
    const { selectedFilters } = this.state;
    const updatedFilters = selectedFilters.includes(value)
      ? selectedFilters.filter((filter:any) => filter !== value)
      : [...selectedFilters, value];

    this.setState({ selectedFilters: updatedFilters });
    const filterParams = updatedFilters.length > 0
    ? `?filter_by[]=${updatedFilters.join('&filter_by[]=')}`
    : '';
    this.handleHappinessRatingListApiCall(filterParams);
  };

  handleHappinessRatinglistApiCallResponse = (responseJson: SuccessResponse, errorJson: any) => {
    this.setState({ loading: false })
    if ("message" in responseJson || errorJson) {
      this.setState({ sellerRatingList: [] });
      this.setState({ catalogRatingList: [] });
      this.setState({ deliveryRatingList: [] });
      this.parseApiCatchErrorResponse(errorJson);

    } else {
      this.setState({
        sellerAverageRating: responseJson?.seller?.meta || null,
        deliveryAverageRating: responseJson?.delivery?.meta || null,
        catalogAverageRating: responseJson?.product?.meta || null,
        sellerRatingList: responseJson?.seller?.reviews || [],
        deliveryRatingList:  responseJson?.delivery?.reviews || [],
        catalogRatingList: responseJson?.product?.reviews || [],
      });
    }
  };

  handleHappinessRatingListApiCall = async (params:string) => {
    this.setState({ loading: true })
    const headers = {
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiHappinessratingList = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.happinessRatingApiEndPoint(params));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
