// Customizable Area Start
import { connect, ConnectProps } from 'react-redux';
import Cookies from 'js-cookie';

export const SetCookies = (name: string, value: string, expires: number) => {
  Cookies.set(name, value, { expires });
};

export const GetCookies = (name: string) => {
  return Cookies.get(name) || '';
};

export const RemoveCookies = (name: string) => {
  Cookies.remove(name);
};

const mapStateToProps = (state: ConnectProps) => ({});

const mapDispatchToProps = {};

export const connector = connect(mapStateToProps, mapDispatchToProps);
// Customizable Area End
