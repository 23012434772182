// Customizable Area Start
import React from "react";
import { Box, Button, Container, Grid, Link, Theme, Typography, withStyles } from "@material-ui/core";
import FooterController, { Props, configJSON } from "./FooterController.web";
import { bitmapsImg, emailImg, fbImg, linkdinImg, originalsImg, tiktokImg, whatsappImg } from "./LandingPageController";
import { ClickableBox } from "../../otp-input-confirmation/src/CustomElementStyling";
// Customizable Area End

class Footer extends FooterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box className={classes.footer} data-test-id="footer" id="footer">
          <Container maxWidth={"lg"}>
            <Grid container spacing={1} className={classes.footerContainer}>
              <Grid item xs={6} sm={4} style={{ marginBottom: "20px" }}>
                <Box className={classes.categoriesContainer}>{configJSON.categoriesLabel}</Box>

                <Grid container spacing={1}>
                  {this.chunkArray(this.state.categories, 9).map((column, columnIndex) => (
                    <Grid item lg={4} sm={4} xs={12} key={columnIndex}>
                      <Box className={classes.test2}>
                        {column.map((item: any, index: number) => (
                          <Link href={`/buyer/categories/${item.id}/${item.attributes.name}`} underline="none" rel={"noopener"}>
                            <Typography key={index} className={classes.text}>
                              {item.attributes.name}
                            </Typography>
                          </Link>
                        ))}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={6} sm={4} style={{ marginBottom: "20px" }}>
                <Box className={classes.categoriesContainer}>{configJSON.quickLinkLabel}</Box>
                <Box className={classes.test2}>
                  <ClickableBox data-test-id="click-1" onClick={() => this.handleGoToStaticPage("About us")}>
                    <Typography className={classes.text}>{configJSON.aboutUsLabel}</Typography>
                  </ClickableBox>
                  <ClickableBox data-test-id="click-2" onClick={() => this.handleGoToStaticPage("Testimonials")}>
                    <Typography className={classes.text}>{configJSON.testimonialLabel}</Typography>
                  </ClickableBox>
                  <ClickableBox data-test-id="click-3" onClick={() => this.handleGoToStaticPage("Contact")}>
                    <Typography className={classes.text}>{configJSON.contactLabel}</Typography>
                  </ClickableBox>
                  <ClickableBox data-test-id="click-4" onClick={() => this.handleGoToStaticPage("Privacy Policy")}>
                    <Typography className={classes.text}>{configJSON.privacyPolicyLabel}</Typography>
                  </ClickableBox>
                  <ClickableBox data-test-id="click-5" onClick={() => this.handleGoToStaticPage("Support")}>
                    <Typography className={classes.text}>{configJSON.supportLabel}</Typography>
                  </ClickableBox>
                  <ClickableBox data-test-id="click-6" onClick={() => this.handleGoToStaticPage("Terms & Conditions")}>
                    <Typography className={classes.text}>{configJSON.termsConditionsLabel}</Typography>
                  </ClickableBox>
                  <ClickableBox data-test-id="click-7" onClick={() => this.handleGoToStaticPage("Shipping & Returns")}>
                    <Typography className={classes.text}>{configJSON.shippingReturnLabel}</Typography>
                  </ClickableBox>
                  <Box sx={{ marginTop: "30px" }}>
                    <Typography className={classes.body1}>{configJSON.wantToSellWithUsText}</Typography>
                    <Button className={classes.signUpBtnWrapper} onClick={this.handleGoToSignUpPage} data-test-id="goToSignUpButton">
                      <Box className={classes.signUpBox}>{configJSON.signUpLabel}</Box>
                    </Button>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4} style={{ marginBottom: "20px" }}>
                <Box className={classes.categoriesContainer}>{configJSON.findOnSocialPlatformText}</Box>
                <Grid container spacing={2}>
                  <Grid item>
                    <Link data-test-id="fbIcon" href={this.state?.socialMediaInfo && this.state?.socialMediaInfo[2]?.social_media_url} underline="none" target="_blank" rel="noopener">
                      <Box className={classes.socialIcons} style={{ backgroundColor: '#39579C' }}>
                        <img src={fbImg} width={"34px"} style={{ width: '24px', margin: 'auto' }} />
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href={this.state?.socialMediaInfo && this.state.socialMediaInfo[1]?.social_media_url} underline="none" target="_blank" rel="noopener">
                      <Box className={classes.socialIcons} style={{ backgroundColor: '#2581F6' }}>
                        <img src={linkdinImg} width={"34px"} style={{width: '24px', margin: 'auto' }} />
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href={this.state?.socialMediaInfo && this.state.socialMediaInfo[3]?.social_media_url} underline="none" target="_blank" rel="noopener">
                      <Box className={classes.socialIcons} style={{ backgroundColor: '#D40C0C' }}>
                        <img src={emailImg} width={"34px"} style={{width: '24px', margin: 'auto' }} />
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href={"https://www.whatsapp.com"} underline="none" target="_blank" rel="noopener">
                      <Box className={classes.socialIcons} style={{ backgroundColor: '#509B2E' }} >
                        <img src={whatsappImg} width={"34px"} style={{width: '24px', margin: 'auto' }} />
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href={this.state?.socialMediaInfo && this.state.socialMediaInfo[0]?.social_media_url} underline="none" target="_blank" rel="noopener">
                      <Box className={classes.socialIcons} style={{ backgroundColor: '#F76330' }} >
                        <img src={tiktokImg} width={"34px"} style={{width: '27px', margin: 'auto' }} />
                      </Box>
                    </Link>
                  </Grid>
                </Grid>

                <Grid container spacing={1} style={{ marginTop: "30px", alignItems: "center" }}>
                  <Grid item >
                    <Box className={classes.originalImg}>
                      <img src={originalsImg} width={"34px"} style={{ width: '40px', margin: 'auto', marginTop: '-5px' }} />
                    </Box>
                  </Grid>
                  <Grid item style={{width:'75%'}}>
                    <Typography className={classes.fontTheme1} style={{fontSize:12}}>{configJSON.guaranteeText}</Typography>
                    <Typography className={classes.fontTheme1} style={{fontSize:12}}>{configJSON.forAllProductsText}</Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={1} style={{ marginTop: "15px", alignItems: "center" }}>
                  <Grid item>
                    <Box className={classes.originalImg}>
                      <img src={bitmapsImg} width={"34px"} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.fontTheme1}>{configJSON.returnWithIn30DaysText}</Typography>
                    <Typography className={classes.fontTheme1}>{configJSON.receivingOrderText}</Typography>
                  </Grid>
                </Grid>

                <Box className={classes.btnWrapper} />
                <Button data-test-id="goToHome" onClick={this.handleGoToHomeContactUsPage} className={classes.contactUsWrapper}>
                  <Box className={classes.contactUsBox}>{configJSON.contactUsText}</Box>
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} style={{ marginBottom: "10px", marginTop: "20px" }}>
                <Box className={classes.copyRightText}>{configJSON.copyRightText}</Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
  fontTheme1: {
    fontSize: 14,
    color: "white",
    fontFamily: "Arial, san-serif",
    paddingBottom: "5px"
  },
  text: {
    color: "#ffffff",
    fontFamily: `"Lato",sans-serif` as const,
    fontSize: "14px",
    fontWeight: 400,
    textTransform: "capitalize" as const
  },
  footer: {
    width: "100%",
    background: "rgba(23,34,54,255)",
    padding: "20px 0px"
  },
  footerContainer: {
    padding: "30px 60px 0",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "15px 0",
    },
  },
  categoriesContainer: {
    fontSize: 18,
    fontWeight: "bold" as const,
    marginBottom: "30px",
    color: "white"
  },
  signUpBtnWrapper: {
    marginTop: "11px",
    background: "rgba(253, 97, 12, 1)",
    textTransform: "none" as const,
    fontSize: 14,
    fontWeight: 600 as const,
    fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif` as const,
    borderRadius: "5px",
    width: "fit-content"
  },
  signUpBox: {
    padding: "5px 33px",
    fontWeight: "bold" as const,
    fontSize: 12,
    color: "white",
    fontFamily: "Arial, san-serif" as const
  },
  socialIcons: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    display: 'flex',
  },
  originalImg: {
    width: "45px",
    height: "40px",
    borderRadius: "50px"
  },
  btnWrapper: {
    width: "185px",
    height: "2px",
    marginTop: "30px",
    background: "white"
  },
  contactUsWrapper: {
    marginTop: "20px",
    background: "rgba(253, 97, 12, 1)",
    textTransform: "none" as const,
    fontSize: 14,
    fontWeight: 600 as const,
    fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif` as const,
    borderRadius: "5px",
    width: "fit-content"
  },
  contactUsBox: {
    padding: "5px 43px",
    fontWeight: "bold" as const,
    fontSize: 13,
    color: "white",
    fontFamily: "Arial, san-serif" as const
  },
  copyRightText: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontSize: 14,
    color: "#eeedee"
  },
  test2: {
    width: "100%"
  },
  body1: {
    fontSize: 16,
    color: "white",
    fontFamily: "Lato, Arial, san-serif" as const
  }
});

export { Footer };

export default withStyles(styles)(Footer);
// Customizable Area End
