import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

// Customizable Area Start
import { getStorageData } from "../../../../framework/src/Utilities";
import { IErrorData, ITaxAndLegalPolicyResponse } from "../Others/Interfaces";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  contents: string[];
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerLegalPolicyController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetTaxAndLegalPolicyCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.ReceiveMessage)];

    this.state = {
      contents: ["..."]
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || [];
      if (apiRequestCallId === this.apiGetTaxAndLegalPolicyCallId) {
        this.handleGetTaxAndLegalPolicyResponse(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  handleGetTaxAndLegalPolicyResponse = (responseJson: unknown) => {
    const legalsuccessData = responseJson as ITaxAndLegalPolicyResponse;
    const legalerrorData = responseJson as IErrorData;
    if ("errors" in legalerrorData) {
      return this.setState({ contents: ["..."] });
    }
    if (legalsuccessData?.data?.length > 0) {
      const legalPolicy = legalsuccessData?.data?.filter((item: any) => {
        return item.attributes.title === "Legal Policy";
      });
      if (legalPolicy.length > 0) {
        return this.setState({ contents: legalPolicy[0].attributes.content.split(/\r?\n+/).filter(item => item !== "") });
      }
    }
  };

  getTaxAndLegalPolicy = async () => {
    const headers = { token: await getStorageData("authToken"), "Content-Type": configJSON.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetTaxAndLegalPolicyCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiTaxAndLegalPolicyEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount(): Promise<void> {
    this.getTaxAndLegalPolicy();
  }
  // Customizable Area End
}
