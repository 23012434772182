import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { ISearchProductBrandResult, ISearchProductAndBrandData } from "./Interfaces";
import { getStorageData, removeStorageData } from "../../../../framework/src/Utilities";
import { setStorageData } from "framework/src/Utilities";
import { v4 as uuidv4 } from "uuid";
// Customizable Area Start
import { toast } from "react-toastify";
import { createRef } from 'react';
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    radioBtn: string;
    prodImage: string;
    tableRow: string;
    thead: string;
    nextError: string;
    validateErr: string;
    header: string;
    wrapper: string;
    headerWrap: string;
    headerDes: string;
    searchText: string;
    searchWrapper: string;
    searchValue: string;
    filterContainer: string;
    filterBtn: string;
    main: string;
    resultsText: string;
    resultDes: string;
    tableHeaderText: string;
    image: string;
    left: string;
    sku: string;
    inputPadding: string;
    validateBtn: string;
    marginOne: string;
    validateWrap: string;
    check: string;
    cancel: string;
    checkWrap: string;
    cancelWrap: string;
    deleteWrap: string;
    nextWrap: string;
    details: string;
    tableContainer: string;
    text: string;
    tableHeaderTextOne: string;
    newProductBtn: string;
    addNew: string;
    nextButton: string;
    viewButton: string;
    searchGrid: string
  };
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  openSearchResultMenu: boolean;
  keyword: string;
  category: string;
  productsResults: ISearchProductAndBrandData[];
  errorMessage: string;
  isFetching: boolean;
  searchResults: string[];
  successMesg: string;
  skuValue: string;
  isSkuValid: boolean;
  isValidate: boolean;
  productId: string | null;
  errorMsg: string;
  validateError: string;
  page: number;
  token: string;
  totalCount: number;
  skuError: string;
  skuValidateErr: string;
  sellerProductId: string;
  isAddNewClicked: boolean;
  brandId: string;
  isNextDisable: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SearchProductNameController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiSearchProductsCallId: string = "";
  apiValidateSKUCallId: string = "";
  getProductDataApiCallId: string = "";
  getProductProfileApiCallId: string = "";
  createProductApiCallId: string = "";
  topRef: React.RefObject<HTMLFormElement>;
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      openSearchResultMenu: false,
      keyword: "",
      category: "product_keyword",
      productsResults: [],
      successMesg: "",
      isFetching: false,
      searchResults: [],
      errorMessage: "",
      skuValue: "",
      isSkuValid: false,
      isValidate: false,
      productId: null,
      errorMsg: "",
      validateError: "",
      page: 1,
      token: "",
      totalCount: 0,
      skuError: "",
      skuValidateErr: "",
      sellerProductId: "",
      isAddNewClicked: false,
      brandId: "",
      isNextDisable: false
    };
    this.topRef = createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message1: Message) {
    runEngine.debugLog("Message Received", message1);
    if (getName(MessageEnum.RestAPIResponceMessage) === message1.id) {
      this.setState({ isFetching: false });
      const apiRequestCallId = message1.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message1.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.apiSearchProductsCallId) {
        this.handleSearchProductsResult(responseJson);
      }
      if (apiRequestCallId === this.apiValidateSKUCallId) {
        this.handleValidateSKU(responseJson);
      }
      if (apiRequestCallId === this.getProductProfileApiCallId) {
        this.handleProfileDetailsRes(responseJson);
      }
      if (apiRequestCallId === this.createProductApiCallId) {
        this.handleCreateProductRes(responseJson);
      }
    }
  }

  handleProfileDetailsRes = (responseJson: any) => {
    if (responseJson) {
      const { product_image, category, sub_category, mini_category, micro_category, brand } = responseJson.data?.attributes;
      this.createNewProduct(product_image, category.id, sub_category.id, mini_category.id, micro_category.id, brand.id);
    }
  };

  handleCreateProductRes = (responseJson: any) => {
    if (responseJson.data) {
      setStorageData("catalog_id", responseJson.data.id);
      setStorageData("micro_category_id", responseJson.data.attributes?.micro_category?.id);
      setStorageData("brand_name", responseJson.data.attributes.brand.brand_name);
      this.props.navigation.navigate(configJSON.sellerHomeText, { path: configJSON.addProductPath });
      const msg: Message = new Message(getName(MessageEnum.NavigationSellerDashboardMessage));
      msg.addData(getName(MessageEnum.ChangeMenuPanel), configJSON.addProductPath);
      const message: Message = new Message(getName(MessageEnum.SendMessage));
      message.addData(getName(MessageEnum.ChangeActivePanelProductDetails), { activePanel: "product-content", activeScreen: "product-content" });
      this.send(message);
      this.send(msg);
    }
  };

  handleValidateSKU = (responseJson: any) => {
    if (responseJson.message === configJSON.skuValidText) {
      setStorageData("sku", this.state.skuValue);
      this.setState({ isSkuValid: true, errorMsg: "", skuError: "", skuValidateErr: configJSON.skuValidText });
    } else this.setState({ skuError: configJSON.skuAlreadyTakenText, isSkuValid: false, errorMsg: "", skuValidateErr: configJSON.alreadyExistsText });
  };

  handleSKUOnChangeInputs = (value1: string) => {
    if (value1) {
      this.setState({ skuValue: value1 });
      const isValueValid = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9&.,|\-]+$/.test(value1);
      if (isValueValid && value1.length < 26 && value1.length >= 2) {
        this.setState({ skuValue: value1, isValidate: false, errorMsg: "", validateError: "", skuError: "", skuValidateErr: "" });
      } else this.setState({ skuError: configJSON.skuValidateMsg, isValidate: false, validateError: "", errorMsg: "", skuValidateErr: "" });
    } else this.setState({ isValidate: true, skuValue: "", skuError: "", skuValidateErr: "" });
  };
  handleValidates = () => {
    if (this.state.skuValue && !this.state.skuError) {
      this.setState({ isValidate: true, errorMsg: "", validateError: "" });
      this.validateSKU();
    } else if (!this.state.skuValue) {
      if (!this.state.productId) {
        this.setState({ validateError: configJSON.selectProductText });
      } else this.setState({ validateError: configJSON.createSkuError });
    }
  };

  handleNextBtn = async () => {
    const isSkuCreated = await getStorageData("sku");
    const isValid = isSkuCreated && this.state.productId;
    if (isValid) {
      this.setState({ isNextDisable: true });
      this.getProductProfile(this.state.productId);
      setStorageData("newProductId", this.state.productId);
    } else {
      const isSkuPresent = this.state.skuValue;
      if (this.state.productId && !isSkuCreated && isSkuPresent) {
        this.setState({ errorMsg: configJSON.validateSkuMsg });
      } else this.setState({ errorMsg: configJSON.nextBtnErrorMsg });
    }
  };

  handleDeleteIcon = () => {
    this.setState({ isValidate: false, isSkuValid: false, skuValue: "", skuError: "" });
    setStorageData("sku", "");
  };

  validateSKU = () => {
    const headers = {
      "Content-Type": "application/json"
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiValidateSKUCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/sku_validate?sku=${this.state.skuValue}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  searchProducts = async (search: string, pageValue: number) => {
    const microCategoryId = await getStorageData("micro_category_id");
    const headers = {
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiSearchProductsCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getProductByBrandEndpoint + `${this.state.brandId}&${this.state.category}=${search}` + `&micro_category_id=${microCategoryId}&per_page=10&page=${pageValue || this.state.page}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  SearchDebounce = (call: any, delay: any) => {
    let timer: any;
    return function(...args: any) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        call(...args);
      }, delay);
    };
  };
  handleSearchOnChange = this.SearchDebounce(this.searchProducts, 1000);

  handleSearchProductsResult = (responseJson: any) => {
    const successData = responseJson as ISearchProductBrandResult;
    this.setState({ totalCount: responseJson.total_count });
    if ("data" in successData) {
      return this.setState({
        productsResults: successData?.data?.map((item: any) => {
          const { brand, category, product_image, product_title, besku, details, prod_model_no, content_status, status } = item.attributes;
          return {
            category_id: item?.id,
            brand_id: brand?.id,
            category: category?.name,
            product_image: product_image,
            brand_name: brand?.brand_name,
            product_title: product_title,
            besku: besku,
            details: details || "Details",
            model: prod_model_no || "12345",
            content_status,
            status 
          };
        })
      });
    }
  };

  handleSelectProduct = (id: string, besku: string) => {
    if (id === this.state.productId) {
      this.setState((prevState: any) => ({
        productId: prevState.productId === id ? null : id
      }));
      setStorageData("besku", "");
      removeStorageData("newProductId");
    } else {
      this.setState({ productId: id, errorMsg: "", validateError: "" });
      setStorageData("besku", besku);
      setStorageData("newProductId", id);
    }
  };
  // Customizable Area End
  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    await removeStorageData("sku");
    await removeStorageData("besku");
    const brandId = await getStorageData("brand_id");
    const token = await getStorageData("authToken");
    this.setState({ token: token, brandId: brandId });
    this.searchProducts("", this.state.page);
    await removeStorageData("catalog_id");
  }

  async componentWillUnmount(): Promise<void> {
    this.setState({ isNextDisable: false });
  }

  generateRandomAlphanumeric = () => {
    const uuid = uuidv4();
    const alphanumericString = uuid.replace(/-/g, "").toUpperCase();
    const trimmedUUID = alphanumericString.substring(0, 16);
    return trimmedUUID;
  };

  handleAddNewProductBtn = async () => {
    this.setState({ isAddNewClicked: true, errorMsg: "", validateError: "" });

    const isSkuCreated = await getStorageData("sku");
    const productIdExists = !this.state.productId;

    if (isSkuCreated && productIdExists) {
      setStorageData("besku", this.generateRandomAlphanumeric());
      this.props.navigation.navigate(configJSON.sellerHomeText, { path: configJSON.addProductPath });
      const msg: Message = new Message(getName(MessageEnum.NavigationSellerDashboardMessage));
      msg.addData(getName(MessageEnum.ChangeMenuPanel), configJSON.addProductPath);
      this.send(msg);

      await removeStorageData("newProductId");
    } else {
      if (!this.state.skuValue) {
        this.setState({ skuError: configJSON.createSkuError });
      } else {
        if (!isSkuCreated) {
          this.setState({ validateError: configJSON.validateSkuMsg });
        } else if (this.state.productId) {
          this.setState({ validateError: configJSON.deselectProductMsg });
        } else {
          this.setState({ validateError: "" });
        }
      }
    }
  };

  handleViewProduct = (item: any) => {
    if(item.status && item.content_status === "approved") {
      window.open(`/product/${item.category_id}`, "_blank");
    } else {
      toast.error("The product is unapproved/Inactive!", {
        autoClose: 3000,
        draggable: true,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        pauseOnHover: true,
        position: "top-right",
      });
    }
  }

  getProductProfile = async (id: string | null) => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: await getStorageData("authToken")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProductProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/${id}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createNewProduct = async (productImage: string, categoryId: string, subCategoryId: string, miniCategoryId: string, microCategoryId: string, brandId: string) => {
    try {
      const headers = { token: await getStorageData("authToken") };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.createProductApiCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiPostProductProfileEndpoint);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
      const formData = new FormData();
      formData.append("sku", await getStorageData("sku"));
      formData.append("besku", await getStorageData("besku"));
      formData.append("category_id", categoryId);
      formData.append("sub_category_id", subCategoryId);
      formData.append("mini_category_id", miniCategoryId);
      formData.append("micro_category_id", microCategoryId);
      formData.append("brand_id", brandId);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
      const response = await fetch(productImage);
      let blob = await response.blob();
      formData.append("product_image", blob);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } catch {}
  };

  // Customizable Area End
}
