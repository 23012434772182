import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
export const configJSON = require("../src/config.js")
import { ClassNameMap } from "@material-ui/styles";
import { MouseEvent } from "react";
import { toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";

export interface IWarehouseDelRes {
  message: string,
  errors: {
    [key: string]: string; 
  }[];
}
export interface IWarehouseData {
  attributes: {
    id: string,
    contact_number: string,
    warehouse_address_1:string,
    warehouse_address_2: string,
    processing_days: string | number,
    contact_person: string,
    warehouse_name: string
  }
}

export interface WarehouseData {
  data: IWarehouseData[]
}
interface DocumentsData {
  id: string | number,
  warehouse_type: string,
  warehouse_name: string,
  warehouse_address: string,
  created_at: string,
  updated_at: string,
  contact_number: string,
  contact_person: string,
  processing_days: number,
  account_id: string

}
interface SuccessResponse {
  data: DocumentsData[];
  meta: {
    message: string;
  };
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  openDeleteModal: boolean
  loading: boolean
  token: string,
  selectedId: string | null,
  warehouseList: IWarehouseData[],
  anchorEl: Array<null | HTMLElement>;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class WarehouseListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getWarehouseList: string = "";
  deleteWarehouseId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.ReceiveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)];

    this.state = {
      openDeleteModal: false,
      loading: false,
      token: "",
      selectedId: "",
      anchorEl: [],
      warehouseList: []
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorJson = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (this.getWarehouseList === apiRequestCallId) {
        this.handleWarehouselistApiCallResponse(responseJson);
      }
      if (this.deleteWarehouseId === apiRequestCallId) {
        this.handleWarehouseDeleteApiCallResponse(responseJson, errorJson);
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    await this.getToken();
    await this.handleWarehouseListApiCall();
  }

  getToken = async () => {
    const token = await getStorageData("authToken");
    this.setState({ token })
  }

  handleAddNewWarehouse = () => {
    this.props.navigation.navigate(configJSON.sellerHomeText, {path: configJSON.createWarehousePath})
  }

  editWarehouseHandler = (data:IWarehouseData) => {
    this.props.navigation.navigate(configJSON.sellerHomeText, {path: configJSON.createWarehousePath})
    const msg1: Message = new Message(getName(MessageEnum.NavigationSellerDashboardMessage));
    msg1.addData(getName(MessageEnum.ChangeMenuPanel), configJSON.createWarehousePath);
    msg1.addData(getName(MessageEnum.WarehouseData), JSON.stringify(data.attributes));
    this.send(msg1);
  }

  handleWarehouseListApiCall = async () => {
    this.setState({ loading: true })
    const headers = {
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getWarehouseList = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sellerWarehouseApiEndPoint.list);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteWarehouseHandler = async () => {
    this.setState({ loading: true })
    const headers = {
      token: this.state.token
    };
    const formData = new FormData();
    formData.append("warehouse[id]", this.state.selectedId as string);
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteWarehouseId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sellerWarehouseApiEndPoint.delete);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeDelete);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleWarehouselistApiCallResponse = (responseJson: WarehouseData ) => {
    this.setState({ loading: false })
    if (responseJson && responseJson.data) {
      this.setState({ warehouseList: responseJson.data, loading: false });
    } 
  };

  handleWarehouseDeleteApiCallResponse = (responseJson: IWarehouseDelRes, errorResponse: IWarehouseDelRes) => {
    this.setState({ loading: false })
    if (responseJson) {
      toast.success("Warehouse deleted successfully!", {
        position: "top-right",
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.handleWarehouseListApiCall();
      this.handleCloseDeleteModal();
    }

    if ("errors" in responseJson || errorResponse) {
      const key1 = Object.keys(responseJson.errors[0])[0];
      toast.error( responseJson?.errors?.[0]?.[key1] ? `${JSON.stringify(responseJson.errors?.[0]?.[key1])}` : "Failed to delete warehouse. Please try again later!", {
        autoClose: 3000, 
        draggable: true,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        pauseOnHover: true,
        position: "top-right",
      });
    }

  };

  handleCloseAction = (index:number) => {
    const { anchorEl } = this.state;
    const updatedAnchorEl = [...anchorEl];
    updatedAnchorEl[index] = null;
    this.setState({ anchorEl: updatedAnchorEl });
  }

  handleOpenDeleteModal = (warehouseId:string) => {
    this.setState({ openDeleteModal: true ,selectedId:warehouseId});
  }

  handleCloseDeleteModal = () => {
    this.setState({ openDeleteModal: false ,selectedId:null, anchorEl: []});
  }

  handleClickAction = (index: number, event: MouseEvent<HTMLButtonElement> ) => {
    const { anchorEl } = this.state;
    const updatedAnchorEl = [...anchorEl];
    updatedAnchorEl[index] = event.currentTarget;
    this.setState({ anchorEl: updatedAnchorEl });
  };

  // Customizable Area End
}
