import React, { Component, RefObject  } from 'react';
import { Tooltip, Typography, withStyles} from "@material-ui/core";

interface Props {
 text: any;
 title?: string;
 maxWidth?: string;
 maxChar?: number;
 classes?: any;
 direction?: any;
 classNames?: any;
 isOverflowProps?: boolean;
}

interface State {
  isTextOverflowing: boolean;
}

const tStyles:any = ({
  tooltip: {
    fontStyle: "normal",
    textAlign: "center",
    borderRadius: "5px",
    color: "white",
    fontWeight: 600,
    padding: "5px 9px",
    fontSize: "12px",
    lineHeight: "16px",
    maxHeight: "280px",
  },
});

class TruncatedTextWithTooltip extends Component<Props, State> {
  textRef: RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.state = {
      isTextOverflowing: false,
    };
    this.textRef = React.createRef();
  }

  componentDidMount() {
    if(this.props.isOverflowProps){
      this.setState({ isTextOverflowing: true });
    } else {
      this.checkTextOverflow();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.text !== this.props.text) {
      this.checkTextOverflow();
    }
  }

  checkTextOverflow() {
    if (this.textRef.current) {
      const isOverflowing =
        this.textRef.current.clientWidth < this.textRef.current.scrollWidth;
      this.setState({ isTextOverflowing: isOverflowing });
    }
  }

  truncateText = (title: string, titleLength: number) => {
    if (title === undefined || title === null) return "";
    if (title.length > titleLength) {
      return `${title.slice(0, titleLength)}...`;
    } else {
      return title;
    }
  };

  render() {
    const { text, maxWidth, classes, direction, classNames, title, maxChar } = this.props;
    const { isTextOverflowing } = this.state;

    const displayText = !!maxChar ? this.truncateText(text, maxChar) : text;
    
    const isOverflow = !!maxChar ? (text.length > maxChar ? true :  false ): isTextOverflowing

    const tipTitle = title ? title : isOverflow  ? text : ''

    return (
      <Tooltip title={tipTitle} arrow classes={classes} placement={direction ? direction : "bottom"}>
        <Typography
          style={{
            maxWidth: maxWidth,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: tipTitle ? 'pointer' : 'default',
          }}
          className={classNames}
          ref={this.textRef}
        >
          {displayText}
        </Typography>
      </Tooltip>
    );
  }
};

export default withStyles(tStyles)(TruncatedTextWithTooltip);