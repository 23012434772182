// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography,
} from "@material-ui/core";
import PrintLabelController, {
  Props,
  configJSON,
} from "./PrintLabelController.web";
import { mobileImg } from "./assets";
import { OrangeButton } from "../../dashboard/src/CustomizedElementStyle";
// Customizable Area End

class PrintLabel extends PrintLabelController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  render() {
    const { classes } = this.props;
    return (
      <>
        <Box style={webStyle.wrapper}>
          <Box sx={webStyle.headerContainer}>
            <Box sx={webStyle.headerText}>
              {configJSON.fulFilledByEzzyText}/{configJSON.fbbStockMgmtText}/
              {configJSON.stockPath} /
              <span style={webStyle.printLabelText}>
                {configJSON.printLabelPath}
              </span>
            </Box>
          </Box>
          <Box style={webStyle.bckGround}>
            <Box style={webStyle.formWrapper}>
              <Box data-test-id="formHeader" sx={webStyle.formHeader}>
                {configJSON.printLabelPath}
              </Box>
            </Box>

            <Box sx={webStyle.description}>{configJSON.stockDescText}</Box>
            <Grid container>
              <Grid item xs={12} style={webStyle.textWrapper}>
                <Box sx={webStyle.requestText}>3 {configJSON.requestsText}</Box>
              </Grid>
            </Grid>

            <div className={classes.root}>
              <Grid container spacing={2}>
                {this.state.cardsData.map((card, index) => (
                  <Grid item key={index} xs={12} sm={6} md={6} lg={3}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Box
                          style={webStyle.flex}
                        >
                          <img
                            src={mobileImg}
                            alt="prodImage"
                            className={classes.prodImag}
                          />
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            className={classes.buyer}
                          >
                            {card.attributes.productName}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className={classes.requestId}>
                            {configJSON.reqIdLabel}{" "}
                            <span style={webStyle.printLabelText}>
                              {card.attributes.requestId}
                            </span>
                          </Typography>
                          <Typography className={classes.requestId}>
                            {configJSON.fullFillmentLabel}{" "}
                            <span className={classes.byEzzyText}>
                              {card.attributes.fulfillment}
                            </span>
                          </Typography>
                        </Box>
                        <Box
                          style={webStyle.productWrapper}
                        >
                          <Box
                            sx={webStyle.detailWrapper}
                          >
                            <Typography className={classes.productDetails}>
                              {configJSON.stockQuantityLabel}
                            </Typography>
                            <Typography className={classes.productValue}>
                              {card.attributes.stockQty}
                            </Typography>
                          </Box>
                          <Box
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography className={classes.productDetails}>
                              {configJSON.unitPriceLabel}
                            </Typography>
                            <Typography className={classes.productValue}>
                              {card.attributes.price}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          style={webStyle.stockWrapper}
                        >
                          <Box
                            sx={webStyle.stockDetails}
                          >
                            <Typography className={classes.productDetails}>
                              {configJSON.stockVolumeLabel}
                            </Typography>
                            <Typography className={classes.productValue}>
                              {card.attributes.stockVolume}
                            </Typography>
                          </Box>
                          <Box
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography className={classes.productDetails}>
                              {configJSON.stockWeightLabel}
                            </Typography>
                            <Typography className={classes.productValue}>
                              {card.attributes.weight}
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Button className={classes.downloadBtn}>
                            {configJSON.downloadPdfBtn}
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>

            <Box sx={webStyle.viewBtnContainer}>
              <OrangeButton style={webStyle.viewBtn}>
                {configJSON.printAllBtn}
              </OrangeButton>
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  // Customizable Area End
}

// Customizable Area Start
const webStyle = {
  wrapper: {
    overflowY: "auto" as const,
    overflowX: "hidden" as const,
    marginBottom: "30px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "30px 0px 20px",
  },
  headerText: {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: `"Lato", sans-serif`,
    color: "#747474",
  },
  printLabelText: {
    color: "rgb(255, 96, 8)",
  },
  bckGround: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
  },
  description: {
    fontSize: "14px",
    fontFamily: `"Lato", sans-serif`,
    color: "#747474",
    fontWeight: 500,
  },
  formWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row" as const,
    flexWrap: "wrap" as const,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  formHeader: {
    fontSize: "28px",
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`,
  },
  textWrapper: {
    width: "100%",
    margin: "30px 0",
  },
  requestText: {
    fontSize: "24px",
    fontWeight: 700 as const,
    fontFamily: `"Lato", sans-serif` as const,
  },
  viewBtnContainer: {
    alignItems: "flex-end",
    marginLeft: "10px",
    marginTop: "100px",
  },
  viewBtn: {
    padding: "8px 50px",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  productWrapper : {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginTop: "20px",
  },
  detailWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "120px",
  },
  stockWrapper : {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginTop: "20px",
  },
  stockDetails: {
    display: "flex",
    flexDirection: "column",
    width: "120px",
  }
};

const styles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  card: {
    minWidth: 200,
    boxShadow: "none",
    border: "0.5px solid rgba(101, 122, 142, 0.320241)",
    borderRadius: "8px",
  },
  prodImag: {
    width: "60px",
    height: "60px",
  },
  buyer: {
    fontSize: "13px",
    color: "#232529",
    fontFamily: `"Lato", sans-serif` as const,
    fontWeight: 400 as const,
    marginLeft: "10px",
  },
  requestId: {
    fontSize: "14px",
    color: "#697A8D",
    fontFamily: `"Lato", sans-serif` as const,
    fontWeight: 400 as const,
    marginTop: "10px",
  },
  productDetails: {
    fontSize: "15px",
    color: "#697A8D",
    fontFamily: `"Lato", sans-serif` as const,
    fontWeight: 400 as const,
    marginTop: "10px",
  },
  downloadBtn: {
    marginTop: "10px",
    fontSize: "16px",
    fontFamily: `"Lato", sans-serif` as const,
    fontWeight: 500 as const,
    textTransform: "capitalize" as const,
    padding: "16px 24px",
    backgroundColor: "#0fbc9e",
    borderRadius: "8px",
    color: "#fff",
    display: "flex",
    alignItems: "center" as const,
    justifyContent: "center" as const,
    width: "100%",
    textAlign: "center" as const,
    "&:hover": {
      backgroundColor: "white",
      color: "#0fbc9e",
      border: "0.5px solid #0fbc9e",
    },
  },
  productValue: {
    fontSize: "12px",
    fontWeight: 600 as const,
    fontFamily: `"Lato", sans-serif` as const,
  },
  byEzzyText: {
    fontSize: "14px",
    color: "#000",
    fontWeight: 600 as const,
    fontFamily: `"Lato", sans-serif` as const,
  },
});

export { PrintLabel };
export default withStyles(styles)(PrintLabel);
// Customizable Area End
