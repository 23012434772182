// Customizable Area Start
import { Box, FormControl, InputAdornment, Select, Typography, withStyles } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { BlackButton, CustomizeBrandInput, FormControlTwo, MenuItemTwo, OrangeButton } from "../../dashboard/src/CustomizedElementStyle";
import ReturnAndWarrentyClaimsController, { Props, configJSON, initialValues } from "./ReturnAndWarrentyClaimsController.web";
// Customizable Area End

// Customizable Area Start
class ReturnAndWarrentyClaims extends ReturnAndWarrentyClaimsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  validationSchema = Yup.object().shape({
    requestType: Yup.string().required(configJSON.requestTyperequiredText),
    returnReason: Yup.string().required(configJSON.reasonRequiredText),
    description: Yup.string().required(configJSON.descriptionRequiredText),
  })

  render() {
    const { classes } = this.props;
    const { title, subTitle, requestType, requestExchange,returnReason, description, submitButton, cancelButton } = configJSON.ReturnAndWarrenty;
    return (
      <>
        <Box className={classes.containerWrraper}>
          <Box className={classes.titleWrraper}>
            <Typography className={classes.titleClass} variant="h4">{title}</Typography>
            <Typography className={classes.subtitleClass} variant="body1">{""}</Typography>
          </Box>

          <Formik
            initialValues={initialValues}
            validationSchema={this.validationSchema}
            validateOnMount={true}
            validateOnChange={true}
            onSubmit={this.onSubmit}
            data-test-id="formik"
          >
            {({ handleChange,
              errors,
              setFieldTouched,
              touched,
            }) => (
              <Form translate={undefined} className={classes.formWrraper}>
                <Box className={classes.fieldContainer}>
                  <Box className="">
                    <FormControl className={classes.formControl}>
                      <Select
                        name="requestExchange"
                        onChange={handleChange("requestExchange")}
                        onBlur={() => setFieldTouched("requestExchange")}
                        data-test-id="requestExchangeRef"
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        displayEmpty
                        className={classes.selectEmpty}
                        disableUnderline
                        defaultValue={""}
                        inputProps={{ 'aria-label': 'Without label', style: { borderBottom: 'none', background: 'white' }, }}
                      >
                        <MenuItemTwo value={""}>{requestExchange}</MenuItemTwo>
                        <MenuItemTwo value={"1"}>{configJSON.reqText}</MenuItemTwo>
                        <MenuItemTwo value={"2"}>{configJSON.exchangeText}</MenuItemTwo>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className="">
                    <FormControlTwo className={classes.fieldWrraper} >
                      <Box id="demo-simple-select-outlined-label" sx={webStyles.label}>{requestType}</Box>
                      <Select
                        name="requestType"
                        onChange={handleChange("requestType")}
                        onBlur={() => setFieldTouched("requestType")}
                        data-test-id="requestTypeRef"
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        placeholder={configJSON.selectTypeText}
                        defaultValue={''}
                        displayEmpty
                        onOpen={this.handleMenuOpen}
                        onClose={this.handleMenuClose}
                        open={this.state.menuOpen}
                        MenuProps={{
                          onExited: this.handleMenuClose,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: webStyles.selectOptions
                          }
                        }}
                      >
                         {!this.state.menuOpen && <MenuItemTwo value={""}  disabled><span style={{color:"#9a9a9a",fontSize:'12px'}} >{requestExchange}</span></MenuItemTwo>}
                        <MenuItemTwo value={"1"}>{configJSON.requestOneText}</MenuItemTwo>
                        <MenuItemTwo value={"2"}>{configJSON.requestTwoText}</MenuItemTwo>
                      </Select>
                      {touched.requestType && errors.requestType ? (
                        <Typography className={classes.errorStyle}>{errors.requestType}</Typography>
                      ) : null}
                    </FormControlTwo>
                  </Box>
                  <Box className="">
                    <FormControlTwo className={classes.fieldWrraper} >
                      <Box id="demo-simple-select-outlined-Reason-label" sx={webStyles.label}>{returnReason}</Box>
                      <Select
                        name="returnReason"
                        onChange={handleChange("returnReason")}
                        onBlur={() => setFieldTouched("returnReason")}
                        data-test-id="returnReasonRef"
                        labelId="demo-simple-select-outlined-Reason-label"
                        label={configJSON.selectReasonText}
                        defaultValue={''}
                        displayEmpty
                        onOpen={this.handleMenuOpen2}
                        onClose={this.handleMenuClose}
                        open={this.state.menuOpen2}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: webStyles.selectOptions
                          }
                        }}
                        >
                       {!this.state.menuOpen2 && <MenuItemTwo value={""}  disabled><span style={{color:"#9a9a9a",fontSize:'12px'}}>{requestExchange}</span></MenuItemTwo>}
                        <MenuItemTwo value={"1"}>{configJSON.reasonOneText}</MenuItemTwo>
                        <MenuItemTwo value={"2"}>{configJSON.reasonTwoText}</MenuItemTwo>
                      </Select>
                      {touched.returnReason && errors.returnReason ? (
                        <Typography className={classes.errorStyle}>{errors.returnReason}</Typography>
                      ) : null}
                    </FormControlTwo>
                  </Box>
                  <Box className={classes.fieldWrraper} >
                    <Box sx={webStyles.label}>{description}</Box>
                    <CustomizeBrandInput
                      name="description"
                      onChange={handleChange("description")}
                      onBlur={() => setFieldTouched("description")}
                      data-test-id="descriptionRef"
                      label={""}
                      placeholder={configJSON.describeInDetailsText}
                      multiline
                      fullWidth
                      minRows={4}
                      InputProps={{
                        startAdornment: <InputAdornment position="start" style={{ marginRight: 5 }}></InputAdornment>
                      }}
                    />
                    {touched.description && errors.description ? (
                      <Typography className={classes.errorStyle}>{errors.description}</Typography>
                    ) : null}
                  </Box>
                </Box>
                <Box className={classes.buttonContainer}>
                  <BlackButton style={webStyles.viewBtn}>{cancelButton}</BlackButton>
                  <OrangeButton type="submit" style={webStyles.viewBtn}>
                    {submitButton}
                  </OrangeButton>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </>
    )
  }
  // Customizable Area End
}

const styles = (theme: any) => ({
  containerWrraper: {
    padding: '0 15px'
  },
  titleWrraper: {
    margin: "0 0 10px 0"
  },
  titleClass: {
    fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    fontWeight: 700,
    color: "#3E3E3E",
  },
  subtitleClass: {
    fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    fontSize: 15,
    color: "rgb(111, 111, 111)",
    margin: "15px 0 -10px 0",
    fontWeight: 600,
  },
  formWrraper: {
    width: '100%',  // Set default width for mobile
    [theme.breakpoints.up('md')]: {
      width: '50%',  // Set width for screens larger than md
    },
  },
  fieldWrraper: {
    width: '100%',  // Set default width for mobile
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    fontSize: 24,
    fontFamily: `"Lato", sans-serif`,
    marginTop: theme.spacing(2),
    fontWeight:700,
    color:"#3e3e3e",
    '&:focus': {
      outline: '2px solid #2196f3',
      borderColor: '#2196f3',
      boxShadow: '0 0 5px rgba(33, 150, 243, 0.5)',
      backgroundColor:"#000",
    },
    '&:before': {
      borderBottom: 'none', // Remove underline when not focused
    },
    '&:after': {
      borderBottom: 'none', // Remove underline when focused
    },
      
    
  },
  fieldContainer: {
    rowGap: "20px",
    display: "flex",
    flexDirection: "column" as const,
  },
  buttonContainer: {
    columnGap: "20px",
    display: "flex",
    width: "min-content",
    marginLeft: "auto",
    marginTop: "40px"
  },
  errorStyle: {
    color: "red",
    textAlign: "left" as const,
    fontSize: "12px",
    marginTop: "5px"
  },
});

export const webStyles = {
  selectOptions: {
    background: "white",
    border: "1px solid #b1b3b5",
    boxShadow: "none",
    borderRadius: "5px",
    marginTop: "5px",
    padding: "0px",
    maxHeight: "400px", //Set the maximum height limit for the menu
  },
  viewBtn: {
    padding: '10px 30px',
    letterSpacing:"0",
    fontFamily: `"Lato", sans-serif`,
  },
  label: {
    color: "rgb(111, 111, 111)",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 600,
    fontSize: 13,
    marginBottom: 8
  }
};

export { ReturnAndWarrentyClaims };

export default withStyles(styles)(ReturnAndWarrentyClaims)
// Customizable Area End