// Customizable Area Start
import React from "react";
import PromotionsController, { Props, configJSON } from "./PromotionsController.web";
import { Box } from "@material-ui/core";
import { BoxNoScroll } from "./CustomizedElementStyle";
import ProductDeals from "../../catalogue/src/ProductDeals.web";
import { webStyle } from "./SellerHomeDashboardComponents/SellerAddNewProduct.web";
// Customizable Area End

class Promotions extends PromotionsController {
    constructor(props:Props) {
        super(props)
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            <Box sx={webStyle.topHeader}>{configJSON.productCatalogueText}</Box>
            <BoxNoScroll style={webStyle.boxScroll}>
            <Box sx={webStyle.box}>
            <Box sx={webStyle.wrapper}>
              <Box data-test-id="deals" sx={webStyle.tabHeader}>{configJSON.dealsTab}</Box>
            </Box>
            <ProductDeals navigation={this.props.navigation} id={"deals"} />
          </Box>
        </BoxNoScroll>
        <Box sx={style.footerContainer}>
          <Box sx={style.footerItem}>{configJSON.upgradeText}</Box>
          <Box sx={style.footerItem}>{configJSON.moreThemesText}</Box>
          <Box sx={style.footerItem}>{configJSON.supportText}</Box>
          <Box sx={style.footerItem}>{configJSON.contactText}</Box>
          </Box>
            </>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
export const style = {
  footerContainer: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    color: "#B1B2B4",
  },
  footerItem: {
    fontWeight: 600,
    padding: "30px 0px 20px 20px",
  }
  };

export default Promotions
// Customizable Area End
