import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
export const configJSON = require("../src/config.js")

export interface IRequestData {
  id: string,
  attributes: {
    id: string, 
    created_at: string,
    status: string
  }
}
export interface RequestList {
  data: IRequestData[],
  message: string
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    partnerLabel: string,
    backGrnd: string,
    createReqBtn: string,
    mainText: string,
    paraText: string,
    divider: string,
    row: string,
    box: string,
    padding: string,
    yellowBtn: string,
    requestText: string,
    greenBtn: string,
    redBtn: string,
    dateTime: string,
    infoContainer: string,
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  reqList: IRequestData[],
  loading: boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DeliveryRequestListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getDeliveryReqListApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.ReceiveMessage), getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
        reqList: [],
        loading: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getDeliveryReqListApiCallId) {
        this.handleDeliveryReqListRes(responseJson);
      }
    }
  }

  handleDeliveryReqListRes = (responseJson:RequestList) => {
    if(responseJson.message === "No Delivery Request is present") {
      this.setState({reqList: [], loading: false})
    }
    else
      this.setState({reqList : responseJson.data, loading: false})
  }

  getClass = (status:string) => {
    if(status === configJSON.pendingText){
        return this.props.classes.yellowBtn
    }
    if(status === configJSON.acceptedText){
        return this.props.classes.greenBtn
    }
    else return this.props.classes.redBtn
  }

  handleCreateNewReqBtn = () => {
    this.props.navigation.navigate(configJSON.sellerHomeText, {path: configJSON.createDeliveryReqPath})
  }

  async componentDidMount(): Promise<void> {
      this.getDeliveryRequests()
  }

  getDeliveryRequests = async() => {
    this.setState({ loading: true})
    const headers = {
      token: await getStorageData('authToken')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getDeliveryReqListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.createDeliveryReqEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDateTime = (value: string) => {
    const dateTime = new Date(value);

    // Format date
    const monthNames = configJSON.monthNames;
    const days = dateTime.getDate();
    const monthIndex = dateTime.getMonth();
    const year = dateTime.getFullYear();
    const formattedDate = `${monthNames[monthIndex]} ${days}, ${year}`;
    let hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12; 
    const formattedHours = hours < 10 ? '0' + hours : hours; 
    const formattedTime = `${formattedHours}.${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
    const result = `${formattedDate} | ${formattedTime}`
    return result
  }
  // Customizable Area End
}
