import React from "react";

// Customizable Area Start
import { Box, Theme, Typography, withStyles } from "@material-ui/core";
import { OrangeButton } from "../../dashboard/src/CustomizedElementStyle"
import { cross } from "../../dashboard/src//assets";
// Customizable Area End

import SellerAddSingleController, { Props, configText } from "./AddAddressController";
import {styledAddress as UserAddressForm} from "./UserAddress.web"
import { Divider } from "react-native-elements";

export default class SellerAddSingle extends SellerAddSingleController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    // Customizable Area Start
    render() {
        return (
            <>
                {
                !this.state.showAddressForm ? 
                <Box className={this.props.classes.container}>
                    <Typography variant="h4" className={this.props.classes.header}>
                        {configText.addNewAddressText}
                    </Typography>
                    <Divider style={webStyle.divider} />

                    <Box className={this.props.classes.middleSec}>
                        <Box className={this.props.classes.wrap}>
                            <img src={cross} className={this.props.classes.image}/>
                            <Box className={this.props.classes.text}>{configText.noAddressAddedText} </Box>
                           
                            <OrangeButton data-test-id="addAddressButton" className={this.props.classes.addBtn} 
                            onClick={this.handleGoToCategorizeProducts}
                            >
                                {configText.addText}
                            </OrangeButton>
                        </Box>
                    </Box>
                </Box> 
                : 
                 <UserAddressForm data-test-id="addressForm" navigation={this.props.navigation} id={this.props.id} userId={this.props.userId} singleAddressInfo={""} match={this.props.match} toShow={() => {}}/>
                }
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    divider: {
        height: "1px", 
        margin: "10px 0", 
        backgroundColor: '#efeff4'
    }
}

const styles = {
    header : {
        color: "#232529",
        fontWeight: 700,
        fontSize: "32px",
        fontFamily: "Lato, sans-serif",
        marginBottom: "25px",
        '@media (max-width: 960px)': {
            fontSize: "28px"
        },
        '@media (max-width: 600px)': {
            fontSize: "24px"
        },
        '@media (max-width: 460px)': {
            fontSize: "22px"
        }
    },
    container: {
        marginBottom:'-10px',
        minHeight: 614, 
        borderRadius: "8px", 
        background: "white", 
        display: "flex", 
        flexDirection: "column" as const, 
        position: "relative" as const
    },
    middleSec: {
        width: "100%", 
        paddingTop:'150px', 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center", 
        position: "absolute" as const, 
        zIndex: 998 
    },
    wrap: {
        display: "flex", 
        flexDirection: "column" as const, 
        alignItems: "center" 
    },
    text: { 
        fontFamily: `"Lato", sans-serif`, 
        marginTop: 10, 
        fontWeight: 700, 
        fontSize: '20px', 
        color: "#565656",
        '@media (max-width: 960px)': {
            fontSize: "18px"
        },
        '@media (max-width: 600px)': {
            fontSize: "16px"
        }
    },
    addBtn : { 
        marginTop: 20, 
        fontSize:'20px',
        padding: "10px 90px",
        '@media (max-width: 960px)': {
            fontSize: "18px"
        },
        '@media (max-width: 600px)': {
            fontSize: "16px",
            padding: "10px 70px",
        }
    },
    image: {
        '@media (max-width: 600px)': {
            width:'80px',
            height:'80px'
        }
    }
}
const StyledAddAddress = withStyles(styles)(SellerAddSingle)
export {StyledAddAddress}
// Customizable Area End
