import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBrandList, ICategory, ProductListArgs } from "./ProductCatalogueController.web";
import { ChangeEvent } from "react";
import { setStorageData } from "framework/src/Utilities";

// Customizable Area Start
export const configJSON = require("../../src/config.js")


// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  match?: {
    params : {
      id: string | number
    }
  }
  classes: {
    listWrapper: string,
    checkBox: string,
    sliderColor: string,
    headerText: string,
    container: string,
    orderTextWrapper: string,
    ordersText: string,
    searchWrapper: string,
    searchOrderText: string,
    searchBoxWrapper: string,
    contentWrapper: string
    checkBoxFormControl: string,
    titleText: string,
    optionText: string,
    customTitle: string  ,
    colorBox: string,
    colorGrid: string,
    colorWrapper: string
  }
  subCategoryList: ICategory[];
  brandList: IBrandList[];
  subCatagoryItemsIds: string[];
  brandItemsIds: string[];
  min_price: number | null;
  max_price: number | null;
  productList: {
    min_range: number,
    max_range: number
  };
  setFilterValues: (args :ProductListArgs)=>void;
  customFieldsData: {[key: string]: string[]; };
  customFieldValues: string[],
  handleRatingVlaue: (args: any) => any;
  colorFilter: string[];
  onSetColorFilter: (color: string) => void;
  out_of_stocks: boolean;
  onSetOutOfStocks: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  authToken: string;
  searchValue: string;
  priceRange: number[];
  ratingValues: boolean[]
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class CategoriesSideBarController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  getCustomFieldsApiCallId: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      authToken: "",
      searchValue: '',
      priceRange:[Number(this.props.productList.min_range),Number(this.props.productList.max_range)],
      ratingValues: new Array(5).fill(false)
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  handleChangeCheckBox = async(id:string, e: React.ChangeEvent<HTMLInputElement>) =>{
    const { subCatagoryItemsIds } = this.props;
    const filter = subCatagoryItemsIds.filter((item: string) => item !== id);
   const tempArray:string[] = e.target?.checked
   ? [...subCatagoryItemsIds, id]
   : [...filter];
   await setStorageData("subCatagoryIds", JSON.stringify(tempArray))
   this.handleUpdateUrl("subCatagoryIds", tempArray)
    this.props.setFilterValues({subCatagoryIds:tempArray})
  };

  handleUpdateUrl = (type: string, value: string | string[]) => {
    const { origin, pathname } = window.location;
    const pathParts = pathname.split('/');
    const baseUrl = `${origin}/search/categories/${pathParts[3]}/${pathParts[4]}`;

    let newUrl = `${pathname}/${type}=${value}`;

    if (pathname.includes(type)) {
        newUrl = `${baseUrl}/${type}=${value}`;
    }

    if (!value.length && (pathname.includes("subCatagoryIds") || pathname.includes("brandIds"))) {
        newUrl = baseUrl;
    }
    window.history.pushState(null, '', newUrl);
};

  handleChangeBrands = (id: string) => (e: React.ChangeEvent<HTMLInputElement>) =>{
    const { brandItemsIds } = this.props;
    const filter = brandItemsIds.filter((item: string) => item !== id);
    const tempArray:string[] = e.target?.checked
    ? [...brandItemsIds, id]
    : [...filter];
    setStorageData("brandIds", JSON.stringify(tempArray))
    this.handleUpdateUrl("brandIds", tempArray)
    this.props.setFilterValues({brandIds:tempArray})
  };

  handlePriceSliderChange = (event: ChangeEvent<{}>, value: number | number[]) => {
    this.setState({ priceRange: value as number[] });
  };

  handleChangeCommitted = (
    event: ChangeEvent<{}>,
    newValue: number | number[]
  ) => {
    const value = newValue as number[]; 
    const min_price = Math.floor(value[0]);
    const max_price = Math.ceil(value[1]);
    this.props.setFilterValues({min_price,max_price})
  };

  handleChangeCustomFilter = (value: string) => {
    const { customFieldValues } = this.props;
    const valueIndex = customFieldValues.indexOf(value);

    if (valueIndex !== -1) {
      const updatedValues = [...customFieldValues.slice(0, valueIndex), ...customFieldValues.slice(valueIndex + 1)];
      this.props.setFilterValues({ customFieldValues: updatedValues });
    } else {
      const updatedValues = [...customFieldValues, value];
      this.props.setFilterValues({ customFieldValues: updatedValues });
    }
  }

  handleChange = (index:number) => (event:React.ChangeEvent<HTMLInputElement>) => {
    const checked  = event.target?.checked;
    this.setState((prevState) => {
        const ratingValues = [...prevState.ratingValues];
        ratingValues[index] = checked; 
        this.props.handleRatingVlaue(ratingValues);
        return { ratingValues };
    });
};

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
      if(prevProps.subCategoryList !== this.props.subCategoryList) {
      this.setState({ ratingValues: new Array(5).fill(false)
        , priceRange: [] 
      });
      this.props.setFilterValues({ customFieldValues: [] });
    }
  }
  
  // Customizable Area End
}
